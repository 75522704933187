import { useContext, useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  Switch,
  Dialog,
  Button,
  TextField,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Close as CloseIcon, Lock as LockIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { SettingsContext } from 'src/context/settings'
import { LoadingBtn, StylePaper } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      settingBody: {
        marginTop: '24px',
        padding: '24px 48px',
      },
      userInfoItem: {
        position: 'relative',
      },
      passwordDotWrap: {
        position: 'relative',
        height: 24,
        marginTop: '8px',
        fontSize: 14,
      },
      dialog: {
        boxSizing: 'border-box',
        padding: '37px 40px',
        width: '380px',
        background: theme.palette.custom.dialogBg,
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '-8px',
        top: '-22px',
        cursor: 'pointer',
      },
      dialogTitle: {
        position: 'relative',
        boxSizing: 'border-box',
        fontSize: '16px',
        fontWeight: 600,
        paddingBottom: 10,
      },
      littleTitle: {
        fontSize: 14,
        marginTop: 14,
        lineHeight: '20px',
        fontWeight: 500,
        color: theme.palette.text.secondary,
      },
      dialogContent: {
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: 16,
      },
      verifyButton: {
        height: '36px !important',
        width: '100% !important',
        fontSize: '14px !important',
      },
      footer: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
      },
    }
  })

const View = (): JSX.Element => {
  const [t] = useTranslation()
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const className = useStyles()
  const { state, dispatch, toggleTwoFa } = useContext(SettingsContext)
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const checkChange = () => {
    setPassword('')
    setError('')
    dispatch({
      type: 'TWO_FA_DIALOG',
      payload: true,
    })
  }
  const valueChange = (e: any) => {
    setError('')
    setPassword(e.target.value)
  }
  const handleEmailClose = () => {
    dispatch({
      type: 'TWO_FA_DIALOG',
      payload: false,
    })
  }
  const handleSubmit = () => {
    if (password.length < 6) {
      setError(t('setting.password.passwordMinMaxErrMsg'))
      return
    }
    if (password.length > 20) {
      setError(t('setting.password.passwordMinMaxErrMsg'))
      return
    }
    toggleTwoFa(password, !state.twoFaStatus)
  }

  return (
    <StylePaper className={className.settingBody}>
      <Box className={className.userInfoItem}>
        <Typography variant="body2" color="textSecondary" fontSize={12}>
          Two-Factor Authentication
        </Typography>
        <Box className={className.passwordDotWrap}>
          Require a security code in addition to your password.
        </Box>
        <Typography
          color="textSecondary"
          component="div"
          style={{
            position: 'absolute',
            right: 0,
            top: 10,
            transform: 'scale(0.8)',
            transformOrigin: 'right',
          }}
        >
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>{state.twoFaStatus ? 'ON' : 'OFF'}</Grid>
            <Grid item style={{ position: 'relative' }}>
              <Switch
                checked={state.twoFaStatus}
                name="checkedC"
                color="primary"
                onChange={checkChange}
              />
            </Grid>
          </Grid>
        </Typography>
      </Box>
      <Dialog
        onClose={handleEmailClose}
        aria-labelledby="customized-dialog-title"
        open={state.twoFaDialog}
      >
        <Box className={className.dialog}>
          <Box className={className.dialogTitle} position="relative">
            <Box className={className.littleTitle}>
              Enter your password to {state.twoFaStatus ? 'disable' : 'turn on'}{' '}
              Two-Factor Authentication
            </Box>
            <CloseIcon
              className={className.closeFilterIcon}
              onClick={handleEmailClose}
            />
          </Box>
          <Box className={className.dialogContent}>
            <TextField
              fullWidth
              type="password"
              placeholder="password"
              error={!!error}
              helperText={error}
              variant="outlined"
              InputProps={{
                startAdornment: <LockIcon />,
              }}
              value={password}
              onChange={valueChange}
            />
          </Box>
          <Box className={className.footer}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              className={className.verifyButton}
            >
              {state.twoFaLoading ? (
                <Box>
                  <LoadingBtn
                    loadingStyle={{
                      justifyContent: 'center',
                    }}
                  />
                </Box>
              ) : (
                'Confirm'
              )}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </StylePaper>
  )
}

export default View
