import { UnsubscribeContextProvider } from 'src/context/unsubscribe'
import UnsubscribeView from './unsubscribe'

const TestDevices = (): JSX.Element => {
  // returns
  return (
    <UnsubscribeContextProvider>
      <UnsubscribeView />
    </UnsubscribeContextProvider>
  )
}

export default TestDevices
