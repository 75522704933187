import { useContext, useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import { toPercent, toThousands } from 'src/utils'
import { AdPlacementsContext } from 'src/context/adPlacement'
import { GlobalContext } from 'src/context/global'
import TableSetting from './tableSetting'
import { SortButton } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '100%',
      },
      container: {},
      game: {
        display: 'flex',
        alignItems: 'center',
      },
      num: {
        width: '20px',
        transform: 'translateX(-16px)',
      },
      gamePhoto: {
        width: '26px',
        height: '26px',
        verticalAlign: 'middle',
        borderRadius: '4px',
      },
      caption: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: '18px',
      },
      textPrimaryColor: {
        color: theme.palette.text.primary,
      },
      textSecondaryColor: {
        color: theme.palette.text.secondary,
      },
      mobileCard: {
        padding: '20px',
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      tableHead: {
        color: `${theme.palette.text.primary} !important`,
        backgroundColor: `${theme.palette.background.paper} !important`,
      },
    }
  })
const loadingRow = ['', '', '']

const TableView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, sortList, exportData } = useContext(AdPlacementsContext)
  const { state: globalState } = useContext(GlobalContext)
  const allTableListRow = useMemo(() => {
    return state.summaryList?.rows?.filter((item: any) => item.name) || []
  }, [state.summaryList])
  const headerList = useMemo(() => {
    const temp = state.summaryList.columns.filter(
      (item: { id: string; label: string }) => {
        return state.checkedMetrics.includes(item.id) || item.id === 'name'
      }
    )
    return temp
  }, [state.summaryList.columns, state.checkedMetrics])

  return (
    <TableContainer className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        height="26px"
        lineHeight="26px"
        position="relative"
        fontSize="20px"
        fontWeight={500}
        marginBottom="16px"
      >
        <Box>Summary</Box>
        <Box display="flex">
          {!!state.summaryList?.rows?.length && (
            <>
              <Box
                boxSizing="border-box"
                width="100px"
                height="28px"
                fontSize="12px"
                fontWeight={600}
                lineHeight="26px"
                textAlign="center"
                border={`1px solid ${theme.palette.text.primary}`}
                borderRadius="6px"
                marginRight="16px"
              >
                <Box
                  onClick={() => {
                    exportData(headerList, allTableListRow)
                  }}
                  style={{
                    color: theme.palette.text.primary,
                    cursor: 'pointer',
                  }}
                >
                  Export
                </Box>
              </Box>
              <TableSetting />
            </>
          )}
        </Box>
      </Box>
      {!state.loading && !allTableListRow.length ? (
        <Box
          sx={{
            position: 'relative',
            boxSizing: 'border-box',
            width: '100%',
            minHeight: '130px',
            fontSize: '18px',
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              marginTop: '-10px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            Add ad placements to view the data.
          </Box>
        </Box>
      ) : (
        <>
          {!globalState.isMobile ? (
            <Box
              sx={{
                width: '100%',
                overflowX: 'auto',
                paddingBottom: '10px',
              }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ width: 'calc(100% - 2px)' }}
              >
                <TableHead>
                  <TableRow>
                    {headerList.map((column: any, i: number) => (
                      <TableCell
                        key={column.id + 'id1'}
                        align={column.align}
                        className={classes.tableHead}
                        style={{
                          minWidth: !i ? '260px' : '160px',
                          fontSize: '16px',
                          fontWeight: 400,
                          textAlign: !!i ? 'right' : 'left',
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={i ? 'flex-end' : 'flex-start'}
                        >
                          <Box>{i ? column.label : 'Ad Placement'}</Box>
                          {!!i && (
                            <SortButton
                              sort={
                                state.sort.id === column.id
                                  ? state.sort.by
                                  : null
                              }
                              clickSort={(sort: string) => {
                                sortList({ id: column.id, by: sort })
                              }}
                            />
                          )}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(state.loading ? loadingRow : allTableListRow).map(
                    (row: any, index: number) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index + 'row'}
                        >
                          {state.loading
                            ? headerList.map((column: any, i: number) => {
                                return (
                                  <TableCell
                                    key={i + 'column'}
                                    style={{
                                      minWidth: !i ? '260px' : '160px',
                                      color: theme.palette.text.primary,
                                    }}
                                  >
                                    {i ? (
                                      <Skeleton variant="text" />
                                    ) : (
                                      <Box className={classes.game}>
                                        <Box>
                                          <Skeleton
                                            variant="text"
                                            width={100}
                                          />
                                        </Box>
                                      </Box>
                                    )}
                                  </TableCell>
                                )
                              })
                            : headerList.map((column: any, i: number) => {
                                const value = row[column.id]
                                return (
                                  <TableCell
                                    key={column.id + 'id2'}
                                    align={column.align}
                                    className={classes.textSecondaryColor}
                                    style={{
                                      minWidth: !i ? '260px' : '160px',
                                      textAlign: i ? 'right' : 'left',
                                      fontSize: '14px',
                                      fontWeight: i ? 400 : 600,
                                    }}
                                  >
                                    {i ? (
                                      <Box
                                        sx={{
                                          fontVariantNumeric: 'tabular-nums',
                                          display: 'inline-block',
                                        }}
                                      >
                                        {!value && value !== 0 ? (
                                          '-'
                                        ) : (
                                          <>
                                            {(column.id === 'ratio_revenue' ||
                                              column.id ===
                                                'placement_arpdau' ||
                                              column.id ===
                                                'placement_arpdav') &&
                                              '$'}
                                            {column.id === 'ad_viewer_rate' &&
                                            state.showDavMetrics
                                              ? toPercent(value)
                                              : toThousands(value, true)}
                                          </>
                                        )}
                                      </Box>
                                    ) : (
                                      <Box className={classes.game}>
                                        <Box
                                          className={classes.textPrimaryColor}
                                        >
                                          {value}
                                        </Box>
                                      </Box>
                                    )}
                                  </TableCell>
                                )
                              })}
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <Box>
              {(state.loading ? loadingRow : allTableListRow)?.map(
                (row: any, i: number) => {
                  return (
                    <Box key={i + 'row2'} className={classes.mobileCard}>
                      <Box>
                        {state.loading
                          ? headerList.map((column: any, index: any) => {
                              if (index) {
                                return (
                                  <Box
                                    key={index + 'column2'}
                                    display="flex"
                                    justifyContent="space-between"
                                    marginTop="10px"
                                    color="#6E6E6E"
                                  >
                                    <Box>
                                      <Skeleton variant="text" width={100} />
                                    </Box>
                                    <Box>
                                      <Skeleton variant="text" width={100} />
                                    </Box>
                                  </Box>
                                )
                              } else {
                                return (
                                  <div className={classes.game} key={index}>
                                    <div>
                                      <Skeleton variant="text" width={100} />
                                    </div>
                                  </div>
                                )
                              }
                            })
                          : headerList.map((column: any, index: any) => {
                              if (index) {
                                return (
                                  <Box
                                    key={index + 'index'}
                                    display="flex"
                                    justifyContent="space-between"
                                    marginTop="10px"
                                    className={classes.textSecondaryColor}
                                  >
                                    <Box>{column.label}:</Box>
                                    <Box
                                      sx={{
                                        fontVariantNumeric: 'tabular-nums',
                                      }}
                                    >
                                      {!row[column.id] &&
                                      row[column.id] !== 0 ? (
                                        '-'
                                      ) : (
                                        <>
                                          {(column.id === 'ratio_revenue' ||
                                            column.id === 'placement_arpdau' ||
                                            column.id === 'placement_arpdav') &&
                                            '$'}
                                          {column.id === 'ad_viewer_rate' &&
                                          state.showDavMetrics
                                            ? toPercent(row[column.id])
                                            : toThousands(row[column.id], true)}
                                        </>
                                      )}
                                    </Box>
                                  </Box>
                                )
                              } else {
                                return (
                                  <div className={classes.game} key={index}>
                                    <Box className={classes.textPrimaryColor}>
                                      {row.name}
                                    </Box>
                                  </div>
                                )
                              }
                            })}
                      </Box>
                    </Box>
                  )
                }
              )}
            </Box>
          )}
        </>
      )}
    </TableContainer>
  )
}
export default TableView
