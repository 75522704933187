import {
  Dialog,
  Box,
  TextField,
  Button,
  Typography,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useContext, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Email as EmailIcon, Close as CloseIcon } from '@material-ui/icons'

import { SettingsContext } from 'src/context/settings'
import { LoadingBtn } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      dialog: {
        boxSizing: 'border-box',
        padding: '40px',
        maxWidth: '380px',
        minWidth: '290px',
        background: theme.palette.custom.dialogBg,
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '3px',
        cursor: 'pointer',
      },
      dialogTitle: {
        boxSizing: 'border-box',
        fontSize: '18px',
        fontWeight: 900,
        paddingBottom: 24,
      },
      dialogContent: {
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: 16,
      },
      inputWrap: {
        maxWidth: '298px',
        width: '100%',
      },
      textField: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
        '& .MuiFormHelperText-root.Mui-error': {
          whiteSpace: 'nowrap',
        },
      },
      sendCodeButton: {
        height: '36px !important',
        width: '150px !important',
        fontSize: '14px !important',
        marginLeft: `${theme.spacing(1)} !important`,
        '& .MuiButton-label': {
          whiteSpace: 'nowrap',
        },
      },
      verifyButton: {
        height: '36px !important',
        width: '100% !important',
        fontSize: '14px !important',
      },
      codeCaption: {
        fontSize: '12px !important',
        fontWeight: 500,
        color: `${theme.palette.text.disabled} !important`,
        marginTop: '7px !important',
      },
      footer: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
      },
      inputLabel: {
        color: theme.palette.custom.inputLabel,
        fontSize: 12,
        marginBottom: 8,
      },
    }
  })

interface ChangeEmailDialogProps {
  newEmail: string
}

const ChangeEmailDialog = (props: ChangeEmailDialogProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [t] = useTranslation()
  const { state, handleEmailClose, verifyEmail, emailSendCode } =
    useContext(SettingsContext)

  // form check
  const personalFormSchema = yup.object().shape({
    code: yup.string(),
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ code: string }>({
    resolver: yupResolver(personalFormSchema),
  })

  const isEmailCodeSend = useMemo(
    () => !!state.emailCodeInterval,
    [state.emailCodeInterval]
  )

  const getCode = (params: { code: string }) => {
    emailSendCode(props.newEmail)
  }

  const changeEmail = (params: { code: string }) => {
    if (!params.code) {
      return setError('code', {
        message: t('setting.personalInfo.verificationCodeErrMsg'),
      })
    }

    verifyEmail({
      code: params.code,
      email: props.newEmail,
    })
  }

  const codeInputView = (
    <Controller
      name="code"
      control={control}
      defaultValue={''}
      render={({ field }) => (
        <Box display="flex">
          <TextField
            {...field}
            // className={classes.textField}
            error={!!errors.code}
            helperText={errors.code?.message}
            variant="outlined"
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(getCode)}
            className={classes.sendCodeButton}
            disabled={isEmailCodeSend}
          >
            {state.codeLoading ? (
              <Box>
                <LoadingBtn
                  loadingStyle={{
                    justifyContent: 'center',
                  }}
                />
              </Box>
            ) : isEmailCodeSend ? (
              `Sent!(${state.emailCodeInterval})`
            ) : (
              'Resend'
            )}
          </Button>
        </Box>
      )}
    />
  )

  return (
    <Dialog
      onClose={handleEmailClose}
      aria-labelledby="customized-dialog-title"
      open={state.openChangeEmail}
    >
      <Box className={classes.dialog}>
        <Box className={classes.dialogTitle} position="relative">
          Verify Email Address
          <CloseIcon
            className={classes.closeFilterIcon}
            onClick={handleEmailClose}
          />
        </Box>
        <Box className={classes.dialogContent}>
          <Box className={classes.inputWrap} marginBottom={3}>
            <Box className={classes.inputLabel}>New Email Address</Box>
            <TextField
              defaultValue={props.newEmail}
              fullWidth
              disabled
              placeholder={t('auth.login.email')}
              InputProps={{
                startAdornment: <EmailIcon />,
              }}
            />
          </Box>
          <Box className={classes.inputWrap}>
            <Box className={classes.inputLabel}>Verification Code</Box>
            {codeInputView}
            <Typography className={classes.codeCaption}>
              The code will be sent to your new email address
            </Typography>
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit(changeEmail)}
            className={classes.verifyButton}
          >
            {state.verifyLoading ? (
              <Box>
                <LoadingBtn
                  loadingStyle={{
                    justifyContent: 'center',
                  }}
                />
              </Box>
            ) : (
              'Verify'
            )}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ChangeEmailDialog
