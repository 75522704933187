import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import HTMLReactParser from 'html-react-parser'
import { getDateEnglishFormat } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        position: 'relative',
        boxSizing: 'border-box',
        minWidth: '100%',
        padding: '10px 16px',
        cursor: 'pointer',
      },
      line: {
        width: 'calc(100% - 12px)',
        height: '1px',
        background: theme.palette.mode === 'light' ? '#E1E5E7' : '#495263',
        marginLeft: '6px',
      },
      right: {
        width: '100%',
      },
      title: {
        position: 'relative',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        marginBottom: '6px',
      },
      time: {
        minWidth: '86px',
        textAlign: 'right',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        color:
          theme.palette.mode === 'light'
            ? 'rgba(34, 51, 81, 0.4)'
            : 'rgba(247, 250, 252, 0.4)',
        transform: 'scale(0.83)',
        transformOrigin: 'right center',
      },
      content: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        color:
          theme.palette.mode === 'light'
            ? 'rgba(34, 51, 81, 0.4)'
            : 'rgba(247, 250, 252, 0.4)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    }
  })
const MessageBox = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const clickMessage = (e: any) => {
    if (props.boxClick) {
      props.boxClick(e, props)
    }
  }
  return (
    <>
      <Box
        className={classes.container}
        style={
          props.is_read
            ? {}
            : {
                background:
                  theme.palette.mode === 'light'
                    ? '#F2F9FF'
                    : 'rgba(242, 249, 255, 0.3)',
              }
        }
        onClick={clickMessage}
      >
        <Box className={classes.title}>
          <Box width="100%">
            {props.notificationLoading ? (
              <Skeleton variant="text" />
            ) : (
              props.title
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.content}>
            {props.notificationLoading ? (
              <Skeleton variant="rectangular" height={45} />
            ) : (
              // html to text 并解析
              HTMLReactParser(props.content.replace(/<[^>]*>/g, ''))
            )}
          </Box>
          <Box className={classes.time}>
            {props.notificationLoading ? (
              <Skeleton variant="text" />
            ) : (
              getDateEnglishFormat(props.time)
            )}
          </Box>
        </Box>
      </Box>

      <Box className={classes.line} />
    </>
  )
}

export default MessageBox
