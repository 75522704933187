import { AppPerformanceContextProvider } from 'src/context/compareGames'
import CompareGamesView from './compareGame'

const GameManage = (): JSX.Element => {
  // returns
  return (
    <AppPerformanceContextProvider>
      <CompareGamesView />
    </AppPerformanceContextProvider>
  )
}

export default GameManage
