const SVG = (props: any) => {
  const width = props.width || '24'
  const height = props.height || '20'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.49258"
        y="1.0707"
        width="11.0143"
        height="17.8586"
        rx="1.4"
        stroke="white"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M15.2822 0.982422V1.12402C15.2822 2.22859 14.3868 3.12402 13.2822 3.12402H10.6782C9.57365 3.12402 8.67822 2.22859 8.67822 1.12402V0.982422"
        stroke="white"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M10.0503 16.6787H13.9744"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SVG
