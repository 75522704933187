import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import RadioBoxView from './radioBoxView'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      platform: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '48px',
      },
    }
  })
const OrientationStepView = (props: any) => {
  const { screen, onChange } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  return (
    <>
      <Box className={classes.platform} sx={{ flexWrap: 'wrap' }}>
        <RadioBoxView
          icon={SVGIcon.PortraitSVG}
          label="Portrait"
          checked={screen === 'Vertical'}
          click={() => {
            onChange('Vertical')
          }}
        />
        <RadioBoxView
          icon={SVGIcon.LandscapeSVG}
          label="Landscape"
          checked={screen === 'Horizontal'}
          click={() => {
            onChange('Horizontal')
          }}
        />
        <RadioBoxView
          icon={SVGIcon.BothSVG}
          label="Both"
          checked={screen === 'all'}
          click={() => {
            onChange('all')
          }}
        />
      </Box>
    </>
  )
}
export default OrientationStepView
