const countries = [
  {
    name: 'Afghanistan',
    full_name: 'the Islamic Republic of Afghanistan',
    chinese_name: '阿富汗',
    code: 'AF',
    code_3: 'AFG',
    code_numeric: '004',
  },
  {
    name: 'Aland Islands',
    full_name: null,
    chinese_name: '奥兰群岛',
    code: 'AX',
    code_3: 'ALA',
    code_numeric: '248',
  },
  {
    name: 'Albania',
    full_name: 'the Republic of Albania',
    chinese_name: '阿尔巴尼亚',
    code: 'AL',
    code_3: 'ALB',
    code_numeric: '008',
  },
  {
    name: 'Algeria',
    full_name: "the People's Democratic Republic of Algeria",
    chinese_name: '阿尔及利亚',
    code: 'DZ',
    code_3: 'DZA',
    code_numeric: '012',
  },
  {
    name: 'American Samoa',
    full_name: null,
    chinese_name: '美属萨摩亚',
    code: 'AS',
    code_3: 'ASM',
    code_numeric: '016',
  },
  {
    name: 'Andorra',
    full_name: 'the Principality of Andorra',
    chinese_name: '安道尔',
    code: 'AD',
    code_3: 'AND',
    code_numeric: '020',
  },
  {
    name: 'Angola',
    full_name: 'the Republic of Angola',
    chinese_name: '安哥拉',
    code: 'AO',
    code_3: 'AGO',
    code_numeric: '024',
  },
  {
    name: 'Anguilla',
    full_name: null,
    chinese_name: '安圭拉',
    code: 'AI',
    code_3: 'AIA',
    code_numeric: '660',
  },
  {
    name: 'Antarctica',
    full_name: null,
    chinese_name: '南极洲',
    code: 'AQ',
    code_3: 'ATA',
    code_numeric: '010',
  },
  {
    name: 'Antigua and Barbuda',
    full_name: null,
    chinese_name: '安提瓜和巴布达',
    code: 'AG',
    code_3: 'ATG',
    code_numeric: '028',
  },
  {
    name: 'Argentina',
    full_name: 'the Argentine Republic',
    chinese_name: '阿根廷',
    code: 'AR',
    code_3: 'ARG',
    code_numeric: '032',
  },
  {
    name: 'Armenia',
    full_name: 'the Republic of Armenia',
    chinese_name: '亚美尼亚',
    code: 'AM',
    code_3: 'ARM',
    code_numeric: '051',
  },
  {
    name: 'Aruba',
    full_name: null,
    chinese_name: '阿鲁巴',
    code: 'AW',
    code_3: 'ABW',
    code_numeric: '533',
  },
  {
    name: 'Australia',
    full_name: null,
    chinese_name: '澳大利亚',
    code: 'AU',
    code_3: 'AUS',
    code_numeric: '036',
  },
  {
    name: 'Austria',
    full_name: 'the Republic of Austria',
    chinese_name: '奥地利',
    code: 'AT',
    code_3: 'AUT',
    code_numeric: '040',
  },
  {
    name: 'Azerbaijan',
    full_name: 'the Republic of Azerbaijan',
    chinese_name: '阿塞拜疆',
    code: 'AZ',
    code_3: 'AZE',
    code_numeric: '031',
  },
  {
    name: 'Bahamas',
    full_name: 'the Commonwealth of The Bahamas',
    chinese_name: '巴哈马',
    code: 'BS',
    code_3: 'BHS',
    code_numeric: '044',
  },
  {
    name: 'Bahrain',
    full_name: 'the Kingdom of Bahrain',
    chinese_name: '巴林',
    code: 'BH',
    code_3: 'BHR',
    code_numeric: '048',
  },
  {
    name: 'Bangladesh',
    full_name: "the People's Republic of Bangladesh",
    chinese_name: '孟加拉国',
    code: 'BD',
    code_3: 'BGD',
    code_numeric: '050',
  },
  {
    name: 'Barbados',
    full_name: null,
    chinese_name: '巴巴多斯',
    code: 'BB',
    code_3: 'BRB',
    code_numeric: '052',
  },
  {
    name: 'Belarus',
    full_name: 'the Republic of Belarus',
    chinese_name: '白俄罗斯',
    code: 'BY',
    code_3: 'BLR',
    code_numeric: '112',
  },
  {
    name: 'Belgium',
    full_name: 'the Kingdom of Belgium',
    chinese_name: '比利时',
    code: 'BE',
    code_3: 'BEL',
    code_numeric: '056',
  },
  {
    name: 'Belize',
    full_name: null,
    chinese_name: '伯利兹',
    code: 'BZ',
    code_3: 'BLZ',
    code_numeric: '084',
  },
  {
    name: 'Benin',
    full_name: 'the Republic of Benin',
    chinese_name: '贝宁',
    code: 'BJ',
    code_3: 'BEN',
    code_numeric: '204',
  },
  {
    name: 'Bermuda',
    full_name: null,
    chinese_name: '百慕大',
    code: 'BM',
    code_3: 'BMU',
    code_numeric: '060',
  },
  {
    name: 'Bhutan',
    full_name: 'the Kingdom of Bhutan',
    chinese_name: '不丹',
    code: 'BT',
    code_3: 'BTN',
    code_numeric: '064',
  },
  {
    name: 'Bolivia',
    full_name: 'the Republic of Bolivia',
    chinese_name: '玻利维亚',
    code: 'BO',
    code_3: 'BOL',
    code_numeric: '068',
  },
  {
    name: 'Bonaire, Saint Eustatius and Saba',
    full_name: 'Bonaire, Saint Eustatius and Saba',
    chinese_name: 'Bonaire, Saint Eustatius and Saba',
    code: 'BQ',
    code_3: 'BES',
    newAdd: true,
  },
  {
    name: 'Bosnia and Herzegovina',
    full_name: null,
    chinese_name: '波黑',
    code: 'BA',
    code_3: 'BIH',
    code_numeric: '070',
  },
  {
    name: 'Botswana',
    full_name: 'the Republic of Botswana',
    chinese_name: '博茨瓦纳',
    code: 'BW',
    code_3: 'BWA',
    code_numeric: '072',
  },
  {
    name: 'Bouvet Island',
    full_name: null,
    chinese_name: '布维岛',
    code: 'BV',
    code_3: 'BVT',
    code_numeric: '074',
  },
  {
    name: 'Brazil',
    full_name: 'the Federative Republic of Brazil',
    chinese_name: '巴西',
    code: 'BR',
    code_3: 'BRA',
    code_numeric: '076',
  },
  {
    name: 'British Indian Ocean Territory',
    full_name: null,
    chinese_name: '英属印度洋领地',
    code: 'IO',
    code_3: 'IOT',
    code_numeric: '086',
  },
  {
    name: 'British Virgin Islands',
    full_name: 'British Virgin Islands',
    chinese_name: '英属维尔京群岛',
    code: 'VG',
    code_3: 'VGB',
    code_numeric: '092',
  },
  {
    name: 'Brunei',
    full_name: null,
    chinese_name: '文莱',
    code: 'BN',
    code_3: 'BRN',
    code_numeric: '096',
  },
  {
    name: 'Bulgaria',
    full_name: 'the Republic of Bulgaria',
    chinese_name: '保加利亚',
    code: 'BG',
    code_3: 'BGR',
    code_numeric: '100',
  },
  {
    name: 'Burkina Faso',
    full_name: 'Burkina Faso',
    chinese_name: '布基纳法索',
    code: 'BF',
    code_3: 'BFA',
    code_numeric: '854',
  },
  {
    name: 'Burundi',
    full_name: 'the Republic of Burundi',
    chinese_name: '布隆迪',
    code: 'BI',
    code_3: 'BDI',
    code_numeric: '108',
  },
  {
    name: 'Cambodia',
    full_name: 'the Kingdom of Cambodia',
    chinese_name: '柬埔寨',
    code: 'KH',
    code_3: 'KHM',
    code_numeric: '116',
  },
  {
    name: 'Cameroon',
    full_name: 'the Republic of Cameroon',
    chinese_name: '喀麦隆',
    code: 'CM',
    code_3: 'CMR',
    code_numeric: '120',
  },
  {
    name: 'Canada',
    full_name: null,
    chinese_name: '加拿大',
    code: 'CA',
    code_3: 'CAN',
    code_numeric: '124',
  },
  {
    name: 'Cape Verde',
    full_name: 'the Republic of Cape Verde',
    chinese_name: '佛得角',
    code: 'CV',
    code_3: 'CPV',
    code_numeric: '132',
  },
  {
    name: 'Cayman Islands',
    full_name: null,
    chinese_name: '开曼群岛',
    code: 'KY',
    code_3: 'CYM',
    code_numeric: '136',
  },
  {
    name: 'Central African Republic',
    full_name: 'the Central African Republic',
    chinese_name: '中非',
    code: 'CF',
    code_3: 'CAF',
    code_numeric: '140',
  },
  {
    name: 'Chad',
    full_name: 'the Republic of Chad',
    chinese_name: '乍得',
    code: 'TD',
    code_3: 'TCD',
    code_numeric: '148',
  },
  {
    name: 'Chile',
    full_name: 'the Republic of Chile',
    chinese_name: '智利',
    code: 'CL',
    code_3: 'CHL',
    code_numeric: '152',
  },
  {
    name: 'China',
    full_name: "the People's Republic of China",
    chinese_name: '中国',
    code: 'CN',
    code_3: 'CHN',
    code_numeric: '156',
  },
  {
    name: 'Christmas Island',
    full_name: null,
    chinese_name: '圣诞岛',
    code: 'CX',
    code_3: 'CXR',
    code_numeric: '162',
  },
  {
    name: 'Cocos Islands',
    full_name: null,
    chinese_name: '科科斯（基林）群岛',
    code: 'CC',
    code_3: 'CCK',
    code_numeric: '166',
  },
  {
    name: 'Colombia',
    full_name: 'the Republic of Colombia',
    chinese_name: '哥伦比亚',
    code: 'CO',
    code_3: 'COL',
    code_numeric: '170',
  },
  {
    name: 'Comoros',
    full_name: 'the Union of the Comoros',
    chinese_name: '科摩罗',
    code: 'KM',
    code_3: 'COM',
    code_numeric: '174',
  },
  {
    name: 'Cook Islands',
    full_name: null,
    chinese_name: '库克群岛',
    code: 'CK',
    code_3: 'COK',
    code_numeric: '184',
  },
  {
    name: 'Costa Rica',
    full_name: 'the Republic of Costa Rica',
    chinese_name: '哥斯达黎加',
    code: 'CR',
    code_3: 'CRI',
    code_numeric: '188',
  },
  {
    name: 'Croatia',
    full_name: 'the Republic of Croatia',
    chinese_name: '克罗地亚',
    code: 'HR',
    code_3: 'HRV',
    code_numeric: '191',
  },
  {
    name: 'Cuba',
    full_name: 'the Republic of Cuba',
    chinese_name: '古巴',
    code: 'CU',
    code_3: 'CUB',
    code_numeric: '192',
  },
  {
    name: 'Curacao',
    full_name: 'Curacao',
    chinese_name: 'Curacao',
    code: 'CW',
    code_3: 'CUW',
    newAdd: true,
  },
  {
    name: 'Cyprus',
    full_name: 'the Republic of Cyprus',
    chinese_name: '塞浦路斯',
    code: 'CY',
    code_3: 'CYP',
    code_numeric: '196',
  },
  {
    name: 'Czech Republic',
    full_name: 'the Czech Republic',
    chinese_name: '捷克',
    code: 'CZ',
    code_3: 'CZE',
    code_numeric: '203',
  },
  {
    name: 'Democratic Republic of the Congo',
    full_name: 'the Democratic Republic of the Congo',
    chinese_name: '刚果（金）',
    code: 'CD',
    code_3: 'COD',
    code_numeric: '180',
  },
  {
    name: 'Denmark',
    full_name: 'the Kingdom of Denmark',
    chinese_name: '丹麦',
    code: 'DK',
    code_3: 'DNK',
    code_numeric: '208',
  },
  {
    name: 'Djibouti',
    full_name: 'the Republic of Djibouti',
    chinese_name: '吉布提',
    code: 'DJ',
    code_3: 'DJI',
    code_numeric: '262',
  },
  {
    name: 'Dominica',
    full_name: 'the Commonwealth of Dominica',
    chinese_name: '多米尼克',
    code: 'DM',
    code_3: 'DMA',
    code_numeric: '212',
  },
  {
    name: 'Dominican Republic',
    full_name: 'the Dominican Republic',
    chinese_name: '多米尼加',
    code: 'DO',
    code_3: 'DOM',
    code_numeric: '214',
  },
  {
    name: 'East Timor',
    full_name: 'the Democratic Republic of Timor-Leste',
    chinese_name: '东帝汶',
    code: 'TL',
    code_3: 'TLS',
    code_numeric: '626',
  },
  {
    name: 'Ecuador',
    full_name: 'the Republic of Ecuador',
    chinese_name: '厄瓜多尔',
    code: 'EC',
    code_3: 'ECU',
    code_numeric: '218',
  },
  {
    name: 'Egypt',
    full_name: 'the Arab Republic of Egypt',
    chinese_name: '埃及',
    code: 'EG',
    code_3: 'EGY',
    code_numeric: '818',
  },
  {
    name: 'El Salvador',
    full_name: 'the Republic of El Salvador',
    chinese_name: '萨尔瓦多',
    code: 'SV',
    code_3: 'SLV',
    code_numeric: '222',
  },
  {
    name: 'Equatorial Guinea',
    full_name: 'the Republic of Equatorial Guinea',
    chinese_name: '赤道几内亚',
    code: 'GQ',
    code_3: 'GNQ',
    code_numeric: '226',
  },
  {
    name: 'Eritrea',
    full_name: null,
    chinese_name: '厄立特里亚',
    code: 'ER',
    code_3: 'ERI',
    code_numeric: '232',
  },
  {
    name: 'Estonia',
    full_name: 'the Republic of Estonia',
    chinese_name: '爱沙尼亚',
    code: 'EE',
    code_3: 'EST',
    code_numeric: '233',
  },
  {
    name: 'Ethiopia',
    full_name: 'the Federal Democratic Republic of Ethiopia',
    chinese_name: '埃塞俄比亚',
    code: 'ET',
    code_3: 'ETH',
    code_numeric: '231',
  },
  {
    name: 'Falkland Islands',
    full_name: null,
    chinese_name: '福克兰群岛（马尔维纳斯）',
    code: 'FK',
    code_3: 'FLK',
    code_numeric: '238',
  },
  {
    name: 'Faroe Islands',
    full_name: null,
    chinese_name: '法罗群岛',
    code: 'FO',
    code_3: 'FRO',
    code_numeric: '234',
  },
  {
    name: 'Fiji',
    full_name: 'the Republic of the Fiji Islands',
    chinese_name: '斐济',
    code: 'FJ',
    code_3: 'FJI',
    code_numeric: '242',
  },
  {
    name: 'Finland',
    full_name: 'the Republic of Finland',
    chinese_name: '芬兰',
    code: 'FI',
    code_3: 'FIN',
    code_numeric: '246',
  },
  {
    name: 'France',
    full_name: 'the French Republic',
    chinese_name: '法国',
    code: 'FR',
    code_3: 'FRA',
    code_numeric: '250',
  },
  {
    name: 'French Guiana',
    full_name: null,
    chinese_name: '法属圭亚那',
    code: 'GF',
    code_3: 'GUF',
    code_numeric: '254',
  },
  {
    name: 'French Polynesia',
    full_name: null,
    chinese_name: '法属波利尼西亚',
    code: 'PF',
    code_3: 'PYF',
    code_numeric: '258',
  },
  {
    name: 'French Southern Territories',
    full_name: null,
    chinese_name: '法属南部领地',
    code: 'TF',
    code_3: 'ATF',
    code_numeric: '260',
  },
  {
    name: 'Gabon',
    full_name: 'the Gabonese Republic',
    chinese_name: '加蓬',
    code: 'GA',
    code_3: 'GAB',
    code_numeric: '266',
  },
  {
    name: 'Gambia',
    full_name: 'the Republic of The Gambia',
    chinese_name: '冈比亚',
    code: 'GM',
    code_3: 'GMB',
    code_numeric: '270',
  },
  {
    name: 'Georgia',
    full_name: null,
    chinese_name: '格鲁吉亚',
    code: 'GE',
    code_3: 'GEO',
    code_numeric: '268',
  },
  {
    name: 'Germany',
    full_name: 'he Federal Republic of Germany',
    chinese_name: '德国',
    code: 'DE',
    code_3: 'DEU',
    code_numeric: '276',
  },
  {
    name: 'Ghana',
    full_name: 'the Republic of Ghana',
    chinese_name: '加纳',
    code: 'GH',
    code_3: 'GHA',
    code_numeric: '288',
  },
  {
    name: 'Gibraltar',
    full_name: null,
    chinese_name: '直布罗陀',
    code: 'GI',
    code_3: 'GIB',
    code_numeric: '292',
  },
  {
    name: 'Greece',
    full_name: 'the Hellenic Republic',
    chinese_name: '希腊',
    code: 'GR',
    code_3: 'GRC',
    code_numeric: '300',
  },
  {
    name: 'Greenland',
    full_name: null,
    chinese_name: '格陵兰',
    code: 'GL',
    code_3: 'GRL',
    code_numeric: '304',
  },
  {
    name: 'Grenada',
    full_name: null,
    chinese_name: '格林纳达',
    code: 'GD',
    code_3: 'GRD',
    code_numeric: '308',
  },
  {
    name: 'Guadeloupe',
    full_name: null,
    chinese_name: '瓜德罗普',
    code: 'GP',
    code_3: 'GLP',
    code_numeric: '312',
  },
  {
    name: 'Guam',
    full_name: null,
    chinese_name: '关岛',
    code: 'GU',
    code_3: 'GUM',
    code_numeric: '316',
  },
  {
    name: 'Guatemala',
    full_name: 'the Republic of Guatemala',
    chinese_name: '危地马拉',
    code: 'GT',
    code_3: 'GTM',
    code_numeric: '320',
  },
  {
    name: 'Guernsey',
    full_name: null,
    chinese_name: '格恩西岛',
    code: 'GG',
    code_3: 'GGY',
    code_numeric: '831',
  },
  {
    name: 'Guinea',
    full_name: 'the Republic of Guinea',
    chinese_name: '几内亚',
    code: 'GN',
    code_3: 'GIN',
    code_numeric: '324',
  },
  {
    name: 'Guinea-Bissau',
    full_name: 'the Republic of Guinea-Bissau',
    chinese_name: '几内亚比绍',
    code: 'GW',
    code_3: 'GNB',
    code_numeric: '624',
  },
  {
    name: 'Guyana',
    full_name: 'the Republic of Guyana',
    chinese_name: '圭亚那',
    code: 'GY',
    code_3: 'GUY',
    code_numeric: '328',
  },
  {
    name: 'Haiti',
    full_name: 'the Republic of Haiti',
    chinese_name: '海地',
    code: 'HT',
    code_3: 'HTI',
    code_numeric: '332',
  },
  {
    name: 'Heard Island and McDonald Islands',
    full_name: null,
    chinese_name: '赫德岛和麦克唐纳岛',
    code: 'HM',
    code_3: 'HMD',
    code_numeric: '334',
  },
  {
    name: 'Honduras',
    full_name: 'the Republic of Honduras',
    chinese_name: '洪都拉斯',
    code: 'HN',
    code_3: 'HND',
    code_numeric: '340',
  },
  {
    name: 'Hong Kong',
    full_name: 'the Hong Kong Special Administrative Region of China',
    chinese_name: '香港',
    code: 'HK',
    code_3: 'HKG',
    code_numeric: '344',
  },
  {
    name: 'Hungary',
    full_name: 'the Republic of Hungary',
    chinese_name: '匈牙利',
    code: 'HU',
    code_3: 'HUN',
    code_numeric: '348',
  },
  {
    name: 'Iceland',
    full_name: 'the Republic of Iceland',
    chinese_name: '冰岛',
    code: 'IS',
    code_3: 'ISL',
    code_numeric: '352',
  },
  {
    name: 'India',
    full_name: 'the Republic of India',
    chinese_name: '印度',
    code: 'IN',
    code_3: 'IND',
    code_numeric: '356',
  },
  {
    name: 'Indonesia',
    full_name: 'the Republic of Indonesia',
    chinese_name: '印度尼西亚',
    code: 'ID',
    code_3: 'IDN',
    code_numeric: '360',
  },
  {
    name: 'Iran',
    full_name: 'the Islamic Republic of Iran',
    chinese_name: '伊朗',
    code: 'IR',
    code_3: 'IRN',
    code_numeric: '364',
  },
  {
    name: 'Iraq',
    full_name: 'the Republic of Iraq',
    chinese_name: '伊拉克',
    code: 'IQ',
    code_3: 'IRQ',
    code_numeric: '368',
  },
  {
    name: 'Ireland',
    full_name: null,
    chinese_name: '爱尔兰',
    code: 'IE',
    code_3: 'IRL',
    code_numeric: '372',
  },
  {
    name: 'Isle of Man',
    full_name: null,
    chinese_name: '英国属地曼岛',
    code: 'IM',
    code_3: 'IMN',
    code_numeric: '833',
  },
  {
    name: 'Israel',
    full_name: 'the State of Israel',
    chinese_name: '以色列',
    code: 'IL',
    code_3: 'ISR',
    code_numeric: '376',
  },
  {
    name: 'Italy',
    full_name: 'the Republic of Italy',
    chinese_name: '意大利',
    code: 'IT',
    code_3: 'ITA',
    code_numeric: '380',
  },
  {
    name: 'Ivory Coast',
    full_name: "the Republic of Côte d'Ivoire",
    chinese_name: '科特迪瓦',
    code: 'CI',
    code_3: 'CIV',
    code_numeric: '384',
  },
  {
    name: 'Jamaica',
    full_name: null,
    chinese_name: '牙买加',
    code: 'JM',
    code_3: 'JAM',
    code_numeric: '388',
  },
  {
    name: 'Japan',
    full_name: null,
    chinese_name: '日本',
    code: 'JP',
    code_3: 'JPN',
    code_numeric: '392',
  },
  {
    name: 'Jersey',
    full_name: null,
    chinese_name: '泽西岛',
    code: 'JE',
    code_3: 'JEY',
    code_numeric: '832',
  },
  {
    name: 'Jordan',
    full_name: 'the Hashemite Kingdom of Jordan',
    chinese_name: '约旦',
    code: 'JO',
    code_3: 'JOR',
    code_numeric: '400',
  },
  {
    name: 'Kazakhstan',
    full_name: 'the Republic of Kazakhstan',
    chinese_name: '哈萨克斯坦',
    code: 'KZ',
    code_3: 'KAZ',
    code_numeric: '398',
  },
  {
    name: 'Kenya',
    full_name: 'the Republic of Kenya',
    chinese_name: '肯尼亚',
    code: 'KE',
    code_3: 'KEN',
    code_numeric: '404',
  },
  {
    name: 'Kiribati',
    full_name: 'the Republic of Kiribati',
    chinese_name: '基里巴斯',
    code: 'KI',
    code_3: 'KIR',
    code_numeric: '296',
  },
  {
    name: 'Kosovo',
    full_name: 'Kosovo',
    chinese_name: 'Kosovo',
    code: 'XK',
    code_3: 'XXK',
    newAdd: true,
  },
  {
    name: 'Kuwait',
    full_name: 'he State of Kuwait',
    chinese_name: '科威特',
    code: 'KW',
    code_3: 'KWT',
    code_numeric: '414',
  },
  {
    name: 'Kyrgyzstan',
    full_name: 'the Kyrgyz Republic',
    chinese_name: '吉尔吉斯斯坦',
    code: 'KG',
    code_3: 'KGZ',
    code_numeric: '417',
  },
  {
    name: 'Laos',
    full_name: "the Lao People's Democratic Republic",
    chinese_name: '老挝',
    code: 'LA',
    code_3: 'LAO',
    code_numeric: '418',
  },
  {
    name: 'Latvia',
    full_name: 'the Republic of Latvia',
    chinese_name: '拉脱维亚',
    code: 'LV',
    code_3: 'LVA',
    code_numeric: '428',
  },
  {
    name: 'Lebanon',
    full_name: 'the Lebanese Republic',
    chinese_name: '黎巴嫩',
    code: 'LB',
    code_3: 'LBN',
    code_numeric: '422',
  },
  {
    name: 'Lesotho',
    full_name: 'the Kingdom of Lesotho',
    chinese_name: '莱索托',
    code: 'LS',
    code_3: 'LSO',
    code_numeric: '426',
  },
  {
    name: 'Liberia',
    full_name: 'the Republic of Liberia',
    chinese_name: '利比里亚',
    code: 'LR',
    code_3: 'LBR',
    code_numeric: '430',
  },
  {
    name: 'Libya',
    full_name: "the Socialist People's Libyan Arab Jamahiriya",
    chinese_name: '利比亚',
    code: 'LY',
    code_3: 'LBY',
    code_numeric: '434',
  },
  {
    name: 'Liechtenstein',
    full_name: 'the Principality of Liechtenstein',
    chinese_name: '列支敦士登',
    code: 'LI',
    code_3: 'LIE',
    code_numeric: '438',
  },
  {
    name: 'Lithuania',
    full_name: 'the Republic of Lithuania',
    chinese_name: '立陶宛',
    code: 'LT',
    code_3: 'LTU',
    code_numeric: '440',
  },
  {
    name: 'Luxembourg',
    full_name: 'the Grand Duchy of Luxembourg',
    chinese_name: '卢森堡',
    code: 'LU',
    code_3: 'LUX',
    code_numeric: '442',
  },
  {
    name: 'Macao',
    full_name: 'Macao Special Administrative Region of China',
    chinese_name: '澳门',
    code: 'MO',
    code_3: 'MAC',
    code_numeric: '446',
  },
  {
    name: 'Macedonia',
    full_name: 'the former Yugoslav Republic of Macedonia',
    chinese_name: '前南马其顿',
    code: 'MK',
    code_3: 'MKD',
    code_numeric: '807',
  },
  {
    name: 'Madagascar',
    full_name: 'the Republic of Madagascar',
    chinese_name: '马达加斯加',
    code: 'MG',
    code_3: 'MDG',
    code_numeric: '450',
  },
  {
    name: 'Malawi',
    full_name: 'the Republic of Malawi',
    chinese_name: '马拉维',
    code: 'MW',
    code_3: 'MWI',
    code_numeric: '454',
  },
  {
    name: 'Malaysia',
    full_name: null,
    chinese_name: '马来西亚',
    code: 'MY',
    code_3: 'MYS',
    code_numeric: '458',
  },
  {
    name: 'Maldives',
    full_name: 'the Republic of Maldives',
    chinese_name: '马尔代夫',
    code: 'MV',
    code_3: 'MDV',
    code_numeric: '462',
  },
  {
    name: 'Mali',
    full_name: 'the Republic of Mali',
    chinese_name: '马里',
    code: 'ML',
    code_3: 'MLI',
    code_numeric: '466',
  },
  {
    name: 'Malta',
    full_name: 'the Republic of Malta',
    chinese_name: '马耳他',
    code: 'MT',
    code_3: 'MLT',
    code_numeric: '470',
  },
  {
    name: 'Marshall Islands',
    full_name: 'the Republic of the Marshall Islands',
    chinese_name: '马绍尔群岛',
    code: 'MH',
    code_3: 'MHL',
    code_numeric: '584',
  },
  {
    name: 'Martinique',
    full_name: null,
    chinese_name: '马提尼克',
    code: 'MQ',
    code_3: 'MTQ',
    code_numeric: '474',
  },
  {
    name: 'Mauritania',
    full_name: 'the Islamic Republic of Mauritania',
    chinese_name: '毛利塔尼亚',
    code: 'MR',
    code_3: 'MRT',
    code_numeric: '478',
  },
  {
    name: 'Mauritius',
    full_name: 'the Republic of Mauritius',
    chinese_name: '毛里求斯',
    code: 'MU',
    code_3: 'MUS',
    code_numeric: '480',
  },
  {
    name: 'Mayotte',
    full_name: null,
    chinese_name: '马约特',
    code: 'YT',
    code_3: 'MYT',
    code_numeric: '175',
  },
  {
    name: 'Mexico',
    full_name: 'the United Mexican States',
    chinese_name: '墨西哥',
    code: 'MX',
    code_3: 'MEX',
    code_numeric: '484',
  },
  {
    name: 'Micronesia',
    full_name: 'the Federated States of Micronesia',
    chinese_name: '密克罗尼西亚联邦',
    code: 'FM',
    code_3: 'FSM',
    code_numeric: '583',
  },
  {
    name: 'Moldova',
    full_name: 'the Republic of Moldova',
    chinese_name: '摩尔多瓦',
    code: 'MD',
    code_3: 'MDA',
    code_numeric: '498',
  },
  {
    name: 'Monaco',
    full_name: 'the Principality of Monaco',
    chinese_name: '摩纳哥',
    code: 'MC',
    code_3: 'MCO',
    code_numeric: '492',
  },
  {
    name: 'Mongolia',
    full_name: null,
    chinese_name: '蒙古',
    code: 'MN',
    code_3: 'MNG',
    code_numeric: '496',
  },
  {
    name: 'Montenegro',
    full_name: 'he Republic of Montenegro',
    chinese_name: '黑山',
    code: 'ME',
    code_3: 'MNE',
    code_numeric: '499',
  },
  {
    name: 'Montserrat',
    full_name: null,
    chinese_name: '蒙特塞拉特',
    code: 'MS',
    code_3: 'MSR',
    code_numeric: '500',
  },
  {
    name: 'Morocco',
    full_name: 'the Kingdom of Morocco',
    chinese_name: '摩洛哥',
    code: 'MA',
    code_3: 'MAR',
    code_numeric: '504',
  },
  {
    name: 'Mozambique',
    full_name: 'the Republic of Mozambique',
    chinese_name: '莫桑比克',
    code: 'MZ',
    code_3: 'MOZ',
    code_numeric: '508',
  },
  {
    name: 'Myanmar',
    full_name: 'the Union of Myanmar',
    chinese_name: '缅甸',
    code: 'MM',
    code_3: 'MMR',
    code_numeric: '104',
  },
  {
    name: 'Namibia',
    full_name: 'the Republic of Namibia',
    chinese_name: '纳米比亚',
    code: 'NA',
    code_3: 'NAM',
    code_numeric: '516',
  },
  {
    name: 'Nauru',
    full_name: 'the Republic of Nauru',
    chinese_name: '瑙鲁',
    code: 'NR',
    code_3: 'NRU',
    code_numeric: '520',
  },
  {
    name: 'Nepal',
    full_name: 'Nepal',
    chinese_name: '尼泊尔',
    code: 'NP',
    code_3: 'NPL',
    code_numeric: '524',
  },
  {
    name: 'Netherlands',
    full_name: 'the Kingdom of the Netherlands',
    chinese_name: '荷兰',
    code: 'NL',
    code_3: 'NLD',
    code_numeric: '528',
  },
  {
    name: 'Netherlands Antilles',
    full_name: null,
    chinese_name: '荷属安的列斯',
    code: 'AN',
    code_3: 'ANT',
    code_numeric: '530',
  },
  {
    name: 'New Caledonia',
    full_name: null,
    chinese_name: '新喀里多尼亚',
    code: 'NC',
    code_3: 'NCL',
    code_numeric: '540',
  },
  {
    name: 'New Zealand',
    full_name: null,
    chinese_name: '新西兰',
    code: 'NZ',
    code_3: 'NZL',
    code_numeric: '554',
  },
  {
    name: 'Nicaragua',
    full_name: 'the Republic of Nicaragua',
    chinese_name: '尼加拉瓜',
    code: 'NI',
    code_3: 'NIC',
    code_numeric: '558',
  },
  {
    name: 'Niger',
    full_name: 'the Republic of the Niger',
    chinese_name: '尼日尔',
    code: 'NE',
    code_3: 'NER',
    code_numeric: '562',
  },
  {
    name: 'Nigeria',
    full_name: 'the Federal Republic of Nigeria',
    chinese_name: '尼日利亚',
    code: 'NG',
    code_3: 'NGA',
    code_numeric: '566',
  },
  {
    name: 'Niue',
    full_name: 'the Republic of Niue',
    chinese_name: '纽埃',
    code: 'NU',
    code_3: 'NIU',
    code_numeric: '570',
  },
  {
    name: 'Norfolk Island',
    full_name: null,
    chinese_name: '诺福克岛',
    code: 'NF',
    code_3: 'NFK',
    code_numeric: '574',
  },
  {
    name: 'North Korea',
    full_name: "the Democratic People's Republic of Korea",
    chinese_name: '朝鲜',
    code: 'KP',
    code_3: 'PRK',
    code_numeric: '408',
  },
  {
    name: 'Northern Mariana Islands',
    full_name: 'the Commonwealth of the Northern Mariana Islands',
    chinese_name: '北马里亚纳',
    code: 'MP',
    code_3: 'MNP',
    code_numeric: '580',
  },
  {
    name: 'Norway',
    full_name: 'the Kingdom of Norway',
    chinese_name: '挪威',
    code: 'NO',
    code_3: 'NOR',
    code_numeric: '578',
  },
  {
    name: 'Oman',
    full_name: 'the Sultanate of Oman',
    chinese_name: '阿曼',
    code: 'OM',
    code_3: 'OMN',
    code_numeric: '512',
  },
  {
    name: 'Pakistan',
    full_name: 'the Islamic Republic of Pakistan',
    chinese_name: '巴基斯坦',
    code: 'PK',
    code_3: 'PAK',
    code_numeric: '586',
  },
  {
    name: 'Palau',
    full_name: 'the Republic of Palau',
    chinese_name: '帕劳',
    code: 'PW',
    code_3: 'PLW',
    code_numeric: '585',
  },
  {
    name: 'Palestinian Territory',
    full_name: 'the Occupied Palestinian Territory',
    chinese_name: '巴勒斯坦',
    code: 'PS',
    code_3: 'PSE',
    code_numeric: '275',
  },
  {
    name: 'Panama',
    full_name: 'the Republic of Panama',
    chinese_name: '巴拿马',
    code: 'PA',
    code_3: 'PAN',
    code_numeric: '591',
  },
  {
    name: 'Papua New Guinea',
    full_name: null,
    chinese_name: '巴布亚新几内亚',
    code: 'PG',
    code_3: 'PNG',
    code_numeric: '598',
  },
  {
    name: 'Paraguay',
    full_name: 'the Republic of Paraguay',
    chinese_name: '巴拉圭',
    code: 'PY',
    code_3: 'PRY',
    code_numeric: '600',
  },
  {
    name: 'Peru',
    full_name: 'the Republic of Peru',
    chinese_name: '秘鲁',
    code: 'PE',
    code_3: 'PER',
    code_numeric: '604',
  },
  {
    name: 'Philippines',
    full_name: 'the Republic of the Philippines',
    chinese_name: '菲律宾',
    code: 'PH',
    code_3: 'PHL',
    code_numeric: '608',
  },
  {
    name: 'Pitcairn',
    full_name: null,
    chinese_name: '皮特凯恩',
    code: 'PN',
    code_3: 'PCN',
    code_numeric: '612',
  },
  {
    name: 'Poland',
    full_name: 'the Republic of Poland',
    chinese_name: '波兰',
    code: 'PL',
    code_3: 'POL',
    code_numeric: '616',
  },
  {
    name: 'Portugal',
    full_name: 'the Portuguese Republic',
    chinese_name: '葡萄牙',
    code: 'PT',
    code_3: 'PRT',
    code_numeric: '620',
  },
  {
    name: 'Puerto Rico',
    full_name: null,
    chinese_name: '波多黎各',
    code: 'PR',
    code_3: 'PRI',
    code_numeric: '630',
  },
  {
    name: 'Qatar',
    full_name: 'the State of Qatar',
    chinese_name: '卡塔尔',
    code: 'QA',
    code_3: 'QAT',
    code_numeric: '634',
  },
  {
    name: 'Republic of the Congo',
    full_name: 'the Republic of the Congo',
    chinese_name: '刚果（布）',
    code: 'CG',
    code_3: 'COG',
    code_numeric: '178',
  },
  {
    name: 'Reunion',
    full_name: null,
    chinese_name: '留尼汪',
    code: 'RE',
    code_3: 'REU',
    code_numeric: '638',
  },
  {
    name: 'Romania',
    full_name: null,
    chinese_name: '罗马尼亚',
    code: 'RO',
    code_3: 'ROU',
    code_numeric: '642',
  },
  {
    name: 'Russia',
    full_name: 'the Russian Federation',
    chinese_name: '俄罗斯联邦',
    code: 'RU',
    code_3: 'RUS',
    code_numeric: '643',
  },
  {
    name: 'Rwanda',
    full_name: 'the Republic of Rwanda',
    chinese_name: '卢旺达',
    code: 'RW',
    code_3: 'RWA',
    code_numeric: '646',
  },
  {
    name: 'Saint Barthelemy',
    full_name: 'Saint Barthelemy',
    chinese_name: 'Saint Barthelemy',
    code: 'BL',
    code_3: 'BLM',
    newAdd: true,
  },
  {
    name: 'Saint Helena',
    full_name: null,
    chinese_name: '圣赫勒拿',
    code: 'SH',
    code_3: 'SHN',
    code_numeric: '654',
  },
  {
    name: 'Saint Kitts and Nevis',
    full_name: null,
    chinese_name: '圣基茨和尼维斯',
    code: 'KN',
    code_3: 'KNA',
    code_numeric: '659',
  },
  {
    name: 'Saint Lucia',
    full_name: null,
    chinese_name: '圣卢西亚',
    code: 'LC',
    code_3: 'LCA',
    code_numeric: '662',
  },
  {
    name: 'Saint Martin',
    full_name: 'Saint Martin',
    chinese_name: 'Saint Martin',
    code: 'MF',
    code_3: 'MAF',
    newAdd: true,
  },
  {
    name: 'Saint Pierre and Miquelon',
    full_name: null,
    chinese_name: '圣皮埃尔和密克隆',
    code: 'PM',
    code_3: 'SPM',
    code_numeric: '666',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    full_name: null,
    chinese_name: '圣文森特和格林纳丁斯',
    code: 'VC',
    code_3: 'VCT',
    code_numeric: '670',
  },
  {
    name: 'Samoa',
    full_name: 'the Independent State of Samoa',
    chinese_name: '萨摩亚',
    code: 'WS',
    code_3: 'WSM',
    code_numeric: '882',
  },
  {
    name: 'San Marino',
    full_name: 'the Republic of San Marino',
    chinese_name: '圣马力诺',
    code: 'SM',
    code_3: 'SMR',
    code_numeric: '674',
  },
  {
    name: 'Sao Tome and Principe',
    full_name: 'the Democratic Republic of Sao Tome and Principe',
    chinese_name: '圣多美和普林西比',
    code: 'ST',
    code_3: 'STP',
    code_numeric: '678',
  },
  {
    name: 'Saudi Arabia',
    full_name: 'the Kingdom of Saudi Arabia',
    chinese_name: '沙特阿拉伯',
    code: 'SA',
    code_3: 'SAU',
    code_numeric: '682',
  },
  {
    name: 'Senegal',
    full_name: 'the Republic of Senegal',
    chinese_name: '塞内加尔',
    code: 'SN',
    code_3: 'SEN',
    code_numeric: '686',
  },
  {
    name: 'Serbia',
    full_name: 'the Republic of Serbia',
    chinese_name: '塞尔维亚',
    code: 'RS',
    code_3: 'SRB',
    code_numeric: '688',
  },
  {
    name: 'Serbia and Montenegro',
    full_name: 'Serbia and Montenegro',
    chinese_name: 'Serbia and Montenegro',
    code: 'CS',
    code_3: 'SCG',
    newAdd: true,
  },
  {
    name: 'Seychelles',
    full_name: 'the Republic of Seychelles',
    chinese_name: '塞舌尔',
    code: 'SC',
    code_3: 'SYC',
    code_numeric: '690',
  },
  {
    name: 'Sierra Leone',
    full_name: 'the Republic of Sierra Leone',
    chinese_name: '塞拉利昂',
    code: 'SL',
    code_3: 'SLE',
    code_numeric: '694',
  },
  {
    name: 'Singapore',
    full_name: 'the Republic of Singapore',
    chinese_name: '新加坡',
    code: 'SG',
    code_3: 'SGP',
    code_numeric: '702',
  },
  {
    name: 'Sint Maarten',
    full_name: 'Sint Maarten',
    chinese_name: 'Sint Maarten',
    code: 'SX',
    code_3: 'SXM',
    newAdd: true,
  },
  {
    name: 'Slovakia',
    full_name: 'the Slovak Republic',
    chinese_name: '斯洛伐克',
    code: 'SK',
    code_3: 'SVK',
    code_numeric: '703',
  },
  {
    name: 'Slovenia',
    full_name: 'the Republic of Slovenia',
    chinese_name: '斯洛文尼亚',
    code: 'SI',
    code_3: 'SVN',
    code_numeric: '705',
  },
  {
    name: 'Solomon Islands',
    full_name: null,
    chinese_name: '所罗门群岛',
    code: 'SB',
    code_3: 'SLB',
    code_numeric: '090',
  },
  {
    name: 'Somalia',
    full_name: 'the Somali Republic',
    chinese_name: '索马里',
    code: 'SO',
    code_3: 'SOM',
    code_numeric: '706',
  },
  {
    name: 'South Africa',
    full_name: 'the Republic of South Africa',
    chinese_name: '南非',
    code: 'ZA',
    code_3: 'ZAF',
    code_numeric: '710',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    full_name: null,
    chinese_name: '南乔治亚岛和南桑德韦奇岛',
    code: 'GS',
    code_3: 'SGS',
    code_numeric: '239',
  },
  {
    name: 'South Korea',
    full_name: 'the Republic of Korea',
    chinese_name: '韩国',
    code: 'KR',
    code_3: 'KOR',
    code_numeric: '410',
  },
  {
    name: 'South Sudan',
    full_name: 'South Sudan',
    chinese_name: 'South Sudan',
    code: 'SS',
    code_3: 'SSD',
    newAdd: true,
  },
  {
    name: 'Spain',
    full_name: 'the Kingdom of Spain',
    chinese_name: '西班牙',
    code: 'ES',
    code_3: 'ESP',
    code_numeric: '724',
  },
  {
    name: 'Sri Lanka',
    full_name: 'the Democratic Socialist Republic of Sri Lanka',
    chinese_name: '斯里兰卡',
    code: 'LK',
    code_3: 'LKA',
    code_numeric: '144',
  },
  {
    name: 'Sudan',
    full_name: 'the Republic of the Sudan',
    chinese_name: '苏丹',
    code: 'SD',
    code_3: 'SDN',
    code_numeric: '736',
  },
  {
    name: 'Suriname',
    full_name: 'the Republic of Suriname',
    chinese_name: '苏里南',
    code: 'SR',
    code_3: 'SUR',
    code_numeric: '740',
  },
  {
    name: 'Svalbard and Jan Mayen',
    full_name: null,
    chinese_name: '斯瓦尔巴岛和扬马延岛',
    code: 'SJ',
    code_3: 'SJM',
    code_numeric: '744',
  },
  {
    name: 'Swaziland',
    full_name: 'the Kingdom of Swaziland',
    chinese_name: '斯威士兰',
    code: 'SZ',
    code_3: 'SWZ',
    code_numeric: '748',
  },
  {
    name: 'Sweden',
    full_name: 'the Kingdom of Sweden',
    chinese_name: '瑞典',
    code: 'SE',
    code_3: 'SWE',
    code_numeric: '752',
  },
  {
    name: 'Switzerland',
    full_name: 'the Swiss Confederation',
    chinese_name: '瑞士',
    code: 'CH',
    code_3: 'CHE',
    code_numeric: '756',
  },
  {
    name: 'Syria',
    full_name: 'the Syrian Arab Republic',
    chinese_name: '叙利亚',
    code: 'SY',
    code_3: 'SYR',
    code_numeric: '760',
  },
  {
    name: 'Taiwan',
    full_name: null,
    chinese_name: '台湾',
    code: 'TW',
    code_3: 'TWN',
    code_numeric: '158',
  },
  {
    name: 'Tajikistan',
    full_name: 'the Republic of Tajikistan',
    chinese_name: '塔吉克斯坦',
    code: 'TJ',
    code_3: 'TJK',
    code_numeric: '762',
  },
  {
    name: 'Tanzania',
    full_name: 'the United Republic of Tanzania',
    chinese_name: '坦桑尼亚',
    code: 'TZ',
    code_3: 'TZA',
    code_numeric: '834',
  },
  {
    name: 'Thailand',
    full_name: 'the Kingdom of Thailand',
    chinese_name: '泰国',
    code: 'TH',
    code_3: 'THA',
    code_numeric: '764',
  },
  {
    name: 'Togo',
    full_name: 'the Togolese Republic',
    chinese_name: '多哥',
    code: 'TG',
    code_3: 'TGO',
    code_numeric: '768',
  },
  {
    name: 'Tokelau',
    full_name: null,
    chinese_name: '托克劳',
    code: 'TK',
    code_3: 'TKL',
    code_numeric: '772',
  },
  {
    name: 'Tonga',
    full_name: 'the Kingdom of Tonga',
    chinese_name: '汤加',
    code: 'TO',
    code_3: 'TON',
    code_numeric: '776',
  },
  {
    name: 'Trinidad and Tobago',
    full_name: 'the Republic of Trinidad and Tobago',
    chinese_name: '特立尼达和多巴哥',
    code: 'TT',
    code_3: 'TTO',
    code_numeric: '780',
  },
  {
    name: 'Tunisia',
    full_name: 'the Republic of Tunisia',
    chinese_name: '突尼斯',
    code: 'TN',
    code_3: 'TUN',
    code_numeric: '788',
  },
  {
    name: 'Turkey',
    full_name: 'the Republic of Turkey',
    chinese_name: '土耳其',
    code: 'TR',
    code_3: 'TUR',
    code_numeric: '792',
  },
  {
    name: 'Turkmenistan',
    full_name: null,
    chinese_name: '土库曼斯坦',
    code: 'TM',
    code_3: 'TKM',
    code_numeric: '795',
  },
  {
    name: 'Turks and Caicos Islands',
    full_name: null,
    chinese_name: '特克斯和凯科斯群岛',
    code: 'TC',
    code_3: 'TCA',
    code_numeric: '796',
  },
  {
    name: 'Tuvalu',
    full_name: null,
    chinese_name: '图瓦卢',
    code: 'TV',
    code_3: 'TUV',
    code_numeric: '798',
  },
  {
    name: 'U.S. Virgin Islands',
    full_name: 'the Virgin Islands of the United States',
    chinese_name: '美属维尔京群岛',
    code: 'VI',
    code_3: 'VIR',
    code_numeric: '850',
  },
  {
    name: 'Uganda',
    full_name: 'the Republic of Uganda',
    chinese_name: '乌干达',
    code: 'UG',
    code_3: 'UGA',
    code_numeric: '800',
  },
  {
    name: 'Ukraine',
    full_name: null,
    chinese_name: '乌克兰',
    code: 'UA',
    code_3: 'UKR',
    code_numeric: '804',
  },
  {
    name: 'United Arab Emirates',
    full_name: 'the United Arab Emirates',
    chinese_name: '阿联酋',
    code: 'AE',
    code_3: 'ARE',
    code_numeric: '784',
  },
  {
    name: 'United Kingdom',
    full_name: 'the United Kingdom of Great Britain and Northern Ireland',
    chinese_name: '英国',
    code: 'GB',
    code_3: 'GBR',
    code_numeric: '826',
  },
  {
    name: 'United States',
    full_name: 'the United States of America',
    chinese_name: '美国',
    code: 'US',
    code_3: 'USA',
    code_numeric: '840',
  },
  {
    name: 'United States Minor Outlying Islands',
    full_name: null,
    chinese_name: '美国本土外小岛屿',
    code: 'UM',
    code_3: 'UMI',
    code_numeric: '581',
  },
  {
    name: 'Uruguay',
    full_name: 'the Eastern Republic of Uruguay',
    chinese_name: '乌拉圭',
    code: 'UY',
    code_3: 'URY',
    code_numeric: '858',
  },
  {
    name: 'Uzbekistan',
    full_name: 'the Republic of Uzbekistan',
    chinese_name: '乌兹别克斯坦',
    code: 'UZ',
    code_3: 'UZB',
    code_numeric: '860',
  },
  {
    name: 'Vanuatu',
    full_name: 'the Republic of Vanuatu',
    chinese_name: '瓦努阿图',
    code: 'VU',
    code_3: 'VUT',
    code_numeric: '548',
  },
  {
    name: 'Vatican',
    full_name: null,
    chinese_name: '梵蒂冈',
    code: 'VA',
    code_3: 'VAT',
    code_numeric: '336',
  },
  {
    name: 'Venezuela',
    full_name: 'the Bolivarian Republic of Venezuela',
    chinese_name: '委内瑞拉',
    code: 'VE',
    code_3: 'VEN',
    code_numeric: '862',
  },
  {
    name: 'Vietnam',
    full_name: 'the Socialist Republic of Viet Nam',
    chinese_name: '越南',
    code: 'VN',
    code_3: 'VNM',
    code_numeric: '704',
  },
  {
    name: 'Wallis and Futuna',
    full_name: 'Wallis and Futuna Islands',
    chinese_name: '瓦利斯和富图纳',
    code: 'WF',
    code_3: 'WLF',
    code_numeric: '876',
  },
  {
    name: 'Western Sahara',
    full_name: null,
    chinese_name: '西撒哈拉',
    code: 'EH',
    code_3: 'ESH',
    code_numeric: '732',
  },
  {
    name: 'Yemen',
    full_name: 'the Republic of Yemen',
    chinese_name: '也门',
    code: 'YE',
    code_3: 'YEM',
    code_numeric: '887',
  },
  {
    name: 'Zambia',
    full_name: 'the Republic of Zambia',
    chinese_name: '赞比亚',
    code: 'ZM',
    code_3: 'ZMB',
    code_numeric: '894',
  },
  {
    name: 'Zimbabwe',
    full_name: 'the Republic of Zimbabwe',
    chinese_name: '津巴布韦',
    code: 'ZW',
    code_3: 'ZWE',
    code_numeric: '716',
  },
  {
    name: 'Unknown',
    full_name: 'Unknown',
    chinese_name: 'Unknown',
    code: 'XX',
    code_3: 'XX',
    code_numeric: '000',
  },
]
export default countries
