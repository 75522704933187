import { Box, Dialog, Button, useTheme, Theme } from '@material-ui/core'
import Skeleton from '@material-ui/core/Skeleton'
import { makeStyles } from '@material-ui/styles'
import { useContext, useState, ChangeEvent } from 'react'
import { Close as CloseIcon } from '@material-ui/icons'
import { AppPerformanceContext } from 'src/context/appPerformance'
import { StylePaper, LoadingBtn } from 'src/components'
import { getScoringColor, getScoringNum, getScoringTitle } from 'src/utils'
import ReactMarkdown from 'react-markdown'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      dialog: {
        boxSizing: 'border-box',
        width: '80vw',
        maxWidth: '560px',
        padding: '24px',
        background: theme.palette.custom.dialogBg,
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
        paddingRight: '40px',
        lineHeight: '26px',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '-3px',
        cursor: 'pointer',
      },
      input: {
        boxSizing: 'border-box',
        width: '100%',
        outline: 'none',
        border: `1px solid ${theme.palette.custom.paperBorder}`,
        boxShadow:
          theme.palette.mode === 'light'
            ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
            : 'none',
        borderRadius: '6px',
        fontSize: '14px',
        marginTop: '16px',
        padding: '8px 12px',
        background: theme.palette.custom.dialogBg,
        color:
          theme.palette.mode === 'light'
            ? '#102542'
            : 'rgba(255, 255, 255, 0.7)',
        fontFamily: "Inter, 'Lato', Helvetica, sans-serif",
        '&::-webkit-input-placeholder': {
          color:
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.4)'
              : 'rgba(255, 255, 255, 0.4)',
        },
      },
      button: {
        width: '156px !important',
        height: '40px !important',
      },
    }
  })
const keyList = [
  'general',
  'monetization',
  'stickiness',
  'quality',
  'popularity',
]
const titleList = [
  'General',
  'Monetization',
  'Stickiness',
  'Quality',
  'Popularity',
]
const checkedBoxList = [
  'This isn’t helpful',
  'This recommendation is incorrect',
  'Can’t do',
]

const TableList = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, submitFeedback, submitFeedbackDetail } = useContext(
    AppPerformanceContext
  )
  const [feedbackType, setFeedbackType] = useState('')
  const [feedbackValue, setFeedbackValue] = useState('')
  const [checkedBox, setCheckedBox] = useState<string[]>([])
  const [feedbackId, setFeedbackId] = useState('')
  const [disabledForm, setDisabledForm] = useState(false)

  const feedback = (type: string, id: string) => {
    submitFeedback(state.appScoringDetail.id, {
      recommendation_id: id,
      vote: type,
    })
    setFeedbackValue('')
    setFeedbackType(type)
    setFeedbackId(id)
    setCheckedBox([])
    setDisabledForm(false)
    dispatch({
      type: 'openFeedbackDialog',
      payload: true,
    })
  }
  const showFeedback = (detail: {
    vote: string
    content: null | {
      content: string
      reason?: string[]
    }
  }) => {
    setFeedbackValue(detail.content?.content || '')
    setFeedbackType(detail.vote)
    setCheckedBox(detail.content?.reason || [])
    setDisabledForm(true)
    dispatch({
      type: 'openFeedbackDialog',
      payload: true,
    })
  }
  const inputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackValue(event.target.value)
  }
  const handleChange = (value: string) => {
    if (checkedBox.includes(value)) {
      setCheckedBox(checkedBox.filter(item => item !== value))
    } else {
      setCheckedBox([value, ...checkedBox])
    }
  }
  const submit = () => {
    if (state.voteLoading || state.feedbackLoading) {
      return
    }
    const comment: {
      content: string
      reason?: string[]
    } = {
      content: feedbackValue,
    }
    if (feedbackType === 'DOWN') {
      comment.reason = checkedBox
    }
    submitFeedbackDetail(state.appScoringDetail.id, {
      recommendation_id: feedbackId,
      vote: feedbackType,
      content: comment,
    })
  }
  const getContent = (content: string, isTitle: boolean) => {
    const contentList = content.split(':')
    if (isTitle) {
      return contentList[0] || ''
    }
    contentList.shift()
    const newContent = contentList.join(':').trim()
    return newContent || ''
  }

  return (
    <>
      <Box>
        {keyList.map((item, index) => {
          if (state.scoringLoading) {
            return (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={120}
                sx={{ marginTop: '24px', borderRadius: '8px' }}
                key={item}
              />
            )
          }
          const formatScoring = getScoringNum(
            state.appScoringDetail[item]?.score,
            true
          )
          return (
            <StylePaper width="100%" marginTop="24px" key={item}>
              <Box
                sx={{
                  display: 'flex',
                  padding: '16px 24px 12px',
                  boxSizing: 'border-box',
                  fontSize: '18px',
                  fontWeight: 700,
                  borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
                  lineHeight: '27px',
                }}
              >
                {titleList[index]}
                <Box
                  sx={{
                    width: '70px',
                    height: '27px',
                    lineHeight: '27px',
                    textAlign: 'center',
                    background:
                      getScoringColor(state.appScoringDetail[item]?.score) ||
                      theme.palette.custom.editIconButton,
                    color: getScoringColor(state.appScoringDetail[item]?.score)
                      ? '#FFF'
                      : theme.palette.text.primary,
                    fontSize: '12px',
                    fontWeight: 400,
                    borderRadius: '12px',
                    marginLeft: '20px',
                  }}
                >
                  {formatScoring}
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '10px 24px 24px',
                  boxSizing: 'border-box',
                }}
              >
                <Box
                  sx={{
                    fontSize: '14px',
                    lineHeight: '26px',
                  }}
                >
                  {formatScoring === '-'
                    ? 'Yet to be evaluated.'
                    : `${getScoringTitle(
                        state.appScoringDetail[item]?.score
                      )}. ${
                        state.appScoringDetail[item]?.recommendations?.length
                          ? 'Here are some recommendations for you.'
                          : 'No recommendations for this category.'
                      }`}
                </Box>
                {!!state.appScoringDetail[item]?.recommendations?.length && (
                  <Box>
                    {state.appScoringDetail[item]?.recommendations.map(
                      (element: any, i: number) => {
                        return (
                          <Box key={i} marginTop="6px" lineHeight="24px">
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Box sx={{ fontSize: '14px', fontWeight: 700 }}>
                                {i + 1}. {getContent(element.text, true)}
                              </Box>

                              <Box
                                sx={{
                                  display: 'flex',
                                  transform: 'translateY(-1px)',
                                  minWidth: '64px',
                                  width: '64px',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {state.voteDetailListLoading ? (
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      width="26px"
                                      height="26px"
                                      sx={{
                                        marginRight: '12px',
                                        borderRadius: '4px',
                                      }}
                                    />
                                    <Skeleton
                                      variant="rectangular"
                                      width="26px"
                                      height="26px"
                                      sx={{ borderRadius: '4px' }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {state.voteDetail[element.id] ? (
                                      <Box
                                        sx={{
                                          width: '26px',
                                          height: '26px',
                                          cursor: 'pointer',
                                          'svg path': {
                                            fill: '#F3F9FF',
                                          },
                                          'svg rect': {
                                            fill: '#1E96FC',
                                          },
                                        }}
                                        onClick={() => {
                                          showFeedback(
                                            state.voteDetail[element.id]
                                          )
                                        }}
                                      >
                                        {state.voteDetail[element.id].vote ===
                                        'UP' ? (
                                          <SVGIcon.UpvoteSVG
                                            color2={
                                              theme.palette.mode === 'dark'
                                                ? '#313741'
                                                : ''
                                            }
                                            style={{ verticalAlign: 'middle' }}
                                          />
                                        ) : (
                                          <SVGIcon.DownvoteSVG
                                            color2={
                                              theme.palette.mode === 'dark'
                                                ? '#313741'
                                                : ''
                                            }
                                            style={{ verticalAlign: 'middle' }}
                                          />
                                        )}
                                      </Box>
                                    ) : (
                                      <>
                                        <Box
                                          sx={{
                                            width: '26px',
                                            height: '26px',
                                            marginRight: '12px',
                                            cursor: 'pointer',
                                            '&:hover svg path': {
                                              fill: '#F3F9FF',
                                            },
                                            '&:hover svg rect': {
                                              fill: '#1E96FC',
                                            },
                                          }}
                                          onClick={() => {
                                            feedback('UP', element.id)
                                          }}
                                        >
                                          <SVGIcon.UpvoteSVG
                                            color2={
                                              theme.palette.mode === 'dark'
                                                ? '#313741'
                                                : ''
                                            }
                                            style={{ verticalAlign: 'middle' }}
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            width: '26px',
                                            height: '26px',
                                            cursor: 'pointer',
                                            '&:hover svg path': {
                                              fill: '#F3F9FF',
                                            },
                                            '&:hover svg rect': {
                                              fill: '#1E96FC',
                                            },
                                          }}
                                          onClick={() => {
                                            feedback('DOWN', element.id)
                                          }}
                                        >
                                          <SVGIcon.DownvoteSVG
                                            color2={
                                              theme.palette.mode === 'dark'
                                                ? '#313741'
                                                : ''
                                            }
                                            style={{ verticalAlign: 'middle' }}
                                          />
                                        </Box>
                                      </>
                                    )}
                                  </>
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                padding: '10px 0',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '17px',
                                color:
                                  theme.palette.mode === 'light'
                                    ? 'rgba(0, 0, 0)'
                                    : 'rgba(255, 255, 255)',
                                borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
                                '&>p': {
                                  margin: 0,
                                },
                              }}
                            >
                              <ReactMarkdown>
                                {getContent(element.text, false)}
                              </ReactMarkdown>
                            </Box>
                          </Box>
                        )
                      }
                    )}
                  </Box>
                )}
              </Box>
            </StylePaper>
          )
        })}
      </Box>
      <Dialog
        open={state.openFeedbackDialog}
        onClose={() => {
          disabledForm &&
            dispatch({
              type: 'openFeedbackDialog',
              payload: false,
            })
        }}
      >
        <Box className={classes.dialog}>
          <Box className={classes.title}>
            {feedbackType === 'UP' ? (
              <SVGIcon.UpvoteSVG
                color2={theme.palette.mode === 'dark' ? '#313741' : ''}
                style={{
                  verticalAlign: 'middle',
                  transform: 'translateY(-1px)',
                  marginRight: '16px',
                }}
              />
            ) : (
              <SVGIcon.DownvoteSVG
                color2={theme.palette.mode === 'dark' ? '#313741' : ''}
                style={{
                  verticalAlign: 'middle',
                  transform: 'translateY(-1px)',
                  marginRight: '16px',
                }}
              />
            )}
            {disabledForm ? 'Feedback details' : 'Provide additional feedback'}
            {disabledForm && (
              <CloseIcon
                className={classes.closeFilterIcon}
                onClick={() => {
                  dispatch({
                    type: 'openFeedbackDialog',
                    payload: false,
                  })
                }}
              />
            )}
          </Box>
          <Box>
            <textarea
              value={feedbackValue}
              className={classes.input}
              rows={5}
              placeholder={
                disabledForm
                  ? 'No details added'
                  : feedbackType === 'UP'
                  ? 'What do you like about the recommendation?'
                  : 'What was the issue with the recommendation? How can it be improved?'
              }
              onInput={inputChange}
              disabled={disabledForm}
            />
          </Box>
          {feedbackType === 'DOWN' && (
            <>
              <Box marginTop="6px">
                {checkedBoxList.map((item: string) => (
                  <Box
                    onClick={() => {
                      !disabledForm && handleChange(item)
                    }}
                    sx={{
                      display: 'flex',
                      marginLeft: '-2px',
                      cursor: disabledForm ? 'default' : 'pointer',
                    }}
                    key={item}
                  >
                    <Box
                      sx={{
                        height: '16px',
                        width: '16px',
                        padding: '7px',
                      }}
                    >
                      {checkedBox.includes(item) ? (
                        <SVGIcon.SdkCheckedBoxSVG />
                      ) : (
                        <SVGIcon.SdkUnCheckedBoxSVG
                          color={theme.palette.mode === 'dark' ? '#D5D6DA' : ''}
                        />
                      )}
                    </Box>
                    <Box lineHeight="30px" fontSize="14px">
                      {item}
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
          {!disabledForm && (
            <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
              <Button
                className={classes.button}
                variant="contained"
                disabled={
                  (feedbackType === 'UP' && !feedbackValue) ||
                  (feedbackType !== 'UP' &&
                    !feedbackValue &&
                    !checkedBox.length)
                }
                onClick={submit}
              >
                {state.voteLoading || state.feedbackLoading ? (
                  <LoadingBtn
                    loadingStyle={{
                      justifyContent: 'center',
                    }}
                  />
                ) : (
                  'Submit feedback'
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  )
}

export default TableList
