import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SessionsContext } from 'src/context/gamePlayInsights/sessions'
import { GlobalContext } from 'src/context/global'
import { BorderStyleSelectMultiple, BorderStyleDate } from 'src/components'
import dayjs from 'dayjs'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      topFilter: {
        display: 'flex',
        padding: '20px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${
          theme.palette.mode === 'light' ? '#E5E8EB' : '#484644'
        }`,
        top: 68,
        zIndex: 10,
      },
      topFilterOption: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '26px',
      },
      button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        width: 80,
        height: '40px',
        color: '#FFF',
        borderRadius: '6px',
        fontWeight: 600,
        fontSize: '14px',
        cursor: 'pointer',
        background: theme.palette.mode === 'light' ? '#1D3353' : '#ff7a08',
      },
    }
  })

const platformOptions = [
  {
    label: 'iOS',
    value: 'iOS',
  },
  {
    label: 'Android',
    value: 'android',
  },
]
const TopFilterView = (props: any): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, getSessions } = useContext(SessionsContext)
  const { state: globalState } = useContext(GlobalContext)

  const timeChange = (dateRange: any[], timeShow: string) => {
    dispatch({
      type: 'time',
      payload: dateRange,
    })

    dispatch({
      type: 'timeShow',
      payload: timeShow,
    })
  }
  const reset = () => {
    timeChange(
      [
        dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      ],
      'last_month'
    )
    dispatch({
      type: 'gameSelect',
      payload: [],
    })
    dispatch({
      type: 'country',
      payload: [],
    })
    dispatch({
      type: 'platform',
      payload: [],
    })
    dispatch({
      type: 'resetIndex',
      payload: state.resetIndex + 1,
    })
  }
  const search = () => {
    getSessions()
  }

  // returns
  return (
    <Box
      className={classes.topFilter}
      sx={
        !globalState.isMobile
          ? { top: 68, position: 'sticky' }
          : { top: 0, display: 'block' }
      }
    >
      <Box
        className={classes.topFilterOption}
        sx={!globalState.isMobile ? {} : { justifyContent: 'center' }}
      >
        <BorderStyleDate
          label="Dates"
          timeShow={state.timeShow}
          value={state.time}
          onChange={timeChange}
          onTriggerFilter={search}
        />
        <BorderStyleSelectMultiple
          label="Apps"
          valueKey="app_key"
          labelKey="name"
          placeholder="Apps"
          value={state.gameSelect}
          onChange={(row: any) => {
            dispatch({
              type: 'gameSelect',
              payload: row,
            })
          }}
          options={globalState.gameOption}
          onTriggerFilter={search}
        />
        <BorderStyleSelectMultiple
          label="Countries"
          valueKey="code"
          labelKey="name"
          placeholder="Countries"
          value={state.country}
          onChange={(row: any) => {
            dispatch({
              type: 'country',
              payload: row,
            })
          }}
          options={globalState.countryOption}
          onTriggerFilter={search}
        />
        <BorderStyleSelectMultiple
          label="Platform"
          placeholder="Platform"
          showSearch={false}
          value={state.platform}
          onChange={(row: any) => {
            dispatch({
              type: 'platform',
              payload: row,
            })
          }}
          options={platformOptions}
          onTriggerFilter={search}
        />
      </Box>
      <Box
        marginLeft="8px"
        display="flex"
        sx={
          !globalState.isMobile
            ? { minWidth: '133px', position: 'relative' }
            : { justifyContent: 'space-evenly', marginTop: '26px' }
        }
      >
        <Box
          sx={{
            lineHeight: '40px',
            fontSize: '14px',
            fontWeight: 600,
            cursor: 'pointer',
            marginRight: '20px',
          }}
          onClick={reset}
        >
          Reset
        </Box>
        <Box className={classes.button} id="overview-apply" onClick={search}>
          Apply
        </Box>
      </Box>
    </Box>
  )
}

export default TopFilterView
