const SVG = (props: any) => {
  const width = props.width || '24'
  const height = props.height || '24'
  const color = props.color || '#90CC75'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7144 10.029C18.095 10.4096 18.095 11.0222 17.7144 11.4028C17.5241 11.5932 17.2773 11.6883 17.0275 11.6883C16.7777 11.6883 16.5309 11.5932 16.3405 11.4028L12.9713 8.03059V18.3139C12.9713 18.8491 12.5372 19.2863 11.999 19.2863C11.4607 19.2863 11.0266 18.8521 11.0266 18.3139V8.03059L7.65737 11.4028C7.27673 11.7835 6.66415 11.7835 6.28352 11.4028C5.90289 11.0222 5.90289 10.4096 6.28352 10.029L11.312 5.00032C11.4934 4.81595 11.7402 4.71484 11.999 4.71484C12.2577 4.71484 12.5045 4.81595 12.6859 5.00032L17.7144 10.029Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
