import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        color: theme.palette.text.primary,
        paddingTop: '40px',
      },
      noData: {
        fontWeight: 600,
        fontSize: '18px',
        textAlign: 'center',
        marginTop: '20px',
      },
      desc: {
        fontSize: '14px',
        textAlign: 'center',
        marginTop: '10px',
      },
    }
  })

interface NoDataProps {
  desc?: string
  noDataDesc?: string
}

const NoData = (props: NoDataProps) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box textAlign="center">
        <SVGIcon.NoDataSVG
          color={theme.palette.mode === 'light' ? '' : '#3b414d'}
        />
      </Box>
      <Box className={classes.noData}>{props?.noDataDesc || 'No data'}</Box>
      {props.desc && <Box className={classes.desc}>{props.desc}</Box>}
    </Box>
  )
}

export default NoData
