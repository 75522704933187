import { createContext, useReducer, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { getPreMetricsApi, putPreMetricsApi } from 'src/api/game'
import { GlobalContext } from './global'
import { GameContext } from './games'
type Props = {
  metricsDialog: boolean
  preMetrics: any
  preMetricsLoading: boolean
  submitMetricsLoading: boolean
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  metricsDialog: false,
  preMetrics: {
    mediation_name: null,
    network_name: null,
    dau: null,
    ecpm: null,
    revenue: null,
    impressions: null,
    legal_frameworks: {
      GDPR: null,
      CCPA: null,
      COPPA: null,
      complied_legal_frameworks: null,
    },
    ad_types: [
      {
        checked: false,
        label: 'Rewarded Video',
        key: 'rewarded_video',
        metric: {
          ecpm: null,
          impressions: null,
          revenue: null,
        },
      },
      {
        checked: false,
        label: 'Interstitial',
        key: 'interstitial',
        metric: {
          ecpm: null,
          impressions: null,
          revenue: null,
        },
      },
      {
        checked: false,
        label: 'Banner',
        key: 'banner',
        metric: {
          ecpm: null,
          impressions: null,
          revenue: null,
        },
      },
      {
        checked: false,
        label: 'App Open',
        key: 'openads',
        metric: {
          ecpm: null,
          impressions: null,
          revenue: null,
        },
      },
      {
        checked: false,
        label: 'Rewarded Interstitial',
        key: 'rewardinter',
        metric: {
          ecpm: null,
          impressions: null,
          revenue: null,
        },
      },
      {
        checked: false,
        label: 'Native Ads ',
        key: 'nativeads',
        metric: {
          ecpm: null,
          impressions: null,
          revenue: null,
        },
      },
      {
        checked: false,
        label: 'Other',
        key: 'other',
        specify: '',
        metric: {
          ecpm: null,
          impressions: null,
          revenue: null,
        },
      },
    ],
    countries_info: [
      {
        country_name: '',
        metric: {
          ecpm: null,
          impressions: null,
          dau: null,
        },
      },
      {
        country_name: '',
        metric: {
          ecpm: null,
          impressions: null,
          dau: null,
        },
      },
      {
        country_name: '',
        metric: {
          ecpm: null,
          impressions: null,
          dau: null,
        },
      },
    ],
  },
  preMetricsLoading: false,
  submitMetricsLoading: false,
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'metricsDialog':
    case 'preMetrics':
    case 'preMetricsLoading':
    case 'submitMetricsLoading':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  getPreMetrics: (app_key: string) => void
  putPreMetrics: (app_key: string, data: any, obj?: any) => void
}
export const MetricsFormContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  getPreMetrics: (app_key: string) => {},
  putPreMetrics: (app_key: string, data: any, obj?: any) => {},
})

export const MetricsFormContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { updateListForOne } = useContext(GameContext)
  const { errorCatch, customAlert } = useContext(GlobalContext)
  const [t] = useTranslation()

  const getPreMetrics = (app_key: string) => {
    dispatch({ type: 'preMetricsLoading', payload: true })
    getPreMetricsApi(app_key)
      .then(({ data }) => {
        const temp = data.data
        const countriesTemp = [
          {
            country_name: '',
            metric: {
              revenue: null,
              ecpm: null,
              impressions: null,
              dau: null,
            },
          },
          {
            country_name: '',
            metric: {
              revenue: null,
              ecpm: null,
              impressions: null,
              dau: null,
            },
          },
          {
            country_name: '',
            metric: {
              revenue: null,
              ecpm: null,
              impressions: null,
              dau: null,
            },
          },
        ]
        if (temp.countries_info) {
          temp.countries_info.forEach((item: any, index: number) => {
            countriesTemp[index] = item
          })
        }
        temp.countries_info = countriesTemp
        const adTypeTemp = [
          {
            checked: false,
            label: 'Rewarded Video',
            key: 'rewarded_video',
            metric: {
              ecpm: null,
              impressions: null,
              revenue: null,
            },
          },
          {
            checked: false,
            label: 'Interstitial',
            key: 'interstitial',
            metric: {
              ecpm: null,
              impressions: null,
              revenue: null,
            },
          },
          {
            checked: false,
            label: 'Banner',
            key: 'banner',
            metric: {
              ecpm: null,
              impressions: null,
              revenue: null,
            },
          },
          {
            checked: false,
            label: 'App Open',
            key: 'openads',
            metric: {
              ecpm: null,
              impressions: null,
              revenue: null,
            },
          },
          {
            checked: false,
            label: 'Rewarded Interstitial',
            key: 'rewardinter',
            metric: {
              ecpm: null,
              impressions: null,
              revenue: null,
            },
          },
          {
            checked: false,
            label: 'Native Ads ',
            key: 'nativeads',
            metric: {
              ecpm: null,
              impressions: null,
              revenue: null,
            },
          },
          {
            checked: false,
            label: 'Other',
            key: 'other',
            specify: '',
            metric: {
              ecpm: null,
              impressions: null,
              revenue: null,
            },
          },
        ]
        if (temp.ad_types) {
          const indexList = {
            rewarded_video: 0,
            interstitial: 1,
            banner: 2,
            openads: 3,
            rewardinter: 4,
            nativeads: 5,
            other: 6,
          }
          Object.keys(temp.ad_types).forEach((item: any) => {
            if (item.includes('other')) {
              adTypeTemp[6].checked = true
              adTypeTemp[6].metric = temp.ad_types[item]
              const specify = item.split(':')
              adTypeTemp[6].specify = specify[1] || ''
            } else if (temp.ad_types[item]) {
              const index = indexList[item]
              adTypeTemp[index].checked = true
              adTypeTemp[index].metric = temp.ad_types[item]
            }
          })
        }
        temp.ad_types = adTypeTemp
        dispatch({ type: 'preMetrics', payload: temp })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'preMetricsLoading', payload: false })
      })
  }
  const putPreMetrics = (app_key: string, data: any, obj?: any) => {
    if (state.submitMetricsLoading) return
    dispatch({ type: 'submitMetricsLoading', payload: true })
    const ad_types = {}
    data.ad_types.forEach((item: any) => {
      if (item.checked) {
        let key = item.key
        if (key === 'other') {
          key = 'other:' + item.specify
        }
        ad_types[key] = {
          ecpm:
            item.metric.ecpm || item.metric.ecpm === 0
              ? item.metric.ecpm - 0
              : null,
          impressions:
            item.metric.impressions || item.metric.impressions === 0
              ? item.metric.impressions - 0
              : null,
          revenue:
            item.metric.revenue || item.metric.revenue === 0
              ? item.metric.revenue - 0
              : null,
        }
      }
    })
    const newData: any = {
      ...data,
      ad_types,
      countries_info: data.countries_info
        .filter((item: any) => item.country_name)
        .map((item: any) => {
          return {
            country_name: item.country_name,
            metric: {
              revenue:
                item.metric.revenue || item.metric.revenue === 0
                  ? item.metric.revenue - 0
                  : null,
              ecpm:
                item.metric.ecpm || item.metric.ecpm === 0
                  ? item.metric.ecpm - 0
                  : null,
              impressions:
                item.metric.impressions || item.metric.impressions === 0
                  ? item.metric.impressions - 0
                  : null,
              dau:
                item.metric.dau || item.metric.dau === 0
                  ? item.metric.dau - 0
                  : null,
            },
          }
        }),
    }
    putPreMetricsApi(app_key, newData)
      .then(() => {
        if (obj?.getNewData) {
          updateListForOne(obj.getNewData.app_key, obj.getNewData.index, () => {
            customAlert('success', t('common.success'))
            dispatch({ type: 'metricsDialog', payload: false })
            obj?.onClose && obj.onClose()
            setTimeout(() => {
              dispatch({ type: 'submitMetricsLoading', payload: false })
            }, 500)
          })
        } else {
          customAlert('success', t('common.success'))
          dispatch({ type: 'metricsDialog', payload: false })
          obj?.onClose && obj.onClose()
          setTimeout(() => {
            dispatch({ type: 'submitMetricsLoading', payload: false })
          }, 500)
        }
      })
      .catch((err: any) => {
        errorCatch(err)
        setTimeout(() => {
          dispatch({ type: 'submitMetricsLoading', payload: false })
        }, 500)
      })
  }

  // returns
  return (
    <MetricsFormContext.Provider
      value={{
        state,
        dispatch,
        getPreMetrics,
        putPreMetrics,
      }}
    >
      {children}
    </MetricsFormContext.Provider>
  )
}
