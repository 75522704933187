import { Box, Dialog, useTheme, Theme, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useContext } from 'react'
import { GlobalContext } from 'src/context/global'
import { PNG } from 'src/images'
import { Close as CloseIcon } from '@material-ui/icons'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        position: 'relative',
        width: '800px !important',
        height: '405px',
        padding: '50px',
        boxSizing: 'border-box',
        maxWidth: '800px',
        background: `url(${PNG.fancraft})`,
        backgroundSize: 'contain',
        color: '#FFF',
        overflow: 'hidden',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '34px !important',
        right: '16px',
        top: '16px',
        cursor: 'pointer',
      },
    }
  })

const FancraftDialogView = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch } = useContext(GlobalContext)
  const close = () => {
    dispatch({
      type: 'SHOW_FANCRAFT',
      payload: false,
    })
  }
  // returns
  return (
    <Dialog
      open={state.showFancraft}
      onClose={close}
      sx={{ '.MuiPaper-root': { maxWidth: '800px' } }}
    >
      <Box className={classes.root}>
        <CloseIcon className={classes.closeFilterIcon} onClick={close} />
        <img
          src="https://fancraft.yodo1.com/wp-content/uploads/2024/04/FanCraft-Logo-W.png"
          alt="fancraft"
          style={{ height: '60px', marginLeft: '-8px' }}
        />
        <Box sx={{ fontSize: '33px', fontWeight: 700, lineHeight: '32px' }}>
          Has Launched
        </Box>
        <Box
          sx={{
            boxSizing: 'border-box',
            width: '350px',
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '22.5px',
            padding: '20px 52px 0px 0',
            marginBottom: '16px',
          }}
        >
          Turn your players into lifetime fans with AI-driven engagement.
          Automatically reply to all your store reviews and reward players with
          in-app items.
        </Box>
        <Button
          color="primary"
          variant="contained"
          sx={{ width: '268px', height: '50px' }}
          onClick={() => {
            window.open('https://hubs.ly/Q02t_Kpl0')
          }}
        >
          START FREE TRIAL TODAY
        </Button>
      </Box>
    </Dialog>
  )
}

export default FancraftDialogView
