import { useMemo, useEffect, useContext } from 'react'
import { Box } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { SessionsContext } from 'src/context/gamePlayInsights/sessions'
import { StickinessContext } from 'src/context/gamePlayInsights/stickiness'
import { CohortsContext } from 'src/context/gamePlayInsights/cohorts'
import { ChurnRateContext } from 'src/context/gamePlayInsights/churnRate'
import { InstallsContext } from 'src/context/gamePlayInsights/installs'
import { GlobalStoreContext } from 'src/context/globalStore'
import { ReportsTab } from 'src/components'

const GameplayInsightsView = (): JSX.Element => {
  const navigate = useNavigate()
  const params = useParams()
  const { state: stickinessState } = useContext(StickinessContext)
  const { state, changeTabType } = useContext(SessionsContext)
  const { state: cohortsState } = useContext(CohortsContext)
  const { state: churnRateState } = useContext(ChurnRateContext)
  const { state: installsState } = useContext(InstallsContext)
  const { state: GlobalStoreState, finishToPage } =
    useContext(GlobalStoreContext)
  const tabs = useMemo(() => {
    const sessionDisabled = state.holdData?.tabDisabled || false
    const sessionUntilTime = state.holdData?.untilTime || null
    return [
      {
        label: 'Retention',
        key: 'retention',
        value: '-',
        trend: '-',
        tip: 'Retention in mobile apps refers to the percentage of users who continue to engage with and use the app over a specific period of time.',
        loading: cohortsState.thirtyLoading,
        ...(cohortsState.retentionTrend || {}),
      },
      {
        label: 'Churn Rate',
        key: 'churn-rate',
        value: '-',
        trend: '-',
        tip: 'Churn rate refers to the rate at which users discontinue or stop using your apps over a period of 30 days.',
        loading: churnRateState.churnThirtyLoading,
        ...(churnRateState.retentionTrend || {}),
      },
      {
        label: 'Stickiness',
        key: 'stickiness',
        value: '-',
        trend: '-',
        tip: 'Stickiness is the DAU to MAU %, indicating your app’s ability to retain players over 30 days.',
        loading: stickinessState.loading,
        ...(stickinessState.holdData?.relative_change?.stickiness || {}),
      },
      {
        label: 'Sessions',
        key: 'sessions',
        value: '-',
        trend: '-',
        disabled: sessionDisabled,
        untilTime: sessionUntilTime,
        tip: 'Sessions indicate the total number of user sessions on your apps integrated with MAS.',
        loading: state.loading,
        ...(state.holdData?.relative_change?.session_count || {}),
      },
      {
        label: 'Session Length',
        key: 'session-length',
        value: '-',
        trend: '-',
        disabled: sessionDisabled,
        untilTime: sessionUntilTime,
        tip: 'Session length indicates the average length of a user session on your apps integrated with MAS.',
        loading: state.loading,
        ...(state.holdData?.relative_change?.session_length || {}),
      },
      {
        label: 'Installs',
        key: 'installs',
        value: '-',
        tip: 'The total number of installs detected of your apps integrated with MAS.',
        loading: installsState.loading,
        ...(installsState.tabCardData || {}),
      },
    ]
  }, [
    cohortsState.retentionTrend,
    churnRateState.retentionTrend,
    stickinessState.holdData,
    state.holdData,
    installsState.tabCardData,
    cohortsState.thirtyLoading,
    churnRateState.churnThirtyLoading,
    stickinessState.loading,
    state.loading,
    installsState.loading,
  ])
  const switchTabs = (key: string) => {
    if (state.tabType === key) {
      return
    }
    changeTabType(key || '')
    navigate(`/dash/gameplay-insights/${key}`)
  }
  useEffect(() => {
    if (GlobalStoreState.fromWelcome) {
      finishToPage()
    }
    // eslint-disable-next-line
  }, [state.holdData])
  useEffect(() => {
    if (params.type) {
      changeTabType(params.type)
    }
    // eslint-disable-next-line
  }, [])
  // returns
  return (
    <Box width="100%">
      <ReportsTab
        value={state.tabType}
        labelKey="label" // tab名称
        valueKey="key" // tab选择的value
        numKey="value" // tab中的num值
        tooltipKey="tip" // tab中的tooltip
        showTrend // 是否展示趋势
        options={tabs}
        onChange={switchTabs}
      />
    </Box>
  )
}

export default GameplayInsightsView
