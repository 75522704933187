import { useEffect, useState, useContext, ChangeEvent } from 'react'
import {
  Box,
  TextField,
  Popover,
  Dialog,
  Button,
  Tooltip,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Delete as DeleteIcon,
  Save as SaveIcon,
  StarOutline as StarOutlineIcon,
  Star as StarIcon,
} from '@material-ui/icons'
import clsx from 'clsx'
import { PerformanceContext } from 'src/context/performance/index'
import { GlobalStoreContext } from 'src/context/globalStore'
import { GlobalContext } from 'src/context/global'
import { SVGIcon } from 'src/images'
import { LoadingBtn, LoadingComponent, DeleteDialog } from 'src/components'
import { getDateRangeFromValue } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      filterSelectBox: {
        fontSize: '14px',
        color: theme.palette.text.secondary,
        padding: '0 28px',
        cursor: 'pointer',
      },
      chartDefaultPopover: {
        '& .MuiPaper-root': {
          marginTop: '20px',
          borderRadius: '8px',
          padding: '0',
        },
      },
      addDefaultButton: {
        boxSizing: 'border-box',
        width: '238px',
        marginLeft: '16px',
        marginTop: '12px',
        height: '40px',
        lineHeight: '38px',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        borderRadius: '6px',
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        cursor: 'pointer',
      },
      chartConfigList: {
        width: '100%',
        display: 'flex',
        padding: '6px 14px',
        cursor: 'pointer',
        color: theme.palette.custom.inputLabel,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
          background:
            theme.palette.mode === 'light'
              ? 'rgba(224, 229, 231, 0.5)'
              : '#555D6A',
        },
      },
      chartConfigCheckedList: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        background:
          theme.palette.mode === 'light'
            ? 'rgba(224, 229, 231, 0.5)'
            : '#555D6A',
      },
      root: {
        width: '400px',
        padding: '40px',
        boxSizing: 'border-box',
        lineHeight: '22px',
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
      closeButton: {
        flex: 1,
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        borderRadius: '6px',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.custom.dialogButton}`,
        color: theme.palette.custom.dialogButton,
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
      borderSelectBox: {
        width: '150px',
        minWidth: '150px',
        position: 'relative',
        display: 'flex',
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '8px 16px',
        border: `1px solid ${theme.palette.custom.paperBorder}`,
        borderRadius: '6px',
        justifyContent: 'space-between',
        marginLeft: '20px',
        boxShadow:
          theme.palette.mode === 'light'
            ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
            : 'none',
      },
      label: {
        position: 'absolute',
        fontSize: '12px',
        lineHeight: '12px',
        top: '-6px',
        left: '10px',
        background: theme.palette.background.paper,
        padding: '0 6px',
        color: theme.palette.text.secondary,
      },
    }
  })
const ConfigView = (props: any): JSX.Element => {
  const {
    state,
    dispatch,
    addChartConfig,
    putChartConfig,
    deleteChartConfig,
    setDefaultConfig,
    searchDefaultData,
  } = useContext(PerformanceContext)
  const { state: globalState } = useContext(GlobalContext)
  const { state: globalStoreState } = useContext(GlobalStoreContext)

  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  // states & memos
  const [checkedConfig, setCheckedConfig] = useState('Default Report')
  const [openModel, setOpenModel] = useState(false)
  const [deleteConfig, setDeleteConfig] = useState<any>({})
  const [value, setValue] = useState('')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [errorTitle, setErrorTitle] = useState('')

  const openPopover = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-config-popover' : undefined
  // const
  const typeOptions = [
    {
      label: 'Interstitial',
      value: 'interstitial',
    },
    {
      label: 'Banner',
      value: 'banner',
    },
    {
      label: 'Rewarded Video',
      value: 'reward_video',
    },
    {
      label: 'Native Ads',
      value: 'nativeads',
    },
    {
      label: 'App Open',
      value: 'openads',
    },
    {
      label: 'Rewarded Interstitial',
      value: 'rewardinter',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]
  const platformOptions = [
    {
      label: 'iOS',
      value: 'iOS',
    },
    {
      label: 'Android',
      value: 'android',
    },
  ]

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    errorTitle && setErrorTitle('')
    setValue(e.target.value)
  }
  const addConfig = () => {
    if (value.length > 20) {
      setErrorTitle('Maximum title length is 20')
      return
    }
    const temp = globalState.chartConfig.data.find(
      (item: any) => value === item.title
    )
    if (temp) {
      setErrorTitle('Title already exists')
      return
    }
    addChartConfig(value, globalState.chartConfig.data.length)
  }
  const getArray = (arr1: any, arr2: any, key: string) => {
    if (!arr1.length || !arr2.length) {
      return arr1
    }
    const temp: any = []
    arr1.forEach((item: any) => {
      const temp2 = arr2.find((res: any) => {
        return item === res[key]
      })
      temp.push(temp2)
    })
    return temp
  }
  const getGameArray = (arr1: any, arr2: any, key: string) => {
    if (!arr1.length || !arr2.length) {
      return []
    }
    const temp: any = []
    arr1.forEach((item: any) => {
      const temp2 = arr2.find((res: any) => {
        return item === res[key]
      })
      if (temp2) {
        temp.push(temp2)
      }
    })
    return temp
  }
  const checkReport = (item: any) => {
    if (item.title === checkedConfig) {
      handleClose()
      return
    }
    setCheckedConfig(item.title)
    setFilter(item)
    searchDefaultData(item)
    handleClose()
  }
  const setFilter = (data: any) => {
    const filter = data.data.filter
    dispatch({
      type: 'type',
      payload: getArray(filter.ad_types, typeOptions, 'value'),
    })
    dispatch({
      type: 'platform',
      payload: getArray(filter.platform, platformOptions, 'value'),
    })
    dispatch({
      type: 'view',
      payload: data.data.view_by || 'all',
    })
    dispatch({
      type: 'gameSelect',
      payload: getGameArray(filter.app_keys, globalState.gameOption, 'app_key'),
    })
    dispatch({
      type: 'country',
      payload: getArray(filter.country_code, globalState.countryOption, 'code'),
    })
    dispatch({
      type: 'countryChecked',
      payload: filter.country_code,
    })
    dispatch({
      type: 'timeShow',
      payload: data.custom_date,
    })
    if (data.custom_date == 'custom') {
      dispatch({
        type: 'time',
        payload: [data.data.from_date, data.data.to_date],
      })
    } else {
      dispatch({
        type: 'time',
        payload: getDateRangeFromValue(data.custom_date),
      })
    }
  }
  useEffect(() => {
    if (!state.configTitleLoading) {
      setAnchorEl(null)
    }
  }, [state.configTitleLoading])
  useEffect(() => {
    if (!state.configLoading) {
      setAnchorEl(null)
    }
  }, [state.configLoading])
  useEffect(() => {
    if (!state.deleteConfigLoading) {
      setOpenModel(false)
      setAnchorEl(null)
      if (deleteConfig.title === checkedConfig) {
        setCheckedConfig('Default Report')
        setFilter(globalState.chartConfig.data[0])
        searchDefaultData(globalState.chartConfig.data[0])
      }
    }
    // eslint-disable-next-line
  }, [state.deleteConfigLoading])
  useEffect(() => {
    if (!globalState.chartConfig?.data?.length) return
    if (!globalState.chartConfig?.noUpdate) {
      let index: number = globalState.chartConfig.default_selected
      if (index > globalState.chartConfig.data?.length - 1) {
        index = 0
      }
      if (!globalState.chartConfig?.data[index]) return
      if (globalStoreState.fromWelcome) {
        index = 0
      }
      setCheckedConfig(globalState.chartConfig.data[index].title)
      if (globalStoreState.performanceData.filter) {
        Object.keys(globalStoreState.performanceData.filter).forEach(
          (item: string) => {
            dispatch({
              type: item,
              payload: globalStoreState.performanceData.filter[item],
            })
          }
        )
        Object.keys(globalStoreState.performanceData)
          .filter((item: string) => item !== 'filter')
          .forEach((item: string) => {
            dispatch({
              type: item,
              payload: globalStoreState.performanceData[item],
            })
          })
        return
      }
      setFilter(globalState.chartConfig.data[index])
      searchDefaultData(globalState.chartConfig.data[index])
    } else {
      if (globalState.chartConfig?.index !== undefined) {
        checkReport(
          globalState.chartConfig.data[globalState.chartConfig?.index]
        )
      }
    }
    // eslint-disable-next-line
  }, [globalState.chartConfig.data])
  // returns
  return (
    <>
      <Box
        className={classes.borderSelectBox}
        aria-describedby={id}
        onClick={openPopover}
      >
        <Box className={classes.label}>Saved Reports</Box>
        <Box display="flex" width="100%">
          <Box
            sx={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: theme.palette.text.secondary,
            }}
          >
            {checkedConfig}
          </Box>
        </Box>
        <SVGIcon.TriangleDownSVG
          color={
            theme.palette.mode === 'dark'
              ? 'rgb(255, 255, 255)'
              : 'rgba(0, 0, 0, 0.54)'
          }
          style={{
            marginTop: '9px',
            transform: anchorEl ? 'rotate(180deg)' : '',
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ overflowY: 'inherit' }}
        className={classes.chartDefaultPopover}
      >
        <Box
          position="relative"
          boxSizing="border-box"
          width="270px"
          padding="16px 0"
        >
          {state.configLoading && (
            <Box className={classes.loadingMask}>
              <LoadingComponent />
            </Box>
          )}
          {!!globalState.chartConfig?.data?.length && (
            <Box>
              {globalState.chartConfig.data.map((item: any, index: number) => (
                <Box
                  className={clsx({
                    [classes.chartConfigList]: true,
                    [classes.chartConfigCheckedList]:
                      item.title === checkedConfig,
                  })}
                  key={index}
                >
                  <Box
                    width="178px"
                    lineHeight="34px"
                    onClick={() => {
                      checkReport(item)
                    }}
                  >
                    {item.title}
                  </Box>
                  <Box width="60px" padding="8px 0">
                    <Tooltip
                      title="Set report as default"
                      placement="top"
                      arrow
                    >
                      {globalState.chartConfig.default_selected === index ? (
                        <StarIcon
                          style={{
                            fontSize: '16px',
                            margin: '0 2px',
                            verticalAlign: 'middle',
                          }}
                        />
                      ) : (
                        <StarOutlineIcon
                          onClick={() => {
                            setDefaultConfig(index)
                          }}
                          style={{
                            fontSize: '16px',
                            cursor: 'pointer',
                            margin: '0 2px',
                            verticalAlign: 'middle',
                          }}
                        />
                      )}
                    </Tooltip>
                    {!!index && (
                      <>
                        <Tooltip
                          title="Save report options"
                          placement="top"
                          arrow
                        >
                          <SaveIcon
                            onClick={() => {
                              putChartConfig(item.title, index)
                            }}
                            style={{
                              fontSize: '16px',
                              cursor: 'pointer',
                              margin: '0 2px',
                              verticalAlign: 'middle',
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Delete report" placement="top" arrow>
                          <DeleteIcon
                            onClick={() => {
                              setDeleteConfig({
                                index,
                                title: item.title,
                              })
                              setOpenModel(true)
                            }}
                            style={{
                              fontSize: '16px',
                              cursor: 'pointer',
                              margin: '0 2px',
                              verticalAlign: 'middle',
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          <Box
            className={classes.addDefaultButton}
            onClick={() => {
              setValue('')
              setErrorTitle('')
              dispatch({ type: 'openConfigTitle', payload: true })
            }}
          >
            Save Current Report
          </Box>
        </Box>
      </Popover>
      <Dialog
        open={state.openConfigTitle}
        onClose={() => {
          dispatch({ type: 'openConfigTitle', payload: false })
        }}
      >
        <Box className={classes.root}>
          <Box>Report title:</Box>
          <Box marginTop="8px">
            <TextField
              className={classes.formInputSearch}
              placeholder="Title"
              variant="outlined"
              value={value}
              onChange={inputChange}
              error={!!errorTitle}
              helperText={errorTitle}
            />
          </Box>
          <Box display="flex" marginTop="24px">
            <Button
              color="primary"
              variant="contained"
              style={{ marginRight: '24px', flex: 1 }}
              disabled={value.length === 0}
              onClick={addConfig}
            >
              {state.configTitleLoading ? <LoadingBtn /> : 'Save'}
            </Button>
            <Box
              className={classes.closeButton}
              onClick={() => {
                dispatch({ type: 'openConfigTitle', payload: false })
              }}
            >
              Close
            </Box>
          </Box>
        </Box>
      </Dialog>
      <DeleteDialog
        openModel={openModel}
        onClose={() => {
          setOpenModel(false)
        }}
        onConfirm={() => {
          deleteChartConfig(deleteConfig.index)
        }}
        title={deleteConfig.title}
        loading={state.deleteConfigLoading}
        deleteButtonText="Delete Report"
      />
    </>
  )
}

export default ConfigView
