import {
  Drawer,
  Box,
  Link,
  useTheme,
  Theme,
  Switch,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  PlaylistAddCheckOutlined as PlaylistAddCheckOutlinedIcon,
  VideoLibrary as VideoLibraryIcon,
  VideogameAsset as VideogameAssetIcon,
} from '@material-ui/icons'
import { useCallback, useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { GlobalStoreContext } from 'src/context/globalStore'
import { GlobalContext } from 'src/context/global'
import { SVGIcon } from 'src/images'
import { OpenInNewIconView } from 'src/components'
import { Userpilot } from 'userpilot'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        display: 'flex',
        zIndex: 1100,
      },
      containerDesktop: {
        display: 'flex',
        width: '232px',
        minHeight: '100%',
        zIndex: 1100,
      },
      root: {
        position: 'relative',
        width: '232px',
        background: theme.palette.background.paper,
        boxSizing: 'border-box',
      },
      rootDesktop: {
        width: '232px',
        background: theme.palette.background.paper,
        boxSizing: 'border-box',
        position: 'fixed',
        left: 0,
        top: 0,
        height: '100%',
        overflow: 'auto',
      },
      topLogo: {
        position: 'relative',
        boxSizing: 'border-box',
        height: '68px',
        padding: '13px 0',
        textAlign: 'center',
      },
      menuName: {
        position: 'relative',
        height: '56px',
        lineHeight: '56px',
        boxSizing: 'border-box',
        paddingLeft: '33px',
        fontSize: '14px',
        color: '#7487AA',
        cursor: 'pointer',
        userSelect: 'none',
      },
      childrenMenuName: {
        boxSizing: 'border-box',
        padding: '16px 0 16px 44px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#7487AA',
        cursor: 'pointer',
        userSelect: 'none',
        borderTop: '1px solid transparent',
        borderBottom: '1px solid transparent',
      },
      active: {
        fontWeight: 500,
        color: '#1E96FC',
        background: 'rgba(30, 150, 252, 0.06)',
        borderTop: '1px solid #1E96FC',
        borderBottom: '1px solid #1E96FC',
      },
      openIcon: {
        position: 'absolute',
        fontSize: '10px !important',
        top: '50%',
        color: '#7487AA',
      },
      chooseTab: {
        background: theme.palette.custom.sideBarBg,
        color: theme.palette.primary.main,
      },
      chooseAppearance: {
        color: theme.palette.primary.main,
      },
      themeItemBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.custom.appearanceIconColor,
        border: `1px solid ${theme.palette.custom.appearanceIconBg}`,
        borderRadius: 8,
        width: 58,
        height: 66,
        cursor: 'pointer',
      },
      themeItemCaption: {
        userSelect: 'none',
        transform: 'scale(.7)',
        marginTop: '4px !important',
      },
      themeItemBoxActive: {
        color: theme.palette.custom.appearanceIconActiveColor,
        borderColor: theme.palette.custom.appearanceIconBg,
        backgroundColor: theme.palette.custom.appearanceIconBg,
      },

      icon: {
        width: '16px',
        height: '16px',
        marginRight: '16px',
        verticalAlign: 'middle',
        transform: 'translateY(-1px)',
      },
      arrowIcon: {
        position: 'absolute',
        fontSize: '14px !important',
        right: '26px',
        top: '50%',
        transform: 'translateY(-50%) rotate(90deg)',
        color: '#7487AA',
      },
      open: {
        transform: 'translateY(-50%) rotate(-90deg)',
      },
      line: {
        height: '1px',
        margin: '0 16px',
        background: theme.palette.mode === 'light' ? '#E5E8EB' : '#484644',
      },
    }
  })
const menuMetaList = [
  {
    label: 'Performance',
    path: '/dash/performance/revenue',
    group: 1,
    groupLabel: 'Analytics',
    IconComponent: SVGIcon.overviewSVG,
  },
  {
    label: 'User Activity',
    path: '/dash/user-activity/arpdau',
    group: 1,
    groupLabel: 'Analytics',
    IconComponent: SVGIcon.CohortsSVG,
  },
  {
    label: 'Gameplay Insights',
    path: '/dash/gameplay-insights/retention',
    group: 1,
    groupLabel: 'Analytics',
    IconComponent: SVGIcon.GameplayInsightsSVG,
  },
  {
    label: 'Ad Engagement',
    path: '/dash/engagement/rate',
    group: 1,
    groupLabel: 'Analytics',
    IconComponent: SVGIcon.EngagementSVG,
  },
  {
    label: 'Ad Placement',
    path: '/dash/ad-placement/ratio_revenue',
    group: 1,
    groupLabel: 'Analytics',
    IconComponent: SVGIcon.adPlacementSVG,
  },
  {
    label: 'Games',
    path: '/dash/games',
    group: 2,
    groupLabel: 'Management',
    IconComponent: VideogameAssetIcon,
    isIcon: true,
  },
  {
    label: 'Testing',
    path: '/dash/testing',
    group: 2,
    groupLabel: 'Management',
    IconComponent: SVGIcon.testingSVG,
  },
  {
    label: 'Finance',
    path: '/dash/finance',
    group: 2,
    groupLabel: 'Management',
    IconComponent: SVGIcon.financeSVG,
  },
  {
    label: 'Users',
    path: '/dash/users',
    group: 2,
    groupLabel: 'Management',
    IconComponent: SVGIcon.memberSVG,
  },
  {
    label: 'Action Items',
    path: '/dash/ad-management',
    group: 2,
    groupLabel: 'Management',
    IconComponent: PlaylistAddCheckOutlinedIcon,
    isIcon: true,
  },
  {
    label: 'Documentation',
    path: '',
    group: 3,
    groupLabel: 'Support',
    IconComponent: SVGIcon.DocumentationSVG,
    openInNew: true,
    click: () => {
      window.open(
        'https://developers.yodo1.com/docs/getting-started/the-basics/easy-steps-for-release-with-mas/'
      )
    },
  },
  {
    label: 'GCA',
    path: '',
    group: 3,
    groupLabel: 'Support',
    IconComponent: VideoLibraryIcon,
    openInNew: true,
    click: () => {
      window.open('https://yodo1.thinkific.com/')
    },
  },
]
const arr: any = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    IconComponent: SVGIcon.DashboardSVG,
  },
  {
    label: 'Analytics',
    IconComponent: SVGIcon.reportSVG,
    open: false,
    children: [],
  },
  {
    label: 'Management',
    IconComponent: SVGIcon.managementSVG,
    open: false,
    children: [],
  },
  {
    label: 'Support',
    IconComponent: SVGIcon.supportSVG,
    open: false,
    children: [],
  },
]
interface SideBarProps {
  className?: string
  style?: string
}

const View = (props: SideBarProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const isDesktop = useMediaQuery('(min-width:1280px)')
  const { state, onMobileMenuClose } = useContext(GlobalStoreContext)
  const {
    state: globalState,
    changeLocalTheme,
    personalInfoChange,
  } = useContext(GlobalContext)
  const [menuMetaListShow, setMenuMetaListShow] = useState(arr)
  const [openIndex, setOpenIndex] = useState<any>([])
  const [activeIndex, setActiveIndex] = useState<any>(null)
  const [userData, setUserData] = useState({
    avatar: globalState.userInfo?.avatar,
    owner_tel: globalState.userInfo?.owner_tel,
    name: globalState.userInfo?.name, // TODO: fix & add a loading maybe
    theme: globalState.userInfo?.theme || 0,
  })
  const { mobileMenuOpen } = state
  // handlers
  const isActiveMenu = useCallback(
    (path: string) => {
      const page = location.pathname.split('/').pop() || ''
      if (
        path === '/dash/gameplay-insights/retention' &&
        [
          'sessions',
          'retention',
          'churn-rate',
          'session-length',
          'stickiness',
          'installs',
        ].includes(page)
      ) {
        return true
      }
      if (
        path === '/dash/engagement/rate' &&
        ['rate', 'deu', 'imp_deu'].includes(page)
      ) {
        return true
      }
      if (path === '/dash/performance/revenue') {
        if (location.pathname.split('/')[2] === 'performance') {
          return true
        }
      }
      if (path === '/dash/user-activity/arpdau') {
        if (location.pathname.split('/')[2] === 'user-activity') {
          return true
        }
      }
      if (path === '/dash/engagement/rate') {
        if (location.pathname.split('/')[2] === 'engagement') {
          return true
        }
      }
      if (path === '/dash/games') {
        if (location.pathname.split('/')[2] === 'games') {
          return true
        }
      }
      if (path === '/dash/ad-placement/ratio_revenue') {
        if (location.pathname.split('/')[2] === 'ad-placement') {
          return true
        }
      }
      return path === location.pathname
    },
    [location.pathname]
  )
  const sideBarList = {
    Welcome: 'welcome_page',
    Dashboard: 'dashboard_page',
    Finance: 'finance_page',
    Games: 'games_page',
    Users: 'roles_page',
    Testing: 'testing_page',
    'Ad Placement': 'ad_placement_page',
    Performance: 'overview_page',
    'User Activity': 'user_activity_page',
    'Gameplay Insights': 'gameplay_insights_page',
    'Ad Engagement': 'engagement_page',
    'Action Items': 'ad_manager_page',
  }
  const tempList = ['1', '2', '3', '4']
  const openGroup = (index: string | null) => {
    let temp: any = []
    if (openIndex.includes(index)) {
      openIndex.forEach((item: any) => {
        if (item !== index) {
          temp.push(item)
        }
      })
    } else {
      temp = [...openIndex, index]
    }
    setOpenIndex(temp)
  }
  const switchChange = (e: any) => {
    let mode = 1
    if (userData.theme === 1) {
      mode = 2
    }
    setUserData(response => ({
      ...response,
      theme: mode,
    }))

    changeLocalTheme(mode)
    personalInfoChange(
      {
        theme: mode,
      },
      true
    )
  }
  // effects
  useEffect(() => {
    arr.forEach((element: any) => {
      element.children = []
    })
    menuMetaList.forEach(item => {
      if (
        !sideBarList[item.label] ||
        globalState.accountAuthority[sideBarList[item.label]] !== 0
      ) {
        if (sideBarList[item.label] === 'ad_manager_page') {
          if (globalState.accountAuthority[sideBarList[item.label]] !== 2) {
            return
          }
        }
        arr[item.group].children.push(item)
        if (location.pathname === item.path) {
          setOpenIndex([item.groupLabel])
          setActiveIndex(item.group)
        }
      }
    })
    const temp: any = []
    arr.forEach((item: any, index: number) => {
      if (!index) {
        if (globalState.accountAuthority?.dashboard_page !== 0) {
          temp.push(item)
        }
      } else {
        if (item.children.length) {
          temp.push(item)
        }
      }
    })

    const pathName = location.pathname.split('/')[2]
    if (
      [
        'performance',
        'user-activity',
        'gameplay-insights',
        'engagement',
        'ad-placement',
      ].includes(pathName)
    ) {
      setOpenIndex(['Analytics'])
      setActiveIndex(1)
    }
    if (pathName === 'games') {
      setOpenIndex(['Management'])
      setActiveIndex(2)
    }
    setMenuMetaListShow(temp)
    if (
      location.pathname === '/dashboard' ||
      ['performance', 'user-activity'].includes(pathName)
    ) {
      if (globalState.accountAuthority.dashboard_page === 0) {
        navigate('/dash/games')
        setOpenIndex(['Management'])
        setActiveIndex(2)
      }
    }
    // eslint-disable-next-line
  }, [globalState.accountAuthority])
  useEffect(() => {
    if (globalState.userInfo) {
      const { avatar, theme, name, developer_key, email, owner_tel } =
        globalState.userInfo
      let tempTheme = theme
      if (theme === 0) {
        if (prefersDarkMode) {
          tempTheme = 2
        } else {
          tempTheme = 1
        }
      }
      setUserData(() => ({
        avatar,
        owner_tel,
        theme: tempTheme,
        name,
      }))

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const apm: any = window.apm
      if (process.env.REACT_APP_ENV !== 'local' && apm) {
        apm.setUserContext({ id: developer_key, username: name, email })
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const heap: any = window.heap
      if (process.env.REACT_APP_ENV === 'product' && heap) {
        heap.identify && heap.identify(developer_key)
        heap.addUserProperties &&
          heap.addUserProperties({ id: developer_key, username: name, email })
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const gtag: any = window.gtag
      if (process.env.REACT_APP_ENV === 'product' && gtag) {
        gtag('config', 'G-M96P3537G4', { user_id: developer_key })
        gtag('set', 'user_properties', { developer_key: developer_key })
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const clarity: any = window.clarity
      if (process.env.REACT_APP_ENV === 'product' && clarity) {
        clarity('identify', developer_key)
      }
    }
    // eslint-disable-next-line
  }, [globalState.userInfo])
  // welcome页面跳转后打开left side bar
  useEffect(() => {
    if (state.fromWelcome) {
      !openIndex.includes('Analytics') && openGroup('Analytics')
    }
    // eslint-disable-next-line
  }, [state.fromWelcome])
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'test') {
      Userpilot.reload()
    }
    // eslint-disable-next-line
  }, [location.pathname])
  // views
  const childrenMenuView = (meta: any, i: number) => (
    <>
      {openIndex.includes(meta.label) && (
        <Box>
          {meta.children.map((childrenMeta: any, index: number) => (
            <Box
              key={index}
              className={clsx([
                {
                  [classes.active]: isActiveMenu(childrenMeta.path),
                },
                classes.childrenMenuName,
              ])}
              id={'sidebar-' + childrenMeta.label.split(' ').join('')}
              onClick={() => {
                if (childrenMeta.click) {
                  childrenMeta.click()
                } else {
                  navigate(childrenMeta.path)
                }
                setActiveIndex(i)
                onMobileMenuClose()
              }}
              sx={isActiveMenu(childrenMeta.path) ? { color: '#1E96FC' } : {}}
            >
              <childrenMeta.IconComponent
                color={isActiveMenu(childrenMeta.path) ? '#1E96FC' : ''}
                style={{
                  verticalAlign: 'middle',
                  marginRight: '16px',
                  transform: 'translateY(-2px)',
                  color: childrenMeta.isIcon
                    ? isActiveMenu(childrenMeta.path)
                      ? '#1E96FC'
                      : '#7487AA'
                    : '',
                }}
              />

              <span>
                {childrenMeta.label}
                {childrenMeta.openInNew && <OpenInNewIconView />}
                {childrenMeta.labelIcon && (
                  <childrenMeta.labelIcon
                    fontSize="14px"
                    verticalAlign="text-bottom"
                  />
                )}
              </span>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
  const AntSwitch = styled(Switch)(() => ({
    width: '44px !important',
    height: '24px !important',
    padding: '0 !important',
    borderRadius: '12px',
    margin: '-2px 8px 0',
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      color: theme.palette.custom.appearanceIconActiveColor,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: theme.palette.custom.appearanceIconActiveColor,
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,.35)'
              : 'rgba(0,0,0,.25)',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 18,
      height: 18,
      borderRadius: 9,
      marginTop: '1.5px',
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }))
  const navBarView = (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      className={isDesktop ? classes.rootDesktop : classes.root}
    >
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        sx={{ overflowX: 'hidden' }}
      >
        <Box className={classes.topLogo}>
          <Link
            onClick={() => {
              navigate('/dashboard')
            }}
          >
            <SVGIcon.logoSVG
              color={theme.palette.mode === 'light' ? '' : '#FFF'}
              style={{ verticalAlign: 'middle' }}
            />
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          justifyContent="space-between"
        >
          <Box>
            {!globalState.userInfoLoadingSideBar
              ? menuMetaListShow.map((meta: any, index: number) => (
                  <Box key={index}>
                    <>
                      <Box
                        className={clsx({
                          [classes.menuName]: true,
                          [classes.active]:
                            meta.path && isActiveMenu(meta.path),
                        })}
                        id={'sidebar-' + meta.label.split(' ').join('')}
                        onClick={() => {
                          if (meta.path) {
                            navigate(meta.path)
                            onMobileMenuClose()
                            setActiveIndex(null)
                          } else {
                            openGroup(meta.label)
                          }
                        }}
                      >
                        <meta.IconComponent
                          color={
                            activeIndex === index || isActiveMenu(meta.path)
                              ? '#1E96FC'
                              : ''
                          }
                          style={{
                            verticalAlign: 'middle',
                            marginRight: '16px',
                            transform: 'translateY(-2px)',
                          }}
                        />
                        <span
                          style={
                            activeIndex === index
                              ? {
                                  color: '#1E96FC',
                                }
                              : {}
                          }
                        >
                          {meta.label}
                        </span>
                        {!!meta.children?.length && (
                          <ArrowForwardIosIcon
                            className={clsx([
                              classes.arrowIcon,
                              openIndex.includes(meta.label)
                                ? classes.open
                                : '',
                            ])}
                            style={
                              activeIndex === index
                                ? {
                                    color: '#1E96FC',
                                  }
                                : {}
                            }
                          />
                        )}
                      </Box>
                      {!!meta.children?.length && childrenMenuView(meta, index)}
                      {index <= 2 && <Box className={classes.line} />}
                    </>
                  </Box>
                ))
              : tempList.map(index => (
                  <Box
                    key={index}
                    className={classes.menuName}
                    paddingTop="18px"
                  >
                    <Skeleton variant="rectangular" width={146} height={20} />
                  </Box>
                ))}
          </Box>
          <Box
            width="100%"
            display="flex"
            lineHeight="24px"
            justifyContent="center"
            margin="24px 0"
          >
            <>
              <Box
                fontSize="12px"
                lineHeight="24px"
                color={theme.palette.custom.appearanceIconActiveColor}
                style={{ transform: 'scale(0.8)' }}
              >
                LIGHT
              </Box>
              <SVGIcon.LightSVG
                style={{ marginTop: '2px' }}
                color={theme.palette.custom.appearanceIconActiveColor}
              />
              <AntSwitch
                checked={userData.theme === 2}
                onChange={switchChange}
              />
              <SVGIcon.DarkSVG style={{ marginTop: '2px' }} color={'#7487AA'} />
              <Box
                fontSize="12px"
                lineHeight="24px"
                style={{ transform: 'scale(0.8)' }}
                color={'#7487AA'}
              >
                DARK
              </Box>
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  )

  // return
  return (
    <div className={isDesktop ? classes.containerDesktop : classes.container}>
      {/* sidebar右侧分割线 */}
      {isDesktop && (
        <Box
          sx={{
            position: 'fixed',
            width: '1px',
            height: '100%',
            top: '68px',
            left: '232px',
            background: theme.palette.mode === 'light' ? '#E5E8EB' : '#484644',
          }}
        />
      )}
      <Box>
        <Drawer
          anchor="left"
          onClose={onMobileMenuClose}
          open={mobileMenuOpen}
          variant="temporary"
        >
          {navBarView}
        </Drawer>
      </Box>
      <Box sx={{ display: isDesktop ? 'block' : 'none' }}>{navBarView}</Box>
    </div>
  )
}

export default View
