import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  Dispatch,
} from 'react'
import { getCohortsApi } from 'src/api/cohorts'
import { GlobalContext } from '../global'
import { GlobalStoreContext } from '../globalStore'
import { transformUTC0ToLocalTime, numFixed } from 'src/utils'
import dayjs from 'dayjs'

type Props = {
  gameOption: any
  countryOption: any
  gameListObject: any
  countryListObject: any
  game: any
  country: any
  time: any
  platform: string
  userData: any
  retentionsAverage: any
  thirtyUserData: any
  retentionTrend: any
  churnLoading: boolean
  churnThirtyLoading: boolean
  firstLoad: boolean
  resetIndex: number
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  gameOption: [],
  gameListObject: {},
  countryOption: [],
  countryListObject: {},
  game: '',
  country: '',
  time: dayjs(new Date()).add(-30, 'day'),
  platform: '',
  userData: [],
  retentionsAverage: [],
  thirtyUserData: [],
  retentionTrend: {
    trend: '-',
    value: '-',
  },
  churnLoading: false,
  churnThirtyLoading: false,
  firstLoad: true,
  resetIndex: 0,
}
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'retentionTrend':
      const disabled = action.payload?.tabDisabled || false
      const untilTime = action.payload?.untilTime || null
      if (!action.payload?.num) {
        return {
          ...prevState,
          retentionTrend: {
            value: '0%',
            trend: 'up',
            disabled,
            untilTime,
          },
        }
      }
      let trend = 'up'
      if (action.payload >= 0) {
        trend = 'up'
      } else {
        trend = 'down'
      }
      const retentionTrend = {
        value: numFixed(Math.abs(action.payload?.num || 0)) + '%',
        trend,
        disabled,
        untilTime,
      }
      return { ...prevState, retentionTrend }
    case 'time':
    case 'country':
    case 'game':
    case 'gameOption':
    case 'gameListObject':
    case 'countryOption':
    case 'countryListObject':
    case 'platform':
    case 'userData':
    case 'retentionsAverage':
    case 'thirtyUserData':
    case 'churnLoading':
    case 'churnThirtyLoading':
    case 'firstLoad':
    case 'resetIndex':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
  search: () => void
}

export const ChurnRateContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
  search: () => {},
})
export const ChurnRateContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: globalState, errorCatch } = useContext(GlobalContext)
  const { state: globalStoreState, dispatch: globalStoreDispatch } =
    useContext(GlobalStoreContext)

  // handlers

  // effects
  // 获取总的游戏列表
  useEffect(() => {
    dispatch({ type: 'gameListObject', payload: globalState.gameListObject })
    dispatch({ type: 'gameOption', payload: globalState.gameOption })
    if (globalState.gameOption.length) {
      dispatch({ type: 'game', payload: globalState.gameOption[0] })
    }
    // eslint-disable-next-line
  }, [globalState.gameListObject])
  // 获取国家列表
  useEffect(() => {
    dispatch({
      type: 'countryListObject',
      payload: globalState.countryListObject,
    })
    dispatch({ type: 'countryOption', payload: globalState.countryOption })
    // eslint-disable-next-line
  }, [globalState.countryListObject])
  const getCohorts = () => {
    const params = {
      app_key: state.game.app_key,
      country_code: state.country.code,
      from_date: transformUTC0ToLocalTime(state.time, 'YYYY-MM-DD'),
      to_date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      duration: 30,
    }
    dispatch({ type: 'churnLoading', payload: true })
    getCohortsApi({ ...params, type: 'churns' })
      .then((res: any) => {
        dispatch({ type: 'userData', payload: res.data.data })
        dispatch({ type: 'retentionsAverage', payload: res.data.average })
        globalStoreDispatch({
          type: 'churnRateData',
          payload: {
            userData: res.data.data,
            retentionsAverage: res.data.average,
          },
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'churnRateData',
          payload: {
            userData: [],
            retentionsAverage: [],
          },
        })
      })
      .finally(() => {
        dispatch({ type: 'churnLoading', payload: false })
      })
  }
  const getThirtyCohorts = () => {
    const params: any = {
      app_key: state.game.app_key,
      country_code: state.country.code,
      from_date: transformUTC0ToLocalTime(state.time, 'YYYY-MM-DD'),
      to_date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      duration: 30,
      benchmark: true,
    }
    globalStoreDispatch({
      type: 'churnRateData',
      payload: {
        filter: {
          game: state.game,
          country: state.country,
          time: state.time,
        },
      },
    })
    // 存储churn数据
    dispatch({ type: 'churnThirtyLoading', payload: true })
    getCohortsApi({ ...params, type: 'churns' })
      .then((res: any) => {
        dispatch({
          type: 'retentionTrend',
          payload: { num: res.data.relative_change || 0 },
        })
        dispatch({ type: 'thirtyUserData', payload: { ...res.data } })
        globalStoreDispatch({
          type: 'churnRateData',
          payload: {
            retentionTrend: { num: res.data.relative_change || 0 },
            thirtyUserData: { ...res.data },
          },
        })
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 503 &&
          err?.response?.data?.code === 'trino_migration'
        ) {
          let untilTime = null
          if (err?.response?.data?.extra?.after) {
            untilTime = transformUTC0ToLocalTime(
              err?.response?.data?.extra?.after,
              'Do MMM YYYY hh:mm a'
            )
          }
          dispatch({
            type: 'retentionTrend',
            payload: { num: 0, tabDisabled: true, untilTime },
          })
          globalStoreDispatch({
            type: 'churnRateData',
            payload: {
              retentionTrend: { num: 0, tabDisabled: true, untilTime },
              thirtyUserData: [],
            },
          })
        } else {
          errorCatch(err)
          globalStoreDispatch({
            type: 'churnRateData',
            payload: {
              retentionTrend: { num: 0 },
              thirtyUserData: [],
            },
          })
        }
      })
      .finally(() => {
        dispatch({ type: 'churnThirtyLoading', payload: false })
      })
  }
  const search = () => {
    if (state?.game.app_key) {
      getCohorts()
      getThirtyCohorts()
    }
  }

  useEffect(() => {
    if (state.game) {
      // firstLoad防止game变化时每次都取filter中的内容
      if (state.firstLoad && globalStoreState.churnRateData.filter) {
        Object.keys(globalStoreState.churnRateData.filter).forEach(
          (item: string) => {
            dispatch({
              type: item,
              payload: globalStoreState.churnRateData.filter[item],
            })
          }
        )
        Object.keys(globalStoreState.churnRateData)
          .filter((item: string) => item !== 'filter')
          .forEach((item: string) => {
            dispatch({
              type: item,
              payload: globalStoreState.churnRateData[item],
            })
          })
      } else {
        search()
      }
      setTimeout(() => {
        dispatch({
          type: 'firstLoad',
          payload: false,
        })
      })
    }
    // eslint-disable-next-line
  }, [state.game])
  useEffect(() => {
    if (state.resetIndex) {
      search()
    }
    // eslint-disable-next-line
  }, [state.resetIndex])
  // returns
  return (
    <ChurnRateContext.Provider
      value={{
        state,
        dispatch,
        search,
      }}
    >
      {children}
    </ChurnRateContext.Provider>
  )
}
