import { useParams } from 'react-router-dom'
import { Box, useTheme, Theme, Tabs, Tab, Skeleton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useState, useContext, useEffect } from 'react'
import { GameManageContext } from 'src/context/gameManage'
import HTMLReactParser from 'html-react-parser'
import NoData from 'src/components/noData'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        background: theme.palette.background.paper,
      },
      root: {
        width: '80vw',
        maxWidth: '100%',
        padding: '40px',
        boxSizing: 'border-box',
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
        paddingRight: '40px',
        lineHeight: '22px',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '-3px',
        cursor: 'pointer',
      },
      content: {
        fontSize: '14px',
        color: theme.palette.text.secondary,
        lineHeight: '18px',
      },
      closeButton: {
        display: 'inline-block',
        width: '168px',
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        borderRadius: '6px',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.custom.dialogButton}`,
        color: theme.palette.custom.dialogButton,
        marginTop: '24px',
      },
      gameKeyLabel: {
        fontSize: '12px',
        color: theme.palette.custom.tableHead,
        margin: '16px 0 8px',
      },
      gameKeyValue: {
        fontSize: '14px',
        color:
          theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.6)'
            : '#102542',
      },
      tab: {
        '& .MuiTabs-flexContainer': {
          borderBottom: `2px solid ${
            theme.palette.mode === 'light'
              ? '#E1E4E6'
              : 'rgba(121, 126, 135, 0.3)'
          }`,
          '& .MuiButtonBase-root': {
            fontWeight: 500,
          },
        },
      },
    }
  })
const ManageView = () => {
  const params: any = useParams()
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, getIssueList } = useContext(GameManageContext)
  const [value, setValue] = useState(0)
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue)
  }
  useEffect(() => {
    getIssueList(params.app_key)
    // eslint-disable-next-line
  }, [])
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>Issue Details</Box>
      <Box>
        <Box className={classes.content}>
          <Box>
            <Box className={classes.gameKeyLabel}>
              APP
              <Box component="span" marginLeft="16px" fontSize="12px">
                app_key:{' '}
              </Box>
              <Box component="span" fontSize="12px" marginLeft="8px">
                {params.app_key}
              </Box>
            </Box>
            <Box className={classes.gameKeyValue}>
              {state.loading ? (
                <Skeleton variant="text" width="200px" />
              ) : (
                state.appDetail.name
              )}
            </Box>
          </Box>
          <>
            {state.issueLoading ? (
              <Skeleton
                variant="rectangular"
                height="200px"
                style={{ marginTop: '12px' }}
              />
            ) : (
              <>
                {state.issueList.length ? (
                  <>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      className={classes.tab}
                    >
                      {state.issueList.map((item: any, index: number) => (
                        <Tab
                          disableRipple
                          label={'ISSUE ' + (index + 1)}
                          value={index}
                          key={index}
                        />
                      ))}
                    </Tabs>
                    <Box className={classes.gameKeyValue} marginTop="16px">
                      {HTMLReactParser(state.issueList[value]?.content || '')}
                    </Box>
                  </>
                ) : (
                  <NoData desc="You have no issues at the moment" />
                )}
              </>
            )}
          </>
        </Box>
      </Box>
    </Box>
  )
}

export default ManageView
