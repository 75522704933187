import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  Dispatch,
} from 'react'
import { useTheme } from '@material-ui/core'
import { UserActivityContext } from './index'
import { GlobalContext } from '../global'

type Props = {
  data: any
  gameListObject: any
  countryListObject: any
  networkObject: any
  chartType: string
  middleChartLoading: boolean
  holdData: any
  chartLine: string
  dashboard_benchmark: boolean
  chartList: any
  checkedChartLine: any
  view: string
  countryIsFilter: boolean
  newChartType: string
  commentsEvent: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  data: {},
  holdData: {},
  gameListObject: {},
  countryListObject: {},
  networkObject: {},
  chartType: 'revenue',
  middleChartLoading: false,
  chartLine: '#dfe6f1',
  dashboard_benchmark: false,
  chartList: [
    {
      label: 'All',
      value: 'All',
      color: '#FF8E00',
    },
  ],
  checkedChartLine: [],
  view: 'all',
  countryIsFilter: false,
  newChartType: 'Graph',
  commentsEvent: {},
}
const type = {
  interstitial: 'Interstitial',
  banner: 'Banner',
  reward_video: 'Rewarded Video',
  nativeads: 'Native Ads',
  openads: 'App Open',
  rewardinter: 'Rewarded Interstitial',
  other: 'Other',
  android: 'Android',
  all: 'All',
}
const colorList = ['#FF8E00', '#90cc75', '#f07474', '#5a70b5']
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'gameListObject':
    case 'countryListObject':
    case 'networkObject':
    case 'chartType':
    case 'middleChartLoading':
    case 'chartLine':
    case 'holdData':
    case 'dashboard_benchmark':
    case 'checkedChartLine':
    case 'view':
    case 'countryIsFilter':
    case 'newChartType':
    case 'commentsEvent':
      return { ...prevState, [action.type]: action.payload }
    case 'data':
      const allData = action.payload.data
      const keys = Object.keys(allData)
      if (!keys.length) {
        return {
          ...prevState,
          data: {},
          chartList: [
            {
              label: 'All',
              value: 'All',
              color: '#FF8E00',
            },
          ],
          checkedChartLine: [],
        }
      }
      const labels = allData[keys[0]][prevState.chartType]?.days || 0
      const echartData: any = []
      const chartList: any = []
      const checkedChartLine: any = []
      keys.forEach((item: any, i: number) => {
        const color: string =
          colorList[i] ||
          prevState.countryListObject[item]?.color ||
          prevState.gameListObject[item]?.color ||
          '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
        const label =
          prevState.gameListObject[item]?.name ||
          prevState.countryListObject[item]?.name ||
          prevState.networkObject[item] ||
          type[item] ||
          item
        echartData.push({
          name: label,
          type: 'line',
          smooth: true,
          itemStyle: { color: color },
          data: allData[item][prevState.chartType]?.values || [],
        })
        chartList.push({
          label,
          value: label,
          color,
          num: allData[item][prevState.chartType]?.summary || 0,
        })
        checkedChartLine.push(label)
      })
      if (
        prevState.chartType === 'impdau' &&
        action.payload.benchmarkData[prevState.chartType] &&
        prevState.dashboard_benchmark
      ) {
        echartData.push({
          name: 'Benchmark for RV',
          type: 'line',
          smooth: true,
          itemStyle: { color: '#1E96FC' },
          data: action.payload.benchmarkData[prevState.chartType],
        })
        chartList.push({
          label: 'Benchmark for RV',
          value: 'Benchmark for RV',
          color: '#1E96FC',
          num: 0,
        })
        checkedChartLine.push('Benchmark for RV')
      }
      return {
        ...prevState,
        data: {
          days: labels,
          data: echartData,
        },
        chartList: chartList.sort((a: any, b: any) => b.num - a.num),
        checkedChartLine,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
}

export const MiddleChartContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
})

export const MiddleChartContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: userActivityState } = useContext(UserActivityContext)
  const { state: globalState } = useContext(GlobalContext)

  const theme = useTheme()
  // effects

  useEffect(() => {
    dispatch({ type: 'view', payload: userActivityState.view })
  }, [userActivityState.view])
  useEffect(() => {
    dispatch({
      type: 'countryIsFilter',
      payload: userActivityState.countryIsFilter,
    })
  }, [userActivityState.countryIsFilter])
  useEffect(() => {
    dispatch({
      type: 'commentsEvent',
      payload: userActivityState.commentsEvent,
    })
  }, [userActivityState.commentsEvent])
  useEffect(() => {
    dispatch({
      type: 'dashboard_benchmark',
      payload: globalState.feature?.dashboard_benchmark,
    })
  }, [globalState.feature])
  useEffect(() => {
    dispatch({ type: 'chartType', payload: userActivityState.chartType })
    if (Object.keys(state.holdData).length) {
      dispatch({ type: 'data', payload: state.holdData })
    }
    // eslint-disable-next-line
  }, [userActivityState.chartType, globalState.feature?.dashboard_benchmark])
  useEffect(() => {
    dispatch({
      type: 'gameListObject',
      payload: userActivityState.gameListObject,
    })
  }, [userActivityState.gameListObject])
  useEffect(() => {
    dispatch({
      type: 'countryListObject',
      payload: userActivityState.countryListObject,
    })
  }, [userActivityState.countryListObject])
  useEffect(() => {
    dispatch({
      type: 'networkObject',
      payload: globalState.networkObject,
    })
  }, [globalState.networkObject])

  useEffect(() => {
    dispatch({
      type: 'chartLine',
      payload: theme?.palette?.custom?.chartLine || '#dfe6f1',
    })
  }, [theme.palette.custom?.chartLine])
  useEffect(() => {
    dispatch({
      type: 'holdData',
      payload: {
        data: userActivityState.data,
        benchmarkData: userActivityState.benchmarkData,
      },
    })
    dispatch({
      type: 'data',
      payload: {
        data: userActivityState.data,
        benchmarkData: userActivityState.benchmarkData,
      },
    })
    // eslint-disable-next-line
  }, [userActivityState.data])
  useEffect(() => {
    dispatch({
      type: 'middleChartLoading',
      payload: userActivityState.dataLoading,
    })
  }, [userActivityState.dataLoading])

  // returns
  return (
    <MiddleChartContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </MiddleChartContext.Provider>
  )
}
