import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useLocation } from 'react-router-dom'
import { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { FinanceContextProvider } from 'src/context/finance'
import { tipaltiConfig } from 'src/utils/tipaltiConfiguration'
import { GlobalContext } from 'src/context/global'
import { LoadingComponent, StylePaper, TopTitle } from 'src/components'
import Crypto from 'crypto'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
      },
      loading: {
        textAlign: 'center',
        fontSize: '20px',
        color: theme.palette.custom?.loading,
        fontWeight: 'bold',
        marginTop: '130px',
      },
    }
  })

const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const [iframeHeight, setIframeHeight] = useState(0)
  const [loadComplete, setLoadComplete] = useState(false)

  const {
    state: { userInfo },
  } = useContext(GlobalContext)

  const isEdit = useMemo(
    () => location.pathname === '/dash/finance/edit',
    [location.pathname]
  )

  const initIframeHeight = () => {
    function tipaltiHandler(evt: any) {
      if (evt.data && evt.data.TipaltiIframeInfo) {
        const { height } = evt.data.TipaltiIframeInfo
        setIframeHeight(height)
        setLoadComplete(true)
      }
    }
    window.addEventListener('message', tipaltiHandler, false)
  }

  const EncryptFullQueryString = (queryString: any, privateKey: any) => {
    const hMac = Crypto.createHmac('sha256', privateKey)
    hMac.update(queryString)
    return hMac.digest('hex')
  }

  const dashboard = useCallback((idap: any) => {
    let url: any = tipaltiConfig.iframe
    const payer: any = tipaltiConfig.payer
    const nowTime: any = Date.now()
    const ts = parseInt(String(nowTime / 1000), 10)
    const queryString: any = 'idap=' + idap + '&payer=' + payer + '&ts=' + ts
    url = url.replace('{domain}', tipaltiConfig.domain)
    url = url.replace('{path}', tipaltiConfig.path.home)
    url = url.replace('{idap}', idap)
    url = url.replace('{payer}', payer)
    url = url.replace('{ts}', ts)
    url = url.replace(
      '{hashkey}',
      EncryptFullQueryString(queryString, tipaltiConfig.masterKey)
    )
    return url
  }, [])

  const initIFrame = useCallback(() => {
    if (userInfo?.owner_key) {
      const iframe: any = document.getElementById('tipalti_home_iframe')
      iframe.src = dashboard(userInfo.owner_key)
    }
  }, [userInfo, dashboard])

  useEffect(() => {
    initIFrame()
  }, [initIFrame])
  initIframeHeight()

  // returns
  return (
    <FinanceContextProvider>
      <TopTitle
        title={isEdit ? 'Edit payment profile' : 'Setup payment profile'}
        showBack
        goBack={() => {
          navigate('/dash/finance')
        }}
      />
      <StylePaper
        width="100%"
        padding="24px"
        boxSizing="border-box"
        marginTop="24px"
      >
        <Box className={classes.root}>
          <Box>
            {!loadComplete && (
              <Box className={classes.loading}>
                <Box display="flex" justifyContent="center" marginBottom="80px">
                  <LoadingComponent />
                </Box>
                <Box component="h3">
                  The page is loading... If it is not displayed, please refresh
                  and try it again.
                </Box>
              </Box>
            )}
            <iframe
              title="tipalti profile"
              id="tipalti_home_iframe"
              width="100%"
              height={iframeHeight}
              frameBorder="0"
            />
          </Box>
        </Box>
      </StylePaper>
    </FinanceContextProvider>
  )
}

export default View
