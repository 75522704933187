const SVG = (props: any) => {
  const width = props.width || '25'
  const height = props.height || '14'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 25 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.597656 2.9213V11.1123C0.599295 12.5578 1.77067 13.7292 3.21619 13.7306H22.597C24.0426 13.7292 25.2139 12.5578 25.2154 11.1123V2.9213C25.2139 1.47577 24.0426 0.304388 22.597 0.302734H3.21619C1.77067 0.304388 0.599295 1.47577 0.597656 2.9213ZM22.597 1.42188C23.4248 1.42269 24.0956 2.09352 24.0964 2.9213V11.1123C24.0956 11.9401 23.4248 12.6109 22.597 12.6117H3.21619C2.38822 12.6109 1.7174 11.9401 1.71658 11.1123V2.9213C1.7174 2.09352 2.38822 1.42269 3.21619 1.42188H22.597Z"
        fill={color}
      />
      <path
        d="M2.83545 5.33866V8.69564C2.83545 9.00459 3.08583 9.25519 3.39481 9.25519C3.70379 9.25519 3.95437 9.00459 3.95437 8.69564V5.33866C3.95437 5.02967 3.70379 4.7793 3.39481 4.7793C3.08583 4.7793 2.83545 5.02967 2.83545 5.33866Z"
        fill={color}
      />
      <path
        d="M21.8582 5.89844C22.4764 5.89844 22.9773 6.39941 22.9773 7.01735C22.9773 7.63553 22.4764 8.1365 21.8582 8.1365C21.2402 8.1365 20.7393 7.63553 20.7393 7.01735C20.7393 6.39941 21.2402 5.89844 21.8582 5.89844Z"
        fill={color}
      />
      <path
        d="M5.37817 2.93189V11.0646C5.37817 11.277 5.55039 11.4492 5.76283 11.4492H18.7311C18.9436 11.4492 19.1158 11.277 19.1158 11.0646V2.93189C19.1158 2.71945 18.9436 2.54723 18.7311 2.54723H5.76283C5.55039 2.54723 5.37817 2.71945 5.37817 2.93189Z"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4667 5.16797C11.4551 5.16797 10.635 5.98801 10.635 6.99965C10.635 8.01125 11.4551 8.83133 12.4667 8.83133C13.4783 8.83133 14.2984 8.01125 14.2984 6.99965C14.2984 6.51383 14.1054 6.04794 13.7619 5.70444C13.4184 5.36095 12.9525 5.16797 12.4667 5.16797ZM12.4667 8.46498C11.6574 8.46498 11.0014 7.80892 11.0014 6.99965C11.0014 6.19034 11.6574 5.53427 12.4667 5.53427C13.276 5.53427 13.932 6.19034 13.932 6.99965C13.932 7.38826 13.7776 7.76099 13.5028 8.03579C13.228 8.31058 12.8553 8.46498 12.4667 8.46498ZM11.9886 6.21142C12.0323 6.18741 12.0857 6.18907 12.1278 6.21569L13.1994 6.8824C13.2393 6.90798 13.2635 6.95218 13.2635 6.99965C13.2635 7.04707 13.2393 7.09127 13.1994 7.11685L12.1278 7.78361C12.0857 7.81019 12.0323 7.81184 11.9886 7.78788C11.9448 7.76391 11.9175 7.7181 11.9172 7.66821V6.33108C11.9175 6.28119 11.9448 6.23538 11.9886 6.21142Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
