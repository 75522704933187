import {
  TextField,
  Box,
  Dialog,
  Button,
  useTheme,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  InfoOutlined as InfoOutlinedIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useContext, useEffect, useState, useMemo } from 'react'
import { RolesContext } from 'src/context/Roles'
import { GlobalContext } from 'src/context/global'
import {
  LoadingBtn,
  StyleSelectMultiple,
  StyleSelectOne,
  AdaptiveTooltip,
} from 'src/components'
import * as yup from 'yup'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      formInput: {
        width: '100%',
        display: 'flex',
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
      backButton: {
        boxSizing: 'border-box',
        width: '235.5px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        borderRadius: '6px',
        border: `1px solid ${theme.palette.custom.dialogButton}`,
        color: theme.palette.custom.dialogButton,
        cursor: 'pointer',
        marginRight: '24px',
      },
      footerButton: {
        width: '235.5px !important',
        height: '40px !important',
      },
      pageDefaultButton: {
        background:
          theme.palette.mode === 'light'
            ? 'rgba(16, 18, 20, 0.05)'
            : 'rgba(255, 255, 255, 0.05)',
        border: `1px solid ${
          theme.palette.mode === 'light'
            ? 'rgba(16, 18, 20, 0.3)'
            : 'rgba(255, 255, 255, 0.6)'
        }`,
        color: theme.palette.mode === 'light' ? '#828282' : '#FFF',
      },
      pageWriteButton: {
        background: 'rgba(39, 174, 96, 0.1)',
        border: '1px solid rgba(39, 174, 96, 0.5)',
        color: '#27AE60',
      },
      pageReadButton: {
        background: 'rgba(255, 142, 0, 0.1)',
        border: '1px solid rgba(255, 142, 0, 0.5)',
        color:
          theme.palette.mode === 'light'
            ? '#7487AA'
            : theme.palette.primary.main,
      },
      sendCodeButton: {
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '40px',
        width: '100px',
        fontSize: '14px',
        marginLeft: '8px',
        background: 'rgba(255, 142, 0, 0.1)',
        borderRadius: '6px',
        textAlign: 'center',
        color: theme.palette.primary.main,
        cursor: 'pointer',
      },
      disabledButton: {
        cursor: 'default',
      },
      helpIcon: {
        fontSize: '16px !important',
        verticalAlign: 'middle',
        marginLeft: '4px',
        marginTop: '-2px',
        cursor: 'pointer',
      },
      dialog: {
        boxSizing: 'border-box',
        padding: '40px',
        maxWidth: '575px',
        background: theme.palette.custom.dialogBg,
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '3px',
        cursor: 'pointer',
      },
      formLabel: {
        fontSize: '12px',
        color: theme.palette.custom.inputLabel,
        marginBottom: '8px',
      },
      selectRole: {
        width: '100% !important',
      },
    }
  })

const DialogView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [t] = useTranslation()
  const min638px = useMediaQuery('(min-width:638px)')
  const { state, dispatch, editAccount, sendCode } = useContext(RolesContext)
  const { state: globalState } = useContext(GlobalContext)
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [role, setRole] = useState(0)
  const [gameList, setGameList] = useState([])
  const [emailError, setEmailError] = useState('')
  const [codeError, setCodeError] = useState('')
  const [roleErrorDetail, setRoleErrorDetail] = useState('')
  const FormSchema = yup.object().shape({
    email: yup.string().email().required(t('validator.account_email_error')),
  })
  const isEmailCodeSend = useMemo(
    () => !!state.emailCodeInterval,
    [state.emailCodeInterval]
  )
  const selectGame = (row: any) => {
    setGameList(row)
  }
  const handleClose = () => {
    dispatch({
      type: 'dialogOpen',
      payload: false,
    })
  }
  const inputChange = (e: any) => {
    setEmail(e.target.value)
  }
  const inputCodeChange = (e: any) => {
    setCode(e.target.value)
  }
  const saveAccount = () => {
    if (!email) {
      setEmailError(t('validator.account_email_input'))
    } else {
      FormSchema.isValid({
        email,
      }).then(valid => {
        if (valid) {
          setEmailError('')
          if (!state.dialogData?.developer_key) {
            if (!code) {
              setCodeError(t('validator.verificationCodeErrMsg'))
              return
            } else {
              setCodeError('')
            }
          }
          if (!role) {
            setRoleErrorDetail(t('validator.account_role_select'))
            return
          } else {
            setRoleErrorDetail('')
          }
          const data = { ...state.dialogData, email, role_id: role, code }
          if (state.limitAppViewList.includes(role)) {
            data.app_keys = gameList.map((item: any) => item.app_key)
          }
          editAccount(data)
        } else {
          setEmailError(t('validator.account_email_error'))
        }
      })
    }
  }
  const handleSubmit = () => {
    if (isEmailCodeSend) return
    if (!email) {
      setEmailError(t('validator.account_email_input'))
    } else {
      FormSchema.isValid({
        email,
      }).then(valid => {
        if (valid) {
          setEmailError('')
          if (state.codeLoading || state.emailCodeInterval > 0) {
            return
          }
          sendCode(email)
        } else {
          setEmailError(t('validator.account_email_error'))
        }
      })
    }
  }
  useEffect(() => {
    setEmailError('')
    setCodeError('')
    setRoleErrorDetail('')
    setEmail(state.dialogData.email)
    setRole(state.dialogData.role_id || '')
    setGameList([])
  }, [state.dialogData])
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={state.dialogOpen}
    >
      <Box className={classes.dialog}>
        <Box className={classes.title}>
          Add User
          <CloseIcon
            className={classes.closeFilterIcon}
            onClick={handleClose}
          />
        </Box>
        <Box className={classes.formLabel} marginTop="24px">
          Email:
        </Box>
        <TextField
          className={classes.formInputSearch}
          name="email"
          placeholder="Email"
          error={!!emailError}
          helperText={emailError}
          value={email}
          onChange={inputChange}
          variant="outlined"
        />
        <Box className={classes.formLabel} marginTop="24px">
          Code:
        </Box>
        <Box className={classes.formInput}>
          <TextField
            autoComplete="off"
            className={classes.formInputSearch}
            error={!!codeError}
            helperText={codeError}
            style={{ width: 'calc(100% - 108px)' }}
            onChange={inputCodeChange}
            placeholder="Code"
            variant="outlined"
          />
          <Box
            onClick={handleSubmit}
            className={clsx({
              [classes.sendCodeButton]: true,
              [classes.disabledButton]: isEmailCodeSend,
            })}
          >
            {state.codeLoading ? (
              <Box lineHeight="20px" paddingTop="10px">
                <LoadingBtn
                  loadingStyle={{
                    justifyContent: 'center',
                    color: theme.palette.primary.main,
                  }}
                />
              </Box>
            ) : isEmailCodeSend ? (
              `Sent!(${state.emailCodeInterval})`
            ) : (
              'Send Code'
            )}
          </Box>
        </Box>
        <Box className={classes.formLabel} marginTop="24px">
          Role:
        </Box>
        <StyleSelectOne
          placeholder="Role"
          value={role}
          labelKey="title"
          valueKey="id"
          selectStyle={true}
          customClass={classes.selectRole}
          onChange={(value: any) => {
            setRole(value)
            setRoleErrorDetail('')
          }}
          options={state.rolesList}
        />
        {!!roleErrorDetail && (
          <Box
            sx={{ margin: '6px 0 0 14px', color: '#d32f2f', fontSize: '12px' }}
          >
            {roleErrorDetail}
          </Box>
        )}
        {state.limitAppViewList.includes(role) && (
          <>
            <Box className={classes.formLabel} marginTop="24px">
              Select Games:
            </Box>
            <StyleSelectMultiple
              customClass={classes.selectRole}
              label=""
              placeholder="Select Games"
              value={gameList}
              labelKey="name"
              valueKey="app_key"
              selectStyle={true}
              onChange={selectGame}
              options={globalState.gameOption}
            />
          </>
        )}
        <Box
          display="flex"
          flexWrap="wrap"
          boxSizing="border-box"
          sx={{
            justifyContent: min638px ? 'flex-start' : 'space-around',
          }}
        >
          {Object.keys(
            state.rolesListObject[role]?.permission || state.noneRoleObject
          ).map((item: string, index: number) => (
            <Box
              key={item}
              display="inline-block"
              borderRadius="6px"
              textAlign="center"
              minWidth="157px"
              height="30px"
              lineHeight="30px"
              fontSize="12px"
              margin={index % 3 === 1 && min638px ? '0 8px' : '0'}
              marginTop="24px"
              className={
                state.rolesListObject[role]
                  ? state.rolesListObject[role]?.permission[item] === 2
                    ? classes.pageWriteButton
                    : state.rolesListObject[role]?.permission[item] === 1
                    ? classes.pageReadButton
                    : classes.pageDefaultButton
                  : classes.pageDefaultButton
              }
            >
              {t(item) || item}
              <AdaptiveTooltip
                isMobile={globalState.isMobile}
                title={
                  state.rolesListObject[role]
                    ? state.rolesListObject[role]?.permission[item] === 2
                      ? 'Write permission'
                      : state.rolesListObject[role]?.permission[item] === 1
                      ? 'Read-only access'
                      : 'No permission'
                    : 'No permission'
                }
                arrow
                placement="top"
                disableInteractive
              >
                <InfoOutlinedIcon className={classes.helpIcon} />
              </AdaptiveTooltip>
            </Box>
          ))}
        </Box>
        <Box marginTop="24px" display="flex">
          <Box className={classes.backButton} onClick={handleClose}>
            Back
          </Box>
          <Button
            className={classes.footerButton}
            color="primary"
            variant="contained"
            onClick={saveAccount}
          >
            {state.editDeviceLoading ? (
              <Box>
                <LoadingBtn
                  loadingStyle={{
                    justifyContent: 'center',
                  }}
                />
              </Box>
            ) : (
              'Save'
            )}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DialogView
