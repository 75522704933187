import { AdPlacementsContextProvider } from 'src/context/adPlacement'
import List from './list'

const AdPlacement = (): JSX.Element => {
  // returns
  return (
    <AdPlacementsContextProvider>
      <List />
    </AdPlacementsContextProvider>
  )
}

export default AdPlacement
