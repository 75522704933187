import { createContext, useReducer, useEffect, useContext } from 'react'

import { ApiResponse } from 'src/api'
import {
  getUserMcmApi,
  updateUserMcmApi,
  downloadAppAdsApi,
  uploadAppAdsApi,
  getUpdateTimeApi,
} from 'src/api/adManage'
import { GlobalContext } from './global'
import { downloadCsv } from 'src/utils'
type Props = {
  mcm_state: string
  mcm_email: string
  loading: boolean
  updateLoading: boolean
  uploadLoading: boolean
  appAdsTxtUpdateTime: string
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  mcm_state: '',
  mcm_email: '',
  loading: false,
  updateLoading: false,
  uploadLoading: false,
  appAdsTxtUpdateTime: '',
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'mcm_email':
    case 'mcm_state':
    case 'loading':
    case 'updateLoading':
    case 'uploadLoading':
    case 'appAdsTxtUpdateTime':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  updateUserMcm: (email: string, confirm_email: string) => void
  downloadAppAds: () => void
  uploadAppAds: (params: any) => void
}
export const ADContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  updateUserMcm: (email: string, confirm_email: string) => {},
  downloadAppAds: () => {},
  uploadAppAds: () => {},
})

export const ADContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { customAlert, errorCatch } = useContext(GlobalContext)

  const getUpdateTime = () => {
    getUpdateTimeApi()
      .then(({ data }) => {
        dispatch({
          type: 'appAdsTxtUpdateTime',
          payload: data?.app_ads_txt || '',
        })
      })
      .catch(errorCatch)
  }
  const getUserMcm = () => {
    dispatch({ type: 'loading', payload: true })
    getUserMcmApi()
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'mcm_state', payload: data.mcm_state })
        dispatch({ type: 'mcm_email', payload: data.mcm_email })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
  }
  const updateUserMcm = (email: string, confirm_email: string) => {
    if (email !== confirm_email) {
      customAlert('error', 'Confirmation email need same as email')
      return
    }
    const params = {
      mcm_email: email,
      confirm_mcm_email: confirm_email,
    }
    dispatch({ type: 'updateLoading', payload: true })
    updateUserMcmApi(params)
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'loading', payload: true })
        setTimeout(() => {
          getUserMcm()
        }, 2000)
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'updateLoading', payload: false })
      })
  }

  const downloadAppAds = () => {
    dispatch({ type: 'loading', payload: true })
    downloadAppAdsApi()
      .then(({ data }: ApiResponse) => {
        downloadCsv(data, 'app-ads.txt', 'text/txt;encoding:utf-8')
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
  }
  const uploadAppAds = (params: any) => {
    dispatch({ type: 'uploadLoading', payload: true })
    uploadAppAdsApi(params)
      .then(({ data }: ApiResponse) => {
        downloadCsv(data, 'app-ads.txt', 'text/txt;encoding:utf-8')
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'uploadLoading', payload: false })
      })
  }
  // effects
  useEffect(() => {
    getUserMcm()
    // eslint-disable-next-line
  }, [state.mcm_email])
  useEffect(() => {
    getUpdateTime()
    // eslint-disable-next-line
  }, [])
  // returns
  return (
    <ADContext.Provider
      value={{
        state,
        dispatch,
        updateUserMcm,
        downloadAppAds,
        uploadAppAds,
      }}
    >
      {children}
    </ADContext.Provider>
  )
}
