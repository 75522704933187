import { useState, useContext, useEffect } from 'react'
import { Box, Popover, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SVGIcon } from 'src/images'
import { PerformanceContext } from 'src/context/performance/index'
import clsx from 'clsx'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      popover: {
        '& .MuiPaper-root': {
          minWidth: '200px',
          maxWidth: '500px',
          maxHeight: '70vh',
          marginTop: '4px',
          borderRadius: '6px',
          padding: '0',
          background: theme.palette.custom.dialogBg,
        },
      },
      option: {
        width: '100%',
        display: 'flex',
        padding: '6px 14px',
        cursor: 'pointer',
        color: theme.palette.custom.inputLabel,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
          background:
            theme.palette.mode === 'light'
              ? 'rgba(224, 229, 231, 0.5)'
              : '#555D6A',
        },
      },
      checked: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        background:
          theme.palette.mode === 'light'
            ? 'rgba(224, 229, 231, 0.5)'
            : '#555D6A',
      },
    }
  })

const options = [
  {
    label: 'Revenue',
    value: 'revenue',
  },
  {
    label: 'Impressions',
    value: 'impressions',
  },
  {
    label: 'eCPM',
    value: 'ecpm',
  },
]
const SortButton = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch } = useContext(PerformanceContext)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [value, setValue] = useState<any>([])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    dispatch({
      type: 'checkedMetrics',
      payload: value,
    })
  }

  const selectAll = () => {
    if (value.length === options.length) {
      setValue([])
    } else {
      setValue(options.map(item => item.value))
    }
  }
  const toCheck = (item: any) => {
    if (value.includes(item)) {
      const temp = value.filter((element: any) => element !== item)
      setValue(temp)
    } else {
      setValue([...value, item])
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  useEffect(() => {
    setValue(state.checkedMetrics)
  }, [state.checkedMetrics])
  return (
    <>
      <Box
        sx={{
          boxSizing: 'border-box',
          width: '26px',
          height: '26px',
          padding: '4px',
          cursor: 'pointer',
        }}
        aria-describedby={id}
        onClick={handleClick}
      >
        <SVGIcon.SettingSVG
          color={theme.palette.text.primary}
          style={{ verticalAlign: 'text-top', lineHeight: '18px' }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
        sx={{ zIndex: 1999 }}
      >
        <Box fontSize="14px" padding="8px 0" maxWidth="90vw">
          {/* select all */}
          <Box
            className={clsx({
              [classes.option]: true,
              [classes.checked]: value.length === options.length,
            })}
            onClick={selectAll}
          >
            {value.length === options.length ? (
              <SVGIcon.CheckedBoxSVG
                color={
                  theme.palette.mode === 'light'
                    ? ''
                    : theme.palette.primary.main
                }
                style={{
                  marginRight: '4px',
                }}
              />
            ) : (
              <SVGIcon.UncheckedBoxSVG
                color={
                  theme.palette.mode === 'light'
                    ? ''
                    : 'rgba(255, 255, 255, 0.7)'
                }
                style={{
                  marginRight: '4px',
                }}
              />
            )}
            <Box lineHeight="24px">Select All</Box>
          </Box>
          {/* options */}
          <Box>
            {options.map((item: any, index: number) => (
              <Box
                className={clsx({
                  [classes.option]: true,
                  [classes.checked]: value.includes(item.value),
                })}
                key={index}
                onClick={() => {
                  toCheck(item.value)
                }}
              >
                {value.includes(item.value) ? (
                  <SVGIcon.CheckedBoxSVG
                    color={
                      theme.palette.mode === 'light'
                        ? ''
                        : theme.palette.primary.main
                    }
                    style={{
                      marginRight: '4px',
                    }}
                  />
                ) : (
                  <SVGIcon.UncheckedBoxSVG
                    color={
                      theme.palette.mode === 'light'
                        ? ''
                        : 'rgba(255, 255, 255, 0.7)'
                    }
                    style={{
                      marginRight: '4px',
                    }}
                  />
                )}
                <Box lineHeight="24px">{item.label}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  )
}
export default SortButton
