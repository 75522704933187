import {
  Box,
  Tabs,
  Tab,
  useTheme,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useState, useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GameManageContext } from 'src/context/gameManage'
import { GlobalContext } from 'src/context/global'
import { GlobalStoreContext } from 'src/context/globalStore'
import { TopTitle, StylePaper, DeleteDialog } from 'src/components'
import OverviewView from './overview'
import AdsManagementView from './adsManagement'
import IssueView from './issue'
import CappingAndPacingView from './cappingAndPacing'
import MobileCappingAndPacingView from './mobileCappingAndPacing'
import OptimizationDetailsView from './optimizationDetails'
import { Tabs as AntdTabs } from 'antd'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      tab: {
        marginTop: '24px',
        '& .MuiTabs-flexContainer': {
          borderBottom: `2px solid ${
            theme.palette.mode === 'light'
              ? '#E1E4E6'
              : 'rgba(121, 126, 135, 0.3)'
          }`,
          '& .MuiButtonBase-root': {
            fontWeight: 500,
          },
        },
      },
      deleteButton: {
        boxSizing: 'border-box',
        width: '135px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        borderRadius: '6px',
        border: `1px solid ${
          theme.palette.mode === 'light'
            ? 'rgba(116, 135, 170, 0.5)'
            : '#797E87'
        }`,
        color: theme.palette.mode === 'light' ? '#1D3353' : '#797E87',
        cursor: 'pointer',
        marginRight: '16px',
      },
      downloadButton: {
        boxSizing: 'border-box',
        width: '188px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        borderRadius: '6px',
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        cursor: 'pointer',
      },
    }
  })
const ManageView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const params: any = useParams()
  const { state, deleteGame, getGameDetail, getMODetails } =
    useContext(GameManageContext)
  const { state: globalState } = useContext(GlobalContext)
  const { dispatch: globalStoreDispatch } = useContext(GlobalStoreContext)
  const [value, setValue] = useState(params.page_view || 'overview')
  const [openModel, setOpenModel] = useState(false)
  const width990 = useMediaQuery('(min-width:990px)')
  const tabList = useMemo(() => {
    if (state.appDetail?.ads_format?.includes('interstitial')) {
      return [
        {
          label: 'Overview',
          key: 'overview',
        },
        {
          label: 'Ads Management',
          key: 'ads-management',
        },
        {
          label: 'Capping & Pacing',
          key: 'capping-pacing',
        },
        {
          label: 'Outstanding Issues',
          key: 'issue',
        },
      ]
    }
    return [
      {
        label: 'Overview',
        key: 'overview',
      },
      {
        label: 'Ads Management',
        key: 'ads-management',
      },
      {
        label: 'Outstanding Issues',
        key: 'issue',
      },
    ]
  }, [state.appDetail])

  const handleChange = (event: any, newValue: string) => {
    navigate(`/dash/games/manage/${params.app_key}/${newValue}`)
    setValue(newValue)
  }
  const back = () => {
    globalStoreDispatch({
      type: 'gamePageFromDetail',
      payload: true,
    })
    navigate('/dash/games')
  }
  const closeDeleteDialog = () => {
    setOpenModel(false)
  }
  const deleteGameBtn = () => {
    deleteGame(state.appDetail.app_key)
  }
  const buttonGroup = (
    <>
      {globalState.accountAuthority.game_manage_page === 2 &&
        state.appDetail.ad_conf_status === 'no_link' && (
          <Box
            className={classes.deleteButton}
            onClick={() => {
              setOpenModel(true)
            }}
            sx={
              globalState.isMobile
                ? {
                    width: 'calc(50% - 8px)',
                  }
                : {}
            }
          >
            Delete Game
          </Box>
        )}
      <Box
        className={classes.downloadButton}
        sx={
          globalState.isMobile
            ? {
                width: 'calc(50% - 8px)',
              }
            : {}
        }
        onClick={() => {
          let url = 'https://developers.yodo1.com/'
          if (state.appDetail.engine === 'Unity') {
            url =
              'https://developers.yodo1.com/docs/unity/get-started/unity-integration/'
          } else if (state.appDetail.engine === 'Android') {
            url =
              'https://developers.yodo1.com/docs/android/get-started/sdk-integration/'
          } else if (state.appDetail.engine === 'iOS') {
            url =
              'https://developers.yodo1.com/docs/ios/get-started/sdk-integration/ '
          } else if (state.appDetail.engine === 'Flutter') {
            url =
              'https://developers.yodo1.com/docs/flutter/get-started/flutter-integration/'
          } else if (state.appDetail.engine === 'Godot') {
            url =
              'https://developers.yodo1.com/docs/godot/get-started/android-integration/#setup-your-project'
          } else if (state.appDetail.engine === 'ReactNative') {
            url =
              'https://developers.yodo1.com/docs/react-native/get-started/android-integration/'
          } else if (state.appDetail.engine === 'Cocos') {
            url =
              'https://developers.yodo1.com/docs/cocos/cocos-creator/android-integration/'
          } else if (state.appDetail.engine === 'UnrealEngine') {
            url =
              'https://developers.yodo1.com/docs/unreal-engine/get-started/integration/'
          } else if (state.appDetail.engine === 'Solar2D') {
            url =
              'https://developers.yodo1.com/docs/solar2d/get-started/android-integration/'
          }
          window.open(url)
        }}
      >
        Download Latest SDK
      </Box>
    </>
  )
  useEffect(() => {
    getGameDetail(params.app_key)
    getMODetails(params.app_key)
    // eslint-disable-next-line
  }, [])

  return (
    <Box overflow="hidden">
      <TopTitle title="Game Management" showBack={true} goBack={back} />
      <Box position="relative">
        {!globalState.isMobile && (
          <Box
            display="flex"
            position="absolute"
            right="0"
            top={width990 ? 0 : '-34px'}
            zIndex={1}
          >
            {buttonGroup}
          </Box>
        )}
        {globalState.isMobile ? (
          <AntdTabs
            activeKey={value}
            tabPosition="top"
            size="small"
            onChange={(tab: any) => {
              navigate(`/dash/games/manage/${params.app_key}/${tab}`)
              setValue(tab)
            }}
            renderTabBar={(tabBarProps, DefaultTabBar) => (
              <Box
                sx={{
                  marginBottom: '-16px',
                  '& .ant-tabs-ink-bar': {
                    background: theme.palette.primary.main,
                  },
                }}
              >
                <DefaultTabBar {...tabBarProps}>
                  {node => {
                    return (
                      <Box
                        sx={{
                          color: theme.palette.text.secondary,
                          borderBottom: `2px solid ${
                            theme.palette.mode === 'light'
                              ? '#E1E4E6'
                              : 'rgba(121, 126, 135, 0.3)'
                          }`,
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          fontWeight: 500,
                          '& .ant-tabs-tab:hover, & .ant-tabs-tab-btn:active, & .ant-tabs-tab-btn:focus':
                            {
                              color: `${theme.palette.text.secondary} !important`,
                            },
                          '& .ant-tabs-tab-active .ant-tabs-tab-btn': {
                            color: `${theme.palette.primary.main} !important`,
                          },
                        }}
                      >
                        {node}
                      </Box>
                    )
                  }}
                </DefaultTabBar>
              </Box>
            )}
            style={{ marginTop: '24px' }}
            items={tabList}
          />
        ) : (
          <Tabs value={value} onChange={handleChange} className={classes.tab}>
            {tabList.map((item: any) => (
              <Tab
                key={item.key}
                disableRipple
                label={item.label}
                value={item.key}
              />
            ))}
          </Tabs>
        )}
        <Box
          sx={{ display: globalState.isMobile ? 'block' : 'flex', gap: '24px' }}
        >
          <StylePaper
            width="100%"
            padding="24px"
            boxSizing="border-box"
            marginTop="24px"
            flex={3}
          >
            <Box>
              {value === 'ads-management' ? (
                <AdsManagementView isMobile={globalState.isMobile} />
              ) : value === 'issue' ? (
                <IssueView />
              ) : value === 'capping-pacing' ? (
                <>
                  {globalState.isMobile ? (
                    <MobileCappingAndPacingView />
                  ) : (
                    <CappingAndPacingView />
                  )}
                </>
              ) : (
                <OverviewView isMobile={globalState.isMobile} />
              )}
            </Box>
          </StylePaper>
          {!!state.moDetailsData.length && value === 'overview' && (
            <Box boxSizing="border-box" marginTop="24px" flex={2}>
              <OptimizationDetailsView />
            </Box>
          )}
        </Box>
        {globalState.isMobile && (
          <Box display="flex" marginTop="24px" justifyContent="center">
            {buttonGroup}
          </Box>
        )}
      </Box>

      <DeleteDialog
        openModel={openModel}
        onClose={closeDeleteDialog}
        onConfirm={deleteGameBtn}
        title={state.appDetail.name}
        loading={state.deleteGameLoading}
        deleteButtonText="Delete Game"
      />
    </Box>
  )
}

export default ManageView
