const SVG = (props: any) => {
  const width = props.width || '20'
  const height = props.height || '20'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8019 5.02019C8.63321 6.73685 9.18916 8.4984 10.4892 9.79842C11.789 11.0983 13.5508 11.6543 15.2674 11.4855C15.0992 11.7292 14.9064 11.9617 14.6888 12.1793C12.8002 14.0679 9.80448 14.0974 7.99735 12.2902C6.19004 10.4829 6.21983 7.48728 8.10833 5.59878C8.32591 5.3812 8.55828 5.18838 8.8019 5.02019Z"
        stroke={color}
      />
    </svg>
  )
}

export default SVG
