const SVG = (props: any) => {
  const width = props.width || '26'
  const height = props.height || '26'
  const color1 = props.color1 || '#1E96FC'
  const color2 = props.color2 || '#F3F9FF'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="26"
        y="26"
        width="26"
        height="26"
        rx="4"
        transform="rotate(-180 26 26)"
        fill={color2}
      />
      <path
        d="M4.48275 15.8063C4.66377 16.0131 4.88635 16.1786 5.13573 16.292C5.3851 16.4054 5.65556 16.4641 5.92913 16.464L10.429 16.464L10.429 17.76C10.429 18.6193 10.7676 19.4434 11.3704 20.051C11.9732 20.6586 12.7907 21 13.6431 21C13.7626 21.0001 13.8797 20.9666 13.9813 20.9034C14.0829 20.8402 14.165 20.7496 14.2185 20.642L17.2543 14.52L20.7143 14.52C21.0553 14.52 21.3823 14.3835 21.6234 14.1404C21.8645 13.8974 22 13.5677 22 13.224L22 6.096C22 5.75228 21.8645 5.42264 21.6234 5.17959C21.3823 4.93654 21.0553 4.8 20.7143 4.8L6.89339 4.8C6.42355 4.79984 5.96982 4.97257 5.61739 5.28578C5.26495 5.59898 5.03807 6.0311 4.97934 6.501L4.01509 14.277C3.98091 14.5508 4.00494 14.8287 4.08558 15.0924C4.16621 15.3561 4.3016 15.5994 4.48275 15.8063ZM20.7143 13.224L17.5002 13.224L17.5002 6.096L20.7143 6.096L20.7143 13.224ZM5.29112 14.439L6.25537 6.663C6.27495 6.50637 6.35058 6.36232 6.46805 6.25792C6.58553 6.15352 6.73677 6.09594 6.89339 6.096L16.2145 6.096L16.2145 13.7189L13.2647 19.6667C12.8275 19.5785 12.4341 19.3403 12.1515 18.9927C11.8689 18.6451 11.7145 18.2095 11.7146 17.76L11.7146 15.816C11.7146 15.6441 11.6469 15.4793 11.5264 15.3578C11.4058 15.2363 11.2423 15.168 11.0718 15.168L5.92913 15.168C5.83791 15.168 5.74773 15.1485 5.66458 15.1107C5.58142 15.0729 5.50721 15.0176 5.44686 14.9487C5.38651 14.8797 5.34141 14.7986 5.31456 14.7107C5.28771 14.6229 5.27972 14.5302 5.29112 14.439Z"
        fill={color1}
      />
    </svg>
  )
}

export default SVG
