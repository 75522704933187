import { useState, useContext, useMemo } from 'react'
import { Box, Button, TextField, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { GameContext } from 'src/context/games'
import { GlobalContext } from 'src/context/global'
import { SVGIcon } from 'src/images'
import { LoadingBtn } from 'src/components'
import DesktopProgressItemView from './progressItem'
import MobileProgressItemView from './mobileProgressItem'
import dayjs from 'dayjs'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      button: {
        height: '36px !important',
        padding: '0 12px !important',
      },
      formInputSearch: {
        width: '100%',
        height: '36px',
        fontSize: '14px',
      },
    }
  })

const ProgressView = (props: any) => {
  const { appDetail, isMobile = false } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const { state, editGame, progressButton, progressSdkIconButton } =
    useContext(GameContext)
  const { state: globalState } = useContext(GlobalContext)

  const [sdkCheckedOne, setSdkCheckedOne] = useState<boolean>(false)
  const [sdkCheckedTwo, setSdkCheckedTwo] = useState<boolean>(false)
  const [sdkCheckedThree, setSdkCheckedThree] = useState<boolean>(false)
  const [storeUrl, setStoreUrl] = useState<string>('')
  const canReleaseGame = useMemo(() => {
    return (
      (appDetail.game_process_status.ad_config === 2 ||
        (appDetail?.is_whitelist &&
          appDetail.game_process_status.ad_config === 1 &&
          appDetail?.network_status?.admob === 1)) &&
      appDetail.game_process_status.game_link === 2 &&
      appDetail.game_process_status.pre_metric === 2
    )
  }, [appDetail])
  const ProgressItemView = useMemo(() => {
    if (globalState.isMobile) {
      return MobileProgressItemView
    }
    return DesktopProgressItemView
  }, [globalState.isMobile])
  const clickTip = (props: any) => {
    return (
      <Box
        sx={{
          boxSizing: 'border-box',
          position: 'relative',
          width: isMobile ? '90vw' : '424px',
          maxWidth: '424px',
          padding: '20px',
          textAlign: 'left',
          fontSize: '14px',
          lineHeight: '22px',
        }}
      >
        {props.title && (
          <Box
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '20px',
              color: theme.palette.text.primary,
            }}
          >
            {props.title}
          </Box>
        )}
        {props.content && <Box>{props.content}</Box>}
        {props.buttonText && globalState.accountAuthority.games_page === 2 && (
          <Box
            sx={{
              textAlign: 'left',
              marginTop: '22px',
            }}
          >
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={props.buttonClick}
              disabled={props.buttonDisabled}
            >
              {props.buttonLoading ? <LoadingBtn /> : props.buttonText}
            </Button>
            {props.buttonText2 && (
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={props.buttonClick2}
                disabled={props.buttonDisabled}
                style={{ marginLeft: '12px' }}
              >
                {props.buttonLoading ? <LoadingBtn /> : props.buttonText2}
              </Button>
            )}
          </Box>
        )}
      </Box>
    )
  }
  const hoverTip = (props: any) => {
    return (
      <Box
        sx={{
          boxSizing: 'border-box',
          minWidth: '242px',
          padding: '12px 12px 17px',
          fontSize: '14px',
          color: theme.palette.text.primary,
          textAlign: 'center',
          lineHeight: '22px',
        }}
      >
        {props.title && (
          <Box
            sx={{
              fontWeight: 600,
              marginBottom: '8px',
              fontSize: '16px',
            }}
          >
            {props.title}
          </Box>
        )}
        {props.content && <>{props.content}</>}
      </Box>
    )
  }
  const sdkCheckedOneChange = (e: any, target: any) => {
    setSdkCheckedOne(target)
  }
  const sdkCheckedTwoChange = (e: any, target: any) => {
    setSdkCheckedTwo(target)
  }
  const sdkCheckedThreeChange = (e: any, target: any) => {
    setSdkCheckedThree(target)
  }
  const urlChange = (e: any) => {
    setStoreUrl(e.target.value)
  }
  const openSdkUrl = () => {
    let url = 'https://developers.yodo1.com/'
    if (appDetail.engine === 'Unity') {
      url =
        'https://developers.yodo1.com/docs/unity/get-started/download-unity-sdk/'
    } else if (appDetail.engine === 'Android') {
      url =
        'https://developers.yodo1.com/docs/android/get-started/download-the-android-sdk/'
    } else if (appDetail.engine === 'iOS') {
      url =
        'https://developers.yodo1.com/docs/ios/get-started/download-the-ios-sdk/'
    } else if (appDetail.engine === 'Flutter') {
      url =
        'https://developers.yodo1.com/docs/flutter/get-started/flutter-integration/#1-open-your-project-level-build-gradle-and-add-the-repositories'
    } else if (appDetail.engine === 'Godot') {
      url =
        'https://developers.yodo1.com/docs/godot/get-started/android-integration/'
    } else if (appDetail.engine === 'ReactNative') {
      url =
        'https://developers.yodo1.com/docs/react-native/get-started/android-integration/'
    } else if (appDetail.engine === 'Cocos') {
      url =
        'https://developers.yodo1.com/docs/cocos/cocos-creator/android-integration/#1-import-yodo1ads-ts'
    } else if (appDetail.engine === 'UnrealEngine') {
      url =
        'https://developers.yodo1.com/docs/unreal-engine/get-started/integration/#1-download-the-plugin'
    } else if (appDetail.engine === 'Solar2D') {
      url =
        'https://developers.yodo1.com/docs/solar2d/get-started/android-integration/#1-setup%C2%A0build-settings'
    }
    window.open(url)
  }
  const openSdkIntegrationUrl = () => {
    let url =
      'https://developers.yodo1.com/docs/additional-set-up/compliance-with-legal-framework/coppa/'
    if (appDetail.engine === 'Unity') {
      url =
        'https://developers.yodo1.com/docs/unity/get-started/unity-integration/#2-add-the-privacy-dialog'
    } else if (appDetail.engine === 'Android') {
      url =
        'https://developers.yodo1.com/docs/android/get-started/sdk-integration/#3-comply-with-the-legal-frameworks'
    } else if (appDetail.engine === 'iOS') {
      url =
        'https://developers.yodo1.com/docs/ios/get-started/sdk-integration/#2-comply-with-the-legal-frameworks'
    } else if (appDetail.engine === 'Flutter') {
      url =
        'https://developers.yodo1.com/docs/flutter/get-started/flutter-integration/#4-initialization'
    } else if (appDetail.engine === 'Godot') {
      url =
        'https://developers.yodo1.com/docs/godot/get-started/android-integration/'
    } else if (appDetail.engine === 'ReactNative') {
      url =
        'https://developers.yodo1.com/docs/react-native/get-started/android-integration/#7-comply-with-the-legal-frameworks'
    } else if (appDetail.engine === 'Cocos') {
      url =
        'https://developers.yodo1.com/docs/cocos/cocos-creator/android-integration/#2-configure-mas'
    } else if (appDetail.engine === 'UnrealEngine') {
      url =
        'https://developers.yodo1.com/docs/unreal-engine/get-started/integration/#5-%C2%A0initialize-the-sdk'
    } else if (appDetail.engine === 'Solar2D') {
      url =
        'https://developers.yodo1.com/docs/solar2d/get-started/android-integration/#2-initialize-the-sdk'
    }
    window.open(url)
  }
  const openSdkIntegration2Url = () => {
    let url = 'https://developers.yodo1.com/'
    if (appDetail.engine === 'Unity') {
      url =
        'https://developers.yodo1.com/docs/unity/get-started/unity-integration/'
    } else if (appDetail.engine === 'Android') {
      url =
        'https://developers.yodo1.com/docs/android/get-started/sdk-integration/'
    } else if (appDetail.engine === 'iOS') {
      url =
        'https://developers.yodo1.com/docs/ios/get-started/sdk-integration/ '
    } else if (appDetail.engine === 'Flutter') {
      url =
        'https://developers.yodo1.com/docs/flutter/get-started/flutter-integration/'
    } else if (appDetail.engine === 'Godot') {
      url =
        'https://developers.yodo1.com/docs/godot/get-started/android-integration/#setup-your-project'
    } else if (appDetail.engine === 'ReactNative') {
      url =
        'https://developers.yodo1.com/docs/react-native/get-started/android-integration/'
    } else if (appDetail.engine === 'Cocos') {
      url =
        'https://developers.yodo1.com/docs/cocos/cocos-creator/android-integration/'
    } else if (appDetail.engine === 'UnrealEngine') {
      url =
        'https://developers.yodo1.com/docs/unreal-engine/get-started/integration/'
    } else if (appDetail.engine === 'Solar2D') {
      url =
        'https://developers.yodo1.com/docs/solar2d/get-started/android-integration/'
    }
    window.open(url)
  }
  return (
    <Box display="inline-block" marginTop="3px">
      <Box sx={{ display: 'flex' }}>
        {/* Pre-MAS Metrics */}
        <ProgressItemView
          icon={SVGIcon.ProgressOneSVG}
          status={appDetail.game_process_status.pre_metric}
        >
          {appDetail.game_process_status.pre_metric === 2
            ? hoverTip({
                title: 'Pre-MAS Metrics',
                content: 'Pre-MAS Metrics are submitted.',
              })
            : clickTip({
                title: 'Pre-MAS Metrics',
                content: (
                  <Box
                    sx={{
                      marginBottom: '40px',
                    }}
                  >
                    <Box sx={{ marginBottom: '12px' }}>
                      Please submit your Pre-MAS metrics, this step is important
                      as this will help us to optimize your performance.
                    </Box>
                  </Box>
                ),
                buttonText: 'Submit',
                buttonClick: () => {
                  props.openDialog &&
                    props.openDialog(
                      appDetail.app_key,
                      appDetail.name,
                      appDetail.game_process_status.pre_metric,
                      props.index
                    )
                },
              })}
        </ProgressItemView>
        {/* Game Submission */}
        <ProgressItemView
          icon={SVGIcon.ProgressTwoSVG}
          status={
            appDetail.app_termination_status === 'TERMINATION'
              ? 0
              : appDetail.game_process_status.game_link
          }
          disableFocusListener={true}
        >
          {appDetail.app_termination_status === 'TERMINATION'
            ? hoverTip({
                title: 'Game Submission',
                content: (
                  <Box>
                    This app violates our policy against money making apps.
                    Please read more about it{' '}
                    <Box
                      sx={{
                        display: 'inline',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        window.open(
                          'https://policy.yodo1.com/mas/en/terms-of-service.html'
                        )
                      }}
                    >
                      here.
                    </Box>
                  </Box>
                ),
              })
            : appDetail.game_process_status.game_link === 2
            ? hoverTip({
                title: 'Game Submission',
                content: (
                  <Box>
                    Store link added:{' '}
                    <Box
                      sx={{
                        display: 'inline',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        window.open(appDetail.url)
                      }}
                    >
                      {appDetail.url}
                    </Box>
                  </Box>
                ),
              })
            : clickTip({
                title: 'Game Submission',
                content:
                  appDetail.game_process_status.game_link === 1 ? (
                    <Box sx={{ marginBottom: '130px' }}>
                      Store link added:{' '}
                      <Box
                        sx={{
                          display: 'inline',
                          color: theme.palette.primary.main,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          window.open(appDetail.url)
                        }}
                      >
                        {appDetail.url}
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ marginTop: '-8px' }}>
                      <Box
                        sx={{
                          marginBottom: '12px',
                        }}
                      >
                        Please add the public link of the game on the store
                        (Google Play, App Store). If the game is not released
                        yet, this step can be completed later.
                      </Box>
                      <TextField
                        className={classes.formInputSearch}
                        variant="outlined"
                        onChange={urlChange}
                        value={storeUrl}
                        placeholder="Enter game link (Eg. https://play.google.com/…)"
                      />
                    </Box>
                  ),
                buttonText:
                  appDetail.game_process_status.game_link === 1 ? '' : 'Add',
                buttonLoading: state.editGameLoading,
                buttonClick: () => {
                  editGame(
                    {
                      url: storeUrl,
                      app_key: appDetail.app_key,
                      test_ads_status: appDetail.test_ads_status,
                      ads_format: appDetail.ads_format,
                      nativeads_size: appDetail.nativeads_size,
                    },
                    props.index
                  )
                },
                buttonDisabled: storeUrl.length <= 2,
              })}
        </ProgressItemView>
        {/* SDK Integration */}
        <ProgressItemView
          icon={SVGIcon.ProgressThreeSVG}
          status={appDetail.game_process_status.sdk_integrate_checklist}
          canClick={
            appDetail.game_process_status.sdk_integrate_checklist === -1
          }
          onClick={() => {
            if (appDetail.game_process_status.sdk_integrate_checklist === -1) {
              progressSdkIconButton(appDetail.app_key, props.index)
            }
          }}
          loading={state.progressButtonLoading}
        >
          {appDetail.game_process_status.sdk_integrate_checklist === -1
            ? hoverTip({
                title: 'SDK Integration Checklist',
                content: <Box>Please click to check the detail.</Box>,
              })
            : appDetail.game_process_status.sdk_integrate_checklist === 2
            ? clickTip({
                title: 'SDK Integration Checklist',
                content: (
                  <Box
                    sx={{
                      margin: '-5px 0 10px',
                    }}
                  >
                    You&apos;ve completed the SDK integration checklist! If you
                    need further help please refer to our{' '}
                    <Box
                      sx={{
                        display: 'inline',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={openSdkIntegration2Url}
                    >
                      documentation
                    </Box>{' '}
                    or{' '}
                    <Box
                      sx={{
                        display: 'inline',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        window.Intercom && window.Intercom('show')
                      }}
                    >
                      contact support
                    </Box>
                    .
                  </Box>
                ),
              })
            : clickTip({
                title: 'SDK Integration Checklist',
                content: (
                  <Box>
                    <Box>
                      To integrate MAS, please go through each step & their
                      documentation below. Once done, please check them off and
                      proceed to next steps.
                    </Box>
                    <Box display="flex" margin="6px 0 4px -10px">
                      <Box
                        onClick={() => {
                          sdkCheckedOneChange('', !sdkCheckedOne)
                        }}
                        sx={{
                          height: '16px',
                          width: '16px',
                          padding: '14px 16px',
                          cursor: 'pointer',
                        }}
                      >
                        {sdkCheckedOne ? (
                          <SVGIcon.SdkCheckedBoxSVG />
                        ) : (
                          <SVGIcon.SdkUnCheckedBoxSVG
                            color={
                              theme.palette.mode === 'dark' ? '#D5D6DA' : ''
                            }
                          />
                        )}
                      </Box>
                      <Box>
                        Download the{' '}
                        <Box
                          sx={{
                            display: 'inline-block',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={openSdkUrl}
                        >
                          correct type of MAS SDK
                        </Box>{' '}
                        (Standard, Family, Lite)
                      </Box>
                    </Box>
                    <Box display="flex" marginLeft="-10px">
                      <Box
                        onClick={() => {
                          sdkCheckedTwoChange('', !sdkCheckedTwo)
                        }}
                        sx={{
                          height: '16px',
                          width: '16px',
                          padding: '14px 16px',
                          cursor: 'pointer',
                        }}
                      >
                        {sdkCheckedTwo ? (
                          <SVGIcon.SdkCheckedBoxSVG />
                        ) : (
                          <SVGIcon.SdkUnCheckedBoxSVG
                            color={
                              theme.palette.mode === 'dark' ? '#D5D6DA' : ''
                            }
                          />
                        )}
                      </Box>
                      <Box>
                        Configure Privacy dialog / Legal frameworks{' '}
                        <Box
                          sx={{
                            display: 'inline-block',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={openSdkIntegrationUrl}
                        >
                          (eg. COPPA, GDPR)
                        </Box>{' '}
                        correctly
                      </Box>
                    </Box>
                    <Box display="flex" marginLeft="-10px">
                      <Box
                        onClick={() => {
                          sdkCheckedThreeChange('', !sdkCheckedThree)
                        }}
                        sx={{
                          height: '16px',
                          width: '16px',
                          padding: '14px 16px',
                          cursor: 'pointer',
                        }}
                      >
                        {sdkCheckedThree ? (
                          <SVGIcon.SdkCheckedBoxSVG />
                        ) : (
                          <SVGIcon.SdkUnCheckedBoxSVG
                            color={
                              theme.palette.mode === 'dark' ? '#D5D6DA' : ''
                            }
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          lineHeight: '44px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'inline-block',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={openSdkIntegration2Url}
                        >
                          Integrate
                        </Box>{' '}
                        MAS SDK
                      </Box>
                    </Box>
                    <Box>
                      If you’re facing any issues while integrating?{' '}
                      <Box
                        sx={{
                          display: 'inline',
                          color: theme.palette.primary.main,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          window.Intercom && window.Intercom('show')
                        }}
                      >
                        Please connect with our support
                      </Box>
                    </Box>
                  </Box>
                ),
                buttonText: 'Integration Complete',
                buttonLoading: state.progressButtonLoading,
                buttonClick: () => {
                  progressButton(
                    'sdk_integrate_checklist',
                    appDetail.app_key,
                    props.index,
                    2
                  )
                },
                buttonDisabled:
                  !sdkCheckedOne || !sdkCheckedTwo || !sdkCheckedThree,
              })}
        </ProgressItemView>
        {/* Test Device */}
        {/* no_device = Θ # no test devices are added
        exist = 2 # The user has clicked "Test ads work!" and the test mode is off
        not_clicked_and_opened_test = 12 # test devices are added + the user has not clicked "Test ads work!" + app/game is in test mode 
        clicked_and_opened_test = 13 # test devices are added + the user has clicked "Test ads work!" + app/game is in test mode 
        without_test_and_not_clicked = 1 # test devices are added but the app is not in test mode + the user hasn't clicked "Test ads work!" */}
        <ProgressItemView
          icon={SVGIcon.ProgressFourSVG}
          status={
            appDetail.game_process_status.test_device === 0 ||
            appDetail.game_process_status.test_device === 2
              ? appDetail.game_process_status.test_device
              : 1
          }
        >
          {clickTip({
            title: 'Test Ads',
            content:
              appDetail.game_process_status.test_device === 2 ? (
                <Box marginBottom="6px">
                  <Box marginBottom="12px">
                    Testing ads in your game is optional, but highly
                    recommended. Once tested, make sure{' '}
                    <Box
                      sx={{
                        display: 'inline',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        navigate(
                          `/dash/games/manage/${appDetail.app_key}/ads-management`
                        )
                      }}
                    >
                      Display Ads
                    </Box>{' '}
                    is enabled in Ads Management.
                  </Box>
                  Test device is added.
                </Box>
              ) : appDetail.game_process_status.test_device === 0 ? (
                <Box marginBottom="6px">
                  <Box marginBottom="12px">
                    Testing ads in your game is optional, but highly
                    recommended. Once tested, make sure{' '}
                    <Box
                      sx={{
                        display: 'inline',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        navigate(
                          `/dash/games/manage/${appDetail.app_key}/ads-management`
                        )
                      }}
                    >
                      Display Ads
                    </Box>{' '}
                    is enabled in Ads Management.
                  </Box>
                  <Box>Please add a test device for {appDetail.platform}.</Box>
                </Box>
              ) : (
                <Box>
                  <Box marginBottom="12px">
                    Testing ads in your game is optional, but highly
                    recommended. Once tested, make sure{' '}
                    <Box
                      sx={{
                        display: 'inline',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        navigate(
                          `/dash/games/manage/${appDetail.app_key}/ads-management`
                        )
                      }}
                    >
                      Display Ads
                    </Box>{' '}
                    is enabled in Ads Management.
                  </Box>
                  {appDetail.game_process_status.test_device === 13 ? (
                    <Box marginBottom="12px">
                      Your game is in test mode. Please confirm whether you can
                      see ads in your game on your test device.
                    </Box>
                  ) : appDetail.game_process_status.test_device === 12 ? (
                    <Box marginBottom="12px">
                      Your game is in test mode. Before releasing the game you
                      must{' '}
                      <Box
                        sx={{
                          display: 'inline',
                          color: theme.palette.primary.main,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          navigate(
                            `/dash/games/manage/${appDetail.app_key}/ads-management`
                          )
                        }}
                      >
                        disable test mode
                      </Box>
                      .
                    </Box>
                  ) : (
                    <Box />
                  )}
                  <Box marginBottom="12px">
                    Cannot see test ads on your test device?{' '}
                    <Box
                      sx={{
                        display: 'inline',
                        color: theme.palette.primary.main,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        window.Intercom && window.Intercom('show')
                      }}
                    >
                      Please connect with our support
                    </Box>
                  </Box>
                </Box>
              ),
            buttonText:
              appDetail.game_process_status.test_device === 2
                ? !appDetail.previous_app
                  ? 'Test ads not working'
                  : ''
                : appDetail.game_process_status.test_device === 1 ||
                  appDetail.game_process_status.test_device === 12
                ? 'Test Ads Works!'
                : appDetail.game_process_status.test_device === 13
                ? 'Disable test mode'
                : 'Add test device',
            buttonLoading: state.progressButtonLoading,
            buttonClick: () => {
              if (
                appDetail.game_process_status.test_device === 2 ||
                appDetail.game_process_status.test_device === 1 ||
                appDetail.game_process_status.test_device === 12
              ) {
                progressButton(
                  'test_device',
                  appDetail.app_key,
                  props.index,
                  appDetail.game_process_status.test_device === 2 ? 0 : 2
                )
              } else if (appDetail.game_process_status.test_device === 13) {
                navigate(
                  `/dash/games/manage/${appDetail.app_key}/ads-management`
                )
              } else {
                navigate('/dash/testing')
              }
            },
          })}
        </ProgressItemView>
        {/* App-ads.txt */}
        <ProgressItemView
          icon={SVGIcon.ProgressFiveSVG}
          status={
            appDetail.game_process_status.app_ads_txt === -2
              ? 0
              : appDetail.game_process_status.app_ads_txt
          }
        >
          {appDetail.game_process_status.app_ads_txt === 2
            ? hoverTip({
                title: 'App-ads.txt file',
                content: (
                  <Box>
                    <Box>The App-ads.txt file is added.</Box>
                    <Box>Status: Detected</Box>
                  </Box>
                ),
              })
            : clickTip({
                title: 'App-ads.txt file',
                content:
                  appDetail.game_process_status.app_ads_txt === -2 ? (
                    <Box marginTop="-8px">
                      We were not able to verify ownership of your game using
                      app-ads.txt. Ads will only be served once we verify that
                      the game belongs to you.
                      <Box marginTop="8px">
                        Check out{' '}
                        <Box
                          sx={{
                            display: 'inline',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            window.open(
                              'https://developers.yodo1.com/docs/additional-set-up/app-ads/setting-up-your-app-ads-txt/'
                            )
                          }}
                        >
                          app-ads.txt setup documentation
                        </Box>{' '}
                        or{' '}
                        <Box
                          sx={{
                            display: 'inline',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            window.Intercom && window.Intercom('show')
                          }}
                        >
                          contact support
                        </Box>{' '}
                        if you need any help.
                      </Box>
                    </Box>
                  ) : appDetail.game_process_status.app_ads_txt === 1 ? (
                    <Box sx={{ marginBottom: '100px' }}>
                      Please wait while we’re trying to detect your app-ads.txt
                      file.
                      <Box marginTop="16px">
                        Check out{' '}
                        <Box
                          sx={{
                            display: 'inline',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            window.open(
                              'https://developers.yodo1.com/docs/additional-set-up/app-ads/setting-up-your-app-ads-txt/'
                            )
                          }}
                        >
                          app-ads.txt setup documentation
                        </Box>{' '}
                        or{' '}
                        <Box
                          sx={{
                            display: 'inline',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            window.Intercom && window.Intercom('show')
                          }}
                        >
                          contact support
                        </Box>{' '}
                        if you need any help.
                      </Box>
                    </Box>
                  ) : (
                    <Box marginTop="-8px">
                      There seems to be some issue with your app-ads.txt setup.
                      Please make sure all the{' '}
                      <Box
                        sx={{
                          display: 'inline',
                          color: theme.palette.primary.main,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          navigate(
                            `/dash/games/manage/${appDetail.app_key}/issue`
                          )
                        }}
                      >
                        issues
                      </Box>{' '}
                      are fixed and click the button below to restart
                      verification.
                      <Box marginTop="8px">
                        Check out{' '}
                        <Box
                          sx={{
                            display: 'inline',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            window.open(
                              'https://developers.yodo1.com/docs/additional-set-up/app-ads/setting-up-your-app-ads-txt/'
                            )
                          }}
                        >
                          app-ads.txt setup documentation
                        </Box>{' '}
                        or{' '}
                        <Box
                          sx={{
                            display: 'inline',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            window.Intercom && window.Intercom('show')
                          }}
                        >
                          contact support
                        </Box>{' '}
                        if you need any help.
                      </Box>
                    </Box>
                  ),
                buttonText:
                  appDetail.game_process_status.app_ads_txt === 0
                    ? 'Verify app-ads.txt'
                    : '',
                buttonLoading: state.progressButtonLoading,
                buttonClick: () => {
                  progressButton('app_ads_txt', appDetail.app_key, props.index)
                },
              })}
        </ProgressItemView>
        {/* Ad Network Configuration */}
        <>
          {appDetail?.is_whitelist ? (
            <ProgressItemView
              icon={SVGIcon.ProgressSixSVG}
              status={appDetail.game_process_status.ad_config}
              disabled={
                appDetail.game_process_status.game_link < 1 ||
                appDetail.game_process_status.app_ads_txt !== 2 ||
                appDetail.app_termination_status === 'TERMINATION'
              }
              disabledText={hoverTip({
                content:
                  'To kick off ad network configuration, please add the game link in step 2 and verify the app-ads.txt file in step 5.',
              })}
            >
              {clickTip({
                title: 'Ad Network Configuration',
                content: (
                  <Box paddingBottom="20px">
                    {appDetail.game_process_status.ad_config === 2 ? (
                      <Box>All ad networks have been configured</Box>
                    ) : appDetail.game_process_status.ad_config === 1 &&
                      appDetail?.network_status?.default_network === 2 &&
                      appDetail?.network_status?.admob === 0 ? (
                      <Box>
                        <Box marginBottom="16px">Configuring ad networks.</Box>
                        Please{' '}
                        <Box
                          sx={{
                            display: 'inline',
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            window.Intercom && window.Intercom('show')
                          }}
                        >
                          contact support
                        </Box>{' '}
                        to configure AdMob. It may take up to 24-36 hours to
                        configure ad networks.
                      </Box>
                    ) : (
                      <Box>
                        <Box marginBottom="16px">
                          {appDetail?.network_status?.default_network === 1
                            ? 'Default ad network configuration is complete.'
                            : 'Default ad network configuration is in progress.'}
                        </Box>
                        <Box marginBottom="16px">
                          {appDetail?.network_status?.admob === 1 ? (
                            'AdMob configuration is complete.'
                          ) : appDetail?.network_status?.admob === 2 ? (
                            'AdMob configuration is in progress.'
                          ) : appDetail?.network_status?.admob === 3 ||
                            appDetail?.network_status?.admob === 4 ? (
                            <>
                              AdMob configuration failed please{' '}
                              <Box
                                sx={{
                                  display: 'inline',
                                  color: theme.palette.primary.main,
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  window.Intercom && window.Intercom('show')
                                }}
                              >
                                contact support
                              </Box>{' '}
                              to know more.
                            </>
                          ) : (
                            <>
                              Please{' '}
                              <Box
                                sx={{
                                  display: 'inline',
                                  color: theme.palette.primary.main,
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  window.Intercom && window.Intercom('show')
                                }}
                              >
                                contact support
                              </Box>{' '}
                              to configure AdMob.
                            </>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                ),
              })}
            </ProgressItemView>
          ) : (
            <ProgressItemView
              icon={SVGIcon.ProgressSixSVG}
              status={appDetail.game_process_status.ad_config}
              disabled={
                appDetail.game_process_status.game_link < 1 ||
                appDetail.game_process_status.app_ads_txt !== 2 ||
                appDetail.app_termination_status === 'TERMINATION'
              }
              disabledText={hoverTip({
                content:
                  'To kick off ad network configuration, please add the game link in step 2 and verify the app-ads.txt file in step 5.',
              })}
            >
              {clickTip({
                title: 'Ad Network Configuration',
                content: (
                  <Box paddingBottom="20px">
                    <Box>
                      Status:{' '}
                      {appDetail.game_process_status.ad_config === 1
                        ? 'Configuring ad networks'
                        : appDetail.game_process_status.ad_config === 0
                        ? 'No game link'
                        : 'Configured'}
                    </Box>
                    <Box
                      sx={{
                        marginTop: '16px',
                      }}
                    >
                      {appDetail.game_process_status.ad_config === 1 &&
                        ' It may take up to 24-36 hours to configure ad networks.'}
                    </Box>
                  </Box>
                ),
              })}
            </ProgressItemView>
          )}
        </>
        {/* Release The Game */}
        <ProgressItemView
          icon={SVGIcon.ProgressSevenSVG}
          status={appDetail.game_process_status.game_released}
          disabled={
            (appDetail.game_process_status.game_released > 0 &&
              dayjs(appDetail.created_at) >= dayjs('2023-05-31') &&
              appDetail.game_process_status.pre_metric < 2) ||
            (appDetail.game_process_status.pre_metric < 2 &&
              appDetail.game_process_status.game_released === 0) ||
            appDetail.game_process_status.game_link < 1 ||
            appDetail.game_process_status.ad_config === 0 ||
            (appDetail?.is_whitelist &&
              appDetail.game_process_status.ad_config === 1 &&
              appDetail?.network_status?.admob !== 1) ||
            (!appDetail?.is_whitelist &&
              appDetail.game_process_status.ad_config === 1) ||
            appDetail.app_termination_status === 'TERMINATION'
          }
          disabledText={hoverTip({
            content:
              'To release the game, please submit Pre-MAS metrics in step 1, game link in step 2 and allow the network config to finish.',
          })}
        >
          {appDetail.game_process_status.game_released === 2
            ? hoverTip({
                title: 'Game Released',
                content: (
                  <Box textAlign="center">
                    <Box marginBottom="6px">
                      MAS SDK version: {appDetail.sdk_version || '-'}
                    </Box>
                    <Box>App version: {appDetail.app_version || 'None'}</Box>
                  </Box>
                ),
              })
            : clickTip({
                title: 'Release game!',
                content:
                  appDetail.game_process_status.game_released === 1 ? (
                    <Box
                      sx={{
                        marginTop: '-8px',
                        marginBottom: '80px',
                      }}
                    >
                      Congratulations! You’ve marked your game as released! This
                      state will automatically turn green once we detect MAS SDK
                      in your game.
                    </Box>
                  ) : canReleaseGame ? (
                    <Box
                      sx={{
                        marginTop: '-8px',
                        marginBottom: '80px',
                      }}
                    >
                      You’ve completed all the steps necessary to release the
                      game with MAS!
                    </Box>
                  ) : (
                    <Box sx={{ marginTop: '-8px', marginBottom: '70px' }}>
                      <Box
                        sx={{
                          marginBottom: '12px',
                        }}
                      >
                        Please complete all of the steps before releasing the
                        game.
                      </Box>
                      <Box>
                        If the game is being released for the first time, make
                        sure to add the correct public store link of the game
                        once it is live.
                      </Box>
                    </Box>
                  ),
                buttonText:
                  canReleaseGame &&
                  appDetail.game_process_status.game_released === 0
                    ? 'Game Released!'
                    : '',
                buttonLoading: state.progressButtonLoading,
                buttonClick: () => {
                  progressButton(
                    'game_released',
                    appDetail.app_key,
                    props.index,
                    true
                  )
                },
              })}
        </ProgressItemView>
      </Box>
    </Box>
  )
}

export default ProgressView
