import { createContext, useReducer, useEffect, useContext } from 'react'
import { GlobalContext } from '../global'
import { GlobalStoreContext } from '../globalStore'
import { getLastTimeRange, numFixed, transformUTC0ToLocalTime } from 'src/utils'
import { getSessionApi } from 'src/api/cohorts'

type Props = {
  data: any
  holdData: any
  loading: boolean
  gameSelect: any[]
  country: any[]
  timeShow: string
  type: string
  platform: any[]
  time: [string, string]
  placementOption: any[]
  placement: any[]
  filterDialogOpen: boolean
  gameListObject: any
  tabType: string
  chartList: Array<any>
  checkedChartLine: Array<string>
  resetIndex: number
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props
const getTimeRange = (range: [string, string] | number): [string, string] => {
  // custom date range
  return typeof range === 'number' ? getLastTimeRange(range) : range
}

const initialState: Props = {
  loading: false,
  data: {},
  holdData: {},
  gameSelect: [],
  country: [],
  timeShow: 'last_month',
  type: 'overall',
  platform: [],
  time: getLastTimeRange(30),
  placementOption: [],
  placement: [],
  filterDialogOpen: false,
  gameListObject: {},
  tabType: 'rate',
  chartList: [
    {
      label: 'All',
      value: 'All',
      color: '#FF8E00',
    },
  ],
  checkedChartLine: [],
  resetIndex: 0,
}

const colorList = ['#FF8E00', '#90cc75', '#f07474', '#5a70b5']
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'data':
      const type =
        prevState.tabType === 'sessions' ? 'session_count' : 'session_length'
      const allData = action.payload.data
      if (!allData) {
        return {
          ...prevState,
        }
      }
      const keys = Object.keys(allData)
      const labels = action.payload.days
      if (!keys.length) {
        return {
          ...prevState,
          data: {},
        }
      }
      const datasets: any = []
      const chartList: any = []
      const checkedChartLine: any = []
      keys.forEach((item: any, i: number) => {
        const color: string =
          colorList[i] ||
          '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
        const label = prevState.gameListObject[item]?.name || item
        datasets.push({
          label,
          data: allData[item][type],
          pointRadius: 2.5,
          borderColor: color,
          backgroundColor: color,
          tension: 0.2,
          hitRadius: 10,
        })
        chartList.push({
          label,
          value: label,
          color,
          total: allData[item][type]?.reduce((a: number, b: number) => a + b),
        })
        checkedChartLine.push(label)
      })
      const data = {
        labels,
        datasets,
      }
      return {
        ...prevState,
        data,
        chartList: chartList.sort((a: any, b: any) => b.total - a.total),
        checkedChartLine,
      }
    case 'time':
      if (action.payload === 'Last Week') {
        action.payload = getTimeRange(7)
      } else if (action.payload === 'Last Month') {
        action.payload = getTimeRange(30)
      } else if (action.payload === 'Last 3 Month') {
        action.payload = getTimeRange(90)
      } else {
        action.payload = getTimeRange(action.payload)
      }
      return { ...prevState, time: action.payload }

    case 'holdData':
      const holdData = JSON.parse(JSON.stringify(action.payload))
      const disabled = action.payload?.tabDisabled || false
      const untilTime = action.payload?.untilTime || null
      if (!holdData.relative_change) {
        return { ...prevState, holdData }
      }
      let trend = 'up'
      if (holdData.relative_change.session_count >= 0) {
        trend = 'up'
      } else {
        trend = 'down'
      }
      holdData.relative_change.session_count = {
        value: numFixed(Math.abs(holdData.relative_change.session_count)),
        trend,
        disabled,
        untilTime,
      }
      if (holdData.relative_change.session_length >= 0) {
        trend = 'up'
      } else {
        trend = 'down'
      }
      holdData.relative_change.session_length = {
        value: numFixed(Math.abs(holdData.relative_change.session_length)),
        trend,
        disabled,
        untilTime,
      }
      return { ...prevState, holdData }
    case 'loading':
    case 'gameListObject':
    case 'gameSelect':
    case 'country':
    case 'timeShow':
    case 'type':
    case 'platform':
    case 'placementOption':
    case 'placement':
    case 'filterDialogOpen':
    case 'tabType':
    case 'checkedChartLine':
    case 'chartList':
    case 'resetIndex':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  getSessions: () => void
  changeTabType: (tabType: string) => void
}

export const SessionsContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  getSessions: () => {},
  changeTabType: (tabType: string) => {},
})

export const SessionsContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: GlobalState, errorCatch } = useContext(GlobalContext)
  const { state: globalStoreState, dispatch: globalStoreDispatch } =
    useContext(GlobalStoreContext)
  // handlers
  const getSessions = () => {
    dispatch({ type: 'loading', payload: true })
    if (globalStoreState.fromWelcome) {
      dispatch({ type: 'time', payload: globalStoreState.time })
      dispatch({ type: 'timeShow', payload: 'custom' })
      dispatch({ type: 'gameSelect', payload: globalStoreState.gameSelect })
    }
    const data = {
      from_date: globalStoreState.fromWelcome
        ? globalStoreState.time[0]
        : state.time[0],
      to_date: globalStoreState.fromWelcome
        ? globalStoreState.time[1]
        : state.time[1],
      app_keys: globalStoreState.fromWelcome
        ? globalStoreState.gameSelect.map(item => item.app_key)
        : state.gameSelect.map(item => item.app_key),
      platform: state.platform.map(item => item.value),
      countries: state.country.map(item => item.code),
    }
    globalStoreDispatch({
      type: 'sessionsData',
      payload: {
        filter: {
          time: globalStoreState.fromWelcome
            ? globalStoreState.time
            : state.time,
          timeShow: state.timeShow,
          gameSelect: globalStoreState.fromWelcome
            ? globalStoreState.gameSelect
            : state.gameSelect,
          platform: state.platform,
          country: state.country,
        },
      },
    })
    getSessionApi(data)
      .then(({ data }: any) => {
        if (!data.days || !data.data) {
          globalStoreDispatch({
            type: 'sessionsData',
            payload: {
              data: {},
              holdData: {},
            },
          })
          return
        }
        dispatch({ type: 'data', payload: data })
        dispatch({ type: 'holdData', payload: data })
        globalStoreDispatch({
          type: 'sessionsData',
          payload: {
            data: data,
            holdData: data,
          },
        })
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 503 &&
          err?.response?.data?.code === 'trino_migration'
        ) {
          let untilTime = null
          if (err?.response?.data?.extra?.after) {
            untilTime = transformUTC0ToLocalTime(
              err?.response?.data?.extra?.after,
              'Do MMM YYYY hh:mm a'
            )
          }
          dispatch({
            type: 'holdData',
            payload: { tabDisabled: true, untilTime },
          })
          globalStoreDispatch({
            type: 'sessionsData',
            payload: {
              data: {},
              holdData: { tabDisabled: true, untilTime },
            },
          })
        } else {
          errorCatch(err)
          globalStoreDispatch({
            type: 'sessionsData',
            payload: {
              data: {},
              holdData: {},
            },
          })
        }
      })
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
  }

  const changeTabType = (tabType: string) => {
    dispatch({ type: 'tabType', payload: tabType })
    if (!state.holdData.days || !state.holdData.data) {
      return
    }
    if (
      tabType === 'retention' ||
      tabType === 'churn-rate' ||
      tabType === 'stickiness'
    ) {
      return
    }
    globalStoreDispatch({
      type: 'sessionsData',
      payload: {
        tabType: tabType,
      },
    })

    dispatch({ type: 'data', payload: state.holdData })
  }
  useEffect(() => {
    dispatch({ type: 'gameListObject', payload: GlobalState.gameListObject })
  }, [GlobalState.gameListObject])
  useEffect(() => {
    if (globalStoreState.sessionsData.filter) {
      Object.keys(globalStoreState.sessionsData.filter).forEach(
        (item: string) => {
          dispatch({
            type: item,
            payload: globalStoreState.sessionsData.filter[item],
          })
        }
      )
      Object.keys(globalStoreState.sessionsData)
        .filter((item: string) => item !== 'filter')
        .forEach((item: string) => {
          dispatch({
            type: item,
            payload: globalStoreState.sessionsData[item],
          })
        })
      return
    }
    getSessions()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (state.resetIndex) {
      getSessions()
    }
    // eslint-disable-next-line
  }, [state.resetIndex])
  // returns
  return (
    <SessionsContext.Provider
      value={{
        state,
        dispatch,
        getSessions,
        changeTabType,
      }}
    >
      {children}
    </SessionsContext.Provider>
  )
}
