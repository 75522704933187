// SVG
import info from 'src/images/svg/info.svg'
import success from 'src/images/svg/success.svg'
import warning from 'src/images/svg/warning.svg'
import loading from 'src/images/svg/loading.svg'
import warningIcon from 'src/images/svg/warningIcon.svg'
import informationIcon from 'src/images/svg/informationIcon.svg'
import doneIcon from 'src/images/svg/doneIcon.svg'
import authBackgroundIcon from 'src/images/svg/authBackground.svg'
import yodo1LogoIcon from 'src/images/svg/yodo1Logo.svg'
import masLogoIcon from 'src/images/svg/masLogo.svg'
// SVGIcon
import logoSVG from 'src/images/svgIcon/logo'
import reward_videoSVG from 'src/images/svgIcon/reward_video'
import native_adsSVG from 'src/images/svgIcon/native_ads'
import bannerSVG from 'src/images/svgIcon/banner'
import interstitialSVG from 'src/images/svgIcon/interstitial'
import adPlacementSVG from 'src/images/svgIcon/adPlacement'
import financeSVG from 'src/images/svgIcon/finance'
import GameplayInsightsSVG from 'src/images/svgIcon/gameplayInsights'
import supportSVG from 'src/images/svgIcon/support'
import managementSVG from 'src/images/svgIcon/management'
import memberSVG from 'src/images/svgIcon/member'
import overviewSVG from 'src/images/svgIcon/overview'
import reportSVG from 'src/images/svgIcon/report'
import testingSVG from 'src/images/svgIcon/testing'
import GameDefault from 'src/images/svgIcon/gameDefault'
import NoDataSVG from 'src/images/svgIcon/noData'
import NotificationsSVG from 'src/images/svgIcon/notifications'
import PhotoSVG from 'src/images/svgIcon/photo'
import CohortsSVG from 'src/images/svgIcon/cohorts'
import LightSVG from 'src/images/svgIcon/light'
import DarkSVG from 'src/images/svgIcon/dark'
import DocumentationSVG from 'src/images/svgIcon/documentation'
import GrowSVG from 'src/images/svgIcon/grow'
import LowerSVG from 'src/images/svgIcon/lower'
import ChevronDownSVG from 'src/images/svgIcon/chevronDown'
import SearchIconSVG from 'src/images/svgIcon/searchIcon'
import CheckedBoxSVG from 'src/images/svgIcon/checkedBox'
import UncheckedBoxSVG from 'src/images/svgIcon/uncheckedBox'
import DashboardSVG from 'src/images/svgIcon/dashboard'
import UpRightArrow from 'src/images/svgIcon/upRightArrow'
import GoUpHover from 'src/images/svgIcon/goUpHover'
import GoDownHover from 'src/images/svgIcon/goDownHover'
import EngagementSVG from 'src/images/svgIcon/engagement'
import BarChartIconSVG from 'src/images/svgIcon/barChartIcon'
import LineChartIconSVG from 'src/images/svgIcon/lineChartIcon'
import ViewByAllSVG from 'src/images/svgIcon/viewByAll'
import ViewByAppsSVG from 'src/images/svgIcon/viewByApps'
import ViewByCountriesSVG from 'src/images/svgIcon/viewByCountries'
import ViewByPlatformsSVG from 'src/images/svgIcon/viewByPlatforms'
import ViewByAdTypesSVG from 'src/images/svgIcon/viewByAdTypes'
import OpenAdsSVG from 'src/images/svgIcon/openads'
import RewardinterSVG from 'src/images/svgIcon/rewardinter'
import ProgressOneSVG from 'src/images/svgIcon/progressOne'
import ProgressTwoSVG from 'src/images/svgIcon/progressTwo'
import ProgressThreeSVG from 'src/images/svgIcon/progressThree'
import ProgressFourSVG from 'src/images/svgIcon/progressFour'
import ProgressFiveSVG from 'src/images/svgIcon/progressFive'
import ProgressSixSVG from 'src/images/svgIcon/progressSix'
import ProgressSevenSVG from 'src/images/svgIcon/progressSeven'
import ProgressBoxSVG from 'src/images/svgIcon/progressBox'
import GameBannedFailureSVG from 'src/images/svgIcon/gameBannedFailure'
import GameBannedWarningSVG from 'src/images/svgIcon/gameBannedWarning'
import SdkUnCheckedBoxSVG from 'src/images/svgIcon/sdkUnCheckedBox'
import SdkCheckedBoxSVG from 'src/images/svgIcon/sdkCheckedBox'
import AdPlacementIOSSVG from 'src/images/svgIcon/adPlacementIOS'
import AdPlacementAndroidSVG from 'src/images/svgIcon/adPlacementIAndroid'
import TriangleDownSVG from 'src/images/svgIcon/triangleDown'
import SettingSVG from 'src/images/svgIcon/setting'
import TitleInfoOneSVG from 'src/images/svgIcon/titleInfoOne'
import TitleInfoOneDarkSVG from 'src/images/svgIcon/titleInfoOneDark'
import TitleInfoTwoSVG from 'src/images/svgIcon/titleInfoTwo'
import TitleInfoTwoDarkSVG from 'src/images/svgIcon/titleInfoTwoDark'
import TitleInfoThreeSVG from 'src/images/svgIcon/titleInfoThree'
import TitleInfoThreeDarkSVG from 'src/images/svgIcon/titleInfoThreeDark'
import CurrentBalanceSVG from 'src/images/svgIcon/currentBalance'
import PortraitSVG from 'src/images/svgIcon/portrait'
import LandscapeSVG from 'src/images/svgIcon/landscape'
import BothSVG from 'src/images/svgIcon/both'
import GraphSVG from 'src/images/svgIcon/graph'
import bannerInnerSVG from 'src/images/svgIcon/bannerInner'
import reward_videoInnerSVG from 'src/images/svgIcon/reward_videoInner'
import native_adsInnerSVG from 'src/images/svgIcon/native_adsInner'
import interstitialInnerSVG from 'src/images/svgIcon/interstitialInner'
import OpenAdsInnerSVG from 'src/images/svgIcon/OpenAdsInner'
import RewardinterInnerSVG from 'src/images/svgIcon/RewardinterInner'
import ScienceSVG from 'src/images/svgIcon/science'
import UpvoteSVG from 'src/images/svgIcon/upvote'
import DownvoteSVG from 'src/images/svgIcon/downvote'
import TableIconSVG from 'src/images/svgIcon/tableIcon'
import CompareSVG from 'src/images/svgIcon/compare'
import FanCraftSVG from 'src/images/svgIcon/FanCraft'

// PNG
import android from 'src/images/png/android.png'
import Android from 'src/images/png/android.png'
import imgBg from 'src/images/png/bg.png'
import imgBgLight from 'src/images/png/bg-light.png'
import ios from 'src/images/png/ios.png'
import iOS from 'src/images/png/ios.png'
import Godot from 'src/images/png/Godot.png'
import Unity from 'src/images/png/Unity.png'
import Solar2D from 'src/images/png/Solar2D.png'
import other from 'src/images/png/other.png'
import Buildbox from 'src/images/png/Buildbox.png'
import Flutter from 'src/images/png/Flutter.png'
import ReactNative from 'src/images/png/ReactNative.png'
import UnrealEngine from 'src/images/png/UnrealEngine.png'
import Cocos from 'src/images/png/Cocos2d-x.png'
import tips from 'src/images/png/tips.png'
import bg_left from 'src/images/png/bg_left.png'
import bg_right from 'src/images/png/bg_right.png'
import fancraft from 'src/images/png/fancraft.jpg'

const PNG = {
  android,
  imgBg,
  imgBgLight,
  ios,
  Godot,
  Unity,
  Solar2D,
  iOS,
  Android,
  other,
  Buildbox,
  Flutter,
  ReactNative,
  UnrealEngine,
  Cocos,
  tips,
  bg_left,
  bg_right,
  fancraft,
}

const Icons = {
  info,
  success,
  warning,
  loading,
  warningIcon,
  informationIcon,
  doneIcon,
  authBackgroundIcon,
  yodo1LogoIcon,
  masLogoIcon,
}

const SVGIcon = {
  logoSVG,
  bannerSVG,
  reward_videoSVG,
  native_adsSVG,
  interstitialSVG,
  adPlacementSVG,
  financeSVG,
  supportSVG,
  managementSVG,
  memberSVG,
  overviewSVG,
  GameplayInsightsSVG,
  reportSVG,
  testingSVG,
  GameDefault,
  NoDataSVG,
  NotificationsSVG,
  PhotoSVG,
  CohortsSVG,
  LightSVG,
  DarkSVG,
  DocumentationSVG,
  GrowSVG,
  LowerSVG,
  ChevronDownSVG,
  SearchIconSVG,
  CheckedBoxSVG,
  UncheckedBoxSVG,
  DashboardSVG,
  UpRightArrow,
  GoUpHover,
  GoDownHover,
  EngagementSVG,
  BarChartIconSVG,
  LineChartIconSVG,
  ViewByAllSVG,
  ViewByAppsSVG,
  ViewByCountriesSVG,
  ViewByPlatformsSVG,
  ViewByAdTypesSVG,
  OpenAdsSVG,
  RewardinterSVG,
  ProgressOneSVG,
  ProgressTwoSVG,
  ProgressThreeSVG,
  ProgressFourSVG,
  ProgressFiveSVG,
  ProgressSixSVG,
  ProgressSevenSVG,
  ProgressBoxSVG,
  GameBannedFailureSVG,
  GameBannedWarningSVG,
  SdkUnCheckedBoxSVG,
  SdkCheckedBoxSVG,
  AdPlacementIOSSVG,
  AdPlacementAndroidSVG,
  TriangleDownSVG,
  SettingSVG,
  TitleInfoOneSVG,
  TitleInfoOneDarkSVG,
  TitleInfoTwoSVG,
  TitleInfoTwoDarkSVG,
  TitleInfoThreeSVG,
  TitleInfoThreeDarkSVG,
  CurrentBalanceSVG,
  PortraitSVG,
  LandscapeSVG,
  BothSVG,
  GraphSVG,
  bannerInnerSVG,
  reward_videoInnerSVG,
  native_adsInnerSVG,
  interstitialInnerSVG,
  OpenAdsInnerSVG,
  RewardinterInnerSVG,
  ScienceSVG,
  UpvoteSVG,
  DownvoteSVG,
  TableIconSVG,
  CompareSVG,
  FanCraftSVG,
}
export { PNG, Icons, SVGIcon }
