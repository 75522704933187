import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { MiddleChartContext } from 'src/context/performance/middleChart'
import { PerformanceContext } from 'src/context/performance/index'
import EchartView from './echart'
import ChartSettingView from './chartSetting'
import { LoadingComponent, ChartTypeToggleGroup } from 'src/components'
import { rateTitleFormat } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      contentBottom: {
        width: '100%',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })
const titles = {
  revenue: 'Est. Revenue',
  impressions: 'Impressions',
  arpdau: 'ARPDAU',
  impdau: rateTitleFormat('Imp', 'DAU'),
  dau: 'DAU',
  ecpm: 'eCPM',
}
const MiddleChart = (): JSX.Element => {
  // const
  const { state, dispatch } = useContext(MiddleChartContext)
  const { state: performanceState } = useContext(PerformanceContext)
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  // returns
  return (
    <Box className={classes.contentBottom}>
      {state.middleChartLoading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            padding: '0 4px',
          }}
        >
          <Box marginRight="16px">
            {titles[performanceState.chartType || 'revenue']}
          </Box>
          <ChartSettingView />
        </Box>
        <ChartTypeToggleGroup
          value={state.newChartType}
          onChange={(chartType: string) => {
            dispatch({ type: 'newChartType', payload: chartType })
          }}
        />
      </Box>
      <Box marginTop="26px">
        <EchartView />
      </Box>
    </Box>
  )
}

export default MiddleChart
