const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '16'
  const color = props.color || '#AECBFA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.12 7.12004C8.88 7.12004 8.688 6.92804 8.688 6.68804V3.92004C8.688 3.68004 8.88 3.48804 9.12 3.48804H11.936C12.176 3.48804 12.368 3.68004 12.368 3.92004V6.68804C12.368 6.92804 12.176 7.12004 11.936 7.12004H9.12Z"
        fill={color}
      />
      <path
        d="M10.336 8.75197L8.688 10.528C8.592 10.624 8.592 10.8 8.688 10.896L10.336 12.672C10.448 12.784 10.624 12.784 10.736 12.672L12.384 10.896C12.48 10.8 12.48 10.624 12.384 10.528L10.736 8.75197C10.624 8.63997 10.432 8.63997 10.336 8.75197Z"
        fill={color}
      />
      <path
        d="M5.408 12.512C6.43304 12.512 7.264 11.6811 7.264 10.656C7.264 9.63101 6.43304 8.80005 5.408 8.80005C4.38296 8.80005 3.552 9.63101 3.552 10.656C3.552 11.6811 4.38296 12.512 5.408 12.512Z"
        fill={color}
      />
      <path
        d="M3.776 4.11208L5.2 3.29608C5.328 3.21608 5.488 3.21608 5.632 3.29608L7.056 4.11208C7.184 4.19208 7.264 4.33608 7.264 4.48008V6.11208C7.264 6.25608 7.184 6.40008 7.056 6.48008L5.632 7.29608C5.504 7.37608 5.344 7.37608 5.2 7.29608L3.776 6.48008C3.648 6.40008 3.552 6.25608 3.552 6.11208V4.48008C3.552 4.32008 3.648 4.17608 3.776 4.11208Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
