import { Select, MenuItem, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      selectInput: {
        marginLeft: '10px',
        '&.MuiInputBase-root': {
          border: 'none',
          boxShadow: 'none',
          color: theme.palette.text.secondary,
        },
        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-fontSizeSmall': {
          display: 'none',
        },
        '& .MuiSelect-root': {
          color: theme.palette.text.secondary,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          boxShadow: 'none',
          color: theme.palette.text.secondary,
          backgroundColor: 'transparent',
        },
      },
      radioButtonIcon: {
        width: '8px',
        marginRight: '16px',
      },
    }
  })

const StyledSelect = (props: any) => {
  const theme: Theme = useTheme()
  const useRadioStyle = getUseStyles(theme)
  const classes = useRadioStyle()

  return (
    <Select
      data-testid="style-select"
      className={classes.selectInput}
      {...props}
    >
      {props.options.map((option: any) => (
        <MenuItem key={option.value} value={option.value}>
          {option.value === props.value ? (
            <RadioButtonChecked
              className={classes.radioButtonIcon}
              style={{ width: '8px' }}
              fontSize="small"
            />
          ) : (
            <RadioButtonUnchecked
              className={classes.radioButtonIcon}
              style={{ width: '8px' }}
              fontSize="small"
            />
          )}
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default StyledSelect
