const SVG = (props: any) => {
  const width = props.width || '96'
  const height = props.height || '42'
  const color = props.color || '#102542'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 96 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 38.1235C0.00026404 38.2671 0.029258 38.4092 0.0853288 38.5418C0.1414 38.6744 0.223449 38.7948 0.32679 38.8961C0.430132 38.9975 0.552741 39.0778 0.68762 39.1325C0.822499 39.1873 0.967005 39.2153 1.11289 39.215H7.81838C8.11247 39.215 8.39452 39.1 8.60247 38.8953C8.81042 38.6906 8.92725 38.413 8.92725 38.1235V38.0522C5.88948 37.5989 2.90094 36.8694 0 35.873V38.1235Z"
        fill="#FF8E00"
      />
      <path
        d="M89.8967 19.4278C87.4515 18.1639 84.5677 17.5181 81.7784 16.8901C80.2912 16.5553 78.2385 16.2562 76.7795 15.749C74.5255 17.5768 72.1777 19.3175 69.7359 20.971C70.4834 21.5868 71.3037 22.1116 72.179 22.534C74.4994 23.677 77.2303 24.2911 79.8726 24.8835C82.1286 25.3926 84.463 25.9156 85.9261 26.6664C87.0329 27.2389 87.3348 28.8792 86.4916 29.9747C85.8637 30.8007 84.2175 30.987 83.5695 31.0028H70.714L67.6168 22.3577C58.7796 28.022 49.1149 32.3236 38.9574 35.1134L38.571 35.2164C35.763 35.9705 32.9254 36.6011 30.0583 37.1083V38.1225C30.0583 38.2662 30.0872 38.4085 30.1431 38.5412C30.1991 38.6739 30.2812 38.7944 30.3846 38.8958C30.4879 38.9972 30.6107 39.0775 30.7457 39.1321C30.8807 39.1868 31.0253 39.2146 31.1712 39.2141H46.0835L47.8947 34.3666H62.966L64.7028 39.2141H83.5997C84.2578 39.2141 89.1641 39.0972 92.5471 35.7136C93.9779 34.2853 95.6503 31.7259 95.5094 27.6648C95.4168 25.1826 94.3744 21.7416 89.8967 19.4278Z"
        fill="#FF8E00"
      />
      <path
        d="M94.8848 3.93726H88.8756C86.4177 6.83568 83.774 9.57638 80.9606 12.1426H94.8888C95.0347 12.1423 95.1791 12.1138 95.3138 12.0586C95.4485 12.0034 95.5708 11.9226 95.6737 11.8209C95.7767 11.7192 95.8583 11.5985 95.9139 11.4657C95.9695 11.333 95.9979 11.1907 95.9977 11.0471V5.03474C95.9985 4.89057 95.9703 4.74766 95.9146 4.61429C95.859 4.48091 95.7771 4.3597 95.6736 4.25766C95.5702 4.15562 95.4472 4.07476 95.3118 4.01977C95.1764 3.96478 95.0313 3.93674 94.8848 3.93726Z"
        fill={color}
      />
      <path
        d="M37.8462 4.93661H27.7578C27.5334 4.93628 27.3143 5.00295 27.1292 5.12781C26.9442 5.25266 26.802 5.42982 26.7214 5.6359L19.553 23.7998L12.3122 5.626C12.2308 5.42113 12.0886 5.24518 11.904 5.12113C11.7195 4.99709 11.5013 4.93071 11.2778 4.93066H1.11289C0.817731 4.93066 0.534663 5.04608 0.325957 5.25153C0.11725 5.45697 0 5.73562 0 6.02616V32.0546C2.89081 33.1485 5.87972 33.9722 8.92725 34.515V17.0109L15.1779 33.7325C15.2595 33.9373 15.4018 34.1131 15.5863 34.2371C15.7708 34.3611 15.9889 34.4276 16.2123 34.4279H22.9883C23.2124 34.4274 23.4312 34.3604 23.6161 34.2356C23.801 34.1109 23.9434 33.9342 24.0247 33.7286L30.062 17.306V33.9108C33.0634 33.3799 36.034 32.6922 38.9611 31.8506V6.03211C38.9614 5.888 38.9327 5.74527 38.8767 5.61211C38.8208 5.47895 38.7386 5.35799 38.635 5.25619C38.5314 5.15438 38.4084 5.07374 38.273 5.01889C38.1377 4.96405 37.9926 4.93608 37.8462 4.93661Z"
        fill={color}
      />
      <path
        d="M78.2523 3.93726C77.6485 3.93726 72.3699 4.02244 69.0392 7.51298C67.7432 8.87393 66.26 11.2769 66.5639 14.9655C66.6535 16.1106 66.9744 17.2266 67.5078 18.2481C73.5661 14.082 79.1495 9.2839 84.1608 3.93726H78.2523Z"
        fill={color}
      />
      <path
        d="M60.3035 4.85767H50.9134C50.6115 4.85736 50.3167 4.94842 50.069 5.1185C49.8214 5.28858 49.6328 5.52947 49.5289 5.80855L39.9335 31.5617C43.4794 30.5016 46.9572 29.2322 50.3479 27.7601L55.5702 13.7524L59.0598 23.4871C61.5741 22.0938 64.0232 20.5974 66.4073 18.9981L61.6941 5.82836C61.5932 5.54462 61.4051 5.29879 61.156 5.12489C60.9068 4.95098 60.609 4.8576 60.3035 4.85767Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
