import { TextField, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Skeleton, useTheme, Theme } from '@material-ui/core'
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
  AddCircle as AddCircleIcon,
} from '@material-ui/icons'
import { useContext, useEffect, useState } from 'react'
import { RolesContext } from 'src/context/Roles'
import { GlobalContext } from 'src/context/global'

import {
  StylePaper,
  NoDataView,
  DeleteDialog,
  BorderStyleSelectMultiple,
  PopoverTip,
} from 'src/components'
import DialogView from './dialog'
import EditDrawerView from './edit'
import { getDateEnglishFormat } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
        color: theme.palette.text.primary,
      },
      search: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
          height: '40px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.custom.paperBorder} !important`,
          boxShadow:
            theme.palette.mode === 'light'
              ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
              : 'none',
          borderRadius: '6px',
        },
      },
      icon: {
        width: '22px !important',
        height: '18px !important',
      },
      manageBox: {
        width: '80px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '12px',
      },
      deleteBox: {
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '12px',
      },
      manageIcon: {
        fontSize: '16px !important',
        verticalAlign: 'middle',
        color: theme.palette.text.primary,
        marginRight: '6px',
      },
      selectRole: {
        width: '100% !important',
      },
      list: {
        padding: '16px 0',
        borderBottom: '1px solid #F2F2F2',
      },
      email: {
        fontSize: '14px',
        color: theme.palette.text.primary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        flex: 1,
      },
    }
  })

const TableList = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, deleteAccount } = useContext(RolesContext)
  const { state: globalState } = useContext(GlobalContext)
  const arr: any[] = []
  const [tableData, setTableData] = useState(arr)
  const list = Array(20).fill('')
  const [searchValue, setSearchValue] = useState('')
  const timerTemp: any = null
  const [timer, setTimer] = useState(timerTemp)
  const [role, setRole] = useState(arr)
  const [openModel, setOpenModel] = useState(false)
  const [developerKey, setDeveloperKey] = useState('')
  const closeDeleteDialog = () => {
    setOpenModel(false)
  }
  const deleteDeviceBtn = () => {
    deleteAccount(developerKey)
  }
  const handleChangeType = (row: any) => {
    setRole(row)
    dispatch({ type: 'searchData', payload: { role: row, email: searchValue } })
  }
  const valueChange = (e: any) => {
    setSearchValue(e.target.value)
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        dispatch({
          type: 'searchData',
          payload: { email: e.target.value, role },
        })
        clearTimeout(timer)
        setTimer(null)
      }, 1500)
    )
  }
  const openDialog = (row: any) => {
    dispatch({
      type: 'dialogData',
      payload: row,
    })
    dispatch({
      type: 'editDialogOpen',
      payload: true,
    })
  }
  useEffect(() => {
    if (state.data) {
      setTableData(state.data)
    }
  }, [state.data])

  const addAccount = () => {
    dispatch({
      type: 'dialogData',
      payload: {
        email: '',
        role_id: 0,
      },
    })
    dispatch({
      type: 'dialogOpen',
      payload: true,
    })
  }
  useEffect(() => {
    if (state.deleteResult) {
      setOpenModel(false)
      dispatch({
        type: 'editDialogOpen',
        payload: false,
      })
      dispatch({ type: 'deleteResult', payload: false })
    }
    // eslint-disable-next-line
  }, [state.deleteResult])
  return (
    <Box className={classes.container}>
      <StylePaper
        width="100%"
        boxSizing="border-box"
        padding="24px"
        marginTop="24px"
        id="users-content"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '40px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.87)'
                  : '#FFFFFF',
            }}
          >
            Number of users:
            <Box
              component="span"
              color="#7487AA"
              fontSize="16px"
              fontWeight={500}
            >
              {state.temporaryData.length} / 50
            </Box>
            <PopoverTip>
              <Box color={theme.palette.text.secondary}>
                Use the user management page to add sub-accounts that can manage
                part or all of your main MAS account.
              </Box>
            </PopoverTip>
          </Box>
          <Box sx={{ flex: 1, textAlign: 'right' }}>
            {globalState.accountAuthority.roles_page === 2 &&
              state.temporaryData.length < 50 && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addAccount}
                  sx={{
                    minWidth: '122px',
                    height: '40px !important',
                    padding: '0 17px !important',
                    marginLeft: '16px',
                  }}
                >
                  <AddCircleIcon
                    sx={{
                      fontSize: '14px !important',
                      marginRight: '10px',
                      verticalAlign: 'middle',
                    }}
                  />
                  Add User
                </Button>
              )}
          </Box>
        </Box>
        <Box marginTop="16px">
          <TextField
            className={classes.search}
            InputProps={{
              startAdornment: <SearchIcon className={classes.icon} />,
            }}
            placeholder="Search by email"
            onChange={valueChange}
            value={searchValue}
            variant="outlined"
          />
        </Box>
        <Box marginTop="16px">
          <BorderStyleSelectMultiple
            width="100%"
            label="Role"
            placeholder="Role"
            value={role}
            labelKey="title"
            valueKey="id"
            onChange={handleChangeType}
            options={state.rolesList}
          />
        </Box>
        <Box>
          {!state.loading ? (
            tableData.length ? (
              <>
                {tableData.map((row, index) => (
                  <Box key={index} className={classes.list}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      lineHeight="30px"
                    >
                      <Box className={classes.email}>{row.email}</Box>
                      <Box display="flex" width="122px" minWidth="122px">
                        {globalState.accountAuthority.roles_page === 2 && (
                          <>
                            <Box width="80px">
                              <Box
                                className={classes.manageBox}
                                onClick={() => {
                                  openDialog(row)
                                }}
                              >
                                <CreateIcon className={classes.manageIcon} />
                                Manage
                              </Box>
                            </Box>
                            <Box
                              width="42px"
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Box
                                className={classes.deleteBox}
                                onClick={() => {
                                  setDeveloperKey(row.developer_key)
                                  setOpenModel(true)
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: '18px',
                                    verticalAlign: 'middle',
                                    color: 'rgba(235, 40, 39, 0.85)',
                                  }}
                                />
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      marginTop="16px"
                      lineHeight="16px"
                    >
                      <Box
                        sx={{
                          fontSize: '12px',
                          color: theme.palette.custom.tableHead,
                        }}
                      >
                        Role
                      </Box>
                      <Box sx={{ fontSize: '14px' }}>
                        {state.rolesListObject[row.role_id]?.title ||
                          row.role_id}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      marginTop="16px"
                      lineHeight="16px"
                    >
                      <Box
                        sx={{
                          fontSize: '12px',
                          color: theme.palette.custom.tableHead,
                        }}
                      >
                        Invited on
                      </Box>
                      <Box sx={{ fontSize: '14px' }}>
                        {getDateEnglishFormat(row.created)}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            ) : (
              <NoDataView desc="You have no members at the moment" />
            )
          ) : (
            list.map((item, index) => {
              return (
                <Box className={classes.list} key={index}>
                  <Skeleton variant="rectangular" width="100%" height={95} />
                </Box>
              )
            })
          )}
        </Box>
      </StylePaper>
      <DialogView />
      <EditDrawerView />
      <DeleteDialog
        openModel={openModel}
        onClose={closeDeleteDialog}
        onConfirm={deleteDeviceBtn}
        title="this user"
        loading={state.deleteMemberLoading}
        deleteButtonText="Delete User"
      />
    </Box>
  )
}

export default TableList
