const SVG = (props: any) => {
  const width = props.width || '14'
  const height = props.height || '25'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3955 0H3.2045C1.759 0.00163914 0.587632 1.17302 0.586182 2.61854V21.9994C0.587632 23.4449 1.759 24.6163 3.2045 24.6177H11.3955C12.841 24.6163 14.0124 23.4449 14.0141 21.9994V2.61854C14.0124 1.17302 12.841 0.00163914 11.3955 0ZM12.8949 21.9994C12.8941 22.8271 12.2233 23.498 11.3955 23.4988H3.2045C2.37673 23.498 1.70594 22.8271 1.7051 21.9994V2.61854C1.70594 1.79056 2.37673 1.11974 3.2045 1.11892H11.3955C12.2233 1.11974 12.8941 1.79056 12.8949 2.61854V21.9994Z"
        fill={color}
      />
      <path
        d="M8.97857 2.23633H5.62158C5.31263 2.23633 5.06201 2.48671 5.06201 2.79569C5.06201 3.10467 5.31263 3.35525 5.62158 3.35525H8.97857C9.28752 3.35525 9.53792 3.10467 9.53792 2.79569C9.53792 2.48671 9.28752 2.23633 8.97857 2.23633Z"
        fill={color}
      />
      <path
        d="M8.41868 21.2615C8.41868 21.8796 7.91771 22.3806 7.29976 22.3806C6.68159 22.3806 6.18066 21.8796 6.18066 21.2615C6.18066 20.6435 6.68159 20.1426 7.29976 20.1426C7.91771 20.1426 8.41868 20.6435 8.41868 21.2615Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
