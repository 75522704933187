import { portalService } from './service'

export const getAdPlacementList = (data: any) => {
  return portalService({
    url: '/ad_placements',
    method: 'post',
    data,
  })
}
export const getAdPlacementListApi = (data: any) => {
  return portalService({
    url: '/ad_placements/v3',
    method: 'post',
    data,
  })
}
export const getAdPlacementDavListApi = (data: any) => {
  return portalService({
    url: '/ad_placements/v3/supplement',
    method: 'post',
    data,
  })
}
export const getAdPlacementNameListApi = (data: any) => {
  return portalService({
    url: '/ad_placements/name_list/v3',
    method: 'post',
    data,
  })
}
