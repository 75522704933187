const SVG = (props: any) => {
  const width = props.width || '24'
  const height = props.height || '24'
  const color = props.color || '#1D3353'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 4H17.3333C18.8061 4 20 5.19391 20 6.66667V17.3333C20 18.8061 18.8061 20 17.3333 20H6.66667C5.19391 20 4 18.8061 4 17.3333V6.66667C4 5.19391 5.19391 4 6.66667 4ZM11.761 15.2106L15.8233 9.87722V9.85055C16.017 9.59649 16.0604 9.25839 15.9373 8.9636C15.8141 8.66881 15.5431 8.46211 15.2262 8.42138C14.9093 8.38064 14.5948 8.51205 14.401 8.76611L11.041 13.2106L9.59215 11.3617C9.39685 11.1108 9.08254 10.9832 8.76761 11.0269C8.45269 11.0706 8.18501 11.2789 8.06539 11.5735C7.94578 11.8681 7.9924 12.2041 8.18771 12.455L10.3566 15.2194C10.5262 15.4341 10.7852 15.5587 11.0588 15.5572C11.3339 15.5566 11.5932 15.4285 11.761 15.2106Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
