import { useState, ChangeEvent } from 'react'
import {
  Box,
  Dialog,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { LoadingBtn, VideoView } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '800px',
        maxWidth: '100%',
        padding: '40px',
        boxSizing: 'border-box',
        lineHeight: '22px',
      },
      title: {
        fontSize: '16px',
        fontWeight: 600,
      },
      content: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
        marginTop: '24px',
        lineHeight: '18px',
      },
      baseButton: {
        width: '123px',
        height: '32px',
        lineHeight: '32px',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '21px',
        borderRadius: '5px',
        display: 'inline-block',
        cursor: 'pointer',
      },
      goBackButton: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.text.primary}`,
        boxSizing: 'border-box',
      },
      formInputSearch: {
        height: '37px',
        width: '100%',
        marginTop: '8px',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
      radioGroup: {
        '& .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root': {
          padding: '4px 9px',
        },
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
          color: theme.palette.text.secondary,
        },
      },
    }
  })
/**
 * @param openModel 显示隐藏
 * @param submitLoading 上传中
 * @function onSubmit 确认回调
 * @function onClose 关闭区域点击回调
 */
interface RatingDialogProps {
  openModel: boolean
  submitLoading: boolean
  onSubmit: (data: any, url: string) => void
}
const Layout = (props: RatingDialogProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [otherValue, setOtherValue] = useState('')
  const [step, setStep] = useState(1)
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }
  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtherValue(e.target.value)
  }
  const submit = (url: string) => {
    props.onSubmit(
      {
        is_default_channel: value !== 'Other',
        from_channel: value === 'Other' ? otherValue : value,
      },
      url
    )
  }
  const getStarted = () => {
    const url = '/dash/games/add'
    submit(url)
  }
  // returns
  return (
    <Dialog open={props.openModel} maxWidth="xl">
      <Box className={classes.root}>
        {step === 1 && (
          <>
            <Box className={classes.title}>
              Welcome to MAS! Please check this welcome video!
            </Box>
            <Box>
              <Box className={classes.content}>
                <Box position="relative">
                  <VideoView
                    options={{
                      sources:
                        'https://d10syqkpf3jcaw.cloudfront.net/d2a2440f-1055-4911-92b2-c9f72a726503/AppleHLS1/intro.m3u8',
                      preload: 'auto',
                      bigPlayButton: true,
                      textTrackDisplay: false,
                      posterImage: false,
                      width: '720px',
                      height: '405px',
                    }}
                  />
                </Box>
              </Box>
              <Box marginTop="24px" textAlign="right">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: '230px' }}
                  onClick={() => {
                    setStep(2)
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </>
        )}
        {step === 2 && (
          <Box>
            <Box className={classes.content} marginTop="0">
              <Box marginBottom="24px">How did you hear about MAS?</Box>
              <Box>
                <FormControl component="fieldset" style={{ width: '400px' }}>
                  <RadioGroup
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value="Spoke with Yodo1 Team"
                      control={<Radio color="primary" />}
                      label="Spoke with Yodo1 Team"
                    />
                    <FormControlLabel
                      value="Webinar"
                      control={<Radio color="primary" />}
                      label="Webinar"
                    />
                    <FormControlLabel
                      value="Yodo1 Game Dev Connect"
                      control={<Radio color="primary" />}
                      label="Yodo1 Game Dev Connect"
                    />
                    <FormControlLabel
                      value="Yodo1 Website"
                      control={<Radio color="primary" />}
                      label="Yodo1 Website"
                    />
                    <FormControlLabel
                      value="Online Search"
                      control={<Radio color="primary" />}
                      label="Online Search"
                    />
                    <FormControlLabel
                      value="Word of Mouth"
                      control={<Radio color="primary" />}
                      label="Word of Mouth"
                    />
                    <FormControlLabel
                      value="Business of Mobile Gaming Course"
                      control={<Radio color="primary" />}
                      label="Business of Mobile Gaming Course"
                    />
                    <FormControlLabel
                      value="ASO Course"
                      control={<Radio color="primary" />}
                      label="ASO Course"
                    />
                    <FormControlLabel
                      value="Game Competition"
                      control={<Radio color="primary" />}
                      label="Game Competition"
                    />
                    <FormControlLabel
                      value="Game Revenue Predictor"
                      control={<Radio color="primary" />}
                      label="Game Revenue Predictor"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio color="primary" />}
                      label="Other"
                    />
                    {value === 'Other' && (
                      <TextField
                        className={classes.formInputSearch}
                        placeholder="Please Specify"
                        variant="outlined"
                        value={otherValue}
                        onChange={inputChange}
                        disabled={value !== 'Other'}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Box marginTop="24px" textAlign="right">
              <Button
                color="primary"
                variant="contained"
                style={{ width: '230px' }}
                onClick={getStarted}
                disabled={
                  props.submitLoading ||
                  !value ||
                  (value === 'Other' && !otherValue)
                }
              >
                {props.submitLoading ? <LoadingBtn /> : 'Get Started'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default Layout
