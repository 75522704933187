const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6667 2.83333C17.6667 1.82081 16.8459 1 15.8333 1H12.1667L10.332 1.00069L8.5 1H4.83333C3.82081 1 3 1.82081 3 2.83333V17.5C3 18.5125 3.82081 19.3333 4.83333 19.3333H8V17.6667H4.83203V3H6.66536H10.332H13.9987H15.832V11H17.6667V2.83333Z"
        fill={color}
      />
      <path
        d="M18.1064 12.8662C17.6129 12.8662 17.2129 13.2662 17.2129 13.7596H16.8626V12.797C16.8626 12.3036 16.4626 11.9036 15.9691 11.9036C15.4757 11.9036 15.0757 12.3036 15.0757 12.797V13.7596H14.7253V11.9179C14.7253 11.4245 14.3253 11.0245 13.8319 11.0245C13.3385 11.0245 12.9385 11.4245 12.9385 11.9179V13.7596H12.5881V9.69714C12.5881 9.20372 12.1881 8.80371 11.6947 8.80371C11.2013 8.80371 10.8013 9.20372 10.8013 9.69714V16.3119H10.4509V14.1547C10.4509 13.6613 10.0509 13.2613 9.55749 13.2613C9.06407 13.2613 8.66406 13.6613 8.66406 14.1547V17.4429C8.66406 19.4295 10.2803 21.0457 12.2668 21.0457H15.3971C17.3836 21.0457 18.9998 19.4295 18.9998 17.4429V13.7596C18.9998 13.2662 18.5998 12.8662 18.1064 12.8662Z"
        fill={color}
      />
      <path d="M8 9.17084H9.75182V10.2219H8V9.17084Z" fill={color} />
      <path
        d="M9.45123 6.71094L10.6899 7.94961L9.94668 8.69284L8.70801 7.45416L9.45123 6.71094Z"
        fill={color}
      />
      <path d="M11.1709 6H12.222V7.75182H11.1709V6Z" fill={color} />
    </svg>
  )
}

export default SVG
