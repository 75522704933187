import { useContext, useMemo } from 'react'
import { Box, Tooltip, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GlobalContext } from 'src/context/global'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      tooltipContent: {
        backgroundColor:
          theme.palette.mode === 'light'
            ? '#f4fafe !important'
            : '#2B3445 !important',
        boxShadow:
          theme.palette.mode === 'light'
            ? '1px 1px 10px rgba(30, 150, 252, 0.2)'
            : '0px 0px 4px rgba(68, 68, 68, 0.15)',
        borderRadius: '8px !important',
        padding: '0 !important',
        maxWidth: '500px !important',
        color: `${theme.palette.text.primary} !important`,
        border: theme.palette.mode === 'light' ? '1px solid #1E96FC' : '0 none',
      },
      tooltipArrow: {
        color:
          theme.palette.mode === 'light'
            ? '#1E96FC !important'
            : '#2B3445 !important',
      },
    }
  })
const ProgressItemView = (props: any, ref: any) => {
  const { status = 'default' } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state: globalState } = useContext(GlobalContext)
  const readOnly = useMemo(() => {
    return globalState.accountAuthority.games_page === 1 && status !== 2
  }, [globalState.accountAuthority, status])
  const readOnlyTip = () => {
    return (
      <Box
        sx={{
          boxSizing: 'border-box',
          minWidth: '242px',
          padding: '12px 12px 17px',
          fontSize: '14px',
          color: theme.palette.text.primary,
          textAlign: 'center',
          lineHeight: '22px',
        }}
      >
        You only have read-only permission for managing games
      </Box>
    )
  }

  return (
    <Tooltip
      title={
        readOnly ? (
          <>{readOnlyTip()}</>
        ) : props.disabled ? (
          props.disabledText
        ) : (
          props.children
        )
      }
      arrow
      classes={{
        tooltip: classes.tooltipContent,
        arrow: classes.tooltipArrow,
      }}
      disableHoverListener={props.disabled && !props.disabledText}
      describeChild={true}
      disableFocusListener={props.disableFocusListener}
    >
      <Box
        sx={{
          position: 'relative',
          height: '28px',
          '&:hover': { opacity: 0.75 },
          cursor:
            !readOnly && props.canClick && !props.loading
              ? 'pointer'
              : 'default',
        }}
        onClick={() => {
          !readOnly &&
            !props.loading &&
            props.canClick &&
            props.onClick &&
            props.onClick()
        }}
      >
        <SVGIcon.ProgressBoxSVG
          color={
            status === 2
              ? '#27AE60'
              : status === 1
              ? '#FAAE31'
              : status === 0
              ? '#B93D34'
              : 'rgba(116, 135, 170, 0.6)'
          }
        />
        <props.icon
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    </Tooltip>
  )
}

export default ProgressItemView
