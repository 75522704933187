import { useContext, useEffect, useMemo } from 'react'
import { PerformanceContext } from 'src/context/performance/index'
import { GlobalContext } from 'src/context/global'
import {
  BorderStyleSelectMultiple,
  BorderStyleSelectRow,
  BorderStyleDate,
  TopFilterBox,
} from 'src/components'
import dayjs from 'dayjs'
import ConfigView from './config'

const typeOptions = [
  {
    label: 'Interstitial',
    value: 'interstitial',
  },
  {
    label: 'Banner',
    value: 'banner',
  },
  {
    label: 'Rewarded Video',
    value: 'reward_video',
  },
  {
    label: 'Native Ads',
    value: 'nativeads',
  },
  {
    label: 'App Open',
    value: 'openads',
  },
  {
    label: 'Rewarded Interstitial',
    value: 'rewardinter',
  },
  {
    label: 'Other',
    value: 'other',
  },
]
const platformOptions = [
  {
    label: 'iOS',
    value: 'iOS',
  },
  {
    label: 'Android',
    value: 'android',
  },
]
const viewOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Application',
    value: 'application',
  },
  {
    label: 'Platform',
    value: 'platform',
  },
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'Ad Type',
    value: 'ad_type',
  },
]

const TopFilterView = (props: any): JSX.Element => {
  const { state, dispatch, searchData } = useContext(PerformanceContext)
  const { state: globalState } = useContext(GlobalContext)

  const timeChange = (dateRange: any[], timeShow: string) => {
    dispatch({
      type: 'time',
      payload: dateRange,
    })
    dispatch({
      type: 'timeShow',
      payload: timeShow,
    })
  }
  const newViewOptions = useMemo(() => {
    if (globalState.showNetwork) {
      return [
        ...viewOptions,
        {
          label: 'Network',
          value: 'network',
        },
      ]
    }
    return viewOptions
  }, [globalState.showNetwork])

  const reset = () => {
    timeChange(
      [
        dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      ],
      'last_week'
    )
    dispatch({
      type: 'type',
      payload: [],
    })
    dispatch({
      type: 'gameSelect',
      payload: [],
    })
    dispatch({
      type: 'country',
      payload: [],
    })
    dispatch({
      type: 'countryChecked',
      payload: [],
    })
    dispatch({
      type: 'platform',
      payload: [],
    })
    dispatch({
      type: 'resetIndex',
      payload: state.resetIndex + 1,
    })
  }
  const search = () => {
    searchData()
  }

  const getArray = (arr1: any, arr2: any, key: string) => {
    if (!arr1.length || !arr2.length) {
      return arr1
    }
    const temp: any = []
    arr1.forEach((item: any) => {
      const temp2 = arr2.find((res: any) => {
        return item === res[key]
      })
      temp.push(temp2)
    })
    return temp
  }

  // effects
  useEffect(() => {
    if (state.gameSelect.length && typeof state.gameSelect[0] === 'string') {
      dispatch({
        type: 'gameSelect',
        payload: getArray(state.gameSelect, state.gameOption, 'app_key'),
      })
    }
    // eslint-disable-next-line
  }, [state.gameOption])
  useEffect(() => {
    if (state.country.length && typeof state.country[0] === 'string') {
      dispatch({
        type: 'country',
        payload: getArray(state.country, state.countryOption, 'code'),
      })
      dispatch({
        type: 'countryChecked',
        payload: state.country,
      })
    }
    // eslint-disable-next-line
  }, [state.countryOption])

  // returns
  return (
    <TopFilterBox
      ContentView={
        <>
          <BorderStyleDate
            label="Dates"
            timeShow={state.timeShow}
            value={state.time}
            onChange={timeChange}
            onTriggerFilter={search}
          />
          <BorderStyleSelectMultiple
            label="Apps"
            valueKey="app_key"
            labelKey="name"
            placeholder="Apps"
            value={state.gameSelect}
            onChange={(row: any) => {
              dispatch({
                type: 'gameSelect',
                payload: row,
              })
            }}
            options={state.gameOption}
            onTriggerFilter={search}
          />
          <BorderStyleSelectRow
            label="Group by"
            placeholder="Group by"
            value={{ value: state.view }} // 接收对象，所以讲state.view包装为对象
            showSearch={false}
            onChange={(row: any) => {
              dispatch({
                type: 'view',
                payload: row.value,
              })
            }}
            options={newViewOptions}
            onTriggerFilter={search}
          />
        </>
      }
      PopoverView={
        <>
          <BorderStyleSelectMultiple
            width="100%"
            label="Ad Types"
            placeholder="Ad Types"
            value={state.type}
            showSearch={false}
            onChange={(row: any) => {
              dispatch({
                type: 'type',
                payload: row,
              })
            }}
            options={typeOptions}
            onTriggerFilter={search}
          />
          <BorderStyleSelectMultiple
            width="100%"
            label="Countries"
            valueKey="code"
            labelKey="name"
            placeholder="Countries"
            value={state.country}
            onChange={(row: any) => {
              dispatch({
                type: 'country',
                payload: row,
              })
              dispatch({
                type: 'countryChecked',
                payload: row.map((item: any) => item.code),
              })
            }}
            options={state.countryOption}
            onTriggerFilter={search}
          />
          <BorderStyleSelectMultiple
            width="100%"
            label="Platforms"
            placeholder="Platforms"
            value={state.platform}
            showSearch={false}
            onChange={(row: any) => {
              dispatch({
                type: 'platform',
                payload: row,
              })
            }}
            options={platformOptions}
            onTriggerFilter={search}
          />
        </>
      }
      ReportView={<ConfigView />}
      reset={reset}
      search={search}
    />
  )
}

export default TopFilterView
