import { Box } from '@material-ui/core'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { GlobalStoreContext } from 'src/context/globalStore'
import TopFilterView from './topFilter'
import ContentView from './content'
import { TopTitle, StylePaper } from 'src/components'

const CompareGamesView = (props: any) => {
  const { dispatch: globalStoreDispatch } = useContext(GlobalStoreContext)
  const navigate = useNavigate()

  const back = () => {
    globalStoreDispatch({
      type: 'gamePageFromDetail',
      payload: true,
    })
    navigate('/dash/games')
  }

  return (
    <>
      <TopFilterView />
      <Box padding="20px 24px 100px">
        <TopTitle title="Compare Metrics" showBack={true} goBack={back} />
        <StylePaper
          width="100%"
          boxSizing="border-box"
          marginTop="24px"
          id="games-compare"
        >
          <ContentView />
        </StylePaper>
      </Box>
    </>
  )
}

export default CompareGamesView
