const SVG = (props: any) => {
  const width = props.width || '64'
  const height = props.height || '64'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 21.398L0 42.6925C0.00426136 46.4505 3.04954 49.4958 6.80753 49.4995H57.1928C60.9508 49.4958 63.9961 46.4505 63.9998 42.6925V21.398C63.9961 17.64 60.9508 14.5947 57.1928 14.5904H6.80753C3.04954 14.5947 0.00426136 17.64 0 21.398H0ZM57.1928 17.4999C59.3448 17.502 61.0888 19.246 61.0909 21.398V42.6925C61.0888 44.8445 59.3448 46.5885 57.1928 46.5906H6.80753C4.65501 46.5885 2.91104 44.8445 2.90891 42.6925L2.90891 21.398C2.91104 19.246 4.65501 17.502 6.80753 17.4999L57.1928 17.4999Z"
        fill={color}
      />
      <path
        d="M5.81836 27.6814L5.81836 36.4087C5.81836 37.2119 6.46928 37.8634 7.27255 37.8634C8.07582 37.8634 8.72727 37.2119 8.72727 36.4087V27.6814C8.72727 26.8781 8.07582 26.2272 7.27255 26.2272C6.46928 26.2272 5.81836 26.8781 5.81836 27.6814Z"
        fill={color}
      />
      <path
        d="M55.2722 29.1366C56.8793 29.1366 58.1816 30.439 58.1816 32.0455C58.1816 33.6526 56.8793 34.955 55.2722 34.955C53.6657 34.955 52.3633 33.6526 52.3633 32.0455C52.3633 30.439 53.6657 29.1366 55.2722 29.1366Z"
        fill={color}
      />
      <rect
        x="12.4287"
        y="43.5714"
        width="23.1429"
        height="35.7143"
        rx="1"
        transform="rotate(-90 12.4287 43.5714)"
        stroke={color}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8566 27.2382C28.2267 27.2382 26.0947 29.3701 26.0947 32.0001C26.0947 34.63 28.2267 36.762 30.8566 36.762C33.4866 36.762 35.6185 34.63 35.6185 32.0001C35.6185 30.7371 35.1168 29.5259 34.2238 28.6329C33.3308 27.7399 32.1196 27.2382 30.8566 27.2382ZM30.8566 35.8096C28.7526 35.8096 27.0471 34.104 27.0471 32.0001C27.0471 29.8961 28.7526 28.1905 30.8566 28.1905C32.9605 28.1905 34.6661 29.8961 34.6661 32.0001C34.6661 33.0104 34.2647 33.9794 33.5503 34.6938C32.8359 35.4082 31.8669 35.8096 30.8566 35.8096ZM29.6137 29.9509C29.7274 29.8885 29.866 29.8928 29.9757 29.962L32.7614 31.6953C32.8653 31.7618 32.9282 31.8767 32.9282 32.0001C32.9282 32.1234 32.8653 32.2383 32.7614 32.3048L29.9757 34.0382C29.866 34.1073 29.7274 34.1116 29.6137 34.0493C29.4999 33.987 29.4289 33.8679 29.4281 33.7382V30.262C29.4289 30.1323 29.4999 30.0132 29.6137 29.9509Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
