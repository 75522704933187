import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import MetricsFormView from '../metricsForm'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      content: {
        textAlign: 'left',
      },
      showFormButton: {
        boxSizing: 'border-box',
        width: '124px',
        height: '40px',
        lineHeight: '40px',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '6px',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '14px',
        cursor: 'pointer',
      },
    }
  })
interface PreMetrics {
  network_name: string
  dau: number | null
  arp_dau: number | null
  imp_dau_rewarded_video: number | null
  imp_dau_banner: number | null
  imp_dau_interstitial: number | null
  imp_dau_native_ads: number | null
}

const PreMetrics = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const params: any = useParams()
  const MetricsFormeDialog = useRef()

  const openDialog = () => {
    const dialog: any = MetricsFormeDialog.current
    dialog.openDialog(params.app_key)
  }
  return (
    <Box>
      <Box className={classes.content}>
        <Box fontSize="16px" fontWeight={600} marginBottom="16px">
          Pre-MAS Metrics
        </Box>
        <Box
          margin="0 0 20px 0"
          sx={props.isMobile ? { fontSize: '12px', fontWeight: 400 } : {}}
        >
          We can help improve your revenue if you provide us with more
          information about your previous monetization setup.
        </Box>
        <Box className={classes.showFormButton} onClick={openDialog}>
          Show Form
        </Box>
      </Box>
      <MetricsFormView ref={MetricsFormeDialog} app_key={params.app_key} />
    </Box>
  )
}

export default PreMetrics
