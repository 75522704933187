import { useContext } from 'react'
import { AdPlacementsContext } from 'src/context/adPlacement'
import { GlobalContext } from 'src/context/global'
import {
  BorderStyleSelectMultiple,
  BorderStyleSelectRow,
  BorderStyleDate,
  TopFilterBox,
} from 'src/components'
import dayjs from 'dayjs'

const typeOptions = [
  {
    label: 'Interstitial',
    value: 'interstitial',
  },
  {
    label: 'Banner',
    value: 'banner',
  },
  {
    label: 'Rewarded Video',
    value: 'reward_video',
  },
  {
    label: 'Native Ads',
    value: 'nativeads',
  },
  {
    label: 'App Open',
    value: 'openads',
  },
  {
    label: 'Rewarded Interstitial',
    value: 'rewardinter',
  },
  {
    label: 'Other',
    value: 'other',
  },
]
const TopFilterView = (props: any): JSX.Element => {
  const { state, dispatch, getPlacementList } = useContext(AdPlacementsContext)
  const { state: globalState } = useContext(GlobalContext)

  const timeChange = (dateRange: any[], timeShow: string) => {
    dispatch({
      type: 'time',
      payload: dateRange,
    })

    dispatch({
      type: 'timeShow',
      payload: timeShow,
    })
  }
  const reset = () => {
    timeChange(
      [
        dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      ],
      'last_week'
    )
    dispatch({
      type: 'type',
      payload: [],
    })
    dispatch({
      type: 'placement',
      payload: [],
    })
    dispatch({
      type: 'country',
      payload: [],
    })
    dispatch({
      type: 'resetIndex',
      payload: state.resetIndex + 1,
    })
  }
  const search = () => {
    getPlacementList()
  }
  // returns
  return (
    <TopFilterBox
      ContentView={
        <>
          <BorderStyleDate
            label="Dates"
            timeShow={state.timeShow}
            value={state.time}
            onChange={timeChange}
            onTriggerFilter={search}
          />
          <BorderStyleSelectRow
            label="App"
            placeholder="App"
            valueKey="app_key"
            labelKey="name"
            value={state.gameSelect}
            showSearch={true}
            onChange={(row: any) => {
              dispatch({
                type: 'placement',
                payload: [],
              })
              dispatch({
                type: 'gameSelect',
                payload: row,
              })
            }}
            options={state.gameOption}
            onTriggerFilter={search}
          />
          <BorderStyleSelectMultiple
            label="Ad Type"
            placeholder="Ad Type"
            value={state.type}
            showSearch={false}
            onChange={(row: any) => {
              dispatch({
                type: 'placement',
                payload: [],
              })
              dispatch({
                type: 'type',
                payload: row,
              })
            }}
            options={typeOptions}
            onTriggerFilter={search}
          />
          <BorderStyleSelectMultiple
            label="Ad Placements"
            placeholder="Ad Placements"
            value={state.placement}
            showSearch={false}
            onChange={(row: any) => {
              dispatch({
                type: 'placement',
                payload: row,
              })
            }}
            options={state.placementOption.map(item => {
              return { value: item, label: item }
            })}
            onTriggerFilter={search}
          />
        </>
      }
      PopoverView={
        <>
          <BorderStyleSelectMultiple
            width="100%"
            label="Countries"
            valueKey="code"
            labelKey="name"
            placeholder="Countries"
            value={state.country}
            onChange={(row: any) => {
              dispatch({
                type: 'country',
                payload: row,
              })
            }}
            options={globalState.countryOption}
            onTriggerFilter={search}
          />
        </>
      }
      reset={reset}
      search={search}
    />
  )
}

export default TopFilterView
