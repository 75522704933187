import { useState, useEffect } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      scoreButton: {
        flex: 1,
        boxSizing: 'border-box',
        width: '50px',
        height: '60px',
        lineHeight: '60px',
        textAlign: 'center',
        margin: '14px 2px 10px 2px',
        cursor: 'pointer',
        background: theme.palette.custom.scoreCard,
      },
      scoreChecked: {
        background: theme.palette.primary.main,
      },
    }
  })
/**
 * @param leftText 评分左侧描述文字
 * @param rightText 评分右侧描述文字
 * @function MaxScore 最大评分
 * @function onChange 评分改变触发方法
 */
interface RatingDialogProps {
  leftText?: string
  rightText?: string
  MaxScore?: number
  onChange: (score: number) => void
}
const list: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const Layout = (props: RatingDialogProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [score, setScore] = useState<number | null>(0)
  const [ratingList, setRatingList] = useState<number[]>(list)
  useEffect(() => {
    if (props.MaxScore && props.MaxScore !== 10) {
      const temp = []
      for (let i = 1; i <= props.MaxScore; i++) {
        temp.push(i)
      }
      setRatingList(temp)
    }
    // eslint-disable-next-line
  }, [])
  // returns
  return (
    <>
      {(props.leftText || props.rightText) && (
        <Box display="flex" marginTop="20px">
          <Box width="50%" textAlign="left" paddingLeft="3%">
            {props.leftText || ''}
          </Box>
          <Box width="50%" textAlign="right" paddingRight="3%">
            {props.rightText || ''}
          </Box>
        </Box>
      )}
      <Box
        marginBottom="20px"
        marginTop={props.leftText || props.rightText ? '0' : '20px'}
      >
        <Box display="flex" justifyContent="center">
          {ratingList.map((item: number) => (
            <Box
              className={clsx([
                classes.scoreButton,
                score === item ? classes.scoreChecked : '',
              ])}
              onClick={() => {
                setScore(item)
                props.onChange(item)
              }}
              key={item}
            >
              {item}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default Layout
