import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  Dispatch,
} from 'react'

import { UserActivityContext } from './index'
import { toThousands, rateTitleFormat, tabMetricFormat } from 'src/utils'

type Props = {
  topChartsData: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  topChartsData: Array(3).fill({}),
}
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'topChartsData':
      const data = action.payload.data
      const disabled = action.payload.disabled
      const untilTime = action.payload?.untilTime || null
      return {
        ...prevState,
        topChartsData: [
          {
            name: 'ARPDAU',
            value: 'arpdau',
            disabled,
            untilTime,
            num:
              '$' +
              toThousands(
                tabMetricFormat(data.arpdau?.summary || 0, 'arpdau'),
                true
              ),
            tooltip:
              'ARPDAU, or Average Revenue Per Daily Active User, measures the average amount of revenue earned per user on a daily basis.',
            tendency: 'up',
            tendencyData: '0',
          },
          {
            name: rateTitleFormat('Imp', 'DAU'),
            value: 'impdau',
            disabled,
            untilTime,
            num: toThousands(
              tabMetricFormat(data.impdau?.summary || 0, 'impdau'),
              true
            ),
            tooltip:
              'Imp/DAU, or Impressions per Daily Active User, indicates the average number of ad impressions served to each user daily.',
            tendency: 'up',
            tendencyData: '0',
          },
          {
            name: 'Avg.DAU',
            value: 'dau',
            disabled,
            untilTime,
            num: toThousands(
              tabMetricFormat(data.dau?.summary || 0, 'dau'),
              true
            ),
            tooltip:
              'Avg. DAU, or Average Daily Active Users, represents the average number of unique users actively engaging with a platform or application on a daily basis.',
            tendency: 'up',
            tendencyData: '0',
          },
        ],
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
}

export const MetricsContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
})

export const MetricsContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: userActivityState } = useContext(UserActivityContext)

  useEffect(() => {
    if (userActivityState.topData) {
      dispatch({
        type: 'topChartsData',
        payload: {
          data: userActivityState.topData?.all || {},
          disabled: userActivityState.topData?.tabDisabled || false,
          untilTime: userActivityState.topData?.untilTime || null,
        },
      })
    }
    // eslint-disable-next-line
  }, [userActivityState.topData])

  // returns
  return (
    <MetricsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </MetricsContext.Provider>
  )
}
