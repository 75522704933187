import { TextField, Box, Button, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import { useContext, useEffect, useState } from 'react'
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
  AddCircle as AddCircleIcon,
} from '@material-ui/icons'
import { TestDeviceContext } from 'src/context/testDevice'
import { GlobalContext } from 'src/context/global'
import { PNG } from 'src/images'
import {
  StylePaper,
  NoDataView,
  OpenInNewIconView,
  DeleteDialog,
  PopoverTip,
} from 'src/components'
import AddDialogView from './addDialog'
import EditDialogView from './editDialog'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
        color: theme.palette.text.primary,
      },
      tableList: {
        marginTop: '24px',
      },
      tableHead: {
        display: 'flex',
        padding: '16px 0',
        fontSize: '12px',
        color: theme.palette.custom.tableHead,
      },
      tableTh: {
        flex: 1,
        fontSize: '12px',
        color: theme.palette.text.secondary,
        textAlign: 'left',
      },
      tableTr: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
        padding: '12px 0',
        fontSize: '14px',
      },
      tableTd: {
        flex: 1,
        minWidth: '170px',
      },
      icon: {
        width: '22px',
        height: '18px',
      },
      search: {
        width: '254px',
        '& .MuiOutlinedInput-root': {
          height: '40px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.custom.paperBorder} !important`,
          boxShadow:
            theme.palette.mode === 'light'
              ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
              : 'none',
          borderRadius: '6px',
        },
      },
      title: {
        display: 'flex',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        fontSize: '20px',
        fontWeight: 500,
        height: '40px',
        lineHeight: '40px',
        flexWrap: 'wrap',
      },
      gamePhoto: {
        width: '24px',
        height: '24px',
        verticalAlign: 'middle',
        borderRadius: '50%',
        marginRight: '10px',
      },
      review: {
        flex: 1,
        minWidth: '140px',
        textAlign: 'left',
        color: theme.palette.text.primary,
      },
      appName: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '130px',
        textOverflow: 'ellipsis',
        color: theme.palette.text.primary,
      },
      tip: {
        width: '16px',
        verticalAlign: 'text-top',
        marginRight: '6px',
      },
      manageBox: {
        width: '80px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '12px',
      },
      deleteBox: {
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '12px',
      },
      manageIcon: {
        fontSize: '16px !important',
        verticalAlign: 'middle',
        color: theme.palette.text.primary,
        marginRight: '6px',
      },
    }
  })

const TableListView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, searchList, deleteDevice } =
    useContext(TestDeviceContext)
  const { state: globalState } = useContext(GlobalContext)
  const arr: any[] = []
  const [tableData, setTableData] = useState(arr)
  const list = Array(20).fill('')
  const [searchValue, setSearchValue] = useState('')
  const timerTemp: any = null
  const [timer, setTimer] = useState(timerTemp)
  const [openModel, setOpenModel] = useState(false)
  const [deviceRow, setDeviceRow] = useState<any>({})

  const deleteDeviceBtn = () => {
    deleteDevice(deviceRow.id)
  }
  const closeDeleteDialog = () => {
    setOpenModel(false)
  }

  const valueChange = (e: any) => {
    setSearchValue(e.target.value)
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        searchList(e.target.value)
        clearTimeout(timer)
        setTimer(null)
      }, 1500)
    )
  }
  const openDialog = (row: any) => {
    dispatch({
      type: 'editDialogData',
      payload: row,
    })
    dispatch({
      type: 'editDialogOpen',
      payload: true,
    })
  }
  const addTestDevice = () => {
    dispatch({
      type: 'addDialogOpen',
      payload: true,
    })
  }
  const linkHelpWord = () => {
    const url = 'https://developers.yodo1.com/docs/unity/advanced/testing/'
    window.open(url)
  }

  useEffect(() => {
    if (state.data) {
      setTableData(state.data)
    }
  }, [state.data])
  useEffect(() => {
    if (state.deleteResult) {
      setOpenModel(false)
      dispatch({
        type: 'editDialogOpen',
        payload: false,
      })
      dispatch({ type: 'deleteResult', payload: false })
    }
    // eslint-disable-next-line
  }, [state.deleteResult])

  return (
    <Box className={classes.container}>
      <StylePaper
        width="100%"
        boxSizing="border-box"
        padding="24px"
        marginTop="24px"
      >
        <Box className={classes.title}>
          <Box
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.87)'
                  : '#FFFFFF',
            }}
          >
            All Testing Devices
            <PopoverTip>
              <Box lineHeight="22px">
                <Box>
                  To test MAS SDK with test ads in your game, please follow the
                  steps below -
                </Box>
                <Box>
                  1. Add a test device of your choice for a platform you want to
                  test.
                </Box>
                <Box>2. Go to the Management → Games.</Box>
                <Box>
                  3. For the game you want to test ensure “Test Mode” is enabled
                  under Manage → Ads Management.
                </Box>
                <Box marginTop="12px">
                  You can also check out our docs{' '}
                  <Box
                    component="span"
                    color={theme.palette.primary.main}
                    onClick={linkHelpWord}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    here
                    <OpenInNewIconView />
                  </Box>{' '}
                  for more information or{' '}
                  <Box
                    component="span"
                    color={theme.palette.primary.main}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      window.Intercom && window.Intercom('show')
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    connect with our support
                  </Box>{' '}
                  for help.
                </Box>
              </Box>
            </PopoverTip>
          </Box>
          <Box
            sx={{
              flex: 1,
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            {state.holdData.length >= 10 && (
              <TextField
                className={classes.search}
                placeholder="Device Name/Device ID"
                InputProps={{
                  startAdornment: <SearchIcon className={classes.icon} />,
                }}
                onChange={valueChange}
                variant="outlined"
                value={searchValue}
              />
            )}
            {globalState.accountAuthority.testing_page === 2 &&
              tableData.length < 50 && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addTestDevice}
                  sx={{
                    minWidth: '170px',
                    height: '40px !important',
                    padding: '0 17px !important',
                    marginLeft: '16px',
                  }}
                >
                  <AddCircleIcon
                    sx={{
                      fontSize: '14px !important',
                      marginRight: '10px',
                      verticalAlign: 'middle',
                    }}
                  />
                  Add Test Device
                </Button>
              )}
          </Box>
        </Box>
        <Box>
          <Box className={classes.tableList}>
            <Box minWidth="510px">
              <Box className={classes.tableHead}>
                <Box className={classes.tableTh}>NAME</Box>
                <Box className={classes.tableTh} flex={1.7} paddingLeft="150px">
                  DEVICE ID
                </Box>
                <Box width="80px" />
                <Box width="42px" />
              </Box>
              <Box>
                {!state.loading ? (
                  tableData.length ? (
                    <>
                      {tableData.map((row, index) => {
                        return (
                          <Box className={classes.tableTr} key={index}>
                            <Box className={classes.tableTd}>
                              <Box display="flex" alignItems="center">
                                <div>
                                  <img
                                    src={
                                      row.platform === 'iOS'
                                        ? PNG.ios
                                        : PNG.android
                                    }
                                    className={classes.gamePhoto}
                                    alt=""
                                  />
                                </div>
                                <div style={{ position: 'relative' }}>
                                  <div className={classes.appName}>
                                    {row.name}
                                  </div>
                                </div>
                              </Box>
                            </Box>
                            <Box className={classes.review} flex={1.7}>
                              {row.device_id}
                            </Box>
                            {globalState.accountAuthority.testing_page ===
                              2 && (
                              <>
                                <Box width="80px">
                                  <Box
                                    className={classes.manageBox}
                                    onClick={() => {
                                      openDialog(row)
                                    }}
                                  >
                                    <CreateIcon
                                      className={classes.manageIcon}
                                    />
                                    Manage
                                  </Box>
                                </Box>
                                <Box
                                  width="42px"
                                  display="flex"
                                  justifyContent="flex-end"
                                >
                                  <Box
                                    className={classes.deleteBox}
                                    onClick={() => {
                                      setDeviceRow(row)
                                      setOpenModel(true)
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{
                                        fontSize: '18px',
                                        verticalAlign: 'middle',
                                        color: 'rgba(235, 40, 39, 0.85)',
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </>
                            )}
                          </Box>
                        )
                      })}
                    </>
                  ) : (
                    <NoDataView desc="You have no testing devices at the moment" />
                  )
                ) : (
                  list.map((item, index) => {
                    return (
                      <Box className={classes.tableTr} key={index}>
                        <Box className={classes.tableTd}>
                          {item}
                          <Box display="flex" alignItems="center">
                            <div>
                              <Skeleton
                                variant="circular"
                                width={30}
                                height={30}
                                style={{ marginRight: '10px' }}
                              />
                            </div>
                            <div style={{ position: 'relative' }}>
                              <div
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '130px',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                <Skeleton variant="text" width={130} />
                              </div>
                            </div>
                          </Box>
                        </Box>
                        <Box className={classes.review} flex={1.7}>
                          <Skeleton variant="text" width={300} />
                        </Box>
                        <Box width="80px">
                          <Skeleton
                            variant="rectangular"
                            width={80}
                            height={30}
                          />
                        </Box>
                        <Box
                          width="42px"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Skeleton
                            variant="rectangular"
                            width={30}
                            height={30}
                          />
                        </Box>
                      </Box>
                    )
                  })
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </StylePaper>
      <AddDialogView />
      <EditDialogView />
      <DeleteDialog
        openModel={openModel}
        onClose={closeDeleteDialog}
        onConfirm={deleteDeviceBtn}
        title={deviceRow.name}
        loading={state.deleteDeviceLoading}
        deleteButtonText="Delete Device"
      />
    </Box>
  )
}

export default TableListView
