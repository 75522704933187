import { useRef, useEffect, useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'

const VideoView = (props: any): JSX.Element => {
  const videoRef = useRef(null)
  const playerRef = useRef<any>(null)
  const [url, setUrl] = useState('')
  const { options } = props

  useEffect(() => {
    if (url === options.sources || !options?.sources) {
      return
    } else {
      setUrl(options.sources)
    }
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      playerRef.current = videojs(videoElement, options)
      const player = playerRef.current
      const typeList = options.sources.split('.')
      const type =
        typeList[typeList.length - 1] === 'm3u8'
          ? 'application/x-mpegURL'
          : 'video/mp4'
      player.src({
        src: options.sources,
        type,
      })
      player.hlsQualitySelector({
        displayCurrentQuality: true,
      })
    } else {
      const player = playerRef.current
      const typeList = options.sources.split('.')
      const type =
        typeList[typeList.length - 1] === 'm3u8'
          ? 'application/x-mpegURL'
          : 'video/mp4'
      player.src({
        src: options.sources,
        type,
      })
    }
    // eslint-disable-next-line
  }, [options, videoRef])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        controls
        width={props.width || '590px'}
        height={props.height || '332px'}
        preload="auto"
        data-setup="{}"
        disablePictureInPicture // 禁用画中画
        controlsList="nodownload"
        onPause={props.onPause}
        onTimeUpdate={props.onTimeUpdate}
      />
    </div>
  )
}

export default VideoView
