const SVG = (props: any) => {
  const width = props.width || '24'
  const height = props.height || '24'
  const color = props.color || '#FF3D00'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.28366 13.971C5.90303 13.5904 5.90303 12.9778 6.28366 12.5972C6.47398 12.4068 6.7208 12.3117 6.97059 12.3117C7.22038 12.3117 7.46719 12.4068 7.65751 12.5972L11.0267 15.9694L11.0267 5.68614C11.0267 5.15087 11.4609 4.71372 11.9991 4.71372C12.5373 4.71372 12.9715 5.14789 12.9715 5.68614L12.9715 15.9694L16.3407 12.5972C16.7213 12.2165 17.3339 12.2165 17.7145 12.5972C18.0952 12.9778 18.0952 13.5904 17.7145 13.971L12.686 18.9997C12.5046 19.184 12.2578 19.2852 11.9991 19.2852C11.7404 19.2852 11.4936 19.184 11.3122 18.9997L6.28366 13.971Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
