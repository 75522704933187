import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  Dispatch,
} from 'react'
import { PerformanceContext } from './index'
import { GlobalContext } from '../global'
import { sortCompare, downloadCsv, numFixedRefillZero } from 'src/utils'

type Props = {
  tableList: any
  summaryLoading: boolean
  gameListObject: any
  countryListObject: any
  networkObject: any
  sort: any
  holdData: any
}

type Action = {
  type: string
  payload?: any
}
const type = {
  interstitial: 'Interstitial',
  banner: 'Banner',
  reward_video: 'Rewarded Video',
  nativeads: 'Native Ads',
  openads: 'App Open',
  rewardinter: 'Rewarded Interstitial',
  other: 'Other',
  android: 'Android',
  all: 'All',
}
type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  tableList: {
    columns: [
      { id: 'game', label: 'Summary', styles: { fontSize: '18px' } },
      { id: 'revenue', label: 'Est. Revenue' },
      { id: 'impressions', label: 'Impressions' },
      { id: 'ecpm', label: 'eCPM' },
    ],
    rows: [],
  },
  summaryLoading: false,
  gameListObject: {},
  countryListObject: {},
  networkObject: {},
  sort: {
    id: 'revenue',
    by: null,
  },
  holdData: {},
}
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'summaryLoading':
    case 'gameListObject':
    case 'countryListObject':
    case 'networkObject':
    case 'holdData':
      return { ...prevState, [action.type]: action.payload }
    case 'tableList':
      const { tableData, summaryTableView } = action.payload
      let data: any = []
      if (summaryTableView === 'all' && tableData.all) {
        data = tableData.all.arpdau.days.map((item: string, index: number) => {
          return {
            game: item,
            icon: '',
            ecpm: numFixedRefillZero(tableData.all.ecpm.values[index]),
            impressions: tableData.all.impressions.values[index],
            revenue: numFixedRefillZero(tableData.all.revenue.values[index]),
          }
        })
      } else {
        data = Object.keys(tableData).map(item => {
          return {
            game:
              prevState.gameListObject[item]?.name ||
              prevState.countryListObject[item]?.name ||
              prevState.networkObject[item] ||
              type[item] ||
              item,
            icon: prevState.gameListObject[item]?.icon || '',
            ecpm: numFixedRefillZero(tableData[item].ecpm.summary),
            impressions: tableData[item].impressions.summary,
            revenue: numFixedRefillZero(tableData[item].revenue.summary),
          }
        })
      }
      return {
        ...prevState,
        sort: {
          id: 'revenue',
          by: null,
        },
        tableList: {
          columns: [
            {
              id: 'game',
              label: 'Summary',
              styles: { fontSize: '18px' },
            },
            { id: 'revenue', label: 'Est. Revenue' },
            { id: 'impressions', label: 'Impressions' },
            { id: 'ecpm', label: 'eCPM' },
          ],
          rows: data,
        },
      }
    case 'sortTableList':
      const list = prevState.tableList
      return {
        ...prevState,
        sort: {
          id: action.payload.id,
          by: action.payload.by,
        },
        tableList: {
          columns: list.columns,
          rows: sortCompare(list.rows, action.payload.id, action.payload.by),
        },
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
  sortList: (sort: any) => void
  exportData: (data: any, allData: any) => void
}

export const SummaryContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
  sortList: () => {},
  exportData: () => {},
})

export const SummaryContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: dashState } = useContext(PerformanceContext)
  const { state: globalState } = useContext(GlobalContext)

  const exportData = (data: any, allData: any) => {
    const arr = Object.keys(state.holdData)
    const days = state.holdData[arr[0]][dashState.chartType].days
    const name = `MAS_${days[0]}_${days[days.length - 1]}_${
      dashState.view
    }_view.csv`
    let csv = ''
    // 拼接筛选条件
    csv +=
      'VIEW_BY:,' +
      dashState.view +
      ',,AD_TYPE:,' +
      (dashState.type.map(item => item.label).join(',') || 'all') +
      ',,PLATFORM:,' +
      (dashState.platform.map(item => item.label).join(',') || 'all') +
      ',,APPLICATION:,' +
      (dashState.gameSelect.map(item => item.name).join(',') || 'all') +
      ',,COUNTRIES:,' +
      (dashState.country.map(item => item.name).join(',') || 'all') +
      '\n' +
      '\n'
    // 循环拼接数据
    data.forEach((column: any) => {
      csv += column.label + ','
    })
    csv += '\n'
    allData.forEach((row: any) => {
      data.forEach((column: any, i: number) => {
        const value = `"${
          (column.id === 'revenue' || column.id === 'ecpm' ? '$' : '') +
          row[column.id]
        }",`
        csv += value
      })
      csv += '\n'
    })
    downloadCsv(csv, name, 'text/csv;encoding:utf-8')
  }
  // effects
  useEffect(() => {
    dispatch({ type: 'gameListObject', payload: dashState.gameListObject })
    // eslint-disable-next-line
  }, [dashState.gameListObject])
  useEffect(() => {
    dispatch({
      type: 'countryListObject',
      payload: dashState.countryListObject,
    })
    // eslint-disable-next-line
  }, [dashState.countryListObject])
  useEffect(() => {
    dispatch({
      type: 'networkObject',
      payload: globalState.networkObject,
    })
  }, [globalState.networkObject])
  useEffect(() => {
    dispatch({
      type: 'tableList',
      payload: {
        tableData: dashState.data,
        summaryTableView: dashState.view,
      },
    })
    dispatch({ type: 'holdData', payload: dashState.data })
    // eslint-disable-next-line
  }, [dashState.data])
  useEffect(() => {
    dispatch({ type: 'summaryLoading', payload: dashState.dataLoading })
  }, [dashState.dataLoading])
  const sortList = (sort: any) => {
    dispatch({
      type: 'sortTableList',
      payload: sort,
    })
  }
  // returns
  return (
    <SummaryContext.Provider
      value={{
        state,
        dispatch,
        sortList,
        exportData,
      }}
    >
      {children}
    </SummaryContext.Provider>
  )
}
