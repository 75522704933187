import { useContext, useState } from 'react'
import { Box, Grid, useTheme, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DashContext } from 'src/context/dashboard'
import { LoadingComponent, BorderStyleSelectRow } from 'src/components'
import GeoChartView from './geoChart'
import CountryTableView from './countryTable'
import { rateTitleFormat } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      contentBottom: {
        position: 'relative',
        width: '100%',
      },
      searchList: {
        zIndex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
      },
      chartTable: {
        width: '100%',
        marginLeft: '6px',
        position: 'relative',
        marginTop: '10px',
        height: '374px',
        overflow: 'auto',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })
const CountryChart = (): JSX.Element => {
  // const
  const { state } = useContext(DashContext)
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [viewBy, setViewBy] = useState('revenue')
  const min900 = useMediaQuery('(min-width:900px)')
  const viewOptions = [
    {
      label: 'eCPM',
      value: 'ecpm',
    },
    {
      label: rateTitleFormat('Imp', 'DAU'),
      value: 'impdau',
    },
    {
      label: 'Revenue',
      value: 'revenue',
    },
    {
      label: 'Impressions',
      value: 'impressions',
    },
    {
      label: 'ARPDAU',
      value: 'arpdau',
    },
    {
      label: 'DAU',
      value: 'dau',
    },
  ]

  // returns
  return (
    <Box className={classes.contentBottom}>
      {state.dataLoading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}
      <Box>
        {/* title */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginBottom: '16px',
          }}
        >
          <Box fontSize="20px" fontWeight={500} lineHeight="36px">
            Country level Report
          </Box>
          <Box className={classes.searchList}>
            <BorderStyleSelectRow
              label="View By"
              placeholder="View By"
              value={{ value: viewBy }}
              onChange={(row: any) => {
                setViewBy(row.value)
              }}
              options={viewOptions}
              changeClose={true}
            />
          </Box>
        </Box>
        {/* content */}
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={9}
            style={{ height: '410px', overflow: 'hidden' }}
          >
            <Box
              marginTop="10px"
              borderRight={`${min900 ? '1px' : 0} solid ${
                theme.palette.divider
              }`}
            >
              <GeoChartView viewBy={viewBy} />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex" height="374px">
              <Box className={classes.chartTable}>
                <CountryTableView viewBy={viewBy} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CountryChart
