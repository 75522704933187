import { useContext, useMemo, useState } from 'react'
import { Box, Tooltip, Popover, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Notifications as NotificationsIcon } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { GlobalStoreContext } from 'src/context/globalStore'
import MessageBox from './messageBox'
import clsx from 'clsx'
import { NoDataView } from 'src/components'

const getUseStyles = (theme: Theme) => {
  return makeStyles(() => {
    return {
      topIcon: {
        position: 'relative',
        transform: 'translateY(1px)',
        marginRight: '24px',
        cursor: 'pointer',
      },
      topBarIcon: {
        color: theme.palette.text.primary,
      },
      notificationPopover: {
        '& .MuiPaper-root': {
          borderRadius: '8px',
          padding: '0',
        },
      },
      tag: {
        position: 'absolute',
        boxSizing: 'border-box',
        width: '18px',
        height: '18px',
        lineHeight: '15px',
        border: `1.5px solid ${theme.palette.background.paper}`,
        background: theme.palette.primary.main,
        borderRadius: '50%',
        right: '-6px',
        top: '-7px',
        fontSize: '12px',
        textAlign: 'center',
        color: '#FFF',
      },
      tab: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 500,
        height: '30px',
        cursor: 'pointer',
        padding: '0 12px',
      },
      checked: {
        background:
          theme.palette.mode === 'light'
            ? '#D2EAFE'
            : 'rgba(210, 234, 254, 0.3)',
        borderRadius: '10px',
      },
      line: {
        width: 'calc(100% - 12px)',
        height: '1px',
        background: theme.palette.mode === 'light' ? '#E1E5E7' : '#495263',
        position: 'absolute',
        top: '0',
        left: '6px',
        zIndex: 1,
      },
    }
  })
}
const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state: globalStoreState } = useContext(GlobalStoreContext)
  const navigate = useNavigate()
  // const
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [checkedTab, setCheckedTab] = useState<string>('All')
  const list = useMemo(() => {
    if (globalStoreState.notificationLoading) {
      return ['', '', '', '']
    }
    let temp = globalStoreState.notificationList
    if (checkedTab !== 'All') {
      temp = globalStoreState.notificationList.filter(
        item => item.category === checkedTab
      )
    }
    return temp.slice(0, 8)
  }, [
    globalStoreState.notificationLoading,
    globalStoreState.notificationList,
    checkedTab,
  ])
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // returns
  return (
    <>
      <Tooltip title="Notifications" placement="left" arrow>
        <Box
          className={classes.topIcon}
          aria-describedby={id}
          onClick={handleClick}
        >
          {globalStoreState.notificationUnreadCount > 0 && (
            <Box className={classes.tag}>
              {globalStoreState.notificationUnreadCount}
            </Box>
          )}
          <NotificationsIcon className={classes.topBarIcon} />
        </Box>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ overflowY: 'inherit' }}
        className={classes.notificationPopover}
      >
        <Box
          boxSizing="border-box"
          width="405px"
          maxWidth="90vw"
          maxHeight="70vh"
          padding="16px 0"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            fontSize="16px"
            fontWeight={700}
            marginBottom="24px"
            padding="0 16px"
            lineHeight="20px"
          >
            <Box>Notifications</Box>
            <Box
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                cursor: 'pointer',
                color: theme.palette.primary.main,
              }}
              onClick={() => {
                navigate('/dash/notifications')
                setAnchorEl(null)
              }}
            >
              View All
            </Box>
          </Box>
          <Box
            display="flex"
            gap="6px"
            padding="0 16px"
            flexWrap="wrap"
            marginBottom="18px"
          >
            {['All', 'Critical Updates', 'General', 'Optimizations'].map(
              (item: string) => (
                <Box
                  className={clsx({
                    [classes.tab]: true,
                    [classes.checked]: checkedTab === item,
                  })}
                  key={item}
                  onClick={() => {
                    setCheckedTab(item)
                  }}
                >
                  {item}
                </Box>
              )
            )}
          </Box>
          <Box overflow="auto" position="relative">
            <Box className={classes.line} />
            {list.length ? (
              <>
                {list.map((item: any, i: any) => (
                  <MessageBox
                    {...item}
                    key={i}
                    boxClick={() => {
                      navigate(
                        `/dash/notifications?id=${
                          item.id
                        }&type=${encodeURIComponent(checkedTab)}`
                      )
                      setAnchorEl(null)
                    }}
                    notificationLoading={globalStoreState.notificationLoading}
                  />
                ))}
              </>
            ) : (
              <Box marginBottom="30px">
                <NoDataView noDataDesc="0 notifications to see here" />
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default View
