import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ApiResponse } from 'src/api'
import {
  loginApi,
  LoginBody,
  twoFaVerifyApi,
  VerifyBody,
  twoFaRetryApi,
  RetryBody,
} from 'src/api/sso'
import { GlobalContext } from './global'
import {
  registerApi,
  resendVerifyEmailApi,
  verifyEmailApi,
  resetPasswordApi,
  resetPasswordEmailApi,
} from 'src/api/auth'
import {
  RegisterBody,
  VerifyEmailBody,
  ResetPasswordEmailBody,
  ResetPasswordBody,
} from 'src/api/type'
import { localStorageKey } from './type'
import { getQueryParams, md5Password } from 'src/utils'
import { Userpilot } from 'userpilot'

type Props = {
  loading: boolean
  openModel: boolean
  email_not_verified: boolean
  emailCodeInterval: number
  emailCodeLoading: boolean
  twoFaLoading: boolean
  attemptsRemaining: number
  signUpEmail: string
}

enum Actions {
  LOADING = 'LOADING',
  OPEN_MODEL = 'OPEN_MODEL',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  EMAIL_CODE_INTERVAL = 'EMAIL_CODE_INTERVAL',
  EMAIL_CODE_LOADING = 'EMAIL_CODE_LOADING',
  TWO_FA_LOADING = 'TWO_FA_LOADING',
  ATTEMPTS_REMAINING = 'ATTEMPTS_REMAINING',
  SIGN_UP_EMAIL = 'SIGN_UP_EMAIL',
}

type Action = {
  type: Actions
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  loading: false,
  openModel: false,
  email_not_verified: false,
  emailCodeInterval: 0,
  emailCodeLoading: false,
  twoFaLoading: false,
  attemptsRemaining: 3,
  signUpEmail: '',
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case Actions.LOADING:
      return {
        ...prevState,
        loading: !!action.payload,
      }
    case Actions.OPEN_MODEL:
      return {
        ...prevState,
        openModel: action.payload,
      }
    case Actions.EMAIL_NOT_VERIFIED:
      return {
        ...prevState,
        email_not_verified: action.payload,
      }
    case Actions.EMAIL_CODE_INTERVAL:
      return {
        ...prevState,
        emailCodeInterval: action.payload,
      }
    case Actions.EMAIL_CODE_LOADING:
      return {
        ...prevState,
        emailCodeLoading: action.payload,
      }
    case Actions.TWO_FA_LOADING:
      return {
        ...prevState,
        twoFaLoading: action.payload,
      }
    case Actions.ATTEMPTS_REMAINING:
      return {
        ...prevState,
        attemptsRemaining: action.payload,
      }
    case Actions.SIGN_UP_EMAIL:
      return {
        ...prevState,
        signUpEmail: action.payload,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  login: (params: LoginBody) => void
  register: (params: RegisterBody) => void
  resendVerifyEmail: (email: string) => void
  verifyEmail: (params: VerifyEmailBody) => void
  resetPassword: (params: ResetPasswordBody) => void
  resetPasswordEmail: (params: ResetPasswordEmailBody) => void
  setOpenModel: (value: boolean) => void
  setEmailVerifiedModel: (value: boolean) => void
  twoFaVerify: (params: VerifyBody) => void
  twoFaRetry: (params: RetryBody) => void
}

export const AuthContext = createContext<Context>({
  state: initialState,
  login: (params: LoginBody) => {},
  register: (params: RegisterBody) => {},
  resendVerifyEmail: (email: string) => {},
  verifyEmail: (params: VerifyEmailBody) => {},
  resetPassword: (params: ResetPasswordBody) => {},
  resetPasswordEmail: (params: ResetPasswordEmailBody) => {},
  setOpenModel: (value: boolean) => {},
  setEmailVerifiedModel: (value: boolean) => {},
  twoFaVerify: (params: VerifyBody) => {},
  twoFaRetry: (params: RetryBody) => {},
})

export const AuthContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const {
    state: globalState,
    dispatch: globalDispatch,
    setToken,
    customAlert,
    errorCatch,
  } = useContext(GlobalContext)
  const navigate = useNavigate()
  const locationInfo = useLocation()
  const [t] = useTranslation()
  let timer: any = null
  // handlers
  const login = (body: LoginBody) => {
    dispatch({
      type: Actions.LOADING,
      payload: true,
    })
    const params = { ...body, password: md5Password(body.password) }
    const deviceId = localStorage.getItem(params.email)
    if (deviceId) {
      params.device_id = deviceId
    }
    loginApi(params)
      .then(({ data }: ApiResponse) => {
        localStorage.setItem(localStorageKey.TOKEN_SOURCE_ADMIN, 'false')
        setToken(data.access_token)
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 401 &&
          err?.response?.data?.code === 'user.need_2fa_verify'
        ) {
          // 跳转验证码页面
          navigate('/two-fa?email=' + body.email)
        } else if (
          err?.response?.status === 401 &&
          err?.response?.data?.code === 'user.email_not_verified'
        ) {
          setEmailVerifiedModel(true)
        } else if (
          err?.response?.status === 403 &&
          err?.response?.data?.code === 'user.violation_issue_1'
        ) {
          globalDispatch({
            type: 'SHOW_VIOLATION_ISSUE',
            payload: true,
          })
          if (timer) {
            clearTimeout(timer)
            timer = null
          }
          timer = setTimeout(() => {
            globalDispatch({
              type: 'SHOW_VIOLATION_ISSUE',
              payload: false,
            })
            clearTimeout(timer)
            timer = null
          }, 8000)
        } else {
          errorCatch(err)
        }
      })
      .finally(() => {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        })
      })
  }
  const twoFaVerify = (params: VerifyBody) => {
    dispatch({
      type: Actions.TWO_FA_LOADING,
      payload: true,
    })
    twoFaVerifyApi(params)
      .then(({ data }: ApiResponse) => {
        localStorage.setItem(localStorageKey.TOKEN_SOURCE_ADMIN, 'false')
        if (params.device_id) {
          localStorage.setItem(params.email, params.device_id)
        }
        setToken(data.access_token)
        dispatch({
          type: Actions.ATTEMPTS_REMAINING,
          payload: 3,
        })
      })
      .catch((error: any) => {
        const temp = state.attemptsRemaining - 1
        dispatch({
          type: Actions.ATTEMPTS_REMAINING,
          payload: temp < 0 ? 0 : temp,
        })
        errorCatch(error, true)
      })
      .finally(() => {
        dispatch({
          type: Actions.TWO_FA_LOADING,
          payload: false,
        })
      })
  }
  const twoFaRetry = (params: RetryBody) => {
    twoFaRetryApi(params)
      .then(() => {
        emailCodeIntervalInit()
        customAlert('success', 'Send successfully!')
      })
      .catch((error: any) => {
        errorCatch(error)
      })
  }
  const setEmailVerifiedModel = (value: boolean) => {
    dispatch({
      type: Actions.EMAIL_NOT_VERIFIED,
      payload: value,
    })
  }
  const register = (body: RegisterBody) => {
    dispatch({
      type: Actions.LOADING,
      payload: true,
    })
    body.passwd = md5Password(body.passwd)
    const utm: any = {}
    if (locationInfo.search) {
      const temp = getQueryParams(locationInfo.search)
      if (temp.utm_source) {
        utm.utm_source = temp.utm_source
      }
      if (temp.utm_medium) {
        utm.utm_medium = temp.utm_medium
      }
      if (temp.utm_campaign) {
        utm.utm_campaign = temp.utm_campaign
      }
    }
    body.utm = utm
    registerApi(body)
      .then((res: any) => {
        dispatch({
          type: Actions.SIGN_UP_EMAIL,
          payload: res?.data?.email,
        })
        setOpenModel(true)
        if (process.env.REACT_APP_ENV === 'test') {
          Userpilot.identify(res?.data?.developer_key, {
            name: res?.data?.name,
            email: res?.data?.email,
          })
        }
        if (process.env.REACT_APP_ENV !== 'product') return
        const fbq: any = (Window as any).fbq
        fbq && fbq('track', 'MasPortal_RegisteredSuccessfully')
        if (locationInfo.search) {
          const temp = getQueryParams(locationInfo.search)
          const params = []
          if (temp.utm_source) {
            params.push('utm_source=' + temp.utm_source)
          }
          if (temp.utm_medium) {
            params.push('utm_medium=' + temp.utm_medium)
          }
          if (temp.utm_campaign) {
            params.push('utm_campaign=' + temp.utm_campaign)
          }
          if (params.length) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const gtag: any = window.gtag
            gtag &&
              gtag('event', 'RegisteredByUTM', {
                full_path:
                  params.join('&') +
                  '&developer_key=' +
                  res?.data?.developer_key,
                developer_key: res?.data?.developer_key,
                timestamp: new Date().toISOString(),
              })

            params.push('timestamp=' + new Date().toISOString())
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const clarity: any = window.clarity
            clarity &&
              clarity(
                'set',
                'RegisteredByUTM',
                params.join('&') + '&developer_key=' + res?.data?.developer_key
              )
          }
        }
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        })
      })
  }
  const setOpenModel = (value: boolean) => {
    dispatch({
      type: Actions.OPEN_MODEL,
      payload: value,
    })
  }

  const emailCodeIntervalInit = (oldSeconds?: number) => {
    let seconds = oldSeconds || 60
    dispatch({
      type: Actions.EMAIL_CODE_INTERVAL,
      payload: seconds,
    })
    if (!oldSeconds) {
      sessionStorage.setItem('seconds', String(new Date().getTime()))
    }
    const timer = setInterval(() => {
      dispatch({
        type: Actions.EMAIL_CODE_INTERVAL,
        payload: --seconds,
      })

      if (!seconds) {
        sessionStorage.removeItem('seconds')
        clearInterval(timer)
      }
    }, 1000)
  }
  useEffect(() => {
    const seconds = sessionStorage.getItem('seconds')
    if (seconds) {
      const temp =
        60 - Math.round((new Date().getTime() - Number(seconds)) / 1000)
      temp > 0 && emailCodeIntervalInit(temp)
    }
  }, [])
  const resendVerifyEmail = (email: string) => {
    dispatch({
      type: Actions.EMAIL_CODE_LOADING,
      payload: true,
    })

    resendVerifyEmailApi(email)
      .then(({ data }: ApiResponse) => {
        emailCodeIntervalInit()
        customAlert('success', t('auth.emailVerify.resendEmailMsg'))
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: Actions.EMAIL_CODE_LOADING,
          payload: false,
        })
      })
  }

  const verifyEmail = (body: VerifyEmailBody) => {
    dispatch({
      type: Actions.LOADING,
      payload: true,
    })

    verifyEmailApi(body)
      .then(() => {
        setOpenModel(true)
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        })
      })
  }

  const resetPasswordEmail = (body: ResetPasswordEmailBody) => {
    dispatch({
      type: Actions.LOADING,
      payload: true,
    })

    resetPasswordEmailApi(body)
      .then(() => {
        setOpenModel(true)
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        })
      })
  }

  const resetPassword = (body: ResetPasswordBody) => {
    dispatch({
      type: Actions.LOADING,
      payload: true,
    })
    body.passwd = md5Password(body.passwd)
    resetPasswordApi(body)
      .then(() => {
        setOpenModel(true)
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 400 &&
          err?.response?.data?.code === 'user.reset_passwd_code_outdated'
        ) {
          navigate('/password/forgot')
        }
        errorCatch(err)
      })
      .finally(() => {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        })
      })
  }

  const isVerifyEmail = useMemo(
    () => locationInfo.pathname === '/email-verification',
    [locationInfo.pathname]
  )

  const loginRedirect = () => {
    const queryObj = getQueryParams(location.href)
    if (queryObj.access_token) {
      setToken(queryObj.access_token as string)
    }
    if (queryObj.mock_login) {
      localStorage.setItem(localStorageKey.TOKEN_SOURCE_ADMIN, 'true')
    }
  }
  // effects
  useEffect(() => {
    if (globalState.token_pristine) {
      loginRedirect()
      return
    }

    if (globalState.token && !isVerifyEmail) {
      const queryObj = getQueryParams(location.href)
      if (queryObj.redirect) {
        window.location.href = decodeURIComponent(queryObj.redirect as string)
        return
      }

      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.token, globalState.token_pristine, navigate, isVerifyEmail])

  // returns
  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        register,
        resendVerifyEmail,
        verifyEmail,
        resetPassword,
        resetPasswordEmail,
        setOpenModel,
        setEmailVerifiedModel,
        twoFaVerify,
        twoFaRetry,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
