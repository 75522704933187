import { useContext, useEffect, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { AdPlacementsContext } from 'src/context/adPlacement'
import { ReportsTab } from 'src/components'

const TopChart = (): JSX.Element => {
  const navigate = useNavigate()
  const params: any = useParams()
  const { state, dispatch } = useContext(AdPlacementsContext)

  const options = useMemo(() => {
    return [...state.chartTabData, ...state.davChartTabData]
  }, [state.chartTabData, state.davChartTabData])
  const handleChange = (newValue: string) => {
    dispatch({
      type: 'chartType',
      payload: newValue,
    })
    navigate(`/dash/ad-placement/${newValue}`)
  }
  useEffect(() => {
    if (params.tab) {
      dispatch({
        type: 'chartType',
        payload: params.tab,
      })
    }
    // eslint-disable-next-line
  }, [])

  // returns
  return (
    <Box width="100%">
      <ReportsTab
        value={state.chartType}
        options={options}
        onChange={handleChange}
      />
    </Box>
  )
}

export default TopChart
