import { useEffect, useRef, useContext } from 'react'
import { Chart } from 'chart.js'
import { DashContext } from 'src/context/dashboard'
import { topojson } from 'chartjs-chart-geo'
import countryGeo from './json'

const View = (props: { viewBy: string }) => {
  const chart = useRef<any>()
  const { state } = useContext(DashContext)

  // effects
  useEffect(() => {
    const features: any = topojson.feature(
      countryGeo,
      countryGeo.objects.countries
    )
    const countries = features.features
    const labels = countries.map((d: any) => d.properties.name)
    if (!chart.current) {
      const chartEl: any = document.getElementById('geoChart')
      if (chartEl) {
        chart.current = new Chart(chartEl.getContext('2d'), {
          type: 'choropleth',
          data: {
            labels,
            datasets: [
              {
                label: 'Countries',
                data: countries.map((d: any) => ({
                  feature: d,
                  value: 0,
                })),
              },
            ],
          },
          options: {
            showOutline: false,
            showGraticule: false,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              xy: {
                projection: 'mercator',
              },
              color: {
                quantize: 10,
                legend: {
                  align: 'bottom',
                  indicatorWidth: 8,
                  margin: 40,
                },
              },
            },
          },
        })
      }
    }
    let datasets = []
    const keys = Object.keys(state.geoData)
    if (!keys.length || keys[0] === 'all') {
      return
    } else {
      datasets = [
        {
          label: 'Countries',
          data: countries.map((d: any) => ({
            feature: d,
            value: state.geoData[d.properties['Alpha-2']]
              ? state.geoData[d.properties['Alpha-2']][props.viewBy].summary
              : 0,
          })),
        },
      ]
    }
    chart.current.data = {
      labels,
      datasets,
    }
    chart.current?.update()
    return function willUnmount() {
      if (chart.current) {
        chart.current.clear()
        chart.current.destroy()
        chart.current = undefined
      }
    }
  }, [state.geoData, props.viewBy])

  // returns
  return <canvas id="geoChart" width="100%" height="400px" />
}

export default View
