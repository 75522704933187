import Dashboard from './performance'
import { PerformanceContextProvider } from 'src/context/performance'

const Dash = (): JSX.Element => {
  // returns
  return (
    <PerformanceContextProvider>
      <Dashboard />
    </PerformanceContextProvider>
  )
}

export default Dash
