import { useState, useMemo } from 'react'
import { Box, Popover, InputBase, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      popover: {
        '& .MuiPaper-root': {
          minWidth: '200px',
          maxWidth: '500px',
          maxHeight: '70vh',
          marginTop: '14px',
          borderRadius: '6px',
          padding: '0',
          background: theme.palette.custom.dialogBg,
        },
      },
      input: {
        width: '100%',
        boxSizing: 'border-box',
        height: '32px',
      },
      option: {
        width: '100%',
        display: 'flex',
        padding: '6px 14px',
        cursor: 'pointer',
        color: theme.palette.custom.inputLabel,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
          background:
            theme.palette.mode === 'light'
              ? 'rgba(224, 229, 231, 0.5)'
              : '#555D6A',
        },
      },
      checked: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        background:
          theme.palette.mode === 'light'
            ? 'rgba(224, 229, 231, 0.5)'
            : '#555D6A',
      },
      filterStyle: {
        display: 'flex',
        boxSizing: 'border-box',
        width: '210px',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '8px 16px',
        margin: '7px 0',
        justifyContent: 'space-between',
      },
      smallStyle: {
        fontSize: '12px',
        width: '170px',
      },
      selectStyle: {
        display: 'flex',
        boxSizing: 'border-box',
        width: '100%',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '8px 8px',
        margin: '0',
        justifyContent: 'space-between',
        border: `1px solid ${
          theme.palette.mode === 'dark' ? '#51565F' : '#eee'
        }`,
        borderRadius: '4px',
      },
      viewByStyle: {
        display: 'flex',
        boxSizing: 'border-box',
        width: '180px',
        height: '36px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '6px 16px',
        justifyContent: 'space-between',
        background: theme.palette.mode === 'light' ? '#7487AA' : 'transparent',
        borderRadius: '4px',
        border: theme.palette.mode === 'light' ? '0 none' : '1px solid #51565F',
        color: '#FFF',
      },
    }
  })

interface selectProps {
  placeholder?: string
  labelKey?: string
  valueKey?: string
  disabled?: boolean
  label?: string
  isTab?: boolean
  filterStyle?: boolean
  selectStyle?: boolean
  customStyle?: any
  showSearch?: boolean
  options: any[]
  value: any
  customClass?: any
  hideOption?: boolean
  onChange: (value: string) => void
}
export default function StyleSelectOne(props: selectProps) {
  const {
    placeholder = '',
    labelKey = 'label',
    valueKey = 'value',
    disabled = false,
    label = '',
    isTab = false,
    filterStyle = false,
    selectStyle = false,
    showSearch = false,
    hideOption = false,
    customStyle = {},
    options,
    value,
    onChange,
  } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const showValue = useMemo(() => {
    let temp = value
    const selectTemp = options.find((item: any) => item[valueKey] === value)
    if (selectTemp) {
      temp = selectTemp[labelKey]
    }
    return temp
    // eslint-disable-next-line
  }, [value])
  const showOption = useMemo(() => {
    let tempOptions: any = options
    if (hideOption) {
      tempOptions = tempOptions.filter((item: any) => !item.hideOption)
    }
    if (searchValue) {
      return tempOptions.filter((item: any) =>
        item[labelKey].toUpperCase().includes(searchValue.toUpperCase().trim())
      )
    }
    return tempOptions
    // eslint-disable-next-line
  }, [options, searchValue, hideOption])

  const handleClick = (event: any) => {
    if (disabled) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const searchChange = (e: any) => {
    setSearchValue(e.target.value)
  }
  const toCheck = (value: string) => {
    onChange(value)
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-select-popover' : undefined
  return (
    <>
      <Box
        className={clsx({
          [classes.filterStyle]: filterStyle,
          [classes.selectStyle]: selectStyle,
          [classes.viewByStyle]: !filterStyle && !selectStyle,
          [props.customClass]: !!props.customClass,
        })}
        sx={isTab ? { margin: '0' } : customStyle}
        aria-describedby={id}
        onClick={handleClick}
        id={'select' + label}
        style={{
          cursor: disabled ? 'not-allowed' : '',
        }}
      >
        <Box display="flex" width="100%">
          {label && (
            <Box
              sx={{
                marginRight: '8px',
                color:
                  filterStyle || selectStyle
                    ? theme.palette.text.secondary
                    : '',
              }}
            >
              {label}:
            </Box>
          )}
          <Box
            sx={{
              maxWidth: selectStyle ? '100%' : isTab ? '160px' : '90px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {showValue ? (
              <span
                style={
                  filterStyle || selectStyle
                    ? {
                        opacity: disabled ? 0.4 : 1,
                        color: theme.palette.text.primary,
                      }
                    : {
                        color:
                          theme.palette.mode === 'light'
                            ? '#FFF'
                            : theme.palette.primary.main,
                        opacity: disabled ? 0.4 : 1,
                      }
                }
              >
                {showValue}
              </span>
            ) : (
              <span style={{ opacity: 0.4 }}>{placeholder}</span>
            )}
          </Box>
        </Box>
        <SVGIcon.ChevronDownSVG
          color={
            filterStyle || selectStyle ? theme.palette.text.primary : '#FFF'
          }
          style={{
            marginTop: '3px',
            transform: anchorEl ? 'rotate(180deg)' : '',
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
        sx={{ zIndex: 1999 }}
      >
        <Box fontSize="14px" padding="8px 0">
          {/* search input */}
          {showSearch && (
            <Box position="relative" margin="0px 8px 2px">
              <InputBase
                className={classes.input}
                placeholder="Search"
                value={searchValue}
                onChange={searchChange}
                style={{
                  background: theme.palette.custom.dialogBg,
                  border: `1px solid ${theme.palette.custom.paperBorder}`,
                  borderRadius: '6px',
                  boxShadow: 'none',
                }}
              />
              <SVGIcon.SearchIconSVG
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '8px',
                }}
              />
            </Box>
          )}
          {/* options */}
          {showOption.length ? (
            <Box>
              {showOption.map((item: any, index: number) => (
                <Box
                  className={clsx({
                    [classes.option]: true,
                    [classes.checked]: item[valueKey] === value,
                  })}
                  key={index}
                  onClick={() => {
                    toCheck(item[valueKey])
                  }}
                >
                  <Box lineHeight="24px">{item[labelKey]}</Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box textAlign="center" padding="16px">
              No Data
            </Box>
          )}
        </Box>
      </Popover>
    </>
  )
}
