import { portalService } from './service'

export const getDeviceListApi = (params: any) => {
  return portalService({
    url: '/test_device',
    method: 'get',
    params,
  })
}

export const editDeviceApi = (data: any) => {
  return portalService({
    url: `test_device/${data.id}`,
    method: 'patch',
    data,
  })
}
export const addDeviceApi = (data?: any) => {
  return portalService({
    url: '/test_device',
    method: 'post',
    data,
  })
}
export const deleteDeviceApi = (id: string) => {
  return portalService({
    url: `/test_device/${id}`,
    method: 'delete',
  })
}
