import { useEffect, useRef, useContext, useState, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { init, EChartsType } from 'echarts'
import { MiddleChartContext } from 'src/context/userActivity/middleChart'
import { getEchartOptions } from 'src/utils'
import { useTheme, Theme } from '@material-ui/core'
import { OptimizationEventDialog } from 'src/components'

const View = (props: any) => {
  const chart = useRef<EChartsType>()
  const { state } = useContext(MiddleChartContext)
  const theme: Theme = useTheme()
  const [open, setOpen] = useState(false)
  const [eventDate, setEventDate] = useState<string>('')
  const eventData = useMemo(() => {
    const array = state.commentsEvent[eventDate]
    if (array?.length) {
      return array.map((element: { app_key: string; message: string }) => {
        return {
          name: state.gameListObject[element.app_key]?.game_name,
          ...element,
        }
      })
    }
    return []
  }, [eventDate, state.gameListObject, state.commentsEvent])

  // effects
  useEffect(() => {
    const data = state.data?.data?.filter((item: any) =>
      state.checkedChartLine.includes(item.name)
    )
    const options = getEchartOptions(
      state.data.days,
      data,
      state.newChartType,
      theme.palette.mode,
      state.commentsEvent
    )
    if (!chart.current) {
      const chartEl: HTMLElement | null =
        document.getElementById('echartUserActivity')
      if (chartEl) {
        chart.current = init(chartEl)
      }
    }
    chart.current?.on('click', function (params: any) {
      if (params.componentType === 'markLine') {
        setEventDate(params?.data?.value)
        setOpen(true)
      }
    })
    chart.current?.setOption(options, true)
    chart.current?.resize()
  }, [
    state.data,
    state.newChartType,
    state.checkedChartLine,
    theme.palette.mode,
    state.commentsEvent,
    state.gameListObject,
  ])

  useEffect(() => {
    // Bind the resize method to the window
    window.addEventListener('resize', () => {
      chart.current?.resize()
    })

    return function willUnmount() {
      if (chart.current) {
        chart.current.clear()
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [])

  // returns
  return (
    <>
      <Box
        id="echartUserActivity"
        width="100%"
        height="394px"
        boxSizing="border-box"
      />
      <OptimizationEventDialog
        open={open}
        eventData={eventData}
        onClose={() => {
          setOpen(false)
        }}
      />
    </>
  )
}

export default View
