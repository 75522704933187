import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Box, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useContext } from 'react'
import { AuthContext } from 'src/context/auth'
import {
  LoadingComponent,
  AuthPromptDialog,
  AuthPageInput,
} from 'src/components'
import { GlobalContext } from 'src/context/global'

interface FormValues {
  password: string
  password_confirm: string
}

const getUseStyles = () =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
      },
      icon: {
        width: '22px',
        height: '18px',
      },
      title: {
        textAlign: 'center',
        color: '#1D3353',
        fontWeight: 600,
        fontSize: '18px',
        marginBottom: '20px',
        lineHeight: '34px',
      },
    }
  })

const View = (): JSX.Element => {
  const useStyles = getUseStyles()
  const classes = useStyles()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { state, resetPassword, setOpenModel } = useContext(AuthContext)
  const { customAlert } = useContext(GlobalContext)
  const FormSchema = yup.object().shape({
    password: yup.string().min(6, t('auth.login.passwordMinMsg')).max(20),
    password_confirm: yup
      .string()
      .min(6, t('auth.login.passwordMinMsg'))
      .max(20),
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
  })

  // handler
  const onSubmit = (submitData: FormValues) => {
    const { password, password_confirm } = submitData
    if (password_confirm !== password) {
      setError('password_confirm', {
        message: t('setting.password.passwordConfirmErrMsg'),
      })
      return
    }

    const temp: { email?: string; code?: string } = location.search
      .slice(1)
      .split('&')
      .reduce((prev, current: string) => {
        const [keyTemp, val] = current.split('=')
        const key = keyTemp === 'token' ? 'code' : keyTemp

        return {
          ...prev,
          [key]: val,
        }
      }, {})

    if (temp.code && temp.email) {
      resetPassword({
        passwd: password,
        email: temp.email,
        code: temp.code,
      })
    } else {
      customAlert('warning', 'Incorrect reset link')
    }
  }

  const dialogConfirm = () => {
    setOpenModel(false)
    navigate('/login')
  }

  // returns
  if (state.loading) {
    return (
      <Box display="flex" justifyContent="center">
        <LoadingComponent />
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
      <Box className={classes.title}>Reset Password</Box>
      <AuthPageInput
        label="Password"
        name="password"
        control={control}
        defaultValue=""
        placeholder={t('auth.login.password')}
        type="password"
        variant="outlined"
        error={!!errors.password}
        helperText={errors.password?.message}
      />

      <AuthPageInput
        label="Confirm Password"
        name="password_confirm"
        control={control}
        defaultValue=""
        placeholder={t('auth.signup.password_confirm')}
        type="password"
        variant="outlined"
        error={!!errors.password_confirm}
        helperText={errors.password_confirm?.message}
      />

      <Box margin="28px 0 14px">
        <Button fullWidth variant="contained" color="primary" type="submit">
          {t('auth.password.reset')}
        </Button>
      </Box>
      <Box textAlign="center">
        <Link
          onClick={() => {
            navigate('/login')
          }}
          underline="none"
          style={{ fontWeight: 600 }}
        >
          {t('auth.login.sign_in')}
        </Link>
      </Box>
      <AuthPromptDialog
        openModel={state.openModel}
        isSuccessDialog
        title={t('auth.password.resetPasswdSuccessMsgTitle')}
        content={t('auth.password.resetPasswdSuccessMsgBody')}
        onConfirm={dialogConfirm}
        contentPadding="0 20px"
      />
    </form>
  )
}

export default View
