import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from 'src/context/global'
import { LoadingComponent } from 'src/components'
import ScoreChartView from './echart'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      contentBottom: {
        position: 'relative',
        width: '100%',
        height: '362px',
        padding: '22px 24px 24px',
        boxSizing: 'border-box',
      },
      detailButton: {
        fontSize: '12px',
        fontWeight: 400,
        color: '#1E96FC',
        cursor: 'pointer',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: 'calc(100% - 48px)',
        justifyContent: 'center',
        height: 'calc(100% - 48px)',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })
const CountryChart = (): JSX.Element => {
  // const
  const { state } = useContext(GlobalContext)
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()

  // returns
  return (
    <Box className={classes.contentBottom}>
      {state.performanceScoringLoading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}
      <Box width="100%">
        {/* title */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            height: '32px',
            lineHeight: '32px',
          }}
        >
          <Box fontSize="20px" fontWeight={500}>
            Performance Score
          </Box>
          <Box
            className={classes.detailButton}
            onClick={() => {
              navigate('/dash/games')
            }}
          >
            <Box component="span">More Details</Box>
            <ArrowForwardIosIcon
              sx={{
                fontSize: '14px !important',
                color: '#1E96FC',
                verticalAlign: 'text-top',
                marginLeft: '4px',
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '397px',
            fontSize: '12px',
            transform: 'scale(0.83)',
            transformOrigin: 'left center',
          }}
        >
          Recommendations to grow and improve the performance of your apps and
          games.
        </Box>
        {/* content */}
        <Box width="100%" display="flex" justifyContent="center">
          <ScoreChartView />
        </Box>
      </Box>
    </Box>
  )
}

export default CountryChart
