import { useTheme, Theme } from '@material-ui/core'
import StylePaper from './stylePaper'

const TopTipPaper = (props: any): JSX.Element => {
  const theme: Theme = useTheme()
  return (
    <StylePaper
      width="100%"
      boxSizing="border-box"
      padding="16px"
      border={`1px solid ${theme.palette.custom.tipBorder}`}
      boxShadow="none"
      background={theme.palette.custom.tipBg}
      marginTop="24px"
      fontSize="14px"
      color={theme.palette.mode === 'light' ? '#1A202C' : '#FFF'}
      {...props}
    >
      {props.children}
    </StylePaper>
  )
}

export default TopTipPaper
