import { createContext, useReducer, useEffect, useContext } from 'react'
import { GlobalContext } from '../global'
import { GlobalStoreContext } from '../globalStore'
import { numFixed, toPercent, transformUTC0ToLocalTime } from 'src/utils'
import { getStickinessApi } from 'src/api/cohorts'

type Props = {
  data: any
  holdData: any
  loading: boolean
  gameSelect: any
  gameListObject: any
  chartList: Array<any>
  checkedChartLine: Array<string>
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  loading: false,
  data: {},
  holdData: {},
  gameSelect: {},
  gameListObject: {},
  chartList: [
    {
      label: 'All',
      value: 'All',
      color: '#FF8E00',
    },
  ],
  checkedChartLine: [],
}

const colorList = ['#FF8E00', '#90cc75', '#f07474', '#5a70b5']
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'data':
      const allData = action.payload.data || {}
      const keys = Object.keys(allData)
      const labels = action.payload.days
      if (!keys.length) {
        return {
          ...prevState,
          data: {},
        }
      }
      const datasets: any = []
      const chartList: any = []
      const checkedChartLine: any = []
      keys.forEach((item: any, i: number) => {
        const color: string =
          colorList[i] ||
          '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
        const label = prevState.gameListObject[item]?.name || item
        datasets.push({
          label,
          data: allData[item]['stickiness'],
          pointRadius: 2.5,
          borderColor: color,
          backgroundColor: color,
          tension: 0.2,
          hitRadius: 10,
        })
        chartList.push({
          label,
          value: label,
          color,
          total: allData[item]['stickiness'].reduce(
            (a: number, b: number) => a + b
          ),
        })
        checkedChartLine.push(label)
      })
      const data = {
        labels,
        datasets,
      }
      return {
        ...prevState,
        data,
        chartList: chartList.sort((a: any, b: any) => b.total - a.total),
        checkedChartLine,
      }

    case 'holdData':
      const holdData = JSON.parse(JSON.stringify(action.payload))
      const disabled = action.payload?.tabDisabled || false
      const untilTime = action.payload?.untilTime || null
      if (!holdData?.relative_change) {
        holdData.relative_change = {
          stickiness: {
            value: '-',
            trend: '-',
            disabled,
            untilTime,
          },
        }
      } else {
        const trend = holdData.relative_change.stickiness >= 0 ? 'up' : 'down'
        holdData.relative_change.stickiness = {
          value: toPercent(
            numFixed(Math.abs(holdData.relative_change?.stickiness), 4)
          ),
          trend,
          disabled,
          untilTime,
        }
      }
      return { ...prevState, holdData }
    case 'loading':
    case 'gameListObject':
    case 'gameSelect':
    case 'checkedChartLine':
    case 'chartList':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  getStickiness: () => void
}

export const StickinessContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  getStickiness: () => {},
})

export const StickinessContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: GlobalState, errorCatch } = useContext(GlobalContext)
  const { state: globalStoreState, dispatch: globalStoreDispatch } =
    useContext(GlobalStoreContext)
  // handlers
  const getStickiness = () => {
    dispatch({ type: 'loading', payload: true })
    const data = {
      app_key: state.gameSelect.app_key || GlobalState.gameOption[0].app_key,
    }
    globalStoreDispatch({
      type: 'stickinessData',
      payload: {
        filter: {
          gameSelect: state.gameSelect || GlobalState.gameOption[0],
        },
      },
    })
    getStickinessApi(data)
      .then(({ data }: any) => {
        if (!data.days || !data.data || Object.keys(data.data).length <= 0) {
          globalStoreDispatch({
            type: 'stickinessData',
            payload: {
              data: {},
              holdData: {},
            },
          })
          return
        }
        dispatch({ type: 'data', payload: data })
        dispatch({ type: 'holdData', payload: data })
        globalStoreDispatch({
          type: 'stickinessData',
          payload: {
            data: data,
            holdData: data,
          },
        })
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 503 &&
          err?.response?.data?.code === 'trino_migration'
        ) {
          let untilTime = null
          if (err?.response?.data?.extra?.after) {
            untilTime = transformUTC0ToLocalTime(
              err?.response?.data?.extra?.after,
              'Do MMM YYYY hh:mm a'
            )
          }
          dispatch({
            type: 'holdData',
            payload: { tabDisabled: true, untilTime },
          })
          globalStoreDispatch({
            type: 'stickinessData',
            payload: {
              data: {},
              holdData: { tabDisabled: true, untilTime },
            },
          })
        } else {
          errorCatch(err)
          globalStoreDispatch({
            type: 'stickinessData',
            payload: {
              data: {},
              holdData: {},
            },
          })
        }
      })
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
  }

  useEffect(() => {
    dispatch({ type: 'gameListObject', payload: GlobalState.gameListObject })
    if (GlobalState.gameOption.length) {
      if (globalStoreState.stickinessData.filter) {
        Object.keys(globalStoreState.stickinessData.filter).forEach(
          (item: string) => {
            dispatch({
              type: item,
              payload: globalStoreState.stickinessData.filter[item],
            })
          }
        )
        Object.keys(globalStoreState.stickinessData)
          .filter((item: string) => item !== 'filter')
          .forEach((item: string) => {
            dispatch({
              type: item,
              payload: globalStoreState.stickinessData[item],
            })
          })
        return
      }
      dispatch({ type: 'gameSelect', payload: GlobalState.gameOption[0] })
      if (GlobalState.gameOption[0]?.app_key) {
        getStickiness()
      }
    }
    // eslint-disable-next-line
  }, [GlobalState.gameListObject])
  // returns
  return (
    <StickinessContext.Provider
      value={{
        state,
        dispatch,
        getStickiness,
      }}
    >
      {children}
    </StickinessContext.Provider>
  )
}
