const SVG = (props: any) => {
  const width = props.width || '14'
  const height = props.height || '25'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1944 0H3.00334C1.55782 0.00163914 0.386433 1.17301 0.38501 2.61854V21.9994C0.386433 23.4449 1.55782 24.6163 3.00334 24.6177H11.1944C12.6399 24.6163 13.8112 23.4449 13.8129 21.9994V2.61854C13.8112 1.17301 12.6399 0.00163914 11.1944 0ZM12.6937 21.9994C12.6929 22.8271 12.0221 23.498 11.1944 23.4988H3.00334C2.17557 23.498 1.50473 22.8271 1.50393 21.9994V2.61854C1.50473 1.79056 2.17557 1.11974 3.00334 1.11892H11.1944C12.0221 1.11974 12.6929 1.79056 12.6937 2.61854V21.9994Z"
        fill={color}
      />
      <path
        d="M8.77762 2.23828H5.42064C5.11169 2.23828 4.86108 2.48866 4.86108 2.79764C4.86108 3.10662 5.11169 3.3572 5.42064 3.3572H8.77762C9.08661 3.3572 9.33698 3.10662 9.33698 2.79764C9.33698 2.48866 9.08661 2.23828 8.77762 2.23828Z"
        fill={color}
      />
      <path
        d="M8.2178 21.2615C8.2178 21.8797 7.71683 22.3806 7.09888 22.3806C6.48071 22.3806 5.97974 21.8797 5.97974 21.2615C5.97974 20.6436 6.48071 20.1426 7.09888 20.1426C7.71683 20.1426 8.2178 20.6436 8.2178 21.2615Z"
        fill={color}
      />
      <path
        d="M11.1826 4.78125H3.04994C2.8375 4.78125 2.66528 4.95346 2.66528 5.1659V18.1342C2.66528 18.3466 2.8375 18.5189 3.04994 18.5189H11.1826C11.3951 18.5189 11.5673 18.3466 11.5673 18.1342V5.1659C11.5673 4.95346 11.3951 4.78125 11.1826 4.78125Z"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        d="M4.9621 9.61719C4.6678 9.61719 4.42358 9.87883 4.42358 10.1942V13.656C4.42358 13.9714 4.6678 14.233 4.9621 14.233H9.27021C9.56451 14.233 9.80872 13.9714 9.80872 13.656V10.1942C9.80872 9.87883 9.56451 9.61719 9.27021 9.61719H4.9621ZM4.9621 10.1942H9.27021V13.656H4.9621V10.1942ZM7.7893 11.6366L6.8469 12.7906L6.17375 12.0693L5.44064 13.0791H8.79901L7.7893 11.6366Z"
        fill={color}
      />
      <path
        d="M8.65493 16.1543H5.57771C5.25905 16.1543 5.00073 16.4126 5.00073 16.7313C5.00073 17.0499 5.25905 17.3083 5.57771 17.3083H8.65493C8.97359 17.3083 9.23191 17.0499 9.23191 16.7313C9.23191 16.4126 8.97359 16.1543 8.65493 16.1543Z"
        fill={color}
      />
      <path
        d="M11.5399 5.76953H5.38544C5.27922 5.76953 5.19312 5.85564 5.19312 5.96186V7.88512C5.19312 7.99134 5.27922 8.07745 5.38544 8.07745H11.5399C11.6461 8.07745 11.7322 7.99134 11.7322 7.88512V5.96186C11.7322 5.85564 11.6461 5.76953 11.5399 5.76953Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
