import { useState, useMemo, useEffect } from 'react'
import {
  Box,
  TextField,
  Button,
  Dialog,
  useTheme,
  Theme,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { StyleSelectMultiple, LoadingBtn } from 'src/components'
import NumberFormat from 'react-number-format'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '80vw',
        maxWidth: '560px',
        padding: '12px 20px 20px',
        boxSizing: 'border-box',
        lineHeight: '22px',
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
        height: '20px',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '0',
        cursor: 'pointer',
      },
      button: {
        width: '100%',
        height: '40px',
        fontSize: '14px',
      },
      formSearchList: {
        textAlign: 'left',
      },
      label: {
        boxSizing: 'border-box',
        fontSize: '12px',
        color: theme.palette.custom.inputLabel,
        margin: '24px 0 8px',
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
      errorDetail: {
        margin: '6px 0',
        fontSize: '12px',
        color: 'rgb(211, 47, 47)',
        flex: 2,
      },
    }
  })
/**
 * @param openModel 显示隐藏
 * @function onSubmit 确认回调
 * @function onClose 关闭区域点击回调
 */
interface RatingDialogProps {
  openModel: boolean
  app_key: string
  onClickClose: () => void
  onClickSubmit: (data: any) => void
  loading?: boolean
}
const mediationList = [
  {
    value: 'Admob',
    label: 'Admob',
    name: 'Admob',
  },
  {
    value: 'Applovin MAX',
    label: 'Applovin MAX',
    name: 'Applovin MAX',
  },
  {
    value: 'Appodeal',
    label: 'Appodeal',
    name: 'Appodeal',
  },
  {
    value: 'IronSource',
    label: 'IronSource',
    name: 'IronSource',
  },
  {
    value: 'Unity Ads',
    label: 'Unity Ads',
    name: 'Unity Ads',
  },
  {
    value: 'Tapjoy',
    label: 'Tapjoy',
    name: 'Tapjoy',
  },
  {
    value: 'Adcolony',
    label: 'Adcolony',
    name: 'Adcolony',
  },
  {
    value: 'Admost',
    label: 'Admost',
    name: 'Admost',
  },
]
const adNetworkList = [
  {
    value: 'AdColony',
    label: 'AdColony',
    name: 'AdColony',
  },
  {
    value: 'AdFalcon',
    label: 'AdFalcon',
    name: 'AdFalcon',
  },
  {
    value: 'Admob',
    label: 'Admob',
    name: 'Admob',
  },
  {
    value: 'AppLovin',
    label: 'AppLovin',
    name: 'AppLovin',
  },
  {
    value: 'Chartboost',
    label: 'Chartboost',
    name: 'Chartboost',
  },
  {
    value: 'Facebook Audience Network',
    label: 'Facebook Audience Network',
    name: 'Facebook Audience Network',
  },
  {
    value: 'Flurry',
    label: 'Flurry',
    name: 'Flurry',
  },
  {
    value: 'Fyber',
    label: 'Fyber',
    name: 'Fyber',
  },
  {
    value: 'Google Ad Manger',
    label: 'Google Ad Manger',
    name: 'Google Ad Manger',
  },
  {
    value: 'i-mobile',
    label: 'i-mobile',
    name: 'i-mobile',
  },
  {
    value: 'inMobi',
    label: 'inMobi',
    name: 'inMobi',
  },
  {
    value: 'ironSource',
    label: 'ironSource',
    name: 'ironSource',
  },
  {
    value: 'Leadbolt',
    label: 'Leadbolt',
    name: 'Leadbolt',
  },
  {
    value: 'LG U+AD',
    label: 'LG U+AD',
    name: 'LG U+AD',
  },
  {
    value: 'Maio',
    label: 'Maio',
    name: 'Maio',
  },
  {
    value: 'MobFox',
    label: 'MobFox',
    name: 'MobFox',
  },
  {
    value: 'MoPub',
    label: 'MoPub',
    name: 'MoPub',
  },
  {
    value: 'myTarget',
    label: 'myTarget',
    name: 'myTarget',
  },
  {
    value: 'Nend',
    label: 'Nend',
    name: 'Nend',
  },
  {
    value: 'One by AOL (Millenial Media)',
    label: 'One by AOL (Millenial Media)',
    name: 'One by AOL (Millenial Media)',
  },
  {
    value: 'One by AOL (Nexage)',
    label: 'One by AOL (Nexage)',
    name: 'One by AOL (Nexage)',
  },
  {
    value: 'Pangle',
    label: 'Pangle',
    name: 'Pangle',
  },
  {
    value: 'Tapjoy',
    label: 'Tapjoy',
    name: 'Tapjoy',
  },
  {
    value: 'Tencent GDT',
    label: 'Tencent GDT',
    name: 'Tencent GDT',
  },
  {
    value: 'Unity Ads',
    label: 'Unity Ads',
    name: 'Unity Ads',
  },
  {
    value: 'Vpon',
    label: 'Vpon',
    name: 'Vpon',
  },
  {
    value: 'Vungle',
    label: 'Vungle',
    name: 'Vungle',
  },
  {
    value: 'Yandex',
    label: 'Yandex',
    name: 'Yandex',
  },
  {
    value: 'Zucks',
    label: 'Zucks',
    name: 'Zucks',
  },
]
interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}
function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props
  const decimalScale =
    props.name === 'dau' || props.name === 'impressions' ? 0 : 4
  const prefix =
    props.name === 'arp_dau' ||
    props.name === 'ecpm' ||
    props.name === 'revenue'
      ? '$'
      : ''
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalScale={decimalScale}
      thousandSeparator={props.name !== 'impressions' && props.name !== 'dau'}
      isNumericString
      prefix={prefix}
    />
  )
}

const Layout = (props: RatingDialogProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [mediation, setMediation] = useState([])
  const [adNetwork, setAdNetwork] = useState([])
  const submitDisabled = useMemo(() => {
    let temp = false
    if (mediation.length) {
      const mediationTemp = mediation.find((item: any) => {
        return !item.revenue || !item.impressions || item.showError
      })
      if (mediationTemp) {
        temp = true
      }
    }
    if (adNetwork.length) {
      const adNetworkTemp = adNetwork.find((item: any) => {
        return !item.revenue || !item.impressions || item.showError
      })
      if (adNetworkTemp) {
        temp = true
      }
    }
    if (!mediation.length && !adNetwork.length) {
      temp = true
    }
    return temp
    // eslint-disable-next-line
  }, [mediation, adNetwork])
  const close = () => {
    props.onClickClose()
  }
  const mediationInputChange = (e: any, index: number) => {
    const { name, value } = e.target
    const temp = JSON.parse(JSON.stringify(mediation))
    temp[index][name] = value
    setMediation(temp)
  }
  const adNetworkInputChange = (e: any, index: number) => {
    const { name, value } = e.target
    const temp = JSON.parse(JSON.stringify(adNetwork))
    temp[index][name] = value
    setAdNetwork(temp)
  }
  const verifyImpression = (list: any, index: number, setFunc: any) => {
    if (
      list[index]?.revenue > 0 &&
      (list[index]?.impressions === 0 || list[index]?.impressions === '0')
    ) {
      list[index].showError = true
    } else {
      list[index].showError = false
    }
    setFunc(JSON.parse(JSON.stringify(list)))
  }
  const submit = () => {
    props.onClickSubmit({
      is_first_monetization: false,
      is_first_mediation: !!mediation.length,
      mediation: mediation.length ? mediation : null,
      network: adNetwork.length ? adNetwork : null,
    })
  }
  useEffect(() => {
    if (props.openModel) {
      setMediation([])
      setAdNetwork([])
    }
  }, [props.openModel])
  // returns
  return (
    <Dialog open={props.openModel} onClose={close} sx={{ zIndex: 1999 }}>
      <Box className={classes.root}>
        <Box className={classes.title}>
          <CloseIcon className={classes.closeFilterIcon} onClick={close} />
        </Box>
        <Box className={classes.formSearchList}>
          <Box className={classes.label}>Previous Mediation:</Box>
          <Box width="100%">
            <StyleSelectMultiple
              label=""
              placeholder="Select Mediation(s)"
              selectStyle={true}
              showSearch={false}
              showSelectAll={false}
              value={mediation}
              onChange={(element: any) => {
                setMediation(element)
              }}
              options={mediationList}
            />
          </Box>
        </Box>
        {mediation.map((item: any, index: any) => (
          <>
            <Box display="flex" key={item.value} marginTop="16px">
              <Box
                className={classes.formSearchList}
                lineHeight="20px"
                display="flex"
                alignItems="center"
                flex={1}
              >
                {item.value} *
              </Box>
              <Box className={classes.formSearchList} flex={1} margin="0 16px">
                <Box
                  className={classes.label}
                  sx={{ margin: '4px 0 8px !important' }}
                >
                  Last 30 Day Revenue
                </Box>
                <Box width="100%">
                  <TextField
                    className={classes.formInputSearch}
                    name="revenue"
                    placeholder="$"
                    variant="outlined"
                    value={item.revenue}
                    onChange={(e: any) => {
                      mediationInputChange(e, index)
                    }}
                    onBlur={() => {
                      verifyImpression(mediation, index, setMediation)
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </Box>
              </Box>
              <Box className={classes.formSearchList} flex={1}>
                <Box
                  className={classes.label}
                  sx={{ margin: '4px 0 8px !important' }}
                >
                  Last 30 Day Impressions
                </Box>
                <Box width="100%">
                  <TextField
                    className={classes.formInputSearch}
                    name="impressions"
                    placeholder="Impressions"
                    variant="outlined"
                    value={item.impressions}
                    onChange={(e: any) => {
                      mediationInputChange(e, index)
                    }}
                    onBlur={() => {
                      verifyImpression(mediation, index, setMediation)
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {item.showError && (
              <Box display="flex">
                <Box flex={1}></Box>

                <Box className={classes.errorDetail}>
                  Revenue is greater than 0. Hence, Impressions cannot be 0.
                </Box>
              </Box>
            )}
          </>
        ))}
        <Box className={classes.formSearchList}>
          <Box className={classes.label}>Previous Ad Networks:</Box>
          <Box width="100%">
            <StyleSelectMultiple
              label=""
              placeholder="Select Ad Network(s)"
              selectStyle={true}
              showSearch={false}
              showSelectAll={false}
              value={adNetwork}
              onChange={(element: any) => {
                setAdNetwork(element)
              }}
              options={adNetworkList}
            />
          </Box>
        </Box>
        {adNetwork.map((item: any, index: any) => (
          <>
            <Box display="flex" key={item.value} marginTop="16px">
              <Box
                className={classes.formSearchList}
                lineHeight="20px"
                display="flex"
                alignItems="center"
                flex={1}
              >
                {item.value} *
              </Box>
              <Box className={classes.formSearchList} flex={1} margin="0 16px">
                <Box
                  className={classes.label}
                  sx={{ margin: '4px 0 8px !important' }}
                >
                  Last 30 Day Revenue
                </Box>
                <Box width="100%">
                  <TextField
                    className={classes.formInputSearch}
                    name="revenue"
                    placeholder="$"
                    variant="outlined"
                    value={item.revenue}
                    onChange={(e: any) => {
                      adNetworkInputChange(e, index)
                    }}
                    onBlur={() => {
                      verifyImpression(adNetwork, index, setAdNetwork)
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </Box>
              </Box>
              <Box className={classes.formSearchList} flex={1}>
                <Box
                  className={classes.label}
                  sx={{ margin: '4px 0 8px !important' }}
                >
                  Last 30 Day Impressions
                </Box>
                <Box width="100%">
                  <TextField
                    className={classes.formInputSearch}
                    name="impressions"
                    placeholder="Impressions"
                    variant="outlined"
                    value={item.impressions}
                    onChange={(e: any) => {
                      adNetworkInputChange(e, index)
                    }}
                    onBlur={() => {
                      verifyImpression(adNetwork, index, setAdNetwork)
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {item.showError && (
              <Box display="flex">
                <Box flex={1}></Box>

                <Box className={classes.errorDetail}>
                  Revenue is greater than 0. Hence, Impressions cannot be 0.
                </Box>
              </Box>
            )}
          </>
        ))}
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          style={{ marginTop: '16px' }}
          onClick={submit}
          disabled={submitDisabled}
        >
          {props.loading ? <LoadingBtn /> : 'Submit'}
        </Button>
      </Box>
    </Dialog>
  )
}

export default Layout
