import {
  TextField,
  Box,
  Dialog,
  Button,
  Link,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'
import { Close as CloseIcon } from '@material-ui/icons'

import { TestDeviceContext } from 'src/context/testDevice'
import { StyleSelect, LoadingBtn } from 'src/components'
import { validateDeviceId } from 'src/utils'
import { PNG } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      dialog: {
        boxSizing: 'border-box',
        padding: '40px',
        width: '90vw',
        maxWidth: '430px',
        background: theme.palette.custom.dialogBg,
      },
      title: {
        position: 'relative',
        boxSizing: 'border-box',
        fontSize: '20px',
        fontWeight: 600,
        height: '40px',
        lineHeight: '40px',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '3px',
        cursor: 'pointer',
      },
      formLabel: {
        fontSize: '12px',
        color: theme.palette.custom.inputLabel,
        marginBottom: '8px',
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
      footerButton: {
        width: '100% !important',
        height: '40px !important',
        marginTop: '24px !important',
      },
      deviceDecoration: {
        marginTop: '24px',
        fontSize: 14,
        color: '#7487AA',
        lineHeight: '24px',
      },
      tip: {
        width: '14px',
        verticalAlign: 'text-top',
        marginRight: '6px',
      },
    }
  })

const AddDialogView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [t] = useTranslation()
  const { state, dispatch, editDevice } = useContext(TestDeviceContext)
  const obj: any = {
    platform: '',
    device_id: '',
    name: '',
  }

  const [dialogData, setDialogData] = useState(obj)

  const handleClose = () => {
    dispatch({
      type: 'addDialogOpen',
      payload: false,
    })
  }

  const inputChange = (e: any) => {
    const { name, value } = e.target
    setDialogData({ ...dialogData, [name]: value })
  }
  const [nameError, setNameError] = useState('')
  const [idErrorDetail, setIdErrorDetail] = useState('')
  const saveDevice = () => {
    if (!dialogData.name) {
      setNameError(t('validator.device_name_input'))
      return
    } else {
      setNameError('')
    }
    if (!dialogData.device_id) {
      setIdErrorDetail(t('validator.device_id_input'))
      return
    } else if (!validateDeviceId(dialogData)) {
      setIdErrorDetail(t('validator.device_id_format'))
      return
    } else {
      setIdErrorDetail('')
    }
    editDevice(dialogData)
  }
  const downloadApkLink = () => {
    const url = 'https://docs.yodo1.com/download/tools/Yodo1DeviceId.apk'
    window.location.href = url
  }
  const downloadIpaLink = () => {
    const url = 'https://docs.yodo1.com/download/tools/Yodo1DeviceId.ipa'
    window.location.href = url
  }
  const platformOptions = [
    {
      label: 'iOS',
      value: 'iOS',
    },
    {
      label: 'Android',
      value: 'android',
    },
  ]
  const testDeviceForm = () => (
    <Box>
      <Box className={classes.formLabel}>Platform:</Box>
      <StyleSelect
        options={platformOptions}
        value={dialogData.platform}
        onChange={inputChange}
        name="platform"
        style={{
          width: '100%',
          border: `1px solid ${
            theme.palette.mode === 'dark' ? '#51565F' : '#eee'
          }`,
          margin: 0,
        }}
      />
      <Box className={classes.formLabel} marginTop="24px">
        Name:
      </Box>
      <TextField
        className={classes.formInputSearch}
        name="name"
        placeholder="Test device name"
        variant="outlined"
        error={!!nameError}
        helperText={nameError}
        value={dialogData.name}
        onChange={inputChange}
      />
      <Box className={classes.formLabel} marginTop="24px">
        Device ID:
      </Box>
      <TextField
        className={classes.formInputSearch}
        name="device_id"
        placeholder="IDFA / GAID"
        variant="outlined"
        error={!!idErrorDetail}
        helperText={idErrorDetail}
        value={dialogData.device_id}
        onChange={inputChange}
      />
    </Box>
  )
  useEffect(() => {
    if (state.addDialogOpen) {
      setNameError('')
      setIdErrorDetail('')
      setDialogData({ platform: 'iOS', name: '', device_id: '' })
    }
  }, [state.addDialogOpen])
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={state.addDialogOpen}
    >
      <Box className={classes.dialog}>
        <Box className={classes.title}>
          Add Test Device
          <CloseIcon
            className={classes.closeFilterIcon}
            onClick={handleClose}
          />
        </Box>
        <Box className={classes.deviceDecoration} marginTop="0">
          <img src={PNG.tips} alt="tip" className={classes.tip} />
          {t('testDevices.addDeviceTip')}
          <Box>{t('testDevices.addDeviceTip2')}</Box>
        </Box>
        <Box marginTop="16px">
          <Box>
            {testDeviceForm()}
            <Box className={classes.deviceDecoration}>
              To generate a test Device ID for your game, please use&nbsp;
              <Link
                color="primary"
                underline="always"
                onClick={() => downloadApkLink()}
              >
                this apk
              </Link>
              &nbsp;for Android devices and&nbsp;
              <Link
                color="primary"
                underline="always"
                onClick={() => downloadIpaLink()}
              >
                this ipa
              </Link>
              &nbsp;for iOS devices.
            </Box>
            <Button
              className={classes.footerButton}
              color="primary"
              variant="contained"
              onClick={saveDevice}
            >
              {state.editDeviceLoading ? (
                <Box>
                  <LoadingBtn
                    loadingStyle={{
                      justifyContent: 'center',
                    }}
                  />
                </Box>
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddDialogView
