const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_748_98)">
        <path
          d="M12.42 3.36841H3.05089C2.71319 3.36841 2.44043 3.64117 2.44043 3.97887C2.44043 4.31657 2.71319 4.58933 3.05089 4.58933H12.42C12.7577 4.58933 13.0304 4.31657 13.0304 3.97887C13.0304 3.64117 12.7577 3.36841 12.42 3.36841Z"
          fill={color}
        />
        <path
          d="M10.4241 6.8667H3.05089C2.71319 6.8667 2.44043 7.13946 2.44043 7.47716C2.44043 7.81486 2.71319 8.08762 3.05089 8.08762H10.4241C10.7618 8.08762 11.0345 7.81486 11.0345 7.47716C11.0345 7.13946 10.7618 6.8667 10.4241 6.8667Z"
          fill={color}
        />
        <path
          d="M7.21589 10.3647H3.05089C2.71319 10.3647 2.44043 10.6375 2.44043 10.9752C2.44043 11.3129 2.71319 11.5857 3.05089 11.5857H7.21589C7.55359 11.5857 7.82635 11.3129 7.82635 10.9752C7.82635 10.6375 7.55359 10.3647 7.21589 10.3647Z"
          fill={color}
        />
        <path
          d="M21.4167 18.8247L18.4726 15.8807C18.7757 15.2182 18.9446 14.4866 18.9446 13.7116C18.9446 11.4429 17.4898 9.51195 15.4636 8.79325V1.82706C15.4636 0.818278 14.641 0 13.6322 0H1.83431C0.825538 0 0.00292969 0.818278 0.00292969 1.82706V14.0363C0.00292969 14.5255 0.193428 14.9845 0.53979 15.3308L3.4752 18.2706C3.82156 18.6169 4.28049 18.8074 4.76973 18.8074H12.5455C12.9222 18.894 13.3162 18.946 13.7188 18.946C14.4938 18.946 15.2212 18.7728 15.8836 18.4697L18.832 21.4181C19.555 22.1411 20.5898 22.245 21.4167 21.4181C22.209 20.6215 22.17 19.5781 21.4167 18.8247ZM3.65704 16.7249H3.65271L2.08543 15.1533H3.65704V16.7249ZM4.87363 17.5822V14.5428C4.87363 14.2051 4.60087 13.9324 4.26317 13.9324H1.22385V1.82706C1.22385 1.48935 1.49661 1.21659 1.83431 1.21659H13.6409C13.9786 1.21659 14.2514 1.48935 14.2514 1.82706V8.51183C14.0782 8.49451 13.9007 8.48585 13.7232 8.48585C10.8397 8.48585 8.49311 10.8324 8.49311 13.7116C8.49311 15.2486 9.16418 16.6253 10.2206 17.5822H4.87363ZM13.7188 17.7207C11.5065 17.7207 9.7097 15.924 9.7097 13.7116C9.7097 11.4992 11.5065 9.70244 13.7188 9.70244C15.9312 9.70244 17.728 11.4992 17.728 13.7116C17.728 15.924 15.9312 17.7207 13.7188 17.7207ZM20.5551 20.5479C20.3257 20.7773 19.923 20.7773 19.6936 20.5479L16.9573 17.8116C17.2777 17.5605 17.5678 17.2704 17.8189 16.9501L20.5551 19.6863C20.8062 19.9374 20.7976 20.3098 20.5551 20.5479Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_748_98">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SVG
