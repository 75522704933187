const SVG = (props: any) => {
  const width = props.width || '23'
  const height = props.height || '23'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5515 17.6677L14.25 8.82906V3.9375H14.9375C15.1199 3.9375 15.2947 3.86507 15.4237 3.73614C15.5526 3.6072 15.625 3.43234 15.625 3.25C15.625 3.06766 15.5526 2.8928 15.4237 2.76386C15.2947 2.63493 15.1199 2.5625 14.9375 2.5625H8.06252C7.88018 2.5625 7.70531 2.63493 7.57638 2.76386C7.44745 2.8928 7.37502 3.06766 7.37502 3.25C7.37502 3.43234 7.44745 3.6072 7.57638 3.73614C7.70531 3.86507 7.88018 3.9375 8.06252 3.9375H8.75002V8.82906L3.44853 17.6677C3.3235 17.8762 3.25596 18.1141 3.2528 18.3571C3.24964 18.6002 3.31098 18.8397 3.43055 19.0514C3.55013 19.263 3.72367 19.4392 3.9335 19.5619C4.14333 19.6846 4.38194 19.7495 4.62502 19.75H18.375C18.6183 19.75 18.8573 19.6854 19.0674 19.5629C19.2776 19.4403 19.4515 19.2642 19.5714 19.0525C19.6913 18.8408 19.7529 18.6011 19.7498 18.3578C19.7468 18.1145 19.6792 17.8764 19.5541 17.6677H19.5515ZM10.0271 9.37305C10.0914 9.26647 10.1252 9.14432 10.125 9.01984V3.9375H12.875V9.01984C12.8748 9.14432 12.9087 9.26647 12.973 9.37305L16.2575 14.8516C15.2263 15.0552 13.7593 14.9693 11.8103 13.9827C10.443 13.2909 9.14189 12.924 7.92416 12.8819L10.0271 9.37305ZM4.62502 18.375L7.07768 14.2861C8.30229 14.1366 9.68244 14.4451 11.1872 15.2073C12.82 16.0332 14.195 16.3142 15.3122 16.3142C15.8804 16.3167 16.4461 16.2386 16.9923 16.0822L18.375 18.375H4.62502Z"
        fill={color}
      />
      <path
        d="M9.82931 16.1792C9.89509 16.5827 9.61599 16.9778 9.18655 17.0478C8.75711 17.1179 8.36697 16.8318 8.30119 16.4283C8.2354 16.0247 8.5145 15.6296 8.94395 15.5596C9.37339 15.4896 9.76352 15.7756 9.82931 16.1792Z"
        stroke={color}
      />
      <path
        d="M13.1733 18.172C13.1733 18.479 12.9183 18.7415 12.5867 18.7415C12.2551 18.7415 12 18.479 12 18.172C12 17.8651 12.2551 17.6026 12.5867 17.6026C12.9183 17.6026 13.1733 17.8651 13.1733 18.172Z"
        stroke={color}
      />
    </svg>
  )
}

export default SVG
