import { createContext, useReducer, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ApiResponse } from 'src/api'
import {
  getPerformanceScoringDetailApi,
  getAppDetailApi,
  getPerformanceScoringVoteApi,
  submitScoringFeedbackApi,
  submitScoringFeedbackDetailApi,
} from 'src/api/game'
import { GlobalContext } from './global'
import { useTranslation } from 'react-i18next'

type Props = {
  loading: boolean
  scoringLoading: boolean
  appDetail: any
  appScoringDetail: any
  openFeedbackDialog: boolean
  feedbackLoading: boolean
  voteLoading: boolean
  vote_id: string
  voteDetail: any
  voteDetailListLoading: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  loading: false,
  scoringLoading: false,
  appDetail: {},
  appScoringDetail: {},
  openFeedbackDialog: false,
  feedbackLoading: false,
  voteLoading: false,
  vote_id: '',
  voteDetail: {},
  voteDetailListLoading: false,
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'loading':
    case 'scoringLoading':
    case 'appDetail':
    case 'appScoringDetail':
    case 'openFeedbackDialog':
    case 'feedbackLoading':
    case 'voteLoading':
    case 'vote_id':
    case 'voteDetail':
    case 'voteDetailListLoading':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  submitFeedback: (
    score_id: string,
    data: {
      recommendation_id: string
      vote: string
    }
  ) => void
  submitFeedbackDetail: (
    score_id: string,
    data: {
      recommendation_id: string
      vote: string
      content: {
        content: string
        reason?: string[]
      }
    }
  ) => void
}
export const AppPerformanceContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  submitFeedback: (
    score_id: string,
    data: {
      recommendation_id: string
      vote: string
    }
  ) => {},
  submitFeedbackDetail: (
    score_id: string,
    data: {
      recommendation_id: string
      vote: string
      content: {
        content: string
        reason?: string[]
      }
    }
  ) => {},
})

export const AppPerformanceContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, customAlert } = useContext(GlobalContext)
  const params: any = useParams()
  const [t] = useTranslation()

  const getGameDetail = (app_key: string) => {
    dispatch({ type: 'loading', payload: true })
    getAppDetailApi(app_key, { extra_flag: true })
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'appDetail', payload: data.data })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
  }
  const getPerformanceScoringDetail = (app_key: string) => {
    dispatch({ type: 'scoringLoading', payload: true })
    getPerformanceScoringDetailApi(app_key)
      .then(({ data }: ApiResponse) => {
        if (data?.status === 'NoData') {
          const temp = {
            app_key: app_key,
            general: { score: null, recommendations: [] },
            monetization: { score: null, recommendations: [] },
            performance_score: null,
            popularity: { score: null, recommendations: [] },
            quality: { score: null, recommendations: [] },
            stickiness: { score: null, recommendations: [] },
          }
          dispatch({ type: 'appScoringDetail', payload: temp })
        } else {
          dispatch({ type: 'appScoringDetail', payload: data })
        }
      })
      .catch((err: any) => {
        errorCatch(err)
        const temp = {
          app_key: app_key,
          general: { score: null, recommendations: [] },
          monetization: { score: null, recommendations: [] },
          performance_score: null,
          popularity: { score: null, recommendations: [] },
          quality: { score: null, recommendations: [] },
          stickiness: { score: null, recommendations: [] },
        }
        dispatch({ type: 'appScoringDetail', payload: temp })
      })
      .finally(() => {
        dispatch({ type: 'scoringLoading', payload: false })
      })
  }
  const getPerformanceScoringVote = (app_key: string) => {
    dispatch({ type: 'voteDetailListLoading', payload: true })
    getPerformanceScoringVoteApi(app_key)
      .then(({ data }: ApiResponse) => {
        const voteDetail = {}
        if (data.length) {
          data.forEach(
            (item: {
              recommendation_id: string
              vote: string
              content: null | {
                content: string
                reason?: string[]
              }
            }) => {
              voteDetail[item.recommendation_id] = {
                vote: item.vote,
                content: item.content,
              }
            }
          )
        }
        dispatch({ type: 'voteDetail', payload: voteDetail })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'voteDetailListLoading', payload: false })
      })
  }
  const submitFeedback = (
    score_id: string,
    paramsData: {
      recommendation_id: string
      vote: string
    }
  ) => {
    dispatch({ type: 'voteLoading', payload: true })
    submitScoringFeedbackApi(params.app_key, score_id, paramsData)
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'vote_id', payload: data.id })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'voteLoading', payload: false })
      })
  }
  const submitFeedbackDetail = (
    score_id: string,
    paramsData: {
      recommendation_id: string
      vote: string
      content: {
        content: string
        reason?: string[]
      }
    }
  ) => {
    dispatch({ type: 'feedbackLoading', payload: true })
    submitScoringFeedbackDetailApi(
      params.app_key,
      score_id,
      state.vote_id,
      paramsData
    )
      .then(({ data }: ApiResponse) => {
        customAlert('success', t('common.success'))
        dispatch({ type: 'openFeedbackDialog', payload: false })
        getPerformanceScoringVote(params.app_key)
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'feedbackLoading', payload: false })
      })
  }
  useEffect(() => {
    if (!params.app_key) return
    getGameDetail(params.app_key)
    getPerformanceScoringVote(params.app_key)
    getPerformanceScoringDetail(params.app_key)
    // eslint-disable-next-line
  }, [params.app_key])

  // returns
  return (
    <AppPerformanceContext.Provider
      value={{
        state,
        dispatch,
        submitFeedback,
        submitFeedbackDetail,
      }}
    >
      {children}
    </AppPerformanceContext.Provider>
  )
}
