import clsx from 'clsx'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      paper: {
        background: theme.palette.background.paper,
        boxShadow:
          theme.palette.mode === 'light'
            ? '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)'
            : '0 1px 2px 0 rgba(255, 255, 255, 0.04), 0 1px 6px -1px rgba(255, 255, 255, 0.03), 0 2px 4px 0 rgba(255, 255, 255, 0.03)',
        borderRadius: '8px',
        position: 'relative',
      },
    }
  })

const StylePaper = (props: any): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  return (
    <Box
      className={clsx([classes.paper, props.className])}
      style={{ ...props }}
      id={props.id || ''}
      borderBottom={props.isTab ? '0 none' : ''}
    >
      {props.isTab && props.checked && (
        <Box
          sx={{
            height: '2px',
            width: '100%',
            position: 'absolute',
            bottom: '-1px',
            left: 0,
            background: theme.palette.background.paper,
            zIndex: 1,
          }}
        />
      )}
      {props.children}
    </Box>
  )
}

export default StylePaper
