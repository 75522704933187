import { useContext } from 'react'
import { Box, Tooltip, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GlobalContext } from 'src/context/global'
import RadioBoxView from './radioBoxView'
import NativeadsAdTypesView from './nativeadsAdTypesView'
import BannerAdTypesView from './bannerAdTypesView'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      platform: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '48px',
      },
    }
  })
const AdsStepView = (props: any) => {
  const {
    adsFormat,
    clickAdsFormat,
    nativeadsSize,
    sizeChange,
    engine,
    isChooseBannerAdType,
    updateLink,
    bannerNonStandard,
    bannerNonStandardChange,
  } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state: globalState } = useContext(GlobalContext)
  return (
    <>
      <Box className={classes.platform} sx={{ flexWrap: 'wrap' }}>
        <RadioBoxView
          icon={SVGIcon.bannerSVG}
          label="Banner"
          checked={adsFormat.includes('banner')}
          multiple={true}
          click={() => {
            clickAdsFormat('banner')
          }}
        />
        <RadioBoxView
          icon={SVGIcon.interstitialSVG}
          label="Interstitial"
          checked={adsFormat.includes('interstitial')}
          multiple={true}
          click={() => {
            clickAdsFormat('interstitial')
          }}
        />
        <RadioBoxView
          icon={SVGIcon.reward_videoSVG}
          label="Rewarded Video"
          checked={adsFormat.includes('reward_video')}
          multiple={true}
          click={() => {
            clickAdsFormat('reward_video')
          }}
        />
        <Tooltip
          title="Require SDK 4.6.0 or above."
          arrow
          placement="top"
          disableInteractive
        >
          <Box>
            <RadioBoxView
              icon={SVGIcon.native_adsSVG}
              label="Native Ads"
              checked={adsFormat.includes('nativeads')}
              multiple={true}
              click={() => {
                clickAdsFormat('nativeads')
              }}
            />
          </Box>
        </Tooltip>
        <Tooltip
          title="Require SDK 4.8.0 or above."
          arrow
          placement="top"
          disableInteractive
        >
          <Box>
            <RadioBoxView
              icon={SVGIcon.OpenAdsSVG}
              label="App Open"
              checked={adsFormat.includes('openads')}
              multiple={true}
              click={() => {
                clickAdsFormat('openads')
              }}
            />
          </Box>
        </Tooltip>
        {globalState.userInfo.is_whitelist && (
          <>
            <Tooltip
              title="Require SDK 4.8.0 or above."
              arrow
              placement="top"
              disableInteractive
            >
              <Box>
                <RadioBoxView
                  icon={SVGIcon.RewardinterSVG}
                  label="Rewarded Interstitial"
                  checked={adsFormat.includes('rewardinter')}
                  multiple={true}
                  click={() => {
                    clickAdsFormat('rewardinter')
                  }}
                />
              </Box>
            </Tooltip>
          </>
        )}
      </Box>
      {adsFormat.includes('nativeads') && (
        <NativeadsAdTypesView
          value={nativeadsSize}
          onChange={sizeChange}
          engine={engine}
        />
      )}
      {isChooseBannerAdType && updateLink && (
        <BannerAdTypesView
          value={bannerNonStandard}
          onChange={bannerNonStandardChange}
        />
      )}
    </>
  )
}
export default AdsStepView
