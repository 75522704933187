import { KeyboardEvent, useContext, useEffect, useRef, useState } from 'react'
import {
  AppBar,
  IconButton,
  Avatar,
  Toolbar,
  Box,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Paper,
  Grow,
  Popper,
  ListItemIcon,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import {
  ExitToApp as LogoutIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  AccountCircle as AccountCircleIcon,
} from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'

import { GlobalContext } from 'src/context/global'
import { GlobalStoreContext } from 'src/context/globalStore'
import TopBannerBox from './topBannerBox'
import ApplicationNotificationBox from './applicationNotification'
import AdBlockAlertView from './adblockAlert'
import AddButtonView from '../AddGameButton/button'
import NotificationDialogView from './notificationDialog'
import { SVGIcon } from 'src/images'
const getUseStyles = (theme: Theme) => {
  return makeStyles(() => {
    return {
      toolbar: {
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${
          theme.palette.mode === 'light' ? '#E5E8EB' : '#484644'
        }`,
      },
      verticalDivider: {
        width: '1px',
        height: '16px',
        backgroundColor: theme.palette.divider,
        marginRight: '24px',
      },
      listItemIcon: {
        minWidth: '44px !important',
      },
      text: {
        fontSize: '12px',
        width: '68px',
        transform: 'scale(0.7)',
        transformOrigin: 'center left',
      },
      badge: {
        '& .MuiBadge-badge': {
          right: '15px',
          top: '2px',
        },
      },
      bottomArrowIcon: {
        marginTop: '23px',
        marginLeft: '3px',
        color: theme.palette.text.primary,
      },
      topLogo: {
        position: 'relative',
        boxSizing: 'border-box',
        marginTop: '5px',
        marginRight: '16px',
      },
    }
  })
}
const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, logout, setAccountEditKeyHandle } = useContext(GlobalContext)
  const { state: globalStoreState, onMobileMenuToggle } =
    useContext(GlobalStoreContext)
  const navigate = useNavigate()
  const isDesktop = useMediaQuery('(min-width:1280px)')
  const isTopBarMinWidth = useMediaQuery('(max-width:550px)')
  const min650 = useMediaQuery('(min-width:650px)')

  // deconstruct
  const { userInfo, userInfoLoading } = state
  const { mobileMenuOpen, topBannerNotification } = globalStoreState
  // states
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false)
  const profileAnchorRef = useRef<HTMLButtonElement>(null)
  const prevProfileOpen = useRef<boolean>(profileMenuOpen)

  // handlers
  const onProfileMenuToggle = () => {
    setProfileMenuOpen(prevOpen => !prevOpen)
  }

  const onProfileMenuClose = (event: { target: any }) => {
    if (profileAnchorRef.current?.contains(event.target)) {
      return
    }
    setProfileMenuOpen(false)
  }

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setProfileMenuOpen(false)
    }
  }

  // effect
  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevProfileOpen.current && !profileMenuOpen) {
      profileAnchorRef.current?.focus()
    }

    prevProfileOpen.current = profileMenuOpen
  }, [profileMenuOpen])

  // views
  const avatarView = (
    <Avatar
      alt="Avatar"
      src={userInfo?.avatar}
      style={{ width: '20px', height: '20px', padding: '0' }}
    >
      <AccountCircleIcon style={{ color: theme.palette.text.primary }} />
    </Avatar>
  )
  const nameFormat = (name: string) => {
    if (!name) return '-'
    const nameList = name.split(' ')
    if (nameList.length > 1) {
      return nameList[0] + ' ' + nameList[1].substring(0, 1) + '.'
    }
    return name
  }

  // returns
  return (
    <AppBar elevation={0} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <Box sx={{ display: isDesktop ? 'none' : 'block' }}>
            <Box display="flex">
              <IconButton
                onClick={onMobileMenuToggle}
                style={{ transform: 'translateX(-10px)' }}
              >
                {mobileMenuOpen ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
              <Box className={classes.topLogo}>
                <Link
                  onClick={() => {
                    navigate('/dashboard')
                  }}
                >
                  <SVGIcon.logoSVG
                    width="82px"
                    height="30px"
                    color={theme.palette.mode === 'light' ? '' : '#FFF'}
                  />
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
        {!!topBannerNotification.length && <TopBannerBox />}
        <Box display="flex" alignItems="center" height="68px">
          <Box
            style={{
              display: 'flex',
              height: '40px',
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '10px',
              background:
                'linear-gradient(82.32deg, #005897 -3.09%, #00243D 128.6%)',
              color: '#FFF',
              marginRight: '20px',
              alignItems: 'center',
              padding: '0 12px',
              fontSize: '14px',
              fontWeight: 700,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open('https://hubs.ly/Q02t_Kmf0')
            }}
          >
            <SVGIcon.FanCraftSVG
              width="27px"
              height="30px"
              style={{
                marginRight: min650 ? '6px' : '0',
                verticalAlign: 'middle',
              }}
            />

            {min650 ? <>New Product Alert &raquo;</> : ''}
          </Box>
          <Box display="flex">
            <AddButtonView />
          </Box>

          {state.accountAuthority.notification_page > 0 && (
            <NotificationDialogView />
          )}
          {!isTopBarMinWidth && <Box className={classes.verticalDivider} />}
          <Box
            sx={{
              cursor: userInfoLoading ? 'default' : 'pointer',
              display: 'flex',
              height: '68px',
              lineHeight: '68px',
            }}
            aria-controls={profileMenuOpen ? 'menu-list-grow' : undefined}
            ref={profileAnchorRef}
            onClick={() => {
              !userInfoLoading && onProfileMenuToggle()
            }}
          >
            <Box paddingTop="23px">
              {userInfoLoading ? (
                <Skeleton variant="circular" width={20} height={20} />
              ) : (
                avatarView
              )}
            </Box>
            {!isTopBarMinWidth && (
              <>
                {userInfoLoading ? (
                  <Skeleton
                    width={80}
                    height={20}
                    style={{ margin: '23px 0 0 18px' }}
                  />
                ) : (
                  <>
                    <Box
                      sx={{
                        maxWidth: '110px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginLeft: '18px',
                        color: theme.palette.text.primary,
                        userSelect: 'none',
                      }}
                    >
                      {nameFormat(userInfo?.name)}
                    </Box>
                    <KeyboardArrowDownIcon
                      className={classes.bottomArrowIcon}
                    />
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
        <ApplicationNotificationBox />
        {globalStoreState.showAdblockAlert && <AdBlockAlertView />}
        <Popper
          open={profileMenuOpen}
          anchorEl={profileAnchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                marginRight: '5px',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={onProfileMenuClose}>
                  <MenuList
                    autoFocusItem={profileMenuOpen}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    style={{ minWidth: '140px' }}
                  >
                    <MenuItem disabled>
                      <Typography color="textSecondary" variant="body2">
                        {userInfo?.name || '-'}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate('/dash/settings')
                        setProfileMenuOpen(false)
                        setAccountEditKeyHandle('')
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <SVGIcon.PhotoSVG
                          width="18px"
                          height="18px"
                          color={theme.palette.mode === 'light' ? '' : '#FFF'}
                        />
                      </ListItemIcon>
                      <Typography variant="body2" color="textPrimary">
                        Edit Profile
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate('/dash/manage-notifications')
                        setProfileMenuOpen(false)
                        setAccountEditKeyHandle('')
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <SVGIcon.NotificationsSVG
                          width="18px"
                          height="18px"
                          color={theme.palette.mode === 'light' ? '' : '#FFF'}
                        />
                      </ListItemIcon>
                      <Typography variant="body2" color="textPrimary">
                        Manage Notifications
                      </Typography>
                    </MenuItem>
                    <Divider style={{ margin: '6px 0' }} />
                    <MenuItem
                      onClick={() => {
                        logout(true)
                        setProfileMenuOpen(false)
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <LogoutIcon
                          color="error"
                          fontSize="small"
                          style={{ fontSize: '19px' }}
                        />
                      </ListItemIcon>
                      <Typography variant="body2" color="textPrimary">
                        Logout
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
    </AppBar>
  )
}

export default View
