import { Controller } from 'react-hook-form'
import { useState, useMemo, MouseEvent } from 'react'
import { TextField, Box, InputAdornment, IconButton } from '@material-ui/core'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons'

const View = (props: any): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false)
  const type = useMemo(() => {
    if (props.type === 'password') {
      if (showPassword) {
        return 'text'
      }
      return 'password'
    }
    return props.type
  }, [props.type, showPassword])
  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  // views
  const inputView = ({ field }: any): JSX.Element => {
    return (
      <TextField
        fullWidth
        placeholder={props.placeholder}
        type={type}
        autoComplete={props.autoComplete}
        variant="outlined"
        {...field}
        error={props.error}
        helperText={props.helperText}
        InputProps={
          props.type === 'password'
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon sx={{ color: '#1D3353' }} />
                      ) : (
                        <VisibilityIcon sx={{ color: '#1D3353' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            background: '#EEF1F5',
            height: '36px',
          },
          '& .MuiInputBase-input': {
            color: '#102542 !important',
            padding: '18px 12px !important',
            marginLeft: '0 !important',
            boxShadow: '0 0 0 100px #EEF1F5 inset !important',
            textFillColor: '#102542 !important',
            caretColor: '#102542 !important',
          },
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #D2D6DD !important',
          },
        }}
      />
    )
  }

  return (
    <Box marginBottom="6px">
      {props.label && (
        <Box
          sx={{
            color: '#1D3353',
            fontSize: 12,
            fontWeight: 500,
            marginBottom: '6px',
          }}
        >
          {props.label}
        </Box>
      )}
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue}
        render={inputView}
      />
    </Box>
  )
}

export default View
