const SVG = (props: any) => {
  const width = props.width || '18'
  const height = props.height || '18'
  const color = props.color || '#1D3353'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM4.563 14.652C4.95 13.842 7.308 13.05 9 13.05C10.692 13.05 13.059 13.842 13.437 14.652C12.213 15.624 10.674 16.2 9 16.2C7.326 16.2 5.787 15.624 4.563 14.652ZM14.724 13.347C13.437 11.781 10.314 11.25 9 11.25C7.686 11.25 4.563 11.781 3.276 13.347C2.358 12.141 1.8 10.638 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 10.638 15.642 12.141 14.724 13.347ZM9 3.6C7.254 3.6 5.85 5.004 5.85 6.75C5.85 8.496 7.254 9.9 9 9.9C10.746 9.9 12.15 8.496 12.15 6.75C12.15 5.004 10.746 3.6 9 3.6ZM9 8.1C8.253 8.1 7.65 7.497 7.65 6.75C7.65 6.003 8.253 5.4 9 5.4C9.747 5.4 10.35 6.003 10.35 6.75C10.35 7.497 9.747 8.1 9 8.1Z"
        fill={color}
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default SVG
