import {
  TextField,
  Box,
  Button,
  Grid,
  Dialog,
  FormControl,
  RadioGroup,
  FormControlLabel,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import Skeleton from '@material-ui/core/Skeleton'
import { useContext, useEffect, useState } from 'react'
import { GameManageContext } from 'src/context/gameManage'
import { GlobalContext } from 'src/context/global'
import { PNG } from 'src/images'
import {
  ClickCopyText,
  LoadingBtn,
  LoadingComponent,
  StyleRadio,
} from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      title: {
        fontSize: '16px',
        fontWeight: 600,
      },
      formTitle: {
        fontSize: '12px',
        color: theme.palette.custom.inputLabel,
        margin: '16px 0 8px',
      },
      formInputSearch: {
        width: '100%',
      },
      admobInput: {
        width: 'calc(100% - 34px)',
      },
      saveButton: {
        width: '124px !important',
        height: '40px !important',
      },
      platformPhoto: {
        width: '30px',
        height: '30px',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
      clickBox: {
        position: 'absolute',
        right: '1px',
        top: '1px',
        height: '38px',
        lineHeight: '36px',
        padding: '0 10px 0 2px',
        verticalAlign: 'middle',
        background: theme.palette.background.paper,
      },
      storeStatusBox: {
        height: '40px',
        lineHeight: '40px',
        padding: '0 16px',
        background: '#1E96FC',
        borderRadius: '4px',
        textAlign: 'center',
        color: '#FFF',
      },
      iconBox: {
        boxSizing: 'border-box',
        width: '40px',
        height: '40px',
        lineHeight: '40px',
        borderRadius: '4px',
        textAlign: 'center',
        color: '#FFF',
        paddingTop: '5px',
      },
      root: {
        width: '80vw',
        maxWidth: '500px',
        padding: '12px 20px 20px',
        boxSizing: 'border-box',
        lineHeight: '22px',
      },
      dialogTitle: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
        height: '20px',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '0',
        cursor: 'pointer',
      },
      content: {
        fontSize: '16px',
        margin: '30px 0',
        lineHeight: '22px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      button: {
        width: '100%',
        height: '40px',
        fontSize: '14px',
      },
      bannerNonStandardLabel: {
        '& .MuiFormControlLabel-label': {
          fontSize: '14px !important',
        },
      },
    }
  })

const ManageView = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, editGame } = useContext(GameManageContext)
  const { state: globalState } = useContext(GlobalContext)
  const obj: any = {
    name: '',
    review: '',
    platform: '',
    appkey: '',
    bundleId: '',
  }
  const [dialogData, setDialogData] = useState(obj)
  const [storeUrl, setStoreUrl] = useState('')
  const [storeUrlTemp, setStoreUrlTemp] = useState('')
  const [screen, setScreen] = useState('')
  const [screenTemp, setScreenTemp] = useState('')
  const urlChange = (e: any) => {
    setStoreUrl(e.target.value)
  }
  const saveStoreLink = () => {
    editGame(
      {
        app_key: state.appDetail.app_key,
        test_ads_status: state.appDetail.test_ads_status,
        url: storeUrl || null,
        ads_format: state.appDetail.ads_format,
        screen: screen,
      },
      storeUrlTemp === storeUrl ? false : true
    )
  }
  const orientationChange = (e: any, value: string) => {
    setScreen(value)
  }
  const saveButtonView = (
    <>
      {globalState.accountAuthority.game_manage_page === 2 && (
        <Button
          className={classes.saveButton}
          variant="contained"
          onClick={saveStoreLink}
          disabled={
            storeUrlTemp === storeUrl &&
            (screen === screenTemp || (screen === 'all' && screenTemp === null))
          }
          sx={props.isMobile ? { width: '100% !important' } : {}}
        >
          {state.editGameLoading ? (
            <LoadingBtn
              loadingStyle={{
                justifyContent: 'center',
              }}
            />
          ) : (
            'Save'
          )}
        </Button>
      )}
    </>
  )
  useEffect(() => {
    setDialogData(state.appDetail || {})
    setStoreUrl(state.appDetail?.url || '')
    setStoreUrlTemp(state.appDetail?.url || '')
    setScreen(
      state.appDetail?.screen === null ? 'all' : state.appDetail?.screen || ''
    )
    setScreenTemp(state.appDetail?.screen)
    // eslint-disable-next-line
  }, [state.appDetail])

  return (
    <Box>
      <Box position="relative">
        {state.loading && (
          <Box className={classes.loadingMask}>
            <LoadingComponent />
          </Box>
        )}
        <Box>
          <Box className={classes.title}>General Information</Box>
          <Grid container columnSpacing={6}>
            <Grid item xs={12} md={state.moDetailsData.length ? 12 : 6} lg={6}>
              <Box className={classes.formTitle}>AdMob App ID:</Box>
              <Box position="relative">
                <TextField
                  className={classes.formInputSearch}
                  disabled
                  variant="outlined"
                  value={dialogData.admob_key}
                />
                {dialogData.admob_key && (
                  <Box className={classes.clickBox}>
                    <ClickCopyText
                      value={dialogData.admob_key}
                      showIcon
                      showText={false}
                      inputIcon={true}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={state.moDetailsData.length ? 12 : 6} lg={6}>
              <Box className={classes.formTitle}>Game Name:</Box>
              <Box>
                <TextField
                  className={classes.formInputSearch}
                  disabled
                  variant="outlined"
                  value={dialogData.name}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={state.moDetailsData.length ? 12 : 6} lg={6}>
              <Box className={classes.formTitle}>Store link:</Box>
              <Box>
                <TextField
                  className={classes.formInputSearch}
                  disabled={
                    globalState.accountAuthority.game_manage_page !== 2 ||
                    !!dialogData.url
                  }
                  variant="outlined"
                  onChange={urlChange}
                  value={storeUrl}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={state.moDetailsData.length ? 12 : 6} lg={6}>
              <Box className={classes.formTitle}>Orientation:</Box>
              <Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender1"
                    value={screen}
                    onChange={orientationChange}
                  >
                    <FormControlLabel
                      className={classes.bannerNonStandardLabel}
                      value="Vertical"
                      control={<StyleRadio />}
                      label="Portrait"
                      style={{ marginRight: 40 }}
                    />
                    <FormControlLabel
                      className={classes.bannerNonStandardLabel}
                      value="Horizontal"
                      control={<StyleRadio />}
                      label="Landscape"
                      style={{ marginRight: 40 }}
                    />
                    <FormControlLabel
                      className={classes.bannerNonStandardLabel}
                      value="all"
                      control={<StyleRadio />}
                      label="Both"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            position="relative"
            sx={props.isMobile ? { justifyContent: 'space-between' } : {}}
          >
            <Box>
              <Box className={classes.formTitle}>Store Status:</Box>
              {!state.loading ? (
                <Box className={classes.storeStatusBox}>
                  {dialogData.app_store_status === 1
                    ? 'Live'
                    : dialogData.app_store_status === 2
                    ? 'Pre Release'
                    : 'Not Live'}
                </Box>
              ) : (
                <Skeleton variant="rectangular" width={80} height={40} />
              )}
            </Box>
            <Box margin="0 24px">
              <Box className={classes.formTitle}>Engine:</Box>
              {!state.loading ? (
                <Box className={classes.iconBox}>
                  <img
                    src={PNG[dialogData.engine || 'other']}
                    className={classes.platformPhoto}
                    alt=""
                  />
                </Box>
              ) : (
                <Skeleton variant="rectangular" width={40} height={40} />
              )}
            </Box>
            <Box>
              <Box className={classes.formTitle}>Platform:</Box>
              {!state.loading ? (
                <Box className={classes.iconBox}>
                  <img
                    src={dialogData.platform === 'iOS' ? PNG.ios : PNG.android}
                    className={classes.platformPhoto}
                    alt=""
                  />
                </Box>
              ) : (
                <Skeleton variant="rectangular" width={40} height={40} />
              )}
            </Box>
            {!props.isMobile && (
              <Box position="absolute" right="0" top="38px">
                {saveButtonView}
              </Box>
            )}
          </Box>

          {props.isMobile && <Box marginTop="30px">{saveButtonView}</Box>}
        </Box>
      </Box>

      <Dialog
        open={state.openTipDialog}
        onClose={() => {
          dispatch({
            type: 'openTipDialog',
            payload: false,
          })
        }}
      >
        <Box className={classes.root}>
          <Box className={classes.dialogTitle}>
            <CloseIcon
              className={classes.closeFilterIcon}
              onClick={() => {
                dispatch({
                  type: 'openTipDialog',
                  payload: false,
                })
              }}
            />
          </Box>
          <Box className={classes.content}>
            App ownership will be verified via using the app-ads.txt file.
            Please wait two hours after updating the app-ads.txt file for the
            game to be verified
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch({
                  type: 'openTipDialog',
                  payload: false,
                })
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default ManageView
