import { useContext } from 'react'
import { InstallsContext } from 'src/context/gamePlayInsights/installs'
import { GlobalContext } from 'src/context/global'
import {
  BorderStyleSelectMultiple,
  BorderStyleSelectRow,
  BorderStyleDate,
  TopFilterBox,
} from 'src/components'
import dayjs from 'dayjs'

const platformOptions = [
  {
    label: 'iOS',
    value: 'iOS',
  },
  {
    label: 'Android',
    value: 'android',
  },
]
const viewOptions = [
  {
    label: 'Application',
    value: 'application',
  },
  {
    label: 'Platform',
    value: 'platform',
  },
  {
    label: 'Country',
    value: 'country',
  },
]

const TopFilterView = (props: any): JSX.Element => {
  const { state, dispatch, getInstallsData } = useContext(InstallsContext)
  const { state: globalState } = useContext(GlobalContext)

  const timeChange = (dateRange: any[], timeShow: string) => {
    dispatch({
      type: 'time',
      payload: dateRange,
    })
    dispatch({
      type: 'timeShow',
      payload: timeShow,
    })
  }
  const search = () => {
    getInstallsData()
  }
  const reset = () => {
    timeChange(
      [
        dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      ],
      'last_week'
    )
    dispatch({
      type: 'gameSelect',
      payload: [],
    })
    dispatch({
      type: 'country',
      payload: [],
    })
    dispatch({
      type: 'platform',
      payload: [],
    })
    dispatch({
      type: 'resetIndex',
      payload: state.resetIndex + 1,
    })
  }

  // returns
  return (
    <TopFilterBox
      ContentView={
        <>
          <BorderStyleDate
            label="Dates"
            timeShow={state.timeShow}
            value={state.time}
            onChange={timeChange}
            onTriggerFilter={search}
          />

          <BorderStyleSelectMultiple
            label="Apps"
            valueKey="app_key"
            labelKey="name"
            placeholder="Apps"
            value={state.gameSelect}
            onChange={(row: any) => {
              dispatch({
                type: 'gameSelect',
                payload: row,
              })
            }}
            options={globalState.gameOption}
            onTriggerFilter={search}
          />
          <BorderStyleSelectRow
            label="Group by"
            placeholder="Group by"
            value={{ value: state.view }} // 接收对象，所以讲state.view包装为对象
            showSearch={false}
            onChange={(row: any) => {
              dispatch({
                type: 'view',
                payload: row.value,
              })
            }}
            options={viewOptions}
            onTriggerFilter={search}
          />
        </>
      }
      PopoverView={
        <>
          <BorderStyleSelectMultiple
            width="100%"
            label="Countries"
            valueKey="code"
            labelKey="name"
            placeholder="Countries"
            value={state.country}
            onChange={(row: any) => {
              dispatch({
                type: 'country',
                payload: row,
              })
            }}
            options={globalState.countryOption}
            onTriggerFilter={search}
          />
          <BorderStyleSelectMultiple
            width="100%"
            label="Platform"
            placeholder="Platform"
            value={state.platform}
            showSearch={false}
            onChange={(row: any) => {
              dispatch({
                type: 'platform',
                payload: row,
              })
            }}
            options={platformOptions}
            onTriggerFilter={search}
          />
        </>
      }
      reset={reset}
      search={search}
    />
  )
}

export default TopFilterView
