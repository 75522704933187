import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Button, Box, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useContext } from 'react'
import { AuthContext } from 'src/context/auth'
import {
  LoadingComponent,
  AuthPromptDialog,
  AuthPageInput,
} from 'src/components'

interface FormValues {
  email: string
}

const getUseStyles = () =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
      },
      title: {
        textAlign: 'center',
        color: '#1D3353',
        fontWeight: 600,
        fontSize: '18px',
        marginBottom: '16px',
        lineHeight: '34px',
      },
    }
  })

const View = (): JSX.Element => {
  const useStyles = getUseStyles()
  const classes = useStyles()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const { state, resetPasswordEmail, setOpenModel } = useContext(AuthContext)
  const FormSchema = yup.object().shape({
    email: yup.string().email().required(t('auth.login.emailErrMsg')),
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
  })

  // handlers
  const onSubmit = (data: FormValues) => {
    resetPasswordEmail(data)
  }

  const dialogConfirm = () => {
    setOpenModel(false)
  }

  // returns
  if (state.loading) {
    return (
      <Box display="flex" justifyContent="center">
        <LoadingComponent />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name="mas_forgot_password"
        className="mas-forgot-password"
      >
        <Box className={classes.title}>Forgot Password</Box>
        <Box marginBottom="20px">
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ color: '#1D3353', fontSize: '14px' }}
          >
            {t('auth.password.reset_desc')}
          </Typography>
        </Box>
        <AuthPageInput
          label="Your email"
          name="email"
          control={control}
          defaultValue=""
          placeholder={t('auth.login.email')}
          type="email"
          variant="outlined"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <Box margin="28px 0 14px">
          <Button fullWidth variant="contained" color="primary" type="submit">
            {t('auth.password.reset')}
          </Button>
        </Box>
        <Box textAlign="center" fontWeight={600}>
          <Link
            onClick={() => {
              navigate('/login')
            }}
            underline="none"
          >
            {t('auth.login.sign_in')}
          </Link>
        </Box>
        <AuthPromptDialog
          openModel={state.openModel}
          isSuccessDialog
          title={t('auth.password.resetPasswdEmailSuccessMsgTitle')}
          content={t('auth.password.resetPasswdEmailSuccessMsgBody')}
          onConfirm={dialogConfirm}
          contentPadding="0 20px"
        />
        <label htmlFor="cc-num" style={{ display: 'none' }}>
          Workaround
        </label>
        <input
          name="cc-num"
          style={{ display: 'none' }}
          required
          value="HubspotCollectedFormsWorkaround"
          id="cc-num"
        />
      </form>
    </Box>
  )
}

export default View
