import {
  Box,
  Tooltip,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import { StyleRadio } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      title: {
        fontSize: '22px',
        color: theme.palette.text.primary,
        textAlign: 'center',
      },
      bannerNonStandardLabel: {
        '& .MuiFormControlLabel-label': {
          fontSize: '18px !important',
        },
      },
      nativeadsContent: {
        fontSize: '14px',
        color: '#707070',
        textAlign: 'center',
        marginTop: '6px',
      },
    }
  })
const NativeadsAdTypesView = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  return (
    <Box
      marginTop={'48px'}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Box className={classes.title}>Please check the native ads size:</Box>
      <Grid
        container
        spacing={2}
        marginTop={2}
        maxWidth="580px"
        textAlign="center"
      >
        <Grid item xs={12} justifyContent="center" display="flex">
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="gender"
              name="gender1"
              value={props.value}
              onChange={props.onChange}
            >
              <FormControlLabel
                className={classes.bannerNonStandardLabel}
                value="small"
                control={<StyleRadio />}
                label="Small (3:1)"
                style={{
                  marginRight:
                    props.engine === 'iOS' || props.engine === 'Android'
                      ? 80
                      : 140,
                }}
              />
              <FormControlLabel
                className={classes.bannerNonStandardLabel}
                value="medium"
                control={<StyleRadio />}
                label="Medium (6:5)"
                style={{
                  marginRight:
                    props.engine === 'iOS' || props.engine === 'Android'
                      ? 80
                      : 0,
                }}
              />
              {(props.engine === 'iOS' || props.engine === 'Android') && (
                <Box>
                  <FormControlLabel
                    className={classes.bannerNonStandardLabel}
                    value="custom"
                    control={<StyleRadio />}
                    label="Custom"
                    style={{ marginRight: 0 }}
                  />
                  <Tooltip
                    title={
                      <Box>
                        Custom size is a fully customized native ad unit. Please
                        see our{' '}
                        {
                          <Box
                            component="span"
                            color={theme.palette.primary.main}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (props.engine === 'iOS') {
                                window.open(
                                  'https://developers.yodo1.com/docs/ios/ad-units/native-ads-custom/'
                                )
                              } else {
                                window.open(
                                  'https://developers.yodo1.com/docs/android/unit-ads/native-ads-custom/'
                                )
                              }
                            }}
                          >
                            documentation
                          </Box>
                        }{' '}
                        for integration instruction.
                      </Box>
                    }
                    arrow
                    placement="top"
                  >
                    <InfoOutlinedIcon
                      style={{
                        fontSize: '18px',
                        verticalAlign: 'middle',
                        marginLeft: '6px',
                        color: theme.palette.text.secondary,
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Box className={classes.nativeadsContent}>
        You cannot change the size type after you set native ads for the game
        even if it&apos;s cancelled and added again.
      </Box>
    </Box>
  )
}
export default NativeadsAdTypesView
