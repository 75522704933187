const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '16'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.375"
        y="0.375"
        width="15.25"
        height="15.25"
        rx="1.625"
        stroke={color}
        strokeWidth="0.75"
      />
    </svg>
  )
}

export default SVG
