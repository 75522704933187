import { portalService } from './service'

export const getAppListApi = (params?: any) => {
  return portalService({
    url: '/game',
    method: 'get',
    params,
  })
}
export const getAppDetailApi = (app_key?: string, params?: any) => {
  const obj: any = {
    url: `/game/${app_key}`,
    method: 'get',
  }
  if (params) {
    obj.params = params
  }
  return portalService(obj)
}
export const switchGameAdApi = (app_key: string, is_enabled: boolean) => {
  return portalService({
    url: `/game/${app_key}/ad`,
    method: 'patch',
    data: { is_enabled },
  })
}
export const parseUrl = (params?: any) => {
  return portalService({
    url: '/game/parse',
    method: 'get',
    params,
  })
}
export const addLiveGame = (data?: any) => {
  return portalService({
    url: '/game/add_live',
    method: 'post',
    data,
  })
}
export const deleteGameApi = (appKey: string) => {
  return portalService({
    url: `/game/${appKey}`,
    method: 'delete',
  })
}
export const editGameApi = (data: any) => {
  return portalService({
    url: `/game/${data.app_key}`,
    method: 'patch',
    data,
  })
}
export const addNoneLiveGame = (data?: any) => {
  return portalService({
    url: '/game/add_non_live',
    method: 'post',
    data,
  })
}
export const getPreMetricsApi = (app_key: string) => {
  return portalService({
    url: `/game/${app_key}/pre_metrics`,
    method: 'get',
  })
}
export const putPreMetricsApi = (app_key: string, data: any) => {
  return portalService({
    url: `/game/${app_key}/pre_metrics`,
    method: 'put',
    data,
  })
}
export const getRetentionApi = (params: any) => {
  return portalService({
    url: `/game/${params.app_key}/retention`,
    method: 'get',
    params,
  })
}
export const getIssueApi = (app_key: string) => {
  return portalService({
    url: `/game/${app_key}/issue`,
    method: 'get',
  })
}
export const getCappingApi = (app_key: string) => {
  return portalService({
    url: `/game/${app_key}/time_capping`,
    method: 'get',
  })
}
export const updateCappingApi = (data: any, app_key: string) => {
  return portalService({
    url: `/game/${app_key}/time_capping`,
    method: 'post',
    data,
  })
}
export const setGameProcessTriggerApi = (app_key: string, data: any) => {
  return portalService({
    url: `/game/${app_key}/game_process_trigger`,
    method: 'patch',
    data,
  })
}
export const getSdkLatestVersionApi = () => {
  return portalService({
    url: '/game/versions',
    method: 'get',
  })
}
export const getMoDetailApi = (app_key: string) => {
  return portalService({
    url: `/game/${app_key}/comment`,
    method: 'post',
  })
}
// 无params时默认为count = 5
export const getUpsellListApi = (params?: any) => {
  return portalService({
    url: `/game/upsells`,
    method: 'get',
    params,
  })
}
export const getPerformanceScoringDetailApi = (app_key: string) => {
  return portalService({
    url: `/scoring/scoring/${app_key}`,
    method: 'get',
  })
}
export const getPerformanceScoringVoteApi = (app_key: string) => {
  return portalService({
    url: `/scoring/scoring/vote/${app_key}`,
    method: 'get',
  })
}
export const submitScoringFeedbackApi = (
  app_key: string,
  score_id: string,
  data: {
    recommendation_id: string
    vote: string
  }
) => {
  return portalService({
    url: `/scoring/scoring/vote/${app_key}/${score_id}`,
    method: 'post',
    data,
  })
}
export const submitScoringFeedbackDetailApi = (
  app_key: string,
  score_id: string,
  vote_id: string,
  data: {
    recommendation_id: string
    vote: string
    content: {
      content: string
      reason?: string[]
    }
  }
) => {
  return portalService({
    url: `/scoring/scoring/vote/${app_key}/${score_id}/${vote_id}`,
    method: 'put',
    data,
  })
}

export const getCompareGameApi = (data: {
  from_date: string
  to_date: string
  app_keys: string[]
  metric_names?: string[]
}) => {
  return portalService({
    url: `/game/comparison`,
    method: 'post',
    data,
    timeout: 60 * 1000,
  })
}
