import { Dialog, Box, Button, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import { LoadingBtn } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '80vw',
        maxWidth: '380px',
        padding: '40px',
        boxSizing: 'border-box',
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
      },
      content: {
        fontSize: '14px',
        margin: '8px 0 24px',
      },
      button: {
        display: 'flex',
        justifyContent: 'center',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '-20px',
        top: '-20px',
        cursor: 'pointer',
      },
      deleteButton: {
        boxSizing: 'border-box',
        width: '138px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        border: `1px solid ${theme.palette.custom.dialogButton}`,
        color: theme.palette.custom.dialogButton,
        borderRadius: '6px',
        marginRight: '24px',
        cursor: 'pointer',
      },
      backButton: {
        width: '138px',
        height: '40px !important',
        borderRadius: '6px !important',
      },
    }
  })
/**
 * @param openModel 显示隐藏
 * @param title 标题
 * @param deleteButtonText 标题
 * @function onConfirm 确认回调
 * @function onClose 关闭区域点击回调
 * @param loading 是否loading状态
 */
interface PromptDialogProps {
  openModel: boolean
  title: string
  deleteButtonText: string
  onConfirm?: () => void
  onClose?: () => void
  loading: boolean
}

const DeleteDialog = (props: PromptDialogProps) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  return (
    <Dialog
      data-testid="prompt-dialog"
      open={props.openModel}
      onClose={props.onClose}
    >
      <Box className={classes.root}>
        <Box>
          <Box data-testid="prompt-dialog-title" className={classes.title}>
            You want to delete {props.title} ?
            <CloseIcon
              className={classes.closeFilterIcon}
              onClick={props.onClose}
            />
          </Box>
        </Box>
        <Box data-testid="prompt-dialog-content" className={classes.content}>
          NOTE:{' '}
          <Box component="span" color="#687a99">
            This action cannot be undone!
          </Box>
        </Box>
        <Box data-testid="prompt-dialog-footer" className={classes.button}>
          <Box className={classes.deleteButton} onClick={props.onConfirm}>
            {props.loading ? (
              <Box lineHeight="30px" padding="5px 0">
                <LoadingBtn
                  loadingStyle={{
                    justifyContent: 'center',
                    color: '#bcbcbc',
                  }}
                />
              </Box>
            ) : props.deleteButtonText ? (
              props.deleteButtonText
            ) : (
              'Delete'
            )}
          </Box>
          <Button
            className={classes.backButton}
            color="primary"
            variant="contained"
            onClick={props.onClose}
          >
            Go Back
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DeleteDialog
