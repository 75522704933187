import { useState, ChangeEvent } from 'react'
import {
  Box,
  Dialog,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  useTheme,
  Theme,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { LoadingBtn } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '80vw',
        maxWidth: '500px',
        padding: '40px',
        boxSizing: 'border-box',
        lineHeight: '22px',
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '-3px',
        cursor: 'pointer',
      },
      content: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
        margin: '16px 0',
        lineHeight: '18px',
      },
      closeButton: {
        flex: 1,
        boxSizing: 'border-box',
        // width: '178px',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        borderRadius: '6px',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.custom.dialogButton}`,
        color: theme.palette.custom.dialogButton,
      },
      dialogPosition: {
        transform: 'translateY(calc(50vh - 50% - 20px))',
      },
      radioGroup: {
        '& .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root': {
          padding: '4px 9px',
        },
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
          color: theme.palette.text.secondary,
        },
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
    }
  })
/**
 * @param openModel 显示隐藏
 * @param submitLoading 上传中
 * @function onSubmit 确认回调
 * @function onClose 关闭区域点击回调
 */
interface RatingDialogProps {
  openModel: boolean
  submitLoading: boolean
  onSubmit: (data: any) => void
  onClose?: (score: string) => void
}
const Layout = (props: RatingDialogProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [score, setScore] = useState<string>('')
  const [value, setValue] = useState<string>('')
  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setScore((event.target as HTMLInputElement).value)
  }
  const submit = () => {
    props.onSubmit({ integration_score: score, integration_reason: value })
  }
  const close = () => {
    props.onClose && props.onClose(score)
  }
  // returns
  return (
    <Dialog
      open={props.openModel}
      onClose={close}
      classes={{ paper: classes.dialogPosition }}
    >
      <Box className={classes.root}>
        <Box className={classes.title}>
          How difficult was MAS integration?
          <CloseIcon className={classes.closeFilterIcon} onClick={close} />
        </Box>
        <Box className={classes.content}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={score}
              onChange={handleChange}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label="Easy"
              />
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label="Somewhat difficult"
              />
              <FormControlLabel
                value="3"
                control={<Radio color="primary" />}
                label="Very difficult"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box className={classes.title}>What made you choose this score?</Box>
        <Box marginTop="16px">
          <TextField
            className={classes.formInputSearch}
            placeholder="Optional"
            variant="outlined"
            value={value}
            onChange={inputChange}
          />
        </Box>
        <Box display="flex" marginTop="24px">
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: '24px', flex: 1 }}
            onClick={submit}
            disabled={props.submitLoading || Number(score) <= 0}
          >
            {props.submitLoading ? <LoadingBtn /> : 'Submit'}
          </Button>
          <Box className={classes.closeButton} onClick={close}>
            Close
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default Layout
