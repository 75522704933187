import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import Loadable from "react-loadable";

/**
 * Lang codes: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 * i18n docs: https://react.i18next.com/latest/using-with-hooks
 * loadable repo: https://github.com/jamiebuilds/react-loadable
 */

import env from 'src/env'
import en from './en' // TODO: turn to lazy load via Loadable
import zh from './zh'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: env.debug,

    ns: ['translations'],
    defaultNS: 'translations',

    resources: {
      en: {
        translations: en,
      },
      zh: {
        translations: zh,
      },
    },
    // backend: {
    //   loadPath: './locales/{{lng}}/{{ns}}.json'
    // },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
