import { createContext, useReducer, useEffect, useContext } from 'react'

import { ApiResponse } from 'src/api'
import { parseUrl, addLiveGame, addNoneLiveGame } from 'src/api/game'
import { GlobalContext } from './global'

type Props = {
  urlParseDetail: any
  url: string
  live: {
    url: string
    dau_level: string
    banner_non_standard?: boolean
  }
  nonLive: {
    platform: string
    name: string
    dau_level: string
    banner_non_standard?: boolean
  }
  addResult: string | boolean
  addGameDetail: Record<string, string>
  updateLinkLoading: boolean
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  urlParseDetail: {},
  url: '',
  live: {
    url: '',
    dau_level: '',
    banner_non_standard: false,
  },
  nonLive: {
    name: '',
    dau_level: '',
    platform: '',
    banner_non_standard: false,
  },
  addResult: '',
  addGameDetail: {},
  updateLinkLoading: false,
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'url':
    case 'urlParseDetail':
    case 'live':
    case 'nonLive':
    case 'addResult':
    case 'addGameDetail':
    case 'updateLinkLoading':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
}
export const GameAddContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
})

export const GameAddContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, getNewGameList } = useContext(GlobalContext)

  useEffect(() => {
    if (state.url) {
      dispatch({ type: 'urlParseDetail', payload: {} })
      dispatch({ type: 'updateLinkLoading', payload: true })
      parseUrl({ url: state.url })
        .then(({ data }: ApiResponse) => {
          dispatch({ type: 'urlParseDetail', payload: data })
        })
        .catch(errorCatch)
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: 'updateLinkLoading', payload: false })
          }, 500)
          dispatch({ type: 'url', payload: '' })
        })
    }
    // eslint-disable-next-line
  }, [state.url])
  useEffect(() => {
    if (state.live.url) {
      dispatch({ type: 'addResult', payload: '' })
      addLiveGame(state.live)
        .then(res => {
          dispatch({ type: 'addResult', payload: res.status === 200 })
          dispatch({ type: 'addGameDetail', payload: res.data })
          getNewGameList()
        })
        .catch(errorCatch)
        .finally(() => {
          dispatch({ type: 'addResult', payload: false })
        })
    }
    // eslint-disable-next-line
  }, [state.live])
  useEffect(() => {
    if (state.nonLive.name) {
      dispatch({ type: 'addResult', payload: '' })
      addNoneLiveGame(state.nonLive)
        .then(res => {
          dispatch({ type: 'addResult', payload: res.status === 200 })
          dispatch({ type: 'addGameDetail', payload: res.data })
          getNewGameList()
        })
        .catch(errorCatch)
        .finally(() => {
          dispatch({ type: 'addResult', payload: false })
        })
    }
    // eslint-disable-next-line
  }, [state.nonLive])
  // returns
  return (
    <GameAddContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GameAddContext.Provider>
  )
}
