const vars = {
  apiUrl: process.env.REACT_APP_API_URL,
  ssoUrl: process.env.REACT_APP_SSO_URL,
  appName: process.env.REACT_APP_APP_NAME,
  tipaltiDomain: process.env.REACT_APP_TIPALTI_DOMAIN,
  tipaltiMasterKey: process.env.REACT_APP_TIPATLTI_KEY,
  gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  amplitudeKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
  intercomId: process.env.REACT_APP_INTERCOM_ID,
  env: process.env.REACT_APP_ENV,
  codeVersion: '4.0.18',
  protocolPDF: process.env.REACT_APP_PROTOCOL_PDF,
  debug: process.env.NODE_ENV !== 'production',
  SDKMinimumVersion: '4.0.1',
  SDKNewestVersion: '4.3.2',
}

export default vars
