import { useMemo } from 'react'
import { Box, Tooltip, useTheme, Theme } from '@material-ui/core'
import { Tabs as AntdTabs } from 'antd'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import { SVGIcon, Icons } from 'src/images'
import Skeleton from '@material-ui/core/Skeleton'

interface tabProps {
  value: string
  labelKey?: string
  valueKey?: string
  numKey?: string
  tooltipKey?: string
  showTrend?: boolean
  trendKey?: string
  options: any[]
  onChange: (item: string) => void
}
const ReportsTab = (props: tabProps): JSX.Element => {
  const {
    value,
    labelKey = 'name', // tab名称
    valueKey = 'value', // tab选择的value
    numKey = 'num', // tab中的num值
    tooltipKey = 'tooltip', // tab中的tooltip
    showTrend = false, // 是否展示趋势
    trendKey = 'trend', // tab中的trend
    options,
    onChange,
  } = props
  const theme: Theme = useTheme()

  const list = useMemo(() => {
    const newOptions = options.map((item: any) => {
      return {
        label: (
          <Box padding="0 2px">
            {item[labelKey]}
            {item.disabled ? (
              <Tooltip
                title={`The ${
                  item[labelKey]
                } reporting is currently under maintenance${
                  item.untilTime ? ' until ' + item.untilTime : ''
                }.`}
                arrow
                placement="top"
                disableInteractive
              >
                <img
                  src={Icons.warningIcon}
                  alt="icon"
                  style={{
                    width: '14px',
                    verticalAlign: 'middle',
                    marginLeft: '6px',
                    marginTop: '-2px',
                  }}
                />
              </Tooltip>
            ) : (
              <>
                {item[tooltipKey] && (
                  <Tooltip
                    title={item[tooltipKey]}
                    arrow
                    placement="top"
                    disableInteractive
                  >
                    <InfoOutlinedIcon
                      sx={{
                        fontSize: '16px !important',
                        verticalAlign: 'middle',
                        marginLeft: '6px',
                        color: theme.palette.text.secondary,
                        marginTop: '-2px',
                      }}
                    />
                  </Tooltip>
                )}
              </>
            )}
            {item.disabled ? (
              <Box>-</Box>
            ) : (
              <>
                {item.loading ? (
                  <Skeleton
                    variant="rectangular"
                    height="20px"
                    width="80px"
                    style={{ transform: 'translateY(1px)' }}
                  />
                ) : (
                  <Box>
                    {item[numKey]}
                    {showTrend && item[trendKey] && (
                      <>
                        {item[trendKey] === 'down' ? (
                          <SVGIcon.LowerSVG
                            width="20px"
                            height="20px"
                            style={{ verticalAlign: 'text-top' }}
                          />
                        ) : item[trendKey] === 'up' ? (
                          <SVGIcon.GrowSVG
                            width="20px"
                            height="20px"
                            style={{ verticalAlign: 'text-top' }}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </Box>
                )}
              </>
            )}
          </Box>
        ),
        key: item[valueKey],
        disabled: item.disabled,
      }
    })
    return newOptions
  }, [
    options,
    labelKey,
    valueKey,
    numKey,
    tooltipKey,
    theme.palette.text.secondary,
    showTrend,
    trendKey,
  ])
  // 当前选择的tab为disabled时切换至第一个非disabled项，全部都disabled则选择第一项
  const selectValue = useMemo(() => {
    const tempList = options.find(item => {
      return item[valueKey] === value
    })
    if (tempList?.disabled) {
      const notDisabledOption = options.filter(item => !item.disabled)
      const newValue = (notDisabledOption[0] || options[0])[valueKey] || value
      onChange(newValue)
      return newValue
    }
    return value
    // eslint-disable-next-line
  }, [value, options])

  return (
    <AntdTabs
      activeKey={selectValue}
      tabPosition="top"
      size="small"
      onChange={onChange}
      renderTabBar={(tabBarProps, DefaultTabBar) => (
        <Box
          sx={{
            marginBottom: '-16px',
            '& .ant-tabs-ink-bar': {
              background: theme.palette.primary.main,
            },
            '& .ant-tabs-nav-more': {
              color: `${theme.palette.text.primary} !important`,
            },
            '& .ant-tabs-nav::before': {
              borderBottom: '0 none !important',
            },
          }}
        >
          <DefaultTabBar {...tabBarProps}>
            {(node: any) => {
              return (
                <Box
                  sx={{
                    color: theme.palette.text.secondary,
                    borderBottom: `2px solid ${
                      theme.palette.mode === 'light'
                        ? '#E1E4E6'
                        : 'rgba(121, 126, 135, 0.3)'
                    }`,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    fontWeight: 500,
                    '& .ant-tabs-tab:hover, & .ant-tabs-tab-btn:active, & .ant-tabs-tab-btn:focus':
                      {
                        color: `${theme.palette.text.primary} !important`,
                      },
                    '& .ant-tabs-tab-active .ant-tabs-tab-btn': {
                      color: `${theme.palette.primary.main} !important`,
                    },
                    '& .ant-tabs-tab-disabled.ant-tabs-tab, & .ant-tabs-tab-disabled.ant-tabs-tab:hover':
                      {
                        color: `${theme.palette.text.secondary} !important`,
                        opacity: 0.55,
                      },
                  }}
                >
                  <Box>{node}</Box>
                </Box>
              )
            }}
          </DefaultTabBar>
        </Box>
      )}
      style={{ marginTop: '-18px', overflow: 'hidden' }}
      items={list}
    />
  )
}

export default ReportsTab
