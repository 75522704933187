const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '16'
  const color = props.color || '#AECBFA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 14.0001V2.66675H9.04967L9.36634 4.10008H13.333V10.2667H9.06634L8.74967 8.85008H4.33301V14.0001H3.33301ZM9.91634 9.26675H12.333V5.10008H8.51634L8.19967 3.66675H4.33301V7.85008H9.59967L9.91634 9.26675Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
