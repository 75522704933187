import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { AdPlacementsContext } from 'src/context/adPlacement'
import EchartView from './echart'
import ChartSettingView from './chartSetting'
import { LoadingComponent, ChartTypeToggleGroup } from 'src/components'
import { rateTitleFormat } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      contentBottom: {
        width: '100%',
      },
      estRevenue: {
        marginLeft: '6px',
        marginRight: '16px',
        fontVariantNumeric: 'tabular-nums',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })
const titles = {
  ratio_revenue: 'Revenue',
  ratio_impressions: 'Impressions',
  dau: 'DAU',
  placement_imp_dau: rateTitleFormat('Imp', 'DAU'),
  placement_arpdau: 'ARPDAU',
  dav: 'DAV',
  placement_arpdav: 'ARPDAV',
  ad_viewer_rate: 'Ad Viewer Rate',
}
const RevenueChartView = (): JSX.Element => {
  // const
  const { state, dispatch } = useContext(AdPlacementsContext)
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  // returns
  return (
    <Box className={classes.contentBottom}>
      {state.loading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          fontSize: '20px',
          lineHeight: '36px',
          fontWeight: 500,
          padding: '0 4px',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            padding: '0 4px',
          }}
        >
          <Box marginRight="16px">{titles[state.chartType || 'Revenue']}</Box>
          <ChartSettingView />
        </Box>
        <ChartTypeToggleGroup
          value={state.newChartType}
          onChange={(chartType: string) => {
            dispatch({ type: 'newChartType', payload: chartType })
          }}
        />
      </Box>
      <Box marginTop="10px">
        <EchartView />
      </Box>
    </Box>
  )
}

export default RevenueChartView
