import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DashContext } from 'src/context/dashboard'
import { GlobalContext } from 'src/context/global'
import EchartView from './echart'
import TableView from './table'
import {
  LoadingComponent,
  ViewBySelect,
  ChartTypeToggleGroup,
} from 'src/components'
import { toThousands } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      contentBottom: {
        display: 'flex',
        position: 'relative',
        width: '100%',
      },
      topDivider: {
        width: '1px',
        height: '24px',
        backgroundColor: theme.palette.divider,
        marginLeft: '15px',
        marginTop: '4px',
      },
      chartDivider: {
        width: '1px',
        height: '90',
        backgroundColor: theme.palette.divider,
        marginLeft: '8px',
      },
      chartTable: {
        width: '100%',
        marginLeft: '8px',
        position: 'relative',
        height: '324px',
        overflow: 'hidden',
      },
      estRevenue: {
        color: '#FF8E00',
        marginLeft: '15px',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })
const TopChart = (): JSX.Element => {
  // const
  const { state, dispatch, getData } = useContext(DashContext)
  const { state: globalState } = useContext(GlobalContext)

  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  // returns
  return (
    <Box className={classes.contentBottom}>
      {state.dataLoading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}
      <Box
        sx={{
          display: !globalState.isMobile ? 'flex' : 'block',
          width: '100%',
        }}
      >
        <Box
          overflow="hidden"
          flex={1}
          width={`calc(100% - 160px)`}
          maxWidth={`calc(100% - 160px)`}
          sx={
            globalState.isMobile
              ? {
                  width: '100%',
                  maxWidth: '100%',
                }
              : {}
          }
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: '12px',
            }}
          >
            <Box
              fontSize="20px"
              fontWeight={500}
              display="flex"
              height="32px"
              lineHeight="32px"
            >
              {'Est. Revenue'}
              <Box className={classes.topDivider} />
              <div className={classes.estRevenue}>
                ${toThousands(state.chartRevenue)}
              </div>
            </Box>
            <Box>
              <ChartTypeToggleGroup
                value={state.newChartType}
                onChange={(chartType: string) => {
                  dispatch({ type: 'newChartType', payload: chartType })
                }}
              />
            </Box>
          </Box>
          <ViewBySelect
            value={state.view}
            onChange={(value: string) => {
              getData(value)
              dispatch({
                type: 'view',
                payload: value,
              })
              dispatch({
                type: 'checkedChartLine',
                payload: [],
              })
            }}
          />
          <Box marginTop="10px" width="100%">
            <EchartView />
          </Box>
        </Box>
        <Box
          width={!globalState.isMobile ? '160px' : '100%'}
          minWidth={!globalState.isMobile ? '160px' : '100%'}
        >
          <Box display="flex">
            {!globalState.isMobile && <Box className={classes.chartDivider} />}
            <Box className={classes.chartTable}>
              <TableView />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TopChart
