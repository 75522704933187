const SVG = (props: any) => {
  const width = props.width || '50'
  const height = props.height || '28'
  const color = props.color || '#27AE60'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 50 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.146813 0.726432C-0.0221395 0.393806 0.219528 0 0.592603 0H42.1377C42.3259 0 42.4982 0.105731 42.5835 0.273567L49.4406 13.7736C49.5129 13.9159 49.5129 14.0841 49.4406 14.2264L42.5835 27.7264C42.4982 27.8943 42.3259 28 42.1377 28H0.592603C0.219528 28 -0.022139 27.6062 0.146814 27.2736L6.77393 14.2264C6.84621 14.0841 6.84621 13.9159 6.77393 13.7736L0.146813 0.726432Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
