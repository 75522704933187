import { Box, Collapse, Grid, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { FinanceContext, FinanceContextProvider } from 'src/context/finance'
import OverviewView from './Overview'
import ProfileView from './Profile'
import { StylePaper } from 'src/components'
import TipaltiHistory from './TipaltiHistory/history'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        boxSizing: 'border-box',
        width: '100%',
      },
      top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '27px 30px',
        fontSize: '27px',
        fontWeight: 900,
        color: theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      mainTop: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      backBox: {
        color: theme.palette.text.secondary,
        fontSize: '16px',
        fontWeight: 'bold',
        height: '20px',
        lineHeight: '20px',
        cursor: 'pointer',
      },
      backIcon: {
        fontSize: '16px',
        border: '1px solid #2D4D5F',
        borderRadius: '50%',
        verticalAlign: 'text-bottom',
        marginRight: '7px',
      },
      topCard: {
        paddingBottom: 40,
      },
    }
  })

const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  // returns
  return (
    <FinanceContextProvider>
      <FinanceContext.Consumer>
        {data => {
          const {
            state: { viewDetail },
          }: any = data

          return (
            <Box className={classes.root}>
              <Box marginTop="24px">
                <Collapse in={!viewDetail}>
                  <Grid className={classes.topCard} container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <StylePaper width="100%">
                        <OverviewView />
                      </StylePaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StylePaper width="100%">
                        <ProfileView />
                      </StylePaper>
                    </Grid>
                  </Grid>
                </Collapse>
              </Box>
              <StylePaper width="100%" boxSizing="border-box" padding="24px">
                <TipaltiHistory />
              </StylePaper>
            </Box>
          )
        }}
      </FinanceContext.Consumer>
    </FinanceContextProvider>
  )
}

export default View
