import { useState, useContext, useEffect, useMemo, MouseEvent } from 'react'
import {
  Box,
  Popover,
  Tooltip,
  useTheme,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
} from '@material-ui/core'
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { SVGIcon } from 'src/images'
import { MiddleChartContext } from 'src/context/performance/middleChart'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      popover: {
        '& .MuiPaper-root': {
          minWidth: '300px',
          width: '300px',
          maxHeight: '394px',
          marginTop: '4px',
          borderRadius: '6px',
          padding: '16px 12px',
          background: theme.palette.custom.dialogBg,
        },
      },
    }
  })

const SortButton = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch } = useContext(MiddleChartContext)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [showListNumber, setShowListNumber] = useState<number>(8)
  const showList = useMemo(() => {
    if (
      state.view === 'country' &&
      !state.countryIsFilter &&
      showListNumber < state.chartList.length
    ) {
      const temp = state.chartList.slice(0, showListNumber)
      if (showListNumber > 8) {
        const newTemp = [
          ...state.checkedChartLine,
          ...state.chartList
            .slice(showListNumber - 8, showListNumber)
            .map((item: any) => item.label),
        ]
        dispatch({
          type: 'checkedChartLine',
          payload: newTemp,
        })
      }
      return temp
    }
    return state.chartList
    // eslint-disable-next-line
  }, [state.chartList, state.view, showListNumber, state.countryIsFilter])
  const handleClick = (event: MouseEvent<unknown>, value: string) => {
    const selectedIndex = state.checkedChartLine.indexOf(value)
    let newSelected: string[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.checkedChartLine, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.checkedChartLine.slice(1))
    } else if (selectedIndex === state.checkedChartLine.length - 1) {
      newSelected = newSelected.concat(state.checkedChartLine.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.checkedChartLine.slice(0, selectedIndex),
        state.checkedChartLine.slice(selectedIndex + 1)
      )
    }
    dispatch({
      type: 'checkedChartLine',
      payload: newSelected,
    })
  }

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (state.view === 'country') {
      setShowListNumber(8)
    }
  }, [state.view, state.chartList])
  useEffect(() => {
    if (state.view === 'country' && !state.countryIsFilter) {
      const checkedChartLine = state.chartList
        .slice(0, 8)
        .map((item: any) => item.label)
      dispatch({
        type: 'checkedChartLine',
        payload: checkedChartLine,
      })
    }
    // eslint-disable-next-line
  }, [state.chartList])
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Box
        sx={{
          boxSizing: 'border-box',
          width: '24px',
          height: '24px',
          padding: '3px',
          cursor: 'pointer',
        }}
        aria-describedby={id}
        onClick={handleOpen}
      >
        <Tooltip title="Filter graph legends" arrow placement="top">
          <Box>
            <SVGIcon.SettingSVG
              color={theme.palette.text.primary}
              style={{ verticalAlign: 'text-top', lineHeight: '18px' }}
            />
          </Box>
        </Tooltip>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
        sx={{ zIndex: 1999 }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {showList.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={event => handleClick(event, row.value)}
                  role="checkbox"
                  aria-checked={state.checkedChartLine.includes(row.value)}
                  tabIndex={-1}
                  selected={state.checkedChartLine.includes(row.value)}
                  style={{
                    cursor: 'pointer',
                    background: theme.palette.custom.chartTableBg,
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    style={{ padding: 0, width: 42 }}
                  >
                    <Checkbox
                      color="primary"
                      checked={state.checkedChartLine.includes(row.value)}
                      style={{ color: '#7487AA' }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: 0, width: 20 }}
                  >
                    <Box
                      sx={{
                        width: '20px',
                        height: '10px',
                        background: row.color,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: theme.palette.custom.inputLabel,
                      fontSize: 12,
                      padding: '10px 12px',
                    }}
                  >
                    {row.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {state.view === 'country' &&
            state.chartList.length > 1 &&
            !state.countryIsFilter &&
            showListNumber < state.chartList.length && (
              <Box
                sx={{
                  fontSize: 12,
                  color: theme.palette.custom.dialogButton,
                  textAlign: 'center',
                  padding: '24px 0 12px',
                }}
              >
                <Box
                  component="span"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowListNumber(showListNumber + 8)
                  }}
                >
                  Load more
                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: '12px !important',
                      transform: 'rotate(90deg)',
                      marginLeft: '4px',
                      verticalAlign: 'middle',
                      color: theme.palette.custom.dialogButton,
                    }}
                  />
                </Box>
              </Box>
            )}
        </TableContainer>
      </Popover>
    </>
  )
}
export default SortButton
