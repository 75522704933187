import { EngagementContextProvider } from 'src/context/engagement'
import EngagementView from './engagement'

const Cohorts = (): JSX.Element => {
  // returns
  return (
    <EngagementContextProvider>
      <EngagementView />
    </EngagementContextProvider>
  )
}

export default Cohorts
