const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '16'
  const color = props.color || '#4A5C75'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.22655 9.44629H7.21541C7.0964 9.44629 7.00002 9.54267 7.00002 9.66168V12.7842C7.00002 12.9032 7.0964 12.9996 7.21541 12.9996H9.22655C9.34556 12.9996 9.4418 12.9032 9.4418 12.7842V9.66154C9.4418 9.54253 9.34542 9.44629 9.22655 9.44629Z"
        fill={color}
      />
      <path
        d="M5.22655 3H3.21542C3.0964 3 3.00002 3.09638 3.00002 3.21539V12.7846C3.00002 12.9036 3.0964 13 3.21542 13H5.22655C5.34556 13 5.44194 12.9036 5.44194 12.7846V3.21539C5.44194 3.09638 5.34556 3 5.22655 3Z"
        fill={color}
      />
      <path
        d="M13.2265 5.90576H11.2154C11.0964 5.90576 11 6.00214 11 6.12115V12.7832C11 12.9022 11.0964 12.9986 11.2154 12.9986H13.2265C13.3456 12.9986 13.4419 12.9022 13.4419 12.7832V6.12115C13.4419 6.00228 13.3456 5.90576 13.2265 5.90576Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
