import { TextField, Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState, useRef, useMemo } from 'react'
import {
  Search as SearchIcon,
  InfoOutlined as InfoOutlinedIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Create as CreateIcon,
  Info as InfoIcon,
} from '@material-ui/icons'
import { GameContext } from 'src/context/games'
import { GlobalContext } from 'src/context/global'
import { GlobalStoreContext } from 'src/context/globalStore'
import { PNG, SVGIcon } from 'src/images'
import {
  BorderStyleSelectRow,
  StyleTablePagination,
  StylePaper,
  NoDataView,
  TopTipPaper,
  OpenInNewIconView,
  AdaptiveTooltip,
} from 'src/components'
import ProgressView from './progress'
import TitleInfoDialogView from './mobileTitleInfoDialog'
import MonetizingGameView from '../monetizingGame'
import MetricsFormView from '../metricsForm'
import ActiveAdsView from './activeAds'
import UpsellAppView from './upsell/upsellApp'
import { getQueryParams, getScoringColor, getScoringNum } from 'src/utils'
import dayjs from 'dayjs'
const Yesterday = dayjs().subtract(1, 'day')

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        width: '100%',
        color: theme.palette.text.primary,
      },
      icon: {
        width: '22px !important',
        height: '18px !important',
      },
      search: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
          height: '40px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.custom.paperBorder} !important`,
          boxShadow:
            theme.palette.mode === 'light'
              ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
              : 'none',
          borderRadius: '6px',
        },
      },
      gamePhoto: {
        width: '48px',
        height: '48px',
        verticalAlign: 'middle',
        borderRadius: '4px',
      },
      bundle: {
        width: '100%',
        color: '#A8A8A8',
        marginTop: '4px',
        whiteSpace: 'nowrap',
      },
      platformPhoto: {
        width: '14px',
        height: '14px',
        verticalAlign: 'text-top',
      },
      title: {
        boxSizing: 'border-box',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '42px',
      },
      buttonGroup: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: theme.palette.text.secondary,
      },
      appWidth: {
        flex: 1,
        minWidth: '170px',
      },
      manageBox: {
        width: '80px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '12px',
      },
      graphBox: {
        width: '38px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
        marginRight: '6px',
      },
      manageIcon: {
        fontSize: '16px !important',
        verticalAlign: 'middle',
        color: theme.palette.text.primary,
        marginRight: '6px',
      },
      infoIcon: {
        fontSize: '16px !important',
        verticalAlign: 'middle',
        marginLeft: '4px',
        color: theme.palette.text.secondary,
        marginTop: '-3px',
      },
      selectStyle: {
        width: 'calc(50% - 8px) !important',
      },
      versionSelectStyle: {
        width: '100% !important',
      },
    }
  })

const statusOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Not live',
    value: 'not_live',
  },
  {
    label: 'Live',
    value: 'live',
  },
]
const platformOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'iOS',
    value: 'iOS',
  },
  {
    label: 'Android',
    value: 'android',
  },
]

const TableList = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, updateListForOne } = useContext(GameContext)
  const { state: globalState, dispatch: globalDispatch } =
    useContext(GlobalContext)
  const { state: globalStoreState, dispatch: globalStoreDispatch } =
    useContext(GlobalStoreContext)
  const navigate = useNavigate()
  const arr: any[] = []
  const [tableData, setTableData] = useState(arr)
  const list = Array(5).fill('')
  const [searchValue, setSearchValue] = useState('')
  const timerTem: any = null
  const [timer, setTimer] = useState(timerTem)
  const [openTitleInfoModel, setOpenTitleInfoModel] = useState(false)
  const [progressAppKey, setProgressAppKey] = useState<string>('')
  const [progressAppName, setProgressAppName] = useState<string>('')
  const [progressIndex, setProgressIndex] = useState<number>(0)
  const noFilter = useMemo(() => {
    if (
      state.name !== '' ||
      state.review !== 'all' ||
      state.platform !== 'all' ||
      state.masVersion !== ''
    ) {
      return false
    }
    return true
  }, [state.review, state.platform, state.masVersion, state.name])

  const MonetizingGameDialog = useRef()
  const MetricsFormeDialog = useRef()

  const valueChange = (e: any) => {
    setSearchValue(e.target.value)
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        dispatch({
          type: 'name',
          payload: e.target.value,
        })
        clearTimeout(timer)
        setTimer(null)
      }, 1500)
    )
  }
  const openDialog = (row: any) => {
    globalStoreDispatch({
      type: 'gamePagePagination',
      payload: {
        page: state.page,
        page_size: state.page_size,
        name: state.name,
        review: state.review,
        platform: state.platform,
        masVersion: state.masVersion,
      },
    })
    const url = `/dash/games/manage/${row.app_key}/overview`
    navigate(url)
  }
  const toScorePage = (row: any) => {
    globalStoreDispatch({
      type: 'gamePagePagination',
      payload: {
        page: state.page,
        page_size: state.page_size,
        name: state.name,
        review: state.review,
        platform: state.platform,
        masVersion: state.masVersion,
      },
    })
    const url = `/dash/games/performance/${row.app_key}`
    navigate(url)
  }
  const toPerformance = (row: any) => {
    const time = [
      Yesterday.subtract(1, 'week').add(1, 'day').format('YYYY-MM-DD'),
      Yesterday.format('YYYY-MM-DD'),
    ]
    globalStoreDispatch({ type: 'fromWelcome', payload: true })
    globalStoreDispatch({ type: 'time', payload: time })
    globalStoreDispatch({ type: 'gameSelect', payload: [row] })
    globalStoreDispatch({ type: 'performanceData', payload: { filter: '' } })
    navigate('/dash/performance/revenue')
  }
  const pageChange = (page: number, page_size: number) => {
    dispatch({
      type: 'page',
      payload: { page, page_size },
    })
  }
  const toUrlPage = (url: string) => {
    url && window.open(url)
  }
  useEffect(() => {
    if (state.data) {
      setTableData(state.data)
    }
  }, [state.data])
  useEffect(() => {
    if (location.search) {
      const temp: any = getQueryParams(location.search)
      const name = decodeURIComponent(temp.name)
      setSearchValue(name)
      dispatch({
        type: 'name',
        payload: name,
      })
    }
    // eslint-disable-next-line
  }, [location.search])
  useEffect(() => {
    if (
      globalStoreState.gamePageFromDetail &&
      globalStoreState.gamePagePagination?.page &&
      globalStoreState.gamePagePagination?.page_size
    ) {
      setSearchValue(globalStoreState.gamePagePagination.name || '')
      globalStoreDispatch({
        type: 'gamePageFromDetail',
        payload: false,
      })
      dispatch({
        type: 'filter',
        payload: {
          page: globalStoreState.gamePagePagination.page,
          page_size: globalStoreState.gamePagePagination.page_size,
          name: globalStoreState.gamePagePagination.name || '',
          review: globalStoreState.gamePagePagination.review || 'all',
          platform: globalStoreState.gamePagePagination.platform || 'all',
          masVersion: globalStoreState.gamePagePagination.masVersion || '',
        },
      })
      globalStoreDispatch({
        type: 'gamePagePagination',
        payload: {},
      })
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (
      globalState.eventStatus?.feature_game_process_bar === false &&
      globalState.firstLoadGamePage
    ) {
      globalDispatch({
        type: 'FIRST_LOAD_GAME_PAGE',
        payload: false,
      })
      setOpenTitleInfoModel(true)
    }
    // eslint-disable-next-line
  }, [globalState.eventStatus?.feature_game_process_bar])
  return (
    <Box className={classes.container}>
      {globalState.showTerminationTip && (
        <TopTipPaper>
          <Box lineHeight="22px">
            Adding more than one app in violation of our terms and conditions
            would result in account termination and the blocking of future
            payments. Please read our{' '}
            <Box
              component="span"
              color={theme.palette.primary.main}
              onClick={() => {
                window.open(
                  'https://policy.yodo1.com/mas/en/terms-of-service.html'
                )
              }}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              terms and conditions
              <OpenInNewIconView />
            </Box>{' '}
            carefully.
          </Box>
        </TopTipPaper>
      )}

      <StylePaper
        width="100%"
        padding="16px"
        boxSizing="border-box"
        marginTop="24px"
        id="games-content"
      >
        <Box className={classes.title}>
          <Box
            marginBottom="8px"
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.87)'
                  : '#FFFFFF',
            }}
          >
            All Games
          </Box>
          <Box className={classes.buttonGroup}>
            <TextField
              className={classes.search}
              placeholder="Search by name"
              InputProps={{
                startAdornment: <SearchIcon className={classes.icon} />,
              }}
              onChange={valueChange}
              variant="outlined"
              value={searchValue}
            />
            <Box display="flex" margin="16px 0" width="100%" gap="16px">
              <BorderStyleSelectRow
                changeClose={true}
                width="100%"
                label="Status"
                placeholder="Status"
                value={{ value: state.review }} // 接收对象，所以讲state.review
                onChange={(row: any) => {
                  dispatch({
                    type: 'review',
                    payload: row.value,
                  })
                }}
                options={statusOptions}
              />
              <BorderStyleSelectRow
                changeClose={true}
                width="100%"
                label="Platform"
                placeholder="Platform"
                value={{ value: state.platform }} // 接收对象，所以讲state.platform
                onChange={(row: any) => {
                  dispatch({
                    type: 'platform',
                    payload: row.value,
                  })
                }}
                options={platformOptions}
              />
            </Box>

            <BorderStyleSelectRow
              changeClose={true}
              width="100%"
              label="MAS Version"
              placeholder="MAS Version"
              value={{ value: state.masVersion }} // 接收对象，所以讲state.masVersion
              onChange={(row: any) => {
                dispatch({
                  type: 'masVersion',
                  payload: row.value,
                })
              }}
              options={globalStoreState.sdkVersionList}
            />
          </Box>
        </Box>
        <Box>
          {!(
            state.loading ||
            (!state.loading && !tableData.length && state.upsellAppDataLoading)
          ) ? (
            tableData.length || (noFilter && state.upsellAppData.length) ? (
              <>
                {noFilter && <UpsellAppView />}
                {tableData.map((row, index) => {
                  return (
                    <Box
                      sx={{
                        padding: '16px 0',
                        borderBottom: '1px solid #F2F2F2',
                      }}
                      key={index}
                    >
                      <Box className={classes.appWidth}>
                        <Box
                          display="flex"
                          alignItems="center"
                          position="relative"
                          width="100%"
                        >
                          <Box
                            position="relative"
                            width="48px"
                            height="48px"
                            marginRight="16px"
                            onClick={() => {
                              toUrlPage(row.url)
                            }}
                            style={{
                              cursor: row.url ? 'pointer' : 'not-allowed',
                            }}
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                right: '-8px',
                                top: '-8px',
                                width: '14px',
                                height: '14px',
                                borderRadius: '50%',
                                border: `2px solid ${
                                  theme.palette.mode === 'light'
                                    ? '#FFF'
                                    : '#1A202C'
                                }`,
                                overflow: 'hidden',
                                background:
                                  row.platform === 'iOS'
                                    ? '#FFF'
                                    : theme.palette.background.paper,
                              }}
                            >
                              <img
                                src={
                                  row.platform === 'iOS' ? PNG.ios : PNG.android
                                }
                                className={classes.platformPhoto}
                                alt=""
                              />
                            </Box>

                            {row.app_termination_status === 'TERMINATION' ? (
                              <AdaptiveTooltip
                                isMobile={true}
                                title={
                                  row.app_termination_status ===
                                  'TERMINATION' ? (
                                    <Box>
                                      This app violates our policy against money
                                      making apps. Please read more about it{' '}
                                      <Box
                                        sx={{
                                          display: 'inline',
                                          color: theme.palette.primary.main,
                                          textDecoration: 'underline',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          window.open(
                                            'https://policy.yodo1.com/mas/en/terms-of-service.html'
                                          )
                                        }}
                                      >
                                        here.
                                      </Box>
                                    </Box>
                                  ) : (
                                    ''
                                  )
                                }
                                arrow
                                placement="top"
                              >
                                <Box>
                                  {row.app_termination_status ===
                                  'TERMINATION' ? (
                                    <SVGIcon.GameBannedFailureSVG
                                      style={{
                                        marginRight: '16px',
                                        verticalAlign: 'middle',
                                      }}
                                      color={
                                        theme.palette.mode === 'light'
                                          ? '#EFF2F3'
                                          : '#2B313C'
                                      }
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        boxSizing: 'border-box',
                                        width: '48px',
                                        height: '48px',
                                        background:
                                          theme.palette.mode === 'light'
                                            ? '#EFF2F3'
                                            : '#2B313C',
                                        borderRadius: '4px',
                                        padding: '8px',
                                      }}
                                    >
                                      <SVGIcon.GameBannedWarningSVG
                                        style={{
                                          verticalAlign: 'middle',
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </AdaptiveTooltip>
                            ) : row.icon ? (
                              <img
                                src={row.icon}
                                className={classes.gamePhoto}
                                alt=""
                              />
                            ) : (
                              <SVGIcon.GameDefault
                                style={{
                                  marginRight: '16px',
                                  verticalAlign: 'middle',
                                }}
                                color={
                                  theme.palette.mode === 'light'
                                    ? '#EFF2F3'
                                    : '#2B313C'
                                }
                              />
                            )}
                          </Box>
                          <Box width="calc(100% - 238px)">
                            <Box
                              fontSize="14px"
                              fontWeight="500"
                              onClick={() => {
                                if (
                                  row.app_termination_status === 'TERMINATION'
                                ) {
                                  return
                                }
                                openDialog(row)
                              }}
                              sx={{ wordBreak: 'break-word' }}
                              style={
                                row.app_termination_status === 'TERMINATION'
                                  ? { cursor: 'not-allowed' }
                                  : { cursor: 'pointer' }
                              }
                            >
                              {row.name}
                            </Box>
                            <Box
                              className={classes.bundle}
                              display="flex"
                              alignItems="center"
                            >
                              <Box
                                fontSize="12px"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                color={theme.palette.text.secondary}
                              >
                                App Key: {row.app_key}
                              </Box>
                            </Box>
                          </Box>
                          <Box width="168px" marginLeft="6px" display="flex">
                            <AdaptiveTooltip
                              title="Compare metrics"
                              arrow
                              placement="top"
                              disableInteractive
                              isMobile={true}
                            >
                              <Box
                                boxSizing="border-box"
                                paddingTop="5px"
                                className={classes.graphBox}
                                onClick={() => {
                                  globalStoreDispatch({
                                    type: 'gamePagePagination',
                                    payload: {
                                      page: state.page,
                                      page_size: state.page_size,
                                      name: state.name,
                                      review: state.review,
                                      platform: state.platform,
                                      masVersion: state.masVersion,
                                    },
                                  })
                                  navigate(
                                    `/dash/games/compare?app_keys=${row.app_key}`
                                  )
                                }}
                              >
                                <SVGIcon.CompareSVG
                                  color={theme.palette.text.primary}
                                  style={{ verticalAlign: 'text-bottom' }}
                                />
                              </Box>
                            </AdaptiveTooltip>
                            <AdaptiveTooltip
                              title="Performance reports"
                              arrow
                              placement="top"
                              disableInteractive
                              isMobile={true}
                            >
                              <Box
                                className={classes.graphBox}
                                onClick={() => {
                                  toPerformance(row)
                                }}
                              >
                                <SVGIcon.GraphSVG
                                  color={theme.palette.text.primary}
                                  style={{ verticalAlign: 'text-bottom' }}
                                />
                              </Box>
                            </AdaptiveTooltip>
                            {globalState.accountAuthority.game_manage_page !==
                              0 && (
                              <Box
                                className={classes.manageBox}
                                id="games-details"
                                onClick={() => {
                                  if (
                                    row.app_termination_status === 'TERMINATION'
                                  ) {
                                    return
                                  }
                                  openDialog(row)
                                }}
                                style={
                                  row.app_termination_status === 'TERMINATION'
                                    ? { cursor: 'not-allowed' }
                                    : {}
                                }
                              >
                                <CreateIcon className={classes.manageIcon} />
                                Manage
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          fontSize: '12px',
                          color: theme.palette.custom.tableHead,
                          margin: '16px 0 8px',
                          lineHeight: '16px',
                        }}
                      >
                        ACTIVE ADS
                        <AdaptiveTooltip
                          isMobile={true}
                          title="List of ad types enabled or disabled for your apps."
                          arrow
                          placement="top"
                          disableInteractive
                        >
                          <InfoOutlinedIcon className={classes.infoIcon} />
                        </AdaptiveTooltip>
                      </Box>
                      <Box>
                        <ActiveAdsView
                          ads_format={row.ads_format}
                          isMobile={true}
                          app_key={row.app_key}
                        />
                      </Box>
                      <Box
                        sx={{
                          fontSize: '12px',
                          color: theme.palette.custom.tableHead,
                          margin: '16px 0 8px',
                          lineHeight: '16px',
                        }}
                      >
                        RELEASE STEPS
                        <InfoOutlinedIcon
                          className={classes.infoIcon}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setOpenTitleInfoModel(true)
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          transform: 'scale(0.8)',
                          transformOrigin: 'left',
                        }}
                      >
                        <ProgressView
                          isMobile={true}
                          appDetail={row}
                          index={index}
                          openDialog={(
                            app_key: string,
                            name: string,
                            status: number,
                            index: number
                          ) => {
                            setProgressAppKey(app_key)
                            setProgressAppName(name)
                            setProgressIndex(index)
                            if (status === 1) {
                              const dialog: any = MetricsFormeDialog.current
                              dialog.openDialog(app_key)
                            } else {
                              const dialog: any = MonetizingGameDialog.current
                              dialog.openDialog(app_key)
                            }
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          fontSize: '12px',
                          color: theme.palette.custom.tableHead,
                          margin: '16px 0 8px',
                          lineHeight: '16px',
                        }}
                      >
                        HEALTH
                        <AdaptiveTooltip
                          isMobile={true}
                          title="Indicates whether your apps are monetizing without any issues."
                          arrow
                          placement="top"
                          disableInteractive
                        >
                          <InfoOutlinedIcon className={classes.infoIcon} />
                        </AdaptiveTooltip>
                      </Box>
                      <Box>
                        <Box sx={{ fontSize: '14px' }}>
                          {row.health === 1 ? (
                            <>
                              <AdaptiveTooltip
                                isMobile={true}
                                title={
                                  <>
                                    <Box marginBottom="12px">
                                      Your MAS SDK version is outdated, which
                                      may affect monetization. Please upgrade to
                                      MAS {globalStoreState.latestSdkVersion} to
                                      ensure optimal performance.
                                    </Box>
                                    <Box>Click to view changelog.</Box>
                                  </>
                                }
                                arrow
                                placement="top"
                                disableInteractive
                              >
                                <InfoIcon
                                  style={{
                                    color: '#FAAE31',
                                    cursor: 'pointer',
                                    verticalAlign: 'text-top',
                                  }}
                                  onClick={() => {
                                    if (row.engine === 'Android') {
                                      window.open(
                                        'https://developers.yodo1.com/docs/android/get-started/change-logs/'
                                      )
                                    } else if (row.engine === 'iOS') {
                                      window.open(
                                        'https://developers.yodo1.com/docs/ios/get-started/change-logs/'
                                      )
                                    } else {
                                      window.open(
                                        'https://developers.yodo1.com/docs/unity/get-started/change-logs/'
                                      )
                                    }
                                  }}
                                />
                              </AdaptiveTooltip>
                            </>
                          ) : row.health === 2 ? (
                            <CheckCircleIcon
                              style={{
                                color: '#27AE60',
                                verticalAlign: 'text-top',
                              }}
                            />
                          ) : (
                            <>
                              <AdaptiveTooltip
                                isMobile={true}
                                title="Click to view issues"
                                arrow
                                placement="top"
                                disableInteractive
                              >
                                <CancelIcon
                                  style={{
                                    color: '#B93D34',
                                    cursor: 'pointer',
                                    verticalAlign: 'text-top',
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `/dash/games/manage/${row.app_key}/issue`
                                    )
                                  }}
                                />
                              </AdaptiveTooltip>
                            </>
                          )}
                        </Box>
                      </Box>
                      {globalState.showPerformanceScoring && (
                        <>
                          <Box
                            sx={{
                              fontSize: '12px',
                              color: theme.palette.custom.tableHead,
                              margin: '16px 0 8px',
                              lineHeight: '16px',
                            }}
                          >
                            PERF. SCORE
                            <AdaptiveTooltip
                              isMobile={true}
                              title="Recommendations to improve the performance of your apps and games. The recommendations are updated every Monday."
                              arrow
                              placement="top"
                              disableInteractive
                            >
                              <InfoOutlinedIcon className={classes.infoIcon} />
                            </AdaptiveTooltip>
                          </Box>
                          <Box>
                            <Box
                              sx={{
                                display: 'inline-block',
                                width: '55px',
                                height: '25px',
                                textAlign: 'center',
                                lineHeight: '25px',
                                borderRadius: '12px',
                                background:
                                  getScoringColor(
                                    globalState.AppPerformanceScoring[
                                      row.app_key
                                    ]
                                  ) || theme.palette.custom.editIconButton,
                                color: getScoringColor(
                                  globalState.AppPerformanceScoring[row.app_key]
                                )
                                  ? '#FFF'
                                  : theme.palette.text.primary,
                                cursor: 'pointer',
                                fontSize: '14px',
                              }}
                              onClick={() => {
                                toScorePage(row)
                              }}
                            >
                              {getScoringNum(
                                globalState.AppPerformanceScoring[row.app_key]
                              )}
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  )
                })}
                {/* Pagination */}
                {!!tableData.length && (
                  <>
                    <StyleTablePagination
                      isMobile={true}
                      page={state.page}
                      pageSize={state.page_size}
                      total={state.total}
                      pageChange={pageChange}
                      disabled={state.loading}
                    />
                  </>
                )}
              </>
            ) : (
              <NoDataView desc="You have no games at the moment" />
            )
          ) : (
            // skeleton
            list.map((item, index) => {
              return (
                <Box
                  sx={{ padding: '16px 0', borderBottom: '1px solid #F2F2F2' }}
                  key={index}
                >
                  <Skeleton variant="rectangular" width="100%" height={192} />
                </Box>
              )
            })
          )}
        </Box>
      </StylePaper>
      <TitleInfoDialogView
        openModel={openTitleInfoModel}
        onClose={() => {
          setOpenTitleInfoModel(false)
        }}
      />
      <MonetizingGameView
        ref={MonetizingGameDialog}
        app_key={progressAppKey}
        gameName={progressAppName}
        getNewData={{ app_key: progressAppKey, index: progressIndex }}
        onSubmit={() => {
          updateListForOne(progressAppKey, progressIndex)
        }}
        onClose={() => {}}
      />
      <MetricsFormView
        ref={MetricsFormeDialog}
        app_key={progressAppKey}
        getNewData={{ app_key: progressAppKey, index: progressIndex }}
      />
    </Box>
  )
}

export default TableList
