import { useMemo } from 'react'
import {
  Box,
  Tooltip,
  Chip,
  useTheme,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import { SVGIcon, Icons } from 'src/images'
import CardChartView from './cardChartView'
import clsx from 'clsx'
import Skeleton from '@material-ui/core/Skeleton'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      card: {
        position: 'relative',
        boxSizing: 'border-box',
        height: '144px',
        borderRadius: 8,
        boxShadow:
          theme.palette.mode === 'light'
            ? '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)'
            : '0 1px 2px 0 rgba(255, 255, 255, 0.04), 0 1px 6px -1px rgba(255, 255, 255, 0.03), 0 2px 4px 0 rgba(255, 255, 255, 0.03)',
        background: theme.palette.background.paper,
        userSelect: 'none',
        overflow: 'hidden',
        padding: '9px 9px 12px 14px',
        color: theme.palette.text.primary,
      },
      clickCard: {
        cursor: 'pointer',
        '&:hover': {
          background: 'rgba(30, 150, 251)',
          color: '#FFFFFF',
        },
        '&:hover svg': {
          fill: '#FFFFFF',
        },
        '&:hover .MuiBox-root svg': {
          stroke: '#FFFFFF',
          fill: '#FFFFFF',
        },
        '&:hover .MuiChip-root': {
          background: 'rgba(255, 255, 255, 0.2)',
        },
        '&:hover .MuiChip-root svg': {
          background: 'rgba(255, 255, 255, 0)',
          fill: '#FFFFFF',
        },
        '&:hover .MuiChip-label': {
          color: '#FFFFFF',
        },
        '&:hover #middle_chart_bottom': {
          color: '#FFFFFF',
        },
      },
      title: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '32px',
        height: '32px',
      },
      num: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: 600,
        height: '40px',
        lineHeight: '20px',
        margin: '6px 0 9px',
        wordBreak: 'break-all',
      },
      bottomTip: {
        display: 'flex',
        alignItems: 'flex-end',
        height: '38px',
        lineHeight: '15px',
        fontSize: '12px',
        color: theme.palette.text.secondary,
      },
      topRightChipUp: {
        '& .MuiChip-root': {
          backgroundColor: 'rgba(62, 179, 112, 0.07)',
          borderRadius: '12px',
          height: '24px',
          paddingLeft: '8px',
          '& .MuiChip-label': {
            lineHeight: '12px',
            fontWeight: 500,
            paddingLeft: '3px',
            paddingRight: '8px',
          },
        },
        '& .MuiChip-root svg': {
          fill: '#3eb370',
        },
      },
      topRightChipDown: {
        '& .MuiChip-root': {
          backgroundColor: 'rgba(255, 61, 0, 0.07)',
          borderRadius: '12px',
          height: '24px',
          paddingLeft: '8px',
          '& .MuiChip-label': {
            lineHeight: '12px',
            fontWeight: 500,
            paddingLeft: '3px',
            paddingRight: '8px',
          },
        },
        '& .MuiChip-root svg': {
          fill: '#FF3D00',
        },
      },
      chart: {
        height: '50px',
        margin: '0 -11px -12px -16px',
      },
      bottomGoDown: {
        fontWeight: 500,
        display: 'flex',
        position: 'relative',
        '& .MuiBox-root svg': {
          fill: '#FF3D00',
          stroke: '#FF3D00',
        },
      },
      bottomGoUp: {
        fontWeight: 500,
        display: 'flex',
        position: 'relative',
        '& .MuiBox-root svg': {
          fill: '#3eb370',
          stroke: '#3eb370',
        },
      },
    }
  })
const CardView = (props: any): JSX.Element => {
  // const
  const min1890 = useMediaQuery('(min-width:1890px)')
  const min1356 = useMediaQuery('(min-width:1356px)')
  const min1280 = useMediaQuery('(min-width:1280px)')
  const min1120 = useMediaQuery('(min-width:1120px)')
  const min860 = useMediaQuery('(min-width:860px)')
  const min590 = useMediaQuery('(min-width:590px)')
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  const flexBasis = useMemo(() => {
    return min1890
      ? '15%'
      : min1356
      ? '23%'
      : min1280
      ? '32%'
      : min1120
      ? '23%'
      : min860
      ? '32%'
      : min590
      ? '48%'
      : '100%'
  }, [min1890, min1356, min1280, min1120, min860, min590])
  // returns
  return (
    <>
      {props.loading ? (
        <Skeleton
          variant="rectangular"
          style={{
            width: '264px',
            height: '144px',
            minWidth: '264px',
            borderRadius: 8,
            flexBasis: flexBasis,
          }}
        />
      ) : (
        <Box
          className={clsx({
            [classes.card]: true,
            [classes.clickCard]: props.clickCard && !props.disabled,
          })}
          key={props.cardKey}
          onClick={() => {
            if (!props.disabled) {
              props.onClick()
            }
          }}
          sx={{
            width: '264px',
            minWidth: '264px',
            flexBasis: flexBasis,
            cursor: props.disabled ? 'not-allowed' : '',
            opacity: props.disabled ? 0.55 : 1,
            ...props.sx,
          }}
        >
          <Box position="relative" zIndex={1}>
            <Box className={classes.title}>
              <Box>
                {props.title}
                {props.disabled ? (
                  <Tooltip
                    title={`The ${
                      props.title
                    } reporting is currently under maintenance${
                      props.untilTime ? ' until ' + props.untilTime : ''
                    }.`}
                    arrow
                    placement="top"
                    disableInteractive
                  >
                    <img
                      src={Icons.warningIcon}
                      alt="icon"
                      style={{
                        width: '14px',
                        verticalAlign: 'middle',
                        marginLeft: '6px',
                        marginTop: '-2px',
                      }}
                    />
                  </Tooltip>
                ) : (
                  <>
                    {props.tooltip && (
                      <Tooltip
                        title={props.tooltip}
                        arrow
                        placement="top"
                        disableInteractive
                      >
                        <InfoOutlinedIcon
                          style={{
                            fontSize: '16px',
                            verticalAlign: 'middle',
                            margin: '-2px 0 0 4px',
                            color: theme.palette.text.secondary,
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
              {!props.onlyOneGame && !props.hideTrend && !props.disabled && (
                <Box>
                  {!!props.tendency && (
                    <Box>
                      <Box
                        className={
                          props.tendency === 'down'
                            ? classes.topRightChipDown
                            : classes.topRightChipUp
                        }
                      >
                        <Chip
                          icon={
                            <SVGIcon.UpRightArrow
                              style={
                                props.tendency === 'down'
                                  ? {
                                      width: '12px',
                                      height: '12px',
                                      transform: 'rotate(90deg)',
                                    }
                                  : {
                                      width: '12px',
                                      height: '12px',
                                    }
                              }
                            />
                          }
                          label={props.changeRate}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box className={classes.num}>
              {props.disabled ? '-' : !props.onlyOneGame ? props.num : ''}
            </Box>
            {props.disabled ? (
              ''
            ) : props.showChart ? (
              <Box className={classes.chart}>
                <CardChartView
                  id={props.cardKey + 'key'}
                  type={props.chartValue}
                />
              </Box>
            ) : (
              <Box className={classes.bottomTip}>
                {props.onlyOneGame
                  ? 'Select one game from the filter to view this metric'
                  : !props.hideTrend && (
                      <>
                        {props.tendency === 'down' ? (
                          <Box className={classes.bottomGoDown}>
                            <Box>
                              <SVGIcon.GoDownHover
                                style={{
                                  width: '10px',
                                  height: '11px',
                                  transform: 'translateY(1px)',
                                  marginRight: '2px',
                                }}
                              />
                            </Box>
                            <Box
                              id="middle_chart_bottom"
                              style={{ marginRight: '6px' }}
                            >
                              {props.absoluteValue}
                            </Box>
                          </Box>
                        ) : (
                          <Box className={classes.bottomGoUp}>
                            <Box>
                              <SVGIcon.GoUpHover
                                style={{
                                  width: '10px',
                                  height: '11px',
                                  transform: 'translateY(1px)',
                                  marginRight: '2px',
                                }}
                              />
                            </Box>
                            <Box
                              id="middle_chart_bottom"
                              style={{ marginRight: '6px' }}
                            >
                              {props.absoluteValue}
                            </Box>
                          </Box>
                        )}
                        <Box id="middle_chart_bottom">
                          since previous period
                        </Box>
                      </>
                    )}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default CardView
