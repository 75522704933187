import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  Dispatch,
} from 'react'
import { PerformanceContext } from './index'
import { GlobalContext } from '../global'

type Props = {
  data: any
  gameListObject: any
  countryListObject: any
  networkObject: any
  chartType: string
  middleChartLoading: boolean
  holdData: any
  chartList: any
  checkedChartLine: any
  view: string
  countryIsFilter: boolean
  newChartType: string
  commentsEvent: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  data: {},
  holdData: {},
  gameListObject: {},
  countryListObject: {},
  networkObject: {},
  chartType: 'revenue',
  middleChartLoading: false,
  chartList: [
    {
      label: 'All',
      value: 'All',
      color: '#FF8E00',
    },
  ],
  checkedChartLine: [],
  view: 'all',
  countryIsFilter: false,
  newChartType: 'Graph',
  commentsEvent: {},
}
const type = {
  interstitial: 'Interstitial',
  banner: 'Banner',
  reward_video: 'Rewarded Video',
  nativeads: 'Native Ads',
  openads: 'App Open',
  rewardinter: 'Rewarded Interstitial',
  other: 'Other',
  android: 'Android',
  all: 'All',
}
const colorList = ['#FF8E00', '#90cc75', '#f07474', '#5a70b5']
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'gameListObject':
    case 'countryListObject':
    case 'chartType':
    case 'middleChartLoading':
    case 'holdData':
    case 'checkedChartLine':
    case 'view':
    case 'countryIsFilter':
    case 'networkObject':
    case 'newChartType':
    case 'commentsEvent':
      return { ...prevState, [action.type]: action.payload }
    case 'data':
      const allData = action.payload.data
      const keys = Object.keys(allData)
      if (!keys.length) {
        return {
          ...prevState,
          data: {},
        }
      }
      const labels = allData[keys[0]][prevState.chartType].days
      const echartData: any = []
      let chartList: any = []
      const checkedChartLine: any = []
      keys.forEach((item: any, i: number) => {
        const color: string =
          colorList[i] ||
          prevState.countryListObject[item]?.color ||
          prevState.gameListObject[item]?.color ||
          '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
        const label =
          prevState.gameListObject[item]?.name ||
          prevState.countryListObject[item]?.name ||
          prevState.networkObject[item] ||
          type[item] ||
          item
        echartData.push({
          name: label,
          type: 'line',
          smooth: true,
          itemStyle: { color: color },
          data: allData[item][prevState.chartType].values,
        })
        chartList.push({
          label,
          value: label,
          color,
          num: allData[item][prevState.chartType]?.summary || 0,
        })
        checkedChartLine.push(label)
      })
      chartList = chartList.sort((a: any, b: any) => b.num - a.num)
      return {
        ...prevState,
        data: {
          days: labels,
          data: echartData,
        },
        chartList,
        checkedChartLine,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
}

export const MiddleChartContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
})

export const MiddleChartContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: performanceState } = useContext(PerformanceContext)
  const { state: globalState } = useContext(GlobalContext)

  // effects
  useEffect(() => {
    dispatch({ type: 'view', payload: performanceState.view })
  }, [performanceState.view])
  useEffect(() => {
    dispatch({
      type: 'countryIsFilter',
      payload: performanceState.countryIsFilter,
    })
  }, [performanceState.countryIsFilter])
  useEffect(() => {
    dispatch({ type: 'commentsEvent', payload: performanceState.commentsEvent })
  }, [performanceState.commentsEvent])
  useEffect(() => {
    dispatch({
      type: 'networkObject',
      payload: globalState.networkObject,
    })
  }, [globalState.networkObject])

  useEffect(() => {
    dispatch({ type: 'chartType', payload: performanceState.chartType })
    if (Object.keys(state.holdData).length) {
      dispatch({ type: 'data', payload: state.holdData })
    }
    // eslint-disable-next-line
  }, [performanceState.chartType])
  useEffect(() => {
    dispatch({
      type: 'gameListObject',
      payload: performanceState.gameListObject,
    })
  }, [performanceState.gameListObject])
  useEffect(() => {
    dispatch({
      type: 'countryListObject',
      payload: performanceState.countryListObject,
    })
  }, [performanceState.countryListObject])

  useEffect(() => {
    dispatch({
      type: 'holdData',
      payload: {
        data: performanceState.data,
      },
    })
    dispatch({
      type: 'data',
      payload: {
        data: performanceState.data,
      },
    })
    // eslint-disable-next-line
  }, [performanceState.data])
  useEffect(() => {
    dispatch({
      type: 'middleChartLoading',
      payload: performanceState.dataLoading,
    })
  }, [performanceState.dataLoading])

  // returns
  return (
    <MiddleChartContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </MiddleChartContext.Provider>
  )
}
