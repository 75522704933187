import { useEffect, useRef, useContext } from 'react'
import { Chart, registerables } from 'chart.js'
import { DashContext } from 'src/context/dashboard'

const View = (props: any) => {
  const chart = useRef<any>()
  const { state } = useContext(DashContext)
  const getData = () => {
    const type = props.type || 'revenue'
    const allData = state.cardLineChart
    const keys = Object.keys(allData)
    if (!keys.length) {
      return {}
    }
    const labels = allData[keys[0]][type].days
    const datasets: any = []
    keys.forEach((item: any, i: number) => {
      datasets.push({
        label: 'all',
        data: allData[item][type].values,
        pointRadius: 0,
        borderColor: 'rgba(255, 255, 255, 0.5)',
        tension: 0.2,
        fill: {
          target: { value: 0 },
          above: '#C2DDFF', // Area will be red above the origin
        },
      })
    })
    const data = {
      labels,
      datasets,
    }
    return data
  }
  // effects
  useEffect(() => {
    if (!chart.current) {
      const chartEl: any = document.getElementById('cardChart' + props.id)
      if (chartEl) {
        Chart.register(...registerables)
        chart.current = new Chart(chartEl.getContext('2d'), {
          type: 'line',
          data: {
            labels: [1, 2, 3, 4, 5],
            datasets: [
              {
                label: 'all',
                data: [0, 0, 0, 0, 0],
                pointRadius: 0,
                borderColor: 'rgba(255, 255, 255, 0.5)',
                tension: 0.2,
                fill: {
                  target: { value: 0 },
                  above: '#C2DDFF', // Area will be red above the origin
                },
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            scales: {
              y: {
                min: 0,
                stacked: false,
                grid: {
                  drawOnChartArea: false,
                },
                display: false,
              },
              x: {
                stacked: true,
                grid: {
                  drawOnChartArea: false,
                },
                display: false,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          },
        })
      }
    }
    if (!state.cardLineChart) return
    chart.current.data = getData()
    chart.current?.update()
    return function willUnmount() {
      if (chart.current) {
        chart.current.clear()
        chart.current.destroy()
        chart.current = undefined
      }
    }
    // eslint-disable-next-line
  }, [state.cardLineChart])
  // returns
  return <canvas id={'cardChart' + props.id} width="100%" height="50px" />
}

export default View
