import { Box, Skeleton, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useState, useEffect, useContext, useCallback } from 'react'

import { FinanceContextProvider } from 'src/context/finance'
import { tipaltiConfig } from 'src/utils/tipaltiConfiguration'
import { GlobalContext } from 'src/context/global'
import Crypto from 'crypto'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
      },
      top: {
        padding: '27px 30px',
        fontSize: '27px',
        fontWeight: 900,
        color: theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    }
  })

const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [iframeHeight, setIframeHeight] = useState(0)
  const [loadComplete, setLoadComplete] = useState(false)

  const {
    state: { userInfo },
  } = useContext(GlobalContext)

  const initIframeHeight = () => {
    function tipaltiHandler(evt: any) {
      if (evt.data && evt.data.TipaltiIframeInfo) {
        const { height } = evt.data.TipaltiIframeInfo
        setIframeHeight(height)
        setLoadComplete(true)
      }
    }
    window.addEventListener('message', tipaltiHandler, false)
  }

  const EncryptFullQueryString = (queryString: any, privateKey: any) => {
    const hMac = Crypto.createHmac('sha256', privateKey)
    hMac.update(queryString)
    return hMac.digest('hex')
  }

  const dashboard = useCallback((idap: any) => {
    let url: any = tipaltiConfig.iframe
    const payer: any = tipaltiConfig.payer
    const nowTime: any = Date.now()
    const ts = parseInt(String(nowTime / 1000), 10)
    const queryString: any = 'idap=' + idap + '&payer=' + payer + '&ts=' + ts
    url = url.replace('{domain}', tipaltiConfig.domain)
    url = url.replace('{path}', tipaltiConfig.path.history)
    url = url.replace('{idap}', idap)
    url = url.replace('{payer}', payer)
    url = url.replace('{ts}', ts)
    url = url.replace(
      '{hashkey}',
      EncryptFullQueryString(queryString, tipaltiConfig.masterKey)
    )
    return url
  }, [])

  const initIFrame = useCallback(() => {
    if (userInfo?.owner_key) {
      const iframe: any = document.getElementById('tipalti_history_iframe')
      iframe.src = dashboard(userInfo.owner_key)
    }
  }, [userInfo, dashboard])

  useEffect(() => {
    initIFrame()
  }, [initIFrame])
  initIframeHeight()

  // returns
  return (
    <FinanceContextProvider>
      <Box className={classes.root}>
        <Box>
          {!loadComplete && (
            <Box>
              <Box
                sx={{
                  fontSize: '20px',
                  fontWeight: 500,
                  color:
                    theme.palette.mode === 'light'
                      ? 'rgba(0, 0, 0, 0.87)'
                      : '#FFFFFF',
                }}
              >
                Payments History
              </Box>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
                <Box
                  key={item}
                  sx={{ height: '62px', display: 'flex', alignItems: 'center' }}
                >
                  {[1, 2, 3, 4, 5].map(element => (
                    <Box flex={1} key={'element' + element}>
                      <Skeleton variant="text" width="150px" />
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          )}
          <iframe
            title="tipalti profile"
            id="tipalti_history_iframe"
            width="100%"
            height={iframeHeight}
            frameBorder="0"
          />
        </Box>
      </Box>
    </FinanceContextProvider>
  )
}

export default View
