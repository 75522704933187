import { createContext, useReducer, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiResponse } from 'src/api'
import {
  getAppListApi,
  editGameApi,
  getAppDetailApi,
  setGameProcessTriggerApi,
  getUpsellListApi,
} from 'src/api/game'
import { GlobalContext } from './global'
import { GlobalStoreContext } from './globalStore'
import { getQueryParams } from 'src/utils'

type Props = {
  review: string
  platform: string
  masVersion: string
  name: string
  data: any
  loading: boolean
  page: number
  page_size: number
  total: number
  editGameLoading: boolean
  progressButtonLoading: boolean
  firstLoad: boolean
  upsellAppData: any
  upsellAppDataLoading: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  review: 'all',
  platform: 'all',
  masVersion: '',
  name: '',
  loading: false,
  data: [],
  page: 1,
  page_size: 20,
  total: 0,
  editGameLoading: false,
  progressButtonLoading: false,
  firstLoad: true,
  upsellAppData: [],
  upsellAppDataLoading: false,
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'gameList':
      return { ...prevState, data: action.payload }
    case 'review':
    case 'platform':
    case 'masVersion':
    case 'name':
      return { ...prevState, [action.type]: action.payload, page: 1, total: 0 }
    case 'page':
      return {
        ...prevState,
        page: action.payload.page,
        page_size: action.payload.page_size,
      }
    case 'filter':
      return {
        ...prevState,
        page: action.payload.page,
        page_size: action.payload.page_size,
        name: action.payload.name,
        review: action.payload.review,
        platform: action.payload.platform,
        masVersion: action.payload.masVersion,
      }
    case 'page_size':
    case 'total':
    case 'loading':
    case 'editGameLoading':
    case 'progressButtonLoading':
    case 'firstLoad':
    case 'upsellAppData':
    case 'upsellAppDataLoading':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type editData = {
  app_key: string
  url: string | null
  ads_format: string[]
  nativeads_size?: string
  test_ads_status: any
}
type Context = {
  state: Props
  dispatch: (value: Action) => void
  progressButton: (
    key: string,
    app_key: string,
    index: number,
    status?: any
  ) => void
  progressSdkIconButton: (app_key: string, index: number) => void
  editGame: (data: editData, index: number) => void
  updateListForOne: (app_key: string, index: number, func?: any) => void
}
export const GameContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  progressButton: (
    key: string,
    app_key: string,
    index: number,
    status?: any
  ) => {},
  progressSdkIconButton: (app_key: string, index: number) => {},
  editGame: (data: editData, index: number) => {},
  updateListForOne: (app_key: string, index: number, func?: any) => {},
})

export const GameContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, customAlert } = useContext(GlobalContext)
  const { state: globalStoreState } = useContext(GlobalStoreContext)
  const [t] = useTranslation()

  const status = {
    not_live: 0,
    live: 1,
    'pre-release': 2,
    all: null,
  }

  // effects
  useEffect(() => {
    if (
      globalStoreState.gamePageFromDetail &&
      globalStoreState.gamePagePagination?.page &&
      globalStoreState.gamePagePagination?.page_size &&
      (globalStoreState.gamePagePagination.page !== 1 ||
        globalStoreState.gamePagePagination.page_size !== 20 ||
        globalStoreState.gamePagePagination.name !== '' ||
        globalStoreState.gamePagePagination.review !== 'all' ||
        globalStoreState.gamePagePagination.platform !== 'all' ||
        globalStoreState.gamePagePagination.masVersion !== '')
    ) {
      return
    }
    if (location.search) {
      const temp: any = getQueryParams(location.search)
      if (temp.name) {
        history.pushState('', '', location.origin + location.pathname)
        // 防止首次通过弹框跳转进入页面调用2次api
        if (state.firstLoad) {
          dispatch({ type: 'firstLoad', payload: false })
          return
        }
      }
    }
    // 防止先进入game页面再通过弹框跳转不会调用api
    dispatch({ type: 'firstLoad', payload: false })
    dispatch({ type: 'loading', payload: true })
    const params = {
      page: state.page,
      app_name: state.name || null,
      page_size: state.page_size,
      app_store_status: status[state.review],
      platform: state.platform === 'all' ? null : state.platform,
      latest_sdk_version: state.masVersion || null,
      extra_flag: true,
    }

    getAppListApi(params)
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'total', payload: data.total })
        dispatch({ type: 'gameList', payload: data.data })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
    // eslint-disable-next-line
  }, [
    state.review,
    state.platform,
    state.masVersion,
    state.name,
    state.page,
    state.page_size,
  ])
  const updateListForOne = (app_key: string, index: number, func?: any) => {
    getAppDetailApi(app_key, { extra_flag: true })
      .then((response: any) => {
        const list = state.data
        list[index] = response.data.data
        dispatch({
          type: 'gameList',
          payload: list,
        })
      })
      .catch(errorCatch)
      .finally(() => {
        func && func()
      })
  }
  const progressButton = (
    key: string,
    app_key: string,
    index: number,
    status?: any
  ) => {
    if (state.progressButtonLoading) return
    const newStatus = status === undefined || status === null ? 1 : status
    dispatch({ type: 'progressButtonLoading', payload: true })
    setGameProcessTriggerApi(app_key, { [key]: newStatus })
      .then(() => {
        // 获取此应用最新数据并替换原列表中数据
        updateListForOne(app_key, index, () => {
          dispatch({ type: 'progressButtonLoading', payload: false })
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        dispatch({ type: 'progressButtonLoading', payload: false })
      })
  }
  const progressSdkIconButton = (app_key: string, index: number) => {
    const data = state.data
    data[index].game_process_status.sdk_integrate_checklist = 0
    dispatch({
      type: 'gameList',
      payload: data,
    })
    setGameProcessTriggerApi(app_key, { sdk_integrate_checklist: 0 }).catch(
      errorCatch
    )
  }
  const editGame = (data: editData, index: number) => {
    if (state.editGameLoading) return
    dispatch({ type: 'editGameLoading', payload: true })
    editGameApi(data)
      .then(res => {
        if (res.data.success) {
          // 获取此应用最新数据并替换原列表中数据
          updateListForOne(data.app_key, index, () => {
            setTimeout(() => {
              dispatch({ type: 'editGameLoading', payload: false })
            }, 500)
          })
        } else {
          customAlert('error', t(res.data.code || 'app.unknow'))
          setTimeout(() => {
            dispatch({ type: 'editGameLoading', payload: false })
          }, 500)
        }
      })
      .catch((err: any) => {
        errorCatch(err)
        setTimeout(() => {
          dispatch({ type: 'editGameLoading', payload: false })
        }, 500)
      })
  }
  const getUpsellList = () => {
    dispatch({
      type: 'upsellAppDataLoading',
      payload: true,
    })
    getUpsellListApi()
      .then((res: any) => {
        dispatch({
          type: 'upsellAppData',
          payload: res.data.data,
        })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: 'upsellAppDataLoading',
          payload: false,
        })
      })
  }
  useEffect(() => {
    getUpsellList()
    // eslint-disable-next-line
  }, [])
  // returns
  return (
    <GameContext.Provider
      value={{
        state,
        dispatch,
        progressButton,
        progressSdkIconButton,
        editGame,
        updateListForOne,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}
