import { useContext, useState, useEffect, useMemo } from 'react'
import { Box, TextField, useTheme, Theme } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { GlobalStoreContext } from 'src/context/globalStore'
import { GlobalContext } from 'src/context/global'
import { TopTitle, StylePaper, NoDataView } from 'src/components'
import MessageContentView from './content'
import MobileMessageContentView from './mobileContent'
import { getQueryParams } from 'src/utils'
import clsx from 'clsx'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      search: {
        width: '254px',
        '& .MuiOutlinedInput-root': {
          height: '40px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.custom.paperBorder} !important`,
          boxShadow:
            theme.palette.mode === 'light'
              ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
              : 'none',
          borderRadius: '6px',
        },
      },
      icon: {
        width: '22px !important',
        height: '18px !important',
      },
      title: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '16px',
      },
      tab: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 500,
        height: '30px',
        cursor: 'pointer',
        padding: '0 12px',
      },
      checked: {
        background:
          theme.palette.mode === 'light'
            ? '#D2EAFE'
            : 'rgba(210, 234, 254, 0.3)',
        borderRadius: '10px',
      },
    }
  })
const Notifications = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, markAsRead } = useContext(GlobalStoreContext)
  const { state: globalState } = useContext(GlobalContext)
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const [checkedTab, setCheckedTab] = useState<string>('All')
  const list = useMemo(() => {
    let temp = state.notificationList
    if (checkedTab !== 'All') {
      temp = state.notificationList.filter(item => item.category === checkedTab)
    }
    return temp.filter(item => {
      return item.title.includes(searchValue)
    })
  }, [state.notificationList, checkedTab, searchValue])
  const ContentView = useMemo(() => {
    if (globalState.isMobile) {
      return MobileMessageContentView
    }
    return MessageContentView
  }, [globalState.isMobile])
  // constants
  const listArr = ['', '', '', '', '', '', '', '', '', '']

  // handler
  const back = () => {
    navigate(-1)
  }
  const valueChange = (e: any) => {
    setSearchValue(e.target.value)
  }
  const clickNotification = (item: any) => {
    if (!item.is_read && globalState.accountAuthority.notification_page === 2) {
      markAsRead(item.id)
    }
  }
  useEffect(() => {
    const locationSearch = getQueryParams(location.href)
    if (!locationSearch) {
      return
    }
    if (locationSearch.type) {
      const typeTemp: any =
        decodeURIComponent(String(locationSearch.type)) || 'All'
      setCheckedTab(typeTemp)
    }
    history.pushState('', '', location.origin + location.pathname)
    // eslint-disable-next-line
  }, [location.href])

  // returns
  return (
    <Box>
      <TopTitle title="Notifications" showBack={true} goBack={back} />
      <StylePaper
        width="100%"
        boxSizing="border-box"
        padding="24px 16px"
        marginTop="24px"
      >
        <Box className={classes.title}>
          <Box display="flex" gap="6px" flexWrap="wrap" paddingTop="5px">
            {['All', 'Critical Updates', 'General', 'Optimizations'].map(
              (item: string) => (
                <Box
                  className={clsx({
                    [classes.tab]: true,
                    [classes.checked]: checkedTab === item,
                  })}
                  key={item}
                  onClick={() => {
                    setCheckedTab(item)
                  }}
                >
                  {item}
                </Box>
              )
            )}
          </Box>
          <TextField
            className={classes.search}
            InputProps={{
              startAdornment: <SearchIcon className={classes.icon} />,
            }}
            placeholder="Search by title"
            onChange={valueChange}
            value={searchValue}
            variant="outlined"
            style={
              globalState.isMobile
                ? {
                    width: '100%',
                  }
                : {}
            }
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '1px',
            marginTop: '24px',
            background: theme.palette.mode === 'light' ? '#E1E5E7' : '#495263',
          }}
        />
        {state.notificationLoading ? (
          listArr.map((item, i) => <ContentView loading={true} key={i} />)
        ) : list.length ? (
          <>
            {list.map((item: any, i: number) => (
              <ContentView
                {...item}
                key={i + 'key' + item.id}
                clickContent={() => {
                  clickNotification(item)
                }}
              />
            ))}
          </>
        ) : (
          <NoDataView noDataDesc="0 notifications to see here" />
        )}
      </StylePaper>
    </Box>
  )
}

export default Notifications
