const SVG = (props: any) => {
  const width = props.width || '32'
  const height = props.height || '32'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.956 25.0791L18.0889 4.12211C17.3008 2.73029 15.2956 2.73029 14.5075 4.1221L2.64034 25.0791C1.86353 26.451 2.85453 28.151 4.43104 28.151H28.1653C29.7419 28.151 30.7329 26.451 29.956 25.0791ZM20.0766 2.99653C18.4136 0.0597541 14.1827 0.0597495 12.5198 2.99652L0.652608 23.9536C-0.986504 26.8482 1.10455 30.4353 4.43104 30.4353H28.1653C31.4918 30.4353 33.5829 26.8482 31.9438 23.9536L20.0766 2.99653Z"
        fill="#FF8E00"
      />
      <path
        d="M17.9152 22.9752C17.9152 23.8685 17.191 24.5927 16.2977 24.5927C15.4044 24.5927 14.6802 23.8685 14.6802 22.9752C14.6802 22.0819 15.4044 21.3577 16.2977 21.3577C17.191 21.3577 17.9152 22.0819 17.9152 22.9752Z"
        fill="#FF8E00"
      />
      <path
        d="M14.7772 10.2342C14.7246 9.35862 15.4205 8.61963 16.2977 8.61963V8.61963C17.1749 8.61963 17.8709 9.35862 17.8183 10.2342L17.3621 17.8277C17.3283 18.3908 16.8618 18.8301 16.2977 18.8301V18.8301C15.7337 18.8301 15.2672 18.3908 15.2334 17.8277L14.7772 10.2342Z"
        fill="#FF8E00"
      />
    </svg>
  )
}

export default SVG
