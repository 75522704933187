const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49984 1.83301H16.4998C17.5124 1.83301 18.3332 2.65382 18.3332 3.66634V13.5297C18.3325 14.2588 18.0424 14.9578 17.5265 15.473L13.6398 19.3597C13.1246 19.8756 12.4256 20.1657 11.6965 20.1663H5.49984C4.48731 20.1663 3.6665 19.3455 3.6665 18.333V3.66634C3.6665 2.65382 4.48731 1.83301 5.49984 1.83301ZM11.9165 12.833H16.4998V3.66634H5.49984V18.333H10.9998V13.7497C10.9998 13.2434 11.4102 12.833 11.9165 12.833ZM7.7915 7.33301H14.2082C14.4613 7.33301 14.6665 7.12781 14.6665 6.87468V5.95801C14.6665 5.70488 14.4613 5.49968 14.2082 5.49968H7.7915C7.53837 5.49968 7.33317 5.70488 7.33317 5.95801V6.87468C7.33317 7.12781 7.53837 7.33301 7.7915 7.33301ZM11.4582 10.9997H7.7915C7.53837 10.9997 7.33317 10.7945 7.33317 10.5413V9.62467C7.33317 9.37154 7.53837 9.16634 7.7915 9.16634H11.4582C11.7113 9.16634 11.9165 9.37154 11.9165 9.62467V10.5413C11.9165 10.7945 11.7113 10.9997 11.4582 10.9997Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
