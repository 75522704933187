import { TextField, Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import NumberFormat from 'react-number-format'
import { useEffect, useState, ChangeEvent } from 'react'
import { multiplication } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      formSearchList: {
        textAlign: 'left',
      },
      label: {
        boxSizing: 'border-box',
        fontSize: '12px',
        color: theme.palette.custom.inputLabel,
        margin: '24px 0 8px',
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
    }
  })
interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}
function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props
  const decimalScale =
    props.name === 'dau' || props.name === 'impressions' ? 0 : 4
  const prefix =
    props.name === 'arp_dau' ||
    props.name === 'ecpm' ||
    props.name === 'revenue'
      ? '$'
      : ''
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalScale={decimalScale}
      thousandSeparator={props.name !== 'impressions' && props.name !== 'dau'}
      isNumericString
      prefix={prefix}
    />
  )
}

interface AdTypeFormProps {
  preMetricsLoading: boolean
  revenue: string | number
  ecpm: string | number
  impressions: string | number
  disabled: boolean
  onChange: (
    impressions: string | number,
    ecpm: string | number,
    revenue: string | number
  ) => void
  onDisabledButtonChange: (disabled: boolean) => void
}

const AdTypeFormView = (props: AdTypeFormProps) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [revenue, setRevenue] = useState<string | number>('')
  const [ecpm, setEcpm] = useState<string | number>('')
  const [impressions, setImpressions] = useState<string | number>('')
  const [showError, setShowError] = useState<boolean>(false)
  const [errorDetail, setErrorDetail] = useState<string>('')

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'revenue') {
      setRevenue(value)
    } else if (name === 'ecpm') {
      setEcpm(value)
    } else if (name === 'impressions') {
      setImpressions(value)
    }
  }
  const blurVerify = () => {
    const calculatedRevenueTemp = multiplication(
      multiplication(Number(ecpm), Number(impressions)),
      0.001
    )
    if (Number(ecpm) > 1000) {
      setErrorDetail(
        'eCPM cannot be greater than 1000. Please double-check your eCPM'
      )
      setShowError(true)
      props.onDisabledButtonChange(true)
    } else if (calculatedRevenueTemp === 0 && Number(revenue) > 100) {
      setErrorDetail(
        'Avg. daily revenue is incorrect as the eCPM is too low. Please enter correct avg. daily revenue.'
      )
      setShowError(true)
      props.onDisabledButtonChange(true)
    } else if (
      revenue &&
      Number(calculatedRevenueTemp) > 0 &&
      (Number(revenue) > multiplication(Number(calculatedRevenueTemp), 1.5) ||
        Number(revenue) < multiplication(Number(calculatedRevenueTemp), 0.5))
    ) {
      setErrorDetail(
        `With your avg. eCPM and avg. daily impressions your avg. daily revenue should be between ${multiplication(
          Number(calculatedRevenueTemp),
          0.5
        )} - ${multiplication(
          Number(calculatedRevenueTemp),
          1.5
        )}. Please enter correct avg. daily revenue.`
      )
      setShowError(true)
      props.onDisabledButtonChange(true)
    } else if (
      Number(revenue) > 0 &&
      (impressions === 0 || impressions === '0')
    ) {
      setErrorDetail(
        'Revenue is greater than 0. Hence, Impressions cannot be 0.'
      )
      setShowError(true)
      props.onDisabledButtonChange(true)
    } else {
      setShowError(false)
      props.onDisabledButtonChange(false)
    }
  }
  const inputBlur = (e: any) => {
    blurVerify()
    const { name } = e.target
    if (name === 'revenue') {
      props.onChange(impressions, ecpm, revenue)
    } else if ((ecpm || ecpm === 0) && (impressions || impressions === 0)) {
      const calculatedRevenueTemp = multiplication(
        multiplication(Number(ecpm), Number(impressions)),
        0.001
      )
      if (!revenue && revenue !== 0) {
        setRevenue(calculatedRevenueTemp)
        props.onChange(impressions, ecpm, calculatedRevenueTemp)
      } else {
        props.onChange(impressions, ecpm, revenue)
      }
    }
  }

  const formatNum = (num: string | number) => {
    return num === 0 ? 0 : num || ''
  }
  useEffect(() => {
    setRevenue(formatNum(props.revenue))
    setEcpm(formatNum(props.ecpm))
    setImpressions(formatNum(props.impressions))
  }, [props.revenue, props.ecpm, props.impressions])

  return (
    <Box>
      <Box display="flex" alignItems="flex-end">
        <Box className={classes.label} margin="4px 0 8px" flex={1}>
          Avg. Daily Impressions
        </Box>
        <Box className={classes.label} margin="4px 16px 8px" flex={1}>
          Avg. eCPM
        </Box>
        <Box className={classes.label} margin="4px 0 8px" flex={1}>
          Avg. Daily Revenue
        </Box>
      </Box>
      <Box display="flex">
        <Box className={classes.formSearchList} flex={1}>
          <Box width="100%">
            {props.preMetricsLoading ? (
              <Skeleton
                variant="rectangular"
                className={classes.formInputSearch}
                height={40}
              />
            ) : (
              <TextField
                className={classes.formInputSearch}
                name="impressions"
                placeholder="Impressions"
                variant="outlined"
                value={impressions}
                onChange={inputChange}
                onBlur={inputBlur}
                disabled={props.disabled}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.formSearchList} margin="0 16px" flex={1}>
          <Box width="100%">
            {props.preMetricsLoading ? (
              <Skeleton
                variant="rectangular"
                className={classes.formInputSearch}
                height={40}
              />
            ) : (
              <TextField
                className={classes.formInputSearch}
                name="ecpm"
                placeholder="$"
                variant="outlined"
                value={ecpm}
                onChange={inputChange}
                onBlur={inputBlur}
                disabled={props.disabled || (!impressions && impressions !== 0)}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.formSearchList} flex={1}>
          <Box width="100%">
            {props.preMetricsLoading ? (
              <Skeleton
                variant="rectangular"
                className={classes.formInputSearch}
                height={40}
              />
            ) : (
              <TextField
                className={classes.formInputSearch}
                name="revenue"
                placeholder="$"
                variant="outlined"
                value={revenue}
                disabled={
                  props.disabled ||
                  (!impressions && impressions !== 0) ||
                  (!ecpm && ecpm !== 0)
                }
                onChange={inputChange}
                onBlur={inputBlur}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      {showError && (
        <Box
          sx={{ margin: '6px 0', fontSize: '12px', color: 'rgb(211, 47, 47)' }}
        >
          {errorDetail}
        </Box>
      )}
    </Box>
  )
}

export default AdTypeFormView
