const SVG = (props: any) => {
  const width = props.width || '14'
  const height = props.height || '25'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9932 0H2.80217C1.35664 0.00163914 0.185261 1.17301 0.183838 2.61854V21.9994C0.185261 23.4449 1.35664 24.6163 2.80217 24.6177H10.9932C12.4387 24.6163 13.6101 23.4449 13.6117 21.9994V2.61854C13.6101 1.17301 12.4387 0.00163914 10.9932 0ZM12.4926 21.9994C12.4918 22.8271 11.8209 23.498 10.9932 23.4988H2.80217C1.9744 23.498 1.30356 22.8271 1.30275 21.9994V2.61854C1.30356 1.79056 1.9744 1.11974 2.80217 1.11892H10.9932C11.8209 1.11974 12.4918 1.79056 12.4926 2.61854V21.9994Z"
        fill={color}
      />
      <path
        d="M8.57669 2.23828H5.21971C4.91076 2.23828 4.66016 2.48866 4.66016 2.79764C4.66016 3.10662 4.91076 3.3572 5.21971 3.3572H8.57669C8.88568 3.3572 9.13605 3.10662 9.13605 2.79764C9.13605 2.48866 8.88568 2.23828 8.57669 2.23828Z"
        fill={color}
      />
      <path
        d="M8.01687 21.2615C8.01687 21.8797 7.5159 22.3806 6.89796 22.3806C6.27978 22.3806 5.77881 21.8797 5.77881 21.2615C5.77881 20.6436 6.27978 20.1426 6.89796 20.1426C7.5159 20.1426 8.01687 20.6436 8.01687 21.2615Z"
        fill={color}
      />
      <path
        d="M10.9817 4.78125H2.84901C2.63657 4.78125 2.46436 4.95346 2.46436 5.1659V18.1342C2.46436 18.3466 2.63657 18.5189 2.84901 18.5189H10.9817C11.1941 18.5189 11.3663 18.3466 11.3663 18.1342V5.1659C11.3663 4.95346 11.1941 4.78125 10.9817 4.78125Z"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        d="M4.86369 7.50977C4.58339 7.50977 4.35083 7.74233 4.35083 8.02262V11.0998C4.35083 11.3802 4.58339 11.6127 4.86369 11.6127H8.96666C9.24696 11.6127 9.47952 11.3802 9.47952 11.0998V8.02262C9.47952 7.74233 9.24696 7.50977 8.96666 7.50977H4.86369ZM4.86369 8.02262H8.96666V11.0998H4.86369V8.02262ZM7.55626 9.30482L6.65875 10.3305L6.01765 9.68948L5.31946 10.587H8.51789L7.55626 9.30482Z"
        fill={color}
      />
      <path
        d="M8.72316 15.0012C8.72316 14.0028 7.91375 13.1934 6.91529 13.1934C5.91683 13.1934 5.10742 14.0028 5.10742 15.0012C5.10742 15.9997 5.91683 16.8091 6.91529 16.8091C7.91375 16.8091 8.72316 15.9997 8.72316 15.0012Z"
        stroke={color}
        strokeWidth="0.525"
      />
      <path
        d="M6.91526 16.3489L5.63306 14.8103L6.01771 14.041H7.81277L8.19742 14.8103L6.91526 16.3489ZM6.55622 14.7142H7.27425L7.03385 14.2333H6.79663L6.55622 14.7142ZM6.81909 15.9354V14.9065H5.96324L6.81909 15.9354ZM7.01142 15.9354L7.86727 14.9065H7.01142V15.9354ZM7.48901 14.7142H7.92497L7.68456 14.2333H7.2486L7.48901 14.7142ZM5.90554 14.7142H6.34147L6.58188 14.2333H6.14595L5.90554 14.7142Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
