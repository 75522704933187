import { useState, useContext, useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  useTheme,
  Theme,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { GameAddContext } from 'src/context/gameAdd'
import { GlobalContext } from 'src/context/global'
import { LoadingComponent, TopTitle, StylePaper } from 'src/components'
import { PNG } from 'src/images'
import RadioBoxView from './radioBoxView'
import AddLinkStepView from './addLinkStep'
import AdsStepView from './adsStep'
import OrientationStepView from './orientationStep'
import AddedDialogView from './addedDialog'
import { getQueryParams } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        width: '100%',
      },
      title: {
        fontSize: '22px',
        color: theme.palette.text.primary,
        textAlign: 'center',
      },
      platform: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '48px',
      },
      next: {
        textAlign: 'center',
        marginTop: '48px',
      },
      gameInput: {
        width: 'calc(100% - 60px)',
        marginLeft: '30px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '16px',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: '0 none',
        outline: 'none',
        padding: '0',
      },
      backButton: {
        display: 'inline-block',
        width: 124,
        height: 38,
        lineHeight: '40px',
        borderRadius: '6px',
        fontWeight: 600,
        fontSize: 14,
        marginRight: 24,
        cursor: 'pointer',
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.text.secondary}`,
        verticalAlign: 'bottom',
        textAlign: 'center',
      },
      loading: {
        textAlign: 'center',
        fontSize: '20px',
        color: theme.palette.custom?.loading,
        fontWeight: 'bold',
        marginTop: '130px',
      },
      stepIcon: {
        '& .MuiSvgIcon-root': {
          fontSize: '32px',
        },
      },
      completeStepIcon: {
        '& .MuiSvgIcon-root': {
          color: '#27AE60 !important',
        },
      },
    }
  })
const engineList = [
  {
    label: 'Unity',
    value: 'Unity',
  },
  {
    label: 'Android',
    value: 'Android',
  },
  {
    label: 'IOS',
    value: 'iOS',
  },
  {
    label: 'Buildbox',
    value: 'Buildbox',
  },
  {
    label: 'Godot',
    value: 'Godot',
  },
  {
    label: 'Flutter',
    value: 'Flutter',
  },
]
const engineList2 = [
  {
    label: 'React Native',
    value: 'ReactNative',
  },
  {
    label: 'Unreal Engine',
    value: 'UnrealEngine',
  },
  {
    label: 'Cocos',
    value: 'Cocos',
  },
  {
    label: 'Solar2D',
    value: 'Solar2D',
  },
  {
    label: 'Other',
    value: 'other',
  },
]
const Add = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const pageLocation = useLocation()
  const { state, dispatch } = useContext(GameAddContext)
  const { state: globalState } = useContext(GlobalContext)
  const [stepNum, setStepNum] = useState(1)
  const [platform, setPlatform] = useState('')
  const ads: string[] = []
  const [adsFormat, setAdsFormat] = useState(ads)
  const [engine, setEngine] = useState('')
  const [url, setUrl] = useState('')
  const [bannerNonStandard, setBannerNonStandard] = useState(false)
  const [nativeadsSize, setNativeadsSize] = useState('medium')
  const [gameName, setGameName] = useState('')
  const [submiting, setSubmiting] = useState(false)
  const [submited, setSubmited] = useState(false)
  const [updateLink, setUpdateLink] = useState(false)
  const [bundleId, setBundleId] = useState('')
  const [icon, setIcon] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [upsellApp, setUpsellApp] = useState('')
  const [screen, setScreen] = useState<any>('')
  const min1020 = useMediaQuery('(min-width:1020px)')
  const min460 = useMediaQuery('(min-width:460px)')
  const next = () => {
    if (stepNum < 6) {
      if (
        stepNum === 4 &&
        nativeadsSize === 'custom' &&
        engine !== 'iOS' &&
        engine !== 'Android'
      ) {
        setNativeadsSize('medium')
      }
      if (
        stepNum === 3 &&
        ((platform === 'Android' && engine === 'iOS') ||
          (platform === 'iOS' && engine === 'Android') ||
          (platform === 'iOS' && engine === 'Cocos') ||
          (platform === 'iOS' && engine === 'Solar2D'))
      ) {
        setEngine('')
      }
      setStepNum(stepNum + 1)
    } else {
      setSubmiting(true)
      if (updateLink) {
        const data: any = {
          url,
          ads_format: adsFormat,
          engine: engine === 'other' ? null : engine,
          banner_non_standard: isChooseBannerAdType ? bannerNonStandard : false,
          screen: screen,
          extra: {
            from_channel: upsellApp || 'default',
          },
        }
        if (adsFormat.includes('nativeads')) {
          data.nativeads_size = nativeadsSize
        }
        dispatch({
          type: 'live',
          payload: data,
        })
      } else {
        const data: any = {
          name: gameName,
          platform,
          ads_format: adsFormat,
          engine: engine === 'other' ? null : engine,
          banner_non_standard: false,
          screen: screen,
        }
        if (adsFormat.includes('nativeads')) {
          data.nativeads_size = nativeadsSize
        }
        dispatch({
          type: 'nonLive',
          payload: data,
        })
      }
    }
  }

  const confirm = () => {
    setStepNum(4)
  }
  const urlInputChange = (e: any) => {
    setUrl(e.target.value)
  }
  const urlGameChange = (e: any) => {
    setGameName(e.target.value)
  }
  const update = () => {
    if (state.updateLinkLoading || updateLink) return
    dispatch({
      type: 'url',
      payload: url,
    })
  }
  const clickAdsFormat = (ad: string) => {
    if (adsFormat.includes(ad)) {
      setAdsFormat(
        adsFormat.filter(item => {
          return item !== ad
        })
      )
    } else {
      setAdsFormat([...adsFormat, ad])
    }
  }
  const isChooseBannerAdType = useMemo(
    () => adsFormat.includes('banner'),
    [adsFormat]
  )
  const bannerNonStandardChange = () => {
    setBannerNonStandard(pre => !pre)
  }
  const sizeChange = (e: any, value: string) => {
    setNativeadsSize(value)
  }
  const reset = () => {
    setStepNum(1)
    setPlatform('')
    const ads: string[] = []
    setAdsFormat(ads)
    setEngine('')
    setUrl('')
    setBannerNonStandard(false)
    setNativeadsSize('medium')
    setGameName('')
    setSubmiting(false)
    setSubmited(false)
    setUpdateLink(false)
    setBundleId('')
    setIcon('')
    setScreen('')
    setOpenDialog(false)
    setUpsellApp('')
  }
  useEffect(() => {
    if (pageLocation.search) {
      const temp: any = getQueryParams(pageLocation.search)
      const detail = JSON.parse(decodeURIComponent(temp.detail))
      const upsellKey = temp.upsellKey
      setUrl(detail.store_url)
      setBundleId(detail.bundle_id)
      setIcon(detail.icon_url)
      setGameName(detail.name)
      setPlatform(detail.platform)
      setUpdateLink(true)
      setUpsellApp(upsellKey)
      confirm()
      history.pushState('', '', location.origin + location.pathname)
    }
  }, [pageLocation.search])
  useEffect(() => {
    if (state.urlParseDetail.name) {
      setBundleId(state.urlParseDetail.bundle_id)
      setIcon(state.urlParseDetail.icon)
      setGameName(state.urlParseDetail.name)
      setPlatform(state.urlParseDetail.platform)
      setUpdateLink(true)
    }
  }, [state.urlParseDetail])
  useEffect(() => {
    if (submiting && state.addResult !== '') {
      setSubmiting(false)
      if (!state.addResult) return
      setStepNum(stepNum + 1)
      setSubmited(!!state.addResult)
      setOpenDialog(true)
    }
    // eslint-disable-next-line
  }, [state.addResult])
  const backQuestion = () => {
    if (updateLink && stepNum === 4) {
      setStepNum(1)
    } else {
      setStepNum(stepNum - 1)
    }
  }
  const stepList = [
    'Add Link',
    'Name',
    'Platform',
    'Orientation',
    'Engine',
    'Ads',
  ]

  return (
    <Box className={classes.container}>
      <TopTitle
        title="Add New Game"
        showBack
        goBack={() => {
          navigate(-1)
        }}
      />
      <StylePaper
        marginTop="24px"
        padding={globalState.isMobile ? '32px 24px' : '80px'}
        minWidth={globalState.isMobile ? 0 : '480px'}
      >
        <Box
          padding={globalState.isMobile ? '0 0 20px' : '0 80px 40px'}
          sx={
            !min460
              ? {
                  transform: 'scale(0.75)',
                  transformOrigin: 'left center',
                }
              : {}
          }
        >
          <Stepper
            activeStep={stepNum - 1}
            alternativeLabel={min1020 ? false : true}
          >
            {stepList.map((label, index) => {
              const color =
                index < stepNum - 1
                  ? '#27AE60'
                  : index === stepNum - 1
                  ? theme.palette.primary.main
                  : theme.palette.custom.inputLabel
              return (
                <Step
                  key={label}
                  className={clsx({
                    [classes.completeStepIcon]: index < stepNum - 1,
                    [classes.stepIcon]: true,
                  })}
                >
                  <StepLabel>
                    <span style={{ color }}>{label}</span>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Box>
        {submiting && (
          <Box className={classes.loading}>
            <Box display="flex" justifyContent="center" marginBottom="80px">
              <LoadingComponent />
            </Box>
            Confirming game submission…
          </Box>
        )}
        {!submiting && !submited && (
          <Box>
            <Box>
              <Box className={classes.title}>
                {stepNum === 1
                  ? updateLink
                    ? ''
                    : 'If your game is live on Play Store or App Store, copy paste your store link here.'
                  : stepNum === 2
                  ? 'What is the name of your game?'
                  : stepNum === 3
                  ? 'What platform is your game currently offered on?'
                  : stepNum === 4
                  ? 'Select screen orientation of your game'
                  : stepNum === 5
                  ? 'How did you build your game?'
                  : 'What Ad types would you like to integrate into your game?'}
              </Box>
              {stepNum === 1 && (
                <AddLinkStepView
                  updateLink={updateLink}
                  url={url}
                  urlInputChange={urlInputChange}
                  update={update}
                  next={next}
                  icon={icon}
                  bundleId={bundleId}
                  gameName={gameName}
                  platform={platform}
                />
              )}
              {stepNum === 2 && (
                <Box textAlign="center">
                  <Box
                    border="1px solid #eee"
                    borderRadius="6px"
                    height="40px"
                    width={globalState.isMobile ? '100%' : '60%'}
                    marginTop="48px"
                    display="inline-block"
                    textAlign="left"
                  >
                    <input
                      value={gameName}
                      onInput={urlGameChange}
                      type="text"
                      className={classes.gameInput}
                    />
                  </Box>
                </Box>
              )}
              {stepNum === 3 && (
                <Box className={classes.platform}>
                  <RadioBoxView
                    img={PNG.android}
                    label="Android"
                    checked={platform === 'android'}
                    click={() => {
                      setPlatform('android')
                    }}
                  />
                  <RadioBoxView
                    img={PNG.ios}
                    label="IOS"
                    checked={platform === 'iOS'}
                    click={() => {
                      setPlatform('iOS')
                    }}
                  />
                </Box>
              )}
              {stepNum === 4 && (
                <OrientationStepView
                  screen={screen}
                  onChange={(value: string) => {
                    setScreen(value)
                  }}
                />
              )}
              {stepNum === 5 && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    className={classes.platform}
                    flexWrap="wrap"
                    maxWidth="800px"
                  >
                    {engineList
                      .filter(element => {
                        if (platform === 'android') {
                          return element.value !== 'iOS'
                        } else if (platform === 'iOS') {
                          return element.value !== 'Android'
                        }
                        return true
                      })
                      .map(item => (
                        <RadioBoxView
                          key={item.value}
                          img={PNG[item.value] || PNG.ios}
                          label={item.label}
                          checked={engine === item.value}
                          click={() => {
                            setEngine(item.value)
                          }}
                        />
                      ))}
                    {engineList2
                      .filter(element => {
                        if (platform === 'iOS') {
                          return (
                            element.value !== 'Cocos' &&
                            element.value !== 'Solar2D'
                          )
                        }
                        return true
                      })
                      .map(item => (
                        <RadioBoxView
                          key={item.value}
                          img={PNG[item.value] || PNG.ios}
                          label={item.label}
                          checked={engine === item.value}
                          click={() => {
                            setEngine(item.value)
                          }}
                        />
                      ))}
                  </Box>
                </Box>
              )}
              {stepNum === 6 && (
                <AdsStepView
                  adsFormat={adsFormat}
                  clickAdsFormat={clickAdsFormat}
                  nativeadsSize={nativeadsSize}
                  sizeChange={sizeChange}
                  engine={engine}
                  isChooseBannerAdType={isChooseBannerAdType}
                  updateLink={updateLink}
                  bannerNonStandard={bannerNonStandard}
                  bannerNonStandardChange={bannerNonStandardChange}
                />
              )}
            </Box>
            {/* Button - 页面下面的按钮 */}
            <Box className={classes.next}>
              {stepNum === 1 && updateLink && (
                <Box className={classes.backButton} onClick={reset}>
                  Reset
                </Box>
              )}

              {stepNum - 1 > 0 &&
                !submiting &&
                !submited &&
                (!upsellApp || stepNum > 4) && (
                  <Box className={classes.backButton} onClick={backQuestion}>
                    Back
                  </Box>
                )}
              {stepNum !== 1 && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: '124px', height: '40px' }}
                  onClick={next}
                  disabled={
                    (stepNum === 2 && gameName.length < 2) ||
                    (stepNum === 3 && !platform) ||
                    (stepNum === 4 && !screen) ||
                    (stepNum === 5 && !engine) ||
                    (stepNum === 6 && !adsFormat.length)
                  }
                >
                  Next
                </Button>
              )}
              {updateLink && stepNum === 1 && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: '124px', height: '40px' }}
                  onClick={confirm}
                >
                  Confirm
                </Button>
              )}
            </Box>
          </Box>
        )}
        <AddedDialogView open={openDialog} reset={reset} />
      </StylePaper>
    </Box>
  )
}
export default Add
