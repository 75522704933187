import { useEffect, useRef, useContext } from 'react'
import { Box } from '@material-ui/core'
import { init, EChartsType } from 'echarts'
import { GlobalContext } from 'src/context/global'
import { useTheme, Theme } from '@material-ui/core'

const View = (props: any) => {
  const chart = useRef<EChartsType>()
  const { state } = useContext(GlobalContext)
  const theme: Theme = useTheme()

  // effects
  useEffect(() => {
    // 指定图表的配置项和数据
    const options = {
      series: [
        {
          type: 'gauge',
          startAngle: 225,
          endAngle: -45,
          min: 0,
          max: 100,
          splitNumber: 20,
          axisLine: {
            lineStyle: {
              width: 35,
              color:
                state.performanceScoring === '-'
                  ? [[1, '#ABB3C1']]
                  : [
                      [0.3, '#B93D34'],
                      [0.7, '#FAAE31'],
                      [0.85, '#65cd91'],
                      [1, '#27AE60'],
                    ],
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: theme.palette.text.primary,
            distance: -55,
            formatter: function (value: number) {
              if (state.performanceScoring === '-') {
                return ''
              }
              const labels = {
                0: 'Very Poor',
                30: 'Poor',
                70: 'Normal',
                85: 'Good',
                100: 'Excellent',
              }
              return labels[value]
            },
          },
          detail: {
            show: true,
            offsetCenter: [0, '40%'],
            formatter: state.performanceScoring === '-' ? '-' : '{value}',
            color: theme.palette.text.primary,
          },
          pointer: {
            length: '60%',
            width: 10,
            itemStyle: {
              color: theme.palette.mode === 'light' ? '#000000' : '#FFF',
            },
          },
          itemStyle: {
            color: '#FFFFFF',
          },
          title: {
            show: false,
          },
          // value为图表的值
          data: [{ value: Number(state.performanceScoring || 0).toFixed() }],
        },
      ],
    }
    if (!chart.current) {
      const chartEl: HTMLElement | null =
        document.getElementById('score-echart')
      if (chartEl) {
        chart.current = init(chartEl)
      }
    }
    chart.current?.setOption(options, true)
    chart.current?.resize()
  }, [theme.palette.mode, theme.palette.text.primary, state.performanceScoring])

  useEffect(() => {
    // Bind the resize method to the window
    window.addEventListener('resize', () => {
      chart.current?.resize()
    })

    return function willUnmount() {
      if (chart.current) {
        chart.current.clear()
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [])

  // returns
  return (
    <Box
      id="score-echart"
      width="330px"
      height="300px"
      boxSizing="border-box"
      marginTop="-10px"
    />
  )
}

export default View
