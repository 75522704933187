const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2485 3.66602H14.5643C13.2879 3.68636 12.0334 4 10.8976 4.58268L10.3293 4.86685C9.42714 5.30321 8.43455 5.51995 7.43262 5.49935H2.74845C2.24219 5.49935 1.83179 5.90975 1.83179 6.41602V17.416C1.83179 17.9223 2.24219 18.3327 2.74845 18.3327H7.43262C8.70535 18.3266 9.95972 18.0286 11.0993 17.4618L11.6676 17.1777C12.5659 16.7255 13.5587 16.493 14.5643 16.4993H19.2485C19.7547 16.4993 20.1651 16.0889 20.1651 15.5827V4.58268C20.1651 4.07642 19.7547 3.66602 19.2485 3.66602ZM18.3302 11.9159C16.8114 11.9159 15.5802 13.1471 15.5802 14.6659H14.5627C13.29 14.673 12.0359 14.9709 10.896 15.5367L10.3277 15.8209C9.42788 16.2689 8.43612 16.5011 7.43099 16.4992H6.41349C6.41349 14.9805 5.18228 13.7492 3.66349 13.7492V10.0826C5.18228 10.0826 6.41349 8.85136 6.41349 7.33258H7.43099C8.70721 7.31126 9.96155 6.99767 11.0977 6.41591L11.666 6.13175C12.5696 5.6996 13.5612 5.48308 14.5627 5.49925H15.5802C15.5802 7.01803 16.8114 8.24925 18.3302 8.24925V11.9159ZM11.0001 13.7494C12.5189 13.7494 13.7501 12.5182 13.7501 10.9994C13.7501 9.48062 12.5189 8.2494 11.0001 8.2494C9.4813 8.2494 8.25008 9.48062 8.25008 10.9994C8.25008 12.5182 9.4813 13.7494 11.0001 13.7494Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
