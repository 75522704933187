import { Box, Tooltip, ClickAwayListener } from '@material-ui/core'
import { useState } from 'react'

const AdaptiveTooltip = (props: any) => {
  const { children, isMobile, ...prop } = props
  const [open, setOpen] = useState(false)
  return (
    <>
      {isMobile ? (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false)
          }}
        >
          <Box component="span">
            <Tooltip open={open} disableFocusListener {...prop}>
              <Box
                component="span"
                onClick={() => {
                  setOpen(true)
                }}
              >
                {children}
              </Box>
            </Tooltip>
          </Box>
        </ClickAwayListener>
      ) : (
        <Tooltip {...prop}>{children}</Tooltip>
      )}
    </>
  )
}

export default AdaptiveTooltip
