import { useState, useEffect } from 'react'
import { Box, Skeleton, useTheme, Theme } from '@material-ui/core'
import HTMLReactParser from 'html-react-parser'
import { getDateEnglishFormat, getQueryParams } from 'src/utils'

const MessageContentView = (props: any) => {
  const theme: Theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    const locationSearch = getQueryParams(location.href)
    if (!locationSearch) {
      return
    }
    if (locationSearch.id) {
      const idTemp: any = Number(locationSearch.id) || null
      if (idTemp === props.id) {
        setOpen(true)
        props.clickContent && props.clickContent()
      }
    }
    // eslint-disable-next-line
  }, [location.href])
  return (
    <Box
      sx={{
        padding: '8px 0',
        borderBottom: `1px solid ${
          theme.palette.mode === 'light' ? '#E1E5E7' : '#495263'
        }`,
        color: theme.palette.text.primary,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          fontSize: '16px',
          lineHeight: '24px',
          cursor: 'pointer',
          fontWeight: open ? 700 : 400,
          justifyContent: 'space-between',
          background:
            !props.loading && !props.is_read
              ? theme.palette.mode === 'light'
                ? '#F2F9FF'
                : 'rgba(242, 249, 255, 0.3)'
              : '',
        }}
        onClick={() => {
          setOpen(!open)
          props.clickContent && props.clickContent()
        }}
      >
        {props.loading ? <Skeleton width="300px" /> : props.title}
        {!props.loading && (
          <Box sx={{ display: 'flex', fontWeight: 400 }}>
            <Box
              sx={{
                width: '210px',
                minWidth: '210px',
                textAlign: 'center',
                color:
                  props.interaction_type === 'TOP_BANNER'
                    ? theme.palette.primary.main
                    : '',
                fontWeight: props.interaction_type === 'TOP_BANNER' ? 600 : 400,
              }}
            >
              {props.category}
            </Box>
            <Box width="120px" minWidth="120px">
              {getDateEnglishFormat(props.time)}
            </Box>
          </Box>
        )}
      </Box>
      {!props.loading && open && (
        <>
          <Box
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: theme.palette.text.primary,
              padding: '0 16px 16px',
            }}
          >
            {HTMLReactParser(props.content)}
          </Box>
        </>
      )}
    </Box>
  )
}
export default MessageContentView
