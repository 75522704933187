import DayUtils from '@date-io/dayjs'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Box, Popover, TextField, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StaticDatePicker, LocalizationProvider } from '@material-ui/lab'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      borderDateBox: {
        width: '150px',
        position: 'relative',
        display: 'flex',
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '8px 16px',
        border: `1px solid ${theme.palette.custom.paperBorder}`,
        borderRadius: '6px',
        justifyContent: 'space-between',
        boxShadow:
          theme.palette.mode === 'light'
            ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
            : 'none',
      },
      label: {
        position: 'absolute',
        fontSize: '12px',
        lineHeight: '12px',
        top: '-6px',
        left: '10px',
        background: theme.palette.background.paper,
        padding: '0 6px',
        color: theme.palette.text.secondary,
      },
      popover: {
        '& .MuiPaper-root': {
          minWidth: '200px',
          maxHeight: '70vh',
          marginTop: '14px',
          borderRadius: '6px',
          padding: '0',
          background: theme.palette.custom.dialogBg,
        },
      },
    }
  })

interface selectProps {
  label?: string
  customClass?: any
  value: any
  showNumberButton?: boolean
  onChange: (date: any) => void
  onTriggerFilter?: () => void
}
export default function StyleSingleDate(props: selectProps) {
  const { label = 'Date', value, onChange, onTriggerFilter = null } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [defaultValue, setDefaultValue] = useState<string>('')
  const handleClick = (event: any) => {
    setDefaultValue(value)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    if (onTriggerFilter && defaultValue !== value) {
      onTriggerFilter()
    }
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const temp: any = DayUtils

  return (
    <Box>
      <Box
        className={classes.borderDateBox}
        id={'dateSelect' + label}
        aria-describedby={id}
        onClick={handleClick}
      >
        {/* label */}
        {label && <Box className={classes.label}>{label}</Box>}
        {/* value */}
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color:
              theme.palette.mode === 'light'
                ? '#102542'
                : 'rgba(255, 255, 255, 0.6)',
          }}
        >
          {dayjs(value).format('YYYY-MM-DD')}
        </Box>

        {/* right icon */}
        <SVGIcon.TriangleDownSVG
          color={
            theme.palette.mode === 'dark'
              ? 'rgb(255, 255, 255)'
              : 'rgba(0, 0, 0, 0.54)'
          }
          style={{
            marginTop: '9px',
            marginLeft: '2px',
            transform: anchorEl ? 'rotate(180deg)' : '',
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <Box
          fontSize="14px"
          padding="24px"
          display="flex"
          sx={{
            '&>div': {
              background: theme.palette.mode === 'light' ? '#FFF' : '#404858',
            },
            '& .MuiButtonBase-root': {
              background: theme.palette.mode === 'light' ? '#FFF' : '#404858',
            },
          }}
        >
          <LocalizationProvider dateAdapter={temp}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              maxDate={dayjs().subtract(7, 'day')}
              minDate={dayjs().subtract(31, 'day')}
              inputFormat="YYYY-MM-DD"
              value={value}
              onChange={(date: any) => {
                onChange(date)
              }}
              renderInput={params => <TextField {...params} />}
              views={['day']}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </Box>
  )
}
