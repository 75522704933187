import {
  Box,
  Switch,
  Button,
  Typography,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Tooltip,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import Skeleton from '@material-ui/core/Skeleton'
import { useContext, useEffect, useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { GameManageContext } from 'src/context/gameManage'
import { GlobalContext } from 'src/context/global'
import {
  ClickCopyText,
  LoadingBtn,
  LoadingComponent,
  StyleRadio,
} from 'src/components'
import PreMetrics from './preMetrics'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      title: {
        fontSize: '16px',
        fontWeight: 600,
        marginBottom: '16px',
      },
      saveButton: {
        width: '124px !important',
        height: '40px !important',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
      websiteLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'underline',
      },
      bannerNonStandardLabel: {
        '& .MuiFormControlLabel-label': {
          fontSize: '14px !important',
        },
      },
      nativeadsTitle: {
        fontSize: '14px',
        color: theme.palette.custom.inputLabel,
        marginTop: '4px',
      },
      nativeadsContent: {
        fontSize: '12px',
        lineHeight: '16px',
        color: '#707070',
        marginTop: '2px',
      },
    }
  })

const AdsManagementView = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const { state, editGame, switchAd, switchTestMode } =
    useContext(GameManageContext)
  const { state: globalState } = useContext(GlobalContext)
  const obj: any = {
    name: '',
    review: '',
    platform: '',
    appkey: '',
    bundleId: '',
  }
  const [dialogData, setDialogData] = useState(obj)
  const [adsFormat, setAdsFormat] = useState({
    reward_video: false,
    interstitial: false,
    banner: false,
    nativeads: false,
    openads: false,
    rewardinter: false,
  })
  const [adsFormatTemp, setAdsFormatTemp] = useState({
    reward_video: false,
    interstitial: false,
    banner: false,
    nativeads: false,
    openads: false,
    rewardinter: false,
  })
  const [nativeadsSize, setNativeadsSize] = useState('medium')

  const saveStoreLink = () => {
    const ads_format: string[] = []
    Object.keys(adsFormat).forEach((key: string) => {
      adsFormat[key] && ads_format.push(key)
    })
    const data: any = {
      app_key: state.appDetail.app_key,
      test_ads_status: state.appDetail.test_ads_status,
      url: state.appDetail?.url || null,
      ads_format,
    }
    if (adsFormat.nativeads) {
      data.nativeads_size = nativeadsSize
    }
    editGame(data)
  }
  const switchAdBtn = () => {
    switchAd(dialogData.app_key, !dialogData.is_enabled)
  }
  const switchTestModeBtn = () => {
    const data = {
      app_key: state.appDetail.app_key,
      url: state.appDetail?.url || null,
      ads_format: state.appDetail.ads_format,
      test_ads_status: !dialogData.test_ads_status,
    }
    switchTestMode(data)
  }
  const linkWebsite = () => {
    if (!dialogData.short_key) {
      return
    }
    window.open(`https://${dialogData.short_key}.ads-txt.site`)
  }
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    setAdsFormat({ ...adsFormat, [name]: checked })
  }
  const NativeadsAdTypesView = () => (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box className={classes.nativeadsTitle} marginBottom="4px">
        {state.appDetail.nativeads_size
          ? 'Native ads size'
          : 'Please check the native ads size'}
        :
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex">
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="gender"
              name="gender1"
              value={nativeadsSize}
              onChange={sizeChange}
            >
              {(!state.appDetail.nativeads_size ||
                state.appDetail.nativeads_size === 'small') && (
                <FormControlLabel
                  className={classes.bannerNonStandardLabel}
                  value="small"
                  control={<StyleRadio />}
                  label="Small (3:1)"
                  style={{ marginRight: 40 }}
                />
              )}
              {(!state.appDetail.nativeads_size ||
                state.appDetail.nativeads_size === 'medium') && (
                <FormControlLabel
                  className={classes.bannerNonStandardLabel}
                  value="medium"
                  control={<StyleRadio />}
                  label="Medium (6:5)"
                  style={{ marginRight: 40 }}
                />
              )}
              {(!state.appDetail.nativeads_size ||
                state.appDetail.nativeads_size === 'custom') &&
                (state.appDetail.engine === 'iOS' ||
                  state.appDetail.engine === 'Android') && (
                  <Box>
                    <FormControlLabel
                      className={classes.bannerNonStandardLabel}
                      value="custom"
                      control={<StyleRadio />}
                      label="Custom"
                      style={{ marginRight: 0 }}
                    />
                    <Tooltip
                      title={
                        <Box>
                          Custom size is a fully customized native ad unit.
                          Please see our{' '}
                          {
                            <Box
                              component="span"
                              color={theme.palette.primary.main}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (state.appDetail.engine === 'iOS') {
                                  window.open(
                                    'https://developers.yodo1.com/docs/ios/ad-units/native-ads-custom/'
                                  )
                                } else {
                                  window.open(
                                    'https://developers.yodo1.com/docs/android/unit-ads/native-ads-custom/'
                                  )
                                }
                              }}
                            >
                              documentation
                            </Box>
                          }{' '}
                          for integration instruction.
                        </Box>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoOutlinedIcon
                        style={{
                          fontSize: '18px',
                          verticalAlign: 'middle',
                          marginLeft: '6px',
                          color: theme.palette.text.secondary,
                        }}
                      />
                    </Tooltip>
                  </Box>
                )}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {!state.appDetail.nativeads_size && (
        <Box className={classes.nativeadsContent}>
          You cannot change the size type after you set native ads for the game
          even if it&apos;s cancelled and added again.
        </Box>
      )}
    </Box>
  )
  const sizeChange = (e: any, value: string) => {
    setNativeadsSize(value)
  }
  const saveButtonView = (
    <>
      {globalState.accountAuthority.game_manage_page === 2 && (
        <Button
          className={classes.saveButton}
          variant="contained"
          onClick={saveStoreLink}
          disabled={
            (!adsFormat.banner &&
              !adsFormat.interstitial &&
              !adsFormat.reward_video &&
              !adsFormat.nativeads &&
              !adsFormat.openads &&
              !adsFormat.rewardinter) ||
            JSON.stringify(adsFormat) === JSON.stringify(adsFormatTemp)
          }
          sx={props.isMobile ? { width: '100% !important' } : {}}
        >
          {state.editGameLoading ? (
            <LoadingBtn
              loadingStyle={{
                justifyContent: 'center',
              }}
            />
          ) : (
            'Save'
          )}
        </Button>
      )}
    </>
  )

  useEffect(() => {
    setDialogData(state.appDetail || {})
    let format = {
      reward_video: false,
      interstitial: false,
      banner: false,
      nativeads: false,
      openads: false,
      rewardinter: false,
    }
    state.appDetail.ads_format &&
      state.appDetail.ads_format.forEach((name: string) => {
        format = { ...format, [name]: true }
      })
    setAdsFormat(format)
    setAdsFormatTemp(format)
    setNativeadsSize(state.appDetail.nativeads_size || 'medium')
    // eslint-disable-next-line
  }, [state.appDetail])

  return (
    <Box>
      <Box position="relative">
        {state.loading && (
          <Box className={classes.loadingMask}>
            <LoadingComponent />
          </Box>
        )}
        <Box>
          <Grid container columnSpacing={6} rowSpacing={3}>
            {state.loading ? (
              <Grid item xs={12} md={6}>
                <Box>
                  <Box className={classes.title}>Display Ads</Box>
                  <Box>
                    <Skeleton
                      variant="rectangular"
                      width={130}
                      height={20}
                      style={{ marginTop: '10px' }}
                    />
                  </Box>
                </Box>
              </Grid>
            ) : (
              <>
                {typeof dialogData.is_enabled === 'boolean' && (
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Box className={classes.title}>Display Ads</Box>
                      <Box>
                        <Typography color="textSecondary" component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>OFF</Grid>
                            <Grid item style={{ position: 'relative' }}>
                              {state.switchLoading && (
                                <Box
                                  position="absolute"
                                  left="50%"
                                  top="50%"
                                  zIndex={9}
                                  style={{
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                >
                                  <LoadingBtn
                                    loadingStyle={{
                                      justifyContent: 'center',
                                    }}
                                    noText={true}
                                  />
                                </Box>
                              )}
                              <Switch
                                checked={dialogData.is_enabled}
                                name="checkedC"
                                color="primary"
                                disabled={
                                  globalState.accountAuthority
                                    .game_manage_page !== 2 ||
                                  state.switchLoading
                                }
                                onChange={switchAdBtn}
                              />
                            </Grid>
                            <Grid item>ON</Grid>
                          </Grid>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12} md={6}>
              <Box className={classes.title}>Ad Types</Box>
              <FormGroup style={{ flexDirection: 'row' }}>
                {state.loading ? (
                  <>
                    <Skeleton variant="rectangular" width="80%" height={20} />
                  </>
                ) : (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={
                            globalState.accountAuthority.game_manage_page !== 2
                          }
                          checked={adsFormat.banner}
                          onChange={handleChange}
                          name="banner"
                        />
                      }
                      label="Banner"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={
                            globalState.accountAuthority.game_manage_page !== 2
                          }
                          checked={adsFormat.interstitial}
                          onChange={handleChange}
                          name="interstitial"
                        />
                      }
                      label="Interstitial"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={
                            globalState.accountAuthority.game_manage_page !== 2
                          }
                          checked={adsFormat.reward_video}
                          onChange={handleChange}
                          name="reward_video"
                        />
                      }
                      label="Rewarded Video"
                    />
                    <Tooltip
                      title="Require SDK 4.6.0 or above."
                      arrow
                      placement="top"
                      disableInteractive
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            disabled={
                              globalState.accountAuthority.game_manage_page !==
                              2
                            }
                            checked={adsFormat.nativeads}
                            onChange={handleChange}
                            name="nativeads"
                          />
                        }
                        label="Native Ads"
                      />
                    </Tooltip>

                    <Tooltip
                      title="Require SDK 4.8.0 or above."
                      arrow
                      placement="top"
                      disableInteractive
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            disabled={
                              globalState.accountAuthority.game_manage_page !==
                              2
                            }
                            checked={adsFormat.openads}
                            onChange={handleChange}
                            name="openads"
                          />
                        }
                        label="App Open"
                      />
                    </Tooltip>
                    {!!state.appDetail.is_whitelist && (
                      <>
                        <Tooltip
                          title="Require SDK 4.8.0 or above."
                          arrow
                          placement="top"
                          disableInteractive
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                disabled={
                                  globalState.accountAuthority
                                    .game_manage_page !== 2
                                }
                                checked={adsFormat.rewardinter}
                                onChange={handleChange}
                                name="rewardinter"
                              />
                            }
                            label="Rewarded Interstitial"
                          />
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
              </FormGroup>

              {adsFormat.nativeads && <NativeadsAdTypesView />}
              {!state.loading &&
                !adsFormat.banner &&
                !adsFormat.interstitial &&
                !adsFormat.reward_video &&
                !adsFormat.nativeads &&
                !adsFormat.openads &&
                !adsFormat.rewardinter && (
                  <Box color="#FF0000" marginTop="10px" fontSize="14px">
                    Ad Types cannot be empty
                  </Box>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.title}>Test Mode</Box>
              {state.loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={130}
                    height={20}
                    style={{ marginTop: '10px' }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={40}
                    style={{ marginTop: '12px' }}
                  />
                </>
              ) : (
                <>
                  <Box>
                    <Typography color="textSecondary" component="div">
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>OFF</Grid>
                        <Grid item style={{ position: 'relative' }}>
                          {state.switchTestModeLoading && (
                            <Box
                              position="absolute"
                              left="50%"
                              top="50%"
                              zIndex={9}
                              style={{
                                transform: 'translate(-50%, -50%)',
                              }}
                            >
                              <LoadingBtn
                                loadingStyle={{
                                  justifyContent: 'center',
                                }}
                                noText={true}
                              />
                            </Box>
                          )}
                          <Switch
                            checked={dialogData.test_ads_status}
                            color="primary"
                            disabled={
                              globalState.accountAuthority.game_manage_page !==
                                2 || state.switchTestModeLoading
                            }
                            onChange={switchTestModeBtn}
                          />
                        </Grid>
                        <Grid item>ON</Grid>
                      </Grid>
                    </Typography>
                  </Box>
                  <Box
                    marginTop="12px"
                    lineHeight="20px"
                    sx={
                      props.isMobile
                        ? { fontSize: '12px', fontWeight: 400 }
                        : {}
                    }
                  >
                    By enabling test mode you can test MAS SDK setup during
                    development. Enabling test mode only affects ad display
                    settings for test devices. You see your test devices in{' '}
                    <Box
                      className={classes.websiteLink}
                      component="span"
                      onClick={() => {
                        navigate('/dash/testing')
                      }}
                    >
                      Management → Testing
                    </Box>
                    .
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <PreMetrics
                app_key={dialogData.app_key}
                isMobile={props.isMobile}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.title}>Yodo1 app-ads.txt microsite</Box>
              <Box>
                {!state.loading ? (
                  <Box display="flex">
                    <Box
                      className={
                        dialogData.short_key ? classes.websiteLink : ''
                      }
                      onClick={linkWebsite}
                    >
                      {dialogData.short_key
                        ? dialogData.short_key + '.ads-txt.site'
                        : '-'}
                    </Box>
                    {dialogData.short_key && (
                      <Box marginLeft="4px" component="span">
                        <ClickCopyText
                          value={`https://${dialogData.short_key}.ads-txt.site`}
                          showIcon
                          showText={false}
                        />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Skeleton
                    variant="text"
                    width={140}
                    style={{ marginLeft: '17px' }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          {props.isMobile ? (
            <Box marginTop="30px">{saveButtonView}</Box>
          ) : (
            <Box display="flex" position="relative">
              <Box position="absolute" right="0" top="-40px">
                {saveButtonView}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default AdsManagementView
