import { createContext, useReducer, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ApiResponse } from 'src/api'
import { getCompareGameApi } from 'src/api/game'
import { GlobalContext } from './global'
import {
  getLastTimeRange,
  getQueryParams,
  toThousands,
  tabMetricFormat,
} from 'src/utils'

type Props = {
  time: [string, string]
  timeShow: string
  gameSelect: any
  oldGameSelect: any
  contentType: string
  firstData: any
  secondData: any
  thirdData: any
  fourthData: any
  dataKeys: string[]
  firstDataLoading: boolean
  secondDataLoading: boolean
  thirdDataLoading: boolean
  fourthDataLoading: boolean
  showGameCount: number
  viewBy: string
  firstDataForChart: any
  secondDataForChart: any
  thirdDataForChart: any
  fourthDataForChart: any
  checkedChartLine: any
  chartList: any
  resetIndex: number
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  time: getLastTimeRange(7),
  timeShow: 'last_week',
  gameSelect: [],
  oldGameSelect: [],
  contentType: 'Table',
  firstData: {},
  secondData: {},
  thirdData: {},
  fourthData: {},
  dataKeys: ['firstData', 'secondData', 'thirdData', 'fourthData'],
  firstDataLoading: false,
  secondDataLoading: false,
  thirdDataLoading: false,
  fourthDataLoading: false,
  showGameCount: 2,
  viewBy: 'revenue',
  firstDataForChart: {},
  secondDataForChart: {},
  thirdDataForChart: {},
  fourthDataForChart: {},
  checkedChartLine: [],
  chartList: [],
  resetIndex: 0,
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'time':
    case 'timeShow':
    case 'gameSelect':
    case 'oldGameSelect':
    case 'contentType':
    case 'firstData':
    case 'secondData':
    case 'thirdData':
    case 'fourthData':
    case 'firstDataLoading':
    case 'secondDataLoading':
    case 'thirdDataLoading':
    case 'fourthDataLoading':
    case 'showGameCount':
    case 'viewBy':
    case 'firstDataForChart':
    case 'secondDataForChart':
    case 'thirdDataForChart':
    case 'fourthDataForChart':
    case 'checkedChartLine':
    case 'chartList':
    case 'resetIndex':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  searchData: () => void
}
export const CompareGamesContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  searchData: () => {},
})

const type = {
  interstitial: 'Interstitial',
  banner: 'Banner',
  reward_video: 'Rewarded Video',
  nativeads: 'Native Ads',
  openads: 'App Open',
  rewardinter: 'Rewarded Interstitial',
}
export const AppPerformanceContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const {
    state: globalState,
    errorCatch,
    customAlert,
  } = useContext(GlobalContext)
  const pageLocation = useLocation()

  const getDetail = (updateType?: string) => {
    history.pushState(
      '',
      '',
      location.origin +
        location.pathname +
        '?app_keys=' +
        state.gameSelect
          .map((item: { app_key: string }) => item.app_key)
          .join(',')
    )
    const tempOldGameSelect = JSON.parse(JSON.stringify(state.oldGameSelect))
    dispatch({
      type: 'oldGameSelect',
      payload: state.gameSelect,
    })
    state.dataKeys.forEach((item: string, index: number) => {
      // 修改game时，只更新当前game数据
      if (
        updateType === 'gameChange' &&
        tempOldGameSelect[index]?.app_key === state.gameSelect[index]?.app_key
      ) {
        return
      }
      if (state.gameSelect[index]?.app_key) {
        dispatch({ type: item + 'Loading', payload: true })
        getCompareGameApi({
          from_date: state.time[0],
          to_date: state.time[1],
          app_keys: [state.gameSelect[index]?.app_key],
        })
          .then(({ data }: ApiResponse) => {
            const appData = data.data[state.gameSelect[index]?.app_key]
            const tempData = {
              revenue: toThousands(
                tabMetricFormat(
                  appData?.base?.revenue?.summary || 0,
                  'revenue',
                  true
                ),
                true
              ),
              impressions: toThousands(
                appData?.base?.impressions?.summary || 0,
                true
              ),
              impdau: toThousands(
                tabMetricFormat(
                  appData?.base?.impdau?.summary || 0,
                  'impdau',
                  true
                ),
                true
              ),
              ecpm: toThousands(
                tabMetricFormat(
                  appData?.base?.ecpm?.summary || 0,
                  'ecpm',
                  true
                ),
                true
              ),
              dau: toThousands(
                tabMetricFormat(appData?.base?.dau?.summary || 0, 'dau', true),
                true
              ),
              arpdau: toThousands(
                tabMetricFormat(
                  appData?.base?.arpdau?.summary || 0,
                  'arpdau',
                  true
                ),
                true
              ),
              scoring: appData?.scoring?.performance_score,
              country: appData?.country?.map((item: any) => {
                const key = Object.keys(item)[0]
                return {
                  name:
                    key === 'all'
                      ? 'All'
                      : globalState.countryListObject[key]?.name || key,
                  value: toThousands(
                    tabMetricFormat(
                      item[key]?.revenue?.summary || 0,
                      'revenue',
                      true
                    ),
                    true
                  ),
                }
              }),
              ad_type: appData?.ad_type?.summary.map((item: any) => {
                return {
                  name: type[item.ad_type] || item.ad_type,
                  value: toThousands(
                    tabMetricFormat(item?.ratio_revenue || 0, 'revenue', true),
                    true
                  ),
                }
              }),
              ad_placement: appData?.ad_placement?.summary.map((item: any) => {
                return {
                  name: `${item.ad_placement} (${
                    type[item.ad_type] || item.ad_type
                  })`,
                  value: toThousands(
                    tabMetricFormat(item?.ratio_revenue || 0, 'revenue', true),
                    true
                  ),
                }
              }),
            }
            dispatch({ type: item, payload: tempData })
            dispatch({ type: item + 'ForChart', payload: appData })
          })
          .catch((error: any) => {
            if (error.code === 'ECONNABORTED') {
              customAlert(
                'warning',
                'Response timed out. Please try again with a shorter time interval filter.'
              )
            } else {
              errorCatch(error)
            }
          })
          .finally(() => {
            dispatch({ type: item + 'Loading', payload: false })
          })
      } else {
        dispatch({
          type: item,
          payload: {},
        })
        dispatch({ type: item + 'ForChart', payload: {} })
      }
    })
  }
  const searchData = () => {
    getDetail()
  }
  useEffect(() => {
    const temp: any = getQueryParams(pageLocation.search)
    const app_keys = temp.app_keys
    if (app_keys && Object.keys(globalState.gameListObject)?.length) {
      const temp = app_keys.split(',')
      if (temp.length > 2) {
        dispatch({
          type: 'showGameCount',
          payload: temp.length,
        })
      }
      dispatch({
        type: 'gameSelect',
        payload: temp.map((item: string) => {
          return { ...globalState.gameListObject[item], app_key: item }
        }),
      })
    }
    // eslint-disable-next-line
  }, [globalState.gameListObject])
  useEffect(() => {
    if (!state.gameSelect[0]?.app_key) return
    getDetail('gameChange')
    // eslint-disable-next-line
  }, [state.gameSelect])
  useEffect(() => {
    if (state.resetIndex) {
      searchData()
    }
    // eslint-disable-next-line
  }, [state.resetIndex])

  // returns
  return (
    <CompareGamesContext.Provider
      value={{
        state,
        dispatch,
        searchData,
      }}
    >
      {children}
    </CompareGamesContext.Provider>
  )
}
