import { useContext } from 'react'
import { Box, Switch, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { MiddleChartContext } from 'src/context/userActivity/middleChart'
import { UserActivityContext } from 'src/context/userActivity/index'
import { GlobalContext } from 'src/context/global'
import EchartView from './echart'
import ChartSettingView from './chartSetting'
import { LoadingComponent } from 'src/components'
import { LoadingBtn, ChartTypeToggleGroup } from 'src/components'
import { rateTitleFormat } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      contentBottom: {
        position: 'relative',
        width: '100%',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })
const titles = {
  revenue: 'Est. Revenue',
  impressions: 'Impressions',
  arpdau: 'ARPDAU',
  impdau: rateTitleFormat('Imp', 'DAU'),
  dau: 'Avg.DAU',
  ecpm: 'eCPM',
}
const MiddleChart = (): JSX.Element => {
  // const
  const { state, dispatch } = useContext(MiddleChartContext)
  const { state: userActivityState } = useContext(UserActivityContext)
  const { state: globalState, editUserFeature } = useContext(GlobalContext)
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const handleChange = () => {
    editUserFeature({
      dashboard_benchmark: !globalState.feature?.dashboard_benchmark,
    })
  }

  // returns
  return (
    <Box className={classes.contentBottom}>
      {state.middleChartLoading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            padding: '0 4px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              fontSize: '20px',
              fontWeight: 500,
              marginRight: '16px',
            }}
          >
            <Box marginRight="16px">
              {titles[userActivityState.chartType || 'Revenue']}
            </Box>
            <ChartSettingView />
          </Box>
          <Box>
            {/* benchmark Button */}
            {globalState.feature?.dashboard_benchmark !== null &&
              state.view !== 'application' &&
              state.view !== 'country' &&
              state.chartType === 'impdau' && (
                <Box
                  sx={{
                    fontSize: '14px',
                    height: '36px',
                    color: theme.palette.text.primary,
                  }}
                >
                  <Box component="span" marginRight="12px" lineHeight="36px">
                    Benchmark:
                  </Box>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      transform: 'translateY(-1px)',
                    }}
                  >
                    {(globalState.userInfoLoading ||
                      globalState.featureLoading) && (
                      <Box
                        position="absolute"
                        left="50%"
                        top="50%"
                        zIndex={9}
                        style={{
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <LoadingBtn
                          loadingStyle={{
                            justifyContent: 'center',
                          }}
                          noText={true}
                        />
                      </Box>
                    )}
                    <Switch
                      checked={globalState.feature?.dashboard_benchmark}
                      onChange={handleChange}
                      name="checkedC"
                      color="primary"
                      disabled={
                        globalState.accountAuthority.dashboard_page !== 2 ||
                        globalState.userInfoLoading ||
                        globalState.featureLoading
                      }
                    />
                  </Box>
                </Box>
              )}
          </Box>
        </Box>

        <ChartTypeToggleGroup
          value={state.newChartType}
          onChange={(chartType: string) => {
            dispatch({ type: 'newChartType', payload: chartType })
          }}
        />
      </Box>
      <Box marginTop="26px">
        <EchartView />
      </Box>
    </Box>
  )
}

export default MiddleChart
