const lang = {
  common: {
    ok: '确定',
    cancel: '取消',
    confirm: '确定',
    success: '操作成功',
  },
  dashboard: {
    addNewGame: '新游戏添加',
    noValidateMsgTitle: ' 请确认您的邮件',
    resendVerifyEmail: '重发验证邮件',
    noValidateMsgText:
      '在确认你的电子邮件之前，你不能提交游戏。确认链接已经发送到您使用的电子邮件中。如果你找不到垃圾邮件，请检查你的垃圾邮件文件夹。',
  },
  auth: {
    login: {
      sign_in: '登录',
      email: '邮箱',
      emailErrMsg: '邮箱地址不对',
      password: '密码',
      passwordMinMsg: '密码太少',
      forgot_password: '忘记密码?',
      dont_have_account: '还没有账户？',
      signup: '注册',
    },
    password: {
      reset: '重置密码',
      reset_desc: '请填写账户邮箱地址以重置您的密码',
      sign_up_desc:
        '🔥 Holiday Welcome Bonus: Get $25 dollars when you release your first game with MAS. Valid until December 31st. For Terms and Conditions, type "Welcome Bonus" in the chat once you log in.',
      resetPasswdSuccessMsgTitle: '您的密码重置成功',
      resetPasswdSuccessMsgBody: '现在您可以使用新密码登录到MAS Portal。',
      resetPasswdEmailSuccessMsgTitle: '检查您的邮件',
      resetPasswdEmailSuccessMsgBody:
        '密码重置链接已发送到您的邮箱，如果找不到，请检查您的垃圾邮件文件夹。',
    },
    signup: {
      accept_terms_desc: '通过创建帐户，我接受Yodo1的',
      password_confirm: '密码确认',
      company_name: '工作室名称 / 你的名字',
      contact_name: '姓名',
      firstNameMinMsg: '姓名太短',
      lastNameMinMsg: '姓名太短',
      website_url: '网站网址',
      componyNameErrMsg: '必填填写工作室名称或您的名字',
      firstNameErrMsg: '必须填写联系人姓名',
      lastNameErrMsg: '必须填写联系人姓名',
      registerSuccessMsgTitle: 'Your Account is registered!',
      registerSuccessMsgBody:
        "You're one step closer to maximizing your revenue with MAS. Check your email to confirm your account.",
      registerSuccessMsgBody2:
        " Don't see our email? Please check your spam folder.",
    },
    emailVerify: {
      resend_email: '重新发送',
      next: '下一步',
      resendEmailMsg: '邮件发送或验证成功',
      verifySuccessMsgTitle: '祝贺',
      verifySuccessMsgBody:
        '您的电子邮件已通过验证。 现在，您可以使用MAS门户中提供的所有功能。',
      verifyMsgTitle: 'Your Account is registered!',
      verifyMsgBody:
        "You're one step closer to maximizing your revenue with MAS. Check your email to confirm your account.",
      verifyMsgBody2: " Don't see our email? Please check your spam folder.",
    },
  },
  links: {
    terms_conditions: '使用条款',
    privacy_policy: '隐私协议',
  },
  server: {
    testDeviceExists: '重复的 Device ID,该设备已存在',
    dataError: '数据错误',
    invalidEmail: '无效邮箱地址',
    verifyFailed: '校验失败',
    verifyCodeError: '验证码错误',
    verifyCodeGetError: '获取验证码失败',
    passwordError: '密码错误',
    review: '账号审核中',
    userNoExist: '用户不存在',
    loginNameExist: '登录名已存在',
    childExists: '当前子账户已存在',
    partnerExists: '当前邮箱已存在，已注册为主账号',
    currentGroupExist: '当前角色已存在',
    groupUsed: '将该⻆⾊下的账号另⾏分配⻆⾊，⽆法删除。',
    registerSuccess: '注册成功',
    htmlError: '加载协议失败',
    error: '服务器错误',
    noPermission: '无权限',
    mailSend: '邮件已发送',
    resetEmail: '邮件已发送至您的邮箱，请重置密码。',
    changePasswordError: '用户名和原密码不匹配',
    gameNoExist: '游戏不存在',
    gameExist: '游戏已存在',
    modifySuccess: '修改成功',
    partnerNoExist: '用户不存在',
    partnerExist: '用户已存在',
    loginError: '用户名或密码不正确',
    loginTimeOutTitle: '登录超时',
    loginTimeOutMsg: '请重新登录',
    support: '邮件发送成功',
    appNameNotAvailable: '游戏名不合法',
    appNameExist: '游戏名已存在',
    exist: '已存在',
    publishSuccess: '提交发布成功',
    existErr:
      '该应用URL已经存在，请确定是否有填写错误，如果没有，请联系我们mas.support@yodo1.com取得帮助',
    urlErr: '链接地址错误，请修改地址或点击未上线按钮',
    admobTotlasErr: 'Android Admob App ID 可用数量不足！请及时补充',
    bundleIDExist_iOS: '当前BundleID已存在',
    bundleIDExist_android: '当前包名已存在',
  },
  setting: {
    personalInfo: {
      emailErrMsg: '请输入邮箱地址',
      contactNameErrMsg: '请输入你的姓名',
      verificationCodeErrMsg: '请输入验证码',
      avatarLimitErrMsg: '图像大小必须大于20kb，小于1MB',
      emailChangeSuccessMsg: '邮件地址修改成功!',
      contactName: '姓名',
    },
    password: {
      currentPassword: '当前密码',
      newPassword: '新密码',
      confirmPassword: '确认新密码',
      passwordRequiredErrMsg: '请输入密码',
      passwordMinMaxErrMsg: '密码为6～20位数',
      passwordConfirmErrMsg: '确认密码需要与新密码相同',
    },
  },
  game: {
    topText1: '查看 MAS 文档以集成最新的 SDK 版本：',
    topText2: '集成 SDK 教程',
    deleteGameTitle: 'Are you sure you want to delete',
    deleteGameContent: 'NOTE: This action cannot be undone!',
  },
  testDevices: {
    topText1:
      'Tip: It takes 10 minutes for test mode to take effect. Please check the init log on your device to ensure that test mode is active.',
    topText2:
      'Use this page to add a test device first and then toggle test mode on in Games Management -> ads Management page. This is necessary to test that ads will show in your game. Not clear on how to test ads?  ',
    topText3: 'Check here',
    topText4: '当前测试设备数量: ',
    addDeviceTip:
      "Tip: According Google Play’s family policy, the user's GAID is transmitted only when the user is not a child.",
    addDeviceTip2:
      "In order to enable test ads function, please set COPPA false or choose the user's age >13 when initialization.",
  },
  validator: {
    device_id_format: '格式错误，请重新输入',
    device_id_input: '请输入设备ID',
    device_name_input: '请输入设备名称',
    account_email_input: '请输入用户游戏',
    account_role_select: '请选择用户权限',
    account_email_error: '请输入有效邮箱',
    verificationCodeErrMsg: '请输入验证码',
  },

  // API Error Code
  unknown: '一般(未知的)错误',
  'app.unknown': '一般(未知的)错误',
  'api.missing_param': '身份验证参数丢失',
  'app.duplicated_bundle_id': '您或其他帐户已经在我们的系统中添加了此应用',
  'app.bundle_id_not_mutable': 'App bundle-id是不可变的',
  'app.store_not_supported': '不支持此应用商店',
  'user.weak_password': '用户密码太短/弱，至少6个字符',
  'user.wrong_password': '密码错误',
  'user.verify_code_outdated': '验证代码已过期',
  'user.wrong_verify_code': '验证码不正确',
  'user.duplicated_email': '电子邮件地址已被其他开发者帐户使用',
  'user.not_found': '该帐户尚未注册',
  'api.error_email': '邮箱未注册',
  'user.reset_passwd_code_outdated': '重置密码链接已过期',
  'app.store_link_not_valid': '商店链接校验失败',
  'user.email_is_taken': '该电子邮件地址已被使用，请使用其他电子邮件地址',
  'user.change_email_code_wrong': '验证码错误',
  'user.change_email_code_outdated': '验证码错误或已超时',
  'test_device.device_not_exist': '设备不存在',
  'test_device.duplicate_device_id': '重复的设备ID',
  'user.code_outdated': '验证码错误或已超时',
  'user.code_wrong': '验证码错误',
  'user.no_referral_code': '推荐码错误',
  'user.platform_not_match': '平台不匹配',
  dashboard_page: 'Dashboard Page',
  finance_page: 'Finance Page',
  game_manage_page: 'Game Manage Page',
  game_submission: 'Game Submission',
  games_page: 'Games Page',
  notification_page: 'Notification Page',
  roles_page: 'Users Page',
  settings_page: 'Settings Page',
  testing_page: 'Testing Page',
  ad_placement_page: 'Ad Placement Page',
  overview_page: 'Performance Page',
  user_activity_page: 'User Activity Page',
  gameplay_insights_page: 'Gameplay Insights Page',
  engagement_page: 'Ad Engagement Page',
}

export default lang
