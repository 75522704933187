import { createContext, useReducer, useEffect, useContext } from 'react'
import { ApiResponse } from 'src/api'
import {
  getDeviceListApi,
  deleteDeviceApi,
  addDeviceApi,
  editDeviceApi,
} from 'src/api/testDevice'
import { GlobalContext } from './global'

type Props = {
  name: string
  data: any
  loading: boolean
  deleteResult: boolean
  deleteDeviceLoading: boolean
  editDeviceLoading: boolean
  addDialogOpen: boolean
  editDialogOpen: boolean
  editDialogData: any
  switchLoading: boolean
  holdData: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  name: '',
  loading: false,
  data: [],
  deleteResult: false,
  deleteDeviceLoading: false,
  editDeviceLoading: false,
  addDialogOpen: false,
  editDialogOpen: false,
  editDialogData: {},
  switchLoading: false,
  holdData: [],
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'deviceList':
      return { ...prevState, data: action.payload }
    case 'name':
    case 'loading':
    case 'deleteResult':
    case 'deleteDeviceLoading':
    case 'editDeviceLoading':
    case 'addDialogOpen':
    case 'editDialogOpen':
    case 'editDialogData':
    case 'switchLoading':
    case 'holdData':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  deleteDevice: (id: string) => void
  editDevice: (data: Record<string, unknown>) => void
  searchList: (name: string) => void
}

export const TestDeviceContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  deleteDevice: (id: string) => {},
  editDevice: (data: Record<string, unknown>) => {},
  searchList: (name: string) => {},
})

export const TestDeviceContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, customAlert } = useContext(GlobalContext)
  // handlers
  const getDeviceList = () => {
    dispatch({ type: 'loading', payload: true })
    getDeviceListApi({ query: '' })
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'holdData', payload: data })
        dispatch({ type: 'deviceList', payload: data })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
  }
  const deleteDevice = (id: string) => {
    if (state.deleteDeviceLoading) return
    dispatch({ type: 'deleteResult', payload: false })
    dispatch({ type: 'deleteDeviceLoading', payload: true })
    deleteDeviceApi(id)
      .then(() => {
        getDeviceList()
        dispatch({ type: 'deleteResult', payload: true })
        customAlert('success', 'Test device was deleted successfully.')
      })
      .catch(errorCatch)
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: 'deleteDeviceLoading', payload: false })
        }, 500)
      })
  }
  const searchList = (name: string) => {
    const temp = state.holdData.filter((item: any) => item.name.includes(name))
    dispatch({ type: 'deviceList', payload: temp })
  }
  useEffect(() => {
    dispatch({ type: 'loading', payload: true })
    getDeviceList()
    // eslint-disable-next-line
  }, [state.name])
  // 添加、编辑设备
  const editDevice = (data: any) => {
    if (state.editDeviceLoading) return
    dispatch({ type: 'editDeviceLoading', payload: true })
    if (data.id) {
      editDeviceApi(data)
        .then(() => {
          dispatch({
            type: 'editDialogOpen',
            payload: false,
          })
          customAlert('success', 'Test device updated successfully.')
          getDeviceList()
        })
        .catch(errorCatch)
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: 'editDeviceLoading', payload: false })
          }, 500)
        })
    } else {
      addDeviceApi(data)
        .then(() => {
          dispatch({
            type: 'addDialogOpen',
            payload: false,
          })
          customAlert('success', 'Test device added successfully.')
          getDeviceList()
        })
        .catch(errorCatch)
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: 'editDeviceLoading', payload: false })
          }, 500)
        })
    }
  }
  // returns
  return (
    <TestDeviceContext.Provider
      value={{
        state,
        dispatch,
        deleteDevice,
        editDevice,
        searchList,
      }}
    >
      {children}
    </TestDeviceContext.Provider>
  )
}
