import { GameAddContextProvider } from 'src/context/gameAdd'
import Add from './add'

const AddGame = (): JSX.Element => {
  // returns
  return (
    <GameAddContextProvider>
      <Add />
    </GameAddContextProvider>
  )
}

export default AddGame
