import {
  Box,
  Tooltip,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import { StyleRadio } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      title: {
        fontSize: '22px',
        color: theme.palette.text.primary,
        textAlign: 'center',
      },
      infoIcon: {
        verticalAlign: 'middle',
        marginLeft: '5px',
        color: theme.palette.text.secondary,
        marginTop: '-4px',
      },
      bannerNonStandardLabel: {
        '& .MuiFormControlLabel-label': {
          fontSize: '18px !important',
        },
      },
    }
  })
const BannerAdTypesView = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  return (
    <Box
      marginTop={'48px'}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Box className={classes.title}>
        Are you using any of the following banner sizes?
        <Tooltip
          title="To use these banners sizes, you must be in good standing with Admob. The MAS team will independently verify this and reach out to you with any questions."
          arrow
          placement="top"
          disableInteractive
        >
          <InfoOutlinedIcon className={classes.infoIcon} />
        </Tooltip>
      </Box>
      <Grid
        container
        spacing={2}
        marginTop={2}
        maxWidth="580px"
        textAlign="center"
      >
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            Admob Large Banner (320*100)
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Admob Smart Banner</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            IAB Medium Rectangle(300*250)
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Admob Adaptive Banner</Typography>
        </Grid>
        <Grid item xs={12} justifyContent="center" display="flex">
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="gender"
              name="gender1"
              value={props.value}
              onChange={props.onChange}
            >
              <FormControlLabel
                className={classes.bannerNonStandardLabel}
                value={true}
                control={<StyleRadio />}
                label="Yes"
                style={{ marginRight: 140 }}
              />
              <FormControlLabel
                className={classes.bannerNonStandardLabel}
                value={false}
                control={<StyleRadio />}
                label="No"
                style={{ marginRight: 0 }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
export default BannerAdTypesView
