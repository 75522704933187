import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CohortsContext } from 'src/context/gamePlayInsights/cohorts'
import ChartView from './chartView'
import ChartSettingView from './chartSetting'
import { LoadingComponent, ChartTypeToggleGroup } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      contentBottom: {
        position: 'relative',
        width: '100%',
      },
      searchList: {
        zIndex: 1,
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })
const MiddleChart = (): JSX.Element => {
  // const
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch } = useContext(CohortsContext)

  // returns
  return (
    <Box className={classes.contentBottom}>
      {state.thirtyLoading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            padding: '0 4px',
          }}
        >
          <Box marginRight="16px">Retention</Box>
          <ChartSettingView />
        </Box>
        <ChartTypeToggleGroup
          value={state.newChartType}
          onChange={(chartType: string) => {
            dispatch({ type: 'newChartType', payload: chartType })
          }}
        />
      </Box>
      <Box marginTop="26px">
        <ChartView id="chart" width="100" />
      </Box>
    </Box>
  )
}

export default MiddleChart
