const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '16'
  const color = props.color || '#1D3353'
  return (
    <svg
      onClick={props.onClick}
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4786 10.5188C8.38492 10.6126 8.25783 10.6654 8.12527 10.6655H7.87193C7.73966 10.6639 7.61308 10.6114 7.5186 10.5188L4.0986 7.09214C4.0355 7.02955 4 6.94435 4 6.85547C4 6.7666 4.0355 6.6814 4.0986 6.61881L4.57193 6.14547C4.63338 6.08277 4.71747 6.04743 4.80527 6.04743C4.89306 6.04743 4.97716 6.08277 5.0386 6.14547L7.9986 9.11214L10.9586 6.14547C11.0212 6.08237 11.1064 6.04688 11.1953 6.04688C11.2841 6.04688 11.3693 6.08237 11.4319 6.14547L11.8986 6.61881C11.9617 6.6814 11.9972 6.7666 11.9972 6.85547C11.9972 6.94435 11.9617 7.02955 11.8986 7.09214L8.4786 10.5188Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
