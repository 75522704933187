import { useContext, useMemo, useState } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import Skeleton from '@material-ui/core/Skeleton'
import { makeStyles } from '@material-ui/styles'
import { DashContext } from 'src/context/dashboard'
import { toThousands, numFixedRefillZero } from 'src/utils'
import { SortButton } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      content: {
        boxSizing: 'border-box',
        width: '100%',
        height: '374px',
        overflow: 'auto',
        paddingRight: '6px',
        paddingBottom: '2px',
      },
    }
  })
const fill = ['', '', '', '', '', '', '']
const CountryTable = (props: { viewBy: string }): JSX.Element => {
  // const
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state } = useContext(DashContext)
  const [sort, setSort] = useState({ total: 'descending', percent: '' })
  const table = useMemo(() => {
    let temp = state.geoTableData
    if (props.viewBy === 'ecpm') {
      temp = temp.filter((item: any) => item.code !== 'XX')
    }
    return temp.sort((a: any, b: any) => {
      if (sort.percent === 'descending') {
        return (
          b.data[props.viewBy].change_rate - a.data[props.viewBy].change_rate
        )
      } else if (sort.percent === 'rise') {
        return (
          a.data[props.viewBy].change_rate - b.data[props.viewBy].change_rate
        )
      } else if (sort.total === 'rise') {
        return a.data[props.viewBy].summary - b.data[props.viewBy].summary
      } else {
        return b.data[props.viewBy].summary - a.data[props.viewBy].summary
      }
    })
  }, [state.geoTableData, props.viewBy, sort])

  // returns
  return (
    <>
      <Box
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          position: 'absolute',
          right: '10px',
          paddingRight: '16px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 8,
          background: theme.palette.background.paper,
        }}
      >
        <SortButton
          sort={sort.total}
          clickSort={(sort: string) => {
            setSort({ total: sort, percent: '' })
          }}
        />
      </Box>
      <Box className={classes.content}>
        {state.geoLoading ? (
          <>
            {fill.map((item: any, index: number) => (
              <Box key={index} sx={{ paddingTop: '24px' }}>
                <Skeleton variant="rectangular" height="36px" />
              </Box>
            ))}
          </>
        ) : (
          <>
            {table.map((item: any, index: number) => (
              <Box
                key={index}
                sx={{ paddingTop: '24px', color: theme.palette.text.primary }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Box
                    flex={2}
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '18px',
                    }}
                  >
                    {item.name}
                  </Box>
                  <Box
                    flex={1}
                    minWidth="90px"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '18px',
                      boxSizing: 'border-box',
                      padding: '0 4px',
                      textAlign: 'right',
                      fontVariantNumeric: 'tabular-nums',
                    }}
                  >
                    {['revenue', 'ecpm', 'arpdau'].includes(props.viewBy) &&
                      '$'}
                    {['dau', 'impressions'].includes(props.viewBy)
                      ? toThousands(item.data[props.viewBy].summary)
                      : toThousands(
                          numFixedRefillZero(
                            item.data[props.viewBy].summary,
                            2
                          ),
                          true
                        )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '1px',
                    background: '#dadde1',
                    marginTop: '16px',
                  }}
                />
              </Box>
            ))}
          </>
        )}
      </Box>
    </>
  )
}

export default CountryTable
