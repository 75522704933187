import {
  useState,
  useEffect,
  useContext,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  TextField,
  Box,
  Dialog,
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import Skeleton from '@material-ui/core/Skeleton'
import NumberFormat from 'react-number-format'
import { Close as CloseIcon } from '@material-ui/icons'
import { StyleSelectOne, StyleSelectMultiple, LoadingBtn } from 'src/components'
import { MetricsFormContext } from 'src/context/metricsForm'
import { GlobalContext } from 'src/context/global'
import AdTypeFormView from './adTypeForm'
import OverallFormView from './overallForm'
import CountryFormView from './countryForm'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      checkedLabel: {
        color: `${theme.palette.custom.inputLabel} !important`,
        fontSize: '16px',
      },
      formSearchList: {
        textAlign: 'left',
      },
      label: {
        boxSizing: 'border-box',
        fontSize: '12px',
        color: theme.palette.custom.inputLabel,
        margin: '24px 0 8px',
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
      typeFormList: {
        marginTop: '16px',
      },
      typeFormLabel: {
        width: '200px',
        minWidth: '200px',
        fontSize: '16px',
        color: theme.palette.custom.inputLabel,
        textAlign: 'left',
        lineHeight: '40px',
      },
      inputTitle: {
        textAlign: 'left',
        fontSize: '18px',
        fontWeight: 600,
        marginTop: '32px',
      },
      dialog: {
        position: 'relative',
        boxSizing: 'border-box',
        padding: '24px 32px 32px',
        width: '90vw',
        maxWidth: '600px',
        background: theme.palette.custom.dialogBg,
        height: '100%',
        overflow: 'hidden',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '24px',
        top: '22px',
        cursor: 'pointer',
      },
      title: {
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: 600,
        textAlign: 'center',
      },
      text: {
        fontSize: '16px',
        color: theme.palette.text.secondary,
        margin: '16px 0',
        lineHeight: '22px',
        textAlign: 'center',
      },
      dialogContent: {
        height: '100%',
        maxHeight: 'calc(100vh - 350px)',
        overflowY: 'auto',
        margin: '0 -6px',
        padding: '0 6px',
      },
      footerButton: {
        width: '100% !important',
        height: '43px !important',
        marginTop: '24px !important',
      },
      helpIcon: {
        fontSize: '18px !important',
        verticalAlign: 'middle',
        marginLeft: '6px',
        marginTop: '-2px',
      },
      errorDetail: {
        margin: '6px 0',
        fontSize: '12px',
        color: 'rgb(211, 47, 47)',
      },
    }
  })
interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}
function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props
  const decimalScale =
    props.name === 'dau' || props.name === 'impressions' ? 0 : 4
  const prefix =
    props.name === 'arp_dau' ||
    props.name === 'ecpm' ||
    props.name === 'revenue'
      ? '$'
      : ''
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalScale={decimalScale}
      thousandSeparator={props.name !== 'impressions' && props.name !== 'dau'}
      isNumericString
      prefix={prefix}
    />
  )
}
const obj: any = {
  mediation_name: null,
  network_name: null,
  dau: null,
  ecpm: null,
  revenue: null,
  impressions: null,
  legal_frameworks: {
    GDPR: null,
    CCPA: null,
    COPPA: null,
    complied_legal_frameworks: null,
  },
  ad_types: [
    {
      checked: false,
      label: 'Rewarded Video',
      key: 'rewarded_video',
      metric: {
        ecpm: null,
        impressions: null,
        revenue: null,
      },
    },
    {
      checked: false,
      label: 'Interstitial',
      key: 'interstitial',
      metric: {
        ecpm: null,
        impressions: null,
        revenue: null,
      },
    },
    {
      checked: false,
      label: 'Banner',
      key: 'banner',
      metric: {
        ecpm: null,
        impressions: null,
        revenue: null,
      },
    },
    {
      checked: false,
      label: 'App Open',
      key: 'openads',
      metric: {
        ecpm: null,
        impressions: null,
        revenue: null,
      },
    },
    {
      checked: false,
      label: 'Rewarded Interstitial',
      key: 'rewardinter',
      metric: {
        ecpm: null,
        impressions: null,
        revenue: null,
      },
    },
    {
      checked: false,
      label: 'Native Ads ',
      key: 'nativeads',
      metric: {
        ecpm: null,
        impressions: null,
        revenue: null,
      },
    },
    {
      checked: false,
      label: 'Other',
      key: 'other',
      specify: '',
      metric: {
        ecpm: null,
        impressions: null,
        revenue: null,
      },
    },
  ],
  countries_info: [
    {
      country_name: '',
      metric: {
        revenue: null,
        ecpm: null,
        impressions: null,
        dau: null,
      },
    },
    {
      country_name: '',
      metric: {
        revenue: null,
        ecpm: null,
        impressions: null,
        dau: null,
      },
    },
    {
      country_name: '',
      metric: {
        revenue: null,
        ecpm: null,
        impressions: null,
        dau: null,
      },
    },
  ],
}
const mediationList = [
  {
    value: 'Admob',
    label: 'Admob',
    name: 'Admob',
  },
  {
    value: 'Applovin MAX',
    label: 'Applovin MAX',
    name: 'Applovin MAX',
  },
  {
    value: 'Appodeal',
    label: 'Appodeal',
    name: 'Appodeal',
  },
  {
    value: 'IronSource',
    label: 'IronSource',
    name: 'IronSource',
  },
  {
    value: 'Unity Ads',
    label: 'Unity Ads',
    name: 'Unity Ads',
  },
  {
    value: 'Tapjoy',
    label: 'Tapjoy',
    name: 'Tapjoy',
  },
  {
    value: 'Adcolony',
    label: 'Adcolony',
    name: 'Adcolony',
  },
  {
    value: 'Admost',
    label: 'Admost',
    name: 'Admost',
  },
]
const adNetworkList = [
  {
    value: 'AdColony',
    label: 'AdColony',
    name: 'AdColony',
  },
  {
    value: 'AdFalcon',
    label: 'AdFalcon',
    name: 'AdFalcon',
  },
  {
    value: 'Admob',
    label: 'Admob',
    name: 'Admob',
  },
  {
    value: 'AppLovin',
    label: 'AppLovin',
    name: 'AppLovin',
  },
  {
    value: 'Chartboost',
    label: 'Chartboost',
    name: 'Chartboost',
  },
  {
    value: 'Facebook Audience Network',
    label: 'Facebook Audience Network',
    name: 'Facebook Audience Network',
  },
  {
    value: 'Flurry',
    label: 'Flurry',
    name: 'Flurry',
  },
  {
    value: 'Fyber',
    label: 'Fyber',
    name: 'Fyber',
  },
  {
    value: 'Google Ad Manger',
    label: 'Google Ad Manger',
    name: 'Google Ad Manger',
  },
  {
    value: 'i-mobile',
    label: 'i-mobile',
    name: 'i-mobile',
  },
  {
    value: 'inMobi',
    label: 'inMobi',
    name: 'inMobi',
  },
  {
    value: 'ironSource',
    label: 'ironSource',
    name: 'ironSource',
  },
  {
    value: 'Leadbolt',
    label: 'Leadbolt',
    name: 'Leadbolt',
  },
  {
    value: 'LG U+AD',
    label: 'LG U+AD',
    name: 'LG U+AD',
  },
  {
    value: 'Maio',
    label: 'Maio',
    name: 'Maio',
  },
  {
    value: 'MobFox',
    label: 'MobFox',
    name: 'MobFox',
  },
  {
    value: 'MoPub',
    label: 'MoPub',
    name: 'MoPub',
  },
  {
    value: 'myTarget',
    label: 'myTarget',
    name: 'myTarget',
  },
  {
    value: 'Nend',
    label: 'Nend',
    name: 'Nend',
  },
  {
    value: 'One by AOL (Millenial Media)',
    label: 'One by AOL (Millenial Media)',
    name: 'One by AOL (Millenial Media)',
  },
  {
    value: 'One by AOL (Nexage)',
    label: 'One by AOL (Nexage)',
    name: 'One by AOL (Nexage)',
  },
  {
    value: 'Pangle',
    label: 'Pangle',
    name: 'Pangle',
  },
  {
    value: 'Tapjoy',
    label: 'Tapjoy',
    name: 'Tapjoy',
  },
  {
    value: 'Tencent GDT',
    label: 'Tencent GDT',
    name: 'Tencent GDT',
  },
  {
    value: 'Unity Ads',
    label: 'Unity Ads',
    name: 'Unity Ads',
  },
  {
    value: 'Vpon',
    label: 'Vpon',
    name: 'Vpon',
  },
  {
    value: 'Vungle',
    label: 'Vungle',
    name: 'Vungle',
  },
  {
    value: 'Yandex',
    label: 'Yandex',
    name: 'Yandex',
  },
  {
    value: 'Zucks',
    label: 'Zucks',
    name: 'Zucks',
  },
]
const sort = ['1st', '2nd', '3rd']
const MetricsFormView = (props: any, ref: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, getPreMetrics, putPreMetrics } =
    useContext(MetricsFormContext)
  const { state: globalState } = useContext(GlobalContext)
  const [dialogData, setDialogData] = useState(obj)
  const [mediation, setMediation] = useState([])
  const [adNetwork, setAdNetwork] = useState([])
  const [overallDisabledButton, setOverallDisabledButton] = useState(false)
  const [adTypeErrorList, setAdTypeErrorList] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  const [countryErrorList, setCountryErrorList] = useState([
    false,
    false,
    false,
  ])
  // submit按钮禁用
  const disabledButton = useMemo(() => {
    let temp = false
    if (
      (!dialogData.dau && dialogData.dau !== 0) ||
      (!dialogData.ecpm && dialogData.ecpm !== 0) ||
      (!dialogData.impressions && dialogData.impressions !== 0) ||
      (!dialogData.revenue && dialogData.revenue !== 0)
    ) {
      temp = true
    }
    if (
      !dialogData.ad_types[0].checked &&
      !dialogData.ad_types[1].checked &&
      !dialogData.ad_types[2].checked &&
      !dialogData.ad_types[3].checked &&
      !dialogData.ad_types[4].checked &&
      !dialogData.ad_types[5].checked &&
      !dialogData.ad_types[6].checked
    ) {
      temp = true
    } else {
      const flag = dialogData.ad_types.find((item: any) => {
        if (item.checked) {
          return (
            (item.metric.ecpm || item.metric.ecpm === 0) &&
            (item.metric.impressions || item.metric.impressions === 0) &&
            (item.metric.revenue || item.metric.revenue === 0)
          )
        }
      })
      if (!flag) {
        temp = true
      }
      if (dialogData.ad_types[6].checked && !dialogData.ad_types[6].specify) {
        temp = true
      }
    }
    if (
      !dialogData.legal_frameworks?.GDPR &&
      !dialogData.legal_frameworks?.CCPA &&
      !dialogData.legal_frameworks?.COPPA &&
      !dialogData.legal_frameworks?.complied_legal_frameworks
    ) {
      temp = true
    }
    if (
      !dialogData.countries_info[0].country_name &&
      !dialogData.countries_info[1].country_name &&
      !dialogData.countries_info[2].country_name
    ) {
      temp = true
    }
    if (mediation.length) {
      const mediationTemp = mediation.find((item: any) => {
        return (
          (!item.revenue && item.revenue !== 0) ||
          (!item.impressions && item.impressions !== 0) ||
          item.showError
        )
      })
      if (mediationTemp) {
        temp = true
      }
    }
    if (adNetwork.length) {
      const adNetworkTemp = adNetwork.find((item: any) => {
        return (
          (!item.revenue && item.revenue !== 0) ||
          (!item.impressions && item.impressions !== 0) ||
          item.showError
        )
      })
      if (adNetworkTemp) {
        temp = true
      }
    }
    if (countryErrorList.includes(true)) {
      temp = true
    }
    if (adTypeErrorList.includes(true)) {
      temp = true
    }
    return temp
  }, [dialogData, mediation, adNetwork, countryErrorList, adTypeErrorList])

  const openDialog = (app_key: string) => {
    getPreMetrics(app_key)
    dispatch({
      type: 'metricsDialog',
      payload: true,
    })
  }
  useImperativeHandle(ref, () => ({
    openDialog,
  }))
  const saveMetrics = () => {
    const mediation_name = mediation.length ? mediation : null
    const network_name = adNetwork.length ? adNetwork : null
    putPreMetrics(
      props.app_key,
      { ...dialogData, mediation: mediation_name, network: network_name },
      { onClose: props.onClose, getNewData: props.getNewData }
    )
  }
  const handleClose = () => {
    props.onClose && props.onClose()
    dispatch({
      type: 'metricsDialog',
      payload: false,
    })
  }
  const handleChange = (e: any, index: number) => {
    const { checked } = e.target
    const temp = JSON.parse(JSON.stringify(dialogData))
    temp.ad_types[index].checked = checked
    setDialogData(temp)
  }
  const mediationInputChange = (e: any, index: number) => {
    const { name, value } = e.target
    const temp = JSON.parse(JSON.stringify(mediation))
    temp[index][name] = value
    setMediation(temp)
  }
  const adNetworkInputChange = (e: any, index: number) => {
    const { name, value } = e.target
    const temp = JSON.parse(JSON.stringify(adNetwork))
    temp[index][name] = value
    setAdNetwork(temp)
  }
  const legalFrameworksHandleChange = (e: any) => {
    const { name, checked } = e.target
    setDialogData({
      ...dialogData,
      legal_frameworks: {
        ...dialogData.legal_frameworks,
        [name]: checked || null,
      },
    })
  }
  const countryChange = (value: string, index: number) => {
    const temp = JSON.parse(JSON.stringify(dialogData))
    temp.countries_info[index].country_name = value
    setDialogData(temp)
  }
  const specifyChange = (e: any) => {
    const { value } = e.target
    const temp = JSON.parse(JSON.stringify(dialogData))
    temp.ad_types[6].specify = value
    setDialogData(temp)
  }
  const verifyImpression = (list: any, index: number, setFunc: any) => {
    if (
      list[index]?.revenue > 0 &&
      (list[index]?.impressions === 0 || list[index]?.impressions === '0')
    ) {
      list[index].showError = true
    } else {
      list[index].showError = false
    }
    setFunc(JSON.parse(JSON.stringify(list)))
  }
  useEffect(() => {
    setDialogData(state.preMetrics)
    if (state.preMetrics.mediation) {
      const mediation_name = state.preMetrics.mediation.map((item: any) => {
        return {
          value: item.name,
          label: item.name,
          name: item.name,
          revenue: item.revenue,
          impressions: item.impressions,
        }
      })
      setMediation(mediation_name)
    } else {
      setMediation([])
    }
    if (state.preMetrics.network) {
      const network_name = state.preMetrics.network.map((item: any) => {
        return {
          value: item.name,
          label: item.name,
          name: item.name,
          revenue: item.revenue,
          impressions: item.impressions,
        }
      })
      setAdNetwork(network_name)
    } else {
      setAdNetwork([])
    }
  }, [state.preMetrics])
  return (
    <Dialog
      sx={{
        zIndex: 1999,
        '& .MuiPaper-root.MuiPaper-elevation': { margin: '0' },
      }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={state.metricsDialog}
    >
      <Box className={classes.dialog}>
        <CloseIcon className={classes.closeFilterIcon} onClick={handleClose} />
        <Box className={classes.title}>Pre-MAS metrics</Box>
        <Box className={classes.text} marginBottom="0">
          MAS learns from your previous monetization setup and optimizes your
          revenue.
        </Box>
        <Box className={classes.text} marginTop="8px">
          Please fill in the most accurate data for the last 30 days before you
          onboarded your game on MAS.
        </Box>
        <Box className={classes.dialogContent}>
          <form noValidate autoComplete="off">
            <Box marginTop="-24px" />
            {/* Previous Monetization solution */}
            <Box className={classes.inputTitle}>
              Previous Monetization solution
            </Box>
            <Box className={classes.formSearchList}>
              <Box className={classes.label}>Previous Mediation:</Box>
              <Box width="100%">
                <StyleSelectMultiple
                  label=""
                  placeholder="Select Mediation(s)"
                  selectStyle={true}
                  showSearch={false}
                  showSelectAll={false}
                  value={mediation}
                  onChange={(element: any) => {
                    setMediation(element)
                  }}
                  options={mediationList}
                  disabled={globalState.accountAuthority.game_manage_page !== 2}
                />
              </Box>
            </Box>
            {mediation.map((item: any, index: any) => (
              <Box
                display={globalState.isMobile ? 'block' : 'flex'}
                key={item.value}
                marginTop="16px"
              >
                <Box
                  className={classes.formSearchList}
                  lineHeight="20px"
                  display="flex"
                  alignItems="center"
                  flex={1}
                >
                  {item.value} *
                </Box>
                <Box sx={{ flex: 2 }}>
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      className={classes.formSearchList}
                      flex={1}
                      margin={globalState.isMobile ? '0 16px 0 0' : '0 16px'}
                    >
                      <Box className={classes.label} margin="4px 0 8px 0">
                        Last 30 Day Revenue
                      </Box>
                      <Box width="100%">
                        {state.preMetricsLoading ? (
                          <Skeleton
                            variant="rectangular"
                            className={classes.formInputSearch}
                            height={40}
                          />
                        ) : (
                          <TextField
                            className={classes.formInputSearch}
                            name="revenue"
                            placeholder="$"
                            variant="outlined"
                            value={item.revenue}
                            onChange={(e: any) => {
                              mediationInputChange(e, index)
                            }}
                            onBlur={() => {
                              verifyImpression(mediation, index, setMediation)
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom as any,
                            }}
                            disabled={
                              globalState.accountAuthority.game_manage_page !==
                              2
                            }
                          />
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.formSearchList} flex={1}>
                      <Box className={classes.label} margin="4px 0 8px 0">
                        Last 30 Day Impressions
                      </Box>
                      <Box width="100%">
                        {state.preMetricsLoading ? (
                          <Skeleton
                            variant="rectangular"
                            className={classes.formInputSearch}
                            height={40}
                          />
                        ) : (
                          <TextField
                            className={classes.formInputSearch}
                            name="impressions"
                            placeholder="Impressions"
                            variant="outlined"
                            value={item.impressions}
                            onChange={(e: any) => {
                              mediationInputChange(e, index)
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom as any,
                            }}
                            onBlur={() => {
                              verifyImpression(mediation, index, setMediation)
                            }}
                            disabled={
                              globalState.accountAuthority.game_manage_page !==
                              2
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {item.showError && (
                    <Box className={classes.errorDetail}>
                      Revenue is greater than 0. Hence, Impressions cannot be 0.
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
            <Box className={classes.formSearchList}>
              <Box className={classes.label}>Previous Ad Networks:</Box>
              <Box width="100%">
                <StyleSelectMultiple
                  label=""
                  placeholder="Select Ad Network(s)"
                  selectStyle={true}
                  showSearch={false}
                  showSelectAll={false}
                  value={adNetwork}
                  onChange={(element: any) => {
                    setAdNetwork(element)
                  }}
                  options={adNetworkList}
                  disabled={globalState.accountAuthority.game_manage_page !== 2}
                />
              </Box>
            </Box>
            {adNetwork.map((item: any, index: any) => (
              <Box
                display={globalState.isMobile ? 'block' : 'flex'}
                key={item.value}
                marginTop="16px"
              >
                <Box
                  className={classes.formSearchList}
                  lineHeight="20px"
                  display="flex"
                  alignItems="center"
                  flex={1}
                >
                  {item.value} *
                </Box>
                <Box sx={{ flex: 2 }}>
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      className={classes.formSearchList}
                      flex={1}
                      margin={globalState.isMobile ? '0 16px 0 0' : '0 16px'}
                    >
                      <Box className={classes.label} margin="4px 0 8px 0">
                        Last 30 Day Revenue
                      </Box>
                      <Box width="100%">
                        {state.preMetricsLoading ? (
                          <Skeleton
                            variant="rectangular"
                            className={classes.formInputSearch}
                            height={40}
                          />
                        ) : (
                          <TextField
                            className={classes.formInputSearch}
                            name="revenue"
                            placeholder="$"
                            variant="outlined"
                            value={item.revenue}
                            onChange={(e: any) => {
                              adNetworkInputChange(e, index)
                            }}
                            onBlur={() => {
                              verifyImpression(adNetwork, index, setAdNetwork)
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom as any,
                            }}
                            disabled={
                              globalState.accountAuthority.game_manage_page !==
                              2
                            }
                          />
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.formSearchList} flex={1}>
                      <Box className={classes.label} margin="4px 0 8px 0">
                        Last 30 Day Impressions
                      </Box>
                      <Box width="100%">
                        {state.preMetricsLoading ? (
                          <Skeleton
                            variant="rectangular"
                            className={classes.formInputSearch}
                            height={40}
                          />
                        ) : (
                          <TextField
                            className={classes.formInputSearch}
                            name="impressions"
                            placeholder="Impressions"
                            variant="outlined"
                            value={item.impressions}
                            onChange={(e: any) => {
                              adNetworkInputChange(e, index)
                            }}
                            onBlur={() => {
                              verifyImpression(adNetwork, index, setAdNetwork)
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom as any,
                            }}
                            disabled={
                              globalState.accountAuthority.game_manage_page !==
                              2
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {item.showError && (
                    <Box className={classes.errorDetail}>
                      Revenue is greater than 0. Hence, Impressions cannot be 0.
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
            {/* Overall */}
            <Box className={classes.inputTitle}>Overall *</Box>
            <OverallFormView
              preMetricsLoading={state.preMetricsLoading}
              dau={dialogData.dau}
              revenue={dialogData.revenue}
              ecpm={dialogData.ecpm}
              impressions={dialogData.impressions}
              disabled={globalState.accountAuthority.game_manage_page !== 2}
              onChange={(dau, impressions, ecpm, revenue) => {
                setDialogData({
                  ...dialogData,
                  dau,
                  impressions,
                  ecpm,
                  revenue,
                })
              }}
              onDisabledButtonChange={disabled => {
                setOverallDisabledButton(disabled)
              }}
            />
            {/* Previous Legal Frameworks Compliance */}
            <Box className={classes.inputTitle}>
              Previous Legal Frameworks Compliance *
            </Box>
            <Box className={classes.typeFormList}>
              <Box className={classes.typeFormLabel} width="100%">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="GDPR"
                      checked={!!dialogData.legal_frameworks?.GDPR}
                      onChange={legalFrameworksHandleChange}
                      disabled={
                        globalState.accountAuthority.game_manage_page !== 2
                      }
                    />
                  }
                  label="GDPR"
                  classes={{ label: classes.checkedLabel }}
                />
              </Box>
            </Box>
            <Box className={classes.typeFormList}>
              <Box className={classes.typeFormLabel} width="100%">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="CCPA"
                      checked={!!dialogData.legal_frameworks?.CCPA}
                      onChange={legalFrameworksHandleChange}
                      disabled={
                        globalState.accountAuthority.game_manage_page !== 2
                      }
                    />
                  }
                  label="CCPA"
                  classes={{ label: classes.checkedLabel }}
                />
              </Box>
            </Box>
            <Box className={classes.typeFormList}>
              <Box className={classes.typeFormLabel} width="100%">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="COPPA"
                      checked={!!dialogData.legal_frameworks?.COPPA}
                      onChange={legalFrameworksHandleChange}
                      disabled={
                        globalState.accountAuthority.game_manage_page !== 2
                      }
                    />
                  }
                  label="COPPA"
                  classes={{ label: classes.checkedLabel }}
                />
              </Box>
            </Box>
            <Box className={classes.typeFormList}>
              <Box className={classes.typeFormLabel} width="100%">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="complied_legal_frameworks"
                      checked={
                        !!dialogData.legal_frameworks?.complied_legal_frameworks
                      }
                      onChange={legalFrameworksHandleChange}
                      disabled={
                        globalState.accountAuthority.game_manage_page !== 2
                      }
                    />
                  }
                  label="Never complied to a legal framework"
                  classes={{ label: classes.checkedLabel }}
                />
              </Box>
            </Box>
            {/* ad types */}
            <Box className={classes.inputTitle}>
              Ad Types *
              <Tooltip
                title="At least select one ad type"
                arrow
                placement="top"
                disableInteractive
              >
                <InfoOutlinedIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <>
              {dialogData?.ad_types &&
                dialogData.ad_types.map((item: any, index: number) => (
                  <Box key={index} marginTop="12px">
                    <Box
                      className={classes.typeFormLabel}
                      display="flex"
                      width="100%"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={item.key}
                            checked={item.checked}
                            onChange={(e: any) => {
                              handleChange(e, index)
                            }}
                          />
                        }
                        label={item.label}
                        classes={{ label: classes.checkedLabel }}
                        disabled={
                          globalState.accountAuthority.game_manage_page !== 2
                        }
                      />
                      {item.key === 'other' && (
                        <TextField
                          className={classes.formInputSearch}
                          placeholder="Specify"
                          variant="outlined"
                          value={item.specify}
                          disabled={!item.checked}
                          onChange={specifyChange}
                          style={{ width: '200px' }}
                        />
                      )}
                    </Box>
                    <AdTypeFormView
                      preMetricsLoading={state.preMetricsLoading}
                      revenue={item.metric.revenue}
                      ecpm={item.metric.ecpm}
                      impressions={item.metric.impressions}
                      disabled={
                        !item.checked ||
                        globalState.accountAuthority.game_manage_page !== 2
                      }
                      onChange={(impressions, ecpm, revenue) => {
                        const temp = JSON.parse(JSON.stringify(dialogData))
                        temp.ad_types[index].metric.impressions = impressions
                        temp.ad_types[index].metric.ecpm = ecpm
                        temp.ad_types[index].metric.revenue = revenue
                        setDialogData(temp)
                      }}
                      onDisabledButtonChange={disabled => {
                        const listTemp = JSON.parse(
                          JSON.stringify(adTypeErrorList)
                        )
                        listTemp[index] = disabled
                        setAdTypeErrorList(listTemp)
                      }}
                    />
                  </Box>
                ))}
            </>
            {/* Top 3 countries by revenue * */}
            <Box className={classes.inputTitle}>
              <Tooltip
                title="Ad at least one country"
                arrow
                placement="top"
                disableInteractive
              >
                <span>Top 3 countries by revenue *</span>
              </Tooltip>
            </Box>
            <>
              {[1, 2, 3].map((item: number, index: number) => (
                <Box className={classes.formSearchList} key={item}>
                  <Box className={classes.label}>
                    {sort[index]} Country{index === 0 ? ' *' : ''}
                  </Box>
                  <Box width="100%">
                    {state.preMetricsLoading ? (
                      <Skeleton
                        variant="rectangular"
                        className={classes.formInputSearch}
                        height={40}
                      />
                    ) : (
                      <>
                        <StyleSelectOne
                          placeholder="Select Country"
                          value={dialogData.countries_info[index].country_name}
                          valueKey="name"
                          labelKey="name"
                          selectStyle={true}
                          onChange={(value: any) => {
                            countryChange(value, index)
                          }}
                          disabled={
                            globalState.accountAuthority.game_manage_page !==
                              2 ||
                            (index === 1 &&
                              !dialogData.countries_info[0].country_name) ||
                            (index === 2 &&
                              !dialogData.countries_info[1].country_name)
                          }
                          options={globalState.countryOption}
                          showSearch
                        />
                        {dialogData.countries_info[index].country_name && (
                          <>
                            <CountryFormView
                              preMetricsLoading={state.preMetricsLoading}
                              dau={dialogData.countries_info[index].metric.dau}
                              revenue={
                                dialogData.countries_info[index].metric.revenue
                              }
                              ecpm={
                                dialogData.countries_info[index].metric.ecpm
                              }
                              impressions={
                                dialogData.countries_info[index].metric
                                  .impressions
                              }
                              disabled={
                                globalState.accountAuthority
                                  .game_manage_page !== 2
                              }
                              onChange={(dau, impressions, ecpm, revenue) => {
                                const temp = JSON.parse(
                                  JSON.stringify(dialogData)
                                )
                                temp.countries_info[index].metric.dau = dau
                                temp.countries_info[index].metric.impressions =
                                  impressions
                                temp.countries_info[index].metric.ecpm = ecpm
                                temp.countries_info[index].metric.revenue =
                                  revenue
                                setDialogData(temp)
                              }}
                              onDisabledButtonChange={disabled => {
                                const listTemp = JSON.parse(
                                  JSON.stringify(countryErrorList)
                                )
                                listTemp[index] = disabled
                                setCountryErrorList(listTemp)
                              }}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </>
          </form>
        </Box>
        {globalState.accountAuthority.game_manage_page === 2 && (
          <Box>
            {state.preMetricsLoading ? (
              <Box display="flex" justifyContent="center">
                <Skeleton
                  variant="rectangular"
                  className={classes.footerButton}
                  height={43}
                />
              </Box>
            ) : (
              <Button
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={saveMetrics}
                disabled={
                  globalState.accountAuthority.game_manage_page !== 2 ||
                  disabledButton ||
                  overallDisabledButton
                }
              >
                {state.submitMetricsLoading ? (
                  <Box>
                    <LoadingBtn
                      loadingStyle={{
                        justifyContent: 'center',
                      }}
                    />
                  </Box>
                ) : (
                  'Submit'
                )}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default forwardRef(MetricsFormView)
