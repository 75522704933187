import env from 'src/env'

const tipaltiConfig = {
  payer: 'Yodo1',
  path: {
    home: 'payeedashboard/home',
    history: 'PayeeDashboard/PaymentsHistory',
  },
  iframe: '{domain}/{path}?idap={idap}&payer={payer}&ts={ts}&hashkey={hashkey}',
  ts: parseInt(String(Date.now() / 1000), 10),
  masterKey: env.tipaltiMasterKey,
  domain: env.tipaltiDomain,
}

export { tipaltiConfig }
