import { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { SVGIcon } from 'src/images'

/**
 * @param value 当前选择的值
 * @param hideOption 不展示的选项
 * @function onChange 修改时的回调
 */
interface ViewBySelectProps {
  value: string
  hideOption?: string[]
  margin?: string
  onChange: (value: string) => void
}
const list = [
  {
    label: 'All',
    value: 'all',
    icon: SVGIcon.ViewByAllSVG,
  },
  {
    label: 'Apps',
    value: 'application',
    icon: SVGIcon.ViewByAppsSVG,
  },
  {
    label: 'Ad Types',
    value: 'ad_type',
    icon: SVGIcon.ViewByAdTypesSVG,
  },
  {
    label: 'Countries',
    value: 'country',
    icon: SVGIcon.ViewByCountriesSVG,
  },
  {
    label: 'Platforms',
    value: 'platform',
    icon: SVGIcon.ViewByPlatformsSVG,
  },
]

const ViewBySelectView = (props: ViewBySelectProps) => {
  const { value = 'all', hideOption = [], onChange } = props
  const newList = useMemo(() => {
    if (hideOption.length) {
      return list.filter(item => !hideOption.includes(item.value))
    }
    return list
  }, [hideOption])
  return (
    <Box
      sx={{
        display: 'flex',
        marginRight: '-6px',
        flexWrap: 'wrap',
        margin: props.margin ? props.margin : '16px 0 0',
      }}
    >
      {/* <Box sx={{ fontSize: '12px', lineHeight: '28px', marginRight: '2px' }}>
        View by
      </Box> */}
      {newList.map(item => (
        <Box
          key={item.value}
          sx={{
            height: '28px',
            lineHeight: '28px',
            cursor: 'pointer',
            padding: '0 10px',
            background: item.value === value ? 'rgba(30, 150, 252, 0.05)' : '',
          }}
          onClick={() => {
            if (item.value !== value) {
              onChange(item.value)
            }
          }}
        >
          <item.icon
            color={item.value === value ? '#1E96FC' : '#AECBFA'}
            style={{ verticalAlign: 'middle', transform: 'translateY(-1px)' }}
          />
          <Box
            component="span"
            sx={{
              fontSize: '14px',
              color: item.value === value ? '#1E96FC' : '#AECBFA',
              marginLeft: '4px',
            }}
          >
            {item.label}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default ViewBySelectView
