const SVG = (props: any) => {
  const width = props.width || '57'
  const height = props.height || '32'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 57 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.960448 6.68454L0.960449 25.3173C0.964178 28.6055 3.6288 31.2701 6.91704 31.2734L51.0042 31.2734C54.2924 31.2701 56.9571 28.6055 56.9603 25.3173L56.9602 6.68453C56.957 3.39633 54.2924 0.731733 51.0041 0.727934L6.91704 0.727936C3.6288 0.731735 0.964177 3.39634 0.960448 6.68454ZM51.0041 3.27374C52.8871 3.27564 54.4131 4.80153 54.4149 6.68453L54.4149 25.3173C54.4131 27.2003 52.8871 28.7262 51.0042 28.7281L6.91704 28.7281C5.03358 28.7262 3.50761 27.2003 3.50575 25.3173L3.50575 6.68454C3.50761 4.80154 5.03358 3.27564 6.91704 3.27374L51.0041 3.27374Z"
        fill={color}
      />
      <path
        d="M6.05029 12.1825L6.05029 19.8189C6.05029 20.5217 6.61985 21.0918 7.32271 21.0918C8.02557 21.0918 8.59559 20.5217 8.59559 19.8189L8.59559 12.1825C8.59559 11.4797 8.02557 10.9101 7.32271 10.9101C6.61985 10.9101 6.05029 11.4797 6.05029 12.1825Z"
        fill={color}
      />
      <path
        d="M49.3261 13.4559C50.7322 13.4559 51.8718 14.5955 51.8718 16.0012C51.8718 17.4074 50.7322 18.5469 49.3261 18.5469C47.9203 18.5469 46.7808 17.4074 46.7808 16.0012C46.7808 14.5955 47.9203 13.4559 49.3261 13.4559Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
