import { Box, Dialog } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { useTheme, Theme } from '@material-ui/core'

interface dialogProps {
  open: boolean
  eventData: any[]
  onClose: () => void
}
const View = (props: dialogProps) => {
  const theme: Theme = useTheme()

  // returns
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <Box
        sx={{
          boxSizing: 'border-box',
          padding: '24px 24px 18px',
          width: '90vw',
          maxWidth: '430px',
          background: theme.palette.custom.dialogBg,
          fontSize: '14px',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            textAlign: 'center',
            color: theme.palette.primary.main,
            marginBottom: '12px',
            fontSize: '16px',
          }}
        >
          <CloseIcon
            sx={{
              position: 'absolute',
              fontSize: '24px !important',
              right: '0px',
              top: '-3px',
              cursor: 'pointer',
              color: theme.palette.text.primary,
            }}
            onClick={props.onClose}
          />
          Yodo1 Optimization
        </Box>
        {props.eventData.map(
          (item: { name: string; message: string }, index: number) => (
            <Box key={index} sx={{ lineHeight: '16px', marginBottom: '6px' }}>
              {item.name}: {item.message}
            </Box>
          )
        )}
      </Box>
    </Dialog>
  )
}

export default View
