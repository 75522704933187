import { useState, useMemo } from 'react'
import { Box, Popover, InputBase, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      popover: {
        '& .MuiPaper-root': {
          minWidth: '200px',
          maxWidth: '500px',
          maxHeight: '70vh',
          marginTop: '14px',
          borderRadius: '6px',
          padding: '0',
          background: theme.palette.custom.dialogBg,
        },
      },
      input: {
        width: '100%',
        boxSizing: 'border-box',
        height: '32px',
      },
      option: {
        width: '100%',
        display: 'flex',
        padding: '6px 14px',
        cursor: 'pointer',
        color: theme.palette.custom.inputLabel,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
          background:
            theme.palette.mode === 'light'
              ? 'rgba(224, 229, 231, 0.5)'
              : '#555D6A',
        },
      },
      checked: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        background:
          theme.palette.mode === 'light'
            ? 'rgba(224, 229, 231, 0.5)'
            : '#555D6A',
      },
      selectStyle: {
        display: 'flex',
        boxSizing: 'border-box',
        width: '100%',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '8px 8px',
        margin: '0',
        justifyContent: 'space-between',
        border: `1px solid ${
          theme.palette.mode === 'dark' ? '#51565F' : '#eee'
        }`,
        borderRadius: '4px',
      },
      defaultStyle: {
        display: 'flex',
        boxSizing: 'border-box',
        width: '210px',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '8px 16px',
        margin: '7px 0',
        justifyContent: 'space-between',
      },
    }
  })

interface selectProps {
  placeholder?: string
  labelKey?: string
  customClass?: any
  valueKey?: string
  label?: string
  showSearch?: boolean
  selectStyle?: boolean
  showSelectAll?: boolean
  options: any[]
  value: any[]
  disabled?: boolean
  onChange: (item: any[]) => void
}
export default function StyleSelectMultiple(props: selectProps) {
  const {
    placeholder = '',
    labelKey = 'label',
    valueKey = 'value',
    label = '',
    showSearch = true,
    selectStyle = false,
    showSelectAll = true,
    options,
    value,
    onChange,
    disabled = false,
  } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const checkedValue = useMemo(() => {
    return value.map((item: any) => item[valueKey])
    // eslint-disable-next-line
  }, [value])
  const showOption = useMemo(() => {
    if (searchValue) {
      return options.filter((item: any) =>
        item[labelKey].toUpperCase().includes(searchValue.toUpperCase().trim())
      )
    }
    return options
    // eslint-disable-next-line
  }, [options, searchValue])

  const handleClick = (event: any) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const searchChange = (e: any) => {
    setSearchValue(e.target.value)
  }
  const toCheck = (item: any) => {
    if (checkedValue.includes(item[valueKey])) {
      const temp = value.filter(
        (element: any) => element[valueKey] !== item[valueKey]
      )
      onChange(temp)
    } else {
      onChange([...value, item])
    }
  }
  const selectAll = () => {
    if (value.length === options.length) {
      onChange([])
    } else {
      onChange(options)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <Box>
      <Box
        className={clsx({
          [classes.selectStyle]: selectStyle,
          [classes.defaultStyle]: !selectStyle,
          [props.customClass]: !!props.customClass,
        })}
        aria-describedby={id}
        onClick={handleClick}
        id={'multipleSelect' + label}
        sx={disabled ? { cursor: 'not-allowed', opacity: 0.4 } : {}}
      >
        <Box display="flex" width="100%">
          {label && (
            <Box
              sx={{
                marginRight: '8px',
                color: theme.palette.text.secondary,
                whiteSpace: 'nowrap',
              }}
            >
              {label}:
            </Box>
          )}
          <Box
            sx={{
              maxWidth: selectStyle ? '100%' : '90px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value.length === 0 ? (
              <span style={{ opacity: 0.4 }}>{placeholder}</span>
            ) : value.length === 1 ? (
              value[0][labelKey]
            ) : (
              `${value.length} Selected`
            )}
          </Box>
        </Box>
        <SVGIcon.ChevronDownSVG
          color={theme.palette.text.primary}
          style={{
            marginTop: '3px',
            transform: anchorEl ? 'rotate(180deg)' : '',
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
        sx={{ zIndex: 1999 }}
      >
        <Box fontSize="14px" padding="8px 0" maxWidth="90vw">
          {/* search input */}
          {showSearch && (
            <Box position="relative" margin="0px 8px 2px">
              <InputBase
                className={classes.input}
                placeholder="Search"
                value={searchValue}
                onChange={searchChange}
                style={{
                  background: theme.palette.custom.dialogBg,
                  border: `1px solid ${theme.palette.custom.paperBorder}`,
                  borderRadius: '6px',
                  boxShadow: 'none',
                }}
              />
              <SVGIcon.SearchIconSVG
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '8px',
                }}
              />
            </Box>
          )}
          {showOption.length ? (
            <>
              {/* select all */}
              {showSelectAll && (
                <Box
                  className={clsx({
                    [classes.option]: true,
                    [classes.checked]: value.length === options.length,
                  })}
                  onClick={selectAll}
                >
                  {value.length === options.length ? (
                    <SVGIcon.CheckedBoxSVG
                      color={
                        theme.palette.mode === 'light'
                          ? ''
                          : theme.palette.primary.main
                      }
                      style={{
                        marginRight: '4px',
                      }}
                    />
                  ) : (
                    <SVGIcon.UncheckedBoxSVG
                      color={
                        theme.palette.mode === 'light'
                          ? ''
                          : 'rgba(255, 255, 255, 0.7)'
                      }
                      style={{
                        marginRight: '4px',
                      }}
                    />
                  )}
                  <Box lineHeight="24px">Select All</Box>
                </Box>
              )}
              {/* options */}
              <Box>
                {showOption.map((item: any, index: number) => (
                  <Box
                    className={clsx({
                      [classes.option]: true,
                      [classes.checked]: checkedValue.includes(item[valueKey]),
                    })}
                    key={index}
                    onClick={() => {
                      toCheck(item)
                    }}
                  >
                    {checkedValue.includes(item[valueKey]) ? (
                      <SVGIcon.CheckedBoxSVG
                        color={
                          theme.palette.mode === 'light'
                            ? ''
                            : theme.palette.primary.main
                        }
                        style={{
                          marginRight: '4px',
                        }}
                      />
                    ) : (
                      <SVGIcon.UncheckedBoxSVG
                        color={
                          theme.palette.mode === 'light'
                            ? ''
                            : 'rgba(255, 255, 255, 0.7)'
                        }
                        style={{
                          marginRight: '4px',
                        }}
                      />
                    )}
                    <Box lineHeight="24px">{item[labelKey]}</Box>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <Box textAlign="center" padding="16px">
              No Data
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  )
}
