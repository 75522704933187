import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Outlet } from 'react-router-dom'
import { SettingsContextProvider } from 'src/context/settings'
import TwoFaView from './twoFa'
import { StylePaper } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: 645,
        minWidth: 435,
        margin: 'auto',
      },
      top: {
        padding: '27px 0',
        fontSize: '27px',
        fontWeight: 900,
        color: theme.palette.text.primary,
      },
      settingBody: {
        padding: '64px 48px',
      },
      buttonGroup: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        color: theme.palette.text.primary,
      },
      switchButton: {
        height: '32px',
        fontWeight: 'normal',
        padding: '0 12px',
        backgroundColor: theme.palette.background.paper,
      },
      activeSwitchButton: {
        backgroundColor: theme.palette.grey['A200'],
      },
    }
  })

const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const className = useStyles()

  return (
    <SettingsContextProvider>
      <Box className={className.container}>
        <Box className={className.top}>My Account</Box>
        <StylePaper className={className.settingBody}>
          <Outlet />
        </StylePaper>
        <TwoFaView />
      </Box>
    </SettingsContextProvider>
  )
}

export default View
