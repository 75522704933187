const SVG = (props: any) => {
  const width = props.width || '69'
  const height = props.height || '57'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 69 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9502 53.4609C6.04581 53.4609 5.17847 53.1017 4.53897 52.4622C3.89947 51.8227 3.54019 50.9553 3.54019 50.0509V31.4109C3.54283 30.5073 3.90294 29.6415 4.54187 29.0026C5.1808 28.3637 6.04662 28.0036 6.9502 28.0009H40.1502V25.4609H6.9502C5.36752 25.4609 3.84892 26.0861 2.72513 27.2006C1.60134 28.315 0.963384 29.8283 0.950195 31.4109V50.0509C0.963384 51.6336 1.60134 53.1469 2.72513 54.2613C3.84892 55.3757 5.36752 56.001 6.9502 56.0009H51.0502C51.9274 56.0028 52.7941 55.81 53.5878 55.4365C54.3815 55.063 55.0825 54.518 55.6402 53.8409C55.7423 53.7209 55.8359 53.594 55.9202 53.4609H6.9502Z"
        fill={color}
      />
      <path
        d="M6.08984 36.9091V44.5492C6.11809 44.8678 6.26462 45.1643 6.50061 45.3803C6.7366 45.5963 7.04493 45.7161 7.36484 45.7161C7.68475 45.7161 7.99305 45.5963 8.22903 45.3803C8.46502 45.1643 8.61158 44.8678 8.63983 44.5492V36.9091C8.61158 36.5905 8.46502 36.294 8.22903 36.078C7.99305 35.862 7.68475 35.7422 7.36484 35.7422C7.04493 35.7422 6.7366 35.862 6.50061 36.078C6.26462 36.294 6.11809 36.5905 6.08984 36.9091Z"
        fill={color}
      />
      <path
        d="M62.2003 0H43.5603C41.9777 0.0131889 40.4644 0.651171 39.35 1.77496C38.2356 2.89876 37.6103 4.41733 37.6104 6V50.09C37.6234 51.664 38.2545 53.1698 39.3675 54.2829C40.4805 55.3959 41.9863 56.0269 43.5603 56.04H62.2003C63.0776 56.0419 63.9443 55.8491 64.738 55.4756C65.5317 55.1021 66.2327 54.5571 66.7903 53.88C67.2764 53.288 67.6462 52.6094 67.8803 51.88C68.0622 51.3077 68.1533 50.7105 68.1504 50.11V5.96002C68.1399 4.38429 67.51 2.87588 66.3967 1.76071C65.2834 0.645539 63.7761 0.0130968 62.2003 0ZM65.6104 50.05C65.6104 50.4978 65.5221 50.9412 65.3508 51.3549C65.1794 51.7687 64.9282 52.1446 64.6116 52.4612C64.2949 52.7779 63.919 53.0291 63.5053 53.2004C63.0916 53.3718 62.6482 53.46 62.2003 53.46H43.5603C43.1125 53.46 42.6691 53.3718 42.2554 53.2004C41.8417 53.0291 41.4658 52.7779 41.1491 52.4612C40.8325 52.1446 40.5813 51.7687 40.4099 51.3549C40.2385 50.9412 40.1504 50.4978 40.1504 50.05V5.96002C40.1504 5.51221 40.2385 5.06878 40.4099 4.65506C40.5813 4.24134 40.8325 3.86541 41.1491 3.54877C41.4658 3.23212 41.8417 2.98094 42.2554 2.80957C42.6691 2.6382 43.1125 2.55002 43.5603 2.55002H62.2003C62.6482 2.55002 63.0916 2.6382 63.5053 2.80957C63.919 2.98094 64.2949 3.23212 64.6116 3.54877C64.9282 3.86541 65.1794 4.24134 65.3508 4.65506C65.5221 5.06878 65.6104 5.51221 65.6104 5.96002V50.05Z"
        fill={color}
      />
      <path
        d="M56.7 5.08984H49.06C48.7414 5.11809 48.4448 5.26465 48.2289 5.50064C48.0129 5.73663 47.8931 6.04496 47.8931 6.36487C47.8931 6.68478 48.0129 6.99308 48.2289 7.22906C48.4448 7.46505 48.7414 7.61161 49.06 7.63986H56.7C57.0187 7.61161 57.3152 7.46505 57.5312 7.22906C57.7472 6.99308 57.867 6.68478 57.867 6.36487C57.867 6.04496 57.7472 5.73663 57.5312 5.50064C57.3152 5.26465 57.0187 5.11809 56.7 5.08984Z"
        fill={color}
      />
      <path
        d="M55.4301 48.3704C55.4301 48.8747 55.2806 49.3677 55.0004 49.787C54.7202 50.2064 54.3219 50.5332 53.8559 50.7262C53.39 50.9192 52.8773 50.9697 52.3826 50.8713C51.888 50.7729 51.4336 50.5301 51.077 50.1735C50.7204 49.8168 50.4775 49.3625 50.3791 48.8678C50.2807 48.3732 50.3312 47.8604 50.5242 47.3945C50.7172 46.9285 51.0441 46.5303 51.4634 46.2501C51.8827 45.9699 52.3757 45.8203 52.8801 45.8203C53.2153 45.819 53.5475 45.8841 53.8575 46.0117C54.1675 46.1394 54.4491 46.3272 54.6862 46.5643C54.9232 46.8013 55.111 47.083 55.2387 47.3929C55.3663 47.7029 55.4314 48.0351 55.4301 48.3704Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
