import { useContext, useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import { SVGIcon } from 'src/images'
import { toThousands } from 'src/utils'
import { SummaryContext } from 'src/context/performance/summary'
import { PerformanceContext } from 'src/context/performance'
import { GlobalContext } from 'src/context/global'
import TableSetting from './tableSetting'
import { SortButton } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '100%',
      },
      container: {},
      game: {
        display: 'flex',
        alignItems: 'center',
      },
      num: {
        width: '20px',
        transform: 'translateX(-16px)',
      },
      gamePhoto: {
        width: '26px',
        height: '26px',
        verticalAlign: 'middle',
        borderRadius: '4px',
      },
      caption: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: '18px',
      },
      textPrimaryColor: {
        color: theme.palette.text.primary,
      },
      textSecondaryColor: {
        color: theme.palette.text.secondary,
      },
      mobileCard: {
        padding: '20px',
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      tableHead: {
        color: `${theme.palette.text.primary} !important`,
        backgroundColor: `${theme.palette.background.paper} !important`,
      },
    }
  })
const summaryTitle = {
  all: 'Date',
  application: 'Application',
  platform: 'Platform',
  country: 'Country',
  ad_type: 'Ad Type',
  network: 'Network',
}
const loadingRow = ['', '', '']

const TableView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, sortList, exportData } = useContext(SummaryContext)
  const { state: performanceState } = useContext(PerformanceContext)
  const { state: globalState } = useContext(GlobalContext)
  const allTableListRow = useMemo(() => {
    return state.tableList.rows.filter((item: any) => item.game)
  }, [state.tableList])
  const headerList = useMemo(() => {
    const temp = state.tableList.columns.filter(
      (item: { id: string; label: string }) => {
        return (
          performanceState.checkedMetrics.includes(item.id) ||
          item.id === 'game'
        )
      }
    )
    return temp
  }, [state.tableList.columns, performanceState.checkedMetrics])

  return (
    <TableContainer className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        height="26px"
        lineHeight="26px"
        position="relative"
        fontSize="20px"
        fontWeight={500}
        marginBottom="16px"
      >
        <Box>Summary</Box>
        <Box display="flex">
          {!!Object.keys(state.holdData).length && (
            <Box
              boxSizing="border-box"
              width="100px"
              height="28px"
              fontSize="12px"
              fontWeight={600}
              lineHeight="26px"
              textAlign="center"
              border={`1px solid ${theme.palette.text.primary}`}
              borderRadius="6px"
              marginRight="16px"
            >
              <Box
                onClick={() => {
                  exportData(headerList, allTableListRow)
                }}
                style={{
                  color: theme.palette.text.primary,
                  cursor: 'pointer',
                }}
              >
                Export
              </Box>
            </Box>
          )}
          <TableSetting />
        </Box>
      </Box>
      {!globalState.isMobile ? (
        <Box
          sx={{
            width: '100%',
            overflowX: 'auto',
            paddingBottom: '10px',
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ width: 'calc(100% - 2px)' }}
          >
            <TableHead>
              <TableRow>
                {headerList.map((column: any, i: number) => (
                  <TableCell
                    key={column.id + 'id1'}
                    align={column.align}
                    className={classes.tableHead}
                    style={{
                      minWidth: !i ? '260px' : '160px',
                      fontSize: '16px',
                      fontWeight: 400,
                      textAlign: !!i ? 'right' : 'left',
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={i ? 'flex-end' : 'flex-start'}
                    >
                      <Box>
                        {i
                          ? column.label
                          : summaryTitle[
                              performanceState.summaryGroupBy || 'all'
                            ] || 'Summary'}
                      </Box>
                      {!!i && (
                        <SortButton
                          sort={
                            state.sort.id === column.id ? state.sort.by : null
                          }
                          clickSort={(sort: string) => {
                            sortList({ id: column.id, by: sort })
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(state.summaryLoading ? loadingRow : allTableListRow).map(
                (row: any, index: number) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index + 'row'}
                    >
                      {state.summaryLoading
                        ? headerList.map((column: any, i: number) => {
                            return (
                              <TableCell
                                key={i + 'column'}
                                style={{
                                  minWidth: !i ? '260px' : '160px',
                                  color: theme.palette.text.primary,
                                }}
                              >
                                {i ? (
                                  <Skeleton variant="text" />
                                ) : (
                                  <Box className={classes.game}>
                                    {performanceState.view ===
                                      'application' && (
                                      <Box marginRight="20px">
                                        <Skeleton
                                          variant="rectangular"
                                          className={classes.gamePhoto}
                                          width={26}
                                          height={26}
                                        />
                                      </Box>
                                    )}
                                    <Box>
                                      <Skeleton variant="text" width={100} />
                                    </Box>
                                  </Box>
                                )}
                              </TableCell>
                            )
                          })
                        : headerList.map((column: any, i: number) => {
                            const value = row[column.id]
                            return (
                              <TableCell
                                key={column.id + 'id2'}
                                align={column.align}
                                className={classes.textSecondaryColor}
                                style={{
                                  minWidth: !i ? '260px' : '160px',
                                  textAlign: i ? 'right' : 'left',
                                  fontSize: '14px',
                                  fontWeight: i ? 400 : 600,
                                }}
                              >
                                {i ? (
                                  <Box
                                    sx={{
                                      fontVariantNumeric: 'tabular-nums',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {(column.id === 'revenue' ||
                                      column.id === 'ecpm') &&
                                      '$'}
                                    {toThousands(value, true)}
                                  </Box>
                                ) : (
                                  <Box className={classes.game}>
                                    {performanceState.summaryGroupBy ===
                                      'application' && (
                                      <Box marginRight="20px">
                                        {row.icon ? (
                                          <img
                                            src={row.icon}
                                            className={classes.gamePhoto}
                                            alt=""
                                          />
                                        ) : (
                                          <SVGIcon.GameDefault
                                            width="26"
                                            height="26"
                                            style={{
                                              verticalAlign: 'middle',
                                            }}
                                            color={
                                              theme.palette.mode === 'light'
                                                ? '#EFF2F3'
                                                : '#2B313C'
                                            }
                                          />
                                        )}
                                      </Box>
                                    )}
                                    <Box className={classes.textPrimaryColor}>
                                      {value}
                                    </Box>
                                  </Box>
                                )}
                              </TableCell>
                            )
                          })}
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Box>
          {(state.summaryLoading ? loadingRow : allTableListRow).map(
            (row: any, i: number) => {
              return (
                <Box key={i + 'row2'} className={classes.mobileCard}>
                  <Box>
                    {state.summaryLoading
                      ? headerList.map((column: any, index: any) => {
                          if (index) {
                            return (
                              <Box
                                key={index + 'column2'}
                                display="flex"
                                justifyContent="space-between"
                                marginTop="10px"
                                color="#6E6E6E"
                              >
                                <Box>
                                  <Skeleton variant="text" width={100} />
                                </Box>
                                <Box>
                                  <Skeleton variant="text" width={100} />
                                </Box>
                              </Box>
                            )
                          } else {
                            return (
                              <div className={classes.game} key={index}>
                                {performanceState.view === 'application' && (
                                  <div>
                                    <Skeleton
                                      variant="rectangular"
                                      className={classes.gamePhoto}
                                      style={{
                                        marginLeft: '0',
                                        marginRight: '16px',
                                      }}
                                      width={26}
                                      height={26}
                                    />
                                  </div>
                                )}
                                <div>
                                  <Skeleton variant="text" width={100} />
                                </div>
                              </div>
                            )
                          }
                        })
                      : headerList.map((column: any, index: any) => {
                          if (index) {
                            return (
                              <Box
                                key={index + 'index'}
                                display="flex"
                                justifyContent="space-between"
                                marginTop="10px"
                                className={classes.textSecondaryColor}
                              >
                                <Box>{column.label}:</Box>
                                <Box
                                  sx={{ fontVariantNumeric: 'tabular-nums' }}
                                >
                                  {(column.id === 'revenue' ||
                                    column.id === 'ecpm') &&
                                    '$'}
                                  {toThousands(row[column.id], true)}
                                </Box>
                              </Box>
                            )
                          } else {
                            return (
                              <div className={classes.game} key={index}>
                                <div>
                                  {performanceState.summaryGroupBy ===
                                    'application' && (
                                    <>
                                      {row.icon ? (
                                        <img
                                          src={row.icon}
                                          className={classes.gamePhoto}
                                          style={{
                                            marginLeft: '0',
                                            marginRight: '16px',
                                          }}
                                          alt=""
                                        />
                                      ) : (
                                        <SVGIcon.GameDefault
                                          width="26"
                                          height="26"
                                          style={{
                                            marginRight: '16px',
                                            verticalAlign: 'middle',
                                          }}
                                          color={
                                            theme.palette.mode === 'light'
                                              ? '#EFF2F3'
                                              : '#2B313C'
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                                <Box className={classes.textPrimaryColor}>
                                  {row.game}
                                </Box>
                              </div>
                            )
                          }
                        })}
                  </Box>
                </Box>
              )
            }
          )}
        </Box>
      )}
    </TableContainer>
  )
}
export default TableView
