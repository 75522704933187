import { useContext, useEffect, useMemo, useRef } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { CompareGamesContext } from 'src/context/compareGames'
import { GlobalContext } from 'src/context/global'
import { init, EChartsType } from 'echarts'
import { getEchartOptions } from 'src/utils'
import ChartSettingView from './chartSetting'
import { LoadingComponent } from 'src/components'

const type = {
  interstitial: 'Interstitial',
  banner: 'Banner',
  reward_video: 'Rewarded Video',
  nativeads: 'Native Ads',
  openads: 'App Open',
  rewardinter: 'Rewarded Interstitial',
}
const colorList = ['#FF8E00', '#90cc75', '#f07474', '#5a70b5']

const ChartView = (props: any) => {
  const chart = useRef<EChartsType>()
  const theme: Theme = useTheme()
  const { state, dispatch } = useContext(CompareGamesContext)
  const { state: globalState } = useContext(GlobalContext)
  const loading = useMemo(() => {
    if (
      !state.firstDataLoading &&
      !state.secondDataLoading &&
      !state.thirdDataLoading &&
      !state.fourthDataLoading
    ) {
      return false
    }
    return true
  }, [
    state.firstDataLoading,
    state.secondDataLoading,
    state.thirdDataLoading,
    state.fourthDataLoading,
  ])
  const getChartDataFromApi = (data: any, game: any) => {
    if (
      ['revenue', 'impressions', 'ecpm', 'arpdau', 'impdau', 'dau'].includes(
        state.viewBy
      )
    ) {
      return [
        { name: game.name, value: data?.base[state.viewBy]?.values || [] },
      ]
    } else if (state.viewBy === 'country') {
      return data?.country?.map((item: any) => {
        const key = Object.keys(item)[0]
        return {
          name:
            game.name +
            ' - ' +
            (key === 'all'
              ? 'All'
              : globalState.countryListObject[key]?.name || key),
          value: item[key]?.revenue?.values || [],
        }
      })
    } else if (state.viewBy === 'ad_type') {
      return data?.ad_type?.data?.map((item: any) => {
        return {
          name: game.name + ' - ' + type[item.ad_type] || item.ad_type,
          value: item?.metric?.ratio_revenue || 0,
        }
      })
    } else if (state.viewBy === 'ad_placement') {
      return data?.ad_placement?.data?.map((item: any) => {
        return {
          name: `${game.name} - ${item.ad_placement} (${
            type[item.ad_type] || item.ad_type
          })`,
          value: item?.metric?.ratio_revenue || 0,
        }
      })
    }
  }
  const chartData = useMemo(() => {
    const days = state.firstDataForChart?.base?.revenue?.days || []
    const tempData: any = []
    state.dataKeys.forEach((item: string, index: number) => {
      if (state.gameSelect[index]?.app_key) {
        const temp = getChartDataFromApi(
          state[item + 'ForChart'],
          state.gameSelect[index]
        )
        tempData.push(...temp)
      }
    })
    const data = tempData.map(
      (item: { name: string; value: [] }, i: number) => {
        const color =
          colorList[i] ||
          '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
        return {
          name: item.name,
          type: 'line',
          smooth: true,
          itemStyle: { color: color },
          data: item.value,
        }
      }
    )
    dispatch({
      type: 'chartList',
      payload: data.map(
        (item: { name: string; itemStyle: { color: string } }) => {
          return {
            label: item.name,
            value: item.name,
            color: item.itemStyle.color,
          }
        }
      ),
    })
    dispatch({
      type: 'checkedChartLine',
      payload: data.map((item: { name: string }) => item.name),
    })
    return { data, days }
    // eslint-disable-next-line
  }, [
    state.firstDataForChart,
    state.secondDataForChart,
    state.thirdDataForChart,
    state.fourthDataForChart,
    state.viewBy,
  ])

  // effects
  useEffect(() => {
    const data = chartData?.data?.filter((item: any) =>
      state.checkedChartLine.includes(item.name)
    )
    const options = getEchartOptions(
      chartData.days,
      data,
      'Graph',
      theme.palette.mode
    )
    if (!chart.current) {
      const chartEl: HTMLElement | null =
        document.getElementById('compare-echart')
      if (chartEl) {
        chart.current = init(chartEl)
      }
    }
    chart.current?.setOption(options, true)
    chart.current?.resize()
    // eslint-disable-next-line
  }, [chartData, theme.palette.mode, state.checkedChartLine])

  useEffect(() => {
    // Bind the resize method to the window
    window.addEventListener('resize', () => {
      chart.current?.resize()
    })

    return function willUnmount() {
      if (chart.current) {
        chart.current.clear()
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [])

  return (
    <Box position="relative">
      {loading && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            width: '100%',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
            zIndex: 9,
            background: theme.palette.custom?.maskBackground,
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      {/* view by */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            marginRight: '4px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '28px',
              marginRight: '4px',
            }}
          >
            View by
          </Box>
          {props.list.map((item: { title: string; key: string }) => (
            <Box
              key={item.key}
              sx={{
                height: '28px',
                lineHeight: '28px',
                cursor: 'pointer',
                padding: '0 10px',
                background:
                  item.key === state.viewBy ? 'rgba(30, 150, 252, 0.05)' : '',
              }}
              onClick={() => {
                if (item.key !== state.viewBy) {
                  dispatch({
                    type: 'viewBy',
                    payload: item.key,
                  })
                }
              }}
            >
              <Box
                component="span"
                sx={{
                  fontSize: '14px',
                  color: item.key === state.viewBy ? '#1E96FC' : '#AECBFA',
                  marginLeft: '4px',
                }}
              >
                {item.title}
              </Box>
            </Box>
          ))}
        </Box>
        <Box marginTop="2px">
          <ChartSettingView />
        </Box>
      </Box>
      {/* Chart */}
      <Box
        id="compare-echart"
        width="100%"
        height="398px"
        boxSizing="border-box"
        marginTop="10px"
      />
    </Box>
  )
}

export default ChartView
