import { useContext, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { UserActivityContext } from 'src/context/userActivity'
import { MetricsContext } from 'src/context/userActivity/metrics'
import { ReportsTab } from 'src/components'

const TopChart = (): JSX.Element => {
  const navigate = useNavigate()
  const params: any = useParams()
  const { state: userActivityState, dispatch: userActivityDispatch } =
    useContext(UserActivityContext)
  const { state: metricsState } = useContext(MetricsContext)

  const handleChange = (newValue: string) => {
    userActivityDispatch({
      type: 'chartType',
      payload: newValue,
    })
    navigate(`/dash/user-activity/${newValue}`)
  }
  useEffect(() => {
    if (params.tab) {
      userActivityDispatch({
        type: 'chartType',
        payload: params.tab,
      })
    }
    // eslint-disable-next-line
  }, [])

  // returns
  return (
    <Box width="100%">
      <ReportsTab
        value={userActivityState.chartType}
        options={metricsState.topChartsData}
        onChange={handleChange}
      />
    </Box>
  )
}

export default TopChart
