import { Navigate } from 'react-router-dom'

// layout
import AuthLayout from 'src/layouts/Auth'
import AboutLayout from 'src/layouts/About'
import DashLayout from 'src/layouts/Dash'

// global
import NotFoundView from 'src/views/NotFound'

// auth
import SignInView from 'src/views/auth/SignIn'
import SignUpView from 'src/views/auth/SignUp'
import ForgotPasswordView from 'src/views/auth/ForgotPassword'
import ResetPasswordView from 'src/views/auth/ResetPassword'
import EmailVerifyView from 'src/views/auth/EmailVerify'
import TwoFaView from 'src/views/auth/TwoFa'

// about
import TeamConditionsView from 'src/views/about/team-conditions'

// welcome
import DashboardView from 'src/views/Dashboard'

// dashboard
import PerformanceView from 'src/views/Performance'
import UserActivityTempView from 'src/views/UserActivity'
import GamePlayInsights from 'src/views/GamePlayInsights'
import EngagementView from 'src/views/Engagement'
import AdPlacementView from 'src/views/AdPlacement'
import NotificationsView from 'src/views/Notifications'

// finance
import FinanceView from 'src/views/Finance'
import EditProfile from 'src/views/Finance/tipaltiProfile'
import CreateProfile from 'src/views/Finance/tipaltiProfile'

// games
import GameView from 'src/views/Games'
import ManageView from 'src/views/Games/GameManage/manage'
import AddGameView from 'src/views/Games/Add'
import AppPerformanceView from 'src/views/Games/AppPerformance'
import CompareGamesView from 'src/views/Games/CompareGames'

// test Devices
import TestDevicesView from 'src/views/TestDevices'

// Roles
import RolesView from 'src/views/Roles'

// AD
import ADView from 'src/views/AdManager'

// settings
import SettingsView from 'src/views/Settings'
import SettingsAccountView from 'src/views/Settings/Account'
import UnsubscribeView from 'src/views/Unsubscribe'

const routes = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      // default
      { path: '/', element: <Navigate to="/login" /> }, // TODO: change this to check auth view

      // auth
      { path: 'login', element: <SignInView /> },
      { path: 'register', element: <SignUpView /> },
      { path: 'password/forgot', element: <ForgotPasswordView /> },
      { path: 'password/reset', element: <ResetPasswordView /> },
      { path: 'email-verification', element: <EmailVerifyView /> },
      { path: 'two-fa', element: <TwoFaView /> },

      // not found
      { path: '404', element: <NotFoundView /> },

      // catch
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/about',
    element: <AboutLayout />,
    children: [
      // default
      { path: '/', element: <Navigate to="/team-conditions" /> },

      // about
      { path: 'team-conditions', element: <TeamConditionsView /> },

      // catch
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/dashboard',
    element: <DashLayout />,
    children: [
      // default - dashboard
      {
        path: '/',
        element: <DashboardView />,
        meta: {
          role: 'dashboard_page',
        },
      },
      // catch
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/dash',
    element: <DashLayout />,
    children: [
      // default - dashboard
      { path: '/', element: <Navigate to="/dashboard" /> },
      {
        path: '/performance/:tab',
        element: <PerformanceView />,
        meta: {
          role: 'overview_page',
        },
      },
      {
        path: '/user-activity/:tab',
        element: <UserActivityTempView />,
        meta: {
          role: 'user_activity_page',
        },
      },

      // notifications
      {
        path: 'notifications',
        element: <NotificationsView />,
        meta: {
          role: 'dashboard_page',
        },
      },
      // gameplay insights
      {
        path: 'gameplay-insights/:type',
        element: <GamePlayInsights />,
        meta: {
          role: 'gameplay_insights_page',
        },
      },
      {
        path: 'engagement/:page',
        element: <EngagementView />,
        meta: {
          role: 'engagement_page',
        },
      },
      // finance
      {
        path: 'finance',
        children: [
          {
            path: '/',
            element: <FinanceView />,
            meta: {
              role: 'finance_page',
            },
          },
          {
            path: 'edit',
            element: <EditProfile />,
            meta: {
              role: 'finance_page',
            },
          },
          {
            path: 'create',
            element: <CreateProfile />,
            meta: {
              role: 'finance_page',
            },
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },

      // games
      {
        path: 'games',
        children: [
          // default
          {
            path: '/',
            element: <GameView />,
            meta: {
              role: 'games_page',
            },
          },
          // other
          {
            path: 'manage/:app_key/:page_view',
            element: <ManageView />,
            meta: {
              role: 'game_manage_page',
            },
          },
          {
            path: 'add',
            element: <AddGameView />,
            meta: {
              role: 'game_submission',
            },
          },
          {
            path: 'performance/:app_key',
            element: <AppPerformanceView />,
            meta: {
              role: 'games_page',
            },
          },
          {
            path: 'compare',
            element: <CompareGamesView />,
            meta: {
              role: 'games_page',
            },
          },
          // catch
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      // testDevices
      {
        path: 'testing',
        children: [
          // default
          {
            path: '/',
            element: <TestDevicesView />,
            meta: {
              role: 'testing_page',
            },
          },
        ],
      },
      // adPlacement
      {
        path: 'ad-placement/:tab',
        element: <AdPlacementView />,
        meta: {
          role: 'ad_placement_page',
        },
      },
      // Roles
      {
        path: 'users',
        children: [
          // default
          {
            path: '/',
            element: <RolesView />,
            meta: {
              role: 'roles_page',
            },
          },
        ],
      },
      // settings
      {
        path: 'settings',
        element: <SettingsView />,
        children: [
          // default
          { path: '/', element: <SettingsAccountView /> },

          // catch
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      // Unsubscribe
      {
        path: 'manage-notifications',
        children: [
          // default
          { path: '/', element: <UnsubscribeView /> },

          // catch
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      // ad-management
      {
        path: 'ad-management',
        children: [
          // default
          {
            path: '/',
            element: <ADView />,
            meta: {
              role: 'ad_manager_page',
            },
          },
        ],
      },
      // catch
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
]

export default routes
