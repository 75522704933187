import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CohortsContext } from 'src/context/gamePlayInsights/cohorts'
import { ChurnRateContext } from 'src/context/gamePlayInsights/churnRate'
import { GlobalContext } from 'src/context/global'
import { BorderStyleSelectRow, BorderStyleSingleDate } from 'src/components'
import dayjs from 'dayjs'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      topFilter: {
        display: 'flex',
        padding: '20px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${
          theme.palette.mode === 'light' ? '#E5E8EB' : '#484644'
        }`,
        top: 68,
        zIndex: 10,
      },
      topFilterOption: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '26px',
      },
      button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        width: 80,
        height: '40px',
        color: '#FFF',
        borderRadius: '6px',
        fontWeight: 600,
        fontSize: '14px',
        cursor: 'pointer',
        background: theme.palette.mode === 'light' ? '#1D3353' : '#ff7a08',
      },
    }
  })

const TopFilterView = (props: any): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, search } = useContext(CohortsContext)
  const { dispatch: churnRateDispatch, search: churnRateSearch } =
    useContext(ChurnRateContext)
  const { state: globalState } = useContext(GlobalContext)

  const timeHandleChange = (data: any) => {
    dispatch({
      type: 'time',
      payload: data,
    })
    churnRateDispatch({
      type: 'time',
      payload: data,
    })
  }
  const reset = () => {
    timeHandleChange(dayjs(new Date()).subtract(30, 'day'))
    dispatch({
      type: 'country',
      payload: '',
    })
    churnRateDispatch({
      type: 'country',
      payload: '',
    })
    dispatch({
      type: 'resetIndex',
      payload: state.resetIndex + 1,
    })
    churnRateDispatch({
      type: 'resetIndex',
      payload: state.resetIndex + 1,
    })
  }
  const applySearch = () => {
    search()
    churnRateSearch()
  }
  // returns
  return (
    <Box
      className={classes.topFilter}
      sx={
        !globalState.isMobile
          ? { top: 68, position: 'sticky' }
          : { top: 0, display: 'block' }
      }
    >
      <Box
        className={classes.topFilterOption}
        sx={!globalState.isMobile ? {} : { justifyContent: 'center' }}
      >
        <BorderStyleSingleDate
          label="Date"
          value={state.time}
          onChange={timeHandleChange}
          onTriggerFilter={applySearch}
        />
        <BorderStyleSelectRow
          label="App"
          valueKey="app_key"
          labelKey="name"
          placeholder="App"
          value={state.game}
          showSearch={true}
          onChange={(row: any) => {
            dispatch({
              type: 'game',
              payload: row,
            })
            churnRateDispatch({
              type: 'game',
              payload: row,
            })
          }}
          options={state.gameOption}
        />
        <BorderStyleSelectRow
          label="Country"
          placeholder="Country"
          valueKey="code"
          labelKey="name"
          value={state.country}
          showSearch={true}
          onChange={(row: any) => {
            dispatch({
              type: 'country',
              payload: row,
            })
            churnRateDispatch({
              type: 'country',
              payload: row,
            })
          }}
          options={state.countryOption}
          onTriggerFilter={applySearch}
        />
      </Box>
      <Box
        marginLeft="8px"
        display="flex"
        sx={
          !globalState.isMobile
            ? { minWidth: '133px', position: 'relative' }
            : { justifyContent: 'space-evenly', marginTop: '26px' }
        }
      >
        <Box
          sx={{
            lineHeight: '40px',
            fontSize: '14px',
            fontWeight: 600,
            cursor: 'pointer',
            marginRight: '20px',
          }}
          onClick={reset}
        >
          Reset
        </Box>
        <Box
          className={classes.button}
          id="overview-apply"
          onClick={applySearch}
        >
          Apply
        </Box>
      </Box>
    </Box>
  )
}

export default TopFilterView
