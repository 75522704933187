import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  useTheme,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import Skeleton from '@material-ui/core/Skeleton'
import { useContext } from 'react'
import { GameManageContext } from 'src/context/gameManage'
import { NoDataView, StylePaper } from 'src/components'
import { getDateEnglishFormat } from 'src/utils'

const OptimizationDetailsView = () => {
  const theme: Theme = useTheme()
  const min1512 = useMediaQuery('(min-width:1512px)')
  const min1200 = useMediaQuery('(min-width:1200px)')
  const { state } = useContext(GameManageContext)

  const StepIcon = (iconProps: any) => {
    return (
      <Box
        sx={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor:
            state.moDetailsData[iconProps.icon - 1]?.event_key ===
            'custom_event'
              ? theme.palette.primary.main
              : '#9E9E9E',
          margin: '7px',
        }}
      />
    )
  }
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      {state.moDetailsLoading ? (
        <StylePaper boxSizing="border-box" padding="24px" height="100%">
          <Skeleton variant="rectangular" width="100%" height="250px" />
        </StylePaper>
      ) : (
        <>
          {state.moDetailsData.length ? (
            <Box
              sx={{
                boxSizing: 'border-box',
                padding: '8px 0',
                maxHeight: min1512 ? '303px' : min1200 ? '335px' : '456px',
                overflow: 'auto',
              }}
            >
              <Stepper orientation="vertical">
                {state.moDetailsData.map((step: any, index: number) => (
                  <Step key={'step' + index} expanded={true}>
                    <StepLabel
                      StepIconComponent={StepIcon}
                      optional={
                        <Box
                          sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {getDateEnglishFormat(step.created_at)}
                        </Box>
                      }
                    >
                      <Box
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: theme.palette.text.primary,
                        }}
                      >
                        Yodo1 Optimization
                      </Box>
                    </StepLabel>
                    <StepContent>
                      <Box
                        sx={{
                          fontSize: '12px',
                          fontWeight: 400,
                          color: theme.palette.text.primary,
                          background: theme.palette.background.paper,
                          padding: '12px',
                          borderRadius: '8px',
                        }}
                      >
                        {step.message}
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          ) : (
            <StylePaper
              boxSizing="border-box"
              display="flex"
              justifyContent="center"
              padding="24px"
              height="100%"
            >
              <NoDataView desc="The app have no milestone at the moment" />
            </StylePaper>
          )}
        </>
      )}
    </Box>
  )
}

export default OptimizationDetailsView
