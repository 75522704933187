import {
  TextField,
  Box,
  Dialog,
  Button,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'
import { Close as CloseIcon } from '@material-ui/icons'

import { TestDeviceContext } from 'src/context/testDevice'
import { StyleSelect, LoadingBtn } from 'src/components'
import { validateDeviceId } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      dialog: {
        boxSizing: 'border-box',
        padding: '40px',
        width: '90vw',
        maxWidth: '430px',
        background: theme.palette.custom.dialogBg,
      },
      title: {
        position: 'relative',
        boxSizing: 'border-box',
        fontSize: '20px',
        fontWeight: 600,
        height: '40px',
        lineHeight: '40px',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '3px',
        cursor: 'pointer',
      },
      formLabel: {
        fontSize: '12px',
        color: theme.palette.custom.inputLabel,
        marginBottom: '8px',
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
      footerButton: {
        width: '100% !important',
        height: '40px !important',
        marginTop: '24px !important',
      },
      delete: {
        boxSizing: 'border-box',
        width: '100%',
        height: '40px',
        lineHeight: '40px',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        borderRadius: '6px',
        border: `1px solid ${
          theme.palette.mode === 'light'
            ? 'rgba(116, 135, 170, 0.5)'
            : '#797E87'
        }`,
        color: theme.palette.mode === 'light' ? '#1D3353' : '#797E87',
        cursor: 'pointer',
        marginTop: '24px',
        marginRight: '16px',
      },
    }
  })

const EditDialogView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [t] = useTranslation()
  const { state, dispatch, editDevice } = useContext(TestDeviceContext)
  const obj: any = {
    platform: '',
    device_id: '',
    name: '',
  }

  const [dialogData, setDialogData] = useState(obj)

  const handleClose = () => {
    dispatch({
      type: 'editDialogOpen',
      payload: false,
    })
  }

  const inputChange = (e: any) => {
    const { name, value } = e.target
    setDialogData({ ...dialogData, [name]: value })
  }
  const [nameError, setNameError] = useState('')
  const [idErrorDetail, setIdErrorDetail] = useState('')
  const saveDevice = () => {
    if (!dialogData.name) {
      setNameError(t('validator.device_name_input'))
      return
    } else {
      setNameError('')
    }
    if (!dialogData.device_id) {
      setIdErrorDetail(t('validator.device_id_input'))
      return
    } else if (!validateDeviceId(dialogData)) {
      setIdErrorDetail(t('validator.device_id_format'))
      return
    } else {
      setIdErrorDetail('')
    }
    editDevice(dialogData)
  }
  const platformOptions = [
    {
      label: 'iOS',
      value: 'iOS',
    },
    {
      label: 'Android',
      value: 'android',
    },
  ]
  useEffect(() => {
    if (state.editDialogOpen) {
      setNameError('')
      setIdErrorDetail('')
      setDialogData(state.editDialogData)
    }
  }, [state.editDialogOpen, state.editDialogData])
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={state.editDialogOpen}
      >
        <Box className={classes.dialog}>
          <Box className={classes.title}>
            Edit Test Device
            <CloseIcon
              className={classes.closeFilterIcon}
              onClick={handleClose}
            />
          </Box>
          <Box marginTop="16px">
            <Box>
              <Box className={classes.formLabel}>Platform:</Box>
              <StyleSelect
                options={platformOptions}
                value={dialogData.platform}
                onChange={inputChange}
                name="platform"
                style={{
                  width: '100%',
                  border: `1px solid ${
                    theme.palette.mode === 'dark' ? '#51565F' : '#eee'
                  }`,
                  margin: 0,
                }}
                disabled={true}
              />
              <Box className={classes.formLabel} marginTop="24px">
                Name:
              </Box>
              <TextField
                className={classes.formInputSearch}
                name="name"
                placeholder="Test device name"
                variant="outlined"
                error={!!nameError}
                helperText={nameError}
                value={dialogData.name}
                onChange={inputChange}
              />
              <Box className={classes.formLabel} marginTop="24px">
                Device ID:
              </Box>
              <TextField
                className={classes.formInputSearch}
                name="device_id"
                placeholder="IDFA / GAID"
                variant="outlined"
                error={!!idErrorDetail}
                helperText={idErrorDetail}
                value={dialogData.device_id}
                onChange={inputChange}
                disabled={true}
              />
            </Box>
            <Box>
              <Button
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={saveDevice}
              >
                {state.editDeviceLoading ? (
                  <Box>
                    <LoadingBtn
                      loadingStyle={{
                        justifyContent: 'center',
                      }}
                    />
                  </Box>
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default EditDialogView
