import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  Dispatch,
} from 'react'

import dayjs from 'dayjs'
import {
  getEchartApi,
  getEchartApiV2,
  getEchartGeoApi,
  getEchartTopGamesApi,
} from 'src/api/dashboard'
import { getUpsellListApi } from 'src/api/game'
import { getCommentsEventApi } from 'src/api/user'
import { financeOverviewApi } from 'src/api/finance'
import { GlobalContext } from '../global'
import { GlobalStoreContext } from '../globalStore'
import {
  toThousands,
  numFixed,
  getDayBeforeDate,
  toPercent,
  rateTitleFormat,
  transformUTC0ToLocalTime,
} from 'src/utils'

type Props = {
  gameOption: any
  countryOption: any
  gameListObject: any
  countryListObject: any
  view: string
  gameSelect: any[]
  country: any[]
  time: any
  timeShow: string
  data: any
  engagementData: any
  sessionLengthData: any
  dataLoading: boolean
  countryChecked: string[]
  timeRange: any
  newChartType: string
  chartList: any
  checkedChartLine: any
  chartRevenue: number
  chartCardLoading: boolean
  chartCardData: any
  arpuChartCardData: any
  stickinessChartCardData: any
  arpuLoading: any
  stickinessLoading: any
  rateSessionData: any
  financeCardLoading: boolean
  financeCardData: string
  geoLoading: boolean
  geoData: any
  geoTableData: any
  topGamesLoading: boolean
  topGames: Array<any>
  retentionsLoading: boolean
  cardLineChart: any
  resetIndex: number
  echartData: any
  commentsEvent: any
  upsellAppData: any
  upsellAppDataLoading: boolean
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const getBeforeTimeRange = (range: [string, string]): [string, string] => {
  const rangeTime = Math.abs(Date.parse(range[1]) - Date.parse(range[0]))
  const rangeDay = Math.floor(rangeTime / (24 * 3600 * 1000) + 1)
  const startDate = getDayBeforeDate(rangeDay, range[0])
  const endDate = getDayBeforeDate(rangeDay, range[1])
  return [startDate, endDate]
}

const initialState: Props = {
  gameOption: [],
  gameListObject: {},
  countryOption: [],
  countryListObject: {},
  view: 'all',
  gameSelect: [],
  country: [],
  time: [
    dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
  ],
  timeShow: 'last_week',
  data: {},
  engagementData: {},
  sessionLengthData: {},
  dataLoading: false,
  countryChecked: [],
  timeRange: {
    timeRange: [
      dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
      dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    ],
  },
  newChartType: 'Graph',
  chartList: [
    {
      label: 'All',
      value: 'All',
      color: '#FF8E00',
    },
  ],
  checkedChartLine: [],
  chartRevenue: 0,
  chartCardLoading: false,
  chartCardData: Array(9).fill({}),
  arpuChartCardData: {},
  stickinessChartCardData: {},
  arpuLoading: false,
  stickinessLoading: false,
  rateSessionData: [{}],
  financeCardLoading: false,
  financeCardData: '0',
  geoLoading: false,
  geoData: {},
  geoTableData: [],
  topGamesLoading: false,
  topGames: [],
  retentionsLoading: false,
  cardLineChart: {},
  resetIndex: 0,
  echartData: {},
  commentsEvent: {},
  upsellAppData: [],
  upsellAppDataLoading: false,
}
const type = {
  interstitial: 'Interstitial',
  banner: 'Banner',
  reward_video: 'Rewarded Video',
  nativeads: 'Native Ads',
  openads: 'App Open',
  rewardinter: 'Rewarded Interstitial',
  other: 'Other',
  android: 'Android',
  all: 'All',
}
const colorList = ['#FF8E00', '#90cc75', '#f07474', '#5a70b5']
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'data':
      const allData = action.payload
      const keys = Object.keys(allData)
      if (!keys.length) {
        return {
          ...prevState,
          data: {},
        }
      }
      const labels = allData[keys[0]].revenue.days
      const echartData: any = []
      let chartList: any = []
      let checkedChartLine: any = []
      keys.forEach((item: any, i: number) => {
        const color: string =
          colorList[i] ||
          prevState.countryListObject[item]?.color ||
          prevState.gameListObject[item]?.color ||
          '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
        const label: string =
          prevState.gameListObject[item]?.name ||
          prevState.countryListObject[item]?.name ||
          type[item] ||
          item
        echartData.push({
          name: label,
          type: 'line',
          smooth: true,
          itemStyle: { color: color },
          data: allData[item].revenue.values,
        })
        chartList.push({
          label,
          value: label,
          color,
          num: allData[item].revenue.summary,
        })
        checkedChartLine.push(label)
      })
      chartList = chartList.sort((a: any, b: any) => b.num - a.num)
      if (prevState.view === 'country') {
        checkedChartLine = chartList.slice(0, 8).map((item: any) => item.label)
      }
      return {
        ...prevState,
        data: {
          days: labels,
          data: echartData,
        },
        chartList,
        checkedChartLine,
      }
    case 'time':
    case 'timeShow':
    case 'gameSelect':
    case 'country':
    case 'view':
    case 'gameOption':
    case 'gameListObject':
    case 'countryOption':
    case 'countryListObject':
    case 'dataLoading':
    case 'countryChecked':
    case 'timeRange':
    case 'newChartType':
    case 'checkedChartLine':
    case 'chartRevenue':
    case 'chartCardLoading':
    case 'financeCardLoading':
    case 'geoLoading':
    case 'topGamesLoading':
    case 'retentionsLoading':
    case 'cardLineChart':
    case 'engagementData':
    case 'sessionLengthData':
    case 'arpuLoading':
    case 'stickinessLoading':
    case 'resetIndex':
    case 'commentsEvent':
    case 'upsellAppData':
    case 'upsellAppDataLoading':
      return { ...prevState, [action.type]: action.payload }
    case 'chartCardData':
      const cardData = action.payload
      const chartRevenue = cardData?.revenue?.summary || 0
      const disabled = action.payload?.tabDisabled || false
      const untilTime = action.payload?.untilTime || null
      return {
        ...prevState,
        chartRevenue,
        chartCardData: [
          {
            name: 'Revenue',
            value: 'revenue',
            disabled,
            untilTime,
            path: '/dash/performance/revenue',
            storeData: 'performanceData',
            chartCard: true,
            num: '$' + toThousands(cardData.revenue?.summary || '0', true),
            tendency:
              cardData.revenue?.change_rate === 0
                ? null
                : cardData.revenue?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(
              Math.abs(cardData.revenue?.change_rate || '0')
            ),
            absoluteValue: toThousands(
              numFixed(Math.abs(cardData.revenue?.absolute_value || '0'))
            ),
          },
          {
            name: 'Impressions',
            value: 'impressions',
            disabled,
            untilTime,
            path: '/dash/performance/impressions',
            storeData: 'performanceData',
            chartCard: true,
            num: toThousands(cardData.impressions?.summary || '0', true),
            tendency:
              cardData.impressions?.change_rate === 0
                ? null
                : cardData.impressions?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(
              Math.abs(cardData.impressions?.change_rate || '0')
            ),
            absoluteValue: toThousands(
              numFixed(Math.abs(cardData.impressions?.absolute_value || '0'))
            ),
          },
          {
            name: 'eCPM',
            value: 'ecpm',
            disabled,
            untilTime,
            path: '/dash/performance/ecpm',
            storeData: 'performanceData',
            chartCard: true,
            num: '$' + toThousands(cardData.ecpm?.summary || '0', true),
            tendency:
              cardData.ecpm?.change_rate === 0
                ? null
                : cardData.ecpm?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(Math.abs(cardData.ecpm?.change_rate || '0')),
            absoluteValue: toThousands(
              numFixed(Math.abs(cardData.ecpm?.absolute_value))
            ),
          },
          {
            name: 'DAU',
            value: 'dau',
            disabled,
            untilTime,
            path: '/dash/user-activity/dau',
            storeData: 'userActivityData',
            chartCard: true,
            num: toThousands(cardData.dau?.summary || '0', true),
            tendency:
              cardData.dau?.change_rate === 0
                ? null
                : cardData.dau?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(Math.abs(cardData.dau?.change_rate || '0')),
            absoluteValue: toThousands(
              numFixed(Math.abs(cardData.dau?.absolute_value))
            ),
          },
          {
            name: 'ARPDAU',
            value: 'arpdau',
            disabled,
            untilTime,
            path: '/dash/user-activity/arpdau',
            storeData: 'userActivityData',
            chartCard: true,
            num: '$' + toThousands(cardData.arpdau?.summary || '0', true),
            tendency:
              cardData.arpdau?.change_rate === 0
                ? null
                : cardData.arpdau?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(
              Math.abs(cardData.arpdau?.change_rate || '0')
            ),
            absoluteValue: toThousands(
              numFixed(Math.abs(cardData.arpdau?.absolute_value))
            ),
          },
          {
            name: rateTitleFormat('Imp', 'DAU'),
            value: 'impdau',
            disabled,
            untilTime,
            path: '/dash/user-activity/impdau',
            storeData: 'userActivityData',
            chartCard: true,
            num: toThousands(cardData.impdau?.summary || '0', true),
            tendency:
              cardData.impdau?.change_rate === 0
                ? null
                : cardData.impdau?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(
              Math.abs(cardData.impdau?.change_rate || '0')
            ),
            absoluteValue: toThousands(
              numFixed(Math.abs(cardData.impdau?.absolute_value))
            ),
          },
          {
            name: 'DAV',
            value: 'deu',
            disabled,
            untilTime,
            path: '/dash/engagement/deu',
            storeData: 'engagementData',
            num: toThousands(cardData.deu?.summary || '0', true),
            tendency:
              cardData.deu?.change_rate === 0
                ? null
                : cardData.deu?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(Math.abs(cardData.deu?.change_rate || '0')),
            absoluteValue: toThousands(
              numFixed(Math.abs(cardData.deu?.absolute_value || '0'))
            ),
          },
          {
            name: 'Installs',
            value: 'installs',
            disabled,
            untilTime,
            path: '/dash/gameplay-insights/installs',
            storeData: 'userActivityData',
            num: toThousands(cardData.installs?.summary || '0', true),
            tendency:
              cardData.installs?.change_rate === 0
                ? null
                : cardData.installs?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(
              Math.abs(cardData.installs?.change_rate || '0')
            ),
            absoluteValue: toThousands(
              numFixed(Math.abs(cardData.installs?.absolute_value || '0'))
            ),
          },
          {
            name: rateTitleFormat('Revenue', 'DAV'),
            value: 'revenue_deu',
            disabled,
            untilTime,
            path: '/dash/engagement/revenue_deu',
            storeData: 'engagementData',
            num: '$' + toThousands(cardData.revenue_deu?.summary || '0', true),
            tendency:
              cardData.revenue_deu?.change_rate === 0
                ? null
                : cardData.revenue_deu?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(
              Math.abs(cardData.revenue_deu?.change_rate || '0')
            ),
            absoluteValue:
              '$' +
              toThousands(
                numFixed(Math.abs(cardData.revenue_deu?.absolute_value || '0'))
              ),
          },
        ],
      }
    case 'arpuChartCardData':
      const arpuCardData = action.payload
      if (arpuCardData.summary !== undefined) {
        return {
          ...prevState,
          arpuChartCardData: {
            clickState: true,
            name: 'ARPU',
            value: 'arpu',
            path: '',
            num: '$' + toThousands(arpuCardData?.summary || '0', true),
            tendency:
              arpuCardData?.change_rate === 0
                ? null
                : arpuCardData?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(Math.abs(arpuCardData?.change_rate || '0')),
            absoluteValue:
              '$' +
              toThousands(
                numFixed(Math.abs(arpuCardData?.absolute_value || '0'))
              ),
          },
        }
      } else {
        return {
          ...prevState,
          arpuChartCardData: {
            clickState: false,
            name: 'ARPU',
            value: 'arpu',
            path: '',
            num: 0,
            tendency: 'up',
            changeRate: 0,
            absoluteValue: 0,
          },
        }
      }
    case 'stickinessChartCardData':
      const stickinessCardData = action.payload
      if (stickinessCardData.summary !== undefined) {
        return {
          ...prevState,
          stickinessChartCardData: {
            clickState: true,
            name: 'Stickiness',
            value: 'stickiness',
            path: '/dash/gameplay-insights/stickiness',
            storeData: 'stickinessData',
            num: toPercent(
              numFixed(Math.abs(stickinessCardData?.summary || 0), 4)
            ),
            tendency:
              stickinessCardData?.change_rate === 0
                ? null
                : stickinessCardData?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(
              Math.abs(stickinessCardData?.change_rate || '0')
            ),
            absoluteValue: toPercent(
              numFixed(Math.abs(stickinessCardData?.absolute_value || '0'), 4)
            ),
          },
        }
      } else {
        return {
          ...prevState,
          stickinessChartCardData: {
            clickState: false,
            name: 'Stickiness',
            value: 'stickiness',
            path: '/dash/gameplay-insights/stickiness',
            storeData: 'stickinessData',
            num: '0%',
            tendency: 'up',
            changeRate: 0,
            absoluteValue: '0%',
          },
        }
      }

    case 'rateSessionData':
      const rateSession = action.payload
      const sessionDisabled = action.payload?.tabDisabled || false
      const sessionUntilTime = action.payload?.untilTime || null

      return {
        ...prevState,
        rateSessionData: [
          {
            clickState:
              prevState.gameSelect.length === 1 && rateSession.session_length
                ? true
                : false,
            name: 'Avg. Session Length',
            value: 'gameplay-insights',
            disabled: sessionDisabled,
            untilTime: sessionUntilTime,
            path: '/dash/gameplay-insights/session-length',
            storeData: 'sessionsData',
            key: 'session_length',
            num: toThousands(rateSession.session_length?.summary || '0', true),
            tendency:
              rateSession.session_length?.change_rate === 0
                ? null
                : rateSession.session_length?.change_rate > 0
                ? 'up'
                : 'down',
            changeRate: toPercent(
              Math.abs(rateSession.session_length?.change_rate)
            ),
            absoluteValue: toThousands(
              numFixed(Math.abs(rateSession.session_length?.absolute_value))
            ),
          },
        ],
      }
    case 'financeCardData':
      return {
        ...prevState,
        financeCardData: toThousands(action.payload.realRev || '0', true),
      }
    case 'geoData':
      const geoData = action.payload
      const geoCountryKeys = Object.keys(geoData)
      if (!geoCountryKeys.length) {
        return {
          ...prevState,
          data: {},
          geoTableData: [],
        }
      }
      const geoTableData: any = []
      geoCountryKeys.forEach((item: string) => {
        geoTableData.push({
          name: prevState.countryListObject[item]?.name || type[item] || item,
          code: item,
          data: geoData[item],
        })
      })
      return {
        ...prevState,
        geoData,
        geoTableData,
      }
    case 'topGames':
      const topGames = action.payload
      return {
        ...prevState,
        topGames,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
  getData: (view?: string) => void
  searchData: () => void
  getGeoData: () => void
  getTopGames: () => void
}

export const DashContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
  getData: () => {},
  searchData: () => {},
  getGeoData: () => {},
  getTopGames: () => {},
})

export const DashContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: globalState, errorCatch } = useContext(GlobalContext)
  const { state: globalStoreState, dispatch: globalStoreDispatch } =
    useContext(GlobalStoreContext)

  // effects
  // 获取总的游戏列表
  useEffect(() => {
    dispatch({ type: 'gameListObject', payload: globalState.gameListObject })
    dispatch({
      type: 'gameOption',
      payload: globalState.gameOption,
    })
    // eslint-disable-next-line
  }, [globalState.gameListObject])
  // 获取国家列表
  useEffect(() => {
    dispatch({
      type: 'countryListObject',
      payload: globalState.countryListObject,
    })
    dispatch({ type: 'countryOption', payload: globalState.countryOption })
    // eslint-disable-next-line
  }, [globalState.countryListObject])
  // filter和country filter中apply按钮触发
  const searchData = () => {
    getData()
    if (state.view !== 'all') {
      getMiddleCardChartData()
    }
    getChartCardData()
    getFinance()
    getGeoData()
    getTopGames()
  }
  // 获取MO event
  const getCommentsEvent = () => {
    getCommentsEventApi({
      app_keys: state.gameSelect.map(item => item.app_key),
    })
      .then((res: any) => {
        dispatch({
          type: 'commentsEvent',
          payload: res.data.data,
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            commentsEvent: res.data.data,
          },
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            commentsEvent: {},
          },
        })
      })
  }
  // 获取overview页面chart数据
  const getData = (view?: string) => {
    dispatch({ type: 'dataLoading', payload: true })
    getCommentsEvent()
    const newView = view || state.view
    const data = {
      from_date: state.time[0],
      to_date: state.time[1],
      filter: {
        app_keys: state.gameSelect.map(item => item.app_key),
        ad_types: [],
        platform: [],
        country_code: [],
      },
      view_by: newView === 'all' ? null : newView,
    }
    globalStoreDispatch({
      type: 'dashboardData',
      payload: {
        filter: {
          view: newView,
          time: state.time,
          timeShow: state.timeShow,
          gameSelect: state.gameSelect,
        },
      },
    })
    getEchartApi({ ...data })
      .then((res: any) => {
        dispatch({
          type: 'data',
          payload: res.data.data,
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            data: res.data.data,
          },
        })
        if (newView === 'all') {
          dispatch({
            type: 'cardLineChart',
            payload: res.data.data,
          })
          globalStoreDispatch({
            type: 'dashboardData',
            payload: {
              cardLineChart: res.data.data,
            },
          })
        }
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            data: {},
          },
        })
        if (state.view === 'all') {
          globalStoreDispatch({
            type: 'dashboardData',
            payload: {
              cardLineChart: {},
            },
          })
        }
      })
      .finally(() => {
        // 不加延时则loading结束后会有数据闪烁，因为前端数据循环的时间差
        setTimeout(() => {
          dispatch({ type: 'dataLoading', payload: false })
        })
      })
  }
  const getMiddleCardChartData = () => {
    const data = {
      from_date: state.time[0],
      to_date: state.time[1],
      filter: {
        app_keys: state.gameSelect.map(item => item.app_key),
        ad_types: [],
        platform: [],
        country_code: [],
      },
      view_by: null,
    }
    getEchartApi({ ...data })
      .then((res: any) => {
        dispatch({
          type: 'cardLineChart',
          payload: res.data.data,
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            cardLineChart: res.data.data,
          },
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            cardLineChart: {},
          },
        })
      })
      .finally(() => {})
  }
  // 获取overview页面card数据 / ad placement card 数据
  const getChartCardData = () => {
    dispatch({ type: 'chartCardLoading', payload: true })
    const compareDate = getBeforeTimeRange(state.time)
    const data = {
      from_date: state.time[0],
      to_date: state.time[1],
      compare_from_date: compareDate[0],
      compare_to_date: compareDate[1],
      filter: {
        app_keys: state.gameSelect.map(item => item.app_key),
        ad_types: [],
        platform: [],
        country_code: [],
      },
      view_by: null,
    }
    getEchartApiV2({ ...data })
      .then((res: any) => {
        dispatch({
          type: 'chartCardData',
          payload: res.data.data?.all || {},
        })
        dispatch({
          type: 'rateSessionData',
          payload: res.data.data?.all || {},
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            chartCardData: res.data.data?.all || {},
          },
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            rateSessionData: res.data.data?.all || {},
          },
        })
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 503 &&
          err?.response?.data?.code === 'trino_migration'
        ) {
          let untilTime = null
          if (err?.response?.data?.extra?.after) {
            untilTime = transformUTC0ToLocalTime(
              err?.response?.data?.extra?.after,
              'Do MMM YYYY hh:mm a'
            )
          }
          globalStoreDispatch({
            type: 'dashboardData',
            payload: {
              chartCardData: { tabDisabled: true, untilTime },
            },
          })
          globalStoreDispatch({
            type: 'dashboardData',
            payload: {
              rateSessionData: { tabDisabled: true, untilTime },
            },
          })
          dispatch({
            type: 'chartCardData',
            payload: { tabDisabled: true, untilTime },
          })
          dispatch({
            type: 'rateSessionData',
            payload: { tabDisabled: true, untilTime },
          })
        } else {
          errorCatch(err)
          globalStoreDispatch({
            type: 'dashboardData',
            payload: {
              chartCardData: {},
            },
          })
          globalStoreDispatch({
            type: 'dashboardData',
            payload: {
              rateSessionData: {},
            },
          })
        }
      })
      .finally(() => {
        // 不加延时则loading结束后会有数据闪烁，因为前端数据循环的时间差
        setTimeout(() => {
          dispatch({ type: 'chartCardLoading', payload: false })
        })
      })
  }
  // 获取upsell游戏
  const getUpsellList = () => {
    dispatch({
      type: 'upsellAppDataLoading',
      payload: true,
    })
    getUpsellListApi({ count: 10 })
      .then((res: any) => {
        dispatch({
          type: 'upsellAppData',
          payload: res.data.data,
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            upsellAppData: res.data.data,
          },
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            upsellAppData: [],
          },
        })
      })
      .finally(() => {
        dispatch({
          type: 'upsellAppDataLoading',
          payload: false,
        })
      })
  }
  const getFinance = () => {
    dispatch({ type: 'financeCardLoading', payload: true })
    financeOverviewApi()
      .then(({ data }) => {
        dispatch({
          type: 'financeCardData',
          payload: data.data,
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            financeCardData: data.data,
          },
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            financeCardData: '0',
          },
        })
      })
      .finally(() => {
        dispatch({ type: 'financeCardLoading', payload: false })
      })
  }
  // 获取overview页面geo数据
  const getGeoData = () => {
    dispatch({ type: 'geoLoading', payload: true })
    const compareDate = getBeforeTimeRange(state.time)
    const data = {
      from_date: state.time[0],
      to_date: state.time[1],
      compare_from_date: compareDate[0],
      compare_to_date: compareDate[1],
      filter: {
        app_keys: state.gameSelect.map(item => item.app_key),
        ad_types: [],
        platform: [],
        country_code: [],
      },
      view_by: 'country',
    }
    getEchartGeoApi({ ...data })
      .then((res: any) => {
        dispatch({
          type: 'geoData',
          payload: res.data.data,
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            geoData: res.data.data,
          },
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            geoData: {},
          },
        })
      })
      .finally(() => {
        // 不加延时则loading结束后会有数据闪烁，因为前端数据循环的时间差
        setTimeout(() => {
          dispatch({ type: 'geoLoading', payload: false })
        })
      })
  }
  // 获取welcome页面top game数据
  const getTopGames = () => {
    dispatch({ type: 'topGamesLoading', payload: true })
    const data = {
      from_date: state.time[0],
      to_date: state.time[1],
      filter: {
        app_keys: state.gameSelect.map(item => item.app_key),
      },
    }
    getEchartTopGamesApi({ ...data })
      .then((res: any) => {
        dispatch({
          type: 'topGames',
          payload: res.data.data,
        })
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            topGames: res.data.data,
          },
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'dashboardData',
          payload: {
            topGames: [],
          },
        })
      })
      .finally(() => {
        // 不加延时则loading结束后会有数据闪烁，因为前端数据循环的时间差
        setTimeout(() => {
          dispatch({ type: 'topGamesLoading', payload: false })
        })
      })
  }
  useEffect(() => {
    if (globalStoreState.dashboardData?.filter) {
      dispatch({
        type: 'time',
        payload: globalStoreState.dashboardData.filter.time,
      })
      dispatch({
        type: 'timeRange',
        payload: { timeRange: globalStoreState.dashboardData.filter.time },
      })
      dispatch({
        type: 'timeShow',
        payload: globalStoreState.dashboardData.filter.timeShow,
      })
      dispatch({
        type: 'view',
        payload: globalStoreState.dashboardData.filter.view,
      })
      dispatch({
        type: 'gameSelect',
        payload: globalStoreState.dashboardData.filter.gameSelect,
      })
      Object.keys(globalStoreState.dashboardData)
        .filter((item: string) => item !== 'filter')
        .forEach((item: string) => {
          dispatch({
            type: item,
            payload: globalStoreState.dashboardData[item],
          })
        })
      return
    }
    getData()
    getChartCardData()
    getFinance()
    getGeoData()
    getTopGames()
    getUpsellList()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (state.resetIndex) {
      searchData()
    }
    // eslint-disable-next-line
  }, [state.resetIndex])
  // returns
  return (
    <DashContext.Provider
      value={{
        state,
        dispatch,
        getData,
        searchData,
        getGeoData,
        getTopGames,
      }}
    >
      {children}
    </DashContext.Provider>
  )
}
