import {
  createContext,
  useReducer,
  Dispatch,
  useEffect,
  useContext,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  financeProfileCompletionApi,
  financeOverviewApi,
  financeProfileDataApi,
  financePaymentHistoryApi,
  setMinPaymentApi,
} from 'src/api'
import { GlobalContext } from './global'
import { HistoryList, ProfileInfo } from 'src/context/type'

type Props = {
  viewDetail: boolean
  completion: boolean
  activeStep: number
  balance: number
  estimatedRev: number
  quickPay?: boolean
  minPayment: any
  is_custom: any
  setMinPaymentEnabled: boolean
  historyList: HistoryList
  historyListLoading: boolean
  profileInfo: ProfileInfo | null
  setPaymentLoading: boolean
  setPaymentResult: boolean
  userInfoLoading: boolean
}

export enum ACTION_TYPE {
  VIEW_DETAIL = 'VIEW_DETAIL',
  ACTIVE_STEP = 'ACTIVE_STEP',
  COMPLETION_CHANGE = 'COMPLETION_CHANGE',
  INIT_INFO = 'INIT_INFO',
  USER_INFO_LOADING = 'USER_INFO_LOADING',
  SET_PAYMENT_LOADING = 'SET_PAYMENT_LOADING',
  SET_PAYMENT_RESULT = 'SET_PAYMENT_RESULT',
}

type Action = {
  type: ACTION_TYPE
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  viewDetail: false,
  activeStep: 0,
  completion: false,
  balance: 0,
  estimatedRev: 0,
  quickPay: true,
  minPayment: 300,
  is_custom: false,
  setMinPaymentEnabled: false,
  historyListLoading: false,
  historyList: {
    page: 1,
    pageSize: 20,
    total: 0,
    data: [],
  },
  profileInfo: null,
  setPaymentLoading: false,
  setPaymentResult: false,
  userInfoLoading: false,
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case ACTION_TYPE.INIT_INFO:
      return {
        ...prevState,
        ...action.payload,
      }
    case ACTION_TYPE.VIEW_DETAIL:
      return {
        ...prevState,
        viewDetail: !prevState.viewDetail,
      }
    case ACTION_TYPE.ACTIVE_STEP:
      return {
        ...prevState,
        activeStep: action.payload,
      }
    case ACTION_TYPE.COMPLETION_CHANGE:
      return {
        ...prevState,
        completion: action.payload,
      }
    case ACTION_TYPE.SET_PAYMENT_LOADING:
      return {
        ...prevState,
        setPaymentLoading: action.payload,
      }
    case ACTION_TYPE.SET_PAYMENT_RESULT:
      return {
        ...prevState,
        setPaymentResult: action.payload,
      }
    case ACTION_TYPE.USER_INFO_LOADING:
      return {
        ...prevState,
        userInfoLoading: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
  editFinance: (num: number, is_custom: boolean) => void
}

const FinanceContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
  editFinance: (num: number, is_custom: boolean) => {},
})

const FinanceContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, customAlert } = useContext(GlobalContext)
  const [t] = useTranslation()

  const getUserInfo = () => {
    dispatch({
      type: ACTION_TYPE.USER_INFO_LOADING,
      payload: true,
    })
    financeOverviewApi()
      .then(({ data }) => {
        dispatch({
          type: ACTION_TYPE.INIT_INFO,
          payload: {
            balance: data.data.realRev,
            estimatedRev: data.data.estimatedRev,
            quickPay: data.data?.quickPay,
            minPayment: data.data.minPayment,
            setMinPaymentEnabled: data.data.setMinPaymentEnabled,
            is_custom: data.data.is_custom,
          },
        })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: ACTION_TYPE.USER_INFO_LOADING,
          payload: false,
        })
      })
  }
  useEffect(() => {
    getUserInfo()

    financeProfileDataApi()
      .then(({ data }) => {
        const { Name, CompanyName, Address, PaymentMethod, Email } = data

        if (PaymentMethod !== 'NoPM') {
          dispatch({
            type: ACTION_TYPE.INIT_INFO,
            payload: {
              profileInfo: {
                Name,
                CompanyName,
                Address,
                PaymentMethod,
                Email,
              },
            },
          })
        }
      })
      .catch(errorCatch)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    dispatch({
      type: ACTION_TYPE.INIT_INFO,
      payload: {
        historyListLoading: true,
      },
    })

    financePaymentHistoryApi({
      page: state.historyList.page,
      page_size: state.historyList.pageSize,
    })
      .then(({ data }) => {
        dispatch({
          type: ACTION_TYPE.INIT_INFO,
          payload: {
            historyList: {
              ...state.historyList,
              data: data.data,
              total: data.total,
            },
          },
        })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: ACTION_TYPE.INIT_INFO,
          payload: {
            historyListLoading: false,
          },
        })
      })
    // eslint-disable-next-line
  }, [state.historyList.page, state.historyList.pageSize])

  useEffect(() => {
    if (state.activeStep === 2) {
      // TODO: completion request API
      financeProfileCompletionApi({}).finally(() =>
        dispatch({
          type: ACTION_TYPE.COMPLETION_CHANGE,
          payload: true,
        })
      )
    }
  }, [state.activeStep])
  const editFinance = (params: number, is_custom: boolean) => {
    dispatch({
      type: ACTION_TYPE.SET_PAYMENT_LOADING,
      payload: true,
    })
    dispatch({
      type: ACTION_TYPE.SET_PAYMENT_RESULT,
      payload: false,
    })
    setMinPaymentApi({ min_payment: params, is_custom })
      .then(data => {
        if (data.data.data === 'success') {
          customAlert('success', t('common.success'))
          getUserInfo()
          dispatch({
            type: ACTION_TYPE.SET_PAYMENT_RESULT,
            payload: true,
          })
        }
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: ACTION_TYPE.SET_PAYMENT_LOADING,
          payload: false,
        })
      })
  }

  return (
    <FinanceContext.Provider
      value={{
        state,
        dispatch,
        editFinance,
      }}
    >
      {children}
    </FinanceContext.Provider>
  )
}

export { FinanceContext, FinanceContextProvider }
