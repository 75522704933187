import { createContext, useReducer, useEffect, useContext } from 'react'
import { ApiResponse } from 'src/api'
import {
  getAdPlacementListApi,
  getAdPlacementDavListApi,
  getAdPlacementNameListApi,
} from 'src/api/adPlacement'
import { GlobalContext } from './global'
import { GlobalStoreContext } from './globalStore'
import {
  getLastTimeRange,
  sortCompare,
  downloadCsv,
  toThousands,
  rateTitleFormat,
  toPercent,
  tabMetricFormat,
  transformUTC0ToLocalTime,
} from 'src/utils'

type Props = {
  adPlacementList: any[]
  name: string
  chartData: any
  loading: boolean
  davLoading: boolean
  gameOption: any
  gameSelect: any
  country: any[]
  timeShow: string
  type: any[]
  time: [string, string]
  placementOption: any[]
  placementOptionAll: any
  placement: any[]
  checkedChartLine: any
  chartList: any
  resetIndex: number
  newChartType: string
  checkedMetrics: any
  summaryList: any
  sort: any
  chartType: string
  chartTabData: any
  davChartTabData: any
  holdPlacement: any
  holdDavPlacement: any
  showDavMetrics: boolean
  showArpdavMetrics: boolean
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props
const getTimeRange = (range: [string, string] | number): [string, string] => {
  // custom date range
  return typeof range === 'number' ? getLastTimeRange(range) : range
}

const initialState: Props = {
  adPlacementList: [],
  name: '',
  loading: false,
  davLoading: false,
  chartData: {},
  gameOption: [],
  gameSelect: '',
  country: [],
  timeShow: 'last_week',
  type: [],
  time: getTimeRange(7),
  placementOption: [],
  placementOptionAll: {},
  placement: [],
  chartList: [],
  checkedChartLine: [],
  resetIndex: 0,
  newChartType: 'Graph',
  checkedMetrics: ['ratio_revenue', 'ratio_impressions', 'dau'],
  summaryList: {
    columns: [
      { id: 'name', label: 'Summary', styles: { fontSize: '18px' } },
      { id: 'ratio_revenue', label: 'Revenue' },
      { id: 'ratio_impressions', label: 'Impressions' },
      { id: 'dau', label: 'DAU' },
      { id: 'placement_imp_dau', label: rateTitleFormat('Imp', 'DAU') },
      { id: 'placement_arpdau', label: 'ARPDAU' },
      { id: 'dav', label: 'DAV' },
      { id: 'placement_arpdav', label: 'ARPDAV' },
      { id: 'ad_viewer_rate', label: 'Ad Viewer Rate' },
    ],
    rows: [],
  },
  sort: {
    id: 'ratio_revenue',
    by: null,
  },
  chartType: 'ratio_revenue',
  chartTabData: [
    {
      name: 'Revenue',
      value: 'ratio_revenue',
      num: '$0',
      tooltip: 'Estimated revenue generated by the ad placements for the app.',
    },
    {
      name: 'Impressions',
      value: 'ratio_impressions',
      num: '0',
      tooltip: 'Total impressions generated by the ad placements for the app.',
    },
    {
      name: 'DAU',
      value: 'dau',
      num: '0',
      tooltip:
        'The average daily active users for all the ad placements of the app.',
    },
    {
      name: rateTitleFormat('Imp', 'DAU'),
      value: 'placement_imp_dau',
      num: '0',
      tooltip: 'Estimated impressions per DAU for different ad placements.',
    },
    {
      name: 'ARPDAU',
      value: 'placement_arpdau',
      num: '$0',
      tooltip:
        'Estimated average revenue per daily active user generated by each ad placement of the app.',
    },
  ],
  davChartTabData: [
    {
      name: 'DAV',
      value: 'dav',
      num: '0',
      tooltip:
        'The average number of unique daily active users who viewed one or more ads. Please select a single ad type or placement to view the summary.',
    },
    {
      name: 'ARPDAV',
      value: 'placement_arpdav',
      num: '$0',
      tooltip:
        'The average revenue per unique daily active users. Please select a single app to view the summary.',
    },
    {
      name: 'Ad Viewer Rate',
      value: 'ad_viewer_rate',
      num: '%0',
      tooltip:
        'Percentage of users who viewed one or more ads as a percentage of all daily active users. Please select a single ad type or placement to view the summary.',
    },
  ],
  holdPlacement: {},
  holdDavPlacement: {},
  showDavMetrics: true,
  showArpdavMetrics: true,
}

const typeOptions = {
  interstitial: 'Interstitial',
  banner: 'Banner',
  reward_video: 'Rewarded Video',
  nativeads: 'Native Ads',
  openads: 'App Open',
  rewardinter: 'Rewarded Interstitial',
  other: 'Other',
  android: 'Android',
  all: 'All',
}

const colorList = ['#FF8E00', '#90cc75', '#f07474', '#5a70b5']
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'adPlacementList':
      const disabled = action.payload?.tabDisabled || false
      const untilTime = action.payload?.untilTime || null
      const allData = action.payload
      const labels = allData.days
      const echartData: any = []
      const chartList: any = []
      const checkedChartLine: any = []
      allData?.data?.forEach((item: any, i: number) => {
        const color: string =
          colorList[i] ||
          '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
        const label: string =
          item.ad_placement + `(${typeOptions[item.ad_type] || item.ad_type})`
        echartData.push({
          name: label,
          type: 'line',
          smooth: true,
          itemStyle: { color: color },
          data: item.metric[prevState.chartType],
        })
        chartList.push({
          label,
          type: typeOptions[item.ad_type] || item.ad_type,
          value: label,
          totalNum: item.summary[prevState.chartType],
          color,
        })
        checkedChartLine.push(label)
      })
      if (!chartList.length) {
        chartList.push({
          color: '#FF8E00',
          label: 'All',
          totalNum: 0,
          type: '',
          value: 'All',
        })
      }
      const newChartList = JSON.parse(JSON.stringify(chartList))
      return {
        ...prevState,
        chartTabData: [
          {
            name: 'Revenue',
            value: 'ratio_revenue',
            disabled,
            untilTime,
            num:
              '$' +
              toThousands(
                tabMetricFormat(
                  action.payload?.summary_tab?.ratio_revenue || 0,
                  'revenue'
                ),
                true
              ),
            tooltip:
              'Estimated revenue generated by the ad placements for the app.',
          },
          {
            name: 'Impressions',
            value: 'ratio_impressions',
            disabled,
            untilTime,
            num: toThousands(
              action.payload?.summary_tab?.ratio_impressions || '0',
              true
            ),
            tooltip:
              'Total impressions generated by the ad placements for the app.',
          },
          {
            name: 'DAU',
            value: 'dau',
            disabled,
            untilTime,
            num: toThousands(
              tabMetricFormat(action.payload?.summary_tab?.dau || 0, 'dau'),
              true
            ),
            tooltip:
              'The average daily active users for all the ad placements of the app.',
          },
          {
            name: rateTitleFormat('Imp', 'DAU'),
            value: 'placement_imp_dau',
            disabled,
            untilTime,
            num: toThousands(
              tabMetricFormat(
                action.payload?.summary_tab?.placement_imp_dau || 0,
                'impdau'
              ),
              true
            ),
            tooltip:
              'Estimated impressions per DAU for different ad placements.',
          },
          {
            name: 'ARPDAU',
            value: 'placement_arpdau',
            disabled,
            untilTime,
            num:
              '$' +
              toThousands(
                tabMetricFormat(
                  action.payload?.summary_tab?.placement_arpdau || 0,
                  'arpdau'
                ),
                true
              ),
            tooltip:
              'Estimated average revenue per daily active user generated by each ad placement of the app.',
          },
        ],
        chartData: {
          days: labels,
          data: echartData,
        },
        chartList: newChartList.sort(
          (a: any, b: any) => b.totalNum - a.totalNum
        ),
        checkedChartLine: checkedChartLine,
      }
    case 'davChartTabData':
      const disabled2 = action.payload?.tabDisabled || false
      const untilTime2 = action.payload?.untilTime || null
      return {
        ...prevState,
        davChartTabData: [
          {
            name: 'DAV',
            value: 'dav',
            disabled: disabled2,
            untilTime: untilTime2,
            num:
              !prevState.showDavMetrics ||
              (!action.payload?.summary_tab?.deu &&
                action.payload?.summary_tab?.deu !== 0)
                ? '-'
                : toThousands(
                    tabMetricFormat(
                      action.payload?.summary_tab?.deu || 0,
                      'dav'
                    ),
                    true
                  ),
            tooltip:
              'The average number of unique daily active users who viewed one or more ads. Please select a single ad type or placement to view the summary.',
          },
          {
            name: 'ARPDAV',
            value: 'placement_arpdav',
            disabled: disabled2,
            untilTime: untilTime2,
            num:
              !prevState.showArpdavMetrics ||
              (!action.payload?.summary_tab?.revenue_deu &&
                action.payload?.summary_tab?.revenue_deu !== 0)
                ? '-'
                : '$' +
                  toThousands(
                    tabMetricFormat(
                      action.payload?.summary_tab?.revenue_deu || 0,
                      'arpdav'
                    ),
                    true
                  ),
            tooltip:
              'The average revenue per unique daily active users. Please select a single app to view the summary.',
          },
          {
            name: 'Ad Viewer Rate',
            value: 'ad_viewer_rate',
            disabled: disabled2,
            untilTime: untilTime2,
            num:
              !prevState.showDavMetrics ||
              (!action.payload?.summary_tab?.rate &&
                action.payload?.summary_tab?.rate !== 0)
                ? '-'
                : toPercent(
                    tabMetricFormat(
                      action.payload?.summary_tab?.rate || 0,
                      'percent'
                    )
                  ),
            tooltip:
              'Percentage of users who viewed one or more ads as a percentage of all daily active users. Please select a single ad type or placement to view the summary.',
          },
        ],
      }
    case 'summaryList':
      return {
        ...prevState,
        sort: {
          id: 'ratio_revenue',
          by: null,
        },
        summaryList: {
          columns: [
            {
              id: 'name',
              label: 'Summary',
              styles: { fontSize: '18px' },
            },
            { id: 'ratio_revenue', label: 'Revenue' },
            { id: 'ratio_impressions', label: 'Impressions' },
            { id: 'dau', label: 'DAU' },
            { id: 'placement_imp_dau', label: rateTitleFormat('Imp', 'DAU') },
            { id: 'placement_arpdau', label: 'ARPDAU' },
            { id: 'dav', label: 'DAV' },
            { id: 'placement_arpdav', label: 'ARPDAV' },
            { id: 'ad_viewer_rate', label: 'Ad Viewer Rate' },
          ],
          rows: action.payload?.data?.map((item: any) => {
            return {
              name:
                item.ad_placement +
                ' (' +
                (typeOptions[item.ad_type] || item.ad_type) +
                ')',
              ...item.summary,
            }
          }),
        },
      }
    case 'sortTableList':
      const list = prevState.summaryList
      return {
        ...prevState,
        sort: {
          id: action.payload.id,
          by: action.payload.by,
        },
        summaryList: {
          columns: list.columns,
          rows: sortCompare(list.rows, action.payload.id, action.payload.by),
        },
      }
    case 'time':
    case 'name':
    case 'loading':
    case 'davLoading':
    case 'gameOption':
    case 'gameSelect':
    case 'country':
    case 'timeShow':
    case 'type':
    case 'placementOption':
    case 'placementOptionAll':
    case 'placement':
    case 'resetIndex':
    case 'newChartType':
    case 'checkedMetrics':
    case 'chartType':
    case 'checkedChartLine':
    case 'holdPlacement':
    case 'holdDavPlacement':
    case 'showDavMetrics':
    case 'showArpdavMetrics':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  getPlacementList: (app_key?: string) => void
  sortList: (sort: any) => void
  exportData: (data: any, allData: any) => void
}

export const AdPlacementsContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  getPlacementList: (app_key?: string) => {},
  sortList: () => {},
  exportData: () => {},
})

export const AdPlacementsContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, state: GlobalState } = useContext(GlobalContext)
  const { state: globalStoreState, dispatch: globalStoreDispatch } =
    useContext(GlobalStoreContext)

  // handlers
  // 获取总的游戏列表
  useEffect(() => {
    dispatch({
      type: 'gameOption',
      payload: GlobalState.gameOption,
    })
    if (globalStoreState.adPlacementData.filter) {
      Object.keys(globalStoreState.adPlacementData.filter)?.forEach(
        (item: string) => {
          dispatch({
            type: item,
            payload: globalStoreState.adPlacementData.filter[item],
          })
        }
      )
      Object.keys(globalStoreState.adPlacementData)
        ?.filter((item: string) => item !== 'filter')
        ?.forEach((item: string) => {
          dispatch({
            type: item,
            payload: globalStoreState.adPlacementData[item],
          })
        })
      return
    }
    if (GlobalState.gameOption.length) {
      dispatch({
        type: 'gameSelect',
        payload: state.gameOption[0],
      })
      getPlacementList(GlobalState.gameOption[0])
    }
    dispatch({
      type: 'gameSelect',
      payload: GlobalState.gameOption[0] || {},
    })
    // eslint-disable-next-line
  }, [GlobalState.gameOption])
  // 获取广告位
  const formatAdPlacementNameList = (placementObj: any) => {
    let adPlacementList: string[] = []
    let typeList: { value: string }[] = []
    if (state.type.length === 0) {
      typeList = Object.keys(placementObj).map((item: string) => {
        return { value: item }
      })
    } else {
      typeList = state.type
    }
    typeList.forEach((item: { value: string }) => {
      const temp = placementObj[item.value]
      if (temp) {
        adPlacementList = [...adPlacementList, ...temp]
      }
    })
    dispatch({
      type: 'placementOption',
      payload: Array.from(new Set(adPlacementList)),
    })
  }
  useEffect(() => {
    if (!state.gameSelect.app_key) {
      return
    }
    getAdPlacementNameListApi({ app_keys: [state.gameSelect.app_key] })
      .then((res: any) => {
        dispatch({
          type: 'placementOptionAll',
          payload: res.data.data[state.gameSelect.app_key] || {},
        })
        globalStoreDispatch({
          type: 'adPlacementData',
          payload: {
            placementOptionAll: res.data.data[state.gameSelect.app_key] || {},
          },
        })
        formatAdPlacementNameList(res.data.data[state.gameSelect.app_key] || {})
      })
      .catch(errorCatch)
    // eslint-disable-next-line
  }, [state.gameSelect])
  useEffect(() => {
    if (!state.gameSelect.app_key) return
    formatAdPlacementNameList(state.placementOptionAll)
    // eslint-disable-next-line
  }, [state.type])

  useEffect(() => {
    if (!state.gameSelect.app_key) return
    dispatch({ type: 'adPlacementList', payload: state.holdPlacement })
    dispatch({ type: 'davChartTabData', payload: state.holdDavPlacement })
    // eslint-disable-next-line
  }, [state.chartType])
  const getPlacementList = (app?: any) => {
    dispatch({ type: 'loading', payload: true })
    dispatch({ type: 'davLoading', payload: true })
    const params: any = {
      from_date: state.time[0],
      to_date: state.time[1],
      filter: {
        app_keys: [app?.app_key || state.gameSelect.app_key],
        ad_types: state.type.map(item => item.value),
        country_code: state.country.map(item => item.code),
        ad_placements: state.placement.map(item => item.value),
      },
    }
    globalStoreDispatch({
      type: 'adPlacementData',
      payload: {
        filter: {
          time: state.time,
          timeShow: state.timeShow,
          gameSelect: app || state.gameSelect,
          type: state.type,
          country: state.country,
          placement: state.placement,
          placementOption: state.placementOption,
          placementOptionAll: state.placementOptionAll,
        },
      },
    })
    let stopTriggerDavApi = false
    // App Level - When App is selected, and no Ad Types or “All Ad Types” are selected, no ad placements or All ad placements.
    // Ad Type Level - When only a SINGLE ad type is selected with no ad placements or All ad placements
    // Ad Placement Level - When only a single ad placement is selected.
    if (
      (params.filter.app_keys.length &&
        (params.filter.ad_types.length === 0 ||
          params.filter.ad_types.length === 7) &&
        (params.filter.ad_placements.length === 0 ||
          params.filter.ad_placements.length ===
            state.placementOption?.length)) ||
      (params.filter.ad_types.length === 1 &&
        (params.filter.ad_placements.length === 0 ||
          params.filter.ad_placements.length ===
            state.placementOption?.length)) ||
      params.filter.ad_placements.length === 1
    ) {
      dispatch({
        type: 'showDavMetrics',
        payload: true,
      })
      dispatch({
        type: 'showArpdavMetrics',
        payload: true,
      })
      globalStoreDispatch({
        type: 'adPlacementData',
        payload: {
          showDavMetrics: true,
          showArpdavMetrics: true,
        },
      })
    } else {
      dispatch({
        type: 'showDavMetrics',
        payload: false,
      })
      dispatch({
        type: 'showArpdavMetrics',
        payload: false,
      })
      globalStoreDispatch({
        type: 'adPlacementData',
        payload: {
          showDavMetrics: false,
          showArpdavMetrics: false,
        },
      })
      stopTriggerDavApi = true
    }
    if (
      (params.filter.ad_types.length && params.filter.ad_types.length !== 7) ||
      (params.filter.country_code.length &&
        params.filter.country_code.length !== GlobalState.countryOption.length)
    ) {
      dispatch({
        type: 'showArpdavMetrics',
        payload: false,
      })
      globalStoreDispatch({
        type: 'adPlacementData',
        payload: {
          showArpdavMetrics: false,
        },
      })
    }
    getAdPlacementListApi(params)
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'adPlacementList', payload: data })
        dispatch({ type: 'holdPlacement', payload: data })
        dispatch({
          type: 'summaryList',
          payload: data,
        })
        globalStoreDispatch({
          type: 'adPlacementData',
          payload: {
            adPlacementList: data,
            holdPlacement: data,
            summaryList: data,
          },
        })
        if (params.filter.ad_placements.length === 1) {
          const davData = {
            data: {
              deu: data?.data[0]?.metric?.dav || [],
              revenue_deu: data?.data[0]?.metric?.placement_arpdav || [],
              rate: data?.data[0]?.metric?.ad_viewer_rate || [],
            },
            days: data?.days || [],
            summary_tab: {
              deu: data?.summary_tab?.dav || 0,
              revenue_deu: data?.summary_tab?.placement_arpdav || 0,
              rate: data?.summary_tab?.ad_viewer_rate || 0,
            },
          }
          dispatch({ type: 'davChartTabData', payload: davData })
          dispatch({ type: 'holdDavPlacement', payload: davData })
          globalStoreDispatch({
            type: 'adPlacementData',
            payload: {
              davChartTabData: davData,
              holdDavPlacement: davData,
            },
          })
        }
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 503 &&
          err?.response?.data?.code === 'trino_migration'
        ) {
          let untilTime = null
          if (err?.response?.data?.extra?.after) {
            untilTime = transformUTC0ToLocalTime(
              err?.response?.data?.extra?.after,
              'Do MMM YYYY hh:mm a'
            )
          }
          globalStoreDispatch({
            type: 'adPlacementData',
            payload: {
              adPlacementList: { tabDisabled: true, untilTime },
              holdPlacement: { tabDisabled: true, untilTime },
              summaryList: {},
            },
          })
          dispatch({
            type: 'adPlacementList',
            payload: { tabDisabled: true, untilTime },
          })
          dispatch({
            type: 'holdPlacement',
            payload: { tabDisabled: true, untilTime },
          })
          if (params.filter.ad_placements.length === 1) {
            globalStoreDispatch({
              type: 'adPlacementData',
              payload: {
                davChartTabData: { tabDisabled: true, untilTime },
                holdDavPlacement: { tabDisabled: true, untilTime },
              },
            })
            dispatch({
              type: 'davChartTabData',
              payload: { tabDisabled: true, untilTime },
            })
            dispatch({
              type: 'holdDavPlacement',
              payload: { tabDisabled: true, untilTime },
            })
          }
        } else {
          errorCatch(err)
          globalStoreDispatch({
            type: 'adPlacementData',
            payload: {
              adPlacementList: {},
              holdPlacement: {},
              summaryList: {},
            },
          })
          if (params.filter.ad_placements.length === 1) {
            globalStoreDispatch({
              type: 'adPlacementData',
              payload: {
                davChartTabData: {},
                holdDavPlacement: {},
              },
            })
          }
        }
      })
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
        if (params.filter.ad_placements.length === 1) {
          dispatch({ type: 'davLoading', payload: false })
        }
      })
    if (params.filter.ad_placements.length === 1) return
    if (stopTriggerDavApi) {
      dispatch({ type: 'davChartTabData', payload: {} })
      dispatch({ type: 'holdDavPlacement', payload: {} })
      globalStoreDispatch({
        type: 'adPlacementData',
        payload: {
          davChartTabData: {},
          holdDavPlacement: {},
        },
      })
      return
    }
    const davParams = JSON.parse(JSON.stringify(params))
    if (davParams.filter.ad_types.length === 7) {
      davParams.filter.ad_types = []
    }
    getAdPlacementDavListApi(davParams)
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'davChartTabData', payload: data })
        dispatch({ type: 'holdDavPlacement', payload: data })
        globalStoreDispatch({
          type: 'adPlacementData',
          payload: {
            davChartTabData: data,
            holdDavPlacement: data,
          },
        })
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 503 &&
          err?.response?.data?.code === 'trino_migration'
        ) {
          let untilTime = null
          if (err?.response?.data?.extra?.after) {
            untilTime = transformUTC0ToLocalTime(
              err?.response?.data?.extra?.after,
              'Do MMM YYYY hh:mm a'
            )
          }
          globalStoreDispatch({
            type: 'adPlacementData',
            payload: {
              davChartTabData: { tabDisabled: true, untilTime },
              holdDavPlacement: { tabDisabled: true, untilTime },
            },
          })

          dispatch({
            type: 'davChartTabData',
            payload: { tabDisabled: true, untilTime },
          })
          dispatch({
            type: 'holdDavPlacement',
            payload: { tabDisabled: true, untilTime },
          })
        } else {
          errorCatch(err)
          globalStoreDispatch({
            type: 'adPlacementData',
            payload: {
              davChartTabData: {},
              holdDavPlacement: {},
            },
          })
        }
      })
      .finally(() => {
        dispatch({ type: 'davLoading', payload: false })
      })
  }
  const exportData = (data: any, allData: any) => {
    const name = `MAS_ad_placement_summary_view.csv`
    let csv = ''
    // 拼接筛选条件
    csv +=
      'DATES:,' +
      state.time[0] +
      ' / ' +
      state.time[1] +
      ',,APPLICATION:,' +
      state.gameSelect.name +
      ',,AD_TYPE:,' +
      (state.type.map(item => item.label).join(',') || 'all') +
      ',,AD_PLACEMENTS:,' +
      (state.placement.map(item => item.label).join(',') || 'all') +
      ',,COUNTRIES:,' +
      (state.country.map(item => item.name).join(',') || 'all') +
      '\n' +
      '\n'
    // 循环拼接数据
    data.forEach((column: any) => {
      csv += column.label + ','
    })
    csv += '\n'
    allData.forEach((row: any) => {
      data.forEach((column: any, i: number) => {
        const value = `"${
          !row[column.id] && row[column.id] !== 0
            ? '-'
            : (column.id === 'ratio_revenue' ||
              column.id === 'placement_arpdau' ||
              (column.id === 'revenue_deu' && state.showArpdavMetrics)
                ? '$'
                : '') +
              (column.id === 'rate' && state.showDavMetrics
                ? toPercent(row[column.id])
                : row[column.id])
        }",`
        csv += value
      })
      csv += '\n'
    })

    downloadCsv(csv, name, 'text/csv;encoding:utf-8')
  }
  const sortList = (sort: any) => {
    dispatch({
      type: 'sortTableList',
      payload: sort,
    })
  }
  useEffect(() => {
    if (state.resetIndex) {
      getPlacementList()
    }
    // eslint-disable-next-line
  }, [state.resetIndex])
  // returns
  return (
    <AdPlacementsContext.Provider
      value={{
        state,
        dispatch,
        getPlacementList,
        exportData,
        sortList,
      }}
    >
      {children}
    </AdPlacementsContext.Provider>
  )
}
