const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '16'
  const color = props.color || '#4A5C75'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00001 12L5.69232 8.23529L8.00001 10.1176L14 4"
        stroke={color}
      />
      <path d="M11.2305 4H13.9997V6.82353" stroke={color} />
    </svg>
  )
}

export default SVG
