const SVG = (props: any) => {
  const width = props.width || '20'
  const height = props.height || '20'
  const color = props.color || '#41484E'
  return (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="16954"
      width={width}
      height={height}
    >
      <path
        d="M384 764h-44c-49.2 0-58-7.2-67.6-19.2-11-13.8-16.4-38.2-16.4-108.4V302.8c38.2-22.2 64-63.4 64-110.8 0-70.6-57.4-128-128-128S64 121.4 64 192c0 47.4 25.8 88.6 64 110.8v333.6c0 92.8 7.4 141.6 44.2 188 39.8 50.2 90 71.6 167.8 71.6h44v128l192-192-192-192v124zM192 112c44.2 0 80 35.8 80 80s-35.8 80-80 80-80-35.8-80-80 35.8-80 80-80z m704 609.2V381.6c0-92.8-7.4-141.6-44.2-188C812 143.4 761.8 124 684 124h-44V0l-192 192 192 192v-128h44c49.2 0 58 5.2 67.6 17.2 11 13.8 16.4 38.2 16.4 108.4v339.6c-38.2 22.2-64 63.4-64 110.8 0 70.6 57.4 128 128 128s128-57.4 128-128c0-47.4-25.8-88.6-64-110.8zM832 912c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80z"
        p-id="16955"
        fill={color}
      ></path>
    </svg>
  )
}

export default SVG
