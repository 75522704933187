import { useContext, useState } from 'react'
import { Box, useTheme, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DashContext } from 'src/context/dashboard'
import { GlobalStoreContext } from 'src/context/globalStore'
import { useNavigate } from 'react-router-dom'
import CardView from './cardView'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      topCard: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        rowGap: '16px',
        marginTop: '15px',
        padding: '1px',
        overflow: 'hidden',
        '&::-webkit-scrollbar': {
          height: '0',
        },
      },
    }
  })
const TopChart = (): JSX.Element => {
  // const
  const { state } = useContext(DashContext)
  const { dispatch: GlobalStoreDispatch } = useContext(GlobalStoreContext)
  const min860 = useMediaQuery('(min-width:860px)')
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const chartData = state.chartCardData
  const rateSessionData = [
    // state.arpuChartCardData,
    // state.stickinessChartCardData,
    ...state.rateSessionData,
  ]
  const [showAllCards, setShowAllCards] = useState<boolean>(false)
  const toChartPage = (item: any) => {
    GlobalStoreDispatch({ type: 'fromWelcome', payload: true })
    GlobalStoreDispatch({ type: 'time', payload: state.time })
    GlobalStoreDispatch({ type: 'gameSelect', payload: state.gameSelect })
    GlobalStoreDispatch({ type: item.storeData, payload: { filter: '' } })
    if (item.path) {
      navigate(item.path)
    } else {
      navigate('/dash/performance/revenue')
    }
  }
  const toUserActivityPage = (item: any) => {
    if (item.clickState) {
      GlobalStoreDispatch({ type: 'fromWelcome', payload: true })
      GlobalStoreDispatch({ type: 'time', payload: state.time })
      GlobalStoreDispatch({ type: 'gameSelect', payload: state.gameSelect })
      GlobalStoreDispatch({ type: item.storeData, payload: { filter: '' } })
      if (item.path) {
        navigate(item.path)
      } else {
        navigate('/dash/gameplay-insights/sessions')
      }
    }
  }
  // returns
  return (
    <Box position="relative">
      <Box
        className={classes.topCard}
        sx={showAllCards ? {} : { height: '145px' }}
      >
        {Array(9)
          .fill(0)
          .map((item: any, index: number) => (
            <CardView
              key={index}
              cardKey={index}
              title={chartData[index].name}
              tooltip={
                <Box>
                  <Box>
                    The figure in bold is the{' '}
                    {(chartData[index].name === 'DAV' ||
                    chartData[index].name === 'DAU'
                      ? 'average'
                      : 'total number') + ' '}
                    of
                    {' ' + chartData[index].name} during your selected time
                    period.
                  </Box>
                  <Box marginTop="14px">
                    The percentage at the top right is the difference in the
                    number of {chartData[index].name} between your selected
                    Start date and the End date. For example, if you selected
                    Aug. 1 as start date and Aug. 5 end date, the percentage
                    will be the comparison between the change of these two days.
                  </Box>
                  {!chartData[index].chartCard && (
                    <Box marginTop="14px">
                      The number at the bottom is the difference between your
                      elected Start date and the End date expressed in actual
                      numbers.
                    </Box>
                  )}
                </Box>
              }
              tendency={chartData[index].tendency}
              changeRate={chartData[index].changeRate}
              num={chartData[index].num}
              showChart={!!chartData[index].chartCard}
              chartValue={chartData[index].value}
              absoluteValue={chartData[index].absoluteValue}
              onlyOneGame={false}
              clickCard={true}
              onClick={() => {
                if (state.chartCardLoading) return
                toChartPage(chartData[index])
              }}
              loading={state.chartCardLoading}
              disabled={chartData[index].disabled}
              untilTime={chartData[index].untilTime}
            />
          ))}
        {Array(1)
          .fill(0)
          .map((item, index) => (
            <CardView
              key={index + 'second'}
              cardKey={index + 'second'}
              title={rateSessionData[index].name}
              tooltip={
                <Box>
                  {rateSessionData[index].value === 'arpu' ? (
                    <>
                      Ad ARPU is the average ad revenue that is produced by one
                      active user of your app.
                    </>
                  ) : (
                    <>
                      <Box>
                        The bold number is the average of
                        {' ' + rateSessionData[index].name} for the selected
                        duration.
                      </Box>
                      <Box marginTop="14px">
                        The percentage at the top right is the difference in the
                        number of {rateSessionData[index].name} between your
                        selected Start date and the End date. For example, if
                        you selected Aug. 1 as start date and Aug. 5 end date,
                        the percentage will be the comparison between the change
                        of these two days.
                      </Box>
                      <Box marginTop="14px">
                        The number at the bottom is the difference between your
                        elected Start date and the End date expressed in actual
                        numbers.
                      </Box>
                    </>
                  )}
                </Box>
              }
              tendency={rateSessionData[index].tendency}
              changeRate={rateSessionData[index].changeRate}
              num={rateSessionData[index].num}
              showChart={!!rateSessionData[index].chartCard}
              chartValue={rateSessionData[index].value}
              absoluteValue={rateSessionData[index].absoluteValue}
              onlyOneGame={!rateSessionData[index].clickState}
              hideTrend={rateSessionData[index].value === 'arpu'}
              clickCard={
                rateSessionData[index].clickState && rateSessionData[index].path
              }
              onClick={() => {
                if (
                  (index === 0 && state.arpuLoading) ||
                  (index === 1 && state.stickinessLoading) ||
                  state.chartCardLoading ||
                  !rateSessionData[index].path
                )
                  return
                toUserActivityPage(rateSessionData[index])
              }}
              loading={
                (index === 0 && state.arpuLoading) ||
                (index === 1 && state.stickinessLoading) ||
                state.chartCardLoading
              }
              disabled={rateSessionData[index].disabled}
              untilTime={rateSessionData[index].untilTime}
            />
          ))}
        {/* 占位展示 */}
        {min860 && (
          <>
            {Array(2)
              .fill(0)
              .map((item, index) => (
                <CardView key={index + 'third'} sx={{ opacity: 0 }} />
              ))}
          </>
        )}
      </Box>
      <Box
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          textAlign: 'right',
          color: '#1E96FC',
          cursor: 'pointer',
          marginTop: '8px',
        }}
        onClick={() => {
          setShowAllCards(!showAllCards)
        }}
      >
        {showAllCards ? 'Show Less' : 'Show All'}
      </Box>
    </Box>
  )
}

export default TopChart
