import { Box, Button, Tooltip, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import {
  InfoOutlined as InfoOutlinedIcon,
  VideogameAsset as VideogameAssetIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons'
import { GameContext } from 'src/context/games'
import { GlobalContext } from 'src/context/global'
import { PNG, SVGIcon } from 'src/images'
import { numFormatter } from 'src/utils'
import UpsellActiveAdsView from './upsellActiveAds'
import UpsellProgressView from './progress'
import { AdaptiveTooltip } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      tableTr: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid rgba(255, 142, 0, 0.3)',
        padding: '12px 0',
        fontSize: '14px',
      },
      gamePhoto: {
        width: '48px',
        height: '48px',
        verticalAlign: 'middle',
        borderRadius: '4px',
      },
      platformPhoto: {
        width: '14px',
        height: '14px',
        verticalAlign: 'text-top',
      },
      title: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '40px',
      },
      appWidth: {
        flex: 1.5,
        minWidth: '170px',
      },
      issueWidth: {
        flex: 0.5,
        minWidth: '70px',
        textAlign: 'center',
      },
      performance: {
        flex: 0.5,
        minWidth: '104px',
        textAlign: 'center',
      },
      progress: {
        flex: 2,
        minWidth: '350px',
        textAlign: 'center',
      },
      activeAds: {
        flex: 1,
        minWidth: '160px',
        textAlign: 'center',
        marginRight: '10px',
      },
      infoIcon: {
        fontSize: '16px !important',
        verticalAlign: 'middle',
        marginLeft: '4px',
        color: theme.palette.text.secondary,
        marginTop: '-3px',
      },
      outBox: {
        padding: '0 8px',
        border: `1px solid ${
          theme.palette.mode === 'light'
            ? theme.palette.primary.main
            : 'rgba(255, 142, 0, 0.4)'
        }`,
        borderRadius: '8px',
        background:
          theme.palette.mode === 'light'
            ? '#fff3e4'
            : 'rgba(249, 180, 95, 0.15)',
      },
      upsellTitle: {
        position: 'relative',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '13px 0',
      },
      arrowIcon: {
        fontSize: '14px !important',
        transform: 'rotate(90deg)',
        marginLeft: '16px',
        verticalAlign: 'middle',
        marginTop: '-1px',
      },
      closeIconBox: {
        boxSizing: 'border-box',
        position: 'absolute',
        width: '24px',
        height: '24px',
        lineHeight: '24px',
        padding: '4px',
        right: '0',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
      },
      closeIcon: {
        fontSize: '16px !important',
        color: theme.palette.primary.main,
      },
    }
  })

const UpsellAppView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state } = useContext(GameContext)
  const { state: globalState } = useContext(GlobalContext)
  const [showList, setShowList] = useState(false)
  const navigate = useNavigate()
  const toUrlPage = (url: string) => {
    url && window.open(url)
  }
  return (
    <>
      {!!state.upsellAppData.length && (
        <Box
          className={classes.outBox}
          sx={
            globalState.isMobile
              ? { margin: '16px -8px 0' }
              : { margin: '0 16px' }
          }
        >
          <Box
            className={classes.upsellTitle}
            onClick={() => {
              setShowList(!showList)
            }}
          >
            You have{' '}
            <Box
              component="span"
              color={theme.palette.primary.main}
              fontWeight={600}
            >
              {state.upsellAppData.length} Apps to be integrated with MAS
              <ArrowForwardIosIcon
                className={classes.arrowIcon}
                style={showList ? { transform: 'rotate(-90deg)' } : {}}
              />
            </Box>
          </Box>
          {showList && (
            <>
              {state.upsellAppData.map((row: any, index: number) => {
                return globalState.isMobile ? (
                  <Box
                    sx={{
                      padding: '16px 0',
                      borderTop: '1px solid rgba(255, 142, 0, 0.3)',
                    }}
                    key={index}
                  >
                    <Box minWidth="170px">
                      <Box
                        display="flex"
                        alignItems="center"
                        position="relative"
                        width="100%"
                      >
                        <Box
                          position="relative"
                          width="48px"
                          height="48px"
                          marginRight="16px"
                          onClick={() => {
                            toUrlPage(row.store_url)
                          }}
                          style={{
                            cursor: row.store_url ? 'pointer' : 'not-allowed',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              right: '-8px',
                              top: '-8px',
                              width: '14px',
                              height: '14px',
                              borderRadius: '50%',
                              border: `2px solid ${
                                theme.palette.mode === 'light'
                                  ? '#FFF'
                                  : '#1A202C'
                              }`,
                              overflow: 'hidden',
                              background:
                                row.platform === 'iOS'
                                  ? '#FFF'
                                  : theme.palette.background.paper,
                            }}
                          >
                            <img
                              src={
                                row.platform === 'iOS' ? PNG.ios : PNG.android
                              }
                              className={classes.platformPhoto}
                              alt=""
                            />
                          </Box>

                          {row.icon_url ? (
                            <img
                              src={row.icon_url}
                              className={classes.gamePhoto}
                              alt=""
                            />
                          ) : (
                            <SVGIcon.GameDefault
                              style={{
                                marginRight: '16px',
                                verticalAlign: 'middle',
                              }}
                              color={
                                theme.palette.mode === 'light'
                                  ? '#EFF2F3'
                                  : '#2B313C'
                              }
                            />
                          )}
                        </Box>
                        <Box width="calc(100% - 70px)">
                          <Box
                            fontSize="14px"
                            fontWeight="500"
                            sx={{ wordBreak: 'break-word' }}
                          >
                            {row.name}
                          </Box>
                          {!!(
                            row.min_predicted_revenue &&
                            row.max_predicted_revenue
                          ) && (
                            <Box
                              sx={{
                                width: '100%',
                                color: '#A8A8A8',
                                marginTop: '4px',
                              }}
                              display="flex"
                              alignItems="center"
                            >
                              <Box
                                style={{
                                  fontVariantNumeric: 'tabular-nums',
                                  wordBreak: 'break-word',
                                }}
                                fontSize="12px"
                                color={theme.palette.text.secondary}
                              >
                                Est. Revenue/mo:{' '}
                                {`$${numFormatter(
                                  row.min_predicted_revenue
                                )} - `}
                                <Box display="inline-block">
                                  ${numFormatter(row.max_predicted_revenue)}
                                  {row.predicted_revenue_method && (
                                    <AdaptiveTooltip
                                      isMobile={true}
                                      title={row.predicted_revenue_method}
                                      arrow
                                      placement="top"
                                      disableInteractive
                                    >
                                      <InfoOutlinedIcon
                                        className={classes.infoIcon}
                                      />
                                    </AdaptiveTooltip>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        fontSize: '12px',
                        color: theme.palette.custom.tableHead,
                        margin: '16px 0 8px',
                        lineHeight: '16px',
                      }}
                    >
                      ACTIVE ADS
                    </Box>
                    <Box>
                      <UpsellActiveAdsView isMobile={true} />
                    </Box>
                    <Box
                      sx={{
                        fontSize: '12px',
                        color: theme.palette.custom.tableHead,
                        margin: '16px 0 8px',
                        lineHeight: '16px',
                      }}
                    >
                      RELEASE STEPS
                    </Box>
                    <Box
                      sx={{
                        transform: 'scale(0.8)',
                        transformOrigin: 'left',
                      }}
                    >
                      <UpsellProgressView />
                    </Box>
                    <Box marginTop="16px">
                      <Button
                        color="primary"
                        variant="outlined"
                        sx={{ width: '164px' }}
                        onClick={() => {
                          navigate(
                            `/dash/games/add?upsellKey=upsell&detail=${encodeURIComponent(
                              JSON.stringify(row)
                            )}`
                          )
                        }}
                      >
                        <VideogameAssetIcon
                          sx={{
                            fontSize: '22px',
                            marginRight: '8px',
                          }}
                        />
                        Integrate MAS
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.tableTr} key={'upsell' + index}>
                    <Box className={classes.appWidth}>
                      <Box
                        display="flex"
                        alignItems="center"
                        position="relative"
                        width="100%"
                      >
                        <Box
                          position="relative"
                          width="48px"
                          height="48px"
                          marginRight="16px"
                          onClick={() => {
                            toUrlPage(row.store_url)
                          }}
                          style={{
                            cursor: row.store_url ? 'pointer' : 'not-allowed',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              right: '-8px',
                              top: '-8px',
                              width: '14px',
                              height: '14px',
                              borderRadius: '50%',
                              border: `2px solid ${
                                theme.palette.mode === 'light'
                                  ? '#FFF'
                                  : '#1A202C'
                              }`,
                              overflow: 'hidden',
                              background:
                                row.platform === 'iOS'
                                  ? '#FFF'
                                  : theme.palette.background.paper,
                            }}
                          >
                            <img
                              src={
                                row.platform === 'iOS' ? PNG.ios : PNG.android
                              }
                              className={classes.platformPhoto}
                              alt=""
                            />
                          </Box>
                          {row.icon_url ? (
                            <img
                              src={row.icon_url}
                              className={classes.gamePhoto}
                              alt=""
                            />
                          ) : (
                            <SVGIcon.GameDefault
                              style={{
                                marginRight: '16px',
                                verticalAlign: 'middle',
                              }}
                              color={
                                theme.palette.mode === 'light'
                                  ? '#EFF2F3'
                                  : '#2B313C'
                              }
                            />
                          )}
                        </Box>
                        <Box width="calc(100% - 64px)">
                          <Box
                            fontSize="14px"
                            fontWeight="500"
                            sx={{ wordBreak: 'break-word' }}
                          >
                            {row.name}
                          </Box>
                          {!!(
                            row.min_predicted_revenue &&
                            row.max_predicted_revenue
                          ) && (
                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{
                                width: '100%',
                                color: '#A8A8A8',
                                marginTop: '4px',
                              }}
                            >
                              <Box
                                style={{
                                  fontVariantNumeric: 'tabular-nums',
                                  wordBreak: 'break-word',
                                }}
                                color={theme.palette.text.secondary}
                                width="100%"
                                fontSize="12px"
                              >
                                Est. Revenue/mo:{' '}
                                {`$${numFormatter(
                                  row.min_predicted_revenue
                                )} - `}
                                <Box display="inline-block">
                                  ${numFormatter(row.max_predicted_revenue)}
                                  {row.predicted_revenue_method && (
                                    <Tooltip
                                      title={row.predicted_revenue_method}
                                      arrow
                                      placement="top"
                                      disableInteractive
                                    >
                                      <InfoOutlinedIcon
                                        sx={{
                                          fontSize: '14px !important',
                                          verticalAlign: 'middle',
                                          marginLeft: '4px',
                                          color: theme.palette.text.secondary,
                                          marginTop: '-3px',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.activeAds}>
                      <UpsellActiveAdsView />
                    </Box>
                    <Box className={classes.progress}>
                      <UpsellProgressView />
                    </Box>
                    <Box className={classes.issueWidth} />
                    {globalState.showPerformanceScoring && (
                      <Box className={classes.performance} />
                    )}
                    <Box width="168px" position="relative">
                      <Button
                        color="primary"
                        variant="outlined"
                        sx={{
                          width: '164px',
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          transform: 'translateY(-50%)',
                        }}
                        onClick={() => {
                          navigate(
                            `/dash/games/add?upsellKey=upsell&detail=${encodeURIComponent(
                              JSON.stringify(row)
                            )}`
                          )
                        }}
                      >
                        <VideogameAssetIcon
                          sx={{
                            fontSize: '22px',
                            marginRight: '8px',
                          }}
                        />
                        Integrate MAS
                      </Button>
                    </Box>
                  </Box>
                )
              })}
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default UpsellAppView
