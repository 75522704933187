import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SessionsContext } from 'src/context/gamePlayInsights/sessions'
import { CohortsContext } from 'src/context/gamePlayInsights/cohorts'
import SessionsChartView from './sessionsChart'
import ChartSettingView from './chartSetting'
import { LoadingComponent, ChartTypeToggleGroup } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      contentBottom: {
        position: 'relative',
        width: '100%',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
      filterSelectBox: {
        boxSizing: 'border-box',
        width: '124px',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        border: `1px solid ${
          theme.palette.mode === 'light'
            ? theme.palette.primary.main
            : '#51565F'
        }`,
        borderRadius: '6px',
        fontSize: '14px',
        color:
          theme.palette.mode === 'light' ? theme.palette.primary.main : '#FFF',
        padding: '0 28px',
        cursor: 'pointer',
      },
      filterIcon: {
        width: '12px',
        height: '12px',
        marginRight: '10px',
        verticalAlign: 'middle',
      },
    }
  })
const MiddleChart = (): JSX.Element => {
  // const
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state } = useContext(SessionsContext)
  const { state: cohortsState, dispatch: cohortsDispatch } =
    useContext(CohortsContext)

  // returns
  return (
    <Box className={classes.contentBottom}>
      {state.loading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            padding: '0 4px',
          }}
        >
          <Box marginRight="16px">
            {state.tabType === 'sessions' ? 'Sessions' : 'Session Length'}
          </Box>
          <ChartSettingView />
        </Box>
        <ChartTypeToggleGroup
          value={cohortsState.newChartType}
          onChange={(chartType: string) => {
            cohortsDispatch({ type: 'newChartType', payload: chartType })
          }}
        />
      </Box>
      <Box marginTop="26px">
        <SessionsChartView id="chart" width="100" />
      </Box>
    </Box>
  )
}

export default MiddleChart
