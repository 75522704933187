import { Box, TextField, Button, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useContext, useEffect, useState } from 'react'

import { LoadingBtn } from 'src/components'
import { GlobalContext } from 'src/context/global'
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      textField: {
        width: '268px !important',
        marginBottom: `${theme.spacing(1)} !important`,
        marginRight: `${theme.spacing(2)} !important`,
        backgroundColor: `${theme.palette.background.paper} !important`,
        '& .MuiInputBase-input': {
          color: `${theme.palette.text.secondary} !important`,
        },
      },
      backButton: {
        width: '47% !important',
        height: '36px !important',
        padding: '0',
      },
      saveButton: {
        width: '47% !important',
        height: '36px !important',
        padding: '0',
        '&.MuiButton-contained.Mui-disabled': {
          backgroundColor: '#29444f !important',
        },
      },
      icon: {
        width: '22px',
        height: '18px',
      },
      inputLabel: {
        color: theme.palette.custom.inputLabel,
        fontSize: 12,
        marginBottom: 8,
      },
      phoneNumber: {
        position: 'relative',
        '& .PhoneInputInput': {
          height: '36px',
          border: `1px solid ${
            theme.palette.mode === 'light' ? '#eee' : '#51565F'
          }`,
          outline: 'none',
          borderRadius: '4px',
          background: 'transparent',
          color:
            theme.palette.mode === 'light'
              ? '#102542'
              : 'rgba(255, 255, 255, 0.6)',
          paddingLeft: '39px',
        },
        '& .PhoneInputCountrySelectArrow': {
          width: '4px',
          height: '4px',
          marginLeft: '5px',
          border: `1px solid ${
            theme.palette.mode === 'light'
              ? '#102542'
              : 'rgba(255, 255, 255, 0.6)'
          }`,
          borderTopWidth: 0,
          borderLeftWidth: 0,
          transform: 'rotate(45deg)',
          opacity: 0.8,
        },
        '& .PhoneInputCountryIcon': {
          width: '24px',
          height: '16px',
          color:
            theme.palette.mode === 'light'
              ? '#102542'
              : 'rgba(255, 255, 255, 0.6)',
        },
      },
    }
  })

const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const className = useStyles()
  const { state, personalInfoChange, setAccountEditKeyHandle } =
    useContext(GlobalContext)
  const [owner_tel, setOwner_tel] = useState<string>('')
  const [telError, setTelError] = useState<boolean>(false)

  const [userData, setUserData] = useState({
    owner_tel: state.userInfo?.owner_tel || '-',
  })

  const saveChange = () => {
    if (!isPossiblePhoneNumber(owner_tel)) {
      setTelError(true)
      return
    }
    personalInfoChange({
      owner_tel:
        '+' +
        (parsePhoneNumber(owner_tel) as any).countryCallingCode +
        '-' +
        (parsePhoneNumber(owner_tel) as any).nationalNumber,
    })
  }

  useEffect(() => {
    if (state.userInfo) {
      const { owner_tel } = state.userInfo
      setUserData(() => ({
        owner_tel,
      }))
    }
  }, [state.userInfo])

  return (
    <>
      <Box>
        <Box className={className.inputLabel}>Phone Number</Box>
        <TextField
          fullWidth
          disabled
          defaultValue={userData.owner_tel}
          placeholder="phone number"
          type="text"
          variant="outlined"
        />
      </Box>
      <Box marginTop="16px">
        <Box className={className.inputLabel}>New Phone Number</Box>
        <Box className={className.phoneNumber}>
          <Box
            sx={{
              position: 'absolute',
              fontSize: '28px',
              color: theme.palette.primary.main,
              width: '11px',
              textAlign: 'center',
              left: '61px',
              top: '9px',
            }}
          >
            *
          </Box>
          <PhoneInput
            placeholder="Phone number"
            value={owner_tel}
            international
            initialValueFormat="national"
            onChange={(val: any) => {
              setTelError(false)
              setOwner_tel(val)
            }}
          />
          {telError && (
            <Box
              sx={{
                color: '#d32f2f',
                fontSize: '0.75rem',
                margin: '3px 14px 0',
              }}
            >
              {owner_tel
                ? 'Wrong phone number format'
                : 'Phone number is required'}
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex" marginTop="24px" justifyContent="space-between">
        <Button
          className={className.backButton}
          onClick={() => setAccountEditKeyHandle('')}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          disabled={state.buttonLoading}
          className={className.saveButton}
          color="primary"
          variant="contained"
          onClick={saveChange}
        >
          {state.buttonLoading ? (
            <LoadingBtn
              loadingStyle={{
                position: 'absolute',
                width: '100%',
                justifyContent: 'center',
              }}
            />
          ) : (
            'Update'
          )}
        </Button>
      </Box>
    </>
  )
}

export default View
