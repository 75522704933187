import {
  Box,
  Container,
  Dialog,
  TextField,
  InputAdornment,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import { Edit as EditIcon } from '@material-ui/icons'
import { useContext, useState, useEffect } from 'react'

import { toThousands, numFixed } from 'src/utils'
import { FinanceContext } from 'src/context/finance'
import { GlobalContext } from 'src/context/global'
import { LoadingBtn, BorderStyleSelectRow, PopoverTip } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        boxSizing: 'border-box',
        height: '250px',
        position: 'relative',
        padding: '21px 24px',
      },
      title: {
        fontSize: '20px',
        fontWeight: 500,
        color: theme.palette.text.primary,
        marginBottom: '28px',
      },
      cardMaid: {
        fontSize: '24px',
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
        padding: '0 !important',
        marginTop: '8px',
        color: theme.palette.text.primary,
      },
      edit: {
        position: 'absolute',
        top: '11px',
        right: '12px',
        fontSize: '18px !important',
        verticalAlign: 'middle',
        marginLeft: '8px',
        color:
          theme.palette.mode === 'dark'
            ? 'rgb(255, 255, 255)'
            : 'rgba(0, 0, 0, 0.54)',
      },
      dialogContent: {
        width: '70vw',
        maxWidth: '508px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center',
      },
      text: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '20px',
      },
      desc: {
        fontSize: '14px',
      },
      baseButton: {
        boxSizing: 'border-box',
        width: '123px',
        height: '32px',
        lineHeight: '32px',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '21px',
        borderRadius: '5px',
        display: 'inline-block',
        cursor: 'pointer',
        background: theme.palette.primary.main,
        color: '#ffffff',
      },
      searchBox: {
        position: 'absolute',
        height: '40px',
        lineHeight: '40px',
        top: '16px',
        right: '24px',
        color:
          theme.palette.mode === 'light'
            ? theme.palette.text.secondary
            : theme.palette.primary.main,
      },
      searchLabel: {
        width: '150px',
        position: 'relative',
        display: 'flex',
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        padding: '8px 16px',
        border: `1px solid ${theme.palette.custom.paperBorder}`,
        borderRadius: '6px',
        justifyContent: 'space-between',
        boxShadow:
          theme.palette.mode === 'light'
            ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
            : 'none',
      },
      label: {
        position: 'absolute',
        fontSize: '12px',
        lineHeight: '12px',
        top: '-6px',
        left: '10px',
        background: theme.palette.background.paper,
        padding: '0 6px',
        color: theme.palette.text.secondary,
      },
      search: {
        width: '200px',
        '& .MuiOutlinedInput-root': {
          height: '40px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.custom.paperBorder} !important`,
          boxShadow:
            theme.palette.mode === 'light'
              ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
              : 'none',
          borderRadius: '6px',
        },
      },
    }
  })

const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const {
    state: {
      balance,
      estimatedRev,
      quickPay,
      setPaymentLoading,
      setPaymentResult,
      minPayment,
      setMinPaymentEnabled,
      userInfoLoading,
      is_custom,
    },
    editFinance,
  } = useContext(FinanceContext)
  const { state: globalState } = useContext(GlobalContext)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [paymentNum, setPaymentNum] = useState<any>(300)
  const [customPaymentNum, setCustomPaymentNum] = useState<any>('')
  const [error, setError] = useState('')

  const paymentList = [
    { value: 'custom', label: 'Add my own' },
    { value: 1000, label: '$1000' },
    { value: 500, label: '$500' },
    { value: 300, label: '$300' },
    { value: 200, label: '$200' },
    { value: 100, label: '$100' },
  ]
  const valueChange = (e: any) => {
    if (error) {
      setError('')
    }
    setCustomPaymentNum(e.target.value.trim())
  }
  const submit = () => {
    if (!setPaymentLoading) {
      if (paymentNum === 'custom') {
        const reg = /^[1-9]\d*$/
        let valid = reg.test(customPaymentNum)
        if (
          customPaymentNum < 50 ||
          customPaymentNum % 50 !== 0 ||
          customPaymentNum > 1000000
        ) {
          valid = false
        }
        if (valid) {
          setError('')
          editFinance(Number(customPaymentNum), true)
        } else {
          setError(
            'Please enter an amount greater than $100 in multiples of 50'
          )
          if (customPaymentNum > 1000000) {
            setError(
              'Threshold amount should be lower than or equal to 1 million'
            )
          }
        }
      } else {
        editFinance(paymentNum, false)
      }
    }
  }
  const openSetPayment = () => {
    if (is_custom) {
      setPaymentNum('custom')
      setCustomPaymentNum(minPayment || 300)
    } else {
      setPaymentNum(minPayment || 300)
      setCustomPaymentNum('')
    }
    setDialogOpen(true)
  }
  useEffect(() => {
    if (setPaymentResult) {
      setDialogOpen(false)
    }
  }, [setPaymentResult])
  return (
    <Box className={classes.root} id="finance-overview">
      <Box
        className={classes.title}
        sx={{
          color:
            theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#FFFFFF',
        }}
      >
        Balance Overview
        <PopoverTip>
          <Box lineHeight="22px">
            To ensure smooth processing of monthly payments on 10th of every
            month do not make any changes to payment threshold between 1st -
            10th of the month.
          </Box>
        </PopoverTip>
      </Box>

      <Box
        className={classes.searchBox}
        style={{
          cursor:
            setMinPaymentEnabled &&
            globalState.accountAuthority.finance_page === 2
              ? 'pointer'
              : 'default',
        }}
        onClick={() => {
          setMinPaymentEnabled &&
            globalState.accountAuthority.finance_page === 2 &&
            openSetPayment()
        }}
      >
        {userInfoLoading ? (
          <Skeleton variant="rectangular" width="150px" height="40px" />
        ) : (
          <>
            <Box className={classes.searchLabel}>
              <Box className={classes.label}>Min. Threshold</Box>
              <Box
                sx={{
                  width: '100%',
                  color:
                    theme.palette.mode === 'light'
                      ? '#102542'
                      : 'rgba(255, 255, 255, 0.6)',
                }}
              >
                ${minPayment || 300}
              </Box>
              {setMinPaymentEnabled && <EditIcon className={classes.edit} />}
            </Box>
          </>
        )}
      </Box>
      <Box paddingTop={quickPay == false ? '12px' : '18px'}>
        <Box>
          <Box>
            <Box fontSize="14px" fontWeight={700}>
              Available balance
            </Box>
            {userInfoLoading ? (
              <Skeleton
                variant="rectangular"
                width="100px"
                height="30px"
                sx={{ marginTop: '8px' }}
              />
            ) : (
              <Container className={classes.cardMaid}>
                ${toThousands(balance)}
              </Container>
            )}
            {!userInfoLoading && balance < (minPayment || 300) && (
              <Box marginTop="12px">
                ${numFixed((minPayment || 300) - balance)} needed until you can
                withdraw your revenue.
              </Box>
            )}
          </Box>
          {quickPay == false && (
            <Box marginTop="12px">
              <Box fontSize="14px" fontWeight={700}>
                Estimated Revenue
              </Box>
              <Container className={classes.cardMaid}>
                ${toThousands(estimatedRev)}
              </Container>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        onClose={() => {
          setDialogOpen(false)
        }}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        maxWidth={'md'}
      >
        <Box className={classes.dialogContent}>
          <Box className={classes.text}>Minimum monthly payment threshold</Box>
          <Box marginBottom="10px" className={classes.desc}>
            ⚠️ To receive regular payments with minimum transaction fees we
            recommend setting minimum payment threshold to $100 and using Paypal
            or Direct deposit as your payment method.
          </Box>
          <Box className={classes.desc}>
            Please note wire transfers charge a additional $26 as transaction
            fee.
          </Box>
          <Box marginTop="24px" display="flex" justifyContent="center">
            <BorderStyleSelectRow
              width="200px"
              placeholder="minimum monthly payment"
              value={{ value: paymentNum }}
              changeClose={true}
              onChange={(row: any) => {
                setPaymentNum(row.value)
              }}
              options={paymentList}
            />
          </Box>
          {paymentNum === 'custom' && (
            <Box marginTop="24px" display="flex" justifyContent="center">
              <TextField
                className={classes.search}
                onChange={valueChange}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={customPaymentNum}
                error={!!error}
                helperText={error}
              />
            </Box>
          )}
          <Box className={classes.baseButton} onClick={submit}>
            {!setPaymentLoading ? (
              'Submit'
            ) : (
              <LoadingBtn loadingStyle={{ justifyContent: 'center' }} />
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default View
