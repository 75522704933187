import { useState, ChangeEvent } from 'react'
import {
  Box,
  Dialog,
  Button,
  TextField,
  useTheme,
  Theme,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { LoadingBtn, ScoreCard } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '80vw',
        maxWidth: '100%',
        padding: '40px',
        boxSizing: 'border-box',
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
        paddingRight: '40px',
        lineHeight: '22px',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '-3px',
        cursor: 'pointer',
      },
      content: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
        marginTop: '24px',
        lineHeight: '18px',
      },
      closeButton: {
        flex: 1,
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        borderRadius: '6px',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.custom.dialogButton}`,
        color: theme.palette.custom.dialogButton,
      },
      formInputSearch: {
        width: '100%',
        '& .MuiInputBase-input': {
          color: theme.palette.text.secondary,
        },
      },
      dialogPosition: {
        transform: 'translateY(calc(50vh - 50% - 20px))',
      },
    }
  })
/**
 * @param openModel 显示隐藏
 * @param submitLoading 上传中
 * @function onSubmit 确认回调
 * @function onClose 关闭区域点击回调
 */
interface RatingDialogProps {
  openModel: boolean
  submitLoading: boolean
  onSubmit: (data: any) => void
  onClose?: () => void
}
const Layout = (props: RatingDialogProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [score, setScore] = useState<number | null>(0)
  const [value, setValue] = useState<string>('')
  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }
  const submit = () => {
    props.onSubmit({ score, detail: value })
  }
  // returns
  return (
    <Dialog
      open={props.openModel}
      onClose={props.onClose}
      classes={{ paper: classes.dialogPosition }}
    >
      <Box className={classes.root}>
        <Box className={classes.title}>
          On a scale of 1-10, how likely are you to recommend our company to a
          friend or colleague?
          <CloseIcon
            className={classes.closeFilterIcon}
            onClick={props.onClose}
          />
        </Box>
        <Box className={classes.content}>
          <ScoreCard
            leftText="Definitely Not"
            rightText="Definitely"
            onChange={(result: number) => {
              setScore(result)
            }}
          />
        </Box>
        <Box className={classes.title} padding="0">
          Thanks for letting us know! What is the main reason you chose this
          score?
        </Box>
        <Box marginTop="16px">
          <TextField
            className={classes.formInputSearch}
            placeholder="Optional"
            variant="outlined"
            value={value}
            onChange={inputChange}
          />
        </Box>
        <Box display="flex" marginTop="24px">
          <Button
            color="primary"
            variant="contained"
            style={{ flex: 1, marginRight: '24px' }}
            onClick={submit}
            disabled={props.submitLoading || Number(score) <= 0}
          >
            {props.submitLoading ? <LoadingBtn /> : 'Submit'}
          </Button>
          <Box className={classes.closeButton} onClick={props.onClose}>
            Close
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default Layout
