const SVG = (props: any) => {
  const width = props.width || '25'
  const height = props.height || '20'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1020_4207)">
        <path
          d="M11.0586 9L13.0586 11L17.0586 7"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M7.65859 2.12012C7.65859 1.34692 8.2854 0.720117 9.05859 0.720117H14.4926C14.8613 0.720117 15.2152 0.865608 15.4773 1.12499L17.4076 3.03511L19.3512 5.09565C19.5963 5.35546 19.7328 5.69912 19.7328 6.05628V15.2969C19.7328 16.0701 19.106 16.6969 18.3328 16.6969H9.05859C8.2854 16.6969 7.65859 16.0701 7.65859 15.2969V2.12012Z"
          stroke="white"
          strokeWidth="1.2"
          strokeLinejoin="round"
        />
        <path
          d="M18.2751 18.9561H7.00098C5.89641 18.9561 5.00098 18.0606 5.00098 16.9561V2.7793"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1020_4207">
          <rect
            width="19.9111"
            height="19.9111"
            fill="white"
            transform="translate(2.71143 0.0449219)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SVG
