import { Box, TextField, Button, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons'
import { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { LoadingBtn } from 'src/components'
import { ContactNameForm } from 'src/context/type'
import { GlobalContext } from 'src/context/global'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      textField: {
        width: '268px !important',
        marginBottom: `${theme.spacing(1)} !important`,
        marginRight: `${theme.spacing(2)} !important`,
        backgroundColor: `${theme.palette.background.paper} !important`,
        '& .MuiInputBase-input': {
          color: `${theme.palette.text.secondary} !important`,
        },
      },
      backButton: {
        width: '47% !important',
        height: '36px !important',
        padding: '0',
      },
      saveButton: {
        width: '47% !important',
        height: '36px !important',
        padding: '0',
        '&.MuiButton-contained.Mui-disabled': {
          backgroundColor: '#29444f !important',
        },
      },
      icon: {
        width: '22px',
        height: '18px',
      },
      inputLabel: {
        color: theme.palette.custom.inputLabel,
        fontSize: 12,
        marginBottom: 8,
      },
    }
  })

const View = (): JSX.Element => {
  const [t] = useTranslation()
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const className = useStyles()
  const { state, personalInfoChange, setAccountEditKeyHandle } =
    useContext(GlobalContext)

  const newContactName = ''
  const [userData, setUserData] = useState({
    contactName: state.userInfo?.name || '-', // TODO: fix & add a loading maybe
  })

  // form check
  const FormSchema = yup.object().shape({
    contactName: yup
      .string()
      .required(t('setting.personalInfo.contactNameErrMsg')),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactNameForm>({
    resolver: yupResolver(FormSchema),
  })

  const saveChange = (data: ContactNameForm) => {
    personalInfoChange({
      name: data.contactName,
    })
  }

  useEffect(() => {
    if (state.userInfo) {
      const { name } = state.userInfo
      setUserData(() => ({
        contactName: name,
      }))
    }
  }, [state.userInfo])

  const contactNameInputView = (
    <Controller
      name="contactName"
      control={control}
      defaultValue={newContactName}
      render={({ field }) => (
        <TextField
          fullWidth
          placeholder={t('setting.personalInfo.contactName')}
          InputProps={{
            startAdornment: <AccountCircleIcon className={className.icon} />,
          }}
          type="text"
          variant="outlined"
          {...field}
          error={!!errors.contactName}
          helperText={errors.contactName?.message}
        />
      )}
    />
  )

  return (
    <>
      <Box>
        <Box className={className.inputLabel}>Display Name</Box>
        <TextField
          fullWidth
          disabled
          defaultValue={userData.contactName}
          placeholder={t('setting.personalInfo.contactName')}
          InputProps={{
            startAdornment: <AccountCircleIcon className={className.icon} />,
          }}
          type="text"
          variant="outlined"
        />
      </Box>

      <Box marginTop="16px">
        <Box className={className.inputLabel}>New Name</Box>
        {contactNameInputView}
      </Box>

      <Box display="flex" marginTop="24px" justifyContent="space-between">
        <Button
          className={className.backButton}
          onClick={() => setAccountEditKeyHandle('')}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          disabled={state.buttonLoading}
          className={className.saveButton}
          color="primary"
          variant="contained"
          onClick={handleSubmit(saveChange)}
        >
          {state.buttonLoading ? (
            <LoadingBtn
              loadingStyle={{
                position: 'absolute',
                width: '100%',
                justifyContent: 'center',
              }}
            />
          ) : (
            'Update'
          )}
        </Button>
      </Box>
    </>
  )
}

export default View
