const SVG = (props: any) => {
  const width = props.width || '64'
  const height = props.height || '64'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.6016 0H21.307C17.549 0.00426136 14.5037 3.04954 14.5 6.80753V57.1928C14.5037 60.9508 17.549 63.9961 21.307 63.9998H42.6016C46.3596 63.9961 49.4048 60.9508 49.4091 57.1928V6.80753C49.4048 3.04954 46.3596 0.00426136 42.6016 0V0ZM46.4996 57.1928C46.4975 59.3448 44.7535 61.0888 42.6016 61.0909H21.307C19.155 61.0888 17.411 59.3448 17.4089 57.1928V6.80753C17.411 4.65501 19.155 2.91104 21.307 2.90891H42.6016C44.7535 2.91104 46.4975 4.65501 46.4996 6.80753V57.1928Z"
        fill={color}
      />
      <path
        d="M36.3187 5.81836H27.5914C26.7882 5.81836 26.1367 6.46928 26.1367 7.27255C26.1367 8.07582 26.7882 8.72727 27.5914 8.72727H36.3187C37.122 8.72727 37.7729 8.07582 37.7729 7.27255C37.7729 6.46928 37.122 5.81836 36.3187 5.81836Z"
        fill={color}
      />
      <path
        d="M34.8633 55.2722C34.8633 56.8793 33.5609 58.1816 31.9544 58.1816C30.3473 58.1816 29.0449 56.8793 29.0449 55.2722C29.0449 53.6657 30.3473 52.3633 31.9544 52.3633C33.5609 52.3633 34.8633 53.6657 34.8633 55.2722Z"
        fill={color}
      />
      <rect
        x="20.4287"
        y="12.4287"
        width="23.1429"
        height="35.7143"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M26.4 25C25.6349 25 25 25.6802 25 26.5V35.5C25 36.3198 25.6349 37 26.4 37H37.6C38.3651 37 39 36.3198 39 35.5V26.5C39 25.6802 38.3651 25 37.6 25H26.4ZM26.4 26.5H37.6V35.5H26.4V26.5ZM33.75 30.25L31.3 33.25L29.55 31.375L27.6441 34H36.375L33.75 30.25Z"
        fill={color}
      />
      <rect x="26.5" y="42" width="11" height="3" rx="1.5" fill={color} />
      <rect x="27" y="15" width="17" height="6" rx="0.5" fill={color} />
    </svg>
  )
}

export default SVG
