import { useContext } from 'react'
import { Box, Button, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GameAddContext } from 'src/context/gameAdd'
import { GlobalContext } from 'src/context/global'
import { SVGIcon } from 'src/images'
import clsx from 'clsx'
import { LoadingBtn, TopTipPaper } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      urlInput: {
        width: 'calc(100% - 180px)',
        marginLeft: '30px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '16px',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: '0 none',
        outline: 'none',
        padding: '0',
      },
      updateButton: {
        position: 'absolute',
        height: '32px',
        lineHeight: '32px',
        width: '128px',
        textAlign: 'center',
        right: '4px',
        top: '4px',
        fontSize: '14px',
        background:
          theme.palette.mode === 'light'
            ? 'rgba(116, 135, 170, 0.22)'
            : 'rgba(255, 255, 255, 0.2)',
        color:
          theme.palette.mode === 'light'
            ? '#7487AA'
            : 'rgba(255, 255, 255, 0.8)',
        cursor: 'pointer',
        borderRadius: '4px',
        userSelect: 'none',
      },
      updateClickButton: {
        background: theme.palette.primary.main,
        color: '#FFF',
      },
      photo: {
        width: '35px',
        height: '35px',
        borderRadius: '4px',
      },
    }
  })
const AdsStepView = (props: any) => {
  const {
    updateLink,
    url,
    urlInputChange,
    update,
    next,
    icon,
    bundleId,
    gameName,
    platform,
  } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state } = useContext(GameAddContext)
  const { state: globalState } = useContext(GlobalContext)
  return (
    <>
      {updateLink && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <TopTipPaper
            width={globalState.isMobile ? '100%' : '60%'}
            maxWidth="900px"
            textAlign="center"
            border={`1px solid ${
              theme.palette.mode === 'light' ? '#1E96FC' : '#4981b0'
            }`}
            background={`linear-gradient(0deg, rgba(30, 150, 252, 0.05), rgba(30, 150, 252, 0.05)), ${
              theme.palette.mode === 'light' ? '#FFFFFF' : '#1A202C'
            }`}
          >
            <Box
              sx={{
                fontSize: '18px',
                color: '#1E96FC',
              }}
            >
              We&apos;ll verify your app using your app-ads.txt file. It may
              take up to 2 hours to verify the game after{' '}
              <Box
                sx={{
                  display: 'inline',
                  color: theme.palette.primary.main,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.open(
                    'https://developers.yodo1.com/docs/additional-set-up/app-ads/setting-up-your-app-ads-txt/'
                  )
                }}
              >
                updating your app-ads.txt
              </Box>{' '}
              file.
            </Box>
          </TopTipPaper>
        </Box>
      )}
      {
        <Box textAlign="center">
          <Box
            position="relative"
            border="1px solid #eee"
            borderRadius="6px"
            height="40px"
            width={globalState.isMobile ? '100%' : '60%'}
            marginTop="48px"
            display="inline-block"
            textAlign="left"
          >
            <input
              value={url}
              onInput={urlInputChange}
              type="text"
              className={classes.urlInput}
              disabled={updateLink}
            />
            <Box
              className={clsx({
                [classes.updateButton]: true,
                [classes.updateClickButton]: url && !updateLink,
              })}
              onClick={update}
              sx={{ width: globalState.isMobile ? '80px' : '128px' }}
            >
              {state.updateLinkLoading ? (
                <Box>
                  <LoadingBtn
                    loadingStyle={{
                      position: 'absolute',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  />
                </Box>
              ) : (
                'Add game'
              )}
            </Box>
          </Box>
          {!updateLink && (
            <Box marginTop="48px">
              <Button
                color="primary"
                variant="contained"
                style={{
                  width: '220px',
                  height: '40px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
                onClick={next}
              >
                Skip (Game is not live yet)
              </Button>
            </Box>
          )}
          {updateLink && (
            <>
              {globalState.isMobile ? (
                <Box>
                  <Box marginTop="20px">
                    {icon ? (
                      <img src={icon} alt="" className={classes.photo} />
                    ) : (
                      <SVGIcon.GameDefault
                        width="35px"
                        height="35px"
                        color={
                          theme.palette.mode === 'light' ? '#EFF2F3' : '#2B313C'
                        }
                      />
                    )}
                  </Box>
                  <Box
                    display="flex"
                    fontSize="14px"
                    color="#5B5B5B"
                    marginTop="20px"
                  >
                    <Box flex={0.95} textAlign="right">
                      Bundle ID:
                    </Box>
                    <Box
                      flex={1.05}
                      fontWeight={500}
                      textAlign="left"
                      marginLeft="14px"
                    >
                      {bundleId}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    fontSize="14px"
                    color="#5B5B5B"
                    marginTop="20px"
                  >
                    <Box flex={0.95} textAlign="right">
                      App Name:
                    </Box>
                    <Box
                      flex={1.05}
                      fontWeight={500}
                      textAlign="left"
                      marginLeft="14px"
                    >
                      {gameName}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    fontSize="14px"
                    color="#5B5B5B"
                    marginTop="20px"
                  >
                    <Box flex={0.95} textAlign="right">
                      Platform:
                    </Box>
                    <Box
                      flex={1.05}
                      fontWeight={500}
                      textAlign="left"
                      marginLeft="14px"
                    >
                      {platform}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box display="inline-block" marginTop="28px" width="60%">
                  <Box
                    display="flex"
                    textAlign="left"
                    fontSize="12px"
                    lineHeight="15px"
                  >
                    <Box display="flex" flex={2}>
                      <Box width="35px" marginRight="14px">
                        {icon ? (
                          <img src={icon} alt="" className={classes.photo} />
                        ) : (
                          <SVGIcon.GameDefault
                            width="35"
                            height="35"
                            color={
                              theme.palette.mode === 'light'
                                ? '#EFF2F3'
                                : '#2B313C'
                            }
                          />
                        )}
                      </Box>
                      <Box flex={1}>
                        <Box fontWeight={400} marginBottom="5px">
                          App Name
                        </Box>
                        <Box fontWeight={600}>{gameName}</Box>
                      </Box>
                    </Box>
                    <Box flex={2} margin="0 8px">
                      <Box fontWeight={400} marginBottom="5px">
                        Bundle ID
                      </Box>
                      <Box fontWeight={600}>{bundleId}</Box>
                    </Box>
                    <Box flex={1}>
                      <Box fontWeight={400} marginBottom="5px">
                        Platform
                      </Box>
                      <Box fontWeight={600}>{platform}</Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      }
    </>
  )
}
export default AdsStepView
