import { Box, Tooltip, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import {
  InfoOutlined as InfoOutlinedIcon,
  Create as CreateIcon,
} from '@material-ui/icons'
import { AppPerformanceContext } from 'src/context/appPerformance'
import { GlobalContext } from 'src/context/global'
import { GlobalStoreContext } from 'src/context/globalStore'
import { PNG, SVGIcon } from 'src/images'
import { TopTitle, StylePaper, AdaptiveTooltip } from 'src/components'
import ActiveAdsView from '../gameList/activeAds'
import { getScoringColor, getScoringNum } from 'src/utils'
import ContentDetailView from './contentDetail'
import copy from 'copy-to-clipboard'
import dayjs from 'dayjs'
const Yesterday = dayjs().subtract(1, 'day')

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        width: '100%',
        color: theme.palette.text.primary,
      },
      tableHead: {
        display: 'flex',
        paddingBottom: '12px',
        fontSize: '12px',
        color: theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
      },
      tableTr: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '10px',
        fontSize: '14px',
      },
      gamePhoto: {
        width: '48px',
        height: '48px',
        verticalAlign: 'middle',
        borderRadius: '4px',
      },
      platformPhoto: {
        width: '14px',
        height: '14px',
        verticalAlign: 'text-top',
      },
      appWidth: {
        flex: 2,
        minWidth: '170px',
      },
      performance: {
        flex: 1,
        minWidth: '100px',
        textAlign: 'center',
      },
      infoIcon: {
        fontSize: '14px !important',
        verticalAlign: 'middle',
        marginLeft: '4px',
        color: theme.palette.text.secondary,
        marginTop: '-3px',
      },
      activeAds: {
        flex: 1.5,
        minWidth: '160px',
        textAlign: 'center',
        marginRight: '10px',
      },
      manageBox: {
        width: '80px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '12px',
      },
      graphBox: {
        width: '38px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
        marginRight: '6px',
      },
      manageIcon: {
        fontSize: '16px !important',
        verticalAlign: 'middle',
        color: theme.palette.text.primary,
        marginRight: '6px',
      },
    }
  })

const TableList = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state } = useContext(AppPerformanceContext)
  const { state: globalState } = useContext(GlobalContext)
  const { dispatch: globalStoreDispatch } = useContext(GlobalStoreContext)
  const [title, setTitle] = useState('')
  const navigate = useNavigate()

  const toUrlPage = (url: string) => {
    url && window.open(url)
  }
  const copyBundleId = () => {
    setTitle('Copied')
  }
  const handleTooltipClose = () => {
    // 延时器防止鼠标移除时内容改变
    const openTimer = setTimeout(() => {
      setTitle('')
      clearTimeout(openTimer)
    }, 200)
  }
  const back = () => {
    globalStoreDispatch({
      type: 'gamePageFromDetail',
      payload: true,
    })
    navigate(-1)
  }
  const toPerformance = (row: any) => {
    const time = [
      Yesterday.subtract(1, 'week').add(1, 'day').format('YYYY-MM-DD'),
      Yesterday.format('YYYY-MM-DD'),
    ]
    globalStoreDispatch({ type: 'fromWelcome', payload: true })
    globalStoreDispatch({ type: 'time', payload: time })
    globalStoreDispatch({ type: 'gameSelect', payload: [row] })
    globalStoreDispatch({ type: 'performanceData', payload: { filter: '' } })
    navigate('/dash/performance/revenue')
  }
  const openDialog = (row: any) => {
    const url = `/dash/games/manage/${row.app_key}/overview`
    navigate(url)
  }

  return (
    <Box className={classes.container}>
      <TopTitle
        title={`${state.appDetail.name || ''} Analysis`}
        showBack={true}
        goBack={back}
      />
      <StylePaper
        width="100%"
        padding="24px"
        boxSizing="border-box"
        marginTop="24px"
        id="games-content"
      >
        {!globalState.isMobile ? (
          <Box>
            <Box className={classes.tableHead}>
              <Box className={classes.appWidth}>APP</Box>
              <Box className={classes.activeAds}>
                ACTIVE ADS
                <Tooltip
                  title="List of ad types enabled or disabled for your apps."
                  arrow
                  placement="top"
                  disableInteractive
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </Tooltip>
              </Box>
              <Box className={classes.performance}>
                PERF. SCORE
                <Tooltip
                  title="Recommendations to improve the performance of your apps and games. The recommendations are updated every Monday."
                  arrow
                  placement="top"
                  disableInteractive
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </Tooltip>
              </Box>
              <Box width="168px" />
            </Box>
            {!state.loading && !state.scoringLoading ? (
              <Box className={classes.tableTr}>
                <Box className={classes.appWidth}>
                  <Box
                    display="flex"
                    alignItems="center"
                    position="relative"
                    width="100%"
                  >
                    <Box
                      position="relative"
                      width="48px"
                      height="48px"
                      marginRight="16px"
                      onClick={() => {
                        toUrlPage(state.appDetail.url)
                      }}
                      style={{
                        cursor: state.appDetail.url ? 'pointer' : 'not-allowed',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          right: '-8px',
                          top: '-8px',
                          width: '14px',
                          height: '14px',
                          borderRadius: '50%',
                          border: `2px solid ${
                            theme.palette.mode === 'light' ? '#FFF' : '#1A202C'
                          }`,
                          overflow: 'hidden',
                          background:
                            state.appDetail.platform === 'iOS'
                              ? '#FFF'
                              : theme.palette.background.paper,
                        }}
                      >
                        <img
                          src={
                            state.appDetail.platform === 'iOS'
                              ? PNG.ios
                              : PNG.android
                          }
                          className={classes.platformPhoto}
                          alt=""
                        />
                      </Box>
                      {state.appDetail.icon ? (
                        <img
                          src={state.appDetail.icon}
                          className={classes.gamePhoto}
                          alt=""
                        />
                      ) : (
                        <SVGIcon.GameDefault
                          style={{
                            marginRight: '16px',
                            verticalAlign: 'middle',
                          }}
                          color={
                            theme.palette.mode === 'light'
                              ? '#EFF2F3'
                              : '#2B313C'
                          }
                        />
                      )}
                    </Box>
                    <Box width="calc(100% - 64px)">
                      <Box
                        fontSize="14px"
                        fontWeight="500"
                        onClick={() => {
                          if (
                            state.appDetail.app_termination_status ===
                            'TERMINATION'
                          ) {
                            return
                          }
                          openDialog(state.appDetail)
                        }}
                        sx={{ wordBreak: 'break-word' }}
                        style={
                          state.appDetail.app_termination_status ===
                          'TERMINATION'
                            ? { cursor: 'not-allowed' }
                            : { cursor: 'pointer' }
                        }
                      >
                        {state.appDetail.name}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          width: '100%',
                          color: '#A8A8A8',
                          marginTop: '4px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Tooltip
                          title={title || 'Click to copy'}
                          arrow
                          placement="top"
                          onClose={handleTooltipClose}
                          disableInteractive
                        >
                          <Box
                            style={{
                              cursor: 'pointer',
                              fontVariantNumeric: 'tabular-nums',
                            }}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            color={theme.palette.text.secondary}
                            minWidth="98px"
                            fontSize="12px"
                            onClick={() => {
                              copy(state.appDetail.app_key)
                              copyBundleId()
                            }}
                          >
                            App Key: {state.appDetail.app_key}
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.activeAds}>
                  <ActiveAdsView
                    isMobile={globalState.isMobile}
                    ads_format={state.appDetail.ads_format}
                    app_key={state.appDetail.app_key}
                  />
                </Box>
                <Box className={classes.performance}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      width: '55px',
                      height: '25px',
                      textAlign: 'center',
                      lineHeight: '25px',
                      borderRadius: '12px',
                      background:
                        getScoringColor(
                          state.appScoringDetail.performance_score
                        ) || theme.palette.custom.editIconButton,
                      color: getScoringColor(
                        state.appScoringDetail.performance_score
                      )
                        ? '#FFF'
                        : theme.palette.text.primary,
                    }}
                  >
                    {getScoringNum(state.appScoringDetail.performance_score)}
                  </Box>
                </Box>
                <Box width="168px" position="relative" display="flex">
                  <Tooltip
                    title="Compare metrics"
                    arrow
                    placement="top"
                    disableInteractive
                  >
                    <Box
                      boxSizing="border-box"
                      paddingTop="5px"
                      className={classes.graphBox}
                      onClick={() => {
                        navigate(
                          `/dash/games/compare?app_keys=${state.appDetail.app_key}`
                        )
                      }}
                    >
                      <SVGIcon.CompareSVG
                        color={theme.palette.text.primary}
                        style={{ verticalAlign: 'text-bottom' }}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title="Performance reports"
                    arrow
                    placement="top"
                    disableInteractive
                  >
                    <Box
                      className={classes.graphBox}
                      onClick={() => {
                        toPerformance(state.appDetail)
                      }}
                    >
                      <SVGIcon.GraphSVG
                        color={theme.palette.text.primary}
                        style={{ verticalAlign: 'text-bottom' }}
                      />
                    </Box>
                  </Tooltip>
                  {globalState.accountAuthority.game_manage_page !== 0 && (
                    <Box
                      className={classes.manageBox}
                      id="games-details"
                      onClick={() => {
                        if (
                          state.appDetail.app_termination_status ===
                          'TERMINATION'
                        ) {
                          return
                        }
                        openDialog(state.appDetail)
                      }}
                      style={
                        state.appDetail.app_termination_status === 'TERMINATION'
                          ? { cursor: 'not-allowed' }
                          : {}
                      }
                    >
                      <CreateIcon className={classes.manageIcon} />
                      Manage
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box className={classes.tableTr}>
                <Box className={classes.appWidth}>
                  <Box display="flex" alignItems="center">
                    <div>
                      <Skeleton
                        variant="rectangular"
                        width={48}
                        height={48}
                        style={{
                          marginRight: '10px',
                          borderRadius: '4px',
                        }}
                      />
                    </div>
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          maxWidth: '70px',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Skeleton variant="text" width={70} />
                      </div>
                      <div
                        style={{
                          left: '0',
                          width: '100%',
                          color: '#A8A8A8',
                          marginTop: '4px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Skeleton variant="text" />
                      </div>
                    </div>
                  </Box>
                </Box>
                <Box className={classes.activeAds}>
                  <Skeleton
                    variant="rectangular"
                    width={150}
                    height={30}
                    style={{ display: 'inline-block' }}
                  />
                </Box>
                <Box className={classes.performance}>
                  <Skeleton
                    variant="rectangular"
                    width={55}
                    height={25}
                    style={{ display: 'inline-block' }}
                  />
                </Box>
                <Box width="168px" display="flex">
                  <Skeleton
                    variant="rectangular"
                    width={38}
                    height={30}
                    sx={{ marginRight: '6px' }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={38}
                    height={30}
                    sx={{ marginRight: '6px' }}
                  />
                  <Skeleton variant="rectangular" width={80} height={30} />
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <>
            {!state.loading && !state.scoringLoading ? (
              <Box>
                <Box className={classes.appWidth}>
                  <Box
                    display="flex"
                    alignItems="center"
                    position="relative"
                    width="100%"
                  >
                    <Box
                      position="relative"
                      width="48px"
                      height="48px"
                      marginRight="16px"
                      onClick={() => {
                        toUrlPage(state.appDetail.url)
                      }}
                      style={{
                        cursor: state.appDetail.url ? 'pointer' : 'not-allowed',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          right: '-8px',
                          top: '-8px',
                          width: '14px',
                          height: '14px',
                          borderRadius: '50%',
                          border: `2px solid ${
                            theme.palette.mode === 'light' ? '#FFF' : '#1A202C'
                          }`,
                          overflow: 'hidden',
                          background:
                            state.appDetail.platform === 'iOS'
                              ? '#FFF'
                              : theme.palette.background.paper,
                        }}
                      >
                        <img
                          src={
                            state.appDetail.platform === 'iOS'
                              ? PNG.ios
                              : PNG.android
                          }
                          className={classes.platformPhoto}
                          alt=""
                        />
                      </Box>
                      {state.appDetail.icon ? (
                        <img
                          src={state.appDetail.icon}
                          className={classes.gamePhoto}
                          alt=""
                        />
                      ) : (
                        <SVGIcon.GameDefault
                          style={{
                            marginRight: '16px',
                            verticalAlign: 'middle',
                          }}
                          color={
                            theme.palette.mode === 'light'
                              ? '#EFF2F3'
                              : '#2B313C'
                          }
                        />
                      )}
                    </Box>
                    <Box width="calc(100% - 238px)">
                      <Box
                        fontSize="14px"
                        fontWeight="500"
                        onClick={() => {
                          if (
                            state.appDetail.app_termination_status ===
                            'TERMINATION'
                          ) {
                            return
                          }
                          openDialog(state.appDetail)
                        }}
                        sx={{ wordBreak: 'break-word' }}
                        style={
                          state.appDetail.app_termination_status ===
                          'TERMINATION'
                            ? { cursor: 'not-allowed' }
                            : { cursor: 'pointer' }
                        }
                      >
                        {state.appDetail.name}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          width: '100%',
                          color: '#A8A8A8',
                          marginTop: '4px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          fontSize="12px"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          color={theme.palette.text.secondary}
                        >
                          App Key: {state.appDetail.app_key}
                        </Box>
                      </Box>
                    </Box>
                    <Box width="168px" marginLeft="6px" display="flex">
                      <AdaptiveTooltip
                        title="Compare metrics"
                        arrow
                        placement="top"
                        disableInteractive
                        isMobile={true}
                      >
                        <Box
                          boxSizing="border-box"
                          paddingTop="5px"
                          className={classes.graphBox}
                          onClick={() => {
                            navigate(
                              `/dash/games/compare?app_keys=${state.appDetail.app_key}`
                            )
                          }}
                        >
                          <SVGIcon.CompareSVG
                            color={theme.palette.text.primary}
                            style={{ verticalAlign: 'text-bottom' }}
                          />
                        </Box>
                      </AdaptiveTooltip>
                      <AdaptiveTooltip
                        title="Performance reports"
                        arrow
                        placement="top"
                        disableInteractive
                        isMobile={true}
                      >
                        <Box
                          className={classes.graphBox}
                          onClick={() => {
                            toPerformance(state.appDetail)
                          }}
                        >
                          <SVGIcon.GraphSVG
                            color={theme.palette.text.primary}
                            style={{ verticalAlign: 'text-bottom' }}
                          />
                        </Box>
                      </AdaptiveTooltip>
                      {globalState.accountAuthority.game_manage_page !== 0 && (
                        <Box
                          className={classes.manageBox}
                          id="games-details"
                          onClick={() => {
                            if (
                              state.appDetail.app_termination_status ===
                              'TERMINATION'
                            ) {
                              return
                            }
                            openDialog(state.appDetail)
                          }}
                          style={
                            state.appDetail.app_termination_status ===
                            'TERMINATION'
                              ? { cursor: 'not-allowed' }
                              : {}
                          }
                        >
                          <CreateIcon className={classes.manageIcon} />
                          Manage
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    fontSize: '12px',
                    color: theme.palette.custom.tableHead,
                    margin: '16px 0 8px',
                    lineHeight: '16px',
                  }}
                >
                  ACTIVE ADS
                  <AdaptiveTooltip
                    isMobile={true}
                    title="List of ad types enabled or disabled for your apps."
                    arrow
                    placement="top"
                    disableInteractive
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </AdaptiveTooltip>
                </Box>
                <Box>
                  <ActiveAdsView
                    ads_format={state.appDetail.ads_format}
                    isMobile={true}
                    app_key={state.appDetail.app_key}
                  />
                </Box>
                <Box
                  sx={{
                    fontSize: '12px',
                    color: theme.palette.custom.tableHead,
                    margin: '16px 0 8px',
                    lineHeight: '16px',
                  }}
                >
                  PERF. SCORE
                  <AdaptiveTooltip
                    isMobile={true}
                    title="Recommendations to improve the performance of your apps and games. The recommendations are updated every Monday."
                    arrow
                    placement="top"
                    disableInteractive
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </AdaptiveTooltip>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'inline-block',
                      width: '55px',
                      height: '25px',
                      textAlign: 'center',
                      lineHeight: '25px',
                      borderRadius: '12px',
                      background:
                        getScoringColor(
                          state.appScoringDetail.performance_score
                        ) || theme.palette.custom.editIconButton,
                      color: getScoringColor(
                        state.appScoringDetail.performance_score
                      )
                        ? '#FFF'
                        : theme.palette.text.primary,
                      fontSize: '14px',
                    }}
                  >
                    {getScoringNum(state.appScoringDetail.performance_score)}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Skeleton variant="rectangular" width="100%" height={250} />
            )}
          </>
        )}
      </StylePaper>
      <ContentDetailView />
    </Box>
  )
}

export default TableList
