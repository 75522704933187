import { Box, Dialog, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useState, useEffect, useContext } from 'react'
import { GlobalContext } from 'src/context/global'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        boxSizing: 'border-box',
      },
      content: {
        width: '100%',
        height: '100%',
        opacity: 0,
        position: 'absolute',
        left: 0,
        top: 0,
      },
      stepDot: {
        boxSizing: 'border-box',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        border: '1px solid #FFF',
      },
      button: {
        width: '127px',
        height: '33px',
        lineHeight: '33px',
        fontSize: '12px',
        textAlign: 'center',
        borderRadius: '4px',
        background: '#FFF',
        color: theme.palette.mode === 'light' ? '#1E96FC' : '#2B3445',
        fontWeight: 600,
        cursor: 'pointer',
        userSelect: 'none',
      },
    }
  })
/**
 * @param openModel 显示隐藏
 * @function onSubmit 确认回调
 * @function onClose 关闭区域点击回调
 */
interface RatingDialogProps {
  openModel: boolean
  onClose: () => void
}
const TitleInfoDialogView = (props: RatingDialogProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, editGameProgressBarStatus } = useContext(GlobalContext)
  const [step, setStep] = useState<number>(1)
  const close = () => {
    props.onClose()
  }
  useEffect(() => {
    if (props.openModel) {
      setStep(1)
    }
  }, [props.openModel])
  // returns
  return (
    <Dialog open={props.openModel} onClose={close}>
      <Box className={classes.root}>
        <Box
          sx={{
            minHeight: '214px',
            lineHeight: '214px',
            textAlign: 'center',
            position: 'relative',
            background: theme.palette.mode === 'light' ? '#FFFFFF' : '#1A202C',
          }}
        >
          <Box
            className={classes.content}
            sx={step === 1 ? { opacity: 1 } : {}}
          >
            {theme.palette.mode === 'light' ? (
              <SVGIcon.TitleInfoOneSVG width="100%" />
            ) : (
              <SVGIcon.TitleInfoOneDarkSVG width="100%" />
            )}
          </Box>
          <Box
            className={classes.content}
            sx={step === 2 ? { opacity: 1 } : {}}
          >
            {theme.palette.mode === 'light' ? (
              <SVGIcon.TitleInfoTwoSVG width="100%" />
            ) : (
              <SVGIcon.TitleInfoTwoDarkSVG width="100%" />
            )}
          </Box>
          <Box
            className={classes.content}
            sx={step === 3 ? { opacity: 1 } : {}}
          >
            {theme.palette.mode === 'light' ? (
              <SVGIcon.TitleInfoThreeSVG width="100%" />
            ) : (
              <SVGIcon.TitleInfoThreeDarkSVG width="100%" />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            minHeight: '210px',
            background: theme.palette.mode === 'light' ? '#1E96FC' : '#2B3445',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '16px 0',
            }}
          >
            <Box
              className={classes.stepDot}
              sx={{ background: step === 1 ? '#FFF' : '' }}
            />
            <Box
              className={classes.stepDot}
              sx={{
                margin: '0 12px',
                background: step === 2 ? '#FFF' : '',
              }}
            />
            <Box
              className={classes.stepDot}
              sx={{
                background: step === 3 ? '#FFF' : '',
              }}
            />
          </Box>
          <Box
            sx={{
              fontSize: '14px',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#FFF',
              padding: '0 20px',
              fontWeight: 500,
            }}
          >
            {step === 1 ? (
              'Release steps guide you through every step of a release. Each step is represented by different states to help you quickly identify what to do next.'
            ) : step === 2 ? (
              'Simply hover over a step to know more about it and the next actions for a release.'
            ) : (
              <>
                As you complete each step you get closer to a release! Feel free
                to{' '}
                <Box
                  sx={{
                    display: 'inline',
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.Intercom && window.Intercom('show')
                  }}
                >
                  contact support
                </Box>{' '}
                for help or to share feedback.
              </>
            )}
          </Box>
          <Box display="flex" justifyContent="center" padding="20px 0">
            <Box
              className={classes.button}
              onClick={() => {
                if (step < 3) {
                  setStep(step + 1)
                } else {
                  if (state.eventStatus?.feature_game_process_bar === false) {
                    editGameProgressBarStatus('feature_game_process_bar')
                  }
                  props.onClose()
                }
              }}
            >
              {step === 3 ? 'Finish' : 'Next'}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default TitleInfoDialogView
