import { useContext } from 'react'
import { RolesContextProvider } from 'src/context/Roles'
import { GlobalContext } from 'src/context/global'
import TableList from './tableList'
import MobileTableList from './mobileTableList'

const Roles = (): JSX.Element => {
  const { state: globalState } = useContext(GlobalContext)
  // returns
  return (
    <RolesContextProvider>
      {globalState.isMobile ? <MobileTableList /> : <TableList />}
    </RolesContextProvider>
  )
}

export default Roles
