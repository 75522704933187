const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '16'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 0H13.3333C14.8061 0 16 1.19391 16 2.66667V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66667C0 1.19391 1.19391 0 2.66667 0ZM7.76104 11.2066L11.8233 5.87331V5.84664C12.017 5.59259 12.0604 5.25448 11.9373 4.95969C11.8141 4.6649 11.5431 4.45821 11.2262 4.41747C10.9093 4.37673 10.5948 4.50814 10.401 4.7622L7.04104 9.20664L5.59215 7.35776C5.39685 7.10688 5.08254 6.97925 4.76761 7.02295C4.45269 7.06665 4.18501 7.27503 4.06539 7.56961C3.94578 7.86419 3.9924 8.20021 4.18771 8.45109L6.3566 11.2155C6.52622 11.4302 6.78524 11.5548 7.05882 11.5533C7.33393 11.5526 7.59323 11.4246 7.76104 11.2066Z"
        fill="#FF8E00"
      />
    </svg>
  )
}

export default SVG
