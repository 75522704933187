const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4999 2.75004V17.4167C16.4999 17.923 16.0895 18.3334 15.5833 18.3334H15.0424C14.8019 18.3324 14.5715 18.2369 14.4008 18.0675L11.6508 15.3634C10.6293 14.3406 9.24623 13.761 7.80075 13.75H4.58325C3.06447 13.75 1.83325 12.5188 1.83325 11V9.16671C1.83325 7.64792 3.06447 6.41671 4.58325 6.41671H7.80075C9.26213 6.41786 10.6639 5.83737 11.6966 4.80337L14.4466 2.09921C14.6173 1.92986 14.8478 1.83439 15.0883 1.83337H15.5833C16.0895 1.83337 16.4999 2.24378 16.4999 2.75004ZM19.4332 12.2834C19.9093 11.6487 20.1666 10.8767 20.1666 10.0834C20.1766 9.27436 19.9188 8.48474 19.4332 7.83754C19.3518 7.73524 19.2331 7.66933 19.1032 7.65421C18.9699 7.64688 18.8398 7.69692 18.7457 7.79171L18.0857 8.45171C17.9324 8.60664 17.9094 8.84812 18.0307 9.02921C18.4337 9.64105 18.4337 10.434 18.0307 11.0459C17.9094 11.227 17.9324 11.4684 18.0857 11.6234L18.7457 12.2834C18.8343 12.3794 18.9589 12.434 19.0895 12.434C19.2201 12.434 19.3447 12.3794 19.4332 12.2834ZM10.9999 19.7084V19.1767C11.0172 18.9394 10.8499 18.7283 10.6149 18.6909L10.3124 18.6359C10.0104 18.5873 9.75258 18.3913 9.62493 18.1134L9.23993 15.5925C8.78263 15.4083 8.2938 15.3149 7.80077 15.3175H5.5641C5.61275 15.6418 5.72489 15.9533 5.8941 16.2342L7.9841 19.7175C8.14916 19.9959 8.44879 20.1667 8.77243 20.1667H10.5416C10.7947 20.1667 10.9999 19.9615 10.9999 19.7084ZM12.9891 6.10504L14.6666 4.42754V15.7392L12.9891 14.0617C11.9492 13.017 10.6159 12.3136 9.16658 12.045V8.12171C10.6159 7.85312 11.9492 7.14972 12.9891 6.10504Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
