import { AppPerformanceContextProvider } from 'src/context/appPerformance'
import ContentView from './content'

const GameManage = (): JSX.Element => {
  // returns
  return (
    <AppPerformanceContextProvider>
      <ContentView />
    </AppPerformanceContextProvider>
  )
}

export default GameManage
