const SVG = (props: any) => {
  const width = props.width || '10'
  const height = props.height || '5'
  const color = props.color || '#1D3353'
  return (
    <svg
      onClick={props.onClick}
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 0L5 5L0 0L10 0Z" fill={color} />
    </svg>
  )
}

export default SVG
