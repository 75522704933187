import { Box, Button, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '24px',
        lineHeight: '40px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        flexWrap: 'wrap',
      },
      button: {
        height: '40px !important',
        padding: '0 17px !important',
      },
      addIcon: {
        fontSize: '14px !important',
        marginRight: '10px',
        verticalAlign: 'middle',
      },
      backIcon: {
        fontSize: '18px !important',
      },
      backButton: {
        marginLeft: '10px',
        marginRight: '20px',
        cursor: 'pointer',
      },
    }
  })

interface TopTitleProps {
  title?: any
  buttonText?: string
  buttonClick?: () => void
  goBack?: () => void
  showBack?: boolean
  hideAddIcon?: boolean
  addButtonId?: string
  titleId?: string
}

const TopTitle = (props: TopTitleProps) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box display="flex">
        {props.showBack && (
          <Box
            className={classes.backButton}
            onClick={() => {
              props.goBack && props.goBack()
            }}
          >
            <ArrowBackIosIcon className={classes.backIcon} />
          </Box>
        )}
        <Box data-testid="top-title" id={props.titleId || ''}>
          {props.title ? props.title : 'Title'}
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        {props.buttonText && (
          <Button
            data-testid="custom-button"
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={
              props.buttonClick
                ? () => {
                    props.buttonClick && props.buttonClick()
                  }
                : () => {}
            }
          >
            {!props.hideAddIcon && (
              <AddCircleIcon className={classes.addIcon} />
            )}
            {props.buttonText ? props.buttonText : 'Title'}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default TopTitle
