const SVG = (props: any) => {
  const width = props.width || '64'
  const height = props.height || '64'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.6016 0H21.307C17.549 0.00426136 14.5037 3.04954 14.5 6.80753V57.1928C14.5037 60.9508 17.549 63.9961 21.307 63.9998H42.6016C46.3596 63.9961 49.4048 60.9508 49.4091 57.1928V6.80753C49.4048 3.04954 46.3596 0.00426136 42.6016 0V0ZM46.4996 57.1928C46.4975 59.3448 44.7535 61.0888 42.6016 61.0909H21.307C19.155 61.0888 17.411 59.3448 17.4089 57.1928V6.80753C17.411 4.65501 19.155 2.91104 21.307 2.90891H42.6016C44.7535 2.91104 46.4975 4.65501 46.4996 6.80753V57.1928Z"
        fill={color}
      />
      <path
        d="M36.3187 5.81836H27.5914C26.7882 5.81836 26.1367 6.46928 26.1367 7.27255C26.1367 8.07582 26.7882 8.72727 27.5914 8.72727H36.3187C37.122 8.72727 37.7729 8.07582 37.7729 7.27255C37.7729 6.46928 37.122 5.81836 36.3187 5.81836Z"
        fill={color}
      />
      <path
        d="M34.8633 55.2726C34.8633 56.8796 33.5609 58.182 31.9544 58.182C30.3473 58.182 29.0449 56.8796 29.0449 55.2726C29.0449 53.666 30.3473 52.3636 31.9544 52.3636C33.5609 52.3636 34.8633 53.666 34.8633 55.2726Z"
        fill={color}
      />
      <rect
        x="20.4287"
        y="12.4286"
        width="23.1429"
        height="35.7143"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M26.6663 25.5238C25.9376 25.5238 25.333 26.1284 25.333 26.8571V34.8571C25.333 35.5858 25.9376 36.1905 26.6663 36.1905H37.333C38.0617 36.1905 38.6663 35.5858 38.6663 34.8571V26.8571C38.6663 26.1284 38.0617 25.5238 37.333 25.5238H26.6663ZM26.6663 26.8571H37.333V34.8571H26.6663V26.8571ZM33.6663 30.1905L31.333 32.8571L29.6663 31.1905L27.8512 33.5238H36.1663L33.6663 30.1905Z"
        fill={color}
      />
      <path
        d="M40.2354 19.1943C40.267 19.2256 40.2847 19.2682 40.2847 19.3126C40.2847 19.357 40.267 19.3996 40.2354 19.4309L40.0021 19.6643C39.9708 19.6958 39.9282 19.7136 39.8838 19.7136C39.8393 19.7136 39.7967 19.6958 39.7654 19.6643L38.2854 18.1843L36.8054 19.6643C36.7741 19.6958 36.7315 19.7136 36.6871 19.7136C36.6427 19.7136 36.6001 19.6958 36.5688 19.6643L36.3354 19.4309C36.3039 19.3996 36.2861 19.357 36.2861 19.3126C36.2861 19.2682 36.3039 19.2256 36.3354 19.1943L37.8154 17.7143L36.3354 16.2343C36.3039 16.203 36.2861 16.1604 36.2861 16.1159C36.2861 16.0715 36.3039 16.0289 36.3354 15.9976L36.5688 15.7643C36.6001 15.7327 36.6427 15.715 36.6871 15.715C36.7315 15.715 36.7741 15.7327 36.8054 15.7643L38.2854 17.2443L39.7654 15.7643C39.7967 15.7327 39.8393 15.715 39.8838 15.715C39.9282 15.715 39.9708 15.7327 40.0021 15.7643L40.2354 15.9976C40.267 16.0289 40.2847 16.0715 40.2847 16.1159C40.2847 16.1604 40.267 16.203 40.2354 16.2343L38.7554 17.7143L40.2354 19.1943Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  )
}

export default SVG
