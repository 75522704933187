const SVG = (props: any) => {
  const width = props.width || '48'
  const height = props.height || '48'
  const color = props.color || '#EFF2F3'
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="4" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5349 21.8095L32.774 18.7478C32.4672 17.5246 31.3676 16.6669 30.1065 16.667H27.494C27.3194 16.6688 27.1603 16.7678 27.0815 16.9237L26.8799 17.327C26.8011 17.4829 26.642 17.5819 26.4674 17.5837H21.5357C21.3611 17.5819 21.202 17.4829 21.1232 17.327L20.9215 16.9237C20.8428 16.7678 20.6837 16.6688 20.509 16.667H17.8965C16.6354 16.6669 15.5359 17.5246 15.229 18.7478L14.4682 21.8095C14.1027 23.2632 13.918 24.7564 13.9182 26.2553V29.5003C13.9182 30.5128 14.739 31.3337 15.7515 31.3337H16.329C17.6884 31.3519 18.8571 30.3741 19.079 29.0328L19.3265 27.5203C19.3992 27.072 19.7891 26.7445 20.2432 26.7503H27.8515C28.3057 26.7445 28.6956 27.072 28.7682 27.5203L29.0157 29.0328C29.2376 30.3741 30.4063 31.3519 31.7657 31.3337H32.2515C33.2641 31.3337 34.0849 30.5128 34.0849 29.5003V26.2553C34.0851 24.7564 33.9004 23.2632 33.5349 21.8095ZM18.9599 24.0003C17.6942 24.0003 16.6682 22.9743 16.6682 21.7087C16.6682 20.443 17.6942 19.417 18.9599 19.417C20.2255 19.417 21.2515 20.443 21.2515 21.7087C21.2515 22.3164 21.0101 22.8993 20.5803 23.3291C20.1506 23.7589 19.5677 24.0003 18.9599 24.0003ZM31.7916 22.9737C32.0447 22.9737 32.2499 22.7685 32.2499 22.5153V21.8187C32.2499 21.5655 32.0447 21.3603 31.7916 21.3603H30.3066V19.8753C30.3066 19.6222 30.1014 19.417 29.8483 19.417H29.1516C28.8985 19.417 28.6933 19.6222 28.6933 19.8753V21.3603H27.2083C26.9551 21.3603 26.7499 21.5655 26.7499 21.8187V22.5153C26.7499 22.7685 26.9551 22.9737 27.2083 22.9737H28.6933V24.4587C28.6933 24.7118 28.8985 24.917 29.1516 24.917H29.8483C30.1014 24.917 30.3066 24.7118 30.3066 24.4587V22.9737H31.7916Z"
        fill="#7487AA"
      />
    </svg>
  )
}

export default SVG
