const SVG = (props: any) => {
  const width = props.width || '32'
  const height = props.height || '56'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 32 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2764 0H6.6436C3.3554 0.00372869 0.6908 2.66835 0.6875 5.95659V50.0437C0.6908 53.332 3.3554 55.9966 6.6436 55.9998H25.2764C28.5646 55.9966 31.2292 53.332 31.233 50.0437V5.95659C31.2292 2.66835 28.5646 0.00372869 25.2764 0ZM28.6872 50.0437C28.6853 51.9267 27.1594 53.4527 25.2764 53.4545H6.6436C4.7606 53.4527 3.2347 51.9267 3.2328 50.0437V5.95659C3.2347 4.07313 4.7606 2.54716 6.6436 2.5453H25.2764C27.1594 2.54716 28.6853 4.07313 28.6872 5.95659V50.0437Z"
        fill={color}
      />
      <path
        d="M19.7784 5.08984H12.142C11.4392 5.08984 10.8691 5.6594 10.8691 6.36226C10.8691 7.06512 11.4392 7.63514 12.142 7.63514H19.7784C20.4812 7.63514 21.0508 7.06512 21.0508 6.36226C21.0508 5.6594 20.4812 5.08984 19.7784 5.08984Z"
        fill={color}
      />
      <path
        d="M18.5051 48.3656C18.5051 49.7717 17.3655 50.9113 15.9598 50.9113C14.5536 50.9113 13.4141 49.7717 13.4141 48.3656C13.4141 46.9598 14.5536 45.8203 15.9598 45.8203C17.3655 45.8203 18.5051 46.9598 18.5051 48.3656Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
