import { useContext, useEffect, useState } from 'react'
import { Box, Theme, useTheme } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { StylePaper } from 'src/components'
import { Icons } from 'src/images'
import { GlobalStoreContext } from 'src/context/globalStore'
import { GlobalContext } from 'src/context/global'
import { useNavigate } from 'react-router-dom'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      content: {
        display: 'flex',
      },
      closeIcon: {
        position: 'absolute',
        right: '8px',
        top: '14px',
        cursor: 'pointer',
      },
      left: {
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '44px',
      },
      leftIcon: {
        width: '32px',
        height: '32px',
      },
      textContent: {
        maxWidth: '380px',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '18px',
        color: theme.palette.mode === 'light' ? '#B0B5B9' : '#F7FAFC',
      },
      clickContent: {
        color: '#2F80ED',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    }
  })

const ApplicationNotificationView = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const { state } = useContext(GlobalStoreContext)
  const { state: globalState, getNewGameList } = useContext(GlobalContext)

  const [open, setOpen] = useState(false)
  const [list, setList] = useState<any[]>([])
  const [listIndex, setListIndex] = useState<number>(0)
  const toGamePage = () => {
    navigate(
      '/dash/games?name=' + encodeURIComponent(list[listIndex].game_name)
    )
    closeDialog()
  }
  const closeDialog = () => {
    setOpen(false)
    // 记录已经查看的消息
    if (window.name) {
      window.name = window.name + ',' + list[listIndex].app_key
    } else {
      window.name = String(list[listIndex].app_key)
    }
    const timer = setTimeout(() => {
      setListIndex(listIndex + 1)
      if (list[listIndex + 1]) {
        setOpen(true)
      }
      clearTimeout(timer)
    }, 5000)
  }

  useEffect(() => {
    if (state.gameList.length) {
      // 不展示本次已经展示过的消息
      const temp = window.name.split(',')
      const ownershipVerifiedList = state.gameList.filter((item: any) => {
        return (
          item.ownership_verified === false &&
          item.url &&
          !temp.includes(item.app_key)
        )
      })
      setList(ownershipVerifiedList)
      if (ownershipVerifiedList.length) {
        setOpen(true)
      }
    }
  }, [state.gameList])
  useEffect(() => {
    let timer: any = null
    setListIndex(0)
    timer = setInterval(() => {
      window.name = ''
      setListIndex(0)
      getNewGameList()
    }, 259200000)
    return () => {
      if (timer) {
        clearInterval(timer)
        timer = null
      }
    }
    // eslint-disable-next-line
  }, [])

  // returns
  return (
    <>
      {!!(
        !globalState.limitAppView &&
        open &&
        list.length &&
        list[listIndex]
      ) && (
        <StylePaper
          boxSizing="border-box"
          width="70vw"
          maxWidth="466px"
          position="fixed"
          top="8px"
          left="50%"
          transform="translateX(-50%)"
          padding="8px"
          boxShadow="0px 0px 2px rgba(145, 158, 171, 0.6), 0px 12px 24px rgba(145, 158, 171, 0.06)"
          zIndex={1199}
        >
          <CloseIcon className={classes.closeIcon} onClick={closeDialog} />
          <Box className={classes.content}>
            <Box className={classes.left}>
              <img
                src={Icons.warningIcon}
                alt="icon"
                className={classes.leftIcon}
              />
            </Box>
            <Box>
              <Box className={classes.textContent}>
                {list[listIndex].game_name} has some integration issues. Please
                review their{' '}
                <span
                  className={classes.clickContent}
                  onClick={() => {
                    toGamePage()
                  }}
                >
                  release steps
                </span>{' '}
                for more information.
              </Box>
            </Box>
          </Box>
        </StylePaper>
      )}
    </>
  )
}

export default ApplicationNotificationView
