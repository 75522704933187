const SVG = (props: any) => {
  const width = props.width || '14'
  const height = props.height || '25'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6082 0H3.41716C1.97163 0.00163914 0.800251 1.17301 0.798828 2.61854V21.9994C0.800251 23.4449 1.97163 24.6163 3.41716 24.6177H11.6082C13.0537 24.6163 14.2251 23.4449 14.2267 21.9994V2.61854C14.2251 1.17301 13.0537 0.00163914 11.6082 0ZM13.1076 21.9994C13.1068 22.8271 12.4359 23.498 11.6082 23.4988H3.41716C2.58939 23.498 1.91855 22.8271 1.91774 21.9994V2.61854C1.91855 1.79056 2.58939 1.11974 3.41716 1.11892H11.6082C12.4359 1.11974 13.1068 1.79056 13.1076 2.61854V21.9994Z"
        fill={color}
      />
      <path
        d="M9.19144 2.23828H5.83446C5.5255 2.23828 5.2749 2.48866 5.2749 2.79764C5.2749 3.10662 5.5255 3.3572 5.83446 3.3572H9.19144C9.50043 3.3572 9.7508 3.10662 9.7508 2.79764C9.7508 2.48866 9.50043 2.23828 9.19144 2.23828Z"
        fill={color}
      />
      <path
        d="M8.63162 21.2615C8.63162 21.8797 8.13065 22.3806 7.5127 22.3806C6.89453 22.3806 6.39355 21.8797 6.39355 21.2615C6.39355 20.6435 6.89453 20.1426 7.5127 20.1426C8.13065 20.1426 8.63162 20.6435 8.63162 21.2615Z"
        fill={color}
      />
      <path
        d="M11.5964 4.78125H3.46375C3.25132 4.78125 3.0791 4.95346 3.0791 5.1659V18.1342C3.0791 18.3466 3.25132 18.5189 3.46375 18.5189H11.5964C11.8089 18.5189 11.9811 18.3466 11.9811 18.1342V5.1659C11.9811 4.95346 11.8089 4.78125 11.5964 4.78125Z"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        d="M5.47843 9.81836C5.19814 9.81836 4.96558 10.0509 4.96558 10.3312V13.4084C4.96558 13.6887 5.19814 13.9213 5.47843 13.9213H9.58141C9.86171 13.9213 10.0943 13.6887 10.0943 13.4084V10.3312C10.0943 10.0509 9.86171 9.81836 9.58141 9.81836H5.47843ZM5.47843 10.3312H9.58141V13.4084H5.47843V10.3312ZM8.171 11.6134L7.27349 12.6391L6.63239 11.9981L5.93421 12.8956H9.13264L8.171 11.6134Z"
        fill={color}
      />
      <path
        d="M10.6978 7.38324C10.71 7.39528 10.7168 7.41167 10.7168 7.42875C10.7168 7.44583 10.71 7.46221 10.6978 7.47425L10.6081 7.56403C10.596 7.57615 10.5797 7.58299 10.5626 7.58299C10.5455 7.58299 10.5291 7.57615 10.517 7.56403L9.94775 6.99474L9.37846 7.56403C9.36642 7.57615 9.35004 7.58299 9.33296 7.58299C9.31588 7.58299 9.29949 7.57615 9.28745 7.56403L9.19767 7.47425C9.18556 7.46221 9.17871 7.44583 9.17871 7.42875C9.17871 7.41167 9.18556 7.39528 9.19767 7.38324L9.76696 6.81396L9.19767 6.24467C9.18556 6.23263 9.17871 6.21625 9.17871 6.19913C9.17871 6.18205 9.18556 6.16566 9.19767 6.15362L9.28745 6.06389C9.29949 6.05173 9.31588 6.04492 9.33296 6.04492C9.35004 6.04492 9.36642 6.05173 9.37846 6.06389L9.94775 6.63317L10.517 6.06389C10.5291 6.05173 10.5455 6.04492 10.5626 6.04492C10.5797 6.04492 10.596 6.05173 10.6081 6.06389L10.6978 6.15362C10.71 6.16566 10.7168 6.18205 10.7168 6.19913C10.7168 6.21625 10.71 6.23263 10.6978 6.24467L10.1285 6.81396L10.6978 7.38324Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2625"
      />
    </svg>
  )
}

export default SVG
