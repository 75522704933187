const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '18'
  const color = props.color || '#1D3353'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1085 3.825L13.6867 5.418L9.24632 9.9H7.66811V8.307L12.1085 3.825ZM15.0241 3.429L14.079 2.475C13.9007 2.295 13.6243 2.295 13.4459 2.475L12.688 3.24L14.2663 4.833L15.0241 4.068C15.2025 3.888 15.2025 3.6 15.0241 3.429ZM12.483 9.18V13.5H14.2663V15.3H0V13.5H1.78328V7.2C1.78328 4.689 3.48632 2.574 5.79567 1.98V1.35C5.79567 0.603 6.39307 0 7.13313 0C7.87319 0 8.47059 0.603 8.47059 1.35V1.98C9.20173 2.169 9.87047 2.511 10.4411 2.961L9.16607 4.248C8.59542 3.834 7.89102 3.6 7.13313 3.6C5.1626 3.6 3.56656 5.211 3.56656 7.2V13.5H10.6997V10.98L12.483 9.18ZM5.34985 16.2H8.91641C8.91641 17.19 8.11393 18 7.13313 18C6.15232 18 5.34985 17.19 5.34985 16.2Z"
        fill={color}
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default SVG
