const SVG = (props: any) => {
  const width = props.width || '10'
  const height = props.height || '10'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.00132 8.30465L1.69305 8.99742L8.01964 2.67082L8.01964 9.32812L9 9.32812L9 0.998734L0.670613 0.997415L0.670613 1.97768L7.3265 1.97768L1.00132 8.30465Z"></path>
    </svg>
  )
}

export default SVG
