import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  TextField,
  Box,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { VerifyBody } from 'src/api/sso'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from 'src/context/auth'
import { LoadingComponent } from 'src/components'
import { getQueryParams, getUuid, md5Password } from 'src/utils'

const getUseStyles = () =>
  makeStyles(() => {
    return {
      container: {
        position: 'relative',
        boxSizing: 'border-box',
        width: '100%',
      },
      formInput: {
        width: '100%',
        display: 'flex',
      },
      title: {
        textAlign: 'center',
        color: '#1D3353',
        fontWeight: 600,
        fontSize: '18px',
        marginBottom: '16px',
        lineHeight: '34px',
      },
      desc: {
        fontSize: '14px',
        textAlign: 'center',
        color: '#1D3353',
        marginBottom: '6px',
      },
      termsLink: {
        fontSize: '14px',
      },
      warningBox: {
        position: 'absolute',
        top: '-136px',
        fontSize: '14px',
        fontWeight: 400,
        borderRadius: '6px',
        border: '1px solid rgba(235, 40, 39, 0.5)',
        background: 'rgb(250 245 246)',
        padding: '20px 16px',
        color: '#1D3353',
      },
      input: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
          background: '#EEF1F5',
          height: '36px',
        },
        '& .MuiInputBase-input': {
          color: '#102542 !important',
          padding: '18px 12px !important',
          marginLeft: '0 !important',
          boxShadow: '0 0 0 100px #EEF1F5 inset !important',
          textFillColor: '#102542 !important',
        },
        '& .MuiInputAdornment-root': {
          textFillColor: '#102542 !important',
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          border: '1px solid #D2D6DD !important',
        },
      },
    }
  })

const View = (): JSX.Element => {
  const useStyles = getUseStyles()
  const classes = useStyles()
  const [t] = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { state, twoFaVerify, twoFaRetry } = useContext(AuthContext)
  const [emailData, setEmailData] = useState<VerifyBody>({
    email: '',
    code: '',
  })
  // 是否勾选
  const [checkTrust, setCheckTrust] = useState(false)
  const onChangeCheckTrust = (event: any, result: any) => {
    setCheckTrust(result)
  }

  const isAllowVerifyEmail = useMemo(
    () => emailData.code.length === 6 && state.attemptsRemaining,
    [emailData.code, state.attemptsRemaining]
  )

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailData(data => ({
      ...data,
      code: e.target.value,
    }))
  }

  // handlers
  const onNext = () => {
    const temp: VerifyBody = { ...emailData }
    if (checkTrust) {
      const device_id = md5Password(emailData.email + getUuid())
      temp.device_id = device_id
    }
    twoFaVerify(temp)
  }

  useEffect(() => {
    if (location.search) {
      const temp = getQueryParams(location.search)
      if (!temp.email) {
        navigate('/login')
      }
      setEmailData(data => ({
        ...data,
        ...temp,
      }))
    } else {
      navigate('/login')
    }
    // eslint-disable-next-line
  }, [location.search])

  // returns
  if (state.twoFaLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <LoadingComponent />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      {state.attemptsRemaining < 3 && (
        <Box className={classes.warningBox}>
          <Box component="span" fontWeight={600}>
            Warning:
          </Box>{' '}
          after 3 consecutive wrong attempts, your account will be locekd for 3
          hours.
          <Box marginTop="12px">
            Attempts remaining:{' '}
            <Box component="span" fontWeight={600}>
              {state.attemptsRemaining}
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.title}>Two-Factor Authentication</Box>
      <Box marginBottom="10px">
        <Box marginBottom="20px">
          <Box className={classes.desc}>
            A verification code has been sent to {emailData.email}.
          </Box>
          <Box className={classes.desc}>
            This code will be valid for next 10 minutes.
          </Box>
        </Box>
        <Box
          sx={{
            color: '#1D3353',
            fontSize: 12,
            fontWeight: 500,
            marginBottom: '6px',
          }}
        >
          Enter Verification Code
        </Box>
        <Box className={classes.formInput}>
          <TextField
            value={emailData.code}
            placeholder="Code"
            onChange={onChangeCode}
            className={classes.input}
            fullWidth
            type="text"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {emailData.code.length}/6
                </InputAdornment>
              ),
            }}
            variant="outlined"
            inputProps={{
              maxLength: 6,
            }}
          />
        </Box>
        <Box marginTop="10px" display="flex" justifyContent="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={checkTrust}
                onChange={onChangeCheckTrust}
                sx={{ color: '#1D3353' }}
              />
            }
            label={
              <Box
                style={{
                  wordBreak: 'break-all',
                  fontSize: 12,
                  color: '#1D3353',
                }}
              >
                This is a trusted device, don’t ask again.
              </Box>
            }
          />
        </Box>
      </Box>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        onClick={onNext}
        disabled={!isAllowVerifyEmail}
      >
        {t('auth.login.sign_in')}
      </Button>
      <Box
        sx={{
          fontSize: '12px',
          color: '#7487AA',
          marginTop: '14px',
          lineHeight: '25px',
          textAlign: 'center',
          fontWeight: 600,
        }}
      >
        It may take a minute to receive your code. Haven’t received it?{' '}
        <Link
          underline="none"
          className={classes.termsLink}
          onClick={() => {
            if (state.emailCodeInterval) {
              return
            }
            twoFaRetry({ email: emailData.email })
          }}
        >
          {state.emailCodeInterval
            ? `Sent!(${state.emailCodeInterval})`
            : 'Resend a new code'}
        </Link>
      </Box>
    </Box>
  )
}

export default View
