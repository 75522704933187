import { useContext } from 'react'
import { GameContextProvider } from 'src/context/games'
import { GlobalContext } from 'src/context/global'
import TableList from './gameList/tableList'
import MobileTableList from './gameList/mobileTableList'

const Game = (): JSX.Element => {
  const { state: globalState } = useContext(GlobalContext)
  // returns
  return (
    <GameContextProvider>
      {globalState.isMobile ? <MobileTableList /> : <TableList />}
    </GameContextProvider>
  )
}

export default Game
