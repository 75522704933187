import { CohortsContextProvider } from 'src/context/gamePlayInsights/cohorts'
import { ChurnRateContextProvider } from 'src/context/gamePlayInsights/churnRate'
import { SessionsContextProvider } from 'src/context/gamePlayInsights/sessions'
import { StickinessContextProvider } from 'src/context/gamePlayInsights/stickiness'
import { InstallsContextProvider } from 'src/context/gamePlayInsights/installs'
import GameplayInsightsView from './gameplayInsights'

const GamePlayInsights = (): JSX.Element => {
  // returns
  return (
    <CohortsContextProvider>
      <ChurnRateContextProvider>
        <SessionsContextProvider>
          <StickinessContextProvider>
            <InstallsContextProvider>
              <GameplayInsightsView />
            </InstallsContextProvider>
          </StickinessContextProvider>
        </SessionsContextProvider>
      </ChurnRateContextProvider>
    </CohortsContextProvider>
  )
}

export default GamePlayInsights
