import { Box } from '@material-ui/core'
import LoadingView from './loading'

const Component = (props: any) => {
  return (
    <Box
      component="span"
      display="flex"
      alignItems="center"
      color="#ffffff"
      style={props.loadingStyle ? props.loadingStyle : {}}
    >
      <LoadingView />{' '}
      {!props.noText && (
        <Box component="span" marginLeft="4px">
          Loading
        </Box>
      )}
    </Box>
  )
}

export default Component
