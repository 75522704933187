import { ADContextProvider } from 'src/context/adManage'
import ADManagement from './management'

const AD = (): JSX.Element => {
  return (
    <ADContextProvider>
      <ADManagement />
    </ADContextProvider>
  )
}

export default AD
