import { ReactElement } from 'react'

interface Props {
  children?: ReactElement | null
}

const Layout = ({ children }: Props): JSX.Element => {
  // returns
  return <>{children}</>
}

export default Layout
