import { portalService } from './service'

export const getRolesListApi = () => {
  return portalService({
    url: '/roles',
    method: 'get',
  })
}
export const getAccountsListApi = () => {
  return portalService({
    url: '/sub_account',
    method: 'get',
  })
}

export const editRoleApi = (data: any) => {
  return portalService({
    url: `/sub_account/${data.developer_key}/role`,
    method: 'patch',
    data,
  })
}
export const addRoleApi = (data?: any) => {
  return portalService({
    url: '/sub_account',
    method: 'post',
    data,
  })
}
export const deleteAccountApi = (developer_key: string) => {
  return portalService({
    url: `/sub_account/${developer_key}`,
    method: 'delete',
  })
}
export const sendCodeApi = (data: { email: string }) => {
  return portalService({
    url: `/sub_account/email_invite_code`,
    method: 'post',
    data,
  })
}
