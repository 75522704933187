import {
  Box,
  Button,
  Link,
  Dialog,
  useTheme,
  Theme,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  HelpOutline as HelpOutlineIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { ADContext } from 'src/context/adManage'
import { useContext, useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { StylePaper, LoadingBtn, LoadingComponent } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
      },
      childBoxTitle: {
        width: '110px',
        height: 'auto',
        marginRight: '20px',
        display: 'inline-block',
        textAlign: 'right',
        verticalAlign: 'top',
        marginTop: '12px',
      },
      childBox: {
        border: '1px solid #eee',
        borderRadius: '6px',
        height: 'auto',
        width: '60%',
        marginTop: '12px',
        display: 'inline-block',
        textAlign: 'left',
        verticalAlign: 'top',
      },
      childBoxNoBorder: {
        height: 'auto',
        marginTop: '12px',
        display: 'inline-block',
        textAlign: 'left',
        verticalAlign: 'top',
      },
      emailInput: {
        width: 'calc(100% - 180px)',
        marginLeft: '30px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '16px',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        border: '0 none',
        outline: 'none',
        padding: '0',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
      root: {
        width: '80vw',
        maxWidth: '380px',
        padding: '24px',
        boxSizing: 'border-box',
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
      },
      content: {
        fontSize: '14px',
        margin: '16px 0 24px',
      },
      button: {
        display: 'flex',
        justifyContent: 'center',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '-3px',
        cursor: 'pointer',
      },
      cancelButton: {
        boxSizing: 'border-box',
        width: '154px',
        height: '40px',
        lineHeight: '40px',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        border: `1px solid ${theme.palette.custom.dialogButton}`,
        color: theme.palette.custom.dialogButton,
        borderRadius: '6px',
        marginRight: '24px',
        cursor: 'pointer',
      },
      confirmButton: {
        width: '154px',
        height: '40px !important',
        borderRadius: '6px !important',
      },
    }
  })

const ADManagement = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, updateUserMcm, downloadAppAds, uploadAppAds } =
    useContext(ADContext)
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [status, setStatus] = useState('')
  const [openModel, setOpenModel] = useState<boolean>(false)
  const [file, setFile] = useState<any>(null)
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file: any) => {
      setFile(file)
    })
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: { 'text/plain': ['.txt'] },
    disabled: file,
  })
  const emailInputChange = (e: any) => {
    setEmail(e.target.value)
  }
  const confirmEmailInputChange = (e: any) => {
    setConfirmEmail(e.target.value)
  }
  const downloadAd = () => {
    downloadAppAds()
  }
  const update = () => {
    if (state.updateLoading) return
    updateUserMcm(email, confirmEmail)
  }
  const upload = () => {
    const formData = new FormData()
    formData.append('file', file)
    uploadAppAds(formData)
  }
  const onClose = () => {
    setOpenModel(false)
  }
  const statusMap = {
    UNKNOWN:
      'The value returned if the actual value is not exposed by the requested API version.',
    INVITED: 'The child publisher has not acted on the invite from the parent.',
    DECLINED: 'The child publisher has declined the invite.',
    PENDING_GOOGLE_APPROVAL:
      "The child publisher has accepted the invite, and is awaiting Google's policy compliance verifications.",
    CLOSED_POLICY_VIOLATION:
      'The child publisher accepted the invite, but was disapproved by Google for violating its policies.',
    CLOSED_INVALID_ACTIVITY:
      'The child publisher accepted the invite, but was disapproved by Google for invalid activity.',
    CLOSED_BY_PUBLISHER: 'The child publisher has closed their own account.',
    DISAPPROVED_INELIGIBLE:
      'The child publisher accepted the invite, but was disapproved as ineligible by Google.',
    DISAPPROVED_DUPLICATE_ACCOUNT:
      'The child publisher accepted the invite, but was disapproved by Google for being a duplicate of another account.',
    EXPIRED:
      'The invite was sent to the child publisher more than 90 days ago, due to which it has been deactivated.',
    INACTIVE:
      'Either the child publisher disconnected from the parent network, or the parent network withdrew the invite.',
    DEACTIVATED_BY_AD_MANAGER:
      'The association between the parent and child publishers was deactivated by Google Ad Manager.',
    APPROVED:
      'The association request from the parent network is approved by the child network.',
    PENDING:
      'The association request from the parent network is pending child network approval or rejection.',
    REJECTED:
      'The association request from the parent network is rejected or revoked by the child network.',
    WITHDRAWN:
      'The association request from the parent network is withdrawn by the parent network.',
    ERROR: 'There are some Unknown Issue, please contact CSM.',
  }
  useEffect(() => {
    setEmail(state.mcm_email || '')
    setStatus(state.mcm_state)
    // eslint-disable-next-line
  }, [state.mcm_state, state.mcm_email])
  return (
    <Box className={classes.container}>
      {state.loading && (
        <Box className={classes.loadingMask}>
          <LoadingComponent />
        </Box>
      )}
      <StylePaper
        width="100%"
        boxSizing="border-box"
        padding="24px"
        marginTop="24px"
      >
        <Box fontSize="20px" fontWeight={500}>
          Google Ad Manager
          <Tooltip
            title={
              <Box style={{ fontSize: '14px' }}>
                Click{' '}
                {
                  <Box
                    component="span"
                    color={theme.palette.primary.main}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open('https://admanager.google.com/')
                    }}
                  >
                    here
                  </Box>
                }{' '}
                to know more detail about Google Ad Manager
              </Box>
            }
            arrow
            placement="top"
          >
            <HelpOutlineIcon
              style={{
                fontSize: '18px',
                verticalAlign: 'middle',
                marginLeft: '6px',
                color: theme.palette.text.secondary,
              }}
            />
          </Tooltip>
        </Box>
        {!state.mcm_state && (
          <>
            <Box marginTop="28px">
              <Box
                className={classes.childBoxTitle}
                style={{ paddingTop: '10px' }}
              >
                Email:
              </Box>
              <Box position="relative" className={classes.childBox}>
                <input
                  value={email}
                  type="text"
                  onInput={emailInputChange}
                  className={classes.emailInput}
                />
              </Box>
            </Box>
            <Box>
              <Box
                className={classes.childBoxTitle}
                style={{ paddingTop: '10px' }}
              >
                Confirm Email:
              </Box>
              <Box position="relative" className={classes.childBox}>
                <input
                  value={confirmEmail}
                  type="text"
                  onInput={confirmEmailInputChange}
                  className={classes.emailInput}
                />
              </Box>
            </Box>
            <Box>
              <Box position="relative" className={classes.childBoxNoBorder}>
                <Button
                  disabled={
                    email && confirmEmail && !state.updateLoading ? false : true
                  }
                  color="primary"
                  variant="contained"
                  style={{
                    width: '210px',
                    height: '40px',
                    marginLeft: '130px',
                  }}
                  onClick={update}
                >
                  {state.updateLoading ? (
                    <LoadingBtn
                      loadingStyle={{
                        position: 'absolute',
                        width: '100%',
                        justifyContent: 'center',
                      }}
                    />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Box>
            </Box>
          </>
        )}
        {state.mcm_state && (
          <Box marginTop="28px">
            <Box>
              <Box className={classes.childBoxTitle}>Email:</Box>
              <Box className={classes.childBoxNoBorder}>
                <label>{email}</label>
              </Box>
            </Box>
            <Box>
              <Box className={classes.childBoxTitle}>Status:</Box>
              <Box className={classes.childBoxNoBorder}>
                <label>{status}</label>
              </Box>
            </Box>
            <Box>
              <Box className={classes.childBoxTitle}>Description:</Box>
              <Box className={classes.childBoxNoBorder}>
                <label>{statusMap[status]}</label>
              </Box>
            </Box>
          </Box>
        )}
        <Box marginTop="18px" marginLeft="25px">
          <label>
            Make sure that MAS SDK version 4.6.0 or higher is integrated in your
            game for Ad manager to work properly.
          </label>
        </Box>
      </StylePaper>
      <StylePaper
        width="100%"
        boxSizing="border-box"
        padding="24px"
        marginTop="24px"
      >
        <Box fontSize="20px" fontWeight={500}>
          App-Ads
        </Box>
        <Box width="80%" marginTop="12px">
          <Box
            marginTop="12px"
            display="inline-block"
            textAlign="left"
            lineHeight="36px"
          >
            <Box style={{ fontSize: '16px' }}>
              Click
              <Link
                style={{ fontSize: '16px' }}
                underline="none"
                onClick={downloadAd}
              >
                {` here `}
              </Link>
              to download your specific version of the app-ads.txt file.
              <label style={{ color: '#949494', marginLeft: '15px' }}>
                Updated at {state.appAdsTxtUpdateTime}
              </label>
              {/* <Button
                color="primary"
                variant="contained"
                style={{
                  display: 'inline-block',
                  width: '230px',
                  height: '36px',
                  lineHeight: '12px',
                  marginLeft: '24px',
                }}
                onClick={() => {
                  setOpenModel(true)
                }}
              >
                App-Ads.txt Formatter
              </Button> */}
            </Box>
            <Box marginTop="6px">
              If you are
              <a
                href="https://developers.yodo1.com/docs/additional-set-up/app-ads/using-yodo1s-app-ads-url/"
                rel="noreferrer"
                target="_Blank"
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >{` Using Yodo1’s App-Ads URL `}</a>
              that we provide as website for your apps, you can skip this step.
            </Box>
          </Box>
        </Box>
      </StylePaper>

      <Dialog data-testid="prompt-dialog" open={openModel} onClose={onClose}>
        <Box className={classes.root}>
          <Box>
            <Box data-testid="prompt-dialog-title" className={classes.title}>
              Upload app-ads.txt
              <CloseIcon
                className={classes.closeFilterIcon}
                onClick={onClose}
              />
            </Box>
          </Box>
          <Box className={classes.content}>
            <Box
              {...getRootProps()}
              style={{
                width: '100%',
                height: '120px',
                lineHeight: '120px',
                textAlign: 'center',
                border: '1px dashed #d9d9d9',
                background:
                  theme.palette.mode === 'light'
                    ? 'rgb(250, 250, 250)'
                    : 'rgba(250, 250, 250, 0.2)',
                cursor: file ? 'not-allowed' : 'pointer',
                borderRadius: '2px',
              }}
            >
              <input {...getInputProps()} />
              Click or drag file to this area to upload
            </Box>
            {file && (
              <Box
                sx={{
                  display: 'flex',
                  lineHeight: '22px',
                  height: '22px',
                  marginTop: '12px',
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {file.name}
                </Box>
                <Box
                  sx={{
                    boxSizing: 'border-box',
                    width: '24px',
                    textAlign: 'center',
                    padding: '2px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setFile(null)
                  }}
                >
                  <CloseIcon style={{ fontSize: '18px' }} />
                </Box>
              </Box>
            )}
          </Box>
          <Box data-testid="prompt-dialog-footer" className={classes.button}>
            <Box className={classes.cancelButton} onClick={onClose}>
              Cancel
            </Box>
            <Button
              className={classes.confirmButton}
              color="primary"
              variant="contained"
              disabled={!file}
              onClick={upload}
            >
              {state.uploadLoading ? (
                <Box lineHeight="30px" padding="5px 0">
                  <LoadingBtn
                    loadingStyle={{
                      justifyContent: 'center',
                      color: '#bcbcbc',
                    }}
                  />
                </Box>
              ) : (
                'Confirm'
              )}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default ADManagement
