import { createContext, useReducer, useEffect, useContext } from 'react'
import { GlobalContext } from '../global'
import { GlobalStoreContext } from '../globalStore'
import {
  getLastTimeRange,
  toThousands,
  transformUTC0ToLocalTime,
} from 'src/utils'
import { getInstallsApi } from 'src/api/dashboard'

type Props = {
  view: string
  gameSelect: any[]
  country: any[]
  time: [string, string]
  timeShow: string
  platform: any[]
  data: any
  loading: boolean
  gameListObject: any
  countryListObject: any
  chartList: Array<any>
  checkedChartLine: Array<string>
  resetIndex: number
  tabCardData: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  view: 'application',
  gameSelect: [],
  country: [],
  time: getLastTimeRange(7),
  timeShow: 'last_week',
  platform: [],
  loading: false,
  data: {},
  gameListObject: {},
  countryListObject: {},
  chartList: [
    {
      label: 'All',
      value: 'All',
      color: '#FF8E00',
    },
  ],
  checkedChartLine: [],
  resetIndex: 0,
  tabCardData: {},
}

const colorList = ['#FF8E00', '#90cc75', '#f07474', '#5a70b5']
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'data':
      let allInstallsData = action.payload.installsData.data
      const disabled = action.payload?.tabDisabled || false
      const untilTime = action.payload?.untilTime || null
      let chartList: any = []
      let checkedChartLine: any = []
      const installsKeys = allInstallsData ? Object.keys(allInstallsData) : []
      if (!installsKeys.length) {
        allInstallsData = []
        chartList = [
          {
            label: 'All',
            value: 'All',
            color: '#FF8E00',
          },
        ]
        checkedChartLine = ['All']
      }
      const installsLabels = action.payload.installsData.days
      const installsDatasets: any = []
      installsKeys.forEach((item: any, i: number) => {
        const color: string =
          colorList[i] ||
          '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
        const label =
          prevState.gameListObject[item]?.name ||
          prevState.countryListObject[item]?.name ||
          item
        installsDatasets.push({
          label,
          data: allInstallsData[item]?.installs,
          pointRadius: 2.5,
          borderColor: color,
          backgroundColor: color,
          tension: 0.2,
          hitRadius: 10,
        })
        chartList.push({
          label,
          value: label,
          color,
          total: allInstallsData[item]?.installs.reduce(
            (a: number, b: number) => a + b
          ),
        })
        checkedChartLine.push(label)
      })
      const installsData = {
        labels: installsLabels,
        datasets: installsDatasets,
      }
      return {
        ...prevState,
        data: installsData,
        checkedChartLine,
        chartList: chartList.sort((a: any, b: any) => b.total - a.total),
        tabCardData: {
          label: 'Installs',
          key: 'installs',
          disabled,
          untilTime,
          value: toThousands(
            chartList.reduce(
              (result: number, item: any) => result + item.total,
              0
            ) || '0',
            true
          ),
        },
      }
    case 'view':
    case 'gameSelect':
    case 'country':
    case 'time':
    case 'timeShow':
    case 'platform':
    case 'loading':
    case 'gameListObject':
    case 'countryListObject':
    case 'gameSelect':
    case 'checkedChartLine':
    case 'chartList':
    case 'resetIndex':
    case 'tabCardData':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  getInstallsData: () => void
}

export const InstallsContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  getInstallsData: () => {},
})

export const InstallsContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { state: GlobalState, errorCatch } = useContext(GlobalContext)
  const { state: globalStoreState, dispatch: globalStoreDispatch } =
    useContext(GlobalStoreContext)
  // handlers
  const getInstallsData = () => {
    dispatch({ type: 'loading', payload: true })
    if (globalStoreState.fromWelcome) {
      dispatch({ type: 'time', payload: globalStoreState.time })
      dispatch({ type: 'timeShow', payload: 'custom' })
      dispatch({ type: 'gameSelect', payload: globalStoreState.gameSelect })
    }
    const params = {
      from_date: globalStoreState.fromWelcome
        ? globalStoreState.time[0]
        : state.time[0],
      to_date: globalStoreState.fromWelcome
        ? globalStoreState.time[1]
        : state.time[1],
      view_by: state.view,
      platform: state.platform.map(item => item.value),
      countries: state.country.map(item => item.code),
      app_keys: globalStoreState.fromWelcome
        ? globalStoreState.gameSelect.map(item => item.app_key)
        : state.gameSelect.map(item => item.app_key),
    }
    globalStoreDispatch({
      type: 'installsData',
      payload: {
        filter: {
          time: globalStoreState.fromWelcome
            ? globalStoreState.time
            : state.time,
          timeShow: globalStoreState.fromWelcome ? 'custom' : state.timeShow,
          gameSelect: globalStoreState.fromWelcome
            ? globalStoreState.gameSelect
            : state.gameSelect,
          platform: state.platform,
          country: state.country,
          view: state.view,
        },
      },
    })
    getInstallsApi(params)
      .then((res: any) => {
        dispatch({
          type: 'data',
          payload: { installsData: res.data },
        })
        globalStoreDispatch({
          type: 'installsData',
          payload: {
            data: { installsData: res.data },
          },
        })
      })
      .catch((err: any) => {
        if (
          err?.response?.status === 503 &&
          err?.response?.data?.code === 'trino_migration'
        ) {
          let untilTime = null
          if (err?.response?.data?.extra?.after) {
            untilTime = transformUTC0ToLocalTime(
              err?.response?.data?.extra?.after,
              'Do MMM YYYY hh:mm a'
            )
          }
          dispatch({
            type: 'data',
            payload: { installsData: {}, tabDisabled: true, untilTime },
          })
          globalStoreDispatch({
            type: 'installsData',
            payload: {
              data: { installsData: {}, tabDisabled: true, untilTime },
            },
          })
        } else {
          errorCatch(err)
          globalStoreDispatch({
            type: 'installsData',
            payload: {
              data: { installsData: {} },
            },
          })
        }
      })
      .finally(() => {
        // 不加延时则loading结束后会有数据闪烁，因为前端数据循环的时间差
        setTimeout(() => {
          dispatch({ type: 'loading', payload: false })
        })
      })
  }

  useEffect(() => {
    dispatch({ type: 'gameListObject', payload: GlobalState.gameListObject })
    // eslint-disable-next-line
  }, [GlobalState.gameListObject])
  useEffect(() => {
    dispatch({
      type: 'countryListObject',
      payload: GlobalState.countryListObject,
    })
    // eslint-disable-next-line
  }, [GlobalState.countryListObject])
  useEffect(() => {
    if (globalStoreState.installsData.filter) {
      Object.keys(globalStoreState.installsData.filter).forEach(
        (item: string) => {
          dispatch({
            type: item,
            payload: globalStoreState.installsData.filter[item],
          })
        }
      )
      Object.keys(globalStoreState.installsData)
        .filter((item: string) => item !== 'filter')
        .forEach((item: string) => {
          dispatch({
            type: item,
            payload: globalStoreState.installsData[item],
          })
        })
      return
    }
    getInstallsData()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (state.resetIndex) {
      getInstallsData()
    }
    // eslint-disable-next-line
  }, [state.resetIndex])
  // returns
  return (
    <InstallsContext.Provider
      value={{
        state,
        dispatch,
        getInstallsData,
      }}
    >
      {children}
    </InstallsContext.Provider>
  )
}
