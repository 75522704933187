import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  Dispatch,
} from 'react'

import {
  getEchartApi,
  addChartConfigApi,
  putChartConfigApi,
  deleteChartConfigApi,
  setDefaultConfigApi,
} from 'src/api/dashboard'
import { getCommentsEventApi } from 'src/api/user'
import { GlobalContext } from '../global'
import { GlobalStoreContext } from '../globalStore'
import {
  getLastTimeRange,
  toThousands,
  getDateRangeFromValue,
  tabMetricFormat,
  transformUTC0ToLocalTime,
} from 'src/utils'
import { useTranslation } from 'react-i18next'

type Props = {
  gameOption: any
  countryOption: any
  gameListObject: any
  countryListObject: any
  view: string
  gameSelect: any[]
  country: any[]
  time: [string, string]
  timeShow: string
  type: any[]
  chartType: string
  platform: any[]
  data: any
  chartTabData: any
  chartTabDataLoading: any
  dataLoading: boolean
  configLoading: boolean
  openConfigTitle: boolean
  configTitleLoading: boolean
  deleteConfigLoading: boolean
  countryChecked: string[]
  countryIsFilter: boolean
  checkedMetrics: any
  summaryGroupBy: string
  resetIndex: number
  commentsEvent: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  gameOption: [],
  gameListObject: {},
  countryOption: [],
  countryListObject: {},
  view: 'all',
  gameSelect: [],
  country: [],
  time: getLastTimeRange(7),
  timeShow: 'last_week',
  type: [],
  chartType: 'revenue',
  platform: [],
  data: {},
  chartTabData: [
    {
      name: 'Est. Revenue',
      value: 'revenue',
      num: '0',
      tooltip:
        'Revenue generated by your apps integrated with MAS. This may differ from final revenue due to deductions made by ad networks during reconciliation.',
    },
    {
      name: 'Impressions',
      value: 'impressions',
      num: '0',
      tooltip:
        'Impressions are the number of times ads are displayed to users in your apps integrated with MAS.',
    },
    {
      name: 'eCPM',
      value: 'ecpm',
      num: '0',
      tooltip:
        'eCPM, or effective cost per thousand impressions, is a metric that measures the revenue generated for every one thousand ad impressions served to users in your apps integrated with MAS. The eCPM is the weighted average of each ad network’s eCPM.',
    },
  ],
  chartTabDataLoading: false,
  dataLoading: false,
  configLoading: false,
  openConfigTitle: false,
  configTitleLoading: false,
  deleteConfigLoading: false,
  countryChecked: [],
  countryIsFilter: false,
  checkedMetrics: ['revenue', 'impressions', 'ecpm'],
  summaryGroupBy: 'all',
  resetIndex: 0,
  commentsEvent: {},
}
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'time':
    case 'timeShow':
    case 'gameSelect':
    case 'country':
    case 'type':
    case 'view':
    case 'gameOption':
    case 'gameListObject':
    case 'countryOption':
    case 'countryListObject':
    case 'chartType':
    case 'platform':
    case 'data':
    case 'chartTabDataLoading':
    case 'dataLoading':
    case 'configLoading':
    case 'openConfigTitle':
    case 'configTitleLoading':
    case 'deleteConfigLoading':
    case 'countryChecked':
    case 'countryIsFilter':
    case 'checkedMetrics':
    case 'summaryGroupBy':
    case 'resetIndex':
    case 'commentsEvent':
      return { ...prevState, [action.type]: action.payload }
    case 'chartTabData':
      const chartTabTemp = action?.payload?.all || {}
      const disabled = action?.payload?.tabDisabled || false
      const untilTime = action.payload?.untilTime || null
      return {
        ...prevState,
        chartTabData: [
          {
            name: 'Est. Revenue',
            value: 'revenue',
            disabled,
            untilTime,
            num:
              '$' +
              toThousands(
                tabMetricFormat(chartTabTemp?.revenue?.summary || 0, 'revenue'),
                true
              ),
            tooltip:
              'Revenue generated by your apps integrated with MAS. This may differ from final revenue due to deductions made by ad networks during reconciliation.',
          },
          {
            name: 'Impressions',
            value: 'impressions',
            disabled,
            untilTime,
            num: toThousands(chartTabTemp.impressions?.summary || '0', true),
            tooltip:
              'Impressions are the number of times ads are displayed to users in your apps integrated with MAS.',
          },
          {
            name: 'eCPM',
            value: 'ecpm',
            disabled,
            untilTime,
            num:
              '$' +
              toThousands(
                tabMetricFormat(chartTabTemp.ecpm?.summary || 0, 'ecpm'),
                true
              ),
            tooltip:
              'eCPM, or effective cost per thousand impressions, is a metric that measures the revenue generated for every one thousand ad impressions served to users in your apps integrated with MAS. The eCPM is the weighted average of each ad network’s eCPM.',
          },
        ],
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
  searchData: (view?: string) => void
  addChartConfig: (title: string, index: number) => void
  putChartConfig: (title: string, index: number) => void
  deleteChartConfig: (index: number) => void
  setDefaultConfig: (index: number) => void
  searchDefaultData: (data: any) => void
}

export const PerformanceContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
  searchData: () => {},
  addChartConfig: () => {},
  putChartConfig: () => {},
  deleteChartConfig: () => {},
  setDefaultConfig: () => {},
  searchDefaultData: () => {},
})

export const PerformanceContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const [t] = useTranslation()
  const {
    state: globalState,
    dispatch: globalDispatch,
    errorCatch,
    customAlert,
  } = useContext(GlobalContext)
  const {
    state: globalStoreState,
    dispatch: globalStoreDispatch,
    finishToPage,
  } = useContext(GlobalStoreContext)

  // effects
  // 获取总的游戏列表
  useEffect(() => {
    dispatch({ type: 'gameListObject', payload: globalState.gameListObject })
    dispatch({
      type: 'gameOption',
      payload: globalState.gameOption,
    })
    // eslint-disable-next-line
  }, [globalState.gameListObject])
  // 获取国家列表
  useEffect(() => {
    dispatch({
      type: 'countryListObject',
      payload: globalState.countryListObject,
    })
    dispatch({ type: 'countryOption', payload: globalState.countryOption })
    // eslint-disable-next-line
  }, [globalState.countryListObject])
  // 点击top filter中apply filter按钮触发（不传参数）； 传参为summary表格中切换view by
  const searchData = () => {
    const data = {
      from_date: state.time[0],
      to_date: state.time[1],
      filter: {
        app_keys: state.gameSelect.map(item => item.app_key),
        ad_types: state.type.map(item => item.value),
        platform: state.platform.map(item => item.value),
        country_code: state.countryChecked,
      },
      view_by: state.view === 'all' ? null : state.view,
    }
    globalStoreDispatch({
      type: 'performanceData',
      payload: {
        filter: {
          time: state.time,
          timeShow: state.timeShow,
          gameSelect: state.gameSelect,
          platform: state.platform,
          type: state.type,
          countryChecked: state.countryChecked,
          country: state.country,
          view: state.view,
          checkedMetrics: state.checkedMetrics,
        },
      },
    })
    getData('data', data)
    getData('chartTabData', {
      ...data,
      view_by: null,
    })
  }
  // 切换config按钮触发
  const searchDefaultData = (filterData: any) => {
    const data = filterData.data
    // 修改config后发送请求，判断时间范围类型取不同时间
    if (filterData.custom_date !== 'custom') {
      const time = getDateRangeFromValue(filterData.custom_date)
      data.from_date = time[0]
      data.to_date = time[1]
    }
    // 修改筛选条件为 globalStoreState.fromWelcome 带过来的值
    if (globalStoreState.fromWelcome) {
      data.from_date = globalStoreState.time[0]
      data.to_date = globalStoreState.time[1]
      data.filter.app_keys = globalStoreState.gameSelect.map(
        item => item.app_key
      )
      dispatch({
        type: 'time',
        payload: globalStoreState.time,
      })
      dispatch({
        type: 'timeShow',
        payload: 'custom',
      })

      dispatch({
        type: 'gameSelect',
        payload: globalStoreState.gameSelect,
      })
    }
    globalStoreDispatch({
      type: 'performanceData',
      payload: {
        filter: {
          time: globalStoreState.fromWelcome
            ? globalStoreState.time
            : [data.from_date, data.to_date],
          timeShow: globalStoreState.fromWelcome
            ? 'custom'
            : filterData.custom_date,
          gameSelect: globalStoreState.fromWelcome
            ? globalStoreState.gameSelect
            : state.gameSelect,
          platform: state.platform,
          type: state.type,
          countryChecked: state.countryChecked,
          country: state.country,
          view: data.view_by || 'all',
          checkedMetrics: state.checkedMetrics,
        },
      },
    })
    getData('data', data)
    getData('chartTabData', {
      ...data,
      view_by: null,
    })
  }

  // 获取MO event
  const getCommentsEvent = () => {
    getCommentsEventApi({
      app_keys: state.gameSelect.map(item => item.app_key),
    })
      .then((res: any) => {
        dispatch({
          type: 'commentsEvent',
          payload: res.data.data,
        })
        globalStoreDispatch({
          type: 'performanceData',
          payload: {
            commentsEvent: res.data.data,
          },
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        globalStoreDispatch({
          type: 'performanceData',
          payload: {
            commentsEvent: {},
          },
        })
      })
  }
  // initialStateKey  判断将data存储位置，data为中间chart和底部summary chartTabData为chart顶部的值
  // 获取overview页面数据
  const getData = (initialStateKey: 'data' | 'chartTabData', data?: any) => {
    if (state[`${initialStateKey}Loading`]) return
    if (initialStateKey === 'data') {
      getCommentsEvent()
    }
    dispatch({ type: `${initialStateKey}Loading`, payload: true })
    getEchartApi(data)
      .then((res: any) => {
        if (initialStateKey === 'data') {
          dispatch({
            type: 'countryIsFilter',
            payload: data.filter.country_code.length > 0,
          })
          dispatch({
            type: 'summaryGroupBy',
            payload: data.view_by,
          })
          globalStoreDispatch({
            type: 'performanceData',
            payload: {
              countryIsFilter: data.filter.country_code.length > 0,
              summaryGroupBy: data.view_by,
            },
          })
        }
        dispatch({
          type: initialStateKey,
          payload: res.data.data,
        })
        globalStoreDispatch({
          type: 'performanceData',
          payload: {
            [initialStateKey]: res.data.data,
          },
        })
        if (globalStoreState.fromWelcome) {
          finishToPage()
        }
      })
      .catch((err: any) => {
        if (initialStateKey === 'data') {
          globalStoreDispatch({
            type: 'performanceData',
            payload: {
              countryIsFilter: false,
            },
          })
        }
        if (
          initialStateKey === 'chartTabData' &&
          err?.response?.status === 503 &&
          err?.response?.data?.code === 'trino_migration'
        ) {
          let untilTime = null
          if (err?.response?.data?.extra?.after) {
            untilTime = transformUTC0ToLocalTime(
              err?.response?.data?.extra?.after,
              'Do MMM YYYY hh:mm a'
            )
          }
          globalStoreDispatch({
            type: 'performanceData',
            payload: {
              [initialStateKey]: {
                tabDisabled: true,
                untilTime,
              },
            },
          })
          dispatch({
            type: initialStateKey,
            payload: {
              tabDisabled: true,
              untilTime,
            },
          })
        } else {
          errorCatch(err)
          globalStoreDispatch({
            type: 'performanceData',
            payload: {
              [initialStateKey]: {},
            },
          })
        }
      })
      .finally(() => {
        // 不加延时则loading结束后会有数据闪烁，因为前端数据循环的时间差
        setTimeout(() => {
          dispatch({ type: `${initialStateKey}Loading`, payload: false })
        })
      })
  }
  // dashboard中view config 增删改
  const updateConfig = (configData: any, index?: number) => {
    configData.default_selected++
    configData.data = [
      {
        custom_date: 'last_week',
        data: {
          filter: {
            ad_types: [],
            app_keys: [],
            platform: [],
            owner_keys: [],
            country_code: [],
          },
          from_date: '2022-01-05',
          to_date: '2022-01-11',
          view_by: null,
        },
        title: 'Default Report',
      },
      ...configData.data,
    ]
    configData.noUpdate = true
    if (index !== undefined) {
      configData.index = index
    }
    globalDispatch({
      type: 'CHART_CONFIG',
      payload: configData,
    })
  }
  const addChartConfig = (title: string, index: number) => {
    if (state.configTitleLoading) return
    dispatch({ type: 'configTitleLoading', payload: true })
    const data = {
      title,
      data: {
        from_date: state.time[0],
        to_date: state.time[1],
        filter: {
          app_keys: state.gameSelect.map(item => item.app_key),
          ad_types: state.type.map(item => item.value),
          platform: state.platform.map(item => item.value),
          country_code: state.countryChecked,
        },
        view_by: state.view === 'all' ? null : state.view,
      },
      custom_date: state.timeShow,
    }
    addChartConfigApi(data)
      .then(res => {
        updateConfig(res.data, index)
        customAlert('success', t('common.success'))
        dispatch({ type: 'openConfigTitle', payload: false })
      })
      .catch(errorCatch)
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: 'configTitleLoading', payload: false })
        }, 500)
      })
  }
  const putChartConfig = (title: string, index: number) => {
    if (state.configLoading) return
    dispatch({ type: 'configLoading', payload: true })
    putChartConfigApi({
      index: index - 1,
      data: {
        title,
        data: {
          from_date: state.time[0],
          to_date: state.time[1],
          filter: {
            app_keys: state.gameSelect.map(item => item.app_key),
            ad_types: state.type.map(item => item.value),
            platform: state.platform.map(item => item.value),
            country_code: state.countryChecked,
          },
          view_by: state.view === 'all' ? null : state.view,
        },
        custom_date: state.timeShow,
      },
    })
      .then(res => {
        updateConfig(res.data, index)
        customAlert('success', t('common.success'))
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'configLoading', payload: false })
      })
  }
  const deleteChartConfig = (index: number) => {
    if (state.deleteConfigLoading) return
    dispatch({ type: 'deleteConfigLoading', payload: true })
    deleteChartConfigApi(index - 1)
      .then(res => {
        updateConfig(res.data)
        customAlert('success', t('common.success'))
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'deleteConfigLoading', payload: false })
      })
  }
  const setDefaultConfig = (index: number) => {
    if (state.configLoading) return
    dispatch({ type: 'configLoading', payload: true })
    setDefaultConfigApi(index - 1)
      .then(res => {
        updateConfig(res.data)
        customAlert('success', t('common.success'))
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'configLoading', payload: false })
      })
  }
  useEffect(() => {
    if (state.resetIndex) {
      searchData()
    }
    // eslint-disable-next-line
  }, [state.resetIndex])
  // returns
  return (
    <PerformanceContext.Provider
      value={{
        state,
        dispatch,
        searchData,
        addChartConfig,
        putChartConfig,
        deleteChartConfig,
        setDefaultConfig,
        searchDefaultData,
      }}
    >
      {children}
    </PerformanceContext.Provider>
  )
}
