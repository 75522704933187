import { Box } from '@material-ui/core'
import { SVGIcon } from 'src/images'
import { useNavigate } from 'react-router-dom'
import { AdaptiveTooltip } from 'src/components'

const labelObject = {
  banner: 'Banner',
  interstitial: 'Interstitial',
  reward_video: 'Rewarded Video',
  nativeads: 'Native Ads',
  openads: 'App Open',
  rewardinter: 'Rewarded Interstitial',
}
const iconObject = {
  banner: SVGIcon.bannerInnerSVG,
  interstitial: SVGIcon.interstitialInnerSVG,
  reward_video: SVGIcon.reward_videoInnerSVG,
  nativeads: SVGIcon.native_adsInnerSVG,
  openads: SVGIcon.OpenAdsInnerSVG,
  rewardinter: SVGIcon.RewardinterInnerSVG,
}
const ActiveAdsView = (props: any) => {
  const { ads_format = [] } = props
  const navigate = useNavigate()

  const ActiveAdsItem = (itemProps: any) => {
    const { value } = itemProps
    const Icon = iconObject[value]
    return (
      <AdaptiveTooltip
        isMobile={props.isMobile}
        title={`${labelObject[value]} (${
          ads_format.includes(value) ? 'Enabled' : 'Disabled'
        })`}
        arrow
        placement="top"
        disableInteractive
      >
        <Box>
          <Icon style={ads_format.includes(value) ? {} : { opacity: 0.3 }} />
        </Box>
      </AdaptiveTooltip>
    )
  }

  return (
    <Box
      display="flex"
      justifyContent={props.isMobile ? 'flex-start' : 'center'}
      alignItems="center"
      gap="10px"
      flexWrap="wrap"
      paddingTop="2px"
      onClick={() => {
        const url = `/dash/games/manage/${props.app_key}/ads-management`
        navigate(url)
      }}
      sx={{ cursor: 'pointer' }}
    >
      <ActiveAdsItem value="banner" />
      <ActiveAdsItem value="interstitial" />
      <ActiveAdsItem value="reward_video" />
      <ActiveAdsItem value="nativeads" />
      <ActiveAdsItem value="openads" />
      <ActiveAdsItem value="rewardinter" />
    </Box>
  )
}

export default ActiveAdsView
