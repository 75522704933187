import { useEffect, useRef, useContext } from 'react'
import { Box } from '@material-ui/core'
import { init, EChartsType } from 'echarts'
import { AdPlacementsContext } from 'src/context/adPlacement'
import { getEchartOptions, sortCompare, toPercent } from 'src/utils'
import { useTheme, Theme } from '@material-ui/core'

const View = (props: any) => {
  const chart = useRef<EChartsType>()
  const theme: Theme = useTheme()
  const { state } = useContext(AdPlacementsContext)

  // effects
  useEffect(() => {
    const data = state.chartData?.data?.filter((item: any) =>
      state.checkedChartLine.includes(item.name)
    )
    const options = getEchartOptions(
      state.chartData.days,
      data,
      state.newChartType,
      theme.palette.mode
    )
    if (state.chartType === 'ad_viewer_rate') {
      options.tooltip.formatter = (info: any) => {
        // 百分比时的tooltip处理  此处用函数方式是因为有可能是多条折线
        let content = ''
        let name = ''
        let showTooltip = false
        const emptyList = info.filter(
          (item: { value: number | undefined | null }) => {
            return !item.value && item.value !== 0
          }
        )
        let allList = []
        if (emptyList.length) {
          allList = [
            ...sortCompare(
              info.filter((item: { value: number | undefined | null }) => {
                return item.value || item.value === 0
              }),
              'value',
              'descending'
            ),
            ...emptyList,
          ]
        } else {
          allList = sortCompare(info, 'value', 'descending')
        }
        allList.forEach((item: any) => {
          if (item?.data) {
            showTooltip = true
          }
          content += `<div style="display: flex; justify-content: space-between; font-size: 12px;"><div>${
            item.marker
          } ${
            item.seriesName
          }</div><div style="font-weight: 600; margin-left: 20px;">${
            item.value ? toPercent(item.value) : '-'
          }</div> </div>`
          name = item.name
        })
        if (showTooltip) {
          return `<span style='font-size: 14px;'>${name}</span> <br/> ${content}`
        }
        return ''
      }
      options.yAxis.axisLabel.formatter = (value: any) => {
        return toPercent(value)
      }
    }
    if (!chart.current) {
      const chartEl: HTMLElement | null =
        document.getElementById('echartRevenue')
      if (chartEl) {
        chart.current = init(chartEl)
      }
    }
    chart.current?.setOption(options, true)
    chart.current?.resize()
    // eslint-disable-next-line
  }, [
    state.chartData,
    state.newChartType,
    theme.palette.mode,
    state.checkedChartLine,
  ])

  useEffect(() => {
    // Bind the resize method to the window
    window.addEventListener('resize', () => {
      chart.current?.resize()
    })

    return function willUnmount() {
      if (chart.current) {
        chart.current.clear()
        chart.current.dispose()
        chart.current = undefined
      }
    }
  }, [])

  // returns
  return (
    <Box
      id="echartRevenue"
      width="100%"
      height="398px"
      boxSizing="border-box"
    />
  )
}

export default View
