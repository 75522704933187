import { Box, TextField, Button, useTheme, Theme } from '@material-ui/core'
import { Lock as LockIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { PasswordForm, PasswordFormKey } from 'src/context/type'
import { useContext } from 'react'
import { GlobalContext } from 'src/context/global'
import { LoadingBtn } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      inputLabel: {
        color: theme.palette.custom.inputLabel,
        fontSize: 12,
        marginBottom: 8,
      },
      backButton: {
        width: '47% !important',
        height: '36px !important',
        padding: '0',
      },
      saveButton: {
        width: '47% !important',
        height: '36px !important',
        padding: '0',
        '&.MuiButton-contained.Mui-disabled': {
          backgroundColor: '#29444f !important',
        },
      },
    }
  })

const View = (): JSX.Element => {
  const [t] = useTranslation()
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const className = useStyles()
  const { personalPasswordChange, state, setAccountEditKeyHandle } =
    useContext(GlobalContext)
  const passwordData = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  // form check
  const presonalFormSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(t('setting.password.passwordRequiredErrMsg'))
      .min(6, t('setting.password.passwordMinMaxErrMsg'))
      .max(20, t('setting.password.passwordMinMaxErrMsg')),
    newPassword: yup
      .string()
      .required(t('setting.password.passwordRequiredErrMsg'))
      .min(6, t('setting.password.passwordMinMaxErrMsg'))
      .max(20, t('setting.password.passwordMinMaxErrMsg')),
    confirmPassword: yup
      .string()
      .required(t('setting.password.passwordRequiredErrMsg'))
      .min(6, t('setting.password.passwordMinMaxErrMsg'))
      .max(20, t('setting.password.passwordMinMaxErrMsg')),
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<PasswordForm>({
    resolver: yupResolver(presonalFormSchema),
  })

  const saveChange = (data: PasswordForm) => {
    if (data.newPassword !== data.confirmPassword) {
      setError('confirmPassword', {
        message: t('setting.password.passwordConfirmErrMsg'),
      })
      return
    }

    personalPasswordChange({
      passwd: data.currentPassword,
      new_passwd: data.newPassword,
    })
  }

  // views
  const passwordInput = (key: PasswordFormKey) => (
    <Controller
      name={key}
      control={control}
      defaultValue={passwordData[key]}
      render={({ field }) => (
        <TextField
          fullWidth
          type="password"
          placeholder={t(`setting.password.${key}`)}
          {...field}
          error={!!errors[key]}
          helperText={errors[key]?.message}
          variant="outlined"
          InputProps={{
            startAdornment: <LockIcon />,
          }}
        />
      )}
    />
  )

  return (
    <>
      <form>
        <Box>
          <Box className={className.inputLabel}>Current Password</Box>
          {passwordInput('currentPassword')}
        </Box>

        <Box marginTop="16px">
          <Box className={className.inputLabel}>New Password</Box>
          {passwordInput('newPassword')}
        </Box>

        <Box marginTop="16px">
          <Box className={className.inputLabel}>Confirm Password</Box>
          {passwordInput('confirmPassword')}
        </Box>
      </form>
      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <Button
          className={className.backButton}
          onClick={() => setAccountEditKeyHandle('')}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          disabled={state.buttonLoading}
          className={className.saveButton}
          color="primary"
          variant="contained"
          onClick={handleSubmit(saveChange)}
        >
          {state.buttonLoading ? (
            <LoadingBtn
              loadingStyle={{
                position: 'absolute',
                width: '100%',
                justifyContent: 'center',
              }}
            />
          ) : (
            'Update'
          )}
        </Button>
      </Box>
    </>
  )
}

export default View
