import { portalService } from './service'
import {
  RegisterBody,
  VerifyEmailBody,
  ResetPasswordBody,
  ResetPasswordEmailBody,
} from 'src/api/type'

export const registerApi = (data: RegisterBody) => {
  return portalService({
    url: '/user/signup',
    method: 'post',
    data,
  })
}

/**
 * Resend Verify Email
 * @returns
 */
export const resendVerifyEmailApi = (email: string) => {
  return portalService({
    url: '/user/resend_verify_email',
    method: 'post',
    data: { email },
  })
}

/**
 * Verify Email
 * @param data
 * @returns
 */
export const verifyEmailApi = (data: VerifyEmailBody) => {
  return portalService({
    url: '/user/verify_email',
    method: 'post',
    data,
  })
}

/**
 * Forgot password
 * @param data
 * @returns
 */
export const resetPasswordEmailApi = (data: ResetPasswordEmailBody) => {
  return portalService({
    url: '/user/reset_password_email',
    method: 'post',
    data,
  })
}

/**
 * Reset password
 * @param data
 * @returns
 */
export const resetPasswordApi = (data: ResetPasswordBody) => {
  return portalService({
    url: '/user/reset_password',
    method: 'post',
    data,
  })
}
