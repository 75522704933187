const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4265 9.58154L12.1409 10.7236L9.28323 12.4383L7.56856 10.7236L5.28308 13.01V14.7242H18.4265V9.58154Z"
        fill={color}
      />
      <path
        d="M9.46063 10.9954L11.7313 9.63421L18.4265 8.41669V5.57837L12.1409 6.72133L9.85494 8.436L7.56856 6.72133L5.28308 8.436V11.3911L7.56856 9.10516L9.46063 10.9954Z"
        fill={color}
      />
      <path
        d="M18.4293 15.857H4.14252V5.57081C4.14252 5.25509 3.88653 5 3.57126 5C3.25599 5 3 5.25509 3 5.57081V15.857C3 16.4867 3.51287 17 4.14296 17H18.4302C18.7451 17 19.0015 16.7449 19.0015 16.4292C19.0019 16.1135 18.7442 15.857 18.4293 15.857Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
