import { makeStyles } from '@material-ui/styles'
import { motion } from 'framer-motion'
import { Icons } from 'src/images'

/**
 * framer-motion docs:
 * https://www.framer.com/api/motion/examples/#more
 */

const useStyles = makeStyles(() => ({
  container: {
    height: '20px',
    width: '20px',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
}))

const Component = (props: any) => {
  const classes = useStyles()

  return (
    <motion.div
      className={classes.container}
      animate={{
        rotate: [0, 90, 180, 270, 360],
      }}
      transition={{
        duration: 3.6,
        repeat: Infinity,
      }}
    >
      <img src={Icons.loading} alt="" className={classes.icon} />
    </motion.div>
  )
}

export default Component
