import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { EngagementContext } from 'src/context/engagement'
import { GlobalStoreContext } from 'src/context/globalStore'
import {
  StylePaper,
  LoadingComponent,
  ReportsTab,
  ChartTypeToggleGroup,
} from 'src/components'
import EngagementChartView from './engagementChart'
import TopFilterView from './topFilter'
import ChartSettingView from './chartSetting'
import { rateTitleFormat } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })
const titles = {
  rate: 'Ad Engagement Rate',
  deu: 'DAV',
  imp_deu: rateTitleFormat('Imp', 'DAV'),
  revenue_deu: rateTitleFormat('Revenue', 'DAV'),
}
const EngagementView = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const { state, dispatch, changeTabType } = useContext(EngagementContext)
  const { state: GlobalStoreState, finishToPage } =
    useContext(GlobalStoreContext)

  const tabsRevenue = useMemo(() => {
    if (GlobalStoreState.fromWelcome) {
      finishToPage()
    }
    const tabDisabled =
      state.revenueData?.relative_change?.revenue_deu?.disabled || false
    const tabUntilTime =
      state.revenueData?.relative_change?.revenue_deu?.untilTime || null
    const revenue_deu = {
      label: rateTitleFormat('Revenue', 'DAV'),
      key: 'revenue_deu',
      value: '$0',
      disabled: tabDisabled,
      untilTime: tabUntilTime,
      trend: 'up',
      tip: 'Revenue/DAV, or Revenue per Daily Ad Viewers, indicates the average revenue per unique user who viewed one or more ads of the ad type you selected for this report.',
    }
    if (state.revenueData?.relative_change) {
      return [
        { ...revenue_deu, ...state.revenueData.relative_change.revenue_deu },
      ]
    } else {
      return [revenue_deu]
    }
    // eslint-disable-next-line
  }, [state.revenueData])
  const tabs = useMemo(() => {
    if (GlobalStoreState.fromWelcome) {
      finishToPage()
    }
    const disabled = state.holdData?.tabDisabled || false
    const untilTime = state.holdData?.untilTime || null
    const rate = {
      label: 'Ad Engagement Rate',
      key: 'rate',
      value: '0',
      disabled,
      untilTime,
      trend: 'up',
      tip: "Ad engagement rate is a metric that measures the level of interaction and involvement users have with an ad, indicating the effectiveness of the ad in capturing and retaining users' attention. It is the % of ads viewed by daily active users.",
    }
    const dav = {
      label: 'DAV',
      key: 'deu',
      value: '0',
      disabled,
      untilTime,
      trend: 'up',
      tip: 'DAV, or Daily Ad Viewers, indicates the average number of unique users who viewed one or more ads of the ad type you selected for this report.',
    }
    const imp_deu = {
      label: rateTitleFormat('Imp', 'DAV'),
      key: 'imp_deu',
      value: '0',
      disabled,
      untilTime,
      trend: 'up',
      tip: 'Imp/DAV, or Impressions per Daily Ad Viewers, indicates the average number of impressions per unique user who viewed one or more ads of the ad type you selected for this report.',
    }
    if (state.holdData?.relative_change) {
      return [
        { ...rate, ...state.holdData.relative_change.engagement_rate },
        { ...dav, ...state.holdData.relative_change.deu },
        { ...imp_deu, ...state.holdData.relative_change.imp_deu },
      ]
    } else {
      return [rate, dav, imp_deu]
    }
    // eslint-disable-next-line
  }, [state.holdData])
  const options = useMemo(() => {
    return [...tabs, ...tabsRevenue]
  }, [tabs, tabsRevenue])
  const handleClick = (tabType: string) => {
    changeTabType(tabType)
    navigate(`/dash/engagement/${tabType}`)
  }

  // returns
  return (
    <>
      <TopFilterView />
      <Box padding="20px 24px 100px">
        <Box width="100%" marginTop="24px" position="relative">
          {state.loading && (
            <Box className={classes.loadingMask}>
              <LoadingComponent />
            </Box>
          )}
          {/* tabs */}
          <Box width="100%">
            <ReportsTab
              value={state.tabType}
              labelKey="label" // tab名称
              valueKey="key" // tab选择的value
              numKey="value" // tab中的num值
              tooltipKey="tip" // tab中的tooltip
              showTrend // 是否展示趋势
              options={options}
              onChange={handleClick}
            />
          </Box>
          {/* content */}
          <StylePaper
            width="100%"
            padding="24px 16px"
            boxSizing="border-box"
            borderRadius="0 8px 8px"
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  fontSize: '20px',
                  lineHeight: '36px',
                  fontWeight: 500,
                  padding: '0 4px',
                }}
              >
                <Box marginRight="16px">{titles[state.tabType]}</Box>
                <ChartSettingView />
              </Box>
              <ChartTypeToggleGroup
                value={state.newChartType}
                onChange={(chartType: string) => {
                  dispatch({ type: 'newChartType', payload: chartType })
                }}
              />
            </Box>
            <Box marginTop="26px">
              <EngagementChartView id="chart" width="100" />
            </Box>
          </StylePaper>
        </Box>
      </Box>
    </>
  )
}

export default EngagementView
