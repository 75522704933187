import Dashboard from './userActivity'
import { PerformanceContextProvider } from 'src/context/userActivity'

const Dash = (): JSX.Element => {
  // returns
  return (
    <PerformanceContextProvider>
      <Dashboard />
    </PerformanceContextProvider>
  )
}

export default Dash
