import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  InputBase,
  useTheme,
  Theme,
} from '@material-ui/core'
import { MouseEvent, useContext, useMemo, useState, useEffect } from 'react'
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons'
import { DashContext } from 'src/context/dashboard'
import { SVGIcon } from 'src/images'

const TableView = () => {
  const theme: Theme = useTheme()
  const { state, dispatch } = useContext(DashContext)
  const [showListNumber, setShowListNumber] = useState<number>(7)
  const [searchValue, setSearchValue] = useState<string>('')
  const showList = useMemo(() => {
    if (searchValue) {
      const searchList = state.chartList.filter((item: any) =>
        item.label.toUpperCase().includes(searchValue.toUpperCase())
      )
      return searchList
    }
    if (state.view === 'country' && showListNumber < state.chartList.length) {
      const temp = state.chartList.slice(0, showListNumber)
      if (showListNumber > 7) {
        const newTemp = [
          ...state.checkedChartLine,
          ...state.chartList
            .slice(showListNumber - 7, showListNumber)
            .map((item: any) => item.label),
        ]
        dispatch({
          type: 'checkedChartLine',
          payload: newTemp,
        })
      }
      return temp
    }
    return state.chartList
    // eslint-disable-next-line
  }, [state.chartList, state.view, showListNumber, searchValue])

  const searchChange = (e: any) => {
    setSearchValue(e.target.value)
  }
  const handleClick = (event: MouseEvent<unknown>, value: string) => {
    const selectedIndex = state.checkedChartLine.indexOf(value)
    let newSelected: string[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.checkedChartLine, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.checkedChartLine.slice(1))
    } else if (selectedIndex === state.checkedChartLine.length - 1) {
      newSelected = newSelected.concat(state.checkedChartLine.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.checkedChartLine.slice(0, selectedIndex),
        state.checkedChartLine.slice(selectedIndex + 1)
      )
    }
    dispatch({
      type: 'checkedChartLine',
      payload: newSelected,
    })
  }
  useEffect(() => {
    if (state.view === 'country') {
      setShowListNumber(7)
    }
    setSearchValue('')
  }, [state.view, state.chartList])
  return (
    <Box height="100%">
      <Box position="relative" marginBottom="12px">
        <InputBase
          placeholder="Search"
          value={searchValue}
          onChange={searchChange}
          style={{
            width: '100%',
            boxSizing: 'border-box',
            height: '32px',
            background: theme.palette.custom.dialogBg,
            border: `1px solid ${theme.palette.custom.paperBorder}`,
            borderRadius: '6px',
            boxShadow: 'none',
          }}
        />
        <SVGIcon.SearchIconSVG
          style={{
            position: 'absolute',
            right: '10px',
            top: '8px',
          }}
        />
      </Box>
      <Box height="calc(100% - 44px)" overflow="auto">
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {showList.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={event => handleClick(event, row.value)}
                  role="checkbox"
                  aria-checked={state.checkedChartLine.includes(row.value)}
                  tabIndex={-1}
                  selected={state.checkedChartLine.includes(row.value)}
                  style={{
                    cursor: 'pointer',
                    background: 'transparent',
                    borderBottom: `1px solid ${
                      theme.palette.mode === 'light' ? '#7487AA' : '#889BBE'
                    }`,
                  }}
                >
                  <TableCell padding="checkbox" style={{ width: '36px' }}>
                    <Checkbox
                      color="primary"
                      checked={state.checkedChartLine.includes(row.value)}
                      style={{
                        color: theme.palette.primary.main,
                        width: 36,
                        height: 36,
                      }}
                    />
                  </TableCell>
                  {/* <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: 0, width: 20 }}
                  >
                    <Box
                      sx={{
                        width: '20px',
                        height: '10px',
                        background: row.color,
                      }}
                    />
                  </TableCell> */}
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: theme.palette.custom.inputLabel,
                      fontSize: 12,
                      padding: '10px 12px 10px 6px',
                      lineHeight: '16px',
                    }}
                  >
                    {row.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {state.view === 'country' &&
            !searchValue &&
            state.chartList.length > 1 &&
            showListNumber < state.chartList.length && (
              <Box
                sx={{
                  fontSize: 12,
                  color: theme.palette.custom.dialogButton,
                  textAlign: 'center',
                  padding: '6px 0 1px',
                }}
              >
                <Box
                  component="span"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowListNumber(showListNumber + 7)
                  }}
                >
                  Load more
                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: '12px !important',
                      transform: 'rotate(90deg)',
                      marginLeft: '4px',
                      verticalAlign: 'middle',
                      color: theme.palette.custom.dialogButton,
                    }}
                  />
                </Box>
              </Box>
            )}
        </TableContainer>
      </Box>
    </Box>
  )
}
export default TableView
