import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Button,
  Box,
  Link,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from 'src/context/auth'
import { GlobalContext } from 'src/context/global'
import {
  AuthPromptDialog,
  LoadingComponent,
  OpenInNewIconView,
  AuthPageInput,
} from 'src/components'
import 'react-phone-number-input/style.css'
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input'
import { getQueryParams } from 'src/utils'
import { Icons } from 'src/images'

interface FormValues {
  email: string
  password: string
  password_confirm: string
  company: string
  firstName: string
  lastName: string
  website_url: string
}

const getUseStyles = () =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
      },
      desc: {
        fontSize: '12px',
        lineHeight: '16px',
        marginInlineEnd: '5px !important',
        color: '#1D3353',
        userSelect: 'none',
      },
      termsLink: {
        fontSize: '12px',
        wordBreak: 'break-word',
      },
      title: {
        lineHeight: '34px',
        textAlign: 'center',
        color: '#1D3353',
        fontWeight: 600,
        fontSize: '18px',
        marginBottom: '20px',
      },
      signIn: {
        fontWeight: 600,
        marginLeft: '6px',
      },
      phoneNumber: {
        marginBottom: '6px',
        '& .PhoneInputInput': {
          height: '32px',
          border: '1px solid #D2D6DD',
          outline: 'none',
          borderRadius: '4px',
          background: '#EEF1F5',
          color: '#102542',
          paddingLeft: '12px',
        },
        '& .PhoneInputCountrySelectArrow': {
          width: '4px',
          height: '4px',
          marginLeft: '5px',
          border: '1px solid #D2D6DD',
          borderTopWidth: 0,
          borderLeftWidth: 0,
          transform: 'rotate(45deg)',
          opacity: 0.8,
        },
        '& .PhoneInputCountryIcon': {
          width: '24px',
          height: '16px',
          color: '#102542',
        },
      },
    }
  })

let timer: any = null
const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles()
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [t] = useTranslation()
  const { state, register, setOpenModel } = useContext(AuthContext)
  const { dispatch: globalDispatch } = useContext(GlobalContext)
  const FormSchema = yup.object().shape({
    email: yup.string().email().required(t('auth.login.emailErrMsg')),
    password: yup.string().min(6, t('auth.login.passwordMinMsg')).max(20),
    password_confirm: yup
      .string()
      .min(6, t('auth.login.passwordMinMsg'))
      .max(20),
    company: yup.string().required(t('auth.signup.componyNameErrMsg')),
    firstName: yup
      .string()
      .required(t('auth.signup.firstNameErrMsg'))
      .min(2, t('auth.signup.firstNameMinMsg')),
    lastName: yup
      .string()
      .required(t('auth.signup.lastNameErrMsg'))
      .min(2, t('auth.signup.lastNameMinMsg')),
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
  })
  // 是否勾选COPPA
  const [checkCOPPA, setCheckCOPPA] = useState(false)
  const onChangeCheckCOPPA = () => {
    setCheckCOPPA(!checkCOPPA)
  }

  const [owner_tel, setOwner_tel] = useState<string>('')
  const [telError, setTelError] = useState<boolean>(false)
  // 是否勾选
  const [checkAgreement, setCheckAgreement] = useState(false)
  const onChangeCheckAgreement = () => {
    setCheckAgreement(!checkAgreement)
  }

  const [email, setEmail] = useState('')

  const locationSearch = location.search
    .slice(1)
    .split('&')
    .reduce((prev: Record<string, unknown>, current: string) => {
      const [keyTemp, val] = current.split('=')
      const key = keyTemp === 'token' ? 'verify_code' : keyTemp

      return {
        ...prev,
        [key]: val,
      }
    }, {})
  // handlers
  const onSubmit = (data: FormValues) => {
    const {
      email,
      password,
      password_confirm,
      company,
      firstName,
      lastName,
      website_url,
    } = data
    setEmail(data.email)
    if (password_confirm !== password) {
      setError('password_confirm', {
        message: t('setting.password.passwordConfirmErrMsg'),
      })
      return
    }
    if (!isPossiblePhoneNumber(owner_tel)) {
      setTelError(true)
      return
    }
    const temp: any = {
      name: firstName + ' ' + lastName,
      email,
      passwd: password,
      lang: 'en',
      owner_name: company,
      owner_website: website_url,
      owner_tel:
        '+' +
        (parsePhoneNumber(owner_tel) as any).countryCallingCode +
        '-' +
        (parsePhoneNumber(owner_tel) as any).nationalNumber,
    }
    register(temp)
  }

  const dialogConfirm = () => {
    setOpenModel(false)
    navigate('/email-verification?email=' + email)
  }

  useEffect(() => {
    if (locationSearch.referral_code) {
      globalDispatch({
        type: 'SHOW_REFERRAL_CLOSED_TIP',
        payload: true,
      })
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        globalDispatch({
          type: 'SHOW_REFERRAL_CLOSED_TIP',
          payload: false,
        })
        clearTimeout(timer)
        timer = null
      }, 8000)
    }
    if (process.env.REACT_APP_ENV !== 'product') return
    const fbq: any = (Window as any).fbq
    fbq && fbq('init', '492576915282626')
    fbq && fbq('track', 'MasPortal_EnterRegistrationPage')
    if (location.search) {
      const temp = getQueryParams(location.search)
      const params = []
      if (temp.utm_source) {
        params.push('utm_source=' + temp.utm_source)
      }
      if (temp.utm_medium) {
        params.push('utm_medium=' + temp.utm_medium)
      }
      if (temp.utm_campaign) {
        params.push('utm_campaign=' + temp.utm_campaign)
      }
      if (params.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const gtag: any = window.gtag
        gtag &&
          gtag('event', 'RegisterPageUTM', {
            full_path: params.join('&'),
            timestamp: new Date().toISOString(),
          })

        params.push('timestamp=' + new Date().toISOString())
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const clarity: any = window.clarity
        clarity && clarity('set', 'RegisterPageUTM', params.join('&'))
      }
    }
    // eslint-disable-next-line
  }, [])
  // returns
  if (state.loading) {
    return (
      <Box display="flex" justifyContent="center">
        <LoadingComponent />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <form
        name="mas_registration"
        className="mas-registration"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box className={classes.title}>
          Sign Up For
          <img
            src={Icons.masLogoIcon}
            alt="MAS"
            style={{
              width: '67px',
              verticalAlign: 'middle',
              marginLeft: '12px',
              transform: 'translateY(-2px)',
            }}
          />
        </Box>

        <Box
          style={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '10px',
            background:
              'linear-gradient(82.32deg, #005897 -3.09%, #00243D 128.6%)',
            color: '#FFF',
            padding: '10px 12px',
            fontSize: '14px',
            fontWeight: 700,
            margin: '24px',
          }}
        >
          <Box marginBottom="4px">
            Reply to all your player reviews with AI.
          </Box>
          <Box
            component="span"
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              window.open('https://hubs.ly/Q02t_Kmf0')
            }}
          >
            Try FanCraft for free &raquo;
          </Box>
        </Box>
        <AuthPageInput
          label="Email Address *"
          name="email"
          control={control}
          defaultValue=""
          placeholder={t('auth.login.email')}
          type="email"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <Grid container>
          <Grid xs={6} style={{ paddingRight: 5 }}>
            <AuthPageInput
              label="First Name *"
              name="firstName"
              control={control}
              defaultValue=""
              placeholder="First Name"
              type="text"
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          </Grid>
          <Grid xs={6} style={{ paddingLeft: 5 }}>
            <AuthPageInput
              label="Last Name *"
              name="lastName"
              control={control}
              defaultValue=""
              placeholder="Last Name"
              type="text"
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          </Grid>
        </Grid>
        <AuthPageInput
          label="Company Name *"
          name="company"
          control={control}
          defaultValue=""
          placeholder={t('auth.signup.company_name')}
          type="text"
          error={!!errors.company}
          helperText={errors.company?.message}
        />
        <Box
          sx={{
            color: '#1D3353',
            fontSize: 12,
            fontWeight: 500,
            marginBottom: '6px',
          }}
        >
          Phone Number *
        </Box>
        <Box className={classes.phoneNumber}>
          <PhoneInput
            placeholder="Phone number"
            value={owner_tel}
            international
            initialValueFormat="national"
            onChange={(val: any) => {
              setTelError(false)
              setOwner_tel(val)
            }}
          />
          {telError && (
            <Box
              sx={{
                color: '#d32f2f',
                fontSize: '0.75rem',
                margin: '3px 14px 0',
              }}
            >
              {owner_tel
                ? 'Wrong phone number format'
                : 'Phone number is required'}
            </Box>
          )}
        </Box>
        <AuthPageInput
          label="Password *"
          name="password"
          control={control}
          defaultValue=""
          autoComplete="off"
          placeholder={t('auth.login.password')}
          type="password"
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <AuthPageInput
          label="Confirm Password *"
          name="password_confirm"
          control={control}
          defaultValue=""
          autoComplete="off"
          placeholder={t('auth.signup.password_confirm')}
          type="password"
          error={!!errors.password_confirm}
          helperText={errors.password_confirm?.message}
        />

        <Box marginTop="18px" display="flex" justifyContent="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={checkAgreement}
                onChange={onChangeCheckAgreement}
                sx={{ color: '#1D3353' }}
              />
            }
            label={
              <Box
                display="flex"
                flexWrap="wrap"
                style={{ wordBreak: 'break-all' }}
              >
                <Typography className={classes.desc} variant="body2">
                  By signing up I agree to the platform’s{' '}
                  <Link
                    underline="always"
                    className={classes.termsLink}
                    onClick={() => {
                      window.open(
                        'https://policy.yodo1.com/mas/en/terms-of-service.html'
                      )
                    }}
                  >
                    terms and conditions
                    <OpenInNewIconView />
                  </Link>{' '}
                  and to receive updates about products, SDK, best practices and
                  special events.
                </Typography>
              </Box>
            }
          />
        </Box>
        <Box marginTop="6px" display="flex" justifyContent="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={checkCOPPA}
                onChange={onChangeCheckCOPPA}
                sx={{ color: '#1D3353' }}
              />
            }
            label={
              <Box display="flex" flexWrap="wrap">
                <Typography className={classes.desc} variant="body2">
                  You understand your responsibility as a developer to comply
                  with COPPA, as explained
                  <Link
                    underline="always"
                    className={classes.termsLink}
                    onClick={() => {
                      window.open(
                        'https://developers.yodo1.com/docs/additional-set-up/compliance-with-legal-framework/coppa/'
                      )
                    }}
                    style={{ display: 'inline', marginLeft: '5px' }}
                  >
                    here
                    <OpenInNewIconView />
                  </Link>
                </Typography>
              </Box>
            }
          />
        </Box>
        <Box display="flex" justifyContent="center" marginTop="14px">
          <Button
            disabled={!checkAgreement}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
          >
            {t('auth.login.signup')}
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: '14px',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '14px',
            fontWeight: 600,
            color: '#7487AA',
          }}
        >
          Already have an account?
          <Link
            onClick={() => {
              navigate('/login')
            }}
            className={classes.signIn}
            underline="none"
          >
            Sign in
          </Link>
        </Box>
        <AuthPromptDialog
          openModel={state.openModel}
          isSuccessDialog
          title="Congratulations! You’ve registered on MAS"
          content={`We have sent you a verification email on ${state.signUpEmail}. Please click on the verification link to complete registration. Don’t forget to check your email’s spam folder.`}
          // contentDesc=""
          onConfirm={dialogConfirm}
          confirmText="Ok!"
        />
      </form>
    </Box>
  )
}

export default View
