import { Radio, RadioProps, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import clsx from 'clsx'

// radio style
const useRadioStyle = makeStyles(() => {
  const theme = useTheme()
  return {
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 18,
      height: 18,
      boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 18,
        height: 18,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }
})

const StyledRadio = (props: RadioProps) => {
  const radioClasses = useRadioStyle()

  return (
    <Radio
      className={radioClasses.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioClasses.icon, radioClasses.checkedIcon)} />
      }
      icon={<span className={radioClasses.icon} />}
      {...props}
    />
  )
}

export default StyledRadio
