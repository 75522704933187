/**
 * 主题模式
 * @param {number} AUTO 跟随系统
 * @param {number} LIGHT 正常模式
 * @param {number} DARK 暗黑模式
 */
enum ThemeMode {
  AUTO = 0,
  LIGHT = 1,
  DARK = 2,
}

/**
 * 本地缓存字段
 * @param {string} ACCESS_TOKEN token
 * @param {string} THEME 主题
 * @param {string} TOKEN_SOURCE_ADMIN 账号登录来源（admin跳转登录/账号密码登录）
 */
enum localStorageKey {
  ACCESS_TOKEN = 'access_token',
  THEME = 'theme',
  TOKEN_SOURCE_ADMIN = 'token_source_admin',
}

/**
 * @param {string} primary 主题颜色
 * @param {string} secondary 次要颜色
 * @param {string} info 普通颜色
 */
interface ThemeColor {
  primary: string
  secondary: string
  info: string
}

interface ContactNameForm {
  contactName: string
}

interface EmailForm {
  email: string
}

/**
 *
 */
interface VerifyEmailForm {
  email: string
  code: string
}

interface PasswordForm {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

type PasswordFormKey = 'currentPassword' | 'newPassword' | 'confirmPassword'

/**
 * Finance presonal info page
 */
interface PersonalInfoForm {
  type: 'individual' | 'company'
  address: {
    detail: string
    city: string
    zip: string
    country: string
  }
  contactEmail: string
  dob: {
    month: string
    day: string
    year: string
  }
  phone: {
    areaCode: string
    number: string
  }
  contactName: string
  birthCountry: string
}

type PersonalInfoFormKey =
  | 'address'
  | 'phone'
  | 'contactEmail'
  | 'dob'
  | 'contactName'
  | 'birthCountry'
  | 'type'
  | 'address.detail'
  | 'address.city'
  | 'address.zip'
  | 'address.country'
  | 'phone.areaCode'
  | 'phone.number'
  | 'dob.month'
  | 'dob.day'
  | 'dob.year'

/**
 * Finance payment info page
 */

type PaymentInfoFormKey =
  | 'phone'
  | 'type'
  | 'bankName'
  | 'accountName'
  | 'bankAddress'
  | 'iban'
  | 'branchName'
  | 'swift'
  | 'branchZip'
  | 'phone.number'
  | 'phone.areaCode'
  | 'bankAddress.detail'
  | 'bankAddress.city'
  | 'bankAddress.zip'
  | 'bankAddress.country'

interface PaymentInfoForm {
  type: 'PKR' | 'USD'
  bankName: string
  accountName: string
  bankAddress: {
    detail: string
    city: string
    zip: string
    country: string
  }
  iban: string
  phone: {
    areaCode: string
    number: string
  }
  branchName: string
  swift: string
  branchZip: string
}

type RowData = {
  approval_date: string
  amount: number
  method: string | null
  status: number
}

interface HistoryList {
  page: number
  pageSize: number
  total: number
  data: RowData[]
}

interface ProfileInfo {
  Name: string
  CompanyName: string
  Address: string
  PaymentMethod: string
  Email: string
}

export type {
  ThemeColor,
  ContactNameForm,
  EmailForm,
  PasswordForm,
  PasswordFormKey,
  PersonalInfoForm,
  PersonalInfoFormKey,
  PaymentInfoForm,
  PaymentInfoFormKey,
  VerifyEmailForm,
  // finance
  RowData,
  HistoryList,
  ProfileInfo,
}

export { ThemeMode, localStorageKey }
