import { useState, useContext, useMemo } from 'react'
import {
  Box,
  Popover,
  Switch,
  Tooltip,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SVGIcon } from 'src/images'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'
import { CohortsContext } from 'src/context/gamePlayInsights/cohorts'
import { GlobalContext } from 'src/context/global'
import { LoadingBtn } from 'src/components'
import clsx from 'clsx'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      popover: {
        '& .MuiPaper-root': {
          minWidth: '300px',
          width: '300px',
          maxHeight: '394px',
          marginTop: '4px',
          borderRadius: '6px',
          padding: '16px 12px',
          background: theme.palette.custom.dialogBg,
        },
      },
      benchmarkWrap: {
        whiteSpace: 'nowrap',
        paddingLeft: '16px',
        fontSize: '14px',
        height: '40px',
        color: theme.palette.text.secondary,
      },
      benchmarkBtnLoading: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 9,
        transform: 'translate(-50%, -50%)',
      },
      genreWrap: {
        whiteSpace: 'nowrap',
        paddingLeft: '16px',
        lineHeight: '40px',
        fontSize: '14px',
        height: '40px',
        color: theme.palette.text.secondary,
      },
      genreWrapDisabled: {
        opacity: 0.6,
        cursor: 'not-allowed',
      },
      genreValue: {
        fontSize: '14px',
        color: theme.palette.text.primary,
      },
      option: {
        display: 'flex',
        padding: '6px 14px',
        cursor: 'pointer',
        color: theme.palette.custom.inputLabel,
        fontWeight: 400,
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
          background:
            theme.palette.mode === 'light'
              ? 'rgba(224, 229, 231, 0.5)'
              : '#555D6A',
        },
      },
      checked: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        background:
          theme.palette.mode === 'light'
            ? 'rgba(224, 229, 231, 0.5)'
            : '#555D6A',
      },
      tooltip: {
        marginBottom: '30px !important',
        marginLeft: '-26px !important',
      },
      arrow: {
        marginLeft: '-50%',
      },
      infoIcon: {
        fontSize: '18px !important',
        verticalAlign: 'middle',
        marginLeft: '4px',
        color: theme.palette.text.secondary,
      },
    }
  })

const SortButton = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, search } = useContext(CohortsContext)
  const { state: globalState, editUserFeature } = useContext(GlobalContext)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const gameGenreText = useMemo(
    () =>
      globalState.feature?.cohorts_benchmark !== true
        ? 'Turn on Benchmark to select your game genre.'
        : '',
    [globalState.feature?.cohorts_benchmark]
  )

  const handleChange = () => {
    editUserFeature({
      cohorts_benchmark: !globalState.feature?.cohorts_benchmark,
    })
  }

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Box
        sx={{
          boxSizing: 'border-box',
          width: '24px',
          height: '24px',
          padding: '3px',
          cursor: 'pointer',
        }}
        aria-describedby={id}
        onClick={handleOpen}
      >
        <Tooltip title="Filter graph legends" arrow placement="top">
          <Box>
            <SVGIcon.SettingSVG
              color={theme.palette.text.primary}
              style={{ verticalAlign: 'text-top', lineHeight: '18px' }}
            />
          </Box>
        </Tooltip>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <>
          {/* benchmark Button */}
          {globalState.feature?.cohorts_benchmark !== null && (
            <Box className={classes.benchmarkWrap}>
              <Box component="span" marginRight="12px" lineHeight="40px">
                Benchmark:
              </Box>
              <Box sx={{ position: 'relative', display: 'inline-block' }}>
                {(globalState.userInfoLoading ||
                  globalState.featureLoading) && (
                  <Box className={classes.benchmarkBtnLoading}>
                    <LoadingBtn
                      loadingStyle={{
                        justifyContent: 'center',
                      }}
                      noText={true}
                    />
                  </Box>
                )}
                <Switch
                  checked={globalState.feature?.cohorts_benchmark}
                  onChange={handleChange}
                  name="checkedC"
                  color="primary"
                  disabled={
                    globalState.accountAuthority.dashboard_page !== 2 ||
                    globalState.userInfoLoading ||
                    globalState.featureLoading
                  }
                />
              </Box>
              <Tooltip
                title={
                  <>
                    <Box>
                      See how your games compare to our recommended retention
                      curve.
                    </Box>
                    <Box marginTop="8px">
                      You can change the recommendation curve by changing the
                      genre to match the genre of your game.
                    </Box>
                  </>
                }
                arrow
                placement="top"
                disableInteractive
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </Tooltip>
            </Box>
          )}

          <Tooltip
            title={gameGenreText}
            placement="top-start"
            arrow
            classes={{
              popper: classes.tooltip,
            }}
          >
            <Box
              className={clsx({
                [classes.genreWrap]: true,
                [classes.genreWrapDisabled]:
                  globalState.feature?.cohorts_benchmark !== true,
              })}
              maxWidth="150px"
            >
              <Box component="span" marginRight="12px" lineHeight="40px">
                Genre:
              </Box>
              <Box component="span" className={classes.genreValue}>
                {state.genre}
              </Box>
            </Box>
          </Tooltip>
          {globalState.feature?.cohorts_benchmark && (
            <>
              {state.genreList.length ? (
                <Box maxHeight={276} overflow="auto">
                  {state.genreList.map((item: any, index: number) => (
                    <Box
                      className={clsx({
                        [classes.option]: true,
                        [classes.checked]: item.value === state.genre,
                      })}
                      key={index}
                      onClick={() => {
                        if (item.value === state.genre) return
                        search(item.value)
                        dispatch({
                          type: 'genre',
                          payload: item.value,
                        })
                      }}
                    >
                      <Box lineHeight="24px">{item.label}</Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box textAlign="center" fontSize={14} padding="16px">
                  No Data
                </Box>
              )}
            </>
          )}
        </>
      </Popover>
    </>
  )
}
export default SortButton
