import { useEffect, useRef, useContext } from 'react'
import { Chart, registerables } from 'chart.js'
import { useTheme, Theme, Box } from '@material-ui/core'
import { StickinessContext } from 'src/context/gamePlayInsights/stickiness'
import { CohortsContext } from 'src/context/gamePlayInsights/cohorts'
import { toPercent } from 'src/utils'

const StickinessChartView = (props: any) => {
  const theme: Theme = useTheme()
  const chart = useRef<any>()
  const { state } = useContext(StickinessContext)
  const { state: cohortsState } = useContext(CohortsContext)
  // effects
  useEffect(() => {
    if (!chart.current) {
      const chartEl: any = document.getElementById('sessions')
      // chartEl.style.height = '400px'
      if (chartEl) {
        Chart.register(...registerables)
        Chart.defaults.color =
          theme.palette.mode === 'dark' ? '#FFFFFF' : '#1D3353'
        chart.current = new Chart(chartEl.getContext('2d'), {
          type: cohortsState.newChartType === 'Graph' ? 'line' : 'bar',
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            maintainAspectRatio: false,
            scales: {
              y: {
                min: 0,
                ticks: {
                  callback: function (label: any) {
                    return toPercent(label)
                  },
                },
                stacked: cohortsState.newChartType === 'Graph' ? false : true,
                grid: {
                  color:
                    theme.palette.mode === 'dark'
                      ? 'rgba(244, 243, 241, 0.4)'
                      : '#E6E6E6',
                  borderColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(244, 243, 241, 0.4)'
                      : '#E6E6E6',
                },
              },
              x: {
                stacked: true,
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (label: any) {
                    return toPercent(label.formattedValue)
                  },
                },
              },
            },
          },
        })
      }
    }
    chart.current?.update()
    if (!state.data) return
    let newData: any = state.data
    if (state.checkedChartLine.length && state.data.datasets?.length) {
      const temp: any = { datasets: [], labels: state.data.labels }
      temp.datasets = state.data.datasets.filter((item: any) =>
        state.checkedChartLine.includes(item.label)
      )
      newData = temp
    } else {
      newData = { datasets: [], labels: state.data.labels }
    }
    chart.current.data = newData
    chart.current?.update()
    return function willUnmount() {
      if (chart.current) {
        chart.current.clear()
        chart.current.destroy()
        chart.current = undefined
      }
    }
    // eslint-disable-next-line
  }, [state.data, state.checkedChartLine, cohortsState.newChartType, theme])
  // returns
  return (
    <Box position="relative">
      <canvas id="sessions" width={props.width} height="396px" />
    </Box>
  )
}

export default StickinessChartView
