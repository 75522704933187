import { createContext, useReducer, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { getSubscribeApi, putSubscribeApi } from 'src/api/user'
import { GlobalContext } from './global'

type Props = {
  unsubscribe: any
  loading: boolean
  switchLoading: boolean
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  unsubscribe: {
    updates: true,
    announcements: true,
    support: true,
  },
  loading: false,
  switchLoading: false,
}
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'unsubscribe':
    case 'loading':
    case 'switchLoading':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: (value: Action) => void
  switchState: (key: string) => void
}
export const UnsubscribeContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  switchState: (key: string) => {},
})

export const UnsubscribeContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, customAlert } = useContext(GlobalContext)
  const [t] = useTranslation()

  // 获取Unsubscribe状态
  useEffect(() => {
    dispatch({ type: 'loading', payload: true })
    getSubscribeApi()
      .then(res => {
        dispatch({
          type: 'unsubscribe',
          payload: res.data,
        })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
    // eslint-disable-next-line
  }, [])

  // 修改Unsubscribe状态
  const switchState = (key: string) => {
    dispatch({ type: 'switchLoading', payload: true })
    let data = {
      updates: true,
      announcements: true,
      support: true,
    }
    if (key !== 'restoreDefaults') {
      data = {
        ...state.unsubscribe,
        [key]: !state.unsubscribe[key],
      }
    }
    putSubscribeApi(data)
      .then(() => {
        customAlert('success', t('common.success'))
        dispatch({
          type: 'unsubscribe',
          payload: data,
        })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'switchLoading', payload: false })
      })
  }

  // returns
  return (
    <UnsubscribeContext.Provider
      value={{
        state,
        dispatch,
        switchState,
      }}
    >
      {children}
    </UnsubscribeContext.Provider>
  )
}
