import { Box } from '@material-ui/core'
import { Icons } from 'src/images'

const MessageAlert = (props: any) => {
  const icon = {
    success: Icons.success,
    info: Icons.info,
    warning: Icons.warning,
    error: Icons.warning,
  }
  const iconColor = {
    success: '#18AC3B',
    info: '#084CD8',
    warning: '#F97C09',
    error: '#FB230F',
  }
  const borderColor = {
    success: '#54C672',
    info: '#6090E9',
    warning: '#FDAB5C',
    error: '#F8715F',
  }
  const backgroundColor = {
    success: '#E3F7E9',
    info: '#DCEBFA',
    warning: '#FEF5E2',
    error: '#FEE7E4',
  }
  return (
    <Box
      maxWidth="80vw"
      border={'1px solid ' + borderColor[props.alertType] || '#6090E9'}
      borderRadius="12px"
      padding="6px 14px 6px 10px"
      display="flex"
      color="#060606"
      alignItems="center"
      marginTop="20px"
      style={{ background: backgroundColor[props.alertType] || '#DCEBFA' }}
    >
      <Box
        width="34px"
        minWidth="34px"
        height="34px"
        textAlign="center"
        lineHeight="34px"
        borderRadius="10px"
        style={{ background: iconColor[props.alertType] || '#084CD8' }}
      >
        <img
          data-testid="alert-icon"
          src={icon[props.alertType] || Icons.info}
          alt=""
          style={{
            width: '20px',
            height: '20px',
            verticalAlign: 'text-top',
          }}
        />
      </Box>
      <Box data-testid="alert-message" marginLeft="10px" fontWeight="bold">
        {props.alertMessage}
        {props.clickText ? (
          <>
            {' '}
            <Box
              component="span"
              sx={{
                color: '#2F80ED',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={props.onTextClick}
            >
              {props.clickText}
            </Box>
            .
            {!!props.showEmail && (
              <>
                {' '}
                Please contact the Yodo1 Compliance Team{' '}
                <Box
                  component="span"
                  sx={{
                    color: '#2F80ED',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.open('mailto:policy@yodo1.com')
                  }}
                >
                  policy@yodo1.com
                </Box>
                .
              </>
            )}
          </>
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
}

export default MessageAlert
