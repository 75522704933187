import { useContext } from 'react'
import {
  Box,
  useTheme,
  Theme,
  Skeleton,
  Button,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import {
  InfoOutlined as InfoOutlinedIcon,
  VideogameAsset as VideogameAssetIcon,
} from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { PNG, SVGIcon } from 'src/images'
import { DashContext } from 'src/context/dashboard'
import { GlobalContext } from 'src/context/global'
import { numFormatter } from 'src/utils'
import { AdaptiveTooltip } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        maxHeight: '410px',
        overflowY: 'auto',
        color: theme.palette.text.primary,
        '&::-webkit-scrollbar': {
          width: 7,
        },
      },
      titleLine: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px',
      },
      gamePhoto: {
        width: '48px',
        height: '48px',
        verticalAlign: 'middle',
        borderRadius: '4px',
      },
      platformPhoto: {
        width: '14px',
        height: '14px',
        verticalAlign: 'text-top',
      },
      desc: {
        color: theme.palette.text.disabled,
        fontSize: '16px',
        textAlign: 'center',
      },
      headerRow: {
        display: 'flex',
        marginBottom: '12px',
        paddingRight: '6px',
        fontSize: '12px',
        color: theme.palette.text.primary,
      },
      borderRow: {
        display: 'flex',
        padding: '9px 6px 9px 0',
        borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
      },
      tableTd: {
        flex: 3,
        minWidth: '80px',
        textAlign: 'left',
      },
      appWidth: {
        flex: 1,
        minWidth: '170px',
      },
      infoIcon: {
        fontSize: '16px !important',
        verticalAlign: 'middle',
        marginLeft: '4px',
        color: theme.palette.text.secondary,
        marginTop: '-3px',
      },
    }
  })

const RetentionTableView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const min900 = useMediaQuery('(min-width:900px)')
  const loadingRow = Array(5).fill('')
  const { state } = useContext(DashContext)
  const { state: globalState } = useContext(GlobalContext)
  const toUrlPage = (url: string) => {
    url && window.open(url)
  }

  return (
    <>
      <Box className={classes.titleLine}>
        <Box fontSize="20px" fontWeight={500} whiteSpace="nowrap">
          Opportunities
        </Box>
      </Box>
      <>
        {!globalState.isMobile ? (
          <>
            {(state.upsellAppDataLoading || !!state.upsellAppData.length) && (
              <Box className={classes.headerRow}>
                <Box
                  width={min900 ? '300px' : '210px'}
                  minWidth={min900 ? '300px' : '210px'}
                >
                  Game
                </Box>
              </Box>
            )}
            {state.upsellAppDataLoading ? (
              <Box className={classes.container}>
                {loadingRow.map((row: any, index: number) => {
                  return (
                    <Box key={`${index}-row`} className={classes.borderRow}>
                      <Box
                        display="flex"
                        width={min900 ? '300px' : '210px'}
                        minWidth={min900 ? '300px' : '210px'}
                        flex={1}
                      >
                        <Skeleton
                          width="180px"
                          height="40px"
                          variant="rectangular"
                        />
                      </Box>
                      <Box className={classes.tableTd}>
                        <Skeleton
                          width="164px"
                          height="40px"
                          variant="rectangular"
                        />
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            ) : (
              <Box className={classes.container}>
                {!state.upsellAppDataLoading && !state.upsellAppData.length ? (
                  <Box
                    height="410px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box>
                      <Box className={classes.desc} marginBottom="24px">
                        You do not have any new opportunities.
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    {state.upsellAppData.map((row: any, index: number) => {
                      return (
                        <Box key={`${index}-row`} className={classes.borderRow}>
                          <Box
                            display="flex"
                            alignItems="center"
                            width={min900 ? '300px' : '210px'}
                            minWidth={min900 ? '300px' : '210px'}
                            flex={1}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              position="relative"
                              width="100%"
                            >
                              <Box
                                position="relative"
                                width="48px"
                                height="48px"
                                marginRight="16px"
                                onClick={() => {
                                  toUrlPage(row.store_url)
                                }}
                                style={{
                                  cursor: row.store_url
                                    ? 'pointer'
                                    : 'not-allowed',
                                }}
                              >
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    right: '-8px',
                                    top: '-8px',
                                    width: '14px',
                                    height: '14px',
                                    borderRadius: '50%',
                                    border: `2px solid ${
                                      theme.palette.mode === 'light'
                                        ? '#FFF'
                                        : '#1A202C'
                                    }`,
                                    overflow: 'hidden',
                                    background:
                                      row.platform === 'iOS'
                                        ? '#FFF'
                                        : theme.palette.background.paper,
                                  }}
                                >
                                  <img
                                    src={
                                      row.platform === 'iOS'
                                        ? PNG.ios
                                        : PNG.android
                                    }
                                    className={classes.platformPhoto}
                                    alt=""
                                  />
                                </Box>
                                {row.icon_url ? (
                                  <img
                                    src={row.icon_url}
                                    className={classes.gamePhoto}
                                    alt=""
                                  />
                                ) : (
                                  <SVGIcon.GameDefault
                                    style={{
                                      marginRight: '16px',
                                      verticalAlign: 'middle',
                                    }}
                                    color={
                                      theme.palette.mode === 'light'
                                        ? '#EFF2F3'
                                        : '#2B313C'
                                    }
                                  />
                                )}
                              </Box>
                              <Box width="calc(100% - 64px)">
                                <Box
                                  fontSize="14px"
                                  fontWeight="500"
                                  sx={{ wordBreak: 'break-word' }}
                                >
                                  {row.name}
                                </Box>
                                {!!(
                                  row.min_predicted_revenue &&
                                  row.max_predicted_revenue
                                ) && (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                      width: '100%',
                                      color: '#A8A8A8',
                                      marginTop: '4px',
                                    }}
                                  >
                                    <Box
                                      style={{
                                        fontVariantNumeric: 'tabular-nums',
                                        wordBreak: 'break-word',
                                      }}
                                      color={theme.palette.text.secondary}
                                      width="100%"
                                      fontSize="12px"
                                    >
                                      Est. Revenue/mo:{' '}
                                      {`$${numFormatter(
                                        row.min_predicted_revenue
                                      )} - `}
                                      <Box display="inline-block">
                                        $
                                        {numFormatter(
                                          row.max_predicted_revenue
                                        )}
                                        {row.predicted_revenue_method && (
                                          <Tooltip
                                            title={row.predicted_revenue_method}
                                            arrow
                                            placement="top"
                                            disableInteractive
                                          >
                                            <InfoOutlinedIcon
                                              sx={{
                                                fontSize: '14px !important',
                                                verticalAlign: 'middle',
                                                marginLeft: '4px',
                                                color:
                                                  theme.palette.text.secondary,
                                                marginTop: '-3px',
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Box className={classes.tableTd}>
                            <Button
                              color="primary"
                              variant="outlined"
                              sx={{ width: '164px', margin: '4px 0 0 12px' }}
                              onClick={() => {
                                navigate(
                                  `/dash/games/add?upsellKey=dashboard_upsell&detail=${encodeURIComponent(
                                    JSON.stringify(row)
                                  )}`
                                )
                              }}
                            >
                              <VideogameAssetIcon
                                sx={{
                                  fontSize: '22px',
                                  marginRight: '8px',
                                }}
                              />
                              Integrate MAS
                            </Button>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                )}
              </Box>
            )}
          </>
        ) : (
          <>
            {state.upsellAppDataLoading ? (
              <Box className={classes.container}>
                {loadingRow.map((row: any, index: number) => {
                  return (
                    <Box
                      sx={{
                        padding: '16px 0',
                        borderBottom: '1px solid #F2F2F2',
                      }}
                      key={index}
                    >
                      <Skeleton
                        width="100%"
                        height="100px"
                        variant="rectangular"
                        style={{ display: 'inline-block' }}
                      />
                    </Box>
                  )
                })}
              </Box>
            ) : (
              <Box className={classes.container}>
                {state.upsellAppData.map((row: any, index: number) => {
                  return (
                    <Box
                      sx={{
                        padding: '16px 0',
                        borderBottom: '1px solid #F2F2F2',
                      }}
                      key={index}
                    >
                      <Box className={classes.appWidth}>
                        <Box
                          display="flex"
                          alignItems="center"
                          position="relative"
                          width="100%"
                        >
                          <Box
                            position="relative"
                            width="48px"
                            height="48px"
                            marginRight="16px"
                            onClick={() => {
                              toUrlPage(row.store_url)
                            }}
                            style={{
                              cursor: row.store_url ? 'pointer' : 'not-allowed',
                            }}
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                right: '-8px',
                                top: '-8px',
                                width: '14px',
                                height: '14px',
                                borderRadius: '50%',
                                border: `2px solid ${
                                  theme.palette.mode === 'light'
                                    ? '#FFF'
                                    : '#1A202C'
                                }`,
                                overflow: 'hidden',
                                background:
                                  row.platform === 'iOS'
                                    ? '#FFF'
                                    : theme.palette.background.paper,
                              }}
                            >
                              <img
                                src={
                                  row.platform === 'iOS' ? PNG.ios : PNG.android
                                }
                                className={classes.platformPhoto}
                                alt=""
                              />
                            </Box>

                            {row.icon_url ? (
                              <img
                                src={row.icon_url}
                                className={classes.gamePhoto}
                                alt=""
                              />
                            ) : (
                              <SVGIcon.GameDefault
                                style={{
                                  marginRight: '16px',
                                  verticalAlign: 'middle',
                                }}
                                color={
                                  theme.palette.mode === 'light'
                                    ? '#EFF2F3'
                                    : '#2B313C'
                                }
                              />
                            )}
                          </Box>
                          <Box width="calc(100% - 70px)">
                            <Box
                              fontSize="14px"
                              fontWeight="500"
                              sx={{ wordBreak: 'break-word' }}
                            >
                              {row.name}
                            </Box>
                            {!!(
                              row.min_predicted_revenue &&
                              row.max_predicted_revenue
                            ) && (
                              <Box
                                sx={{
                                  width: '100%',
                                  color: '#A8A8A8',
                                  marginTop: '4px',
                                }}
                                display="flex"
                                alignItems="center"
                              >
                                <Box
                                  style={{
                                    fontVariantNumeric: 'tabular-nums',
                                    wordBreak: 'break-word',
                                  }}
                                  fontSize="12px"
                                  color={theme.palette.text.secondary}
                                >
                                  Est. Revenue/mo:{' '}
                                  {`$${numFormatter(
                                    row.min_predicted_revenue
                                  )} - `}
                                  <Box display="inline-block">
                                    ${numFormatter(row.max_predicted_revenue)}
                                    {row.predicted_revenue_method && (
                                      <AdaptiveTooltip
                                        isMobile={true}
                                        title={row.predicted_revenue_method}
                                        arrow
                                        placement="top"
                                        disableInteractive
                                      >
                                        <InfoOutlinedIcon
                                          className={classes.infoIcon}
                                        />
                                      </AdaptiveTooltip>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box marginTop="16px">
                        <Button
                          color="primary"
                          variant="outlined"
                          sx={{ width: '164px' }}
                          onClick={() => {
                            navigate(
                              `/dash/games/add?upsellKey=dashboard_upsell&detail=${encodeURIComponent(
                                JSON.stringify(row)
                              )}`
                            )
                          }}
                        >
                          <VideogameAssetIcon
                            sx={{
                              fontSize: '22px',
                              marginRight: '8px',
                            }}
                          />
                          Integrate MAS
                        </Button>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            )}
          </>
        )}
      </>
    </>
  )
}
export default RetentionTableView
