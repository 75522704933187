import DayUtils from '@date-io/dayjs'
import dayjs from 'dayjs'
import { useState, useMemo, Fragment } from 'react'
import {
  Box,
  Popover,
  TextField,
  useTheme,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StaticDateRangePicker, LocalizationProvider } from '@material-ui/lab'
import clsx from 'clsx'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      borderDateBox: {
        width: '224px',
        position: 'relative',
        display: 'flex',
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '8px 16px',
        border: `1px solid ${theme.palette.custom.paperBorder}`,
        borderRadius: '6px',
        justifyContent: 'space-between',
        boxShadow:
          theme.palette.mode === 'light'
            ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
            : 'none',
      },
      label: {
        position: 'absolute',
        fontSize: '12px',
        lineHeight: '12px',
        top: '-6px',
        left: '10px',
        background: theme.palette.background.paper,
        padding: '0 6px',
        color: theme.palette.text.secondary,
      },
      popover: {
        '& .MuiPaper-root': {
          minWidth: '200px',
          maxHeight: '70vh',
          marginTop: '14px',
          borderRadius: '6px',
          padding: '0',
          background: theme.palette.custom.dialogBg,
        },
      },
      option: {
        width: '100%',
        display: 'flex',
        padding: '6px 14px',
        cursor: 'pointer',
        color: theme.palette.custom.inputLabel,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
          background:
            theme.palette.mode === 'light'
              ? 'rgba(224, 229, 231, 0.5)'
              : '#555D6A',
        },
      },
      checked: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        background:
          theme.palette.mode === 'light'
            ? 'rgba(224, 229, 231, 0.5)'
            : '#555D6A',
      },
      datePicker: {
        background: theme.palette.mode === 'light' ? '#FFF' : '#404858',
      },
    }
  })

interface selectProps {
  label?: string
  customClass?: any
  timeShow: string
  value: any
  showNumberButton?: boolean
  onlySelectLast6Month?: boolean
  onChange: (dateRange: any[], timeShow: string) => void
  onTriggerFilter?: () => void
}
const Yesterday = dayjs().subtract(1, 'day')
const dateOptions = [
  {
    label: 'Yesterday',
    numberLabel: 'Yesterday',
    value: 'yesterday',
    range: [Yesterday.subtract(1, 'day'), Yesterday],
  },
  {
    label: 'This week',
    numberLabel: 'This week',
    value: 'this_week',
    range: [Yesterday.day(0), Yesterday],
  },
  {
    label: 'Last 7 days',
    numberLabel: 'Last 7 days',
    value: 'last_week',
    range: [Yesterday.subtract(1, 'week').add(1, 'day'), Yesterday],
  },
  {
    label: 'Past 2 weeks',
    numberLabel: 'Past 2 weeks',
    value: 'past_two_weeks',
    range: [Yesterday.subtract(2, 'week').add(1, 'day'), Yesterday],
  },
  {
    label: 'This month',
    numberLabel: 'This month',
    value: 'this_month',
    range: [Yesterday.date(1), Yesterday],
  },
  {
    label: 'Last 30 days',
    numberLabel: 'Last 30 days',
    value: 'last_month',
    range: [Yesterday.subtract(29, 'day'), Yesterday],
  },
  {
    label: 'Last 3 months',
    numberLabel: 'Last 3 months',
    value: 'last_three_month',
    range: [Yesterday.subtract(3, 'month').add(1, 'day'), Yesterday],
  },
  {
    label: 'Last 6 months',
    numberLabel: 'Last 6 months',
    value: 'last_six_month',
    range: [Yesterday.subtract(6, 'month').add(1, 'day'), Yesterday],
  },
  {
    label: 'Custom',
    numberLabel: 'Custom',
    value: 'custom',
  },
]
const dateOptionsObject = {
  today: 'Today',
  yesterday: 'Yesterday',
  this_week: 'This week',
  last_week: 'Last 7 days',
  past_two_weeks: 'Past 2 weeks',
  this_month: 'This month',
  last_month: 'Last 30 days',
  last_three_month: 'Last 3 months',
  last_six_month: 'Last 6 months',
  last_year: 'Last year',
  custom: 'Custom',
}
const dateNumberOptionsObject = {
  last_week: 'Last 7 days',
  last_month: 'Last 30 days',
}

export default function StyleSelectMultiple(props: selectProps) {
  const {
    label = '',
    timeShow,
    value,
    onlySelectLast6Month = false,
    onChange,
    showNumberButton = false,
    onTriggerFilter = null,
  } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const showTwoCalendars = useMediaQuery('(min-width:800px)')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [defaultValue, setDefaultValue] = useState<any>(['', ''])
  const showDateOptions = useMemo(() => {
    const temp = JSON.parse(JSON.stringify(dateOptions))
    if (!onlySelectLast6Month) {
      temp.splice(8, 0, {
        label: 'Last year',
        numberLabel: 'Last year',
        value: 'last_year',
        range: [Yesterday.subtract(1, 'year').add(1, 'day'), Yesterday],
      })
    }
    return temp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlySelectLast6Month])
  const handleClick = (event: any) => {
    setDefaultValue(value)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    if (
      onTriggerFilter &&
      !(defaultValue[0] === value[0] && defaultValue[1] === value[1])
    ) {
      onTriggerFilter()
    }
    setAnchorEl(null)
  }
  const wrapHandleChange = (date: any, type: any) => {
    // 未确定区间不触发
    const isRang = date.every((item: any) => !!item)
    if (!isRang) return

    const startTime = dayjs(date[0]).format('YYYY-MM-DD')
    const endTime = dayjs(date[1]).format('YYYY-MM-DD')
    if (startTime === 'Invalid Date' || endTime === 'Invalid Date') return
    onChange([startTime, endTime], 'custom')
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const temp: any = DayUtils

  const toCheck = (item: any) => {
    if (item.value !== 'custom') {
      onChange(
        item.range.map((item: any) => dayjs(item).format('YYYY-MM-DD')),
        item.value
      )
    } else {
      onChange(value, item.value)
    }
  }
  return (
    <Box>
      <Box
        className={classes.borderDateBox}
        id={'dateSelect' + label}
        aria-describedby={id}
        onClick={handleClick}
      >
        {/* label */}
        {label && <Box className={classes.label}>{label}</Box>}
        {/* value */}
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color:
              theme.palette.mode === 'light'
                ? '#102542'
                : 'rgba(255, 255, 255, 0.6)',
          }}
        >
          {timeShow === 'custom'
            ? dayjs(value[0]).format('YYYY-MM-DD') +
              ' / ' +
              dayjs(value[1]).format('YYYY-MM-DD')
            : showNumberButton
            ? dateNumberOptionsObject[timeShow] || dateOptionsObject[timeShow]
            : dateOptionsObject[timeShow]}
        </Box>
        {/* right icon */}
        <SVGIcon.TriangleDownSVG
          color={
            theme.palette.mode === 'dark'
              ? 'rgb(255, 255, 255)'
              : 'rgba(0, 0, 0, 0.54)'
          }
          style={{
            marginTop: '9px',
            marginLeft: '2px',
            transform: anchorEl ? 'rotate(180deg)' : '',
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <Box fontSize="14px" padding="24px" display="flex">
          <Box minWidth="115px">
            {showDateOptions.map((item: any, index: number) => (
              <Box
                className={clsx({
                  [classes.option]: true,
                  [classes.checked]: item.value === timeShow,
                })}
                key={index}
                onClick={() => {
                  toCheck(item)
                }}
              >
                <Box lineHeight="24px">
                  {item[showNumberButton ? 'numberLabel' : 'label']}
                </Box>
              </Box>
            ))}
          </Box>
          <LocalizationProvider dateAdapter={temp}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              maxDate={Yesterday}
              minDate={
                onlySelectLast6Month
                  ? Yesterday.subtract(6, 'month').add(1, 'day')
                  : dayjs('1980-01-01')
              }
              inputFormat="YYYY-MM-DD"
              value={value}
              calendars={showTwoCalendars ? 2 : 1}
              onChange={wrapHandleChange}
              renderInput={(startProps, endProps) => (
                <Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </Fragment>
              )}
              className={classes.datePicker}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </Box>
  )
}
