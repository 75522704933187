import { createContext, useReducer, useEffect, useContext } from 'react'
import { ApiResponse } from 'src/api'
import {
  getRolesListApi,
  deleteAccountApi,
  addRoleApi,
  editRoleApi,
  sendCodeApi,
  getAccountsListApi,
} from 'src/api/roles'
import { GlobalContext } from './global'
import { transformUTC0ToLocalTime } from 'src/utils'

type Props = {
  searchData: { email: string; role: any[] }
  data: any
  loading: boolean
  deleteResult: boolean
  deleteMemberLoading: boolean
  editDeviceLoading: boolean
  dialogOpen: boolean
  editDialogOpen: boolean
  openModel: boolean
  dialogData: any
  codeLoading: boolean
  emailCodeInterval: number
  temporaryData: any
  rolesList: any
  rolesListObject: any
  noneRoleObject: any
  limitAppViewList: any[]
  allRolesList: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  searchData: { email: '', role: [] },
  loading: false,
  data: [],
  deleteResult: false,
  deleteMemberLoading: false,
  editDeviceLoading: false,
  dialogOpen: false,
  editDialogOpen: false,
  openModel: false,
  dialogData: {},
  codeLoading: false,
  emailCodeInterval: 0,
  temporaryData: [],
  rolesList: [],
  rolesListObject: {},
  noneRoleObject: {},
  limitAppViewList: [],
  allRolesList: [],
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'accountList':
      const data = action.payload.map((item: any) => {
        item.roleShow = item.created = transformUTC0ToLocalTime(item.created_at)
        return item
      })
      return { ...prevState, data }
    case 'rolesListObject':
      const obj = {}
      action.payload.forEach((item: any) => {
        obj[item.id] = item
      })
      return { ...prevState, rolesListObject: obj }
    case 'noneRoleObject':
      const newObj = {}
      action.payload.length &&
        Object.keys(action.payload[0].permission).forEach(item => {
          newObj[item] = 0
        })
      return { ...prevState, noneRoleObject: newObj }
    case 'rolesList':
      // TODO 删除old rule权限后将此判断逻辑删除直接将 action.payload返回即可
      const limitAppViewList: any = []
      const allRolesList: any = []
      const rolesList = action.payload.filter((item: any) => {
        if (item.limit_app_view) {
          limitAppViewList.push(item.id)
        }
        const temp = !item.title.startsWith('Old')
        if (!temp) {
          item.hideOption = true
        }
        allRolesList.push(item)
        return temp
      })
      return { ...prevState, rolesList, limitAppViewList, allRolesList }
    case 'temporaryData':
    case 'searchData':
    case 'loading':
    case 'deleteResult':
    case 'deleteMemberLoading':
    case 'editDeviceLoading':
    case 'dialogOpen':
    case 'editDialogOpen':
    case 'openModel':
    case 'dialogData':
    case 'codeLoading':
    case 'emailCodeInterval':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
type Context = {
  state: Props
  dispatch: (value: Action) => void
  deleteAccount: (id: string) => void
  editAccount: (data: Record<string, unknown>) => void
  sendCode: (eamil: string) => void
}

export const RolesContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  deleteAccount: (id: string) => {},
  editAccount: (data: Record<string, unknown>) => {},
  sendCode: (email: string) => {},
})

export const RolesContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, customAlert } = useContext(GlobalContext)
  // handlers
  const emailCodeIntervalInit = () => {
    let seconds = 60
    dispatch({
      type: 'emailCodeInterval',
      payload: seconds,
    })
    const timer = setInterval(() => {
      dispatch({
        type: 'emailCodeInterval',
        payload: --seconds,
      })

      if (!seconds) clearInterval(timer)
    }, 1000)
  }
  const searchList = () => {
    dispatch({ type: 'loading', payload: true })
    getAccountsListApi()
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'temporaryData', payload: data.data })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
  }
  const deleteAccount = (developer_key: string) => {
    if (state.deleteMemberLoading) return
    dispatch({ type: 'deleteResult', payload: false })
    dispatch({ type: 'deleteMemberLoading', payload: true })
    deleteAccountApi(developer_key)
      .then(() => {
        searchList()
        dispatch({ type: 'deleteResult', payload: true })
        customAlert('success', 'User was deleted successfully.')
      })
      .catch(errorCatch)
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: 'deleteMemberLoading', payload: false })
        }, 500)
      })
  }
  const sendCode = (email: string) => {
    dispatch({ type: 'codeLoading', payload: true })
    sendCodeApi({ email })
      .then((data: any) => {
        emailCodeIntervalInit()
        customAlert('success', 'Send Code Success')
      })
      .catch(errorCatch)
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: 'codeLoading', payload: false })
        }, 500)
      })
  }
  useEffect(() => {
    getRolesListApi().then(data => {
      dispatch({ type: 'rolesList', payload: data.data.data })
      dispatch({ type: 'rolesListObject', payload: data.data.data })
      dispatch({ type: 'noneRoleObject', payload: data.data.data })
    })
  }, [])
  useEffect(() => {
    searchList()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const arr = state.temporaryData.filter((item: any) => {
      return (
        item.email.includes(state.searchData.email) &&
        (!state.searchData.role.length ||
          state.searchData.role.map(res => res.id).includes(item.role_id))
      )
    })
    dispatch({ type: 'accountList', payload: arr })
    // eslint-disable-next-line
  }, [state.temporaryData, state.searchData])
  // 添加、编辑设备
  const editAccount = (data: any) => {
    if (state.editDeviceLoading) return
    dispatch({ type: 'editDeviceLoading', payload: true })
    if (data.developer_key) {
      editRoleApi(data)
        .then(() => {
          customAlert('success', 'User updated successfully.')
          searchList()
          dispatch({
            type: 'editDialogOpen',
            payload: false,
          })
        })
        .catch(errorCatch)
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: 'editDeviceLoading', payload: false })
          }, 500)
        })
    } else {
      addRoleApi(data)
        .then(() => {
          customAlert('success', 'User added successfully.')
          searchList()
          dispatch({
            type: 'dialogOpen',
            payload: false,
          })
        })
        .catch(errorCatch)
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: 'editDeviceLoading', payload: false })
          }, 500)
        })
    }
  }
  // returns
  return (
    <RolesContext.Provider
      value={{
        state,
        dispatch,
        deleteAccount,
        editAccount,
        sendCode,
      }}
    >
      {children}
    </RolesContext.Provider>
  )
}
