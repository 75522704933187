import { useContext, useEffect, useState } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Outlet } from 'react-router-dom'

import { GlobalContext } from 'src/context/global'
import { GlobalStoreContextProvider } from 'src/context/globalStore'
import { useNavigate } from 'react-router-dom'
import SideBar from './SideBar'
import TopBar from './TopBar'
import { LoadingComponent } from 'src/components'
import './style.css'
import { localStorageKey } from 'src/context/type'
import RatingDialog from './ratingDialog'
import SourceDialog from './sourceDialog'
import IntegrationScoreDialog from './integrationScoreDialog'
import FlashNotificationView from './flashNotification'
import FancraftDialogView from './fancraftDialog'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        minHeight: '100%',
        width: '100%',
        backgroundColor: theme.palette.background.default,
      },
      container: {
        paddingTop: '64px',
      },
      content: {
        margin: '20px 24px 100px',
        flex: 1,
      },
      welcomeContent: {
        flex: 1,
      },
      closeIcon: {
        fontSize: '32px',
        color: '#eeeeee',
        position: 'absolute',
        right: '6px',
        top: '6px',
        cursor: 'pointer',
        zIndex: 99,
      },
      video: {
        verticalAlign: 'bottom',
      },
      feb: {
        bottom: '12px',
        right: '20px',
      },
      helpText: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
        lineHeight: '18px',
        textAlign: 'center',
        marginBottom: '20px',
      },
      loadingMask: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        zIndex: 9999999,
        background: theme.palette.custom?.maskBackground,
      },
    }
  })

const Layout = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const {
    state,
    editUserEventStatus,
    submitRating,
    submitIntegrationScore,
    logout,
    hideOutsideContentPadding,
  } = useContext(GlobalContext)
  const navigator = useNavigate()
  const [openedModel, setOpenedModel] = useState(false)
  const [openRatingModel, setOpenRatingModel] = useState(false)
  const [openSourceModel, setOpenSourceModel] = useState(false)
  const [overflowValue, setOverflowValue] = useState<any>('clip')

  const [openIntegrationScoreModel, setOpenIntegrationScoreModel] =
    useState(false)
  const closeRatingDialog = () => {
    setOpenRatingModel(false)
  }
  const submitRatingDialog = (data: any) => {
    submitRating({ event_name: 'wait_user_feedback_form', data })
  }
  const submitSourceDialog = (data: any, url: string) => {
    setOpenSourceModel(false)
    navigator(url)
    editUserEventStatus('wait_user_channel_form')
    submitRating({ event_name: 'wait_user_channel_form', data })
  }
  const closeIntegrationScoreDialog = (score: string) => {
    if (score) {
      submitIntegrationScore({
        event_name: 'wait_integration_difficult_form',
        data: { integration_score: score },
      })
    }
    setOpenIntegrationScoreModel(false)
  }
  const submitIntegrationScoreDialog = (data: any) => {
    submitRating({ event_name: 'wait_integration_difficult_form', data })
  }
  // effects
  useEffect(() => {
    if (!localStorage.getItem(localStorageKey.ACCESS_TOKEN)) {
      logout()
    }
    // eslint-disable-next-line
  }, [state.token])
  useEffect(() => {
    if (state.eventStatus?.wait_user_channel_form === false && !openedModel) {
      setOpenedModel(true)
      const timer = setTimeout(() => {
        // 通过admin模拟登录时不进行弹框
        if (
          localStorage.getItem(localStorageKey.TOKEN_SOURCE_ADMIN) !== 'true'
        ) {
          setOpenSourceModel(true)
        }
        clearTimeout(timer)
      }, 5000)
    } else if (
      state.eventStatus?.wait_integration_difficult_form === false &&
      !openedModel
    ) {
      setOpenedModel(true)
      const timer = setTimeout(() => {
        setOpenIntegrationScoreModel(true)
        editUserEventStatus('wait_integration_difficult_form')
        clearTimeout(timer)
      }, 5000)
    } else if (
      state.eventStatus?.wait_user_feedback_form === false &&
      !openedModel
    ) {
      setOpenedModel(true)
      const timer = setTimeout(() => {
        setOpenRatingModel(true)
        editUserEventStatus('wait_user_feedback_form', false)
        clearTimeout(timer)
      }, 5000)
    }
    // eslint-disable-next-line
  }, [state.eventStatus])
  useEffect(() => {
    if (state.submitRatingResult) {
      setOpenRatingModel(false)
      setOpenIntegrationScoreModel(false)
      setOpenSourceModel(false)
    }
    // eslint-disable-next-line
  }, [state.submitRatingResult])
  useEffect(() => {
    const userAgent = window.navigator.userAgent
    const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent)
    if (isSafari) {
      setOverflowValue('')
    }
    // eslint-disable-next-line
  }, [])
  // returns
  return (
    <GlobalStoreContextProvider>
      <Box display="flex" justifyContent="center" className={classes.root}>
        {state.userInfoLoading && (
          <Box className={classes.loadingMask}>
            <LoadingComponent />
          </Box>
        )}
        <Box
          display="flex"
          flex={1}
          className={classes.container}
          style={{
            overflowX: !state.isMobile ? overflowValue : 'auto',
          }}
        >
          <TopBar />
          <SideBar />
          <Box
            className={
              hideOutsideContentPadding
                ? classes.welcomeContent
                : classes.content
            }
            style={{
              overflowX: !state.isMobile ? overflowValue : 'auto',
            }}
          >
            {state.token && <Outlet />}
          </Box>
        </Box>
      </Box>
      <RatingDialog
        openModel={openRatingModel}
        onClose={closeRatingDialog}
        onSubmit={submitRatingDialog}
        submitLoading={state.submitRatingLoading}
      />
      <IntegrationScoreDialog
        openModel={openIntegrationScoreModel}
        onClose={closeIntegrationScoreDialog}
        onSubmit={submitIntegrationScoreDialog}
        submitLoading={state.submitRatingLoading}
      />
      <SourceDialog
        openModel={openSourceModel}
        onSubmit={submitSourceDialog}
        submitLoading={state.submitRatingLoading}
      />
      <FlashNotificationView />
      <FancraftDialogView />
    </GlobalStoreContextProvider>
  )
}

export default Layout
