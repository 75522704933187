import { useContext } from 'react'
import {
  Box,
  Dialog,
  Button,
  useTheme,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { GameAddContext } from 'src/context/gameAdd'
import { SVGIcon } from 'src/images'
import { ClickCopyText } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      gameKeyLabel: {
        fontSize: '12px',
        color: theme.palette.custom.tableHead,
        margin: '16px 0 8px',
      },
      gameKeyValue: {
        fontSize: '14px',
        color: theme.palette.primary.main,
      },
      photo: {
        width: '35px',
        height: '35px',
        borderRadius: '4px',
        verticalAlign: 'middle',
      },
    }
  })
const AddedDialogView = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const { state } = useContext(GameAddContext)
  const min520px = useMediaQuery('(min-width:520px)')
  return (
    <Dialog open={props.open}>
      <Box
        position="relative"
        maxWidth="563px"
        boxSizing="border-box"
        padding={!min520px ? '36px 24px' : '46px 60px'}
      >
        <Box
          fontSize="20px"
          fontWeight={600}
          marginBottom="24px"
          textAlign="center"
        >
          New game added!
        </Box>
        <Box display="flex" alignItems="center">
          <Box marginRight="16px">
            {state.addGameDetail?.icon ? (
              <img
                src={state.addGameDetail?.icon}
                alt=""
                className={classes.photo}
              />
            ) : (
              <SVGIcon.GameDefault
                width="35px"
                height="35px"
                color={theme.palette.mode === 'light' ? '#EFF2F3' : '#2B313C'}
                style={{
                  verticalAlign: 'middle',
                }}
              />
            )}
          </Box>
          <Box>
            <Box
              sx={{
                height: '15px',
                lineHeight: '15px',
                margin: '0 0 4px',
                fontSize: '12px',
                color: theme.palette.custom.tableHead,
              }}
            >
              App Name
            </Box>
            <Box sx={{ lineHeight: '16px' }}>{state.addGameDetail?.name}</Box>
          </Box>
        </Box>
        {!!state.addGameDetail?.platform && (
          <Box marginBottom="16px">
            <Box className={classes.gameKeyLabel}>Platform</Box>
            <Box className={classes.gameKeyValue}>
              {state.addGameDetail.platform === 'android' ? 'Android' : 'iOS'}
            </Box>
          </Box>
        )}
        {!!state.addGameDetail?.bundle_id && (
          <Box marginBottom="16px">
            <Box className={classes.gameKeyLabel}>Bundle ID</Box>
            <Box className={classes.gameKeyValue}>
              {state.addGameDetail.bundle_id}
            </Box>
          </Box>
        )}
        <Box marginBottom="16px">
          <Box className={classes.gameKeyLabel}>App Key</Box>
          <Box className={classes.gameKeyValue}>
            <ClickCopyText
              value={state.addGameDetail.app_key}
              showIcon
              leftIcon
            />
          </Box>
        </Box>
        <Box marginBottom="16px">
          <Box className={classes.gameKeyLabel}>AdMob App ID</Box>
          <Box className={classes.gameKeyValue}>
            <ClickCopyText
              value={state.addGameDetail.admob_key}
              showIcon
              leftIcon
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '32px',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            style={{
              width: '160px',
              height: '40px',
              paddingLeft: 0,
              paddingRight: 0,
            }}
            onClick={() => {
              navigate('/dash/games')
            }}
          >
            Manage Game
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{
              width: '160px',
              height: '40px',
              paddingLeft: 0,
              paddingRight: 0,
              marginLeft: '12px',
            }}
            onClick={props.reset}
          >
            Add New Game
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
export default AddedDialogView
