import { useState, useEffect } from 'react'
import { Box, Skeleton, useTheme, Theme } from '@material-ui/core'
import HTMLReactParser from 'html-react-parser'
import { getDateEnglishFormat, getQueryParams } from 'src/utils'

const MobileMessageContentView = (props: any) => {
  const theme: Theme = useTheme()
  const [open, setOpen] = useState<boolean>(props.open)
  useEffect(() => {
    const locationSearch = getQueryParams(location.href)
    if (!locationSearch) {
      return
    }
    if (locationSearch.id) {
      const idTemp: any = Number(locationSearch.id) || null
      if (idTemp === props.id) {
        setOpen(true)
        props.clickContent && props.clickContent()
      }
    }
    // eslint-disable-next-line
  }, [location.href])
  return (
    <Box
      sx={{
        padding: '8px 0',
        borderBottom: `1px solid ${
          theme.palette.mode === 'light' ? '#E1E5E7' : '#495263'
        }`,
        color: theme.palette.text.primary,
      }}
    >
      {props.loading ? (
        <Skeleton variant="rectangular" width="100%" height="87px" />
      ) : (
        <>
          <Box
            sx={{
              padding: '8px',
              fontSize: '14px',
              cursor: 'pointer',
              fontWeight: open ? 700 : 400,
              background:
                !props.loading && !props.is_read
                  ? theme.palette.mode === 'light'
                    ? '#F2F9FF'
                    : 'rgba(242, 249, 255, 0.3)'
                  : '',
            }}
            onClick={() => {
              setOpen(!open)
              props.clickContent && props.clickContent()
            }}
          >
            {props.title}
            <Box
              sx={{
                color:
                  props.interaction_type === 'TOP_BANNER'
                    ? theme.palette.primary.main
                    : '',
                fontWeight: props.interaction_type === 'TOP_BANNER' ? 600 : 400,
                marginTop: '12px',
              }}
            >
              {props.category}
            </Box>
            <Box marginTop="8px" fontWeight={400}>
              {getDateEnglishFormat(props.time)}
            </Box>
          </Box>
          {!props.loading && open && (
            <Box padding="0 8px 8px">
              <Box sx={{ fontSize: '14px' }}>Content</Box>
              <Box
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  color: theme.palette.text.primary,
                }}
              >
                {HTMLReactParser(props.content)}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
export default MobileMessageContentView
