import { Box } from '@material-ui/core'
import { MetricsContextProvider } from 'src/context/userActivity/metrics'
import { MiddleChartContextProvider } from 'src/context/userActivity/middleChart'
import { SummaryContextProvider } from 'src/context/userActivity/summary'
import TabsView from './tabs'
import MiddleChartView from './middleChart'
import SummaryTableView from './summaryTable'
import TopFilterView from './topFilter'
import { StylePaper } from 'src/components'

const Dashboard = (): JSX.Element => {
  // returns
  return (
    <>
      <TopFilterView />
      <Box padding="20px 24px 100px">
        <Box marginTop="24px" width="100%">
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            flex={1}
            alignItems="center"
            style={{
              overflowX: 'clip',
            }}
          >
            <MiddleChartContextProvider>
              <MetricsContextProvider>
                <TabsView />
              </MetricsContextProvider>
              <StylePaper
                width="100%"
                margin="0 0 24px 0"
                padding="24px 16px"
                boxSizing="border-box"
                borderRadius="0 8px 8px"
              >
                <MiddleChartView />
              </StylePaper>
            </MiddleChartContextProvider>

            <StylePaper width="100%" padding="24px 16px" boxSizing="border-box">
              <SummaryContextProvider>
                <SummaryTableView />
              </SummaryContextProvider>
            </StylePaper>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Dashboard
