import { useEffect, useRef, useContext } from 'react'
import { useTheme, Theme, Box } from '@material-ui/core'
import { Chart, registerables } from 'chart.js'
import { ChurnRateContext } from 'src/context/gamePlayInsights/churnRate'
import { CohortsContext } from 'src/context/gamePlayInsights/cohorts'
import { getRandomColor, toPercent } from 'src/utils'

const View = (props: any) => {
  const theme: Theme = useTheme()
  const chart = useRef<any>()
  const { state } = useContext(ChurnRateContext)
  const { state: cohortsState } = useContext(CohortsContext)
  const getData = () => {
    const labels: any = []
    const datasets: any = []
    const arr = new Array(31)
    let i = arr.length
    while (i--) {
      labels[i] = i
    }
    if (state.thirtyUserData?.data?.length) {
      state.thirtyUserData?.data.forEach((data: any, i: number) => {
        if (i > 0) return
        const percentList = data?.calls.map((item: any) => item.percent)
        percentList.splice(0, 1, null)
        const color = i === 0 ? '#FF8E00' : getRandomColor()
        datasets.push({
          label: 'All Users',
          data: percentList,
          pointRadius: 0,
          borderColor: color,
          borderWidth: 2,
          backgroundColor: color,
          tension: 0.2,
          hitRadius: 10,
        })
      })
    } else {
      const temp = Array(31).fill(0)
      temp.splice(0, 1, null)
      datasets.push({
        label: 'All Users',
        data: temp,
        pointRadius: 0,
        borderColor: '#FF8E00',
        borderWidth: 2,
        backgroundColor: '#FF8E00',
        tension: 0.2,
        hitRadius: 10,
      })
    }
    return {
      labels,
      datasets,
    }
  }
  // effects
  useEffect(() => {
    if (!chart.current) {
      const chartEl: any = document.getElementById('retentionChart' + props.id)
      if (chartEl) {
        Chart.register(...registerables)
        Chart.defaults.color =
          theme.palette.mode === 'dark' ? '#FFFFFF' : '#1D3353'
        chart.current = new Chart(chartEl.getContext('2d'), {
          type: cohortsState.newChartType === 'Graph' ? 'line' : 'bar',
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  title: function (label: any) {
                    return 'Day ' + label[0].label
                  },
                  label: function (label: any) {
                    return toPercent(label.formattedValue)
                  },
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  drawOnChartArea: false,
                },
              },
              y: {
                min: 0,
                ticks: {
                  callback: function (label: any) {
                    return toPercent(label)
                  },
                },
                stacked: cohortsState.newChartType === 'Graph' ? false : true,
                grid: {
                  color:
                    theme.palette.mode === 'dark'
                      ? 'rgba(244, 243, 241, 0.4)'
                      : '#E6E6E6',
                  borderColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(244, 243, 241, 0.4)'
                      : '#E6E6E6',
                },
              },
            },
          },
        })
      }
    }
    chart.current.data = getData()
    chart.current?.update()
    return function willUnmount() {
      if (chart.current) {
        chart.current.clear()
        chart.current.destroy()
        chart.current = undefined
      }
    }
    // eslint-disable-next-line
  }, [state.thirtyUserData, cohortsState.newChartType, theme])
  // returns
  return (
    <Box position="relative">
      <canvas
        id={'retentionChart' + props.id}
        width={props.width}
        height="396px"
      />
    </Box>
  )
}

export default View
