const SVG = (props: any) => {
  const width = props.width || '64'
  const height = props.height || '64'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.6016 0H21.307C17.549 0.00426136 14.5037 3.04954 14.5 6.80753V57.1928C14.5037 60.9508 17.549 63.9961 21.307 63.9998H42.6016C46.3596 63.9961 49.4048 60.9508 49.4091 57.1928V6.80753C49.4048 3.04954 46.3596 0.00426136 42.6016 0V0ZM46.4996 57.1928C46.4975 59.3448 44.7535 61.0888 42.6016 61.0909H21.307C19.155 61.0888 17.411 59.3448 17.4089 57.1928V6.80753C17.411 4.65501 19.155 2.91104 21.307 2.90891H42.6016C44.7535 2.91104 46.4975 4.65501 46.4996 6.80753V57.1928Z"
        fill={color}
      />
      <path
        d="M36.3187 5.81836H27.5914C26.7882 5.81836 26.1367 6.46928 26.1367 7.27255C26.1367 8.07582 26.7882 8.72727 27.5914 8.72727H36.3187C37.122 8.72727 37.7729 8.07582 37.7729 7.27255C37.7729 6.46928 37.122 5.81836 36.3187 5.81836Z"
        fill={color}
      />
      <path
        d="M34.8633 55.2722C34.8633 56.8793 33.5609 58.1816 31.9544 58.1816C30.3473 58.1816 29.0449 56.8793 29.0449 55.2722C29.0449 53.6657 30.3473 52.3633 31.9544 52.3633C33.5609 52.3633 34.8633 53.6657 34.8633 55.2722Z"
        fill={color}
      />
      <rect
        x="20.4287"
        y="12.4287"
        width="23.1429"
        height="35.7143"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M26.6663 19.5244C25.9376 19.5244 25.333 20.129 25.333 20.8577V28.8577C25.333 29.5865 25.9376 30.1911 26.6663 30.1911H37.333C38.0617 30.1911 38.6663 29.5865 38.6663 28.8577V20.8577C38.6663 20.129 38.0617 19.5244 37.333 19.5244H26.6663ZM26.6663 20.8577H37.333V28.8577H26.6663V20.8577ZM33.6663 24.1911L31.333 26.8577L29.6663 25.1911L27.8512 27.5244H36.1663L33.6663 24.1911Z"
        fill={color}
      />
      <rect
        x="27.3"
        y="34.3"
        width="9.4"
        height="9.4"
        rx="4.7"
        stroke={color}
        strokeWidth="0.6"
      />
      <path
        d="M32.0001 42.5L28.6667 38.5L29.6667 36.5H34.3334L35.3334 38.5L32.0001 42.5ZM31.0667 38.25H32.9334L32.3084 37H31.6917L31.0667 38.25ZM31.7501 41.425V38.75H29.5251L31.7501 41.425ZM32.2501 41.425L34.4751 38.75H32.2501V41.425ZM33.4917 38.25H34.6251L34.0001 37H32.8667L33.4917 38.25ZM29.3751 38.25H30.5084L31.1334 37H30.0001L29.3751 38.25Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
