import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from 'src/context/auth'
import { AuthPromptDialog, LoadingComponent, LoadingBtn } from 'src/components'

const getUseStyles = () =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
      },
      formInput: {
        width: '100%',
        display: 'flex',
      },
      desc: {
        fontSize: '14px',
        marginInlineEnd: '5px !important',
        color: '#7487AA',
        fontWeight: 600,
      },
      resend: {
        fontWeight: 600,
        textDecoration: 'none !important',
      },
      input: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
          background: '#EEF1F5',
          height: '36px',
        },
        '& .MuiInputBase-input': {
          color: '#102542 !important',
          padding: '18px 12px !important',
          marginLeft: '0 !important',
          boxShadow: '0 0 0 100px #EEF1F5 inset !important',
          textFillColor: '#102542 !important',
        },
        '& .MuiInputAdornment-root': {
          textFillColor: '#102542 !important',
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          border: '1px solid #D2D6DD !important',
        },
      },
    }
  })

const View = (): JSX.Element => {
  const useStyles = getUseStyles()
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { state, verifyEmail, setOpenModel, resendVerifyEmail } =
    useContext(AuthContext)
  const [emailData, setEmailData] = useState({
    email: '',
    verify_code: '',
  })
  const [isWriteChange, setIsWriteChange] = useState(false)

  const isEmailCodeSend = useMemo(
    () => !!state.emailCodeInterval,
    [state.emailCodeInterval]
  )

  const isAllowVerifyEmail = useMemo(
    () => emailData.verify_code.length === 6,
    [emailData.verify_code]
  )

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsWriteChange(true)
    setEmailData(data => ({
      ...data,
      verify_code: e.target.value,
    }))
  }

  // handlers
  const onNext = () => {
    verifyEmail(emailData)
  }

  useEffect(() => {
    if (location.search) {
      const temp = location.search
        .slice(1)
        .split('&')
        .reduce((prev: Record<string, unknown>, current: string) => {
          const [keyTemp, val] = current.split('=')
          const key = keyTemp === 'token' ? 'verify_code' : keyTemp

          return {
            ...prev,
            [key]: val,
          }
        }, {})
      setEmailData(data => ({
        ...data,
        ...temp,
      }))
    }
  }, [location.search])

  // auto verify code
  useEffect(() => {
    if (isAllowVerifyEmail && !isWriteChange) onNext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowVerifyEmail])

  const dialogConfirm = () => {
    setOpenModel(false)
    navigate('/login')
  }
  const resendCode = () => {
    if (state.emailCodeLoading || state.emailCodeInterval > 0) {
      return
    }
    resendVerifyEmail(emailData.email)
  }

  // returns
  if (state.loading) {
    return (
      <Box display="flex" justifyContent="center">
        <LoadingComponent />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <Box>
        <Box marginBottom="20px">
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ color: '#1D3353', fontSize: '14px' }}
          >
            Please click on the verification link
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ color: '#1D3353', fontSize: '14px' }}
          >
            or enter the 6 digit code in the email sent to you.
          </Typography>
        </Box>
        <Box
          sx={{
            color: '#1D3353',
            fontSize: 12,
            fontWeight: 500,
            marginBottom: '6px',
          }}
        >
          Enter Verification Code
        </Box>
        <Box className={classes.formInput}>
          <TextField
            value={emailData.verify_code}
            className={classes.input}
            placeholder="Code"
            onChange={onChangeCode}
            fullWidth
            type="text"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {emailData.verify_code.length}/6
                </InputAdornment>
              ),
            }}
            variant="outlined"
            inputProps={{
              maxLength: 6,
            }}
          />
        </Box>
      </Box>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        onClick={onNext}
        disabled={!isAllowVerifyEmail}
        sx={{ margin: '28px 0 14px' }}
      >
        Verify account
      </Button>
      <Box
        sx={{
          fontSize: '12px',
          color: '#7487AA',
          lineHeight: '25px',
          textAlign: 'center',
        }}
      >
        <Typography className={classes.desc} variant="body2">
          {isEmailCodeSend ? (
            <>
              You can request a verification email again in{' '}
              <Box component="span" color="#FF8E00">
                {state.emailCodeInterval}
              </Box>{' '}
              seconds
            </>
          ) : (
            <>
              Haven’t received a verification email yet?{' '}
              {state.emailCodeLoading ? (
                <LoadingBtn
                  loadingStyle={{
                    justifyContent: 'center',
                    color: '#FF8E00',
                  }}
                />
              ) : (
                <Link
                  onClick={resendCode}
                  className={classes.resend}
                  underline="always"
                >
                  Request again.
                </Link>
              )}
            </>
          )}
        </Typography>
      </Box>

      <AuthPromptDialog
        openModel={state.openModel}
        isSuccessDialog
        title="Welcome to MAS!"
        content="Great, you're verified! You are one step closer to optimizing monetization for your mobile game!"
        onConfirm={dialogConfirm}
        contentPadding="0 20px"
        confirmText="Ok"
      />
    </Box>
  )
}

export default View
