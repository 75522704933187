import { useContext, useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/core/Skeleton'
import { ChurnRateContext } from 'src/context/gamePlayInsights/churnRate'
import { toPercent, toThousands, transformUTC0ToLocalTime } from 'src/utils'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '100%',
      },
      container: {
        '&::-webkit-scrollbar': {
          height: 4,
        },
      },
      title: {
        color:
          theme.palette.mode === 'light'
            ? 'rgba(0, 0, 0, 0.5)'
            : theme.palette.custom.tableHead,
        fontSize: '12px',
        lineHeight: '24px',
        textAlign: 'right',
        paddingRight: '8px',
      },
      game: {
        display: 'flex',
        alignItems: 'center',
      },
      num: {
        width: '20px',
        transform: 'translateX(-16px)',
      },
      gamePhoto: {
        width: '30px',
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '4px',
        marginLeft: '16px',
      },
      caption: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: '18px',
      },
      textPrimaryColor: {
        color: theme.palette.text.primary,
      },
      tbodyRow: {
        '&:nth-child(1) td, &:nth-child(3) td': {
          borderTop: `1px solid ${theme.palette.custom.tableBorder} !important`,
        },
      },
      tbodyCell: {
        borderRight: `1px solid ${theme.palette.custom.tableBorder} !important`,
        '&:last-child': {
          borderRight: 'none !important',
        },
      },
      mobileCard: {
        padding: '20px',
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      tableHead: {
        minWidth: 80,
        fontSize: '12px !important',
        color: `${
          theme.palette.mode === 'light'
            ? 'rgba(0, 0, 0, 0.5)'
            : theme.palette.custom.tableHead
        } !important`,
        backgroundColor: `${theme.palette.background.paper} !important`,
      },
    }
  })
const tempData = ['All Users', 0, ...Array(30).fill('-')]
const ChurnRateListView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state } = useContext(ChurnRateContext)
  const loadingRow = ['', '']
  const headList = ['Day', 'Users', ...Array(30).fill('')]
  const getBackgroundColor = (value: number | string) => {
    if (typeof value === 'string') return
    if (value !== 0) {
      value = value > 0.5 ? value - 0.5 : 0.01
    }
    if (value >= 0.5) return 'rgba(69, 150, 244, 1)'
    if (value >= 0.4) return 'rgba(69, 150, 244, 0.82)'
    if (value >= 0.3) return 'rgba(69, 150, 244, 0.64)'
    if (value >= 0.2) return 'rgba(69, 150, 244, 0.46)'
    if (value >= 0.1) return 'rgba(69, 150, 244, 0.28)'
    if (value > 0) return 'rgba(69, 150, 244, 0.1)'

    return
  }
  const allData = useMemo(() => {
    const data = []
    const Average = []
    if (state.retentionsAverage?.calls?.length) {
      Average.push({
        date: 'Average',
        ...state.retentionsAverage,
      })
    }
    if (state.userData?.length) {
      const allLists = [...Average, ...state.userData]
      allLists.forEach((beta: any, i: number) => {
        const temp = JSON.parse(JSON.stringify(tempData)).map(
          (item: any, index: number) => {
            if (index === 0) {
              return beta?.date || item
            } else if (index === 1) {
              return beta?.total_people || item
            } else {
              return beta?.calls[index - 1]?.percent ||
                beta?.calls[index - 1]?.percent === 0
                ? beta?.calls[index - 1]?.percent
                : item
            }
          }
        )
        data.push(temp)
      })
    } else {
      data.push(tempData)
    }
    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userData, state.retentionsAverage])
  return (
    <>
      <Box height="24px">
        <Box className={classes.title}>
          Updated at:
          <Box component="span" marginLeft="10px">
            {state.userData && state.userData[0]?.last_updated_at
              ? transformUTC0ToLocalTime(state.userData[0]?.last_updated_at)
              : '-'}
          </Box>
        </Box>
      </Box>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headList.map((column: any, i: number) => (
                <TableCell
                  key={i + 'head'}
                  align="left"
                  className={classes.tableHead}
                >
                  <Box>{i < 2 ? column : `Day ${i - 1}`}</Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(state.churnLoading ? loadingRow : allData).map(
              (row: any, index: number) => {
                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index + 'row'}
                      className={classes.tbodyRow}
                    >
                      {state.churnLoading
                        ? tempData.map((column: any, i: number) => {
                            return (
                              <TableCell key={i + 'column'}>
                                <Skeleton variant="text" />
                              </TableCell>
                            )
                          })
                        : row.map((column: any, i: number) => {
                            return (
                              <TableCell
                                width={80}
                                key={i + 'id2'}
                                className={classes.tbodyCell}
                                sx={{
                                  backgroundColor:
                                    i === 0 || i === 1
                                      ? ''
                                      : getBackgroundColor(column),
                                  fontVariantNumeric: 'tabular-nums',
                                }}
                              >
                                {i === 0 || typeof column !== 'number'
                                  ? column
                                  : i === 1
                                  ? toThousands(column)
                                  : toPercent(column)}
                              </TableCell>
                            )
                          })}
                    </TableRow>
                    {index === 0 && <tr style={{ height: '12px' }} />}
                  </>
                )
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default ChurnRateListView
