import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AuthContextProvider } from 'src/context/auth'
import { GlobalContext } from 'src/context/global'
import { MessageAlert } from 'src/components'
import { Icons } from 'src/images'

const Layout = (): JSX.Element => {
  const { state, dispatch } = useContext(GlobalContext)
  const [t] = useTranslation()

  // returns
  return (
    <AuthContextProvider>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          background: '#FFF',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            minHeight: '100%',
            backgroundImage: `url(${Icons.authBackgroundIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          {/* message alert */}
          <Box position="fixed" zIndex="999999999">
            {state.showViolationIssue && (
              <MessageAlert
                alertType="error"
                clickText="policy"
                alertMessage="Your account has been blocked as it violated our"
                onTextClick={() => {
                  window.open(
                    'https://policy.yodo1.com/mas/en/terms-of-service.html'
                  )
                  dispatch({
                    type: 'SHOW_VIOLATION_ISSUE',
                    payload: false,
                  })
                }}
                showEmail={true}
              >
                Your account has been blocked as it violated our
              </MessageAlert>
            )}
            {state.showReferralClosedTip && (
              <MessageAlert
                alertType="warning"
                alertMessage="MAS referral program is no longer active. You can still maximize your revenue with MAS & share the good word with your peers to grow with us!"
              >
                MAS referral program is no longer active. You can still maximize
                your revenue with MAS & share the good word with your peers to
                grow with us!
              </MessageAlert>
            )}
          </Box>
          {/* content */}
          <Box
            width="100%"
            maxWidth="360px"
            minHeight="300px"
            padding="24px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width="100%">
              <Box
                sx={{
                  textAlign: 'center',
                  marginBottom: '24px',
                }}
              >
                <img
                  src={Icons.yodo1LogoIcon}
                  style={{ width: '152px', verticalAlign: 'middle' }}
                  alt="YODO1"
                />
              </Box>
              <Outlet />
              <Box
                onClick={() => {
                  window.open(
                    'https://policy.yodo1.com/mas/en/terms-of-service.html'
                  )
                }}
                sx={{
                  color: '#1D3353',
                  fontSize: 14,
                  fontWeight: 600,
                  cursor: 'pointer',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {t('links.terms_conditions')}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AuthContextProvider>
  )
}

export default Layout
