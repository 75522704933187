const SVG = (props: any) => {
  const width = props.width || '25'
  const height = props.height || '22'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1020_4196)">
        <path
          d="M4.7542 14.9307C4.35978 14.9307 4.0459 15.2505 4.0459 15.6449V17.5511C4.0459 17.9455 4.35978 18.2658 4.7542 18.2658C5.14815 18.2658 5.46203 17.946 5.46203 17.5511V15.6449C5.46203 15.2505 5.14815 14.9307 4.7542 14.9307Z"
          fill="white"
        />
        <path
          d="M4.7542 10.167C4.35978 10.167 4.0459 10.4868 4.0459 10.8817V12.787C4.0459 13.1818 4.35978 13.5017 4.7542 13.5017C5.14815 13.5017 5.46203 13.1818 5.46203 12.787V10.8817C5.46203 10.4864 5.14815 10.167 4.7542 10.167Z"
          fill="white"
        />
        <path
          d="M8.80399 17.5566H6.89827C6.50385 17.5566 6.18311 17.8705 6.18311 18.2645C6.18311 18.6589 6.50339 18.9723 6.89827 18.9723H8.80399C9.19794 18.9723 9.51869 18.6589 9.51869 18.2645C9.51869 17.8705 9.19794 17.5566 8.80399 17.5566Z"
          fill="white"
        />
        <path
          d="M16.7151 6.37891C12.7879 6.37891 9.59326 9.57356 9.59326 13.5008C9.59326 17.428 12.7884 20.6231 16.7151 20.6231C20.6428 20.6231 23.8365 17.428 23.8365 13.5008C23.8365 9.57356 20.6428 6.37891 16.7151 6.37891ZM11.0671 14.2301H12.9819C13.0679 15.929 13.4633 17.4536 14.0709 18.5549C12.4608 17.7066 11.3031 16.1056 11.0671 14.2301ZM12.9815 12.7783H11.0666C11.3027 10.9018 12.4599 9.30086 14.07 8.45255C13.4628 9.55389 13.0675 11.0794 12.9815 12.7783ZM16.01 18.7334C15.2317 18.0594 14.5417 16.3541 14.4145 14.2301H16.01V18.7334ZM16.01 12.7783H14.4145C14.5417 10.6191 15.2317 8.94259 16.01 8.26953V12.7783ZM17.4257 18.7334V14.2301H19.0162C18.8885 16.3536 18.2049 18.0594 17.4257 18.7334ZM17.4257 12.7783V8.26953C18.2049 8.94259 18.8876 10.6191 19.0162 12.7783H17.4257ZM22.3623 12.7783H20.4474C20.3614 11.0794 19.9661 9.55343 19.358 8.45209C20.969 9.3004 22.1271 10.9023 22.3623 12.7783ZM19.3589 18.5554C19.967 17.4545 20.3623 15.929 20.4483 14.2301H22.3637C22.1262 16.1056 20.969 17.7071 19.3589 18.5554Z"
          fill="white"
        />
        <path
          d="M4.99814 5.74059H3.71973L5.16007 1.37695H6.77939L8.21973 5.74059H6.94132L5.98677 2.57866H5.95268L4.99814 5.74059ZM4.7595 4.019H7.16291V4.90536H4.7595V4.019Z"
          fill="white"
        />
        <path
          d="M10.619 5.74059H8.94V1.37695H10.6019C11.0508 1.37695 11.4386 1.46431 11.7653 1.63903C12.0934 1.81232 12.3463 2.06232 12.5238 2.38903C12.7028 2.71431 12.7923 3.10423 12.7923 3.55877C12.7923 4.01332 12.7035 4.40394 12.5259 4.73065C12.3484 5.05593 12.097 5.30593 11.7717 5.48065C11.4464 5.65394 11.0622 5.74059 10.619 5.74059ZM10.1247 4.73491H10.5764C10.7923 4.73491 10.9762 4.70011 11.1282 4.6305C11.2816 4.5609 11.3981 4.44087 11.4776 4.27042C11.5586 4.09996 11.5991 3.86275 11.5991 3.55877C11.5991 3.25479 11.5579 3.01758 11.4755 2.84712C11.3945 2.67667 11.2752 2.55664 11.1176 2.48704C10.9613 2.41744 10.7695 2.38263 10.5423 2.38263H10.1247V4.73491Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1020_4196">
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(3.27832 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SVG
