import { useContext, useMemo } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GlobalContext } from 'src/context/global'
import { CompareGamesContext } from 'src/context/compareGames'
import { BorderStyleSelectRow } from 'src/components'
import { rateTitleFormat, getScoringColor, getScoringNum } from 'src/utils'
import TableChartToggleButtonView from './tableChartToggleButton'
import ChartView from './chart/index'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Skeleton from '@material-ui/core/Skeleton'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      label: {
        fontSize: '14px',
        fontWeight: 600,
        width: '160px',
        minWidth: '160px',
      },
      tableHeader: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
      },
      contentLine: {
        display: 'flex',
        flex: 5,
        gap: '10px',
      },
      contentColumn: {
        boxSizing: 'border-box',
        flex: 1,
        minWidth: '190px',
        maxWidth: '360px',
        paddingRight: '16px',
      },
      addIcon: {
        fontSize: '30px !important',
        marginRight: '16px',
        verticalAlign: 'middle',
        cursor: 'pointer',
      },
    }
  })

const list = [
  { title: 'Revenue', key: 'revenue', showUnit: true },
  { title: 'Impressions', key: 'impressions' },
  { title: 'eCPM', key: 'ecpm', showUnit: true },
  { title: 'ARPDAU', key: 'arpdau', showUnit: true },
  { title: rateTitleFormat('Imp', 'DAU'), key: 'impdau' },
  { title: 'Avg. DAU', key: 'dau' },
  { title: 'Top 3 Countries', key: 'country' },
  { title: 'Ad Types', key: 'ad_type' },
  { title: 'Ad Placements', key: 'ad_placement' },
]
const CompareGamesView = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch } = useContext(CompareGamesContext)
  const { state: globalState } = useContext(GlobalContext)
  const showList = useMemo(() => {
    const temp = JSON.parse(JSON.stringify(list))
    if (globalState.showPerformanceScoring) {
      temp.splice(6, 0, { title: 'Latest Perf. Score', key: 'scoring' })
    }
    return temp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.showPerformanceScoring])
  const showColumn = useMemo(() => {
    const data: any = []
    state.dataKeys.find((item: string, index: number) => {
      if (index + 1 > state.showGameCount) {
        return true
      }
      data.push(state[item])
      return false
    })
    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.showGameCount,
    state.dataKeys,
    state.firstData,
    state.secondData,
    state.thirdData,
    state.fourthData,
  ])

  const selectGame = (row: any, index: number) => {
    const temp = JSON.parse(JSON.stringify(state.gameSelect))
    temp[index] = row
    dispatch({
      type: 'gameSelect',
      payload: temp,
    })
  }
  const addGame = () => {
    dispatch({
      type: 'showGameCount',
      payload: state.showGameCount + 1,
    })
  }
  const ScoringBox = (props: { scoring: any }) => {
    return (
      <Box
        sx={{
          display: 'inline-block',
          width: '55px',
          height: '25px',
          textAlign: 'center',
          lineHeight: '25px',
          borderRadius: '12px',
          background:
            getScoringColor(props.scoring) ||
            theme.palette.custom.editIconButton,
          color: getScoringColor(props.scoring)
            ? '#FFF'
            : theme.palette.text.primary,
        }}
      >
        {getScoringNum(props.scoring)}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        color: theme.palette.text.primary,
        padding: globalState.isMobile ? '6px 16px 16px' : '14px 24px 24px',
        fontSize: '14px',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          overflowX:
            globalState.isMobile || state.contentType === 'Chart'
              ? 'hidden'
              : 'auto',
          paddingTop: '10px',
        }}
      >
        {/* head */}
        <Box
          className={classes.tableHeader}
          paddingBottom="10px"
          sx={
            globalState.isMobile || state.contentType === 'Chart'
              ? {
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end',
                }
              : {}
          }
        >
          <Box
            className={classes.label}
            sx={{ height: '40px', lineHeight: '40px' }}
          >
            Compare Apps
          </Box>
          <Box
            className={classes.contentLine}
            sx={
              globalState.isMobile || state.contentType === 'Chart'
                ? { flexWrap: 'wrap' }
                : {}
            }
          >
            <Box className={classes.contentColumn}>
              <BorderStyleSelectRow
                width={globalState.isMobile ? '180px' : '100%'}
                label="Select Game"
                placeholder="Select Game"
                valueKey="app_key"
                labelKey="name"
                value={state.gameSelect[0] || ''}
                showSearch={true}
                onChange={(row: any) => {
                  selectGame(row, 0)
                }}
                options={globalState.gameOption}
                disabled
              />
            </Box>
            <Box className={classes.contentColumn}>
              <BorderStyleSelectRow
                width={globalState.isMobile ? '180px' : '100%'}
                label="Select Game"
                placeholder="Select Game"
                valueKey="app_key"
                labelKey="name"
                value={state.gameSelect[1] || ''}
                showSearch={true}
                onChange={(row: any) => {
                  selectGame(row, 1)
                }}
                options={globalState.gameOption}
                clearable
                changeClose
              />
            </Box>
            {state.showGameCount > 2 && (
              <Box className={classes.contentColumn}>
                <BorderStyleSelectRow
                  width={globalState.isMobile ? '180px' : '100%'}
                  label="Select Game"
                  placeholder="Select Game"
                  valueKey="app_key"
                  labelKey="name"
                  value={state.gameSelect[2] || ''}
                  showSearch={true}
                  onChange={(row: any) => {
                    selectGame(row, 2)
                  }}
                  options={globalState.gameOption}
                  clearable
                  changeClose
                />
              </Box>
            )}
            {state.showGameCount > 3 && (
              <Box className={classes.contentColumn}>
                <BorderStyleSelectRow
                  width={globalState.isMobile ? '180px' : '100%'}
                  label="Select Game"
                  placeholder="Select Game"
                  valueKey="app_key"
                  labelKey="name"
                  value={state.gameSelect[3] || ''}
                  showSearch={true}
                  onChange={(row: any) => {
                    selectGame(row, 3)
                  }}
                  options={globalState.gameOption}
                  clearable
                  changeClose
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            {state.showGameCount < 4 && (
              <Box lineHeight="40px">
                <AddCircleIcon className={classes.addIcon} onClick={addGame} />
              </Box>
            )}
            <Box marginTop="4px">
              <TableChartToggleButtonView />
            </Box>
          </Box>
        </Box>
        {/* body */}
        {state.contentType === 'Chart' ? (
          <ChartView list={list} />
        ) : (
          <>
            {!globalState.isMobile ? (
              <Box>
                {showList.map(
                  (
                    item: { title: string; key: string; showUnit?: boolean },
                    index: number
                  ) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        padding: '16px 0',
                        borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
                        fontVariantNumeric: 'tabular-nums',
                      }}
                    >
                      <Box
                        className={classes.label}
                        sx={
                          item.key === 'scoring' ? { lineHeight: '25px' } : {}
                        }
                      >
                        {item.title}
                      </Box>
                      <Box className={classes.contentLine}>
                        {showColumn.map((element: any, i: number) => (
                          <Box
                            className={classes.contentColumn}
                            key={i + 'column'}
                            sx={{
                              paddingRight: '24px',
                              textAlign: 'right',
                            }}
                          >
                            {state[state.dataKeys[i] + 'Loading'] ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Skeleton variant="text" width={80} />
                              </Box>
                            ) : (
                              <>
                                {item.key === 'scoring' ? (
                                  state.gameSelect[i]?.app_key && (
                                    <ScoringBox scoring={element[item.key]} />
                                  )
                                ) : item.key === 'country' ||
                                  item.key === 'ad_type' ||
                                  item.key === 'ad_placement' ? (
                                  <Box marginBottom="-8px">
                                    {element[item.key]?.map(
                                      (ele: any, eleIndex: number) => (
                                        <Box
                                          key={item.key + eleIndex}
                                          sx={{
                                            display: 'flex',
                                            lineHeight: '16px',
                                            gap: '6px',
                                            marginBottom: '8px',
                                            justifyContent: 'space-between',
                                            textAlign: 'left',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Box>{ele.name}</Box>
                                          <Box
                                            sx={{
                                              fontSize: '12px',
                                              color:
                                                theme.palette.text.secondary,
                                            }}
                                          >
                                            ${ele.value}
                                          </Box>
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                ) : (
                                  <Box>
                                    {item.showUnit &&
                                      (element[item.key] ||
                                        element[item.key] == 0) &&
                                      '$'}
                                    {element[item.key]}
                                  </Box>
                                )}
                              </>
                            )}
                          </Box>
                        ))}
                      </Box>
                      {state.showGameCount < 4 && <Box width="46px"></Box>}
                      <Box width="80px"></Box>
                    </Box>
                  )
                )}
              </Box>
            ) : (
              <Box padding="0 16px">
                {showColumn.map((element: any, i: number) => (
                  <Box key={i + 'list'}>
                    {state[state.dataKeys[i] + 'Loading'] ? (
                      <Box padding="12px 0">
                        <Skeleton
                          width="100%"
                          height="338px"
                          variant="rectangular"
                          style={{ display: 'inline-block' }}
                        />
                      </Box>
                    ) : (
                      <>
                        {state.gameSelect[i]?.app_key ? (
                          <Box
                            sx={{
                              padding: '12px 0',
                              borderBottom: `1px solid #F2F2F2`,
                              fontSize: '12px',
                              fontVariantNumeric: 'tabular-nums',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box
                                sx={{
                                  margin: '8px 0',
                                  lineHeight: '16px',
                                  fontSize: '16px',
                                }}
                              >
                                Compare Apps
                              </Box>
                              <Box
                                sx={{
                                  margin: '8px 0',
                                  lineHeight: '16px',
                                  flex: 1,
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  textAlign: 'right',
                                  fontVariantNumeric: 'tabular-nums',
                                }}
                              >
                                {state.gameSelect[i]?.name}
                              </Box>
                            </Box>
                            {showList.map(
                              (
                                item: {
                                  title: string
                                  key: string
                                  showUnit?: boolean
                                },
                                index: number
                              ) => (
                                <Box key={index}>
                                  {item.key === 'scoring' ? (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          margin: '8px 0',
                                          lineHeight: '16px',
                                          fontSize: '16px',
                                        }}
                                      >
                                        {item.title}
                                      </Box>
                                      <Box>
                                        {state.gameSelect[i]?.app_key && (
                                          <Box marginTop="4px">
                                            <ScoringBox
                                              scoring={element[item.key]}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  ) : item.key === 'country' ||
                                    item.key === 'ad_type' ||
                                    item.key === 'ad_placement' ? (
                                    <Box>
                                      <Box
                                        sx={{
                                          margin: '8px 0',
                                          lineHeight: '16px',
                                          fontSize: '16px',
                                        }}
                                      >
                                        {item.title}
                                      </Box>
                                      <Box>
                                        {
                                          <Box margin="8px 0 12px">
                                            {element[item.key]?.map(
                                              (ele: any, eleIndex: number) => (
                                                <Box
                                                  key={item.key + eleIndex}
                                                  sx={{
                                                    display: 'flex',
                                                    lineHeight: '16px',
                                                    gap: '6px',
                                                    marginBottom: '8px',
                                                    justifyContent:
                                                      'space-between',
                                                    textAlign: 'left',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <Box>{ele.name}</Box>
                                                  <Box
                                                    sx={{
                                                      fontSize: '12px',
                                                      color:
                                                        theme.palette.text
                                                          .secondary,
                                                    }}
                                                  >
                                                    ${ele.value}
                                                  </Box>
                                                </Box>
                                              )
                                            )}
                                          </Box>
                                        }
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          margin: '8px 0',
                                          lineHeight: '16px',
                                          fontSize: '16px',
                                        }}
                                      >
                                        {item.title}
                                      </Box>
                                      <Box
                                        sx={{
                                          margin: '8px 0',
                                          lineHeight: '16px',
                                          flex: 1,
                                          fontSize: '14px',
                                          fontWeight: 400,
                                          textAlign: 'right',
                                          fontVariantNumeric: 'tabular-nums',
                                        }}
                                      >
                                        <Box>
                                          {item.showUnit && '$'}
                                          {element[item.key]}
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              )
                            )}
                          </Box>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default CompareGamesView
