import { useRef, forwardRef, useImperativeHandle } from 'react'
import { MetricsFormContextProvider } from 'src/context/metricsForm'
import MetricsFormView from './metricsForm'

const MetricsForm = (props: any, ref: any): JSX.Element => {
  const MetricsFormeDialog = useRef()
  const openDialog = (app_key?: string) => {
    const dialog: any = MetricsFormeDialog.current
    dialog.openDialog(app_key)
  }
  useImperativeHandle(ref, () => ({
    openDialog,
  }))
  // returns
  return (
    <MetricsFormContextProvider>
      <MetricsFormView
        app_key={props.app_key}
        ref={MetricsFormeDialog}
        onClose={props.onClose}
        getNewData={props.getNewData}
      />
    </MetricsFormContextProvider>
  )
}

export default forwardRef(MetricsForm)
