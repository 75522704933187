import { Box, Theme, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StylePaper } from 'src/components'
import { Icons } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      content: {
        display: 'flex',
      },
      left: {
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '44px',
      },
      leftIcon: {
        width: '32px',
        height: '32px',
      },
      textContent: {
        maxWidth: '380px',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '16px',
        color: theme.palette.mode === 'light' ? '#B0B5B9' : '#F7FAFC',
      },
    }
  })

const AdblockAlertView = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  // returns
  return (
    <StylePaper
      boxSizing="border-box"
      width="70vw"
      maxWidth="466px"
      position="fixed"
      top="8px"
      left="50%"
      transform="translateX(-50%)"
      padding="6px 8px"
      boxShadow="0px 0px 2px rgba(145, 158, 171, 0.6), 0px 12px 24px rgba(145, 158, 171, 0.06)"
      zIndex={1199}
    >
      <Box className={classes.content}>
        <Box className={classes.left}>
          <Box
            width="32px"
            minWidth="32px"
            height="32px"
            textAlign="center"
            lineHeight="32px"
            borderRadius="8px"
            style={{ background: '#FB230F' }}
          >
            <img
              data-testid="alert-icon"
              src={Icons.warning}
              alt=""
              style={{
                width: '20px',
                height: '20px',
                verticalAlign: 'text-top',
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box className={classes.textContent}>
            You currently have an ad-blocker enabled in your browser. For a
            seamless MAS experience, please disable it for this site or
            whitelist us and refresh page.
          </Box>
        </Box>
      </Box>
    </StylePaper>
  )
}

export default AdblockAlertView
