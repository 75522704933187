import { useContext, useMemo } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { GlobalStoreContext } from 'src/context/globalStore'
import { useNavigate } from 'react-router-dom'
import HTMLReactParser from 'html-react-parser'

const TopBannerBox = (props: any) => {
  const theme: Theme = useTheme()
  const navigate = useNavigate()
  const { state, setAction } = useContext(GlobalStoreContext)
  const list = useMemo(() => {
    return state.topBannerNotification.slice(0, 3)
  }, [state.topBannerNotification])

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '60px',
        right: '196px',
        zIndex: 999999,
        marginBottom: '-8px',
      }}
    >
      {list.map((item: any) => (
        <Box
          key={item.id}
          sx={{
            boxSizing: 'border-box',
            width: '390px',
            border: `1px solid ${
              theme.palette.mode === 'light' ? '#1E96FC' : '#4981b0'
            }`,
            borderRadius: '8px',
            marginBottom: '16px',
            background: `linear-gradient(0deg, rgba(30, 150, 252, 0.05), rgba(30, 150, 252, 0.05)), ${
              theme.palette.mode === 'light' ? '#FFFFFF' : '#1A202C'
            }`,
            padding: '12px 16px 16px',
            boxShadow: '1px 1px 10px rgba(30, 150, 252, 0.2)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              marginBottom: '8px',
            }}
          >
            <Box
              sx={{
                fontSize: '16px',
                color: '#1E96FC',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                flex: 1,
                marginRight: '8px',
              }}
            >
              <Box
                component="span"
                sx={{
                  cursor: 'pointer',
                  fontWeight: 500,
                  lineHeight: '19px',
                }}
                onClick={() => {
                  navigate('/dash/notifications?id=' + item.id)
                }}
              >
                {item.title}
              </Box>
            </Box>

            <CloseIcon
              style={{
                color: theme.palette.mode === 'light' ? '#4A566D' : '#FFFFFF',
                fontSize: '24px !important',
                cursor: 'pointer',
              }}
              onClick={() => {
                setAction(item.id)
              }}
            />
          </Box>
          <Box
            sx={{
              color: theme.palette.mode === 'light' ? '#4A566D' : '#FFFFFF',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {HTMLReactParser(item.content.replace(/<[^>]*>/g, ''))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default TopBannerBox
