const SVG = (props: any) => {
  const width = props.width || '42'
  const height = props.height || '42'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.0327 18.4858H36.1737V13.3242C36.1737 11.2136 34.4556 9.50294 32.3525 9.50294H31.5601L31.5675 7.58492C31.5675 6.16307 30.4122 5.00781 28.9904 5.00781H6.07783C4.36717 5.00781 2.96753 6.38523 2.94531 8.0959C2.94531 8.11071 2.94531 8.12552 2.94531 8.14033V33.4226C2.94531 34.8445 4.10057 35.9998 5.52242 35.9998H32.3599C34.4705 35.9998 36.1811 34.2817 36.1811 32.1785V27.0095H37.0402C38.151 27.0095 39.0544 26.106 39.0544 24.9952V20.5001C39.047 19.3893 38.1436 18.4858 37.0327 18.4858ZM6.07783 6.78513H28.9904C29.4273 6.78513 29.7902 7.14059 29.7902 7.57751L29.7828 9.50294H6.07783C5.32988 9.50294 4.72263 8.89569 4.72263 8.14774C4.72263 7.39978 5.32988 6.78513 6.07783 6.78513ZM34.3964 32.1711C34.3964 33.2968 33.4781 34.215 32.3525 34.215H5.51501C5.07809 34.215 4.71522 33.8596 4.71522 33.4152V10.9692C5.12993 11.1692 5.58907 11.2803 6.07783 11.2803H32.3525C33.4781 11.2803 34.3964 12.1985 34.3964 13.3242V18.4858H28.3313C27.2205 18.4858 26.317 19.3893 26.317 20.5001V24.9952C26.317 26.106 27.2205 27.0095 28.3313 27.0095H34.3964V32.1711ZM37.2697 24.9952C37.2697 25.1285 37.166 25.2322 37.0327 25.2322H28.3387C28.2054 25.2322 28.1017 25.1285 28.1017 24.9952V20.5001C28.1017 20.3742 28.2054 20.2631 28.3387 20.2631H37.0327C37.1586 20.2631 37.2697 20.3668 37.2697 20.5001V24.9952Z"
        fill={color}
      />
      <path
        opacity="0.2"
        d="M33.1398 10.3628H30.5753V7.94417L29.7857 6.21689H24.5051L9.89725 5.77344H5.85048L3.67904 7.155L3.42969 32.7188L4.1224 34.4954L5.10942 34.7915L31.5121 35.1863L33.5849 34.7915L35.0161 33.2123V30.0045V28.0798L35.8057 26.2538L36.8914 25.9577L38.1745 25.7109C38.0265 24.4936 37.7304 22.0096 37.7304 21.8122C37.7304 21.5655 37.8784 20.1343 37.7304 19.9369C37.6119 19.779 37.352 19.6408 37.2369 19.5914L35.2628 19.2953V18.5057V16.0382V12.781L33.1398 10.3628Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
