import { useParams } from 'react-router-dom'
import {
  Box,
  useTheme,
  Theme,
  Select,
  MenuItem,
  Tooltip,
  Button,
  Switch,
  Typography,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { useState, useContext, useEffect, ChangeEvent } from 'react'
import Skeleton from '@material-ui/core/Skeleton'
import clsx from 'clsx'
import { GameManageContext } from 'src/context/gameManage'
import { GlobalContext } from 'src/context/global'
import { LoadingBtn } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        background: theme.palette.background.paper,
      },
      label: {
        display: 'inline-block',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        marginBottom: '4px',
      },
      selectInput: {
        margin: '0 12px',
        width: '200px',
        border: '1px solid #DBE8F8',
        borderRadius: '4px',
        height: '40px !important',
        lineHeight: '40px !important',
        fontSize: '14px !important',
        color: theme.palette.text.secondary,
        '&.MuiInputBase-root.MuiInput-underline': {
          border: 'none',
          boxShadow: 'none',
        },
        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-fontSizeSmall': {
          display: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0 none',
        },
      },
      daySelectInput: {
        width: '120px',
      },
      menuItem: {
        width: '236px',
      },
      dayMenuItem: {
        width: '150px',
      },
      radioButtonIcon: {
        width: '8px',
        marginRight: '16px',
      },
      line: {
        height: '1px',
        width: '100%',
        background: theme.palette.custom.tableBorder,
        margin: '27px 0 20px',
      },
      disabled: {
        opacity: '0.25',
      },
      saveButton: {
        width: '124px !important',
        height: '40px !important',
      },
      tip: {
        width: '16px',
        verticalAlign: 'text-top',
        marginRight: '6px',
      },
      tipBox: {
        boxSizing: 'border-box',
        padding: '0 16px',
        fontSize: '14px',
        lineHeight: '42px',
        color: theme.palette.primary.main,
        marginRight: '10px',
        border: '1px solid rgba(255, 142, 0, 0.2)',
        borderRadius: '4px',
        background: theme.palette.custom.sideBarBg,
      },
    }
  })
const minuteCapOptions = [
  {
    label: 'No per minute cap',
    value: 0,
  },
  {
    label: 'One ad every 0.5 minute',
    value: 0.5,
  },
  {
    label: 'One ad every 1 minute',
    value: 1,
  },
  {
    label: 'One ad every 1.5 minute',
    value: 1.5,
  },
  {
    label: 'One ad every 2 minutes',
    value: 2,
  },
  {
    label: 'One ad every 2.5 minute',
    value: 2.5,
  },
  {
    label: 'One ad every 3 minutes',
    value: 3,
  },
  {
    label: 'One ad every 5 minutes',
    value: 5,
  },
  {
    label: 'One ad every 10 minutes',
    value: 10,
  },
  {
    label: 'One ad every 15 minutes',
    value: 15,
  },
  {
    label: 'One ad every 30 minutes',
    value: 30,
  },
  {
    label: 'One ad every 60 minutes',
    value: 60,
  },
]
const dayCapOptions = [
  {
    label: 'No daily cap',
    value: 0,
  },
  {
    label: '1 per day',
    value: 1,
  },
  {
    label: '2 per day',
    value: 2,
  },
  {
    label: '3 per day',
    value: 3,
  },
  {
    label: '5 per day',
    value: 5,
  },
  {
    label: '10 per day',
    value: 10,
  },
  {
    label: '20 per day',
    value: 20,
  },
]
const CappingAndPacingView = () => {
  const params: any = useParams()
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, getCapping, saveCapping } =
    useContext(GameManageContext)
  const { state: globalState } = useContext(GlobalContext)
  // const [rewardedDisabled, setRewardedDisabled] = useState(true)
  const [interstitialDisabled, setInterstitialDisabled] = useState(true)

  const handleChange = (event: any) => {
    const { name, value } = event.target
    const temp = state.cappingData
    if (name === 'interstitialMinuteCap') {
      temp.interstitial.minute_frequency = value
    } else {
      temp.interstitial.day_limit = value
    }
    dispatch({ type: 'cappingData', payload: temp })
  }
  const checkChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    const temp = state.cappingData
    if (temp.interstitial !== null) {
      temp.interstitial.mode = checked ? 1 : 0
    } else {
      temp.interstitial = {
        mode: checked ? 1 : 0,
        day_limit: 0,
        minute_frequency: 0,
      }
    }

    dispatch({ type: 'cappingData', payload: temp })
  }
  const save = () => {
    saveCapping(params.app_key, state.cappingData)
  }
  useEffect(() => {
    if (state.appDetail?.id) {
      setInterstitialDisabled(
        !state.appDetail.ads_format.includes('interstitial')
      )
    }
    // eslint-disable-next-line
  }, [state.appDetail])
  useEffect(() => {
    getCapping(params.app_key)
    // eslint-disable-next-line
  }, [])
  return (
    <Box className={classes.container}>
      <Box>
        <Tooltip
          title={
            interstitialDisabled
              ? 'You have not selected Interstitial from the Ads Management page. Select it to complete this task'
              : ''
          }
          arrow
          placement="top"
        >
          <Box display="inline-block">
            <Box className={classes.label}>Interstitial</Box>
            <Box>
              {state.cappingLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="130px"
                  height="24px"
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: '8px',
                  }}
                />
              ) : (
                <Typography color="textSecondary" component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>OFF</Grid>
                    <Grid item style={{ position: 'relative' }}>
                      {state.switchLoading && (
                        <Box
                          position="absolute"
                          left="50%"
                          top="50%"
                          zIndex={9}
                          style={{
                            transform: 'translate(-50%, -50%)',
                          }}
                        >
                          <LoadingBtn
                            loadingStyle={{
                              justifyContent: 'center',
                            }}
                            noText={true}
                          />
                        </Box>
                      )}
                      <Switch
                        checked={state.cappingData.interstitial?.mode}
                        name="checkedC"
                        color="primary"
                        disabled={
                          globalState.accountAuthority.game_manage_page !== 2 ||
                          interstitialDisabled
                        }
                        onChange={checkChange}
                      />
                    </Grid>
                    <Grid item>ON</Grid>
                  </Grid>
                </Typography>
              )}
            </Box>
          </Box>
        </Tooltip>
      </Box>
      <Box marginTop="16px">
        <Box
          component="span"
          className={interstitialDisabled ? classes.disabled : ''}
        >
          Show a maximum of
        </Box>
        {state.cappingLoading ? (
          <Skeleton
            variant="rectangular"
            width="198px"
            height="40px"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              margin: '0 12px',
            }}
          />
        ) : (
          <Select
            className={classes.selectInput}
            value={state.cappingData.interstitial?.minute_frequency || 0}
            name="interstitialMinuteCap"
            onChange={handleChange}
            disabled={
              globalState.accountAuthority.game_manage_page !== 2 ||
              interstitialDisabled ||
              state.cappingData.interstitial?.mode !== 1
            }
          >
            {minuteCapOptions.map(option => (
              <MenuItem
                key={option.value}
                className={classes.menuItem}
                value={option.value}
              >
                {option.value ===
                state.cappingData.interstitial?.minute_frequency ? (
                  <RadioButtonChecked
                    className={classes.radioButtonIcon}
                    style={{ width: '8px' }}
                    fontSize="small"
                  />
                ) : (
                  <RadioButtonUnchecked
                    className={classes.radioButtonIcon}
                    style={{ width: '8px' }}
                    fontSize="small"
                  />
                )}
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        <Box
          component="span"
          className={interstitialDisabled ? classes.disabled : ''}
        >
          and/or limit to
        </Box>
        {state.cappingLoading ? (
          <Skeleton
            variant="rectangular"
            width="118px"
            height="40px"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              margin: '0 12px',
            }}
          />
        ) : (
          <Select
            className={clsx([classes.selectInput, classes.daySelectInput])}
            value={state.cappingData.interstitial?.day_limit || 0}
            name="interstitialDayCap"
            onChange={handleChange}
            disabled={
              globalState.accountAuthority.game_manage_page !== 2 ||
              interstitialDisabled ||
              state.cappingData.interstitial?.mode !== 1
            }
          >
            {dayCapOptions.map(option => (
              <MenuItem
                key={option.value}
                className={classes.dayMenuItem}
                value={option.value}
              >
                {option.value === state.cappingData.interstitial?.day_limit ? (
                  <RadioButtonChecked
                    className={classes.radioButtonIcon}
                    style={{ width: '8px' }}
                    fontSize="small"
                  />
                ) : (
                  <RadioButtonUnchecked
                    className={classes.radioButtonIcon}
                    style={{ width: '8px' }}
                    fontSize="small"
                  />
                )}
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      <Box position="relative" height="50px">
        <Box position="absolute" right="0" top="10px">
          {state.cappingLoading ? (
            <Skeleton
              variant="rectangular"
              width="124px"
              height="40px"
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                margin: '0 12px',
              }}
            />
          ) : (
            <>
              {globalState.accountAuthority.game_manage_page === 2 && (
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  onClick={save}
                >
                  {state.saveCappingLoading ? (
                    <LoadingBtn
                      loadingStyle={{
                        justifyContent: 'center',
                      }}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CappingAndPacingView
