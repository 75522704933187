import { portalService } from './service'

export const getUserMcmApi = () => {
  return portalService({
    url: '/user/me/mcm',
    method: 'get',
  })
}

export const updateUserMcmApi = (params: any) => {
  return portalService({
    url: '/user/me/mcm',
    method: 'patch',
    data: params,
  })
}

export const downloadAppAdsApi = () => {
  return portalService({
    url: '/user/me/mcm/app-ads.txt',
    method: 'get',
  })
}

export const uploadAppAdsApi = (params: any) => {
  return portalService.post('/user/me/mcm/optimize-app-ads', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const getUpdateTimeApi = () => {
  return portalService({
    url: `/game/latest_update_time`,
    method: 'get',
  })
}
