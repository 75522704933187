const SVG = (props: any) => {
  const width = props.width || '19'
  const height = props.height || '15'
  const color = props.color || '#323843'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9.04804L19 6.57713C19 6.43091 18.6203 6.3125 18.1514 6.3125L5.84864 6.3125C5.37973 6.3125 5 6.43091 5 6.57713L5 9.04804C5 9.19426 5.37973 9.3125 5.84864 9.3125L18.1519 9.3125C18.6208 9.3125 19 9.19409 19 9.04804Z"
        fill={color}
      />
      <path
        d="M3 9.04804L3 6.57713C3 6.43091 2.91863 6.3125 2.81815 6.3125L0.181851 6.3125C0.0813701 6.3125 -7.39421e-10 6.43091 -1.6525e-09 6.57713L-1.7082e-08 9.04804C-1.79951e-08 9.19426 0.0813701 9.3125 0.181851 9.3125L2.81827 9.3125C2.91875 9.3125 3 9.19409 3 9.04804Z"
        fill={color}
      />
      <path
        d="M19 3.4932L19 1.02243C19 0.876218 18.8651 0.757812 18.6985 0.757812L5.30155 0.757812C5.13493 0.757812 5 0.876217 5 1.02243L5 3.49319C5 3.63941 5.13493 3.75781 5.30155 3.75781L18.6985 3.75781C18.8651 3.75781 19 3.63941 19 3.4932Z"
        fill={color}
      />
      <path
        d="M3 3.4932L3 1.02243C3 0.876218 2.97109 0.757812 2.93538 0.757812L0.0646179 0.757812C0.0289137 0.757812 2.37679e-07 0.876217 2.36766e-07 1.02243L2.21338e-07 3.49319C2.20424e-07 3.63941 0.0289137 3.75781 0.0646179 3.75781L2.93538 3.75781C2.97109 3.75781 3 3.63941 3 3.4932Z"
        fill={color}
      />
      <path
        d="M19 14.5869L19 12.1162C19 11.97 18.8098 11.8516 18.5749 11.8516L5.42515 11.8516C5.19023 11.8516 5 11.97 5 12.1162L5 14.5869C5 14.7332 5.19023 14.8516 5.42515 14.8516L18.5749 14.8516C18.8095 14.8516 19 14.7332 19 14.5869Z"
        fill={color}
      />
      <path
        d="M3 14.5869L3 12.1162C3 11.97 2.95924 11.8516 2.9089 11.8516L0.0911026 11.8516C0.0407643 11.8516 -7.39377e-10 11.97 -1.65241e-09 12.1162L-1.7081e-08 14.5869C-1.79941e-08 14.7332 0.0407643 14.8516 0.0911026 14.8516L2.9089 14.8516C2.95918 14.8516 3 14.7332 3 14.5869Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
