import { Dialog, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CheckCircle, Close as CloseIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'

const getUseStyles = () =>
  makeStyles(() => {
    return {
      root: {
        width: '80vw',
        maxWidth: '480px',
        padding: '40px',
        boxSizing: 'border-box',
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
        color: '#1D3353',
      },
      content: {
        fontSize: '14px',
        margin: '16px 0 24px',
        lineHeight: '24px',
        color: '#7487AA',
      },
      contentDesc: {
        fontSize: '12px',
        margin: '4px 0 24px',
        lineHeight: '20px',
        color: '#7487AA',
      },
      button: {
        width: '100%',
      },
      successIcon: {
        color: '#309A45',
        verticalAlign: 'text-bottom',
        margin: '0 8px -2px 0',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '-3px',
        cursor: 'pointer',
      },
      dialog: {
        '& .MuiPaper-root': {
          background: '#FFF',
        },
      },
    }
  })
/**
 * @param openModel 显示隐藏
 * @param title 标题
 * @param content 内容
 * @param contentDesc 内容下方的描述
 * @param isSuccessDialog 是否为成功提示
 * @param confirmText 确认按钮文字
 * @param contentPadding 内容区内边距
 * @param customFooter 自定义按钮
 * @function onConfirm 确认回调
 * @function onClose 关闭区域点击回调
 */
interface PromptDialogProps {
  openModel: boolean
  title: string
  content?: string
  contentDesc?: string
  isSuccessDialog?: boolean
  confirmText?: string
  contentPadding?: string
  customFooter?: ReactNode
  onConfirm?: () => void
  onClose?: () => void
}

const PromptDialog = (props: PromptDialogProps) => {
  const useStyles = getUseStyles()
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <Dialog
      data-testid="prompt-dialog"
      open={props.openModel}
      onClose={props.onClose}
      className={classes.dialog}
    >
      <Box className={classes.root}>
        <Box>
          <Box data-testid="prompt-dialog-title" className={classes.title}>
            {props.isSuccessDialog && (
              <CheckCircle
                data-testid="prompt-dialog-icon"
                className={classes.successIcon}
              />
            )}
            {props.title}
            {props.onClose && (
              <CloseIcon
                className={classes.closeFilterIcon}
                onClick={props.onClose}
              />
            )}
          </Box>
        </Box>
        <Box
          data-testid="prompt-dialog-content"
          className={classes.content}
          style={props.contentDesc ? { marginBottom: 0 } : {}}
        >
          {props.content}
        </Box>
        {props.contentDesc && (
          <Box className={classes.contentDesc}>{props.contentDesc}</Box>
        )}
        {props.customFooter || (
          <Button
            data-testid="prompt-dialog-footer"
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={props.onConfirm}
          >
            {props.confirmText || t('common.ok')}
          </Button>
        )}
      </Box>
    </Dialog>
  )
}

export default PromptDialog
