import {
  Box,
  Typography,
  Avatar,
  Fab,
  Button,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { EditOutlined } from '@material-ui/icons'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GlobalContext } from 'src/context/global'
import ContactNameEdit from './contactNameEdit'
import EmailEdit from './emailEdit'
import PasswordEdit from './passwordEdit'
import PhoneNumberEdit from './phoneNumberEdit'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      avatarWrap: {
        position: 'relative',
        paddingBottom: '40px ',
        display: 'flex',
      },
      avatar: {
        width: '70px !important',
        height: '70px !important',
        marginRight: 24,
        border: `1px solid ${theme.palette.common.white} !important`,
      },
      editorFab: {
        position: 'absolute',
        top: '0px',
        left: '48px',
        height: '22px !important',
        minHeight: '0 !important',
        width: '22px !important',
        color: '#7487AA !important',
        border: `1px solid ${theme.palette.common.white} !important`,
        backgroundColor: '#E7EAEB !important',
        '&:hover': {
          backgroundColor: `${theme.palette.info.main} !important`,
          color: `${theme.palette.common.white} !important`,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 14,
        },
      },
      userInfoShowEditor: {
        right: 0,
        top: 16,
        height: '32px !important',
        width: '32px !important',
        color: '#7487AA !important',
        backgroundColor: '#E7EAEB !important',
        minWidth: '32px !important',
        '&.MuiButton-root': {
          position: 'absolute',
        },
        '& .MuiButton-endIcon': {
          margin: 0,
        },
        '&:hover': {
          color: `${theme.palette.common.white} !important`,
          backgroundColor: `${theme.palette.info.main} !important`,
        },
      },
      userInfoItem: {
        position: 'relative',
        paddingTop: 16,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      passwordDotWrap: {
        position: 'relative',
        display: 'flex',
        height: 24,
        alignItems: 'center',
        margin: '8px 0 16px',
      },
      passwordDot: {
        width: 6,
        height: 6,
        borderRadius: '50%',
        marginRight: 6,
        backgroundColor: theme.palette.text.primary,
      },
    }
  })

// avatar upload size limit
const AVATAR_LIMIT = {
  MAX: 1024 * 1024 * 1, // 1M
  MIN: 1024 * 20, // 20K
}

const View = (): JSX.Element => {
  const [t] = useTranslation()
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const className = useStyles()
  const {
    state,
    personalInfoChange,
    avatarUpload,
    customAlert,
    setAccountEditKeyHandle,
  } = useContext(GlobalContext)

  const [userData, setUserData] = useState({
    avatar: state.userInfo?.avatar,
    email: state.userInfo?.email || '-',
    owner_tel: state.userInfo?.owner_tel || '-',
    contactName: state.userInfo?.name || '-', // TODO: fix & add a loading maybe
    theme: state.userInfo?.theme || 0,
    is_admin: state.userInfo?.is_admin || false,
  })
  const isNotEdit = useMemo(() => !state.accountEditKey, [state.accountEditKey])
  const isContactNameEdit = useMemo(
    () => state.accountEditKey === 'contactName',
    [state.accountEditKey]
  )
  const isEmailEdit = useMemo(
    () => state.accountEditKey === 'email',
    [state.accountEditKey]
  )
  const isPasswordEdit = useMemo(
    () => state.accountEditKey === 'password',
    [state.accountEditKey]
  )
  const isPhoneNumberEdit = useMemo(
    () => state.accountEditKey === 'phoneNumber',
    [state.accountEditKey]
  )

  // defined function
  const imgRef: any = useRef(null)
  const onEditorAvatar = () => {
    imgRef?.current.click()
  }

  const saveChange = (avatarUrl: string) => {
    personalInfoChange({
      avatar: avatarUrl,
    })
  }

  const onAvatarChange = (event: any) => {
    const file = event.target?.files[0]
    if (file) {
      if (file.size < AVATAR_LIMIT.MIN || file.size > AVATAR_LIMIT.MAX) {
        customAlert('error', t('setting.personalInfo.avatarLimitErrMsg'))
        return
      }

      const formData = new FormData()
      formData.append('file', file)
      avatarUpload(formData).then(url => {
        if (!url) return
        setUserData(userDataProps => ({
          ...userDataProps,
          avatar: url,
        }))

        // auto save
        saveChange(url)
      })
    }
  }

  useEffect(() => {
    if (state.userInfo) {
      const { avatar, email, theme, name, owner_tel, is_admin } = state.userInfo
      setUserData(() => ({
        avatar,
        email,
        theme,
        contactName: name,
        owner_tel,
        is_admin,
      }))
    }
  }, [state.userInfo])

  // Avatar view
  const AvatarView = (
    <Box className={className.avatarWrap}>
      <Box display="inline-block" position="relative">
        <Avatar className={className.avatar} src={userData.avatar} />
        <Fab
          size="small"
          className={className.editorFab}
          onClick={onEditorAvatar}
          style={{ position: 'absolute' }}
        >
          <EditOutlined fontSize="small" />
          <input
            hidden
            type="file"
            accept="image/*"
            ref={imgRef}
            onChange={onAvatarChange}
          />
        </Fab>
      </Box>
      <Typography
        variant="h6"
        color="textPrimary"
        fontWeight="600"
        marginTop="6px"
      >
        {userData.contactName}
      </Typography>
    </Box>
  )

  const userInfoShowView = (
    <Box>
      <Box className={className.userInfoItem}>
        <Typography variant="body2" color="textSecondary" fontSize={12}>
          Display Name
        </Typography>
        <Typography variant="subtitle1" margin="8px 0 16px" fontSize={14}>
          {userData.contactName}
        </Typography>
        <Button
          variant="contained"
          className={className.userInfoShowEditor}
          endIcon={<EditOutlined />}
          onClick={() => setAccountEditKeyHandle('contactName')}
        />
      </Box>
      <Box className={className.userInfoItem}>
        <Typography variant="body2" color="textSecondary" fontSize={12}>
          Email
        </Typography>
        <Typography variant="subtitle1" margin="8px 0 16px" fontSize={14}>
          {userData.email}
        </Typography>
        <Button
          variant="contained"
          className={className.userInfoShowEditor}
          endIcon={<EditOutlined />}
          onClick={() => setAccountEditKeyHandle('email')}
        />
      </Box>
      <Box className={className.userInfoItem}>
        <Typography variant="body2" color="textSecondary" fontSize={12}>
          Password
        </Typography>
        <Box className={className.passwordDotWrap}>
          {Array(12)
            .fill('')
            .map((_, index) => (
              <i key={index} className={className.passwordDot} />
            ))}
        </Box>
        <Button
          variant="contained"
          className={className.userInfoShowEditor}
          endIcon={<EditOutlined />}
          onClick={() => setAccountEditKeyHandle('password')}
        />
      </Box>
      {userData.is_admin && (
        <Box className={className.userInfoItem}>
          <Typography variant="body2" color="textSecondary" fontSize={12}>
            Phone Number
          </Typography>
          <Box className={className.passwordDotWrap}>
            {userData.owner_tel || '-'}
          </Box>
          <Button
            variant="contained"
            className={className.userInfoShowEditor}
            endIcon={<EditOutlined />}
            onClick={() => setAccountEditKeyHandle('phoneNumber')}
          />
        </Box>
      )}
    </Box>
  )

  return (
    <>
      {AvatarView}
      {isNotEdit && userInfoShowView}
      {isContactNameEdit && <ContactNameEdit />}
      {isEmailEdit && <EmailEdit />}
      {isPasswordEdit && <PasswordEdit />}
      {isPhoneNumberEdit && <PhoneNumberEdit />}
    </>
  )
}

export default View
