import { GameManageContextProvider } from 'src/context/gameManage'
import ManageView from './managePage'

const GameManage = (): JSX.Element => {
  // returns
  return (
    <GameManageContextProvider>
      <ManageView />
    </GameManageContextProvider>
  )
}

export default GameManage
