import { useContext } from 'react'
import { useTheme, Theme, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CompareGamesContext } from 'src/context/compareGames'
import { SVGIcon } from 'src/images'
import clsx from 'clsx'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      toggleButton: {
        boxSizing: 'border-box',
        width: '40px',
        height: '32px',
        background: theme.palette.mode === 'light' ? '#ECF0F7' : '#323843',
        textAlign: 'center',
        paddingTop: '8px',
        cursor: 'pointer',
      },
      graphButton: {
        borderRadius: '6px 0 0 6px',
      },
      chartButton: {
        borderRadius: '0 6px 6px 0',
      },
      checked: {
        background: theme.palette.mode === 'light' ? '#4A5C75' : '#ECF0F7',
      },
      toggleGroup: {
        display: 'flex',
        lineHeight: '16px',
      },
    }
  })

const TableChartToggleButtonView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch } = useContext(CompareGamesContext)

  const handleAlignment = (type: string) => {
    if (state.contentType === type || !type) return
    dispatch({
      type: 'contentType',
      payload: type,
    })
  }

  return (
    <Box className={classes.toggleGroup}>
      <Box
        className={clsx({
          [classes.toggleButton]: true,
          [classes.graphButton]: true,
          [classes.checked]: state.contentType === 'Table',
        })}
        onClick={() => {
          handleAlignment('Table')
        }}
      >
        <SVGIcon.TableIconSVG
          color={
            state.contentType === 'Table'
              ? theme.palette.mode === 'light'
                ? '#ECF0F7'
                : '#323843'
              : theme.palette.mode === 'light'
              ? '#4A5C75'
              : '#ECF0F7'
          }
        />
      </Box>
      <Box
        className={clsx({
          [classes.toggleButton]: true,
          [classes.chartButton]: true,
          [classes.checked]: state.contentType === 'Chart',
        })}
        onClick={() => {
          handleAlignment('Chart')
        }}
      >
        <SVGIcon.LineChartIconSVG
          color={
            state.contentType !== 'Table'
              ? theme.palette.mode === 'light'
                ? '#ECF0F7'
                : '#323843'
              : theme.palette.mode === 'light'
              ? '#4A5C75'
              : '#ECF0F7'
          }
        />
      </Box>
    </Box>
  )
}

export default TableChartToggleButtonView
