const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '18'
  const color = props.color || '#41484E'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.7238 11H6.90713C6.74045 11 6.60547 11.1822 6.60547 11.4071V17.309C6.60547 17.5339 6.74045 17.7161 6.90713 17.7161H9.7238C9.89049 17.7161 10.0253 17.5339 10.0253 17.309V11.4068C10.0253 11.1819 9.89029 11 9.7238 11Z"
        fill={color}
      />
      <path
        d="M4.11834 13H1.30167C1.13498 13 1 13.0454 1 13.1015V17.6131C1 17.6692 1.13498 17.7146 1.30167 17.7146H4.11834C4.28502 17.7146 4.42 17.6692 4.42 17.6131V13.1015C4.42 13.0454 4.28502 13 4.11834 13Z"
        fill={color}
      />
      <path
        d="M15.3136 6.85938H12.497C12.3303 6.85938 12.1953 7.00684 12.1953 7.18894V17.3823C12.1953 17.5644 12.3303 17.7118 12.497 17.7118H15.3136C15.4803 17.7118 15.6153 17.5644 15.6153 17.3823V7.18894C15.6153 7.00706 15.4803 6.85938 15.3136 6.85938Z"
        fill={color}
      />
      <path d="M12 1H14.9996V4.27869" stroke={color} strokeWidth="1.5" />
      <path
        d="M1 11.3829L4.99947 7.01136L7.49915 9.19715L14.5 1.54688"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default SVG
