import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  Dispatch,
} from 'react'
import {
  notificationListApi,
  markAsReadApi,
  notificationActionApi,
  getFlashNotificationApi,
  setFlashNotificationApi,
  updateMonetizationApi,
  recordMonetizationApi,
} from 'src/api/dashboard'
import { getSdkLatestVersionApi } from 'src/api/game'
import { GlobalContext } from '../global'
import { transformUTC0ToLocalTime, versionSort, detectAdBlock } from 'src/utils'
import HTMLReactParser from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import env from 'src/env'

type Props = {
  notificationList: any[]
  notificationUnreadCount: number
  notificationLoading: boolean
  mobileMenuOpen: boolean
  flashNotification: any
  topBannerNotification: any
  gamePagePagination: any
  gamePageFromDetail: boolean
  monetizationLoading: boolean
  fromWelcome: boolean
  time: any
  gameSelect: any[]
  gameList: any[]
  latestSdkVersion: string
  sdkVersionList: any[]
  showAdblockAlert: boolean
  // 页面数据存储
  dashboardData: any
  adPlacementData: any
  engagementData: any
  stickinessData: any
  sessionsData: any
  retentionData: any
  churnRateData: any
  userActivityData: any
  performanceData: any
  installsData: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  notificationList: [],
  notificationUnreadCount: 0,
  notificationLoading: false,
  mobileMenuOpen: false,
  flashNotification: {},
  topBannerNotification: [],
  gamePagePagination: {},
  gamePageFromDetail: false,
  monetizationLoading: false,
  fromWelcome: false,
  time: [],
  gameSelect: [],
  gameList: [],
  latestSdkVersion: '',
  sdkVersionList: [],
  showAdblockAlert: false,
  dashboardData: {},
  adPlacementData: {},
  engagementData: {},
  stickinessData: {},
  sessionsData: {},
  retentionData: {},
  churnRateData: {},
  userActivityData: {},
  performanceData: {},
  installsData: {},
}
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'notificationList':
    case 'notificationLoading':
    case 'notificationUnreadCount':
    case 'flashNotification':
    case 'topBannerNotification':
    case 'gamePagePagination':
    case 'gamePageFromDetail':
    case 'monetizationLoading':
    case 'fromWelcome':
    case 'time':
    case 'gameSelect':
    case 'gameList':
    case 'latestSdkVersion':
    case 'sdkVersionList':
    case 'showAdblockAlert':
      return { ...prevState, [action.type]: action.payload }
    case 'setMobileMenuOpen':
      return { ...prevState, mobileMenuOpen: action.payload }
    case 'dashboardData':
    case 'adPlacementData':
    case 'engagementData':
    case 'stickinessData':
    case 'sessionsData':
    case 'retentionData':
    case 'churnRateData':
    case 'userActivityData':
    case 'performanceData':
    case 'installsData':
      return {
        ...prevState,
        [action.type]: { ...prevState[action.type], ...action.payload },
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
  onMobileMenuToggle: () => void
  onMobileMenuClose: () => void
  markAsRead: (item: number) => void
  setAction: (id: number) => void
  getFlashNotification: () => void
  setFlashNotification: (id: number) => void
  updateMonetization: (app_key: string, data: any, func?: any) => void
  recordMonetization: (app_key: string) => void
  finishToPage: () => void
}

export const GlobalStoreContext = createContext<Context>({
  state: initialState,
  dispatch: (action: Action) => {},
  onMobileMenuToggle: () => {},
  onMobileMenuClose: () => {},
  markAsRead: () => {},
  setAction: () => {},
  getFlashNotification: () => {},
  setFlashNotification: () => {},
  updateMonetization: (app_key: string, data: any, func?: any) => {},
  recordMonetization: (app_key: string) => {},
  finishToPage: () => {},
})

export const GlobalStoreContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const navigate = useNavigate()
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const {
    state: globalState,
    dispatch: globalDispatch,
    errorCatch,
    customAlert,
  } = useContext(GlobalContext)
  // handlers
  const onMobileMenuToggle = () => {
    dispatch({
      type: 'setMobileMenuOpen',
      payload: !state.mobileMenuOpen,
    })
  }
  const onMobileMenuClose = () => {
    dispatch({
      type: 'setMobileMenuOpen',
      payload: false,
    })
  }

  // effects
  // 记录弹框状态
  const setAction = (id: number, isBrowserNotification?: boolean) => {
    notificationActionApi(
      id,
      isBrowserNotification
        ? { browser_popped_up: true }
        : { interaction_action: 'CLOSE' }
    )
      .then(res => {
        if (isBrowserNotification) return
        const list = state.notificationList.map(
          (item: {
            time: string
            updated_at: string | number | Date
            is_read: boolean
            interaction_type: string
            interaction_action: null | string
            id: number
          }) => {
            if (Number(id) === Number(item.id)) {
              item.interaction_action = 'CLOSE'
            }
            return item
          }
        )
        dispatch({ type: 'notificationList', payload: list })
        dispatch({
          type: 'topBannerNotification',
          payload: state.topBannerNotification.filter(
            (item: any) => item.id !== id
          ),
        })
      })
      .catch(errorCatch)
  }
  // 发送浏览器消息
  const notify = (item: any) => {
    const Notification: any = window.Notification
    if (!Notification) return
    let n: any = null
    const body = {
      body: HTMLReactParser(item.content.replace(/<[^>]*>/g, '')),
      requireInteraction: true,
    }
    if (Notification.permission && Notification.permission === 'granted') {
      n = new Notification(item.title, body) // 显示
      n.onclick = function () {
        navigate('/dash/notifications?id=' + item.id)
        n.close()
      }
      n.onshow = function () {
        setAction(item.id, true)
        const timer = setTimeout(() => {
          n.close()
          clearTimeout(timer)
        }, 7000)
      }
    }
  }
  // 消息中心初始化 获取消息列表接口、已读
  const getNotification = () => {
    notificationListApi({ page: 1, page_size: 100 })
      .then(res => {
        let unread_count = 0
        const topBannerNotification: any = []
        const list = res.data.data.map(
          (item: {
            id: number
            time: string
            updated_at: string | number | Date
            is_read: boolean
            need_browser_pop_up: boolean
            browser_popped_up: boolean
            interaction_type: string
            interaction_action: null | string
          }) => {
            // 已读api有延迟，防止重新获取的notification状态未更新
            const isReadList: any = localStorage.getItem('isReadList')
            if (isReadList && JSON.parse(isReadList).includes(item.id)) {
              item.is_read = true
            }
            if (!item.is_read) {
              unread_count++
            }
            if (
              item.interaction_type === 'TOP_BANNER' &&
              item.interaction_action !== 'CLOSE' &&
              !item.is_read
            ) {
              topBannerNotification.push(item)
            }
            if (
              item.need_browser_pop_up &&
              !item.browser_popped_up &&
              !item.is_read
            ) {
              notify(item)
            }
            item.time = transformUTC0ToLocalTime(item.updated_at)
            return item
          }
        )
        dispatch({
          type: 'topBannerNotification',
          payload: topBannerNotification,
        })
        dispatch({ type: 'notificationList', payload: list })
        dispatch({ type: 'notificationUnreadCount', payload: unread_count })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'notificationLoading', payload: false })
        // 本地环境不重复调用消息api
        if (env.env === 'local') return
        // 存储global notification，为了logout时可以停止计时器
        globalDispatch({
          type: 'NOTIFICATION_TIMER',
          payload: setTimeout(() => {
            if (localStorage.getItem('access_token')) {
              getNotification()
            }
            clearTimeout(globalState.notificationTimer)
            globalDispatch({
              type: 'NOTIFICATION_TIMER',
              payload: null,
            })
          }, 10000),
        })
      })
  }
  useEffect(() => {
    localStorage.setItem('isReadList', '')
    const timer = setTimeout(() => {
      const Notification: any = window.Notification
      Notification.requestPermission(function (status: any) {
        // 这将使我们能在 Chrome/Safari 中使用 Notification.permission
        if (Notification.permission !== status) {
          Notification.permission = status
        }
      })
      clearTimeout(timer)
    }, 30000)
    dispatch({ type: 'notificationLoading', payload: true })
    getNotification()
    // eslint-disable-next-line
  }, [])
  const markAsRead = (markId: number) => {
    markAsReadApi(String(markId))
      .then(res => {
        // 已读api有延迟，防止重新获取的notification状态未更新
        const string: any = localStorage.getItem('isReadList')
        const isReadList: any = string ? JSON.parse(string) : []
        localStorage.setItem(
          'isReadList',
          JSON.stringify([...isReadList, markId])
        )
        let unread_count = 0
        const list = state.notificationList.map(
          (item: {
            time: string
            updated_at: string | number | Date
            is_read: boolean
            interaction_type: string
            id: number
          }) => {
            if (String(markId) === String(item.id)) {
              item.is_read = true
              if (item.interaction_type === 'TOP_BANNER') {
                dispatch({
                  type: 'topBannerNotification',
                  payload: state.topBannerNotification.filter(
                    (element: any) => element.id !== markId
                  ),
                })
              }
            }
            if (!item.is_read) {
              unread_count++
            }
            return item
          }
        )
        dispatch({ type: 'notificationList', payload: list })
        dispatch({ type: 'notificationUnreadCount', payload: unread_count })
      })
      .catch(errorCatch)
  }
  // 获取和修改Flash Notification弹框
  const getFlashNotification = () => {
    getFlashNotificationApi()
      .then((res: any) => {
        let data = {}
        if (res.data?.data?.length) {
          data = res.data.data[0]
        }
        dispatch({ type: 'flashNotification', payload: data })
      })
      .catch(errorCatch)
  }
  const setFlashNotification = (id: number) => {
    setFlashNotificationApi(id).catch(errorCatch)
  }
  // game manage/ add game页面记录game monetization弹框
  const updateMonetization = (app_key: string, data: any, func?: any) => {
    dispatch({ type: 'monetizationLoading', payload: true })
    updateMonetizationApi(app_key, data.data)
      .then(() => {
        if (data.getNewData) {
          data.updateListForOne(
            data.getNewData.app_key,
            data.getNewData.index,
            () => {
              customAlert('success', 'Pre-MAS metrics submitted successfully.')
              func && func()
              dispatch({ type: 'monetizationLoading', payload: false })
            }
          )
        } else {
          customAlert('success', 'Pre-MAS metrics submitted successfully.')
          func && func()
          dispatch({ type: 'monetizationLoading', payload: false })
        }
      })
      .catch((err: any) => {
        errorCatch(err)
        dispatch({ type: 'monetizationLoading', payload: false })
      })
  }
  const recordMonetization = (app_key: string) => {
    recordMonetizationApi(app_key).catch(errorCatch)
  }
  const finishToPage = () => {
    dispatch({ type: 'fromWelcome', payload: false })
  }
  // 获取总的游戏列表
  useEffect(() => {
    dispatch({
      type: 'gameList',
      payload: globalState.gameOption,
    })
  }, [globalState.gameOption])
  const getSdkLatestVersion = () => {
    getSdkLatestVersionApi()
      .then(({ data }) => {
        dispatch({
          type: 'latestSdkVersion',
          payload: data?.data?.latest_sdk_version || '',
        })
      })
      .catch(errorCatch)
  }
  useEffect(() => {
    getSdkLatestVersion()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    let sdkVersionArr: any = []
    state.gameList.forEach((item: any) => {
      if (
        item.latest_integrate_sdk_version &&
        !sdkVersionArr.includes(item.latest_integrate_sdk_version)
      ) {
        sdkVersionArr.push(item.latest_integrate_sdk_version)
      }
    })
    sdkVersionArr = versionSort(sdkVersionArr)
    const sdkVersionList: any = []
    if (!sdkVersionArr.includes(state.latestSdkVersion)) {
      sdkVersionArr.unshift(state.latestSdkVersion)
    }
    sdkVersionList.push(
      ...sdkVersionArr.map((item: string) => {
        if (item === state.latestSdkVersion) {
          return {
            value: item,
            label: item + ' (Latest)',
          }
        }
        return { value: item, label: item }
      })
    )
    sdkVersionList.unshift({
      value: '',
      label: 'All',
    })
    dispatch({
      type: 'sdkVersionList',
      payload: sdkVersionList || [],
    })

    // eslint-disable-next-line
  }, [state.gameList, state.latestSdkVersion])
  useEffect(() => {
    detectAdBlock(() => {
      dispatch({
        type: 'showAdblockAlert',
        payload: true,
      })
    })
  }, [])
  // returns
  return (
    <GlobalStoreContext.Provider
      value={{
        state,
        dispatch,
        onMobileMenuToggle,
        onMobileMenuClose,
        markAsRead,
        setAction,
        getFlashNotification,
        setFlashNotification,
        updateMonetization,
        recordMonetization,
        finishToPage,
      }}
    >
      {children}
    </GlobalStoreContext.Provider>
  )
}
