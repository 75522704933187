const SVG = (props: any) => {
  const width = props.width || '14'
  const height = props.height || '17'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M0.99243 5.42154C0.459622 5.42154 0.00292969 5.88137 0.00292969 6.41783V10.5562C0.00292969 11.0927 0.459622 11.5525 0.99243 11.5525C1.5633 11.5525 1.98193 11.0927 1.98193 10.5562V6.37951C2.01999 5.84305 1.5633 5.42154 0.99243 5.42154ZM9.06066 1.66632L9.7457 0.401801C9.78376 0.325164 9.7457 0.248527 9.70764 0.210208C9.63153 0.171889 9.55541 0.210208 9.51736 0.248527L8.79426 1.51304C8.18534 1.24481 7.53836 1.09154 6.85332 1.09154C6.16828 1.09154 5.48324 1.24481 4.91237 1.51304L4.18928 0.286845C4.15122 0.210208 4.0751 0.210208 3.99899 0.210208C3.92287 0.248527 3.92287 0.325164 3.96093 0.401801L4.64597 1.66632C3.27589 2.35605 2.36251 3.69721 2.36251 5.22995H11.3441C11.3441 3.73552 10.4307 2.39437 9.06066 1.66632ZM4.7982 3.62057C4.60791 3.62057 4.41762 3.46729 4.41762 3.23738C4.41762 3.04579 4.56985 2.85419 4.7982 2.85419C4.98849 2.85419 5.17878 3.00747 5.17878 3.23738C5.17878 3.46729 5.02655 3.62057 4.7982 3.62057ZM8.90843 3.62057C8.71814 3.62057 8.52786 3.46729 8.52786 3.23738C8.52786 3.04579 8.68009 2.85419 8.90843 2.85419C9.09872 2.85419 9.28901 3.00747 9.28901 3.23738C9.28901 3.46729 9.09872 3.62057 8.90843 3.62057ZM2.40056 5.61314V12.0507C2.40056 12.6255 2.89532 13.1236 3.46618 13.1236H4.18928V15.3078C4.18928 15.8442 4.64597 16.304 5.17878 16.304C5.74964 16.304 6.16828 15.8442 6.16828 15.3078V13.1236H7.5003V15.3078C7.5003 15.8442 7.95699 16.304 8.4898 16.304C9.06066 16.304 9.4793 15.8442 9.4793 15.3078V13.1236H10.2024C10.7733 13.1236 11.268 12.6638 11.268 12.0507V5.61314H2.40056ZM13.7037 6.41783C13.7037 5.88137 13.247 5.42154 12.7142 5.42154C12.1814 5.42154 11.7247 5.88137 11.7247 6.41783V10.5946C11.7247 11.131 12.1814 11.5908 12.7142 11.5908C13.247 11.5908 13.7037 11.131 13.7037 10.5946V6.41783Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
