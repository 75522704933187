import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StylePaper } from 'src/components'
import TopFilterView from './topFilter'
import RevenueChartView from './revenueChart'
import SummaryView from './summaryTable'
import ChartTabView from './tabs'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        padding: '20px 24px 100px',
        boxSizing: 'border-box',
        width: '100%',
      },
    }
  })

const TableListView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  return (
    <>
      <TopFilterView />
      <Box className={classes.container} id="adPlacements-content">
        <Box marginTop="24px">
          <ChartTabView />
          <StylePaper
            width="100%"
            boxSizing="border-box"
            borderRadius="0 8px 8px"
            padding="24px 16px"
          >
            <RevenueChartView />
          </StylePaper>
        </Box>
        <StylePaper
          width="100%"
          boxSizing="border-box"
          marginTop="24px"
          id="adPlacements-summary"
          padding="24px 16px"
        >
          <SummaryView />
        </StylePaper>
      </Box>
    </>
  )
}

export default TableListView
