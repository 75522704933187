import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Check as CheckIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { GlobalContext } from 'src/context/global'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      top: {
        position: 'relative',
        padding: '27px 30px',
        fontSize: '27px',
        fontWeight: 900,
        color: theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      platformIcon: {
        position: 'absolute',
        height: '56px',
        margin: 'auto',
        left: '0',
        right: '0',
      },
      platformCheckBox: {
        boxSizing: 'border-box',
        position: 'absolute',
        width: '20px',
        height: '20px',
        right: '8px',
        top: '8px',
        border: '1px solid #707070',
        borderRadius: '50%',
      },
      radioCheckedBox: {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      checkIcon: {
        position: 'absolute',
        margin: 'auto',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        fontSize: '14px !important',
        color: `#FFF !important`,
      },
      checkBox: {
        position: 'absolute',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        background: theme.palette.primary.main,
        margin: 'auto',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      radioBox: {
        position: 'relative',
        boxSizing: 'border-box',
        width: 140,
        height: 140,
        borderRadius: '8px',
        border: `1px solid ${
          theme.palette.mode === 'light' ? '#E0E5E7' : 'rgba(255,255,255,0.5)'
        }`,
        padding: '23px',
        cursor: 'pointer',
        margin: '8px',
        userSelect: 'none',
      },
      checked: {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      checkedBox: {
        background: theme.palette.primary.main,
        border: '0 none',
      },
      multiple: {
        borderRadius: '4px',
      },
    }
  })
const RadioBoxView = (props: any) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state: globalState } = useContext(GlobalContext)
  return (
    <Box
      className={clsx({
        [classes.radioBox]: true,
        [classes.checked]: props.checked,
      })}
      onClick={props.click}
      sx={
        globalState.isMobile ? { transform: 'scale(0.8)', margin: '-8px' } : {}
      }
    >
      {props.img ? (
        <img className={classes.platformIcon} src={props.img} alt="" />
      ) : (
        <props.icon
          width="56px"
          height="56px"
          color={
            theme.palette.mode === 'light'
              ? ''
              : props.checked
              ? theme.palette.primary.main
              : '#FFF'
          }
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            margin: 'auto',
          }}
        />
      )}
      <Box
        marginTop="68px"
        textAlign="center"
        width="104px"
        marginLeft="-7px"
        style={
          theme.palette.mode === 'dark' && props.checked
            ? { color: theme.palette.primary.main }
            : {}
        }
      >
        {props.label}
      </Box>
      {props.multiple ? (
        <Box
          className={clsx({
            [classes.platformCheckBox]: true,
            [classes.checkedBox]: props.checked,
            [classes.multiple]: props.multiple,
          })}
        >
          {props.checked && <CheckIcon className={classes.checkIcon} />}
        </Box>
      ) : (
        <Box
          className={clsx({
            [classes.platformCheckBox]: true,
            [classes.radioCheckedBox]: props.checked,
          })}
        >
          {props.checked && <Box className={classes.checkBox} />}
        </Box>
      )}
    </Box>
  )
}
export default RadioBoxView
