import { ReactNode, useState } from 'react'
import { Popover, Typography, useTheme, Theme } from '@material-ui/core'
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons'

const PopoverTipView = (props: { children: ReactNode }) => {
  const theme: Theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleClick = (event: React.MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <InfoOutlinedIcon
        onClick={handleClick}
        sx={{
          fontSize: '18px !important',
          verticalAlign: 'middle',
          marginLeft: '6px',
          color: theme.palette.text.secondary,
          marginTop: '-1px',
          cursor: 'pointer',
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
            boxShadow:
              theme.palette.mode === 'dark'
                ? '0 0 5px #444'
                : '0 0 5px #CCCCCC',
          },
        }}
      >
        <Typography
          sx={{
            p: 2,
            maxWidth: '880px',
            fontSize: '14px',
            color: theme.palette.mode === 'dark' ? '#FFF' : '#7487AA',
          }}
        >
          {props.children}
        </Typography>
      </Popover>
    </>
  )
}

export default PopoverTipView
