import { Box, useMediaQuery } from '@material-ui/core'
import { useContext } from 'react'
import { SessionsContext } from 'src/context/gamePlayInsights/sessions'
import RetentionChartView from './retention/retention'
import ChurnRateChartView from './churnRate/churnRate'
import SessionsView from './sessions/sessions'
import StickinessView from './stickiness/stickiness'
import InstallsView from './installs'
import RetentionListView from './retention/retentionList'
import ChurnRateListView from './churnRate/churnRateList'
import RetentionTopFilterView from './retention/retentionTopFilter'
import StickinessTopFilterView from './stickiness/stickinessTopFilter'
import SessionTopFilterView from './sessions/sessionTopFilter'
import InstallsTopFilterView from './installs/installsTopFilter'
import TabsView from './tabs'
import { StylePaper } from 'src/components'

const GameplayInsightsView = (): JSX.Element => {
  const { state } = useContext(SessionsContext)
  const isDesktop = useMediaQuery('(min-width:1280px)')
  // returns
  return (
    <>
      {state.tabType === 'retention' || state.tabType === 'churn-rate' ? (
        <RetentionTopFilterView />
      ) : state.tabType === 'stickiness' ? (
        <StickinessTopFilterView />
      ) : state.tabType === 'installs' ? (
        <InstallsTopFilterView />
      ) : (
        <SessionTopFilterView />
      )}
      <Box padding="44px 24px 100px">
        <TabsView />
        <StylePaper
          width="100%"
          marginBottom="24px"
          padding="24px 16px"
          boxSizing="border-box"
          borderRadius="0 8px 8px"
        >
          {state.tabType === 'retention' ? (
            <RetentionChartView />
          ) : state.tabType === 'churn-rate' ? (
            <ChurnRateChartView />
          ) : state.tabType === 'stickiness' ? (
            <StickinessView />
          ) : state.tabType === 'installs' ? (
            <InstallsView />
          ) : (
            <SessionsView />
          )}
        </StylePaper>
        <>
          {(state.tabType === 'retention' ||
            state.tabType === 'churn-rate') && (
            <StylePaper
              width="100%"
              // 兼容safari宽度过宽问题
              maxWidth={
                isDesktop ? 'calc(100vw - 280px)' : 'calc(100vw - 48px)'
              }
              padding="24px"
              boxSizing="border-box"
            >
              {state.tabType === 'retention' ? (
                <RetentionListView />
              ) : (
                <ChurnRateListView />
              )}
            </StylePaper>
          )}
        </>
      </Box>
    </>
  )
}

export default GameplayInsightsView
