import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  Dispatch,
} from 'react'

import {
  settingEmailChangeApi,
  settingEmailCodeApi,
  change2FAApi,
} from 'src/api'
import { GlobalContext } from 'src/context/global'
import { VerifyEmailForm } from './type'
import { md5Password } from 'src/utils'

type Props = {
  openChangeEmail: boolean
  openEmailSuccess: boolean
  codeLoading: boolean
  verifyLoading: boolean
  emailCodeInterval: number
  twoFaLoading: boolean
  twoFaDialog: boolean
  twoFaStatus: boolean
  updateButtonLoading: boolean
}

enum Actions {
  TOGGLE_OPEN_CHANGE_EMAIL = 'TOGGLE_OPEN_CHANGE_EMAIL',
  TOGGLE_OPEN_EMAIL_SUCCESS = 'TOGGLE_OPEN_EMAIL_SUCCESS',
  TOGGLE_CODE_LOADING = 'TOGGLE_CODE_LOADING',
  TOGGLE_VERIFY_LOADING = 'TOGGLE_VERIFY_LOADING',
  EMAIL_CODE_INTERVAL = 'EMAIL_CODE_INTERVAL',
  TWO_FA_LOADING = 'TWO_FA_LOADING',
  TWO_FA_DIALOG = 'TWO_FA_DIALOG',
  TWO_FA_STATUS = 'TWO_FA_STATUS',
  UPDATE_BUTTON_LOADING = 'UPDATE_BUTTON_LOADING',
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  openChangeEmail: false,
  openEmailSuccess: false,
  codeLoading: false,
  verifyLoading: false,
  emailCodeInterval: 0,
  twoFaLoading: false,
  twoFaDialog: false,
  twoFaStatus: false,
  updateButtonLoading: false,
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case Actions.TOGGLE_OPEN_CHANGE_EMAIL:
      return {
        ...prevState,
        openChangeEmail: action.payload,
      }
    case Actions.TOGGLE_OPEN_EMAIL_SUCCESS:
      return {
        ...prevState,
        openEmailSuccess: action.payload,
      }
    case Actions.TOGGLE_CODE_LOADING:
      return {
        ...prevState,
        codeLoading: action.payload,
      }
    case Actions.TOGGLE_VERIFY_LOADING:
      return {
        ...prevState,
        verifyLoading: action.payload,
      }
    case Actions.EMAIL_CODE_INTERVAL:
      return {
        ...prevState,
        emailCodeInterval: action.payload,
      }
    case Actions.TWO_FA_LOADING:
      return {
        ...prevState,
        twoFaLoading: action.payload,
      }
    case Actions.TWO_FA_DIALOG:
      return {
        ...prevState,
        twoFaDialog: action.payload,
      }
    case Actions.TWO_FA_STATUS:
      return {
        ...prevState,
        twoFaStatus: action.payload,
      }
    case Actions.UPDATE_BUTTON_LOADING:
      return {
        ...prevState,
        updateButtonLoading: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type Context = {
  state: Props
  dispatch: Dispatch<Action>
  handleEmailOpen: () => void
  handleEmailClose: () => void
  verifyEmail: (params: VerifyEmailForm) => void
  emailSendCode: (email: string, firstSent?: boolean) => void
  toggleEmailSuccess: (open: boolean) => void
  toggleTwoFa: (passwd: string, two_fa: boolean) => void
}

export const SettingsContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  handleEmailOpen: () => {},
  handleEmailClose: () => {},
  verifyEmail: () => {},
  emailSendCode: () => {},
  toggleEmailSuccess: () => {},
  toggleTwoFa: () => {},
})
let timer: any = null
export const SettingsContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const {
    state: globalState,
    customAlert,
    errorCatch,
    setAccountEditKeyHandle,
    logout,
  } = useContext(GlobalContext)

  const emailCodeIntervalInit = () => {
    let seconds = 60
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    dispatch({
      type: Actions.EMAIL_CODE_INTERVAL,
      payload: seconds,
    })
    timer = setInterval(() => {
      dispatch({
        type: Actions.EMAIL_CODE_INTERVAL,
        payload: --seconds,
      })

      if (!seconds) {
        clearInterval(timer)
        timer = null
      }
    }, 1000)
  }

  const toggleEmailSuccess = (open: boolean) => {
    dispatch({
      type: Actions.TOGGLE_OPEN_EMAIL_SUCCESS,
      payload: open,
    })
  }

  const verifyEmail = (params: VerifyEmailForm) => {
    if (state.verifyLoading) return
    dispatch({
      type: Actions.TOGGLE_VERIFY_LOADING,
      payload: true,
    })
    settingEmailChangeApi({
      email: params.email,
      verify_code: params.code,
    })
      .then(() => {
        dispatch({
          type: Actions.TOGGLE_OPEN_CHANGE_EMAIL,
          payload: false,
        })
        customAlert(
          'success',
          'Email address updated successfully. Please use your new email to sign in.'
        )
        toggleEmailSuccess(true)
        setAccountEditKeyHandle('')
        logout(true)
      })
      .catch(errorCatch)
      .finally(() =>
        dispatch({
          type: Actions.TOGGLE_VERIFY_LOADING,
          payload: false,
        })
      )
  }

  const emailSendCode = (email: string, firstSent?: boolean) => {
    if (state.codeLoading) return
    dispatch({
      type: firstSent
        ? Actions.UPDATE_BUTTON_LOADING
        : Actions.TOGGLE_CODE_LOADING,
      payload: true,
    })

    settingEmailCodeApi({ email })
      .then(() => {
        emailCodeIntervalInit()
        customAlert(
          'success',
          `A verification code has been sent to "${email}".`
        )
        if (firstSent) {
          handleEmailOpen()
        }
      })
      .catch(errorCatch)
      .finally(() =>
        dispatch({
          type: firstSent
            ? Actions.UPDATE_BUTTON_LOADING
            : Actions.TOGGLE_CODE_LOADING,
          payload: false,
        })
      )
  }

  // modify email
  const handleEmailOpen = () => {
    dispatch({
      type: Actions.TOGGLE_OPEN_CHANGE_EMAIL,
      payload: true,
    })
  }

  const handleEmailClose = () => {
    dispatch({
      type: Actions.TOGGLE_OPEN_CHANGE_EMAIL,
      payload: false,
    })
  }

  const toggleTwoFa = (passwd: string, two_fa: boolean) => {
    if (state.twoFaLoading) {
      return
    }
    dispatch({
      type: Actions.TWO_FA_LOADING,
      payload: true,
    })
    change2FAApi({ passwd: md5Password(passwd), two_fa })
      .then((res: any) => {
        dispatch({
          type: Actions.TWO_FA_STATUS,
          payload: !state.twoFaStatus,
        })
        customAlert('success', 'Successfully')
        dispatch({
          type: Actions.TWO_FA_DIALOG,
          payload: false,
        })
        if (two_fa === false) {
          localStorage.removeItem(globalState.userInfo.email)
        }
      })
      .catch(errorCatch)
      .finally(() =>
        dispatch({
          type: Actions.TWO_FA_LOADING,
          payload: false,
        })
      )
  }
  useEffect(() => {
    if (globalState.userInfo) {
      dispatch({
        type: Actions.TWO_FA_STATUS,
        payload: globalState.userInfo.two_fa,
      })
    }
    // eslint-disable-next-line
  }, [globalState.userInfo])

  // returns
  return (
    <SettingsContext.Provider
      value={{
        state,
        dispatch,
        handleEmailOpen,
        handleEmailClose,
        verifyEmail,
        emailSendCode,
        toggleEmailSuccess,
        toggleTwoFa,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
