import { colors, ThemeOptions } from '@material-ui/core'

const defaultTheme = (isDarkMode = false): ThemeOptions => {
  const lightTheme = {
    text: {
      primary: '#1D3353',
      secondary: '#7487AA',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      paper: '#fff',
      default: '#f3f6f8',
    },
    custom: {
      loading: colors.blue[900],
      activeShadow: '#0F3D6A',
      maskBackground: 'rgba(255, 255, 255, 0.5)',
      chartLine: '#dfe6f1',
      scoreCard: '#f1f1f1',
      mainTransparent: 'background: rgba(255, 142, 0, 0.1)',
      sideBarBg: '#FFF3E5',
      notificationBg: '#FFF3E5',
      sideBarText: '#7487AA',
      inputLabel: '#41484E',
      tableHead: 'rgba(0, 0, 0, 0.5)',
      tableBorder: '#F2F2F2',
      dialogBg: '#FFF',
      inputBorder: '#7487AA',
      paperBorder: '#DBE8F8',
      tipBorder: 'rgba(230, 49, 33, 0.2)',
      tipBg: '#FFF2F1',
      listBorderBottom: '#EFF2F3',
      appearanceIconColor: '#7487AA',
      appearanceIconActiveColor: '#4A566D',
      appearanceIconBg: '#DBE8F8',
      dialogButton: '#364259',
      progressBg: '#E0E5E7',
      progressContentStartColor: '#F07A38',
      progressContentEndColor: '#F6A84D',
      editIconButton: '#EFF2F3',
      retentionFilterBg: '#FEFEFE',
      retentionFilterBorder: 'rgba(0, 0, 0, 0.07)',
      chartTableBg: 'rgba(255, 247, 238, 1)',
    },
  }
  const darkTheme = {
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.8)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)',
    },
    background: {
      paper: '#1A202C',
      default: '#242a36',
    },
    custom: {
      loading: colors.blue[600],
      activeShadow: colors.blue[600],
      maskBackground: 'rgba(66, 66, 66, 0.5)',
      chartLine: '#616161',
      scoreCard: '#616161',
      mainTransparent: '#FFB23F',
      sideBarBg: 'rgba(255, 133, 0, 0.05)',
      notificationBg: 'rgba(255, 142, 0, 0.1)',
      sideBarText: '#888E9A',
      inputLabel: '#FFF',
      tableHead: '#FFF',
      tableBorder: 'rgba(242,242,242,0.1)',
      dialogBg: '#2B3445',
      inputBorder: '#51565F',
      paperBorder: '#51565F',
      tipBorder: 'rgba(230, 49, 33, 0.2)',
      tipBg: 'rgba(230, 49, 33, 0.1)',
      listBorderBottom: '#303540',
      appearanceIconColor: '#4A566D',
      appearanceIconActiveColor: '#FFFFFF',
      appearanceIconBg: '#1C222E',
      dialogButton: '#C9CED7',
      progressBg: '#909597',
      progressContentStartColor: '#F07A38',
      progressContentEndColor: '#F6A84D',
      editIconButton: 'rgba(255, 255, 255, 0.1)',
      retentionFilterBg: '#1f242f',
      retentionFilterBorder: 'rgba(255, 255, 255, 0.07)',
      chartTableBg: 'rgba(255, 133, 0, 0.07)',
    },
  }
  const baseTheme = isDarkMode ? darkTheme : lightTheme

  return {
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#FF8E00',
        contrastText: '#ffffff',
        dark: '#ff7a08',
      },
      secondary: {
        main: '#29444f',
        contrastText: '#ffffff',
      },
      // custom attribute
      custom: {
        loading: baseTheme.custom.loading,
        activeShadow: baseTheme.custom.activeShadow,
        maskBackground: baseTheme.custom.maskBackground,
        chartLine: baseTheme.custom.chartLine,
        scoreCard: baseTheme.custom.scoreCard,
        mainTransparent: baseTheme.custom.mainTransparent,
        sideBarBg: baseTheme.custom.sideBarBg,
        notificationBg: baseTheme.custom.notificationBg,
        sideBarText: baseTheme.custom.sideBarText,
        inputLabel: baseTheme.custom.inputLabel,
        tableHead: baseTheme.custom.tableHead,
        tableBorder: baseTheme.custom.tableBorder,
        dialogBg: baseTheme.custom.dialogBg,
        inputBorder: baseTheme.custom.inputBorder,
        paperBorder: baseTheme.custom.paperBorder,
        tipBorder: baseTheme.custom.tipBorder,
        tipBg: baseTheme.custom.tipBg,
        listBorderBottom: baseTheme.custom.listBorderBottom,
        appearanceIconColor: baseTheme.custom.appearanceIconColor,
        appearanceIconActiveColor: baseTheme.custom.appearanceIconActiveColor,
        appearanceIconBg: baseTheme.custom.appearanceIconBg,
        dialogButton: baseTheme.custom.dialogButton,
        progressBg: baseTheme.custom.progressBg,
        progressContentStartColor: baseTheme.custom.progressContentStartColor,
        progressContentEndColor: baseTheme.custom.progressContentEndColor,
        editIconButton: baseTheme.custom.editIconButton,
        retentionFilterBg: baseTheme.custom.retentionFilterBg,
        retentionFilterBorder: baseTheme.custom.retentionFilterBorder,
        chartTableBg: baseTheme.custom.chartTableBg,
      },
      background: {
        paper: baseTheme.background.paper,
        default: baseTheme.background.default,
      },
      text: {
        primary: baseTheme.text.primary,
        secondary: baseTheme.text.secondary,
        disabled: baseTheme.text.disabled,
      },
    },
    typography: {
      fontFamily: ['Inter', 'Lato', 'Helvetica', 'Roboto', 'Rubik'].join(','),
      body2: {
        color: '#707070',
      },
      button: {
        textTransform: 'none',
        fontSize: '14px',
        height: '40px',
        borderRadius: '6px',
        fontWeight: 700,
      },
    },
    components: {
      MuiTablePagination: {
        styleOverrides: {
          root: {
            '& .MuiTablePagination-actions .MuiButtonBase-root': {
              width: '32px',
              height: '32px',
              background: isDarkMode ? '#383E4A' : '#41484E',
              color: '#FFF',
              borderRadius: '8px 0 0 8px',
              '&:last-child': {
                borderRadius: '0 8px 8px 0',
              },
              '&.Mui-disabled': {
                color: '#6e6e6e',
              },
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            color: '#29444f',
            backgroundColor: baseTheme.background.default,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            color: '#707070',
          },
          subtitle2: {
            fontWeight: 700,
            color: '#dedede !important',
            textDecoration: 'none !important',
            '&:hover': {
              textDecoration: 'underline !important',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            fontSize: '14px',
          },
          underlineHover: {
            fontWeight: 700,
            color: '#dedede',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            boxShadow: 'none',
            borderRadius: '6px',
            padding: '12px 16px',
            '&.Mui-disabled': {
              background: '#FF8E00',
              color: '#FFF',
              opacity: 0.5,
            },
            '&.MuiButton-containedSecondary.Mui-disabled': {
              background: '#29444f',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#6E6E6E',
            height: '35px',
            fontSize: '14px',
            boxSizing: 'border-box',
            boxShadow: '0px 3px 6px #0000001C',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            '&.MuiInput-underline': {
              border: '1px solid #fff',
            },
            '&::after': {
              display: 'none',
            },
            '&:hover': {
              borderColor: '#ccc',
            },
            '&.Mui-focused': {
              borderColor: baseTheme.text.hint,
            },
            '& .MuiInputBase-input': {
              color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : '#102542',
              padding: '18px 0',
              marginLeft: '8px',
            },
            ' .MuiSvgIcon-root': {
              fontSize: '18px',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            height: '40px',
            boxShadow: 'none',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: isDarkMode ? '#787c83' : '#eeefef',
            },
            '&:focus-within .MuiOutlinedInput-notchedOutline': {
              borderColor: isDarkMode ? '#707274' : '#efefef',
            },
            '& fieldset': {
              borderColor: isDarkMode
                ? baseTheme.custom.inputBorder
                : '#eee !important',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              borderColor: '',
            },
            '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: isDarkMode
                ? baseTheme.custom.inputBorder
                : '#eee !important',
            },
            '&.Mui-disabled:focus-within .MuiOutlinedInput-notchedOutline ': {
              borderColor: isDarkMode
                ? baseTheme.custom.inputBorder
                : '#eee !important',
            },
          },
          input: {
            boxSizing: 'border-box',
          },
          inputAdornedStart: {
            margin: '0 10px',
            paddingLeft: '10px',
            color: colors.grey[500],
          },
          adornedStart: {
            paddingLeft: '20px',
            color: colors.grey[300],
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottom: '0 none',
            },
            '&:hover&:before': {
              borderBottom: '0 none',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '14px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '& .MuiList-root': {
              fontSize: '12px',
              fontWeight: 400,
              background: baseTheme.custom.dialogBg,
              color: baseTheme.custom.sideBarText,
              '& .MuiMenuItem-root:hover': {
                color: baseTheme.text.primary,
                background: isDarkMode
                  ? 'rgba(255, 255, 255, 0.2)'
                  : 'rgba(224, 229, 231, 0.5)',
              },
              '& .Mui-selected': {
                fontWeight: 500,
                color: baseTheme.text.primary,
                background: isDarkMode
                  ? 'rgba(255, 255, 255, 0.2)'
                  : 'rgba(224, 229, 231, 0.5)',
              },
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: '16px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${
              isDarkMode ? 'rgba(242,242,242,0.1)' : '#F2F2F2'
            }`,
          },
        },
      },
    },
  }
}

export default defaultTheme
