import { useContext } from 'react'
import { TestDeviceContextProvider } from 'src/context/testDevice'
import { GlobalContext } from 'src/context/global'
import TableListView from './tableList'
import MobileTableListView from './mobileTableList'

const TestDevices = (): JSX.Element => {
  const { state: globalState } = useContext(GlobalContext)
  // returns
  return (
    <TestDeviceContextProvider>
      {globalState.isMobile ? <MobileTableListView /> : <TableListView />}
    </TestDeviceContextProvider>
  )
}

export default TestDevices
