import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { motion } from 'framer-motion'

/**
 * framer-motion docs:
 * https://www.framer.com/api/motion/examples/#more
 */

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        position: 'relative',
        height: '90px',
        width: '90px',
      },
      dot: {
        height: '14px',
        width: '14px',
        backgroundColor: theme.palette.custom?.loading,
        borderRadius: '50%',
        position: 'absolute',
        '&:nth-child(1)': {
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        },
        '&:nth-child(2)': {
          bottom: '50%',
          right: 0,
          transform: 'translateY(50%)',
        },
        '&:nth-child(3)': {
          bottom: 0,
          right: '50%',
          transform: 'translateX(50%)',
        },
        '&:nth-child(4)': {
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
        },
      },
    }
  })

const Component = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  return (
    <motion.div
      className={classes.container}
      animate={{
        scale: [1, 1, 0.6, 0.6, 1, 1, 1, 1, 1, 1, 0.6, 0.6, 1],
        rotate: [0, 0, 0, 0, 90, 90, 0, 0, 180, 180, 0, 0, 0],
      }}
      transition={{
        duration: 3.6,
        repeat: Infinity,
      }}
    >
      <Box className={classes.dot} />
      <Box className={classes.dot} />
      <Box className={classes.dot} />
      <Box className={classes.dot} />
    </motion.div>
  )
}

export default Component
