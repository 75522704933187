import axios from 'axios'
import { localStorageKey } from 'src/context/type'

import env from 'src/env'

const getAuthHeader = () => {
  const token = localStorage.getItem(localStorageKey.ACCESS_TOKEN)
  if (token) {
    return {
      Authorization: 'Bearer ' + token,
    }
  } else {
    return {}
  }
}

const ssoService = axios.create({
  baseURL: env.ssoUrl,
  timeout: 50 * 1000,
})

const portalService = axios.create({
  baseURL: env.apiUrl,
  timeout: 50 * 1000,
})

portalService.interceptors.request.use(
  config => {
    config.headers = getAuthHeader()
    return config
  },
  error => {
    Promise.reject(error)
  }
)

portalService.interceptors.response.use(
  response => {
    const { status } = response
    switch (status) {
      case 200:
        return response
      default:
        return Promise.reject(new Error('Network Error'))
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export { portalService, ssoService }
