import { Box, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FileCopyOutlined as FileCopyIcon } from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '14px !important',
    verticalAlign: 'text-top',
    color: '#B0B5B9',
  },
  bigIcon: {
    fontSize: '24px !important',
  },
  StyleBox: {
    display: 'inline-block',
    textAlign: 'left',
    cursor: 'pointer',
  },
}))

interface ClickCOpyTextProps {
  value: string
  showText?: boolean
  showIcon?: boolean
  style?: any
  leftIcon?: boolean
  inputIcon?: boolean
}

const ClickCopyText = (props: ClickCOpyTextProps): JSX.Element => {
  const {
    value,
    showIcon = false,
    showText = true,
    leftIcon = false,
    inputIcon = false,
  } = props
  const classes = useStyles()
  const [copied, setCopied] = useState(false)
  const tooltipTitle = useMemo(
    () => (copied ? 'Copied' : 'Click to copy'),
    [copied]
  )

  const copyContent = () => {
    setCopied(true)
    const openTimer = setTimeout(() => {
      setCopied(false)
      clearTimeout(openTimer)
    }, 2000)
  }
  return (
    <Tooltip
      data-testid="tool-tip"
      title={tooltipTitle}
      arrow
      placement="top"
      style={props.style}
      disableInteractive
    >
      <Box className={classes.StyleBox}>
        <CopyToClipboard text={value} onCopy={copyContent}>
          <Box className={classes.StyleBox} data-testid="copy-content">
            {showText && !leftIcon ? value : ''}
            {showIcon && (
              <FileCopyIcon
                data-testid="file-copy-icon"
                style={{
                  marginLeft: showText && !leftIcon ? 8 : 0,
                  marginRight: showText && leftIcon ? 8 : 0,
                }}
                className={clsx({
                  [classes.icon]: true,
                  [classes.bigIcon]: inputIcon,
                })}
              />
            )}
            {showText && leftIcon ? value : ''}
          </Box>
        </CopyToClipboard>
      </Box>
    </Tooltip>
  )
}

export default ClickCopyText
