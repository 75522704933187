import Dashboard from './dashboard'
import { DashContextProvider } from 'src/context/dashboard'

const Dash = (): JSX.Element => {
  // returns
  return (
    <DashContextProvider>
      <Dashboard />
    </DashContextProvider>
  )
}

export default Dash
