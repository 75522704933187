import { OpenInNew as OpenInNewIcon } from '@material-ui/icons'

const OpenInNewIconView = (props: any) => {
  return (
    <OpenInNewIcon
      style={{
        fontSize: props.fontSize || '18px',
        verticalAlign: props.verticalAlign || 'middle',
        marginLeft: props.marginLeft || '4px',
        marginTop: '-2px',
      }}
    />
  )
}

export default OpenInNewIconView
