import { portalService } from './service'

export const getCohortsApi = (params: any) => {
  return portalService({
    url: `/game/${params.app_key}/retention/v2`,
    method: 'get',
    params,
  })
}
export const getStickinessApi = (params: any) => {
  return portalService({
    url: `/metric/stickiness/search/${params.app_key}`,
    method: 'get',
    timeout: 120 * 1000,
  })
}
export const getUserActivityApi = (data: any) => {
  return portalService({
    url: `/metric/engagement/search`,
    method: 'post',
    data,
  })
}

export const getRevenueDeuApi = (data: any) => {
  return portalService({
    url: `/metric/revenue_deu/search`,
    method: 'post',
    data,
  })
}
export const getSessionApi = (data: any) => {
  return portalService({
    url: `/metric/session/search`,
    method: 'post',
    data,
  })
}
