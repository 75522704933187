import { Box } from '@material-ui/core'
import { SVGIcon } from 'src/images'

const iconObject = {
  banner: SVGIcon.bannerInnerSVG,
  interstitial: SVGIcon.interstitialInnerSVG,
  reward_video: SVGIcon.reward_videoInnerSVG,
  nativeads: SVGIcon.native_adsInnerSVG,
  openads: SVGIcon.OpenAdsInnerSVG,
  rewardinter: SVGIcon.RewardinterInnerSVG,
}
const UpsellActiveAdsView = (props: any) => {
  const ActiveAdsItem = (itemProps: any) => {
    const { value } = itemProps
    const Icon = iconObject[value]
    return <Icon style={{ opacity: 0.5 }} color="#FF8E00" />
  }

  return (
    <Box
      display="flex"
      justifyContent={props.isMobile ? 'flex-start' : 'center'}
      alignItems="center"
      gap="10px"
      flexWrap="wrap"
      paddingTop="2px"
    >
      <ActiveAdsItem value="banner" />
      <ActiveAdsItem value="interstitial" />
      <ActiveAdsItem value="reward_video" />
      <ActiveAdsItem value="nativeads" />
      <ActiveAdsItem value="openads" />
      <ActiveAdsItem value="rewardinter" />
    </Box>
  )
}

export default UpsellActiveAdsView
