const SVG = (props: any) => {
  const width = props.width || '26'
  const height = props.height || '26'
  const color1 = props.color1 || '#1E96FC'
  const color2 = props.color2 || '#F3F9FF'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="26" rx="4" fill={color2} />
      <path
        d="M21.5172 10.1937C21.3362 9.98695 21.1136 9.82136 20.8643 9.70796C20.6149 9.59456 20.3444 9.53594 20.0709 9.536H15.571V8.24C15.571 7.3807 15.2324 6.55659 14.6296 5.94897C14.0268 5.34136 13.2093 5 12.3569 5C12.2374 4.99991 12.1203 5.03336 12.0187 5.0966C11.9171 5.15984 11.835 5.25036 11.7815 5.35802L8.74573 11.48H5.28567C4.94469 11.48 4.61767 11.6165 4.37656 11.8596C4.13545 12.1026 4 12.4323 4 12.776V19.904C4 20.2477 4.13545 20.5774 4.37656 20.8204C4.61767 21.0635 4.94469 21.2 5.28567 21.2H19.1066C19.5764 21.2002 20.0302 21.0274 20.3826 20.7142C20.735 20.401 20.9619 19.9689 21.0207 19.499L21.9849 11.723C22.0191 11.4492 21.9951 11.1713 21.9144 10.9076C21.8338 10.6439 21.6984 10.4006 21.5172 10.1937ZM5.28567 12.776H8.49984V19.904H5.28567V12.776ZM20.7089 11.561L19.7446 19.337C19.7251 19.4936 19.6494 19.6377 19.5319 19.7421C19.4145 19.8465 19.2632 19.9041 19.1066 19.904H9.78551V12.2811L12.7353 6.33326C13.1725 6.42147 13.5659 6.65965 13.8485 7.0073C14.1311 7.35494 14.2855 7.79055 14.2854 8.24V10.184C14.2854 10.3559 14.3531 10.5207 14.4736 10.6422C14.5942 10.7637 14.7577 10.832 14.9282 10.832H20.0709C20.1621 10.832 20.2523 10.8515 20.3354 10.8893C20.4186 10.9271 20.4928 10.9823 20.5531 11.0513C20.6135 11.1203 20.6586 11.2014 20.6854 11.2893C20.7123 11.3771 20.7203 11.4698 20.7089 11.561Z"
        fill={color1}
      />
    </svg>
  )
}

export default SVG
