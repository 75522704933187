import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import countriesJSON from 'src/utils/countriesJSON'
import { md5Password } from 'src/utils'
import { Userpilot } from 'userpilot'

import {
  ApiResponse,
  settingPersonalInfoApi,
  settingPersonalPasswordApi,
  PersonaPasswordRequest,
  settingUploadAvatarApi,
} from 'src/api'
import {
  getUserInfoApi,
  editUserInfoApi,
  getUserEventStatusApi,
  editUserEventStatusApi,
  submitRatingApi,
  editUserFeatureApi,
  getGenreListApi,
  getNetworkApi,
} from 'src/api/user'
import { getPerformanceScoringApi } from 'src/api/dashboard'
import { getAppListApi } from 'src/api/game'
import { localStorageKey, ThemeMode } from './type'
import env from 'src/env'
import { enqueueSnackbar } from 'notistack'
import dayjs from 'dayjs'

type Props = {
  token?: string
  token_pristine: boolean
  userInfo: any
  userInfoLoading: boolean
  userInfoLoadingSideBar: boolean
  buttonLoading: boolean
  themeMode: 'light' | 'dark'
  accountEditKey: string
  accountAuthority: any
  countryOption: any[]
  countryListObject: any
  gameOption: any[]
  gameListObject: any
  networkObject: any
  eventStatus: any
  submitRatingLoading: boolean
  submitRatingResult: boolean
  chartConfig: any
  chartConfigTemp: any
  feature: any
  featureLoading: boolean
  genreList: any
  chartType: string
  notificationTimer: any
  showViolationIssue: any
  showReferralClosedTip: boolean
  firstLoadGamePage: boolean
  isMobile: boolean
  limitAppView: boolean
  gameListLoaded: boolean
  showTerminationTip: boolean
  showNetwork: boolean
  showPerformanceScoring: boolean
  performanceScoring: number | string
  AppPerformanceScoring: any
  performanceScoringLoading: boolean
  showFancraft: boolean
}

enum Actions {
  TOKEN = 'TOKEN',
  USER_INFO = 'USER_INFO',
  USER_INFO_LOADING = 'USER_INFO_LOADING',
  BUTTON_LOADING = 'BUTTON_LOADING',
  TOGGLE_THEME_MODE = 'TOGGLE_THEME_MODE',
  INIT_THEME_MODE = 'INIT_THEME_MODE',
  ACCOUNT_AUTHORITY = 'ACCOUNT_AUTHORITY',
  ACCOUNT_EDIT_KEY = 'ACCOUNT_EDIT_KEY',
  USER_INFO_LOADING_SIDE_BAR = 'USER_INFO_LOADING_SIDE_BAR',
  COUNTRY_OPTION = 'COUNTRY_OPTION',
  COUNTRY_LIST_OBJECT = 'COUNTRY_LIST_OBJECT',
  GAME_OPTION = 'GAME_OPTION',
  GAME_LIST_OBJECT = 'GAME_LIST_OBJECT',
  EVENT_STATUS = 'EVENT_STATUS',
  SUBMIT_RATING_LOADING = 'SUBMIT_RATING_LOADING',
  SUBMIT_RATING_RESULT = 'SUBMIT_RATING_RESULT',
  EVENT_STATUS_CHANGE = 'EVENT_STATUS_CHANGE',
  CHART_CONFIG = 'CHART_CONFIG',
  CHART_CONFIG_TEMP = 'CHART_CONFIG_TEMP',
  FEATURE = 'FEATURE',
  FEATURE_LOADING = 'FEATURE_LOADING',
  GENRE_LIST = 'GENRE_LIST',
  CHART_TYPE = 'chartType',
  NOTIFICATION_TIMER = 'NOTIFICATION_TIMER',
  SHOW_VIOLATION_ISSUE = 'SHOW_VIOLATION_ISSUE',
  SHOW_REFERRAL_CLOSED_TIP = 'SHOW_REFERRAL_CLOSED_TIP',
  FIRST_LOAD_GAME_PAGE = 'FIRST_LOAD_GAME_PAGE',
  IS_MOBILE = 'IS_MOBILE',
  LIMIT_APP_VIEW = 'LIMIT_APP_VIEW',
  SHOW_TERMINATION_TIP = 'SHOW_TERMINATION_TIP',
  SHOW_NETWORK = 'SHOW_NETWORK',
  NETWORK_OBJECT = 'NETWORK_OBJECT',
  SHOW_PERFORMANCE_SCORING = 'SHOW_PERFORMANCE_SCORING',
  PERFORMANCE_SCORING = 'PERFORMANCE_SCORING',
  APP_PERFORMANCE_SCORING_OBJECT = 'APP_PERFORMANCE_SCORING_OBJECT',
  PERFORMANCE_SCORING_LOADING = 'PERFORMANCE_SCORING_LOADING',
  SHOW_FANCRAFT = 'SHOW_FANCRAFT',
}

type Action = {
  type: Actions
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  token: '',
  userInfo: undefined,
  token_pristine: true,
  userInfoLoading: false,
  userInfoLoadingSideBar: false,
  buttonLoading: false,
  themeMode: 'light',
  accountEditKey: '',
  accountAuthority: {},
  countryOption: [],
  countryListObject: {},
  gameOption: [],
  gameListObject: {},
  networkObject: {},
  eventStatus: {},
  submitRatingLoading: false,
  submitRatingResult: false,
  chartConfig: {},
  chartConfigTemp: {},
  feature: null,
  featureLoading: false,
  genreList: {},
  chartType: 'revenue',
  notificationTimer: null,
  showViolationIssue: false,
  showReferralClosedTip: false,
  firstLoadGamePage: true,
  isMobile: false,
  limitAppView: true,
  gameListLoaded: false,
  showTerminationTip: false,
  showNetwork: false,
  showPerformanceScoring: false,
  performanceScoring: '-',
  AppPerformanceScoring: {},
  performanceScoringLoading: false,
  showFancraft: false,
}
const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case Actions.TOKEN:
      return {
        ...prevState,
        token: action.payload,
        token_pristine: false,
      }
    case Actions.USER_INFO:
      return {
        ...prevState,
        userInfo: action.payload,
      }
    case Actions.USER_INFO_LOADING:
      return {
        ...prevState,
        userInfoLoading: action.payload,
      }
    case Actions.USER_INFO_LOADING_SIDE_BAR:
      return {
        ...prevState,
        userInfoLoadingSideBar: action.payload,
      }
    case Actions.BUTTON_LOADING:
      return {
        ...prevState,
        buttonLoading: action.payload,
      }
    case Actions.TOGGLE_THEME_MODE:
      return {
        ...prevState,
        themeMode: action.payload,
      }
    case Actions.INIT_THEME_MODE:
      return {
        ...prevState,
        themeMode: action.payload,
      }
    case Actions.ACCOUNT_AUTHORITY:
      return {
        ...prevState,
        accountAuthority: action.payload,
      }
    case Actions.ACCOUNT_EDIT_KEY:
      return {
        ...prevState,
        accountEditKey: action.payload,
      }
    case Actions.COUNTRY_OPTION:
      return {
        ...prevState,
        countryOption: action.payload,
      }
    case Actions.COUNTRY_LIST_OBJECT:
      return {
        ...prevState,
        countryListObject: action.payload,
      }
    case Actions.GAME_OPTION:
      return {
        ...prevState,
        gameOption: action.payload,
      }
    case Actions.GAME_LIST_OBJECT:
      return {
        ...prevState,
        gameListObject: action.payload,
        gameListLoaded: true,
      }
    case Actions.NETWORK_OBJECT:
      return {
        ...prevState,
        networkObject: action.payload,
      }
    case Actions.EVENT_STATUS:
      const statusList = {}
      action.payload.forEach((item: any) => {
        statusList[item.event_name] = item.has_done
      })
      return {
        ...prevState,
        eventStatus: statusList,
      }
    case Actions.EVENT_STATUS_CHANGE:
      const eventStatusList = prevState.eventStatus
      eventStatusList[action.payload] = true
      return {
        ...prevState,
        eventStatus: eventStatusList,
      }
    case Actions.SUBMIT_RATING_LOADING:
      return {
        ...prevState,
        submitRatingLoading: action.payload,
      }
    case Actions.SUBMIT_RATING_RESULT:
      return {
        ...prevState,
        submitRatingResult: action.payload,
      }
    case Actions.CHART_CONFIG:
      return {
        ...prevState,
        chartConfig: action.payload,
      }
    case Actions.CHART_CONFIG_TEMP:
      return {
        ...prevState,
        chartConfigTemp: action.payload,
      }

    case Actions.FEATURE:
      return {
        ...prevState,
        feature: action.payload,
      }
    case Actions.FEATURE_LOADING:
      return {
        ...prevState,
        featureLoading: action.payload,
      }
    case Actions.GENRE_LIST:
      return {
        ...prevState,
        genreList: action.payload,
      }
    case Actions.CHART_TYPE:
      return {
        ...prevState,
        chartType: action.payload,
      }
    case Actions.NOTIFICATION_TIMER:
      return {
        ...prevState,
        notificationTimer: action.payload,
      }
    case Actions.SHOW_VIOLATION_ISSUE:
      return {
        ...prevState,
        showViolationIssue: action.payload,
      }
    case Actions.SHOW_REFERRAL_CLOSED_TIP:
      return {
        ...prevState,
        showReferralClosedTip: action.payload,
      }
    case Actions.FIRST_LOAD_GAME_PAGE:
      return {
        ...prevState,
        firstLoadGamePage: action.payload,
      }
    case Actions.IS_MOBILE:
      return {
        ...prevState,
        isMobile: action.payload,
      }
    case Actions.LIMIT_APP_VIEW:
      return {
        ...prevState,
        limitAppView: action.payload,
      }
    case Actions.SHOW_TERMINATION_TIP:
      return {
        ...prevState,
        showTerminationTip: action.payload,
      }
    case Actions.SHOW_NETWORK:
      return {
        ...prevState,
        showNetwork: action.payload,
      }
    case Actions.SHOW_PERFORMANCE_SCORING:
      return {
        ...prevState,
        showPerformanceScoring: action.payload,
      }

    case Actions.PERFORMANCE_SCORING:
      return {
        ...prevState,
        performanceScoring: action.payload,
      }
    case Actions.APP_PERFORMANCE_SCORING_OBJECT:
      return {
        ...prevState,
        AppPerformanceScoring: action.payload,
      }
    case Actions.PERFORMANCE_SCORING_LOADING:
      return {
        ...prevState,
        performanceScoringLoading: action.payload,
      }
    case Actions.SHOW_FANCRAFT:
      return {
        ...prevState,
        showFancraft: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
type Context = {
  state: Props
  dispatch: any
  hideOutsideContentPadding: boolean
  getToken: () => string
  errorCatch: (error: any, noLogout?: boolean) => void
  customAlert: (type: any, msg: string, closeTimer?: number) => void
  setToken: (token: string) => void
  getUserInfo: (noLoadingLeftBar?: boolean) => void
  logout: (initiativeLogout?: boolean) => void
  changeLocalTheme: (mode: ThemeMode) => void
  personalInfoChange: (personalInfo: any, isThemeChange?: boolean) => void
  setAccountEditKeyHandle: (editKey: string) => void
  personalPasswordChange: (personalInfo: PersonaPasswordRequest) => void
  avatarUpload: (params: FormData) => Promise<string>
  toggleThemeMode: (themeMode: 'dark' | 'light') => void
  editUserInfo: () => void
  getNewGameList: () => void
  editUserEventStatus: (key: string, has_done?: boolean) => void
  editGameProgressBarStatus: (key: string, has_done?: boolean) => void
  submitRating: (data: any) => void
  submitIntegrationScore: (data: any) => void
  editUserFeature: (data: any) => void
}
export const GlobalContext = createContext<Context>({
  state: initialState,
  dispatch: null,
  hideOutsideContentPadding: false,
  errorCatch: () => {},
  customAlert: () => {},
  getToken: () => {
    return ''
  },
  setToken: () => {},
  getUserInfo: () => {},
  logout: () => {},
  personalInfoChange: () => {},
  changeLocalTheme: () => {},
  personalPasswordChange: () => {},
  avatarUpload: async () => '',
  setAccountEditKeyHandle: () => {},
  toggleThemeMode: () => {},
  editUserInfo: () => {},
  getNewGameList: () => {},
  editUserEventStatus: (key: string, has_done?: boolean) => {},
  editGameProgressBarStatus: (key: string, has_done?: boolean) => {},
  submitRating: () => {},
  submitIntegrationScore: () => {},
  editUserFeature: (data: any) => {},
})
const tempAlertList: any = []
export const GlobalContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const location = useLocation()
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const min800 = useMediaQuery('(min-width:800px)')

  // handlers
  const errorCatch = (
    error: {
      response: any
      message: any
      toString: () => any
    },
    noLogout?: boolean
  ) => {
    let msg = ''
    const response = error.response

    if (response) {
      if (response.status === 401 && !noLogout) {
        logout()
      }

      if (response.data?.code) {
        if (response.data?.code === 'unknown') {
          msg =
            response.data.detail ||
            t(response.data?.code) ||
            error.message ||
            error.toString()
        } else {
          msg =
            t(response.data?.code) ||
            response.data.detail ||
            error.message ||
            error.toString()
        }
      } else {
        if (response.status === 400) {
          const detail = response.data.detail
          const code = detail.slice(7, detail.length - 2)
          msg = t(code) || error.message || error.toString()
        } else if (Array.isArray(response.data.detail)) {
          msg = response.data.detail
            .reduce((result: any[], item: { msg: any }) => {
              result.push(item.msg)
              return result
            }, [])
            .join(',')
        } else {
          msg = String(response?.data?.detail)
        }
      }
    } else {
      // TODO: optimize this message
      msg = 'Network Error'
    }
    // 防止多次展示相同错误
    if (!tempAlertList.includes('error-' + msg)) {
      enqueueSnackbar(msg, { variant: 'error' })
      tempAlertList.push('error-' + msg)
      const timer = setTimeout(() => {
        tempAlertList.shift()
        clearTimeout(timer)
      }, 2000)
    }
  }

  const customAlert = (type: any, msg: string, closeTime = 4000) => {
    enqueueSnackbar(msg, { variant: type, autoHideDuration: closeTime })
  }

  const setToken = (token: string) => {
    localStorage.setItem(localStorageKey.ACCESS_TOKEN, token)
    dispatch({
      type: Actions.TOKEN,
      payload: token,
    })
  }

  const clearToken = () => {
    localStorage.removeItem(localStorageKey.ACCESS_TOKEN)
    dispatch({
      type: Actions.TOKEN,
      payload: '',
    })
  }

  const getToken = useCallback((): string => {
    const token = localStorage.getItem(localStorageKey.ACCESS_TOKEN) || ''
    return token
  }, [])

  const getUserInfo = useCallback((flag?: boolean) => {
    dispatch({
      type: Actions.USER_INFO_LOADING,
      payload: true,
    })
    if (!flag) {
      dispatch({
        type: Actions.USER_INFO_LOADING_SIDE_BAR,
        payload: true,
      })
    }
    let mock_login = 'false'
    if (localStorage.getItem(localStorageKey.TOKEN_SOURCE_ADMIN) === 'true') {
      mock_login = 'true'
    }
    getUserInfoApi(mock_login)
      .then(({ data }: ApiResponse) => {
        const showFancraftTime = localStorage.getItem('fancraft')
        if (
          !showFancraftTime ||
          dayjs().isAfter(dayjs(showFancraftTime).add(1, 'day'))
        ) {
          const timer = setTimeout(() => {
            dispatch({
              type: Actions.SHOW_FANCRAFT,
              payload: true,
            })
            localStorage.setItem(
              'fancraft',
              dayjs().format('YYYY-MM-DD HH:mm:ss')
            )
            clearTimeout(timer)
          }, 10000)
        }
        dispatch({
          type: Actions.USER_INFO,
          payload: data,
        })
        const tmpOwnerKeyArray: Array<string> = [
          '64759e30d17449128d4c5841',
          '5e2508f573d4a903a3fd1707',
          '5f33a6c0719aac17795ef25c',
          '6007ff3d7494f8571716aa0b',
        ]
        if (
          data?.owner_feature?.scoring_feature ||
          tmpOwnerKeyArray.includes(data?.owner_key)
        ) {
          dispatch({
            type: Actions.SHOW_PERFORMANCE_SCORING,
            payload: true,
          })
          getPerformanceScoring()
        } else {
          dispatch({
            type: Actions.SHOW_PERFORMANCE_SCORING,
            payload: false,
          })
        }
        dispatch({
          type: Actions.LIMIT_APP_VIEW,
          payload: data.limit_app_view,
        })
        if (process.env.REACT_APP_ENV === 'test') {
          Userpilot.identify(data.developer_key, {
            name: data.name,
            email: data.email,
          })
        }
        localStorage.setItem(localStorageKey.THEME, data.theme)
        const permission = data.permission || {}
        if (data.mcm_feature === true && !data.limit_app_view) {
          Object.assign(permission, { ad_manager_page: 2 })
        } else {
          Object.assign(permission, { ad_manager_page: 0 })
        }
        dispatch({
          type: Actions.ACCOUNT_AUTHORITY,
          payload: permission,
        })
        dispatch({
          type: Actions.FEATURE,
          payload: data.feature || null,
        })
        // owner_key为以下几个的performance和user activity页面group by筛选支持network
        const whiteList = [
          '5f3fcdad5bb807466471381e',
          '5fe1ba964d5d5172e7ea1224',
          '5aec2c673d725e60d8e81600',
        ]
        dispatch({
          type: Actions.SHOW_NETWORK,
          payload: whiteList.includes(data.owner_key),
        })
        const configData = data.chart_config || {
          data: [],
          default_selected: -1,
        }
        configData.default_selected = configData.default_selected + 1
        configData.data = [
          {
            custom_date: 'last_week',
            data: {
              filter: {
                ad_types: [],
                app_keys: [],
                platform: [],
                owner_keys: [],
                country_code: [],
              },
              from_date: '2022-01-05',
              to_date: '2022-01-11',
              view_by: null,
            },
            title: 'Default Report',
          },
          ...configData.data,
        ]
        dispatch({
          type: Actions.CHART_CONFIG_TEMP,
          payload: configData,
        })
        if (
          env.intercomId &&
          localStorage.getItem(localStorageKey.TOKEN_SOURCE_ADMIN) !== 'true'
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.intercomSettings = {
            app_id: env.intercomId,
            user_id: data.developer_key,
            name: data.name, // Full name
            email: data.email, // Email address
            created_at: '', // Signup date as a Unix timestamp
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.Intercom('boot', window.intercomSettings)
        }
      })
      .catch(err => {
        dispatch({
          type: Actions.USER_INFO,
          payload: null,
        })
        errorCatch(err)
      })
      .finally(() => {
        dispatch({
          type: Actions.USER_INFO_LOADING,
          payload: false,
        })
        if (!flag) {
          dispatch({
            type: Actions.USER_INFO_LOADING_SIDE_BAR,
            payload: false,
          })
        }
      })
    // eslint-disable-next-line
  }, [])
  const editUserInfo = () => {
    editUserInfoApi({
      name: state.userInfo.name,
      theme: state.userInfo.theme,
      avatar: state.userInfo.avatar,
    }).catch(errorCatch)
  }
  const editUserFeature = (data: any) => {
    dispatch({
      type: Actions.FEATURE_LOADING,
      payload: true,
    })
    editUserFeatureApi(data)
      .then(() => {
        dispatch({
          type: Actions.FEATURE,
          payload: { ...state.feature, ...data },
        })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({
          type: Actions.FEATURE_LOADING,
          payload: false,
        })
      })
  }
  const logout = (initiativeLogout?: boolean) => {
    if (state.notificationTimer) {
      clearTimeout(state.notificationTimer)
      dispatch({
        type: Actions.NOTIFICATION_TIMER,
        payload: null,
      })
    }
    // 情况首次加载标识，首次登录视为首次加载（区分刷新页面）
    window.name = ''
    clearToken()
    dispatch({
      type: Actions.USER_INFO,
      payload: {},
    })
    dispatch({
      type: Actions.EVENT_STATUS,
      payload: [],
    })
    localStorage.removeItem(localStorageKey.TOKEN_SOURCE_ADMIN)
    localStorage.removeItem('theme')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.intercomSettings = {}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom('shutdown')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const heap: any = window.heap
    if (process.env.REACT_APP_ENV === 'product' && heap) {
      heap.resetIdentity && heap.resetIdentity()
    }
    let href = '/login'
    // 因为token超时或错误退出
    if (!initiativeLogout) {
      href =
        '/login' +
        '?redirect=' +
        encodeURIComponent(location.pathname + location.search)
    }
    if (location.pathname === '/login') return
    navigate(href)
  }

  const changeLocalTheme = (mode: ThemeMode) => {
    dispatch({
      type: Actions.USER_INFO,
      payload: {
        ...state.userInfo,
        theme: mode,
      },
    })
  }

  // My account is edit
  const setAccountEditKeyHandle = (editKey: string) => {
    dispatch({
      type: Actions.ACCOUNT_EDIT_KEY,
      payload: editKey,
    })
  }
  // modify personal info
  const personalInfoChange = (params: any, isThemeChange = false) => {
    dispatch({
      type: Actions.BUTTON_LOADING,
      payload: true,
    })
    if (!params.theme) {
      params.theme = state.userInfo.theme
    }
    settingPersonalInfoApi(params)
      .then(res => {
        dispatch({
          type: Actions.USER_INFO,
          payload: {
            ...state.userInfo,
            ...res.data,
            owner_tel: res.data.owner_tel || state.userInfo.owner_tel,
          },
        })
        setAccountEditKeyHandle('')
        if (!isThemeChange) {
          customAlert('success', 'Save Success')
        }
        localStorage.setItem(localStorageKey.THEME, res.data?.theme)
      })
      .catch(errorCatch)
      .finally(() =>
        dispatch({
          type: Actions.BUTTON_LOADING,
          payload: false,
        })
      )
  }

  //  modify personal password
  const personalPasswordChange = (params: PersonaPasswordRequest) => {
    dispatch({
      type: Actions.BUTTON_LOADING,
      payload: true,
    })
    const md5Params: PersonaPasswordRequest = {
      passwd: md5Password(params.passwd),
      new_passwd: md5Password(params.new_passwd),
    }
    settingPersonalPasswordApi(md5Params)
      .then(() => {
        setAccountEditKeyHandle('')
        customAlert('success', 'Save Success')
      })
      .catch(errorCatch)
      .finally(() =>
        dispatch({
          type: Actions.BUTTON_LOADING,
          payload: false,
        })
      )
  }

  const avatarUpload = async (params: FormData): Promise<string> => {
    let url = ''
    try {
      const res = await settingUploadAvatarApi(params)
      if (res.data.url) {
        url = res.data.url
        customAlert('success', 'Upload Success')
      }
    } catch (error: any) {
      errorCatch(error)
    }
    return url
  }

  const initThemeMode = () => {
    dispatch({
      type: Actions.INIT_THEME_MODE,
      payload: prefersDarkMode ? 'dark' : 'light',
    })
  }

  const toggleThemeMode = (themeMode: 'dark' | 'light') => {
    dispatch({
      type: Actions.TOGGLE_THEME_MODE,
      payload: themeMode,
    })
  }
  const editUserEventStatus = (key: string, has_done?: boolean) => {
    if (localStorage.getItem(localStorageKey.TOKEN_SOURCE_ADMIN) !== 'true') {
      editUserEventStatusApi([
        { event_name: key, has_done: has_done === false ? has_done : true },
      ])
        .then(() => {
          // 当eventStatus状态修改成功后前端修改event状态
          dispatch({
            type: Actions.EVENT_STATUS_CHANGE,
            payload: key,
          })
        })
        .catch()
    }
  }
  const editGameProgressBarStatus = (key: string, has_done?: boolean) => {
    if (localStorage.getItem(localStorageKey.TOKEN_SOURCE_ADMIN) !== 'true') {
      editUserEventStatusApi([
        { event_name: key, has_done: has_done === false ? has_done : true },
      ]).catch()
    }
  }
  const submitRating = (data: any) => {
    dispatch({
      type: Actions.SUBMIT_RATING_LOADING,
      payload: true,
    })
    dispatch({
      type: Actions.SUBMIT_RATING_RESULT,
      payload: false,
    })
    submitRatingApi(data)
      .then(() => {
        dispatch({
          type: Actions.SUBMIT_RATING_RESULT,
          payload: true,
        })
        dispatch({
          type: Actions.SUBMIT_RATING_LOADING,
          payload: false,
        })
      })
      .catch()
      .finally(() => {
        dispatch({
          type: Actions.SUBMIT_RATING_LOADING,
          payload: false,
        })
      })
  }
  const submitIntegrationScore = (data: any) => {
    submitRatingApi(data).then(() => {})
  }
  const getUserEventStatus = () => {
    dispatch({
      type: Actions.EVENT_STATUS,
      payload: [],
    })
    getUserEventStatusApi()
      .then(({ data }) => {
        dispatch({
          type: Actions.EVENT_STATUS,
          payload: data.data,
        })
      })
      .catch(errorCatch)
  }

  // useMemo
  // 隐藏layout中content的padding，使用页面内的padding，方便添加顶部筛选
  const hideOutsideContentPadding = useMemo(() => {
    const temp = [
      '/dashboard',
      '/dash/performance/revenue',
      '/dash/performance/impressions',
      '/dash/performance/ecpm',
      '/dash/user-activity/arpdau',
      '/dash/user-activity/impdau',
      '/dash/user-activity/dau',
      '/dash/gameplay-insights/retention',
      '/dash/gameplay-insights/churn-rate',
      '/dash/gameplay-insights/stickiness',
      '/dash/gameplay-insights/sessions',
      '/dash/gameplay-insights/installs',
      '/dash/gameplay-insights/session-length',
      '/dash/engagement/rate',
      '/dash/engagement/deu',
      '/dash/engagement/imp_deu',
      '/dash/engagement/revenue_deu',
      '/dash/ad-placement/ratio_revenue',
      '/dash/ad-placement/ratio_impressions',
      '/dash/ad-placement/dau',
      '/dash/ad-placement/placement_imp_dau',
      '/dash/ad-placement/placement_arpdau',
      '/dash/ad-placement/dav',
      '/dash/ad-placement/placement_arpdav',
      '/dash/ad-placement/ad_viewer_rate',
      '/dash/games/compare',
    ]
    return temp.includes(location.pathname)
  }, [location.pathname])
  // effects
  useEffect(() => {
    if (!state.token) {
      return
    }
    getGenreListApi()
      .then(({ data }) => {
        dispatch({
          type: Actions.GENRE_LIST,
          payload: data,
        })
      })
      .catch(errorCatch)
    // eslint-disable-next-line
  }, [state.token])
  // 获取国家列表
  useEffect(() => {
    const countryList: any = countriesJSON.map((item: any) => {
      item.color = '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
      item.name = item.name.replace(' (the)', '').replace(' (The)', '')
      return item
    })
    dispatch({
      type: Actions.COUNTRY_OPTION,
      payload: countryList,
    })
    const temp = {}
    countryList.forEach(
      (item: {
        code: string
        name: string
        chinese_name: string
        color: string
      }) => {
        temp[item.code] = {
          name: item.name,
          chinese_name: item.chinese_name,
          color: item.color,
        }
      }
    )
    dispatch({
      type: Actions.COUNTRY_LIST_OBJECT,
      payload: temp,
    })
    // eslint-disable-next-line
  }, [])
  // 获取总的游戏列表
  const getNewGameList = () => {
    const params = {
      page: 1,
      page_size: 0,
      extra_flag: true,
    }
    getAppListApi(params)
      .then(({ data }) => {
        let showTerminationTip = false
        dispatch({
          type: Actions.GAME_OPTION,
          payload: data.data.map((item: any) => {
            if (item?.app_termination_status === 'TERMINATION') {
              showTerminationTip = true
            }
            item.game_name = item.name
            item.name = `${item.name} (${item.platform})`
            item.color =
              '#' + Math.random().toString(16).substr(2, 6).toUpperCase()
            return item
          }),
        })
        dispatch({
          type: Actions.SHOW_TERMINATION_TIP,
          payload: showTerminationTip,
        })
        const temp = {}
        data.data.forEach(
          (item: {
            app_key: string | number
            name: any
            game_name: any
            platform: any
            icon: any
            url: any
            bundle_id: any
            app_termination_status: any
          }) => {
            temp[item.app_key] = {
              name: item.name,
              game_name: item.game_name,
              platform: item.platform,
              icon: item.icon,
              url: item.url,
              bundle_id: item.bundle_id,
              color:
                '#' + Math.random().toString(16).substr(2, 6).toUpperCase(),
              app_termination_status: item.app_termination_status,
            }
          }
        )
        dispatch({ type: Actions.GAME_LIST_OBJECT, payload: temp })
      })
      .catch(errorCatch)
  }
  // 获取network列表
  const getNetwork = () => {
    getNetworkApi()
      .then(({ data }) => {
        const temp = {}
        data.data.forEach((item: { label: string; value: string }) => {
          temp[item.value] = item.label
        })
        dispatch({
          type: Actions.NETWORK_OBJECT,
          payload: temp,
        })
      })
      .catch(errorCatch)
  }

  // 获取Performance Scoring
  const getPerformanceScoring = () => {
    dispatch({
      type: Actions.PERFORMANCE_SCORING_LOADING,
      payload: true,
    })
    getPerformanceScoringApi()
      .then((res: any) => {
        dispatch({
          type: Actions.PERFORMANCE_SCORING,
          payload: res?.data?.data?.performance_score || '-',
        })
        const temp = {}
        if (res?.data?.data?.details?.length) {
          res?.data?.data?.details.forEach(
            (item: { app_key: string; performance_score: number }) => {
              temp[item.app_key] = item.performance_score
            }
          )
        }
        dispatch({
          type: Actions.APP_PERFORMANCE_SCORING_OBJECT,
          payload: temp,
        })
      })
      .catch((err: any) => {
        errorCatch(err)
        dispatch({
          type: Actions.PERFORMANCE_SCORING,
          payload: '-',
        })
        const temp = {}
        dispatch({
          type: Actions.APP_PERFORMANCE_SCORING_OBJECT,
          payload: temp,
        })
      })
      .finally(() => {
        dispatch({
          type: Actions.PERFORMANCE_SCORING_LOADING,
          payload: false,
        })
      })
  }
  useEffect(() => {
    if (!state.gameListLoaded || !Object.keys(state.chartConfigTemp).length) {
      return
    }
    const configData = state.chartConfigTemp
    configData.data = configData.data.map((item: any) => {
      item.data.filter.app_keys = item.data.filter.app_keys.filter(
        (element: any) => Object.keys(state.gameListObject).includes(element)
      )
      return item
    })
    dispatch({
      type: Actions.CHART_CONFIG,
      payload: configData,
    })
    // eslint-disable-next-line
  }, [state.gameListObject, state.chartConfigTemp])
  useEffect(() => {
    if (!state.token) {
      return
    }
    getNewGameList()
    getNetwork()
    // eslint-disable-next-line
  }, [state.token])
  useEffect(() => {
    if (!state.token) {
      const token = getToken()
      setToken(token)
    }
  }, [state.token, getToken])

  useEffect(() => {
    if (state.token && !state.userInfoLoading) {
      getUserInfo()
    }
    /* eslint-disable */
  }, [state.token])

  useEffect(() => {
    if (state.userInfo?.is_admin) {
      getUserEventStatus()
    }
  }, [state.userInfo])

  useEffect(() => {
    if (state.themeMode === 'dark') {
      document.body.setAttribute(
        'style',
        'background-color:#121212;color:#fff;'
      )
    } else {
      document.body.setAttribute(
        'style',
        'background-color:#fafbfc;color:rgba(0,0,0,.87);'
      )
    }
  }, [state.themeMode])

  useEffect(() => {
    const themeKey =
      state.userInfo?.theme ?? localStorage.getItem(localStorageKey.THEME)

    if (themeKey === ThemeMode.LIGHT) {
      toggleThemeMode('light')
      return
    }

    if (themeKey === ThemeMode.DARK) {
      toggleThemeMode('dark')
      return
    }

    initThemeMode()
  }, [state.userInfo])

  useEffect(() => {
    // 屏幕宽度小于800时为min800为true
    dispatch({
      type: Actions.IS_MOBILE,
      payload: !min800,
    })
  }, [min800])
  // returns
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
        hideOutsideContentPadding,
        getToken,
        setToken,
        getUserInfo,
        changeLocalTheme,
        logout,
        personalInfoChange,
        personalPasswordChange,
        setAccountEditKeyHandle,
        avatarUpload,
        errorCatch,
        customAlert,
        toggleThemeMode,
        editUserInfo,
        getNewGameList,
        editUserEventStatus,
        editGameProgressBarStatus,
        submitRating,
        submitIntegrationScore,
        editUserFeature,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
