import { portalService } from './service'

export const getUserInfoApi = (mock_login: string) => {
  return portalService({
    url: '/user/me?mock_login=' + mock_login,
    method: 'get',
  })
}

export const editUserInfoApi = (data: any) => {
  return portalService({
    url: '/user/me',
    method: 'patch',
    data,
  })
}

export const editUserFeatureApi = (data: any) => {
  return portalService({
    url: '/user/me/feature',
    method: 'patch',
    data,
  })
}
export const getBenchmarkTipsApi = (params: { tips_type: string }) => {
  return portalService({
    url: '/feature/benchmark_tips',
    method: 'get',
    params,
  })
}

export const editBenchmarkTipsApi = (params: any) => {
  return portalService({
    url: '/feature/benchmark_tips/mark_as_read',
    method: 'patch',
    params,
  })
}

export const postBenchmarkTFeedbackApi = (data: any) => {
  return portalService({
    url: '/feature/benchmark_tips/feedback',
    method: 'post',
    data,
  })
}
export const getGenreListApi = () => {
  return portalService({
    url: '/game/genre',
    method: 'get',
  })
}

export const getUserEventStatusApi = () => {
  return portalService({
    url: '/event_status',
    method: 'get',
  })
}

export const editUserEventStatusApi = (data: any) => {
  return portalService({
    url: '/event_status',
    method: 'patch',
    data,
  })
}

export const submitRatingApi = (data: any) => {
  return portalService({
    url: '/event_status/submit',
    method: 'post',
    data,
  })
}

export const getSubscribeApi = () => {
  return portalService({
    url: '/user/me/subscribe',
    method: 'get',
  })
}
export const putSubscribeApi = (data: {
  updates: boolean
  announcements: boolean
  support: boolean
}) => {
  return portalService({
    url: '/user/me/subscribe',
    method: 'put',
    data,
  })
}
export const getNetworkApi = () => {
  return portalService({
    url: '/base_data/networks',
    method: 'get',
  })
}
export const getCommentsEventApi = (data: any) => {
  return portalService({
    url: '/game/comments',
    method: 'post',
    data,
  })
}
