import { Box, TextField, Button, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Email as EmailIcon } from '@material-ui/icons'
import { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { LoadingBtn, PromptDialog } from 'src/components'
import { EmailForm } from 'src/context/type'
import { GlobalContext } from 'src/context/global'
import { SettingsContext } from 'src/context/settings'

import ChangeEmailDialog from './changeEmailDialog'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      backButton: {
        width: '47% !important',
        height: '36px !important',
        padding: '0',
      },
      saveButton: {
        width: '47% !important',
        height: '36px !important',
        padding: '0',
        '&.MuiButton-contained.Mui-disabled': {
          backgroundColor: '#29444f !important',
        },
      },
      icon: {
        width: '22px',
        height: '18px',
      },
      inputLabel: {
        color: theme.palette.custom.inputLabel,
        fontSize: 12,
        marginBottom: 8,
      },
    }
  })

const View = (): JSX.Element => {
  const [t] = useTranslation()
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const className = useStyles()
  const { state, setAccountEditKeyHandle } = useContext(GlobalContext)
  const {
    state: settingsState,
    toggleEmailSuccess,
    emailSendCode,
  } = useContext(SettingsContext)

  const [userData, setUserData] = useState({
    avatar: state.userInfo?.avatar,
    email: state.userInfo?.email || '-',
    contactName: state.userInfo?.name || '-', // TODO: fix & add a loading maybe
    theme: state.userInfo?.theme || 0,
  })

  const [newEmail, setNewEmail] = useState('')

  // form check
  const formSchema = yup.object().shape({
    email: yup.string().email().required(t('setting.personalInfo.emailErrMsg')),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<EmailForm>({
    resolver: yupResolver(formSchema),
  })

  const saveChange = (data: EmailForm) => {
    setNewEmail(data.email)
    emailSendCode(data.email, true)
  }

  useEffect(() => {
    if (state.userInfo) {
      const { avatar, email, theme, name } = state.userInfo
      setUserData(() => ({
        avatar,
        email,
        theme,
        contactName: name,
      }))
    }
  }, [setValue, state.userInfo])

  // views
  const emailInputView = (
    <Controller
      name="email"
      control={control}
      defaultValue={newEmail}
      render={({ field }) => (
        <TextField
          fullWidth
          placeholder={t('auth.login.email')}
          InputProps={{
            startAdornment: <EmailIcon className={className.icon} />,
          }}
          type="email"
          variant="outlined"
          {...field}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      )}
    />
  )

  return (
    <>
      <Box>
        <Box className={className.inputLabel}>Current Email Address</Box>
        <TextField
          defaultValue={userData.email}
          fullWidth
          disabled
          placeholder={t('auth.login.email')}
          type="email"
          variant="outlined"
          InputProps={{
            startAdornment: <EmailIcon className={className.icon} />,
          }}
        />
      </Box>
      <Box marginTop="16px">
        <Box className={className.inputLabel}>New Email Address</Box>
        {emailInputView}
      </Box>

      <Box display="flex" marginTop="24px" justifyContent="space-between">
        <Button
          className={className.backButton}
          onClick={() => setAccountEditKeyHandle('')}
          variant="outlined"
        >
          Back
        </Button>
        {state.accountAuthority.settings_page === 2 && (
          <Button
            disabled={state.buttonLoading || settingsState.updateButtonLoading}
            className={className.saveButton}
            color="primary"
            variant="contained"
            onClick={handleSubmit(saveChange)}
          >
            {state.buttonLoading || settingsState.updateButtonLoading ? (
              <LoadingBtn
                loadingStyle={{
                  position: 'absolute',
                  width: '100%',
                  justifyContent: 'center',
                }}
              />
            ) : (
              'Update'
            )}
          </Button>
        )}
      </Box>
      {settingsState.openChangeEmail && (
        <ChangeEmailDialog newEmail={newEmail} />
      )}

      <PromptDialog
        openModel={settingsState.openEmailSuccess}
        isSuccessDialog
        title={t('setting.personalInfo.emailChangeSuccessMsg')}
        onClose={() => toggleEmailSuccess(false)}
        onConfirm={() => toggleEmailSuccess(false)}
      />
    </>
  )
}

export default View
