const SVG = (props: any) => {
  const width = props.width || '25'
  const height = props.height || '20'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0688 14.7735V9.08789"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M5.19775 14.7736L5.19775 12.8711"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M15.6016 14.7736L15.6016 10.9697"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M12.1333 14.7743L12.1333 12.1836"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M8.66504 14.7736L8.66504 10.9697"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M5.19775 17.3418H19.5964"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M5.08887 10.388L8.33535 8.00349C8.71025 7.72812 9.21052 7.69423 9.61914 7.9165L11.6989 9.04783C12.1176 9.27559 12.6314 9.23395 13.008 8.94174L19.9287 3.57129"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M16.9424 2.65723H20.6888V6.51867"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SVG
