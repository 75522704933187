const SVG = (props: any) => {
  const width = props.width || '24'
  const height = props.height || '24'
  const color = props.color || '#1D3353'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 20H17.3333C18.8061 20 20 18.8061 20 17.3333V6.66667C20 5.19391 18.8061 4 17.3333 4H6.66667C5.19391 4 4 5.19391 4 6.66667V17.3333C4 18.8061 5.19391 20 6.66667 20ZM5.77734 6.66623C5.77734 6.17531 6.17531 5.77734 6.66623 5.77734H17.3329C17.8238 5.77734 18.2218 6.17531 18.2218 6.66623V17.3329C18.2218 17.8238 17.8238 18.2218 17.3329 18.2218H6.66623C6.17531 18.2218 5.77734 17.8238 5.77734 17.3329V6.66623Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
