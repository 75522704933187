import { useState, useContext, useEffect } from 'react'
import {
  Box,
  useTheme,
  Theme,
  Skeleton,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { makeStyles } from '@material-ui/styles'
import { toThousands, numFixedRefillZero } from 'src/utils'
import { PNG, SVGIcon } from 'src/images'
import { DashContext } from 'src/context/dashboard'
import { GlobalContext } from 'src/context/global'
import { rateTitleFormat, getScoringColor, getScoringNum } from 'src/utils'
import { AdaptiveTooltip } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        maxHeight: '410px',
        overflowY: 'auto',
        color: theme.palette.text.primary,
        '&::-webkit-scrollbar': {
          // display: 'none',
          width: 7,
        },
      },
      titleLine: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px',
      },
      gamePhoto: {
        width: '48px',
        height: '48px',
        verticalAlign: 'middle',
        borderRadius: '4px',
      },
      bundle: {
        width: '100%',
        color: '#A8A8A8',
        bottom: '-12px',
        left: '40px',
        marginTop: '4px',
        whiteSpace: 'nowrap',
      },
      platformPhoto: {
        width: '14px',
        height: '14px',
        verticalAlign: 'text-top',
      },
      desc: {
        color: theme.palette.text.disabled,
        fontSize: '16px',
        textAlign: 'center',
      },
      headerRow: {
        display: 'flex',
        textAlign: 'right',
        marginBottom: '12px',
        paddingRight: '6px',
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 400,
      },
      tableTh: {
        flex: 1,
        minWidth: '80px',
      },
      app: {
        flex: 2,
        textAlign: 'left',
      },
      borderRow: {
        display: 'flex',
        padding: '9px 6px 9px 0',
        borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
      },
      tableTd: {
        flex: 1,
        minWidth: '80px',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'right',
        lineHeight: '48px',
        fontVariantNumeric: 'tabular-nums',
      },
      graphBox: {
        width: '38px',
        height: '30px',
        lineHeight: '30px',
        textAlign: 'center',
        background: theme.palette.custom.editIconButton,
        cursor: 'pointer',
        borderRadius: '4px',
      },
    }
  })

const RetentionTableView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const min950 = useMediaQuery('(min-width:950px)')
  const loadingRow = Array(5).fill('')
  const { state } = useContext(DashContext)
  const { state: globalState } = useContext(GlobalContext)

  const [title, setTitle] = useState('')
  const [games, setGames] = useState<Array<any>>([])
  const copyBundleId = () => {
    setTitle('Copied')
  }
  const handleTooltipClose = () => {
    // 延时器防止鼠标移除时内容改变
    const openTimer = setTimeout(() => {
      setTitle('')
      clearTimeout(openTimer)
    }, 200)
  }
  const toUrlPage = (url: string) => {
    url && window.open(url)
  }
  const numFormat = (num: any, isInt?: boolean) => {
    if (isInt) {
      return toThousands(num)
    }
    return toThousands(numFixedRefillZero(num, 2), true)
  }
  const toScorePage = (row: any) => {
    const url = `/dash/games/performance/${row.app_key}`
    navigate(url)
  }

  useEffect(() => {
    const topGames = state.topGames
    if (topGames?.length) {
      setGames(
        topGames
          .sort((a, b) => b.revenue - a.revenue)
          .slice(0, 10)
          .map((game: any) => {
            const appInfo = globalState.gameListObject[game.app_key]
            return {
              platform: appInfo?.platform,
              name: appInfo?.game_name,
              icon: appInfo?.icon,
              url: appInfo?.url,
              bundle_id: appInfo?.bundle_id,
              app_termination_status: appInfo?.app_termination_status,
              revenue: game.revenue,
              impressions: game.impressions,
              ecpm: game.ecpm,
              arp_dau: game.arp_dau,
              imp_dau: game.imp_dau,
              dau: game.dau,
              app_key: game.app_key,
            }
          })
      )
    }
  }, [state.topGames, globalState.gameListObject])

  return (
    <>
      <Box className={classes.titleLine}>
        <Box fontSize="20px" fontWeight={500} whiteSpace="nowrap">
          {'Top Games'}
        </Box>
      </Box>
      {(state.topGamesLoading || !!games.length) && !globalState.isMobile && (
        <Box
          className={classes.headerRow}
          paddingRight={games.length > 5 ? '13px' : '6px'}
        >
          <Box
            className={classes.app}
            width={min950 ? '300px' : '210px'}
            minWidth={min950 ? '300px' : '210px'}
          >
            Game
          </Box>
          <Box className={classes.tableTh}>Revenue</Box>
          <Box className={classes.tableTh}>Impressions</Box>
          <Box className={classes.tableTh}>eCPM</Box>
          <Box className={classes.tableTh}>ARPDAU</Box>
          <Box className={classes.tableTh}>{rateTitleFormat('Imp', 'DAU')}</Box>
          <Box className={classes.tableTh}>DAU</Box>
          {globalState.showPerformanceScoring && (
            <Box className={classes.tableTh} sx={{ minWidth: '110px' }}>
              PERF. SCORE
            </Box>
          )}
          <Box width="78px" />
        </Box>
      )}
      <Box className={classes.container}>
        {!state.topGamesLoading && !games.length ? (
          <Box
            height="410px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <Box className={classes.desc} marginBottom="24px">
                You have not added any games yet.
              </Box>
              <Box className={classes.desc}>
                Add a game to view this metric.
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            {!globalState.isMobile ? (
              <>
                {!state.topGamesLoading ? (
                  <Box>
                    {games.map((row: any, index: number) => {
                      return (
                        <Box key={`${index}-row`} className={classes.borderRow}>
                          <Box
                            display="flex"
                            alignItems="center"
                            className={classes.app}
                            width={min950 ? '300px' : '210px'}
                            minWidth={min950 ? '300px' : '210px'}
                          >
                            <Box
                              sx={{
                                width: '48px',
                                height: '48px',
                                position: 'relative',
                                marginRight: '16px',
                              }}
                              onClick={() => {
                                toUrlPage(row.url)
                              }}
                              style={{
                                cursor: row.url ? 'pointer' : 'not-allowed',
                              }}
                            >
                              <Box
                                sx={{
                                  position: 'absolute',
                                  right: '-8px',
                                  top: '-8px',
                                  width: '14px',
                                  height: '14px',
                                  borderRadius: '50%',
                                  border: `2px solid ${
                                    theme.palette.mode === 'light'
                                      ? '#FFF'
                                      : '#1A202C'
                                  }`,
                                  overflow: 'hidden',
                                  background:
                                    row.platform === 'iOS'
                                      ? '#FFF'
                                      : theme.palette.background.paper,
                                }}
                              >
                                <img
                                  src={
                                    row.platform === 'iOS'
                                      ? PNG.ios
                                      : PNG.android
                                  }
                                  className={classes.platformPhoto}
                                  alt=""
                                />
                              </Box>
                              {row.icon ? (
                                <img
                                  src={row.icon}
                                  className={classes.gamePhoto}
                                  alt=""
                                />
                              ) : (
                                <SVGIcon.GameDefault
                                  style={{
                                    verticalAlign: 'middle',
                                  }}
                                  color={
                                    theme.palette.mode === 'light'
                                      ? '#EFF2F3'
                                      : '#2B313C'
                                  }
                                />
                              )}
                            </Box>
                            <Box>
                              <Box
                                fontSize="14px"
                                fontWeight="500"
                                onClick={() => {
                                  if (
                                    row.app_termination_status === 'TERMINATION'
                                  ) {
                                    return
                                  }
                                  const url = `/dash/games/manage/${row.app_key}/overview`
                                  navigate(url)
                                }}
                                sx={{ wordBreak: 'break-word' }}
                                style={
                                  row.app_termination_status === 'TERMINATION'
                                    ? { cursor: 'not-allowed' }
                                    : { cursor: 'pointer' }
                                }
                              >
                                {row.name}
                              </Box>
                              <Box
                                className={classes.bundle}
                                display="flex"
                                alignItems="center"
                              >
                                <Tooltip
                                  title={title || 'Click to copy'}
                                  arrow
                                  placement="top"
                                  onClose={handleTooltipClose}
                                  disableInteractive
                                >
                                  <Box
                                    style={{
                                      cursor: 'pointer',
                                      fontVariantNumeric: 'tabular-nums',
                                    }}
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    textOverflow="ellipsis"
                                    color={theme.palette.text.secondary}
                                    width="140px"
                                    minWidth="98px"
                                    fontSize="12px"
                                    onClick={() => {
                                      copy(row.app_key)
                                      copyBundleId()
                                    }}
                                  >
                                    App Key: {row.app_key}
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Box className={classes.tableTd}>
                            ${numFormat(row.revenue)}
                          </Box>
                          <Box className={classes.tableTd}>
                            {numFormat(row.impressions, true)}
                          </Box>
                          <Box className={classes.tableTd}>
                            ${numFormat(row.ecpm)}
                          </Box>
                          <Box className={classes.tableTd}>
                            ${numFormat(row.arp_dau)}
                          </Box>
                          <Box className={classes.tableTd}>
                            {numFormat(row.imp_dau)}
                          </Box>
                          <Box className={classes.tableTd}>
                            {numFormat(row.dau, true)}
                          </Box>
                          {globalState.showPerformanceScoring && (
                            <Box
                              className={classes.tableTd}
                              sx={{ minWidth: '110px' }}
                            >
                              <Box
                                sx={{
                                  display: 'inline-block',
                                  width: '55px',
                                  height: '25px',
                                  textAlign: 'center',
                                  lineHeight: '25px',
                                  borderRadius: '12px',
                                  background:
                                    getScoringColor(
                                      globalState.AppPerformanceScoring[
                                        row.app_key
                                      ]
                                    ) || theme.palette.custom.editIconButton,
                                  color: getScoringColor(
                                    globalState.AppPerformanceScoring[
                                      row.app_key
                                    ]
                                  )
                                    ? '#FFF'
                                    : theme.palette.text.primary,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  toScorePage(row)
                                }}
                              >
                                {getScoringNum(
                                  globalState.AppPerformanceScoring[row.app_key]
                                )}
                              </Box>
                            </Box>
                          )}
                          <Box>
                            <Tooltip
                              title="Compare metrics"
                              arrow
                              placement="top"
                              disableInteractive
                            >
                              <Box
                                boxSizing="border-box"
                                paddingTop="5px"
                                margin="9px 0 0 40px"
                                className={classes.graphBox}
                                onClick={() => {
                                  navigate(
                                    `/dash/games/compare?app_keys=${row.app_key}`
                                  )
                                }}
                              >
                                <SVGIcon.CompareSVG
                                  color={theme.palette.text.primary}
                                  style={{ verticalAlign: 'text-bottom' }}
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                ) : (
                  <Box>
                    {loadingRow.map((row: any, index: number) => {
                      return (
                        <Box key={`${index}-row`} className={classes.borderRow}>
                          <Box
                            display="flex"
                            className={classes.app}
                            width={min950 ? '300px' : '210px'}
                            minWidth={min950 ? '300px' : '210px'}
                          >
                            <Skeleton width="180px" variant="text" />
                          </Box>
                          <Box className={classes.tableTd}>
                            <Skeleton
                              width="80px"
                              variant="text"
                              style={{ display: 'inline-block' }}
                            />
                          </Box>
                          <Box className={classes.tableTd}>
                            <Skeleton
                              width="80px"
                              variant="text"
                              style={{ display: 'inline-block' }}
                            />
                          </Box>
                          <Box className={classes.tableTd}>
                            <Skeleton
                              width="80px"
                              variant="text"
                              style={{ display: 'inline-block' }}
                            />
                          </Box>
                          <Box className={classes.tableTd}>
                            <Skeleton
                              width="80px"
                              variant="text"
                              style={{ display: 'inline-block' }}
                            />
                          </Box>
                          <Box className={classes.tableTd}>
                            <Skeleton
                              width="80px"
                              variant="text"
                              style={{ display: 'inline-block' }}
                            />
                          </Box>
                          <Box className={classes.tableTd}>
                            <Skeleton
                              width="80px"
                              variant="text"
                              style={{ display: 'inline-block' }}
                            />
                          </Box>
                          <Box
                            sx={{
                              boxSizing: 'border-box',
                              width: '56px',
                              textAlign: 'right',
                              lineHeight: '48px',
                            }}
                          >
                            <Skeleton
                              width="38px"
                              variant="text"
                              style={{ display: 'inline-block' }}
                            />
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                )}
              </>
            ) : (
              <>
                {state.topGamesLoading ? (
                  <>
                    {loadingRow.map((row: any, index: number) => {
                      return (
                        <Box
                          key={`${index}-mobile-row`}
                          className={classes.borderRow}
                        >
                          <Skeleton
                            width="100%"
                            height="200px"
                            variant="rectangular"
                            style={{ display: 'inline-block' }}
                          />
                        </Box>
                      )
                    })}
                  </>
                ) : (
                  <>
                    {games.map((row: any, index: number) => (
                      <Box
                        key={index}
                        sx={{
                          padding: '12px',
                          borderBottom: `1px solid ${theme.palette.custom.tableBorder}`,
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          width="100%"
                          minWidth="100px"
                        >
                          <Box
                            sx={{
                              width: '48px',
                              height: '48px',
                              position: 'relative',
                              marginRight: '16px',
                            }}
                            onClick={() => {
                              toUrlPage(row.url)
                            }}
                            style={{
                              cursor: row.url ? 'pointer' : 'not-allowed',
                            }}
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                right: '-8px',
                                top: '-8px',
                                width: '14px',
                                height: '14px',
                                borderRadius: '50%',
                                border: `2px solid ${
                                  theme.palette.mode === 'light'
                                    ? '#FFF'
                                    : '#1A202C'
                                }`,
                                overflow: 'hidden',
                                background:
                                  row.platform === 'iOS'
                                    ? '#FFF'
                                    : theme.palette.background.paper,
                              }}
                            >
                              <img
                                src={
                                  row.platform === 'iOS' ? PNG.ios : PNG.android
                                }
                                className={classes.platformPhoto}
                                alt=""
                              />
                            </Box>
                            {row.icon ? (
                              <img
                                src={row.icon}
                                className={classes.gamePhoto}
                                alt=""
                              />
                            ) : (
                              <SVGIcon.GameDefault
                                style={{
                                  width: '48px',
                                  height: '48px',
                                  verticalAlign: 'middle',
                                }}
                                color={
                                  theme.palette.mode === 'light'
                                    ? '#EFF2F3'
                                    : '#2B313C'
                                }
                              />
                            )}
                          </Box>
                          <Box width={'calc(100% - 54px)'}>
                            <Box
                              fontSize="14px"
                              fontWeight="500"
                              onClick={() => {
                                if (
                                  row.app_termination_status === 'TERMINATION'
                                ) {
                                  return
                                }
                                const url = `/dash/games/manage/${row.app_key}/overview`
                                navigate(url)
                              }}
                              sx={{ wordBreak: 'break-word' }}
                              style={
                                row.app_termination_status === 'TERMINATION'
                                  ? { cursor: 'not-allowed' }
                                  : { cursor: 'pointer' }
                              }
                            >
                              {row.name}
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{
                                width: '100%',
                                color: '#A8A8A8',
                                marginTop: '4px',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Box
                                fontSize="12px"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                color={theme.palette.text.secondary}
                              >
                                App Key: {row.app_key}
                              </Box>
                            </Box>
                          </Box>
                          <Box width="38px" marginLeft="16px" display="flex">
                            <AdaptiveTooltip
                              title="Compare metrics"
                              arrow
                              placement="top"
                              disableInteractive
                              isMobile={true}
                            >
                              <Box
                                boxSizing="border-box"
                                paddingTop="3px"
                                className={classes.graphBox}
                                onClick={() => {
                                  navigate(
                                    `/dash/games/compare?app_keys=${row.app_key}`
                                  )
                                }}
                              >
                                <SVGIcon.CompareSVG
                                  color={theme.palette.text.primary}
                                  style={{ verticalAlign: 'text-bottom' }}
                                />
                              </Box>
                            </AdaptiveTooltip>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          fontSize="16px"
                          color={theme.palette.text.secondary}
                          marginTop="12px"
                        >
                          <Box lineHeight="30px">Revenue</Box>

                          <Box className={classes.tableTd} lineHeight="30px">
                            ${numFormat(row.revenue)}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          fontSize="16px"
                          color={theme.palette.text.secondary}
                        >
                          <Box lineHeight="30px">Impressions</Box>
                          <Box className={classes.tableTd} lineHeight="30px">
                            {numFormat(row.impressions, true)}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          fontSize="16px"
                          color={theme.palette.text.secondary}
                        >
                          <Box lineHeight="30px">eCPM</Box>

                          <Box className={classes.tableTd} lineHeight="30px">
                            ${numFormat(row.ecpm)}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          fontSize="16px"
                          color={theme.palette.text.secondary}
                        >
                          <Box lineHeight="30px">ARPDAU</Box>

                          <Box className={classes.tableTd} lineHeight="30px">
                            ${numFormat(row.arp_dau)}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          fontSize="16px"
                          color={theme.palette.text.secondary}
                        >
                          <Box lineHeight="30px">
                            {rateTitleFormat('Imp', 'DAU')}
                          </Box>
                          <Box className={classes.tableTd} lineHeight="30px">
                            {numFormat(row.imp_dau)}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          fontSize="16px"
                          color={theme.palette.text.secondary}
                        >
                          <Box lineHeight="30px">DAU</Box>
                          <Box className={classes.tableTd} lineHeight="30px">
                            {numFormat(row.dau, true)}
                          </Box>
                        </Box>
                        {globalState.showPerformanceScoring && (
                          <>
                            <Box
                              sx={{
                                fontSize: '16px',
                                color: theme.palette.text.secondary,
                                marginBottom: ' 8px',
                                lineHeight: '30px',
                              }}
                            >
                              PERF. SCORE
                            </Box>
                            <Box>
                              <Box
                                sx={{
                                  display: 'inline-block',
                                  width: '55px',
                                  height: '25px',
                                  textAlign: 'center',
                                  lineHeight: '25px',
                                  borderRadius: '12px',
                                  background:
                                    getScoringColor(
                                      globalState.AppPerformanceScoring[
                                        row.app_key
                                      ]
                                    ) || theme.palette.custom.editIconButton,
                                  color: getScoringColor(
                                    globalState.AppPerformanceScoring[
                                      row.app_key
                                    ]
                                  )
                                    ? '#FFF'
                                    : theme.palette.text.primary,
                                  cursor: 'pointer',
                                  fontSize: '14px',
                                }}
                                onClick={() => {
                                  toScorePage(row)
                                }}
                              >
                                {getScoringNum(
                                  globalState.AppPerformanceScoring[row.app_key]
                                )}
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </>
  )
}
export default RetentionTableView
