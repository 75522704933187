import { useTheme, Theme, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SVGIcon } from 'src/images'
import clsx from 'clsx'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      toggleButton: {
        boxSizing: 'border-box',
        width: '40px',
        height: '32px',
        background: theme.palette.mode === 'light' ? '#ECF0F7' : '#323843',
        textAlign: 'center',
        paddingTop: '8px',
        cursor: 'pointer',
      },
      graphButton: {
        borderRadius: '6px 0 0 6px',
      },
      chartButton: {
        borderRadius: '0 6px 6px 0',
      },
      checked: {
        background: theme.palette.mode === 'light' ? '#4A5C75' : '#ECF0F7',
      },
      toggleGroup: {
        display: 'flex',
        lineHeight: '16px',
      },
    }
  })
/**
 * @param value 选中的值
 * @function onChange 按钮切换
 */
interface ChartTypeToggleGroupViewProps {
  value: string
  onChange: (value: string) => void
}

const ChartTypeToggleGroupView = (props: ChartTypeToggleGroupViewProps) => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()

  const handleAlignment = (chartType: string) => {
    if (props.value === chartType || !chartType) return
    props.onChange(chartType)
  }

  return (
    <Box className={classes.toggleGroup}>
      <Box
        className={clsx({
          [classes.toggleButton]: true,
          [classes.graphButton]: true,
          [classes.checked]: props.value === 'Graph',
        })}
        onClick={() => {
          handleAlignment('Graph')
        }}
      >
        <SVGIcon.LineChartIconSVG
          color={
            props.value === 'Graph'
              ? theme.palette.mode === 'light'
                ? '#ECF0F7'
                : '#323843'
              : theme.palette.mode === 'light'
              ? '#4A5C75'
              : '#ECF0F7'
          }
        />
      </Box>
      <Box
        className={clsx({
          [classes.toggleButton]: true,
          [classes.chartButton]: true,
          [classes.checked]: props.value === 'Chart',
        })}
        onClick={() => {
          handleAlignment('Chart')
        }}
      >
        <SVGIcon.BarChartIconSVG
          color={
            props.value !== 'Graph'
              ? theme.palette.mode === 'light'
                ? '#ECF0F7'
                : '#323843'
              : theme.palette.mode === 'light'
              ? '#4A5C75'
              : '#ECF0F7'
          }
        />
      </Box>
    </Box>
  )
}

export default ChartTypeToggleGroupView
