import { useContext } from 'react'
import { Box, Theme, useTheme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DashContext } from 'src/context/dashboard'
import { GlobalContext } from 'src/context/global'
import TopFilter from './topFilter'
import TopChartView from './revenueChart/topChart'
import PerformanceScoreChartView from './performanceScoreChart'
import TopCardView from './topCard'
import TopGamesView from './topGames'
import UpsellGamesView from './upsellGames'
import CountryChartView from './countryChart'
import { SVGIcon } from 'src/images'
import { useNavigate } from 'react-router-dom'
import { StylePaper } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      content: {
        margin: '20px 24px 100px',
        position: 'relative',
      },
      button: {
        boxSizing: 'border-box',
        width: 160,
        height: '40px',
        lineHeight: '14px',
        padding: '8px 16px',
        color:
          theme.palette.mode === 'light' ? '#FFF' : theme.palette.primary.main,
        borderRadius: '6px',
        fontWeight: 600,
        fontSize: '14px',
        cursor: 'pointer',
        background: theme.palette.mode === 'light' ? '#1D3353' : 'transparent',
        border:
          theme.palette.mode === 'light'
            ? '1px solid #1D3353'
            : `1px solid ${theme.palette.primary.main}`,
      },
      revenueChart: {
        boxSizing: 'border-box',
        padding: '22px 24px 8px 24px',
        borderRadius: 8,
        background: theme.palette.background.paper,
      },
      middleCardWrap: {
        paddingTop: 24,
        display: 'grid',
        gap: 24,
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      middleCard: {
        height: 130,
        width: '100%',
        borderRadius: 8,
        background: theme.palette.background.paper,
      },
      countries: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 8,
        background: theme.palette.background.paper,
        padding: '18px 20px',
        overflow: 'hidden',
      },
      retentions: {
        height: 322,
        width: '100%',
        borderRadius: 8,
        marginTop: 24,
        padding: 22,
        boxSizing: 'border-box',
        background: theme.palette.background.paper,
      },
      topGame: {
        width: '100%',
        padding: 22,
        margin: '24px 0',
        boxSizing: 'border-box',
        borderRadius: 8,
        background: theme.palette.background.paper,
      },
    }
  })
const Welcome = (): JSX.Element => {
  const { state } = useContext(GlobalContext)
  const { state: dashState } = useContext(DashContext)
  const { userInfo } = state
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const min1000 = useMediaQuery('(min-width:1000px)')

  return (
    <>
      <TopFilter />
      <Box className={classes.content}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            fontSize: '24px',
            lineHeight: '58px',
            fontWeight: 600,
            color: theme.palette.text.primary,
            background: theme.palette.background.default,
          }}
          id="dashboard-title"
        >
          <Box>Welcome, {userInfo?.name || ''}!</Box>
          {!state.limitAppView && state.accountAuthority.finance_page !== 0 && (
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                padding: '8px 10px',
                borderRadius: '8px',
                boxShadow:
                  theme.palette.mode === 'light'
                    ? '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)'
                    : '0 1px 2px 0 rgba(255, 255, 255, 0.04), 0 1px 6px -1px rgba(255, 255, 255, 0.03), 0 2px 4px 0 rgba(255, 255, 255, 0.03)',
                background: theme.palette.background.paper,
                minWidth: '180px',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/dash/finance')
              }}
            >
              <SVGIcon.CurrentBalanceSVG style={{ verticalAlign: 'bottom' }} />
              <Box marginLeft="6px" textAlign="right">
                <Box
                  sx={{
                    fontSize: '14px',
                    lineHeight: '17px',
                    marginBottom: '5px',
                    fontWeight: 400,
                  }}
                >
                  Current Balance
                </Box>
                <Box
                  sx={{
                    fontSize: '16px',
                    color: '#1E96FC',
                    fontWeight: 700,
                    lineHeight: '19px',
                  }}
                >
                  ${dashState.financeCardData || 0}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          <TopCardView />
        </Box>
        <Box
          sx={
            state.showPerformanceScoring
              ? {
                  display: !min1000 ? 'block' : 'flex',
                  gap: '24px',
                  marginTop: '23px',
                }
              : {
                  marginTop: '23px',
                }
          }
        >
          <StylePaper className={classes.revenueChart} flex={1}>
            <TopChartView />
          </StylePaper>
          {state.showPerformanceScoring && (
            <StylePaper
              width={!min1000 ? '100%' : '378px'}
              marginTop={!min1000 ? '24px' : '0'}
            >
              <PerformanceScoreChartView />
            </StylePaper>
          )}
        </Box>
        <StylePaper className={classes.topGame}>
          <TopGamesView />
        </StylePaper>
        {!!(
          dashState.upsellAppDataLoading || dashState.upsellAppData.length
        ) && (
          <StylePaper className={classes.topGame}>
            <UpsellGamesView />
          </StylePaper>
        )}
        <StylePaper className={classes.countries}>
          <CountryChartView />
        </StylePaper>
      </Box>
    </>
  )
}

export default Welcome
