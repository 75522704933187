import { Box } from '@material-ui/core'
import { SVGIcon } from 'src/images'
const UpsellProgressView = (props: any) => {
  const view = (props: { icon: any }) => {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '28px',
          opacity: 0.5,
        }}
      >
        <SVGIcon.ProgressBoxSVG color="#FF8E00" />
        <props.icon
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    )
  }
  return (
    <Box display="inline-block" marginTop="3px">
      <Box sx={{ display: 'flex' }}>
        <>{view({ icon: SVGIcon.ProgressOneSVG })}</>
        <>{view({ icon: SVGIcon.ProgressTwoSVG })}</>
        <>{view({ icon: SVGIcon.ProgressThreeSVG })}</>
        <>{view({ icon: SVGIcon.ProgressFourSVG })}</>
        <>{view({ icon: SVGIcon.ProgressFiveSVG })}</>
        <>{view({ icon: SVGIcon.ProgressSixSVG })}</>
        <>{view({ icon: SVGIcon.ProgressSevenSVG })}</>
      </Box>
    </Box>
  )
}

export default UpsellProgressView
