const lang = {
  common: {
    ok: 'OK',
    cancel: 'Cancel',
    confirm: ' Confirm',
    success: 'Success',
  },
  dashboard: {
    addNewGame: 'Add New Game',
    noValidateMsgTitle: 'Please Confirm Your Email',
    resendVerifyEmail: 'Resend Verify Email',
    noValidateMsgText:
      'You cannot submit a game before confirming your email. A confirmation link will be send after you click the resend button. Please check your spam folder if you are unable to find it.',
  },
  auth: {
    login: {
      sign_in: 'Sign In',
      email: 'Email address',
      emailErrMsg: 'Email is incorrect',
      password: 'Password',
      passwordMinMsg: 'Password is too short',
      forgot_password: 'Forgot password?',
      dont_have_account: "Don't have an account?",
      signup: 'Sign Up',
    },
    password: {
      reset: 'Reset Password',
      reset_desc:
        'Please add email address associated with account to reset your password',
      sign_up_desc:
        '🔥 Holiday Welcome Bonus: Get $25 dollars when you release your first game with MAS. Valid until December 31st. For Terms and Conditions, type "Welcome Bonus" in the chat once you log in.',
      resetPasswdSuccessMsgTitle: 'Your password reset successfully',
      resetPasswdSuccessMsgBody:
        'Now you can use new password log in to the MAS Portal.',
      resetPasswdEmailSuccessMsgTitle: 'Check your email',
      resetPasswdEmailSuccessMsgBody:
        'A password reset link has been sent to your email, please check your spam folder if you are unable to find it.',
    },
    signup: {
      accept_terms_desc: 'By creating an account I accept Yodo1’s',
      password_confirm: 'Confirm Password',
      company_name: 'Studio Name / Your Name',
      contact_name: 'Contact Name',
      firstNameMinMsg: 'First Name is too short',
      lastNameMinMsg: 'Last Name is too short',
      website_url: 'Website Url',
      componyNameErrMsg: 'Studio name or your name is required',
      firstNameErrMsg: 'First name is required',
      lastNameErrMsg: 'Last name is required',
      registerSuccessMsgTitle: 'Your Account is registered!',
      registerSuccessMsgBody:
        "You're one step closer to maximizing your revenue with MAS. Check your email to confirm your account.",
      registerSuccessMsgBody2:
        " Don't see our email? Please check your spam folder.",
    },
    emailVerify: {
      resend_email: 'Resend Email',
      next: 'Next',
      resendEmailMsg: 'Verification email resent.',
      verifySuccessMsgTitle: 'Congratulations',
      verifySuccessMsgBody:
        'Your email is verified. You are now able to use all features offered in the MAS Portal.',
      verifyMsgTitle: 'Your Account is registered!',
      verifyMsgBody:
        "You're one step closer to maximizing your revenue with MAS. Check your email to confirm your account.",
      verifyMsgBody2: " Don't see our email? Please check your spam folder.",
    },
  },
  links: {
    terms_conditions: 'Terms and conditions',
    privacy_policy: 'Privacy policy',
  },
  server: {
    testDeviceExists: 'Duplicate device ID, the device already exists',
    dataError: 'Data Error',
    invalidEmail: 'Invalid Email',
    verifyFailed: 'Verify Failed',
    verifyCodeError: 'Verify Code is incorrect',
    verifyCodeGetError: 'Error while getting the Verify Code',
    passwordError: 'Password is Incorrect',
    review: 'Account is under review',
    userNoExist: 'User does not exist',
    loginNameExist: 'Username already exists',
    childExists: 'The email has  already existed',
    partnerExists: 'The email has registered as a master account.',
    currentGroupExist: 'Current role already exists',
    groupUsed: 'The current role is occupied and cannot be deleted',
    registerSuccess: 'Sign up Success!',
    htmlError: 'Page failed to load',
    error: 'Server Error',
    noPermission: "You don't have the permission to enter this page",
    mailSend: 'Email has been sent',
    resetEmail:
      'Mail with password reset instructions has been sent to your inbox.',
    changePasswordError: 'The password is incorrect for the given Username',
    gameNoExist: 'games does not exist',
    gameExist: 'games already exist',
    modifySuccess: 'Modify Success!',
    partnerNoExist: 'User does not exist',
    partnerExist: 'User already exist',
    loginError: 'Username or password is incorrect',
    loginTimeOutTitle: 'Login time out',
    loginTimeOutMsg: 'Please login again',
    support: 'Email send success',
    appNameNotAvailable: 'App name is not available',
    appNameExist: 'App name is already exist',
    exist: 'is already exist',
    publishSuccess: 'Successfully Submitted',
    existErr:
      'This URL has been registered. Please contact us via mas.support@yodo1.com if you have any questions.',
    urlErr: 'Invalid link, please modify the link or click the off-line button',
    admobTotalsErr:
      'Not enough Android Admob App ID available! Please add in time',
    bundleIDExist_iOS: 'current BundleID is already exist',
    bundleIDExist_android: 'current packageName is already exist',
  },
  setting: {
    personalInfo: {
      emailErrMsg: 'Please enter email address',
      contactNameErrMsg: 'Please enter the contact name',
      verificationCodeErrMsg: 'Please enter the verification code',
      avatarLimitErrMsg: 'Image size must larger than 20 KB less than 1MB',
      emailChangeSuccessMsg: 'Email Changed Successfully!',
      contactName: 'Contact Name',
    },
    password: {
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      passwordRequiredErrMsg: 'Please enter the password',
      passwordMinMaxErrMsg: 'The password should be 6 to 20 digits',
      passwordConfirmErrMsg: 'Confirmation password need same as new password',
    },
  },
  game: {
    deleteGameTitle: 'Are you sure you want to delete',
    deleteGameContent: 'NOTE: This action cannot be undone!',
  },
  testDevices: {
    topText1:
      'Tip: It takes 10 minutes for test mode to take effect. Please check the init log on your device to ensure that test mode is active.',
    topText2:
      'Use this page to add a test device first and then toggle test mode on in Games Management -> ads Management page. This is necessary to test that ads will show in your game. Not clear on how to test ads?  ',
    topText3: 'Check here',
    topText4: 'Current number of test device: ',
    addDeviceTip:
      "Tip: According Google Play’s family policy, the user's GAID is transmitted only when the user is not a child.",
    addDeviceTip2:
      "In order to enable test ads function, please set COPPA false or choose the user's age >13 when initialization.",
  },
  validator: {
    device_id_format: 'Incorrect device id format, Please enter again.',
    device_id_input: 'Please input device ID',
    device_name_input: 'Please input device name',
    account_email_input: 'Please input account email',
    account_role_select: 'Please select account role',
    account_email_error: 'Please input a valid email',
    verificationCodeErrMsg: 'Please enter the verification code',
  },
  // API Error Code
  unknown: 'General (unknown) error',
  'app.unknown': 'General (unknown) error',
  'api.missing_param': 'missing auth params',
  'app.duplicated_bundle_id':
    'This app already added to our system by you or another account',
  'app.bundle_id_not_mutable': 'App bundle-id is not mutable',
  'user.weak_password': 'User password is too short / weak, at least 6 chars',
  'user.wrong_password': 'Wrong password',
  'user.verify_code_outdated':
    'Your verification code has expired. Please request your verification code again.',
  'user.wrong_verify_code': 'Verification code is incorrect',
  'user.duplicated_email':
    'This email address is already used by another developer account',
  'user.not_found': 'The account is not registered yet',
  'api.error_email': 'Email not registered',
  'user.reset_passwd_code_outdated': 'Reset password link has expired',
  'app.store_link_not_valid': 'Store link validation failed',
  'app.store_not_supported': 'This application store is not supported',
  'user.email_is_taken':
    'The email address has been used, please use another email address',
  'user.change_email_code_wrong': 'Verification code error',
  'user.change_email_code_outdated': 'The code is wrong or has been timeout',
  'test_device.device_not_exist': 'Device not exist',
  'test_device.duplicate_device_id': 'Duplicate device id',
  'user.code_outdated': 'The code is wrong or has been timeout',
  'user.code_wrong': 'Wrong Referral Code',
  'user.no_referral_code': 'Wrong Referral Code',
  'user.platform_not_match': 'platform not match',
  dashboard_page: 'Dashboard Page',
  finance_page: 'Finance Page',
  game_manage_page: 'Game Manage Page',
  game_submission: 'Game Submission',
  games_page: 'Games Page',
  notification_page: 'Notification Page',
  roles_page: 'Users Page',
  settings_page: 'Settings Page',
  testing_page: 'Testing Page',
  ad_placement_page: 'Ad Placement Page',
  overview_page: 'Performance Page',
  user_activity_page: 'User Activity Page',
  gameplay_insights_page: 'Gameplay Insights Page',
  engagement_page: 'Engagement Page',
}

export default lang
