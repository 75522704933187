import { useContext, useEffect, useState } from 'react'
import { Box, Theme, useTheme } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { StylePaper } from 'src/components'
import { useNavigate } from 'react-router-dom'
import { Icons } from 'src/images'
import { GlobalStoreContext } from 'src/context/globalStore'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      content: {
        display: 'flex',
      },
      closeIcon: {
        position: 'absolute',
        right: '8px',
        top: '14px',
        cursor: 'pointer',
      },
      left: {
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '44px',
      },
      leftIcon: {
        width: '32px',
        height: '32px',
      },
      title: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#FF8E00',
      },
      textContent: {
        maxWidth: '380px',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14px',
        color: theme.palette.mode === 'light' ? '#B0B5B9' : '#F7FAFC',
      },
      clickContent: {
        color: '#2F80ED',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    }
  })
const notice_type = {
  APP_NOT_RELEASED: 'is not released within 3 days',
  'yodo1.issues.ads_txt': 'has an app-ads.txt issue',
  'yodo1.issues.store': 'has a Store issue',
  'yodo1.issues.restricted_ad_serving': 'has an Admob issue.',
  'yodo1.issues.ad_serving_at_risk': 'has an Admob issue',
}

const FlashNotificationView = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { state, getFlashNotification, setFlashNotification } =
    useContext(GlobalStoreContext)
  const toIssuePage = (item: any) => {
    closeNotification(item)
    if (item.notice_type === 'APP_NOT_RELEASED') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Intercom('show')
    } else {
      navigate(`/dash/games/manage/${item.app_key}/issue`)
    }
  }
  const closeNotification = (item: any) => {
    setFlashNotification(item.id)
    setOpen(false)
  }
  const warning = () => (
    <Box className={classes.content}>
      <Box className={classes.left}>
        <img src={Icons.warningIcon} alt="icon" className={classes.leftIcon} />
      </Box>
      <Box>
        <Box className={classes.title}>Warning!</Box>
        <Box className={classes.textContent}>
          Your game, {state.flashNotification.app_name},{' '}
          {notice_type[state.flashNotification.notice_type]}. Please{' '}
          <span
            className={classes.clickContent}
            onClick={() => {
              toIssuePage(state.flashNotification)
            }}
          >
            click here
          </span>{' '}
          for more details.
        </Box>
      </Box>
    </Box>
  )
  const information = () => (
    <Box className={classes.content}>
      <Box className={classes.left}>
        <img
          src={Icons.informationIcon}
          alt="icon"
          className={classes.leftIcon}
        />
      </Box>
      <Box>
        <Box className={classes.title} color="#2F80ED">
          Information
        </Box>
        <Box className={classes.textContent}>
          Your game, {state.flashNotification.app_name},{' '}
          {notice_type[state.flashNotification.notice_type]}. Please{' '}
          <span
            className={classes.clickContent}
            onClick={() => {
              toIssuePage(state.flashNotification)
            }}
          >
            click here
          </span>{' '}
          for more details.
        </Box>
      </Box>
    </Box>
  )
  const done = () => (
    <Box className={classes.content}>
      <Box className={classes.left}>
        <img src={Icons.doneIcon} alt="icon" className={classes.leftIcon} />
      </Box>
      <Box>
        <Box className={classes.title} color="#27AE60">
          Done!
        </Box>
        <Box className={classes.textContent}>
          Your game, {state.flashNotification.app_name},{' '}
          {notice_type[state.flashNotification.notice_type]}. Please{' '}
          <span
            className={classes.clickContent}
            onClick={() => {
              toIssuePage(state.flashNotification)
            }}
          >
            click here
          </span>{' '}
          for more details.
        </Box>
      </Box>
    </Box>
  )
  useEffect(() => {
    const timer = setTimeout(() => {
      getFlashNotification()
      clearTimeout(timer)
    }, 20000)
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (state.flashNotification.app_key) {
      setOpen(true)
    }
  }, [state.flashNotification])

  // returns
  return (
    <>
      {open && (
        <StylePaper
          boxSizing="border-box"
          width="70vw"
          maxWidth="466px"
          position="fixed"
          top="8px"
          left="50%"
          transform="translateX(-50%)"
          padding="4px 8px"
          boxShadow="0px 0px 2px rgba(145, 158, 171, 0.6), 0px 12px 24px rgba(145, 158, 171, 0.06)"
          zIndex={1199}
        >
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              closeNotification(state.flashNotification)
            }}
          />
          {state.flashNotification.variant === 'DONE'
            ? done()
            : state.flashNotification.variant === 'INFO'
            ? information()
            : warning()}
        </StylePaper>
      )}
    </>
  )
}

export default FlashNotificationView
