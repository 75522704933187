import { useState, useContext } from 'react'
import {
  Box,
  Grid,
  Typography,
  Switch,
  useTheme,
  Theme,
} from '@material-ui/core'
import Skeleton from '@material-ui/core/Skeleton'
import { makeStyles } from '@material-ui/styles'
import { UnsubscribeContext } from 'src/context/unsubscribe'
import { TopTitle, StylePaper, LoadingBtn } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: 645,
        minWidth: 400,
        margin: 'auto',
        paddingTop: '24px',
      },
      button: {
        position: 'absolute',
        right: 0,
        top: '-4px',
      },
      skeletonButton: {
        position: 'absolute',
        right: 0,
        top: '2px',
      },
      title: {
        fontSize: '20px',
        fontWeight: 500,
        position: 'relative',
        marginTop: '12px',
      },
      content: {
        fontSize: '14px',
        lineHeight: '22px',
        color: theme.palette.text.secondary,
        margin: '8px 0 24px',
      },
      line: {
        height: '1px',
        background: '#eee',
      },
    }
  })

const UnsubscribeView = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, switchState } = useContext(UnsubscribeContext)
  const [activeSwitch, setActiveSwitch] = useState<string>('')
  const restoreDefaults = () => {
    setActiveSwitch('')
    switchState('restoreDefaults')
  }
  return (
    <Box className={classes.container}>
      <TopTitle
        title="Manage Notifications"
        buttonText="Restore Defaults"
        buttonClick={restoreDefaults}
        hideAddIcon={true}
      />
      {/* <Box marginTop="8px" color={theme.palette.text.secondary}>
        Manage your MAS notification subscriptions here, the notifications
        include group emails and site notifications.
      </Box> */}
      <StylePaper
        width="100%"
        boxSizing="border-box"
        padding="12px 24px"
        marginTop="24px"
      >
        {/* Essential */}
        <Box className={classes.title}>
          Essential
          {state.loading ? (
            <Skeleton
              className={classes.skeletonButton}
              variant="rectangular"
              width={48}
              height={24}
            />
          ) : (
            <Typography
              color="textSecondary"
              component="div"
              className={classes.button}
            >
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Switch
                    checked={true}
                    name="checkedC"
                    color="primary"
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Typography>
          )}
        </Box>
        <Box className={classes.content}>
          <Box>
            These are essential notification mechanisms that MAS Portal users
            can not be unsubscribed, including verification code/emergency
            connection, etc.
          </Box>
        </Box>
        <Box className={classes.line} />
        {/* Updates */}
        <Box className={classes.title}>
          Updates
          {state.loading ? (
            <Skeleton
              className={classes.skeletonButton}
              variant="rectangular"
              width={48}
              height={24}
            />
          ) : (
            <Typography
              color="textSecondary"
              component="div"
              className={classes.button}
            >
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  {state.switchLoading &&
                    (activeSwitch === 'updates' || !activeSwitch) && (
                      <Box
                        position="absolute"
                        left="50%"
                        top="50%"
                        zIndex={9}
                        style={{
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <LoadingBtn
                          loadingStyle={{
                            justifyContent: 'center',
                          }}
                          noText={true}
                        />
                      </Box>
                    )}
                  <Switch
                    checked={state.unsubscribe?.updates}
                    name="checkedC"
                    color="primary"
                    disabled={state.switchLoading}
                    onChange={() => {
                      setActiveSwitch('updates')
                      switchState('updates')
                    }}
                  />
                </Grid>
              </Grid>
            </Typography>
          )}
        </Box>
        <Box className={classes.content}>
          <Box>
            Including product updates, such as SDK updates with details.
          </Box>
        </Box>
        <Box className={classes.line} />
        {/* Announcements */}
        <Box className={classes.title}>
          Announcements
          {state.loading ? (
            <Skeleton
              className={classes.skeletonButton}
              variant="rectangular"
              width={48}
              height={24}
            />
          ) : (
            <Typography
              color="textSecondary"
              component="div"
              className={classes.button}
            >
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  {state.switchLoading &&
                    (activeSwitch === 'announcements' || !activeSwitch) && (
                      <Box
                        position="absolute"
                        left="50%"
                        top="50%"
                        zIndex={9}
                        style={{
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <LoadingBtn
                          loadingStyle={{
                            justifyContent: 'center',
                          }}
                          noText={true}
                        />
                      </Box>
                    )}
                  <Switch
                    checked={state.unsubscribe?.announcements}
                    name="checkedC"
                    color="primary"
                    disabled={state.switchLoading}
                    onChange={() => {
                      setActiveSwitch('announcements')
                      switchState('announcements')
                    }}
                  />
                </Grid>
              </Grid>
            </Typography>
          )}
        </Box>
        <Box className={classes.content}>
          <Box>Including Yodo1 new products, big adjustments, etc.</Box>
        </Box>
        <Box className={classes.line} />
        {/* Support */}
        <Box className={classes.title}>
          Support
          {state.loading ? (
            <Skeleton
              className={classes.skeletonButton}
              variant="rectangular"
              width={48}
              height={24}
            />
          ) : (
            <Typography
              color="textSecondary"
              component="div"
              className={classes.button}
            >
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  {state.switchLoading &&
                    (activeSwitch === 'support' || !activeSwitch) && (
                      <Box
                        position="absolute"
                        left="50%"
                        top="50%"
                        zIndex={9}
                        style={{
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <LoadingBtn
                          loadingStyle={{
                            justifyContent: 'center',
                          }}
                          noText={true}
                        />
                      </Box>
                    )}
                  <Switch
                    checked={state.unsubscribe?.support}
                    name="checkedC"
                    color="primary"
                    disabled={state.switchLoading}
                    onChange={() => {
                      setActiveSwitch('support')
                      switchState('support')
                    }}
                  />
                </Grid>
              </Grid>
            </Typography>
          )}
        </Box>
        <Box className={classes.content}>
          <Box>
            When we detected your app&apos;s data with risk or we think you may
            need help, we will send emails to connect with you to keep the
            monetization quality.
          </Box>
        </Box>
      </StylePaper>
    </Box>
  )
}

export default UnsubscribeView
