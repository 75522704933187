import { useContext } from 'react'
import { CompareGamesContext } from 'src/context/compareGames'
import { BorderStyleDate, TopFilterBox } from 'src/components'
import dayjs from 'dayjs'

const TopFilterView = (props: any): JSX.Element => {
  const { state, dispatch, searchData } = useContext(CompareGamesContext)

  const timeChange = (dateRange: any[], timeShow: string) => {
    dispatch({
      type: 'time',
      payload: dateRange,
    })
    dispatch({
      type: 'timeShow',
      payload: timeShow,
    })
  }

  const search = () => {
    searchData()
  }
  const reset = () => {
    timeChange(
      [
        dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      ],
      'last_week'
    )
    dispatch({
      type: 'resetIndex',
      payload: state.resetIndex + 1,
    })
  }

  // returns
  return (
    <TopFilterBox
      ContentView={
        <>
          <BorderStyleDate
            label="Dates"
            timeShow={state.timeShow}
            value={state.time}
            onChange={timeChange}
            onTriggerFilter={search}
            onlySelectLast6Month={true}
          />
        </>
      }
      search={search}
      reset={reset}
    />
  )
}

export default TopFilterView
