import { TablePagination, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useEffect, useState } from 'react'
import Skeleton from '@material-ui/core/Skeleton'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      pagination: {
        marginTop: '24px',
        '& .MuiToolbar-root': {
          overflow: 'hidden',
        },
        '& .MuiTablePagination-selectLabel': {
          position: 'absolute',
          margin: '0px',
        },
        '& .MuiInputBase-root': {
          position: 'absolute',
          left: '130px',
          boxShadow: 'none',
        },
        '& .MuiTablePagination-select:focus': {
          background: 'none',
        },
        '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
          margin: '-4px 0',
          '&.MuiTablePagination-selectIcon': {
            margin: 0,
          },
        },
      },
    }
  })
const StyleTablePagination = (props: any) => {
  const { total = 0, page = 1, pageSize = 20, pageChange, disabled } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    pageChange(newPage + 1, rowsPerPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageChange(1, parseInt(event.target.value, 10))
  }

  // effects
  useEffect(() => {
    setRowsPerPage(pageSize)
  }, [pageSize])

  return (
    <>
      {disabled ? (
        <Skeleton height={52} />
      ) : (
        <TablePagination
          className={classes.pagination}
          component="div"
          count={total}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 20, 50, 100, 200]}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => {
            return `Page ${page} of ${Math.ceil(count / rowsPerPage)}`
          }}
          sx={
            props.isMobile
              ? {
                  '& .MuiToolbar-root': {
                    paddingLeft: 0,
                  },
                  '& .MuiInputBase-root': {
                    position: 'absolute',
                    left: '94px',
                    boxShadow: 'none',
                  },
                  '& .MuiTablePagination-actions': {
                    marginLeft: '6px',
                  },
                }
              : {}
          }
        />
      )}
    </>
  )
}

export default StyleTablePagination
