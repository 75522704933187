const SVG = (props: any) => {
  const width = props.width || '12'
  const height = props.height || '12'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.3146L1.55406 7.86903L7.58405 1.83998L7.58405 13.6719L8.36844 13.6719L8.36844 1.83895L14.3974 7.86903L14.9525 7.3146L7.97643 0.338543L1 7.3146Z"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SVG
