import { useState, useContext, MouseEvent } from 'react'
import {
  Box,
  Popover,
  Tooltip,
  useTheme,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SVGIcon } from 'src/images'
import { SessionsContext } from 'src/context/gamePlayInsights/sessions'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      popover: {
        '& .MuiPaper-root': {
          minWidth: '300px',
          width: '300px',
          maxHeight: '394px',
          marginTop: '4px',
          borderRadius: '6px',
          padding: '16px 12px',
          background: theme.palette.custom.dialogBg,
        },
      },
    }
  })

const SortButton = () => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch } = useContext(SessionsContext)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<unknown>, value: string) => {
    const selectedIndex = state.checkedChartLine.indexOf(value)
    let newSelected: string[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.checkedChartLine, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.checkedChartLine.slice(1))
    } else if (selectedIndex === state.checkedChartLine.length - 1) {
      newSelected = newSelected.concat(state.checkedChartLine.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.checkedChartLine.slice(0, selectedIndex),
        state.checkedChartLine.slice(selectedIndex + 1)
      )
    }
    dispatch({
      type: 'checkedChartLine',
      payload: newSelected,
    })
  }

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Box
        sx={{
          boxSizing: 'border-box',
          width: '24px',
          height: '24px',
          padding: '3px',
          cursor: 'pointer',
        }}
        aria-describedby={id}
        onClick={handleOpen}
      >
        <Tooltip title="Filter graph legends" arrow placement="top">
          <Box>
            <SVGIcon.SettingSVG
              color={theme.palette.text.primary}
              style={{ verticalAlign: 'text-top', lineHeight: '18px' }}
            />
          </Box>
        </Tooltip>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
        sx={{ zIndex: 1999 }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {state.chartList.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={event => handleClick(event, row.value)}
                  role="checkbox"
                  aria-checked={state.checkedChartLine.includes(row.value)}
                  tabIndex={-1}
                  selected={state.checkedChartLine.includes(row.value)}
                  style={{
                    cursor: 'pointer',
                    background: theme.palette.custom.chartTableBg,
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    style={{ padding: 0, width: 42 }}
                  >
                    <Checkbox
                      color="primary"
                      checked={state.checkedChartLine.includes(row.value)}
                      style={{ color: '#7487AA' }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ padding: 0, width: 20 }}
                  >
                    <Box
                      sx={{
                        width: '20px',
                        height: '10px',
                        background: row.color,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: theme.palette.custom.inputLabel,
                      fontSize: 12,
                      padding: '10px 12px',
                    }}
                  >
                    {row.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </>
  )
}
export default SortButton
