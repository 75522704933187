import { useContext } from 'react'
import { Button, Box, useMediaQuery } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset'
import { useTranslation } from 'react-i18next'
import { GlobalContext } from 'src/context/global'

const AddButtonView = (props: any) => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const { state } = useContext(GlobalContext)
  const min740 = useMediaQuery('(min-width:740px)')
  const clickButton = () => {
    navigate('/dash/games/add')
  }

  return (
    <>
      {state.accountAuthority.game_submission === 2 && (
        <Box sx={{ width: '100%', textAlign: 'center', marginRight: '20px' }}>
          <Button
            color="primary"
            variant="contained"
            id={props.addButtonId || ''}
            onClick={clickButton}
            style={{
              width: min740 ? '164px' : '64px',
              height: '40px',
              padding: '0',
              textAlign: 'center',
            }}
          >
            <VideogameAssetIcon style={{ marginRight: '6px' }} />
            {min740 ? t('dashboard.addNewGame') : ''}
          </Button>
        </Box>
      )}
    </>
  )
}

export default AddButtonView
