import { useContext, useState } from 'react'
import { Box, Popover, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ArrowDropDownCircleOutlined as ArrowDropDownCircleOutlinedIcon } from '@material-ui/icons'
import { GlobalContext } from 'src/context/global'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      topFilter: {
        display: 'flex',
        padding: '20px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
        top: 68,
        zIndex: 10,
      },
      topFilterOption: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '26px',
      },
      button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        width: 80,
        height: '40px',
        color: '#FFF',
        borderRadius: '6px',
        fontWeight: 600,
        fontSize: '14px',
        cursor: 'pointer',
        background: theme.palette.mode === 'light' ? '#1D3353' : '#ff7a08',
        margin: '0',
      },
      popover: {
        '& .MuiPaper-root': {
          minWidth: '200px',
          maxWidth: '500px',
          maxHeight: '70vh',
          marginTop: '4px',
          borderRadius: '6px',
          padding: '0',
          background: theme.palette.custom.dialogBg,
        },
      },
    }
  })

interface tabProps {
  ContentView: any
  PopoverView?: any
  ReportView?: any
  hideReset?: boolean
  search: () => void
  reset?: () => void
}
const TopFilterBox = (props: tabProps): JSX.Element => {
  const {
    ContentView,
    PopoverView,
    ReportView,
    hideReset = false,
    reset,
    search,
  } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state: globalState } = useContext(GlobalContext)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Box
        className={classes.topFilter}
        sx={
          !globalState.isMobile
            ? { top: 68, position: 'sticky' }
            : { top: 0, display: 'block' }
        }
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            className={classes.topFilterOption}
            sx={!globalState.isMobile ? {} : { justifyContent: 'center' }}
          >
            {ContentView}
          </Box>
          {PopoverView && (
            <Box
              sx={{
                fontSize: '12px',
                boxSizing: 'border-box',
                width: '100px',
                minWidth: '91px',
                height: '26px',
                lineHeight: '26px',
                cursor: 'pointer',
                marginLeft: '16px',
                color: theme.palette.text.secondary,
              }}
              aria-describedby={id}
              onClick={handleClick}
            >
              More filters
              <ArrowDropDownCircleOutlinedIcon
                style={{
                  verticalAlign: 'middle',
                  lineHeight: '18px',
                  color:
                    theme.palette.mode === 'dark'
                      ? 'rgb(255, 255, 255)'
                      : 'rgba(0, 0, 0, 0.54)',
                  fontSize: '20px',
                  transform: anchorEl
                    ? 'rotate(180deg) translateY(1px)'
                    : 'translateY(-1px)',
                  marginLeft: '6px',
                }}
              />
            </Box>
          )}
        </Box>
        <Box
          marginLeft="16px"
          display="flex"
          sx={
            !globalState.isMobile
              ? {
                  minWidth: !ReportView ? '133px' : '303px',
                  position: 'relative',
                }
              : { justifyContent: 'space-evenly', marginTop: '26px' }
          }
        >
          {!hideReset && (
            <Box
              sx={{
                lineHeight: '40px',
                fontSize: '14px',
                fontWeight: 600,
                cursor: 'pointer',
                marginRight: '20px',
                color: theme.palette.text.secondary,
              }}
              onClick={reset}
            >
              Reset
            </Box>
          )}
          <Box className={classes.button} id="overview-apply" onClick={search}>
            Apply
          </Box>
          {!!ReportView && ReportView}
        </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
        sx={{ zIndex: 1999 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            boxSizing: 'border-box',
            width: '300px',
            maxWidth: '90vw',
            padding: '24px',
            gap: '26px',
          }}
        >
          {PopoverView}
        </Box>
      </Popover>
    </>
  )
}

export default TopFilterBox
