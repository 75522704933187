import { useContext, useEffect } from 'react'
import dayjs from 'dayjs'
import { DashContext } from 'src/context/dashboard/index'
import {
  BorderStyleSelectMultiple,
  BorderStyleDate,
  TopFilterBox,
} from 'src/components'

const TopFilterView = (props: any): JSX.Element => {
  const { state, dispatch, searchData } = useContext(DashContext)

  const timeChange = (dateRange: any[], timeShow: string) => {
    dispatch({
      type: 'timeRange',
      payload: {
        ...state.timeRange,
        timeRange: [dateRange[0] || '', dateRange[1] || ''],
      },
    })
    dispatch({
      type: 'time',
      payload: dateRange,
    })

    dispatch({
      type: 'timeShow',
      payload: timeShow,
    })
  }

  const getArray = (arr1: any, arr2: any, key: string) => {
    if (!arr1.length || !arr2.length) {
      return arr1
    }
    const temp: any = []
    arr1.forEach((item: any) => {
      const temp2 = arr2.find((res: any) => {
        return item === res[key]
      })
      temp.push(temp2)
    })
    return temp
  }
  const resetFilters = () => {
    timeChange(
      [
        dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      ],
      'last_week'
    )
    dispatch({
      type: 'gameSelect',
      payload: [],
    })
    dispatch({
      type: 'resetIndex',
      payload: state.resetIndex + 1,
    })
  }
  // effects
  useEffect(() => {
    if (state.gameSelect.length && typeof state.gameSelect[0] === 'string') {
      dispatch({
        type: 'gameSelect',
        payload: getArray(state.gameSelect, state.gameOption, 'app_key'),
      })
    }
    // eslint-disable-next-line
  }, [state.gameOption])
  useEffect(() => {
    if (state.country.length && typeof state.country[0] === 'string') {
      dispatch({
        type: 'country',
        payload: getArray(state.country, state.countryOption, 'code'),
      })
      dispatch({
        type: 'countryChecked',
        payload: state.country,
      })
    }
    // eslint-disable-next-line
  }, [state.countryOption])
  // returns
  return (
    <TopFilterBox
      ContentView={
        <>
          <BorderStyleDate
            label="Dates"
            timeShow={state.timeShow}
            value={state.timeRange.timeRange}
            onChange={timeChange}
            showNumberButton={true}
            onTriggerFilter={searchData}
          />
          <BorderStyleSelectMultiple
            label="Apps"
            valueKey="app_key"
            labelKey="name"
            placeholder="Apps"
            value={state.gameSelect}
            onChange={(row: any) => {
              dispatch({
                type: 'gameSelect',
                payload: row,
              })
            }}
            options={state.gameOption}
            onTriggerFilter={searchData}
          />
        </>
      }
      reset={resetFilters}
      search={searchData}
    />
  )
}

export default TopFilterView
