const SVG = (props: any) => {
  const width = props.width || '16'
  const height = props.height || '16'
  const color = props.color || '#AECBFA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7602 1.99854H3.2407C2.55778 1.99854 1.99902 2.55729 1.99902 3.24021V12.7597C1.99902 13.4426 2.55778 14.0014 3.2407 14.0014H12.7602C13.4431 14.0014 14.0019 13.4426 14.0019 12.7597V3.24021C14.0019 2.55729 13.4431 1.99854 12.7602 1.99854ZM12.3463 12.7597H3.65459C3.42695 12.7597 3.2407 12.5735 3.2407 12.3458V3.6541C3.2407 3.42646 3.42695 3.24021 3.65459 3.24021H12.3463C12.574 3.24021 12.7602 3.42646 12.7602 3.6541V12.3458C12.7602 12.5735 12.574 12.7597 12.3463 12.7597Z"
        fill={color}
      />
      <path
        d="M6.96563 4.48169H4.89617C4.66853 4.48169 4.48228 4.66794 4.48228 4.89558V6.96504C4.48228 7.19268 4.66853 7.37893 4.89617 7.37893H6.96563C7.19327 7.37893 7.37952 7.19268 7.37952 6.96504V4.89558C7.37952 4.66794 7.19327 4.48169 6.96563 4.48169Z"
        fill={color}
      />
      <path
        d="M11.1035 4.48169H9.03407C8.80643 4.48169 8.62018 4.66794 8.62018 4.89558V6.96504C8.62018 7.19268 8.80643 7.37893 9.03407 7.37893H11.1035C11.3312 7.37893 11.5174 7.19268 11.5174 6.96504V4.89558C11.5174 4.66794 11.3312 4.48169 11.1035 4.48169Z"
        fill={color}
      />
      <path
        d="M6.96563 8.62085H4.89617C4.66853 8.62085 4.48228 8.8071 4.48228 9.03474V11.1042C4.48228 11.3318 4.66853 11.5181 4.89617 11.5181H6.96563C7.19327 11.5181 7.37952 11.3318 7.37952 11.1042V9.03474C7.37952 8.8071 7.19327 8.62085 6.96563 8.62085Z"
        fill={color}
      />
      <path
        d="M11.1035 8.62085H9.03407C8.80643 8.62085 8.62018 8.8071 8.62018 9.03474V11.1042C8.62018 11.3318 8.80643 11.5181 9.03407 11.5181H11.1035C11.3312 11.5181 11.5174 11.3318 11.5174 11.1042V9.03474C11.5174 8.8071 11.3312 8.62085 11.1035 8.62085Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
