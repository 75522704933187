import {
  Box,
  Container,
  Button,
  Typography,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FinanceContext } from 'src/context/finance'
import { GlobalContext } from 'src/context/global'
import { PopoverTip } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        boxSizing: 'border-box',
        height: '250px',
        position: 'relative',
        padding: '21px 24px',
      },
      title: {
        fontSize: '20px',
        fontWeight: 500,
        color: theme.palette.text.primary,
      },
      cardBox: {
        boxShadow: theme.shadows[3],
        borderRadius: '15px',
        height: '244px',
        marginTop: '20px',
      },
      cardTop: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '50px',
        padding: '24px 20px 0 0',
      },
      editButton: {
        position: 'absolute',
        right: '24px',
        top: '16px',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        border: `1px solid ${theme.palette.custom.inputBorder}`,
        borderRadius: '6px',
        fontSize: '14px',
        color: theme.palette.text.secondary,
        padding: '0 28px',
        cursor: 'pointer',
      },
      cardMaid: {
        overflow: 'hidden',
        color: theme.palette.text.primary,
      },
      cardAttribute: {
        fontSize: 14,
        flex: 3,
        marginRight: 24,
      },
      cardValue: {
        fontSize: 14,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: 5,
      },
      button: {
        height: '40px !important',
        padding: '0 17px !important',
      },
    }
  })

const profileTitle = Object.freeze({
  Name: 'Name',
  // CompanyName: "Company Name",
  Address: 'Address',
  PaymentMethod: 'Payment Method',
  Email: 'Email',
})

const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigator = useNavigate()
  const {
    state: { profileInfo },
  } = useContext(FinanceContext)
  const { state: globalState } = useContext(GlobalContext)
  const [profile, setProfile] = useState({
    Name: profileInfo?.Name,
    // CompanyName: profileInfo?.CompanyName,
    Address: profileInfo?.Address,
    PaymentMethod: profileInfo?.PaymentMethod,
    Email: profileInfo?.Email,
  })
  const onEdit = () => {
    navigator(`/dash/finance/${isCreated ? 'edit' : 'create'}`)
  }

  const isCreated = useMemo(() => !!profileInfo, [profileInfo])

  useEffect(() => {
    setProfile({
      Name: profileInfo?.Name,
      // CompanyName: profileInfo?.CompanyName,
      Address: profileInfo?.Address,
      PaymentMethod: profileInfo?.PaymentMethod,
      Email: profileInfo?.Email,
    })
  }, [profileInfo])

  return (
    <Box className={classes.root} id="finance-profile">
      <Box
        className={classes.title}
        sx={{
          color:
            theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#FFFFFF',
        }}
      >
        Payment Profile
        <PopoverTip>
          <Box lineHeight="22px">
            To ensure smooth processing of monthly payments on 10th of every
            month do not make any changes to your payment details between 1st -
            10th of the month. Reach out to{' '}
            <Box
              component="span"
              color={theme.palette.primary.main}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.Intercom && window.Intercom('show')
              }}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              support
            </Box>{' '}
            if you need to perform any last minute changes.
          </Box>
        </PopoverTip>
      </Box>
      {isCreated && globalState.accountAuthority.finance_page === 2 && (
        <Box className={classes.editButton} onClick={onEdit}>
          Edit
        </Box>
      )}
      <Container className={classes.cardMaid}>
        {isCreated ? (
          <Box marginTop="40px" marginLeft="-24px">
            {Object.keys(profile).map(key => (
              <Box key={key} marginBottom="16px" display="flex">
                <Box className={classes.cardAttribute} minWidth="121px">
                  {profileTitle[key]}:
                </Box>
                <Box className={classes.cardValue}>{profile[key]}</Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box textAlign="center">
            <Typography
              color="textPrimary"
              variant="subtitle1"
              marginBottom="24px"
              marginTop="37px"
            >
              Please set up a payment profile to receive payments from MAS.
            </Typography>
            {globalState.accountAuthority.finance_page === 2 && (
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={onEdit}
              >
                Setup payment profile
              </Button>
            )}
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default View
