import { Box, Button, Dialog, useTheme, Theme } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { LoadingBtn } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        width: '80vw',
        maxWidth: '340px',
        padding: '12px 20px 20px',
        boxSizing: 'border-box',
        lineHeight: '22px',
      },
      title: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: 600,
        height: '20px',
      },
      closeFilterIcon: {
        position: 'absolute',
        fontSize: '24px !important',
        right: '0px',
        top: '0',
        cursor: 'pointer',
      },
      content: {
        fontSize: '16px',
        margin: '20px 0',
        lineHeight: '22px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      button: {
        width: '142px',
        height: '40px',
        fontSize: '14px',
      },
    }
  })
/**
 * @param openModel 显示隐藏
 * @function onSubmit 确认回调
 * @function onClose 关闭区域点击回调
 */
interface RatingDialogProps {
  openModel: boolean
  onClickClose: () => void
  onClickYes: () => void
  onClickNo: () => void
  loading?: boolean
  gameName: string
}
const Layout = (props: RatingDialogProps): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const close = () => {
    props.onClickClose()
  }
  const yes = () => {
    props.onClickYes()
  }
  const no = () => {
    props.onClickNo()
  }
  // returns
  return (
    <Dialog open={props.openModel} onClose={close} sx={{ zIndex: 1999 }}>
      <Box className={classes.root}>
        <Box className={classes.title}>
          <CloseIcon className={classes.closeFilterIcon} onClick={close} />
        </Box>
        <Box className={classes.content}>
          Have you monetized &quot;{props.gameName}&quot; with any ad network or
          mediation before?
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            style={{ marginRight: '16px' }}
            onClick={yes}
          >
            {props.loading ? <LoadingBtn /> : 'Yes'}
          </Button>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={no}
          >
            {props.loading ? <LoadingBtn /> : 'No'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default Layout
