import { Box, colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
const useStyles = makeStyles(() => {
  return {
    leftIcon: {
      transform: 'rotate(90deg)',
      verticalAlign: 'bottom',
      fontSize: '20px !important',
    },
    rightIcon: {
      transform: 'translateX(-13px) rotate(-90deg)',
      verticalAlign: 'bottom',
      fontSize: '20px !important',
    },
  }
})
const SortButton = (props: { sort: string; clickSort: any }) => {
  const classes = useStyles()
  const sortList = () => {
    props.clickSort &&
      props.clickSort(props.sort === 'descending' ? 'rise' : 'descending')
  }
  return (
    <Box
      display="flex"
      width="20px"
      style={{ cursor: 'pointer' }}
      onClick={sortList}
    >
      <ArrowRightAltIcon
        className={classes.leftIcon}
        style={props.sort === 'descending' ? { color: colors.orange[400] } : {}}
      />
      <ArrowRightAltIcon
        className={classes.rightIcon}
        style={props.sort === 'rise' ? { color: colors.orange[400] } : {}}
      />
    </Box>
  )
}
export default SortButton
