import { useContext, useEffect, useMemo } from 'react'
import {
  useLocation,
  useRoutes,
  matchRoutes,
  useNavigate,
} from 'react-router-dom'
import { init as initApm } from '@elastic/apm-rum'
import { useMediaQuery } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import env from 'src/env'
import 'src/i18n'
import routes from 'src/routes'
import MainLayout from 'src/layouts/Main'
import { defaultTheme } from 'src/theme'
import { GlobalContext } from './context/global'
import { localStorageKey } from './context/type'
import { Userpilot } from 'userpilot'
import 'src/fonts/font.css'
import { Helmet } from 'react-helmet'
import { SnackbarProvider } from 'notistack'

const titles = {
  '/dashboard': 'Dashboard - MAS',
  '/dash/performance/revenue': 'Est. Revenue - Performance - MAS',
  '/dash/performance/impressions': 'Impressions - Performance - MAS',
  '/dash/performance/ecpm': 'eCPM - Performance - MAS',
  '/dash/user-activity/arpdau': 'ARPDAU - User Activity - MAS',
  '/dash/user-activity/impdau': 'Imp/DAU - User Activity - MAS',
  '/dash/user-activity/dau': 'Avg.DAU - User Activity - MAS',
  '/dash/gameplay-insights/installs': 'Installs - Gameplay Insights - MAS',
  '/dash/gameplay-insights/retention': 'Retention - Gameplay Insights - MAS',
  '/dash/gameplay-insights/churn-rate': 'Churn Rate - Gameplay Insights - MAS',
  '/dash/gameplay-insights/stickiness': 'Stickiness - Gameplay Insights - MAS',
  '/dash/gameplay-insights/sessions': 'Sessions - Gameplay Insights - MAS',
  '/dash/gameplay-insights/session-length':
    'Session Length - Gameplay Insights - MAS',
  '/dash/engagement/rate': 'Rate - Ad Engagement - MAS',
  '/dash/engagement/deu': 'DAV - Ad Engagement - MAS',
  '/dash/engagement/imp_deu': 'Imp/DAV - Ad Engagement - MAS',
  '/dash/engagement/revenue_deu': 'Revenue/DAV - Ad Engagement - MAS',
  '/dash/ad-placement/ratio_revenue': 'Revenue - Ad Placements - MAS',
  '/dash/ad-placement/ratio_impressions': 'Impressions - Ad Placements - MAS',
  '/dash/ad-placement/dau': 'DAU - Ad Placements - MAS',
  '/dash/ad-placement/placement_imp_dau': 'Imp/DAU - Ad Placements - MAS',
  '/dash/ad-placement/placement_arpdau': 'ARPDAU - Ad Placements - MAS',
  '/dash/ad-placement/dav': 'DAV - Ad Placements - MAS',
  '/dash/ad-placement/placement_arpdav': 'ARPDAV - Ad Placements - MAS',
  '/dash/ad-placement/ad_viewer_rate': 'Ad Viewer Rate - Ad Placements - MAS',
  '/dash/games/add': 'Add New Game - MAS',
  '/dash/games': 'Games - MAS',
  '/dash/testing': 'Testing Devices - MAS',
  '/dash/finance': 'Finance - MAS',
  '/dash/finance/edit': 'Edit Finance - MAS',
  '/dash/finance/create': 'Create Finance - MAS',
  '/dash/users': 'User Management - MAS',
  '/dash/ad-management': 'Action Items - MAS',
  '/dash/notifications': 'Notifications - MAS',
  '/dash/settings': 'Settings - MAS',
  '/dash/manage-notifications': 'Manage Notifications - MAS',
  '/login': 'Login - MAS',
  '/register': 'Sign Up - MAS',
  '/password/forgot': 'Forgot Password - MAS',
  '/password/reset': 'Password Reset - MAS',
  '/email-verification': 'Verification Email - MAS',
  '/two-fa': 'Two-Factor Authentication - MAS',
}
function App(): JSX.Element {
  const location = useLocation()
  const routing = useRoutes(routes)
  const { state } = useContext(GlobalContext)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const navigate = useNavigate()

  // memos
  const theme = useMemo(() => {
    const themeKey = Number(
      state.userInfo?.theme ?? localStorage.getItem(localStorageKey.THEME)
    )
    let isDark = false

    if (themeKey === 2 || (themeKey === 0 && prefersDarkMode)) isDark = true

    return createTheme(defaultTheme(isDark))
  }, [state.userInfo, prefersDarkMode])
  const masTitle = useMemo(() => {
    const temp = titles[location.pathname]
    if (temp) {
      return temp
    } else {
      if (location.pathname.split('/')[3] === 'manage') {
        return 'Game Management - MAS'
      }
      if (location.pathname.split('/')[3] === 'performance') {
        return 'App Analysis - MAS'
      }
      return 'MAS'
    }
  }, [location.pathname])

  // 禁止访问无权限页面，重定向
  useEffect(() => {
    const routesTemp: any = routes
    const match: any = matchRoutes(routesTemp, location)
    const key = match[match?.length - 1]?.route?.meta?.role
    if (key && state.accountAuthority[key] === 0) {
      if (state.accountAuthority.dashboard_page === 0) {
        navigate('/dash/games')
        return
      }
      navigate('/')
    }
    // eslint-disable-next-line
  }, [location, state.accountAuthority])

  // effects
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    if (env.env == 'test') {
      Userpilot.initialize('NX-aa2f32e1')
    }
    if (env.env == 'local') return

    // apm
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.apm = initApm({
      // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
      serviceName: 'mas-portal-4',
      serverUrl: 'https://yodo1-apm.apm.ap-east-1.aws.elastic-cloud.com/',
      // Set service version (required for sourcemap feature)
      serviceVersion: env.codeVersion,
      environment: env.env,
      eventsLimit: 15,
      flushInterval: 4000,
    })
  }, [])

  useEffect(() => {
    if (
      env.intercomId &&
      localStorage.getItem(localStorageKey.TOKEN_SOURCE_ADMIN) !== 'true'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Intercom('update')
    }
  }, [location])

  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>{masTitle}</title>
        </Helmet>
        <MainLayout>{routing}</MainLayout>
      </ThemeProvider>
    </SnackbarProvider>
  )
}

export default App
