import { createContext, useReducer, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ApiResponse } from 'src/api'
import { getIssueApi } from 'src/api/game'
import {
  deleteGameApi,
  editGameApi,
  getAppDetailApi,
  switchGameAdApi,
  getRetentionApi,
  getCappingApi,
  updateCappingApi,
  getMoDetailApi,
} from 'src/api/game'
import { GlobalContext } from './global'
type Props = {
  loading: boolean
  switchLoading: boolean
  deleteGameLoading: boolean
  editGameLoading: boolean
  appDetail: any
  retention: any
  retentionLoading: boolean
  retentionStore: any
  issueList: any
  issueLoading: boolean
  issueStore: any
  cappingData: any
  cappingLoading: boolean
  saveCappingLoading: boolean
  openTipDialog: boolean
  switchTestModeLoading: boolean
  moDetailsLoading: boolean
  moDetailsData: any
}

type Action = {
  type: string
  payload?: any
}

type Reducer = (prevState: Props, action: Action) => Props

const initialState: Props = {
  loading: false,
  switchLoading: false,
  deleteGameLoading: false,
  editGameLoading: false,
  appDetail: {},
  retention: {},
  retentionLoading: false,
  retentionStore: {},
  issueList: [],
  issueLoading: false,
  issueStore: {},
  cappingData: {},
  cappingLoading: false,
  saveCappingLoading: false,
  openTipDialog: false,
  switchTestModeLoading: false,
  moDetailsLoading: false,
  moDetailsData: [],
}

const reducer: Reducer = (prevState: Props, action: Action): Props => {
  switch (action.type) {
    case 'loading':
    case 'switchLoading':
    case 'deleteGameLoading':
    case 'editGameLoading':
    case 'appDetail':
    case 'retention':
    case 'retentionLoading':
    case 'retentionStore':
    case 'issueList':
    case 'issueLoading':
    case 'issueStore':
    case 'cappingData':
    case 'cappingLoading':
    case 'saveCappingLoading':
    case 'openTipDialog':
    case 'switchTestModeLoading':
    case 'moDetailsLoading':
    case 'moDetailsData':
      return { ...prevState, [action.type]: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type editData = {
  app_key: string
  url: string | null
  ads_format: string[]
  nativeads_size?: string
  test_ads_status: any
  screen?: string
}
type Context = {
  state: Props
  dispatch: (value: Action) => void
  deleteGame: (key: string) => void
  editGame: (data: editData, editUrl?: boolean) => void
  getGameDetail: (app_key: string) => void
  switchAd: (app_key: string, is_enabled: boolean) => void
  switchTestMode: (data: any) => void
  getRetention: (params: any) => void
  getIssueList: (app_key: string) => void
  getCapping: (app_key: string) => void
  saveCapping: (data: any, app_key: string) => void
  getMODetails: (app_key: string) => void
}
export const GameManageContext = createContext<Context>({
  state: initialState,
  dispatch: (value: Action) => {},
  deleteGame: (key: string) => {},
  editGame: (data: editData, editUrl?: boolean) => {},
  getGameDetail: (app_key: string) => {},
  switchAd: (app_key: string, is_enabled: boolean) => {},
  switchTestMode: (data: any) => {},
  getRetention: (params: any) => {},
  getIssueList: (app_key: string) => {},
  getCapping: (app_key: string) => {},
  saveCapping: (data: any, app_key: string) => {},
  getMODetails: (app_key: string) => {},
})

export const GameManageContextProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
  const { errorCatch, customAlert, getNewGameList } = useContext(GlobalContext)
  const navigate = useNavigate()
  const [t] = useTranslation()

  const getGameDetail = (app_key: string) => {
    dispatch({ type: 'loading', payload: true })
    getAppDetailApi(app_key, { extra_flag: true })
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'appDetail', payload: data.data })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'loading', payload: false })
      })
  }
  const deleteGame = (key: string) => {
    if (state.deleteGameLoading) return
    dispatch({ type: 'deleteGameLoading', payload: true })
    deleteGameApi(key)
      .then(() => {
        navigate('/dash/games')
        customAlert('success', t('common.success'))
        getNewGameList()
      })
      .catch(errorCatch)
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: 'deleteGameLoading', payload: false })
        }, 500)
      })
  }
  const editGame = (data: editData, editUrl?: boolean) => {
    if (state.editGameLoading) return
    dispatch({ type: 'editGameLoading', payload: true })
    editGameApi(data)
      .then(res => {
        if (res.data.success) {
          getGameDetail(data.app_key)
          customAlert('success', t('common.success'))
          if (editUrl) {
            dispatch({
              type: 'openTipDialog',
              payload: true,
            })
          }
        } else {
          customAlert('error', t(res.data.code || 'app.unknow'))
        }
      })
      .catch(errorCatch)
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: 'editGameLoading', payload: false })
        }, 500)
      })
  }
  const switchAd = (app_key: string, is_enabled: boolean) => {
    dispatch({ type: 'switchLoading', payload: true })
    switchGameAdApi(app_key, is_enabled)
      .then(() => {
        customAlert('success', t('common.success'))
      })
      .catch(errorCatch)
      .finally(() => {
        getGameDetail(app_key)
        setTimeout(() => {
          dispatch({ type: 'switchLoading', payload: false })
        }, 500)
      })
  }
  const switchTestMode = (data: any) => {
    if (state.switchTestModeLoading) return
    dispatch({ type: 'switchTestModeLoading', payload: true })
    editGameApi(data)
      .then(res => {
        if (res.data.success) {
          getGameDetail(data.app_key)
          customAlert('success', t('common.success'))
        } else {
          customAlert('error', t(res.data.code || 'app.unknow'))
        }
      })
      .catch(errorCatch)
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: 'switchTestModeLoading', payload: false })
        }, 500)
      })
  }
  const getRetention = (params: any) => {
    if (state.retentionStore[params.app_key]) {
      dispatch({
        type: 'retention',
        payload: state.retentionStore[params.app_key],
      })
      return
    }
    dispatch({ type: 'retentionLoading', payload: true })
    getRetentionApi(params)
      .then(({ data }) => {
        dispatch({ type: 'retention', payload: data })
        dispatch({
          type: 'retentionStore',
          payload: { [params.app_key]: data, ...state.retentionStore },
        })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'retentionLoading', payload: false })
      })
  }
  const getIssueList = (app_key: string) => {
    if (state.issueStore[app_key]) {
      dispatch({ type: 'issueList', payload: state.issueStore[app_key] })
      return
    }
    dispatch({ type: 'issueLoading', payload: true })
    getIssueApi(app_key)
      .then(({ data }: ApiResponse) => {
        dispatch({ type: 'issueList', payload: data })
        dispatch({
          type: 'issueStore',
          payload: { [app_key]: data, ...state.issueStore },
        })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'issueLoading', payload: false })
      })
  }
  const getCapping = (app_key: string) => {
    dispatch({ type: 'cappingLoading', payload: true })
    getCappingApi(app_key)
      .then((res: any) => {
        dispatch({ type: 'cappingData', payload: res.data })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'cappingLoading', payload: false })
      })
  }
  const saveCapping = (app_key: string, data: any) => {
    dispatch({ type: 'saveCappingLoading', payload: true })
    const temp: any = {}
    if (data.interstitial) {
      temp.interstitial = data.interstitial
    }
    updateCappingApi(temp, app_key)
      .then((res: any) => {
        customAlert('success', t('common.success'))
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'saveCappingLoading', payload: false })
      })
  }
  const getMODetails = (app_key: string) => {
    dispatch({ type: 'moDetailsLoading', payload: true })
    getMoDetailApi(app_key)
      .then((res: any) => {
        dispatch({ type: 'moDetailsData', payload: res.data.data })
      })
      .catch(errorCatch)
      .finally(() => {
        dispatch({ type: 'moDetailsLoading', payload: false })
      })
  }

  // returns
  return (
    <GameManageContext.Provider
      value={{
        state,
        dispatch,
        deleteGame,
        editGame,
        getGameDetail,
        switchAd,
        switchTestMode,
        getRetention,
        getIssueList,
        getCapping,
        saveCapping,
        getMODetails,
      }}
    >
      {children}
    </GameManageContext.Provider>
  )
}
