const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9872 0H2.01413C1.04319 0 0.253906 0.788826 0.253906 1.75999V20.24C0.253906 21.2112 1.04319 22 2.01413 22H11.9872C12.9586 22 13.7474 21.2112 13.7474 20.24V1.75999C13.7474 0.788826 12.9586 0 11.9872 0ZM5.38727 1.60739C5.38727 1.36696 5.5809 1.17333 5.82156 1.17333H8.17974C8.42063 1.17333 8.61427 1.36696 8.61427 1.60739V1.61915C8.61427 1.85957 8.42063 2.05321 8.17974 2.05321H5.82156C5.5809 2.05321 5.38727 1.85957 5.38727 1.61915V1.60739ZM7.00065 21.3398C6.47554 21.3398 6.04747 20.9117 6.04747 20.3864C6.04747 19.861 6.47531 19.4332 7.00065 19.4332C7.526 19.4332 7.95407 19.8606 7.95407 20.3864C7.9543 20.9117 7.526 21.3398 7.00065 21.3398ZM12.2809 18.48C12.2809 18.6411 12.149 18.773 11.9874 18.773H2.01413C1.853 18.773 1.72091 18.6414 1.72091 18.48V3.51998C1.72091 3.35839 1.853 3.22653 2.01413 3.22653H11.9872C12.1488 3.22653 12.2806 3.35839 12.2806 3.51998V18.48H12.2809Z"
        fill={color}
      />
      <path
        d="M10.8127 9.97656L7.58639 10.5628L6.11961 11.4429L5.2395 10.5628L4.06641 11.7363V12.6162H10.8127V9.97656Z"
        fill={color}
      />
      <path
        d="M6.21067 10.7024L7.37616 10.0037L10.8127 9.37874V7.92188L7.58639 8.50854L6.41306 9.38865L5.2395 8.50854L4.06641 9.38865V10.9054L5.2395 9.73212L6.21067 10.7024Z"
        fill={color}
      />
      <path
        d="M10.8141 13.1977H3.48096V7.91799C3.48096 7.75593 3.34957 7.625 3.18775 7.625C3.02593 7.625 2.89453 7.75593 2.89453 7.91799V13.1977C2.89453 13.5209 3.15778 13.7844 3.48119 13.7844H10.8146C10.9762 13.7844 11.1078 13.6535 11.1078 13.4914C11.1081 13.3294 10.9757 13.1977 10.8141 13.1977Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
