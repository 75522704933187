const SVG = (props: any) => {
  const width = props.width || '25'
  const height = props.height || '20'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.87744 9.99939L11.8202 11.9421L15.7057 8.05664"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17.1282 4.41212C17.3127 4.85245 17.6645 5.20148 18.1062 5.38242L20.3177 6.28823C20.6243 6.41382 20.7711 6.76423 20.6455 7.07088L19.7397 9.28234C19.5588 9.7241 19.5607 10.2197 19.7451 10.66L20.6683 12.864C20.7963 13.1696 20.6523 13.5212 20.3467 13.6492L18.1427 14.5724C17.7023 14.7568 17.3533 15.1086 17.1724 15.5503L16.2669 17.761C16.1413 18.0677 15.7909 18.2144 15.4842 18.0889L13.2735 17.1834C12.8318 17.0024 12.3362 17.0044 11.8959 17.1888L9.69142 18.1121C9.38578 18.2402 9.03423 18.0962 8.90621 17.7905L7.98287 15.5861C7.79845 15.1457 7.44665 14.7967 7.00488 14.6158L4.79407 13.7102C4.48742 13.5846 4.34065 13.2342 4.46625 12.9276L5.37179 10.7168C5.55274 10.275 5.55078 9.77943 5.36635 9.3391L4.44328 7.13527C4.31526 6.82963 4.45926 6.47808 4.7649 6.35006L6.96873 5.42699C7.40906 5.24256 7.75808 4.89076 7.93903 4.449L8.84489 2.2374C8.97049 1.93076 9.32089 1.78399 9.62753 1.90959L11.8391 2.81545C12.2809 2.9964 12.7764 2.99444 13.2168 2.81001L15.4201 1.88713C15.7258 1.75911 16.0773 1.90311 16.2054 2.20875L17.1282 4.41212Z"
        stroke="white"
        strokeWidth="1.2"
      />
    </svg>
  )
}

export default SVG
