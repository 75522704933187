import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Grid, Link, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { PNG, SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      root: {
        height: '100%',
        backgroundColor: theme.palette.background.default,
      },
      bg: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        height: '100%',
        minHeight: '600px',
        width: '30%',
        overflow: 'hidden',
        zIndex: 1,
      },
      bgImg: {
        position: 'absolute',
        '&:first-child': {
          transform: 'translateY(-210px)',
          left: '90px',
          zIndex: -3,
        },
        '&:nth-child(2)': {
          zIndex: -2,
        },
        '&:last-child': {
          transform: 'translateY(210px)',
          left: '90px',
          zIndex: -1,
        },
      },
      container: {
        maxWidth: '1350px',
      },
      content: {
        height: '70vh',
        minHeight: 300,
        padding: '0 20px',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      dot: {
        '&:before': {
          content: "' '",
          backgroundColor: '#dedede',
          borderRadius: '50%',

          display: 'inline-block',
          width: '3px',
          height: '3px',
          margin: '0 10px 2px',
        },
      },
      backIcon: {
        fontSize: '16px !important',
        border: '1px solid #2D4D5F',
        borderRadius: '50%',
        verticalAlign: 'text-bottom',
        marginRight: '7px',
      },
      backBox: {
        cursor: 'pointer',
        color: theme.palette.text.secondary,
        fontSize: '16px',
        fontWeight: 'bold',
        height: '20px',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: '40px 0 0 25px',
      },
    }
  })

const Layout = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const navigate = useNavigate()

  const [t] = useTranslation()

  const backDashboard = () => {
    navigate('/')
  }

  // returns
  return (
    <Box className={classes.root} display="flex" justifyContent="center">
      <Grid container className={classes.container}>
        <Grid item md={2} sm={1}>
          <Box className={classes.backBox} onClick={backDashboard}>
            <ArrowBackIcon className={classes.backIcon} />
            Back to Dashboard
          </Box>
        </Grid>
        <Grid item md={6} sm={7} xs={12}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignSelf="flex-end"
          >
            <Box mb={7} display="flex" justifyContent="center">
              <SVGIcon.logoSVG
                width="215px"
                height="94px"
                color={theme.palette.mode === 'light' ? '' : '#FFF'}
              />
            </Box>
            <Box className={classes.content}>
              <Outlet />
            </Box>
            <Box mt={3} display="flex" justifyContent="center">
              <Link
                onClick={() => {
                  window.open(
                    'https://policy.yodo1.com/mas/en/terms-of-service.html'
                  )
                }}
                variant="subtitle2"
              >
                {t('links.terms_conditions')}
              </Link>
            </Box>
          </Box>
        </Grid>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid item sm={4} />
        </Box>
      </Grid>

      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Box className={classes.bg} display="flex" alignItems="center">
          <img className={classes.bgImg} src={PNG.imgBgLight} alt="bg" />
          <img className={classes.bgImg} src={PNG.imgBg} alt="bg" />
          <img className={classes.bgImg} src={PNG.imgBg} alt="bg" />
        </Box>
      </Box>
    </Box>
  )
}

export default Layout
