const SVG = (props: any) => {
  const width = props.width || '24'
  const height = props.height || '20'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8831 0.413086C16.4135 0.413086 16.9222 0.6238 17.2973 0.998874L21.3732 5.07472C21.7482 5.44979 21.9589 5.9585 21.9589 6.48893V18.1433C21.9617 18.3332 21.9252 18.5216 21.8516 18.6967C21.778 18.8717 21.6689 19.0296 21.5313 19.1605C21.4002 19.2945 21.2438 19.4014 21.0712 19.4748C20.8987 19.5482 20.7133 19.5867 20.5257 19.5881H8.05445C7.86691 19.5867 7.68151 19.5482 7.50893 19.4748C7.33635 19.4014 7.18001 19.2945 7.04889 19.1605C6.91125 19.0296 6.8022 18.8717 6.7286 18.6967C6.65499 18.5216 6.61845 18.3332 6.62124 18.1433V14.3672H8.07757V18.1433H20.5257L20.5489 6.30776L18.3091 4.01672L16.018 1.8463H8.07757V5.41504H6.62124V1.8463C6.6272 1.46805 6.7801 1.10695 7.0476 0.839454C7.3151 0.571955 7.67619 0.419044 8.05445 0.413086H15.8831Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.04102 5.5C2.48873 5.5 2.04102 5.94772 2.04102 6.5V13.5C2.04102 14.0523 2.48873 14.5 3.04102 14.5H17.1392C17.6914 14.5 18.1392 14.0523 18.1392 13.5V6.5C18.1392 5.94772 17.6914 5.5 17.1392 5.5H3.04102ZM3.84595 8.7729V7.81836H7.63856V8.7729H6.32606V12.182H5.15845V8.7729H3.84595ZM9.35429 7.81836L10.0702 9.08825H10.1043L10.8287 7.81836H12.1497L10.8458 10.0002L12.2009 12.182H10.8458L10.1043 10.8865H10.0702L9.32872 12.182H7.98213L9.3202 10.0002L8.02475 7.81836H9.35429ZM12.5413 7.81836V8.7729H13.8538V12.182H15.0214V8.7729H16.3339V7.81836H12.5413Z"
        fill="white"
      />
    </svg>
  )
}

export default SVG
