import { useContext } from 'react'
import { Box, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StickinessContext } from 'src/context/gamePlayInsights/stickiness'
import { GlobalContext } from 'src/context/global'
import { BorderStyleSelectRow } from 'src/components'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      topFilter: {
        display: 'flex',
        padding: '20px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${
          theme.palette.mode === 'light' ? '#E5E8EB' : '#484644'
        }`,
        top: 68,
        zIndex: 10,
      },
      topFilterOption: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '26px',
      },
      button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        width: 80,
        height: '40px',
        color: '#FFF',
        borderRadius: '6px',
        fontWeight: 600,
        fontSize: '14px',
        cursor: 'pointer',
        background: theme.palette.mode === 'light' ? '#1D3353' : '#ff7a08',
      },
    }
  })

const TopFilterView = (props: any): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const { state, dispatch, getStickiness } = useContext(StickinessContext)
  const { state: globalState } = useContext(GlobalContext)

  // returns
  return (
    <Box
      className={classes.topFilter}
      sx={!globalState.isMobile ? { top: 68, position: 'sticky' } : { top: 0 }}
    >
      <Box className={classes.topFilterOption}>
        <BorderStyleSelectRow
          label="App"
          valueKey="app_key"
          labelKey="name"
          placeholder="App"
          value={state.gameSelect}
          showSearch={true}
          onChange={(row: any) => {
            dispatch({
              type: 'gameSelect',
              payload: row,
            })
          }}
          options={globalState.gameOption}
          onTriggerFilter={() => {
            getStickiness()
          }}
        />
      </Box>
      <Box display="flex" sx={{ minWidth: '80px', position: 'relative' }}>
        <Box
          className={classes.button}
          id="overview-apply"
          onClick={() => {
            getStickiness()
          }}
        >
          Apply
        </Box>
      </Box>
    </Box>
  )
}

export default TopFilterView
