const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3349 4.58268C18.3349 3.57016 17.5141 2.74935 16.5015 2.74935H12.8349C12.8349 1.73683 12.0141 0.916016 11.0015 0.916016C9.98902 0.916016 9.16821 1.73683 9.16821 2.74935H5.50155C4.48902 2.74935 3.66821 3.57016 3.66821 4.58268V19.2493C3.66821 20.2619 4.48902 21.0827 5.50155 21.0827H16.5015C17.5141 21.0827 18.3349 20.2619 18.3349 19.2493V4.58268ZM16.4999 19.2493H5.49992V4.58267H7.33325V5.95767C7.33325 6.2108 7.53845 6.416 7.79159 6.416H14.2083C14.4614 6.416 14.6666 6.2108 14.6666 5.95767V4.58267H16.4999V19.2493ZM8.71151 10.0827H7.79484C7.54171 10.0827 7.33651 9.87749 7.33651 9.62436V8.7077C7.33651 8.45456 7.54171 8.24936 7.79484 8.24936H8.71151C8.96464 8.24936 9.16984 8.45456 9.16984 8.7077V9.62436C9.16984 9.87749 8.96464 10.0827 8.71151 10.0827ZM7.79484 13.7493H8.71151C8.96464 13.7493 9.16984 13.5441 9.16984 13.291V12.3743C9.16984 12.1212 8.96464 11.916 8.71151 11.916H7.79484C7.54171 11.916 7.33651 12.1212 7.33651 12.3743V13.291C7.33651 13.5441 7.54171 13.7493 7.79484 13.7493ZM8.71151 17.416H7.79484C7.54171 17.416 7.33651 17.2108 7.33651 16.9577V16.041C7.33651 15.7879 7.54171 15.5827 7.79484 15.5827H8.71151C8.96464 15.5827 9.16984 15.7879 9.16984 16.041V16.9577C9.16984 17.2108 8.96464 17.416 8.71151 17.416Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
