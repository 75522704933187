const SVG = (props: any) => {
  const width = props.width || '12'
  const height = props.height || '12'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4949 6.69582L11.0535 6.14138L6.24934 12.1704L6.24934 0.33854L5.62441 0.33854L5.62441 12.1715L0.821121 6.14139L0.378878 6.69582L5.93673 13.6719L11.4949 6.69582Z"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SVG
