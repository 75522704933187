import {
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { GlobalStoreContext } from 'src/context/globalStore'
import { GameContext } from 'src/context/games'
import MonetizingGameStep1View from './monetizingGameStep1'
import MonetizingGameStep3View from './monetizingGameStep3'
import MetricsFormView from '../metricsForm'

/**
 * @function onSubmit 确认回调
 * @function onClose 关闭区域点击回调
 */
interface RatingDialogProps {
  app_key: string
  gameName: string
  getNewData?: any
  onSubmit: () => void
  onClose: () => void
}
const MonetizingGame = (props: RatingDialogProps, ref: any): JSX.Element => {
  const { state, updateMonetization, recordMonetization } =
    useContext(GlobalStoreContext)
  const { updateListForOne } = useContext(GameContext)
  const [openStep1Model, setOpenStep1Model] = useState(false)
  const [openStep3Model, setOpenStep3Model] = useState(false)
  const MetricsFormeDialog = useRef()

  const openDialog = (app_key?: boolean) => {
    const key: any = app_key || props.app_key
    recordMonetization(key)
    setOpenStep1Model(true)
  }
  useImperativeHandle(ref, () => ({
    openDialog,
  }))
  const step1Submit = () => {
    updateMonetization(
      props.app_key,
      {
        data: { is_first_monetization: true },
        getNewData: props.getNewData,
        updateListForOne: updateListForOne,
      },
      () => {
        setOpenStep1Model(false)
        props.onClose()
      }
    )
  }
  const submit = (data: any) => {
    updateMonetization(
      props.app_key,
      {
        data: data,
        getNewData: props.getNewData,
        updateListForOne: updateListForOne,
      },
      () => {
        setOpenStep3Model(false)
        const dialog: any = MetricsFormeDialog.current
        dialog.openDialog(props.app_key)
      }
    )
  }
  // returns
  return (
    <>
      <MonetizingGameStep1View
        openModel={openStep1Model}
        gameName={props.gameName}
        onClickClose={() => {
          setOpenStep1Model(false)
          props.onClose()
        }}
        onClickNo={step1Submit}
        onClickYes={() => {
          setOpenStep3Model(true)
          setOpenStep1Model(false)
        }}
        loading={state.monetizationLoading}
      />
      <MonetizingGameStep3View
        openModel={openStep3Model}
        app_key={props.app_key}
        onClickClose={() => {
          setOpenStep3Model(false)
          props.onClose()
        }}
        onClickSubmit={submit}
        loading={state.monetizationLoading}
      />
      <MetricsFormView
        ref={MetricsFormeDialog}
        app_key={props.app_key}
        onClose={props.getNewData ? () => {} : props.onClose}
        getNewData={props.getNewData}
      />
    </>
  )
}

export default forwardRef(MonetizingGame)
