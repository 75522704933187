import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import {
  Button,
  Box,
  Link,
  Typography,
  useTheme,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { AuthContext } from 'src/context/auth'
import {
  LoadingComponent,
  AuthPromptDialog,
  AuthPageInput,
} from 'src/components'
import { Icons } from 'src/images'

interface FormValues {
  email: string
  password: string
}

const getUseStyles = () =>
  makeStyles(() => {
    return {
      container: {
        boxSizing: 'border-box',
        width: '100%',
      },
      signUp: {
        fontWeight: 600,
        textDecoration: 'none !important',
      },
      desc: {
        fontSize: '14px',
        marginInlineEnd: '5px !important',
        color: '#7487AA',
        fontWeight: 600,
      },
      title: {
        lineHeight: '34px',
        textAlign: 'center',
        color: '#1D3353',
        fontWeight: 600,
        fontSize: '18px',
        marginBottom: '20px',
      },
    }
  })

const View = (): JSX.Element => {
  const theme: Theme = useTheme()
  const useStyles = getUseStyles()
  const classes = useStyles()
  const [t] = useTranslation()
  const { state, setEmailVerifiedModel, login } = useContext(AuthContext)
  const navigate = useNavigate()
  const FormSchema = yup.object().shape({
    email: yup.string().email().required(t('auth.login.emailErrMsg')),
    password: yup.string().min(6, t('auth.login.passwordMinMsg')).max(20),
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
  })
  const [email, setEmail] = useState('')
  const dialogConfirm = () => {
    setEmailVerifiedModel(false)
    navigate('/email-verification?email=' + email)
  }

  // handlers
  const onSubmit = (data: FormValues) => {
    setEmail(data.email)
    login(data)
  }

  // returns
  if (state.loading) {
    return (
      <Box display="flex" justifyContent="center">
        <LoadingComponent />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <form
        name="mas_login"
        onSubmit={handleSubmit(onSubmit)}
        className="mas-login"
      >
        <Box className={classes.title}>
          Sign In To
          <img
            src={Icons.masLogoIcon}
            alt="MAS"
            style={{
              width: '67px',
              verticalAlign: 'middle',
              marginLeft: '12px',
              transform: 'translateY(-2px)',
            }}
          />
        </Box>
        <Box
          style={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '10px',
            background:
              'linear-gradient(82.32deg, #005897 -3.09%, #00243D 128.6%)',
            color: '#FFF',
            padding: '10px 12px',
            fontSize: '14px',
            fontWeight: 700,
            margin: '24px',
          }}
        >
          <Box marginBottom="4px">
            Reply to all your player reviews with AI.
          </Box>
          <Box
            component="span"
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              window.open('https://hubs.ly/Q02t_Kmf0')
            }}
          >
            Try FanCraft for free &raquo;
          </Box>
        </Box>
        {/* email */}
        <AuthPageInput
          label="Your email"
          name="email"
          control={control}
          defaultValue=""
          placeholder={t('auth.login.email')}
          type="email"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        {/* password */}
        <AuthPageInput
          label="Your password"
          name="password"
          control={control}
          defaultValue=""
          placeholder={t('auth.login.password')}
          type="password"
          variant="outlined"
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <Box paddingTop="6px" textAlign="right">
          <Link
            onClick={() => {
              navigate('/password/forgot')
            }}
            underline="none"
            sx={{ fontWeight: 600 }}
          >
            {t('auth.login.forgot_password')}
          </Link>
        </Box>
        <Box margin="18px 0 14px" display="flex" alignItems="center">
          <Box flex={1}>
            <Button fullWidth variant="contained" color="primary" type="submit">
              {t('auth.login.sign_in')}
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            color="textSecondary"
            className={classes.desc}
            variant="body2"
          >
            {t('auth.login.dont_have_account')}
          </Typography>
          <Link
            onClick={() => {
              navigate('/register')
            }}
            className={classes.signUp}
            underline="always"
          >
            {t('auth.login.signup')}
          </Link>
        </Box>
      </form>

      <AuthPromptDialog
        openModel={state.email_not_verified}
        title={t('auth.emailVerify.verifyMsgTitle')}
        content={t('auth.emailVerify.verifyMsgBody')}
        contentDesc={t('auth.emailVerify.verifyMsgBody2')}
        onConfirm={dialogConfirm}
      />
    </Box>
  )
}

export default View
