import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)

/**
 * metric数据格式化(小数点按type保留位数)
 * @param value
 * @param type 数据metric类型
 * @param RefillZero 小数结尾的0是否保留
 * @returns
 */
const tabMetricFormat = (
  value: any,
  type:
    | 'revenue'
    | 'dau'
    | 'dav'
    | 'arpdau'
    | 'ecpm'
    | 'impdau'
    | 'arpdav'
    | 'percent',
  RefillZero?: boolean
): number => {
  let fixed = 4
  if (type === 'dau' || type === 'dav') {
    // 整数
    fixed = 0
  } else if (type === 'revenue') {
    // 2位小数
    fixed = 2
  }
  //  else {
  // 百分比应但展示2位小数，但是需要*100所以保留4位小数
  // 4位小数 ARPDAU/eCPM/IMPDAU/ARPDAV
  // fixed = 4
  // }
  if (RefillZero) {
    return numFixedRefillZero(value, fixed) as number
  }

  return numFixed(value, fixed)
}
/**
 * 千分位格式化(小数点保留两位)
 * @param value
 * @param noFixed?
 * @returns
 */
const toThousands = (value: any, noFixed?: boolean): string => {
  let num = (value || 0).toString()
  const decimalIndex = num.indexOf('.')
  let decimalValue = num.slice(decimalIndex, decimalIndex + 3)
  if (noFixed) {
    decimalValue = num.slice(decimalIndex)
    if (decimalValue.length > 5) {
      decimalValue = num.slice(decimalIndex, decimalIndex + 5)
    }
  }

  if (decimalIndex !== -1) {
    num = num.slice(0, decimalIndex)
  }

  let result = ''
  while (num.length > 3) {
    result = ',' + num.slice(-3) + result
    num = num.slice(0, num.length - 3)
  }
  if (num) result = num + result
  return decimalIndex !== -1 ? result + decimalValue : result
}

/**
 * 数字格式化成K,M等格式
 * @param num 格式化数值
 * @param digits 保留小数位
 * @returns string
 */

function numFormatter(num: number): string {
  if (typeof num !== 'number') return '0'

  const digits = num < 1e3 ? 4 : 1
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) break
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

/**
 * 时间格式化成Mins,s等格式
 * @param num 格式化数值
 * @returns string
 */

function accessTimeFormatter(num: number): string {
  if (typeof num !== 'number') return '- Mins'
  if (num < 60) {
    return `${num} s`
  }
  if (num === 60) return '1 min'

  if (num < 3600) {
    const mins = Math.floor(num / 60)
    const s = num % 60
    return `${mins} min ${s} s`
  }
  if (num === 3600) return '1 h'

  const h = num / 3600
  const min = Math.floor((num % 3600) / 60)
  return `${h} h ${min} mins`
}

/**
 * 根据key路径获取对象对应的值
 * @param sourceObject 获取数据对象
 * @param key 例如：obj.info.name / arr[0].name
 * @param allowNoAttr 允许对象上不存在key
 * @returns
 */
const getObjectValue = (
  sourceObject: Record<string, unknown> | any[],
  key?: string,
  allowNoAttr = false
): any => {
  if (!key?.length || !Object.keys(sourceObject).length) return sourceObject

  const keyList = key.replace(/\[(\d)]/g, (text, $1) => '.' + $1).split('.')

  const result = keyList.reduce((props, current) => {
    if (!props) return undefined
    if (!Object.prototype.hasOwnProperty.call(props, current) && !allowNoAttr) {
      throw new Error(
        `Can not set ${key}'s ${current} property in current object, Because there is no ${current} property on the object`
      )
    }
    return props[current]
  }, sourceObject)

  return result
}

dayjs.extend(utc)
dayjs.extend(timezone)
/**
 * UTC0 转化为当地时区时间
 * @param utc0Time
 * @returns
 */
function transformUTC0ToLocalTime(
  utc0Time: string | number | Date | any,
  format?: string
): string {
  const localTimezone = dayjs.tz.guess()
  const formatDate = format || 'YYYY-MM-DD HH:mm:ss'
  return dayjs.utc(utc0Time).tz(localTimezone).format(formatDate)
}
/**
 * 获取从某日起几天前的日期
 * @param day
 * @returns
 */
function getDayBeforeDate(dayNum: number, day: string): string {
  const curDay = new Date(day)
  const oldDay = dayjs(curDay).add(-1 * dayNum, 'day')
  return transformUTC0ToLocalTime(oldDay, 'YYYY-MM-DD')
}

/**
 * 获取几天前的日期
 * @param day
 * @returns
 */
function getDay(day: number): string {
  const today = new Date()
  const oldDay = dayjs(today).add(-1 * day, 'day')
  return transformUTC0ToLocalTime(oldDay, 'YYYY-MM-DD')
}
/**
 * 获取前几天日期的集合
 * @param num
 * @returns
 */
function getDays(num: number): string[] {
  const data: string[] = []
  for (let i = 0; i < num; i++) {
    data.unshift(getDay(i)) // -i 代表之前  i代表将来
  }
  return data
}
/**
 * 判断device_id是否合法
 * @param {platform: "iOS" | "android", device_id: "****"}
 * @returns true|false
 */
const regExp = {
  IDFA: /^([0-9a-zA-Z]{8})(([/\s-][0-9a-zA-Z]{4}){3})([/\s-][0-9a-zA-Z]{12})$/,
  GAID: /^([0-9a-zA-Z]{8})(([/\s-][0-9a-zA-Z]{4}){3})([/\s-][0-9a-zA-Z]{12})$/,
}
function validateDeviceId(row: { platform: string; device_id: string }) {
  if (row.platform === 'iOs') {
    return regExp.IDFA.test(row.device_id)
  } else {
    return regExp.GAID.test(row.device_id)
  }
}

/**
 * 获取当前url查询参数
 * @returns
 */
const getQueryParams = (url: string): Record<string, unknown> => {
  const index = url.indexOf('?')
  if (index === -1) return {}

  const searchStr = url.slice(index + 1)
  const result = searchStr
    .split('&')
    .reduce((prev: Record<string, unknown>, current: string) => {
      const [key, val] = current.split('=')

      return {
        ...prev,
        [key]: val,
      }
    }, {})

  return result
}

/**
 * 获取最近日期区间
 * @param range 天数
 * @returns
 */
const getLastTimeRange = (range: number): [string, string] => {
  const startDate = dayjs(new Date())
    .add(-1 * range, 'day')
    .format('YYYY-MM-DD')
  const endDate = dayjs(new Date()).add(-1, 'day').format('YYYY-MM-DD')
  return [startDate, endDate]
}

/**
 * 下载csv文件 The download function takes a CSV string, the filename and mimeType as parameters
 * @param content csv文件内容 fileName 格式 mimeType 编码类型
 * @returns
 */
const downloadCsv = (content: string, fileName: string, mimeType: string) => {
  const a = document.createElement('a')
  mimeType = mimeType || 'application/octet-stream'
  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName
    )
  } else if (URL && 'download' in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    )
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else {
    location.href =
      'data:application/octet-stream,' + encodeURIComponent(content) // only this mime type is supported
  }
}

/**
 * 防抖，防止多次触发
 * @param second 触发时间  function 触发的方法
 * @returns
 */
let timer: any = null
const debounceFunction = (func: any, second?: number) => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
  timer = setTimeout(() => {
    func && func()
    clearTimeout(timer)
    timer = null
  }, second || 3000)
}
/**
 * md5加密密码
 * @param 密码 string
 * @returns  加密后的密码 string
 */

// eslint-disable-next-line @typescript-eslint/no-var-requires
const crypto: any = require('crypto')
const md5Password = (password: string): string => {
  return crypto
    .createHash('md5')
    .update(password + 'yodo1')
    .digest('hex')
}
/**
 * 对比两个版本号
 * @param curV 当前版本  reqV 对比版本
 * @returns 当前版本大于对比版本则为true否则false
 */
const compare = (curV: string, reqV: string) => {
  const arrV1 = curV.split('.')
  const arrV2 = reqV.split('.')
  const minLength = Math.min(arrV1.length, arrV2.length)
  let position = 0
  let diff = 0
  while (position < minLength) {
    diff = parseInt(arrV1[position]) - parseInt(arrV2[position])
    if (diff !== 0) {
      break
    }
    position++
  }
  return diff > 0
}
/**
 * 对一个版本号数组
 * @param 数组
 * @returns 排序后的数组
 * @备注 ：4.8.10  4.8.10-beta.1  4.8.10-alpha.1 版本号为此三种类型，排序按这个顺序由前向后排
 */
const versionSort = (versions: any) => {
  const newVersions: any = [...versions]
  return newVersions.sort((a: any, b: any) => {
    // 将4.8.10-beta.1转换为4.8.10.beta.1格式并分割为数组
    const aDigits = a.split('-').join('.').split('.')
    const bDigits = b.split('-').join('.').split('.')

    // 比较主要版本号
    if (parseInt(aDigits[0]) > parseInt(bDigits[0])) {
      return -1
    } else if (parseInt(aDigits[0]) < parseInt(bDigits[0])) {
      return 1
    }

    // 如果主要版本号相同，比较次要版本号
    if (parseInt(aDigits[1]) > parseInt(bDigits[1])) {
      return -1
    } else if (parseInt(aDigits[1]) < parseInt(bDigits[1])) {
      return 1
    }

    // 如果主要版本号和次要版本号相同，比较修订版本号
    if (parseInt(aDigits[2]) > parseInt(bDigits[2])) {
      return -1
    } else if (parseInt(aDigits[2]) < parseInt(bDigits[2])) {
      return 1
    }

    // 如果修订版本号也相同，则比较先行版本号
    if (aDigits.length > 3 && bDigits.length > 3) {
      if (aDigits[3].startsWith('alpha') && bDigits[3].startsWith('beta')) {
        return 1
      } else if (
        aDigits[3].startsWith('beta') &&
        bDigits[3].startsWith('alpha')
      ) {
        return -1
      } else if (
        aDigits[3].startsWith('alpha') &&
        bDigits[3].startsWith('alpha')
      ) {
        // 版本号的先行版本都为alpha，检查后缀数字
        if (parseInt(aDigits[4]) > parseInt(bDigits[4])) {
          return -1
        } else if (parseInt(aDigits[4]) < parseInt(bDigits[4])) {
          return 1
        }
      } else if (
        aDigits[3].startsWith('beta') &&
        bDigits[3].startsWith('beta')
      ) {
        // 版本号的先行版本都为beta，检查后缀数字
        if (parseInt(aDigits[4]) > parseInt(bDigits[4])) {
          return -1
        } else if (parseInt(aDigits[4]) < parseInt(bDigits[4])) {
          return 1
        }
      }
    } else if (aDigits.length > 3 && bDigits.length == 3) {
      // a版本号有先行版本号，b版本号没有，把a放在b前面
      return 1
    } else if (aDigits.length == 3 && bDigits.length > 3) {
      // b版本号有先行版本号，a版本号没有，把b放在a前面
      return -1
    }

    // 所有部分相等，版本号相等
    return 0
  })
}

const sortFunc = (property: string, sort: string) => {
  //property:根据什么属性排序
  return function (a: any, b: any) {
    const value1 = a[property]
    const value2 = b[property]
    if (sort === 'rise') {
      return value1 - value2 //升序排序
    }
    return value2 - value1 //降序排序
  }
}
/**
 * 数组排序
 * @param list 原数组  property 根据哪个属性排序  sort：升序(rise)还是降序(descending)
 * @returns 排序后的新数组
 */
const sortCompare = (list: any, property: string, sort: string) => {
  return list.sort(sortFunc(property, sort))
}
/**
 * 乘法精密计算
 * @param arg1 格式化数值
 * @param arg2 格式化数值
 * @returns string
 */

function multiplication(arg1: number, arg2: number) {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch {}
  try {
    m += s2.split('.')[1].length
  } catch {}

  return (
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
    Math.pow(10, m)
  )
}

/**
 * 数字格式化成百分比
 * @param num 格式化数值
 * @returns string
 */

function toPercent(num: number) {
  return multiplication(Number(num), 100) + '%'
}

/**
 * 数字保留X位小数，并删除末尾的0
 * @param num 数值 fixed 保留长度
 * @returns string
 */
function numFixed(num: number, fixed?: number) {
  let temp = fixed
  if (!temp && temp !== 0) {
    temp = 2
  }
  // delete the 0 at the end of the number
  let value: any = num.toFixed(temp)
  if (Number(value) === parseFloat(value)) {
    value = parseFloat(String(value))
  }
  return value
}

/**
 * 数字统一保留X位小数，0则展示0
 * @param num 数值 fixed 保留长度
 * @returns string
 */
function numFixedRefillZero(num: number, fixed?: number) {
  let tempFixed = fixed
  if (!tempFixed && tempFixed !== 0) {
    tempFixed = 4
  }
  if (!num) {
    return 0
  }
  return num.toFixed(tempFixed)
}

/**
 * Random color
 * @returns string
 */
function getRandomColor() {
  const r = Math.round(Math.random() * 255),
    g = Math.round(Math.random() * 255),
    b = Math.round(Math.random() * 255)
  const color = (r << 16) | (g << 8) | b
  return '#' + color.toString(16)
}
const Yesterday = dayjs().subtract(1, 'day')
const dateObject = {
  today: {
    label: 'Today',
    numberLabel: 'Today',
    value: 'today',
    range: [dayjs(), dayjs()],
  },
  yesterday: {
    label: 'Yesterday',
    numberLabel: 'Yesterday',
    value: 'yesterday',
    range: [Yesterday.subtract(1, 'day'), Yesterday],
  },
  this_week: {
    label: 'This week',
    numberLabel: 'This week',
    value: 'this_week',
    range: [Yesterday.day(0), Yesterday],
  },
  last_week: {
    label: 'Last week',
    numberLabel: 'Last 7 days',
    value: 'last_week',
    range: [Yesterday.subtract(1, 'week').add(1, 'day'), Yesterday],
  },
  past_two_weeks: {
    label: 'Past 2 weeks',
    numberLabel: 'Past 2 weeks',
    value: 'past_two_weeks',
    range: [Yesterday.subtract(2, 'week').add(1, 'day'), Yesterday],
  },
  this_month: {
    label: 'This month',
    numberLabel: 'This month',
    value: 'this_month',
    range: [Yesterday.date(1), Yesterday],
  },
  last_month: {
    label: 'Last month',
    numberLabel: 'Last 30 days',
    value: 'last_month',
    range: [Yesterday.subtract(1, 'month').add(1, 'day'), Yesterday],
  },
  last_three_month: {
    label: 'Last 3 months',
    numberLabel: 'Last 3 months',
    value: 'last_three_month',
    range: [Yesterday.subtract(3, 'month').add(1, 'day'), Yesterday],
  },
  last_six_month: {
    label: 'Last 6 months',
    numberLabel: 'Last 6 months',
    value: 'last_six_month',
    range: [Yesterday.subtract(6, 'month').add(1, 'day'), Yesterday],
  },
  last_year: {
    label: 'Last year',
    numberLabel: 'Last year',
    value: 'last_year',
    range: [Yesterday.subtract(1, 'year').add(1, 'day'), Yesterday],
  },
  custom: {
    label: 'Custom',
    numberLabel: 'Custom',
    value: 'custom',
  },
}
/**
 * 通过时间组件的value获取具体对应时间
 * @returns array | string
 */
function getDateRangeFromValue(value: string, key?: string) {
  const temp = dateObject[value]
  if (temp) {
    return temp[key || 'range'].map((item: any) => item.format('YYYY-MM-DD'))
  }
  return dateObject.custom[key || 'range'].map((item: any) =>
    item.format('YYYY-MM-DD')
  )
}

/**
 * 格式化比率标题，传入标题前后两部分
 * @returns string
 */
function rateTitleFormat(title1: string, title2: string) {
  return `${title1}/${title2}`
}

/**
 * 随机生成uuid
 * @returns string
 */
function getUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * 获取最大值
 * @returns number
 */
function getMaxValueFromObject(oldArr: any, key: string) {
  return oldArr.reduce((a: number, b: any) => Math.max(a, b[key]), -Infinity)
}

/**
 * 格式化日期为8th Jul 2021格式
 * @returns number
 */
function getDateEnglishFormat(date: string) {
  return dayjs(date).format('Do MMM YYYY')
}

/**
 * 判断是否浏览器打开了adblock(会延时触发)
 * params：如果打开了adblock会触发传入的方法
 */
function detectAdBlock(func: any) {
  const windowTemp: any = window
  if (
    typeof windowTemp?.chrome === 'object' &&
    typeof windowTemp?.chrome?.webstore === 'object'
  ) {
    func()
    return
  }
  const ad = document.createElement('ins')
  ad.className = 'ad_block'
  ad.style.display = 'block'
  ad.style.width = '1px'
  ad.style.height = '1px'
  ad.innerHTML = '&nbsp;'
  document.body.appendChild(ad)
  const timer = window.setTimeout(function () {
    if (ad.offsetHeight === 0) {
      func()
    }
    ad.remove()
    clearTimeout(timer)
  }, 100)
}
/**
 * 对传入数据转换为echart的option
 * params：days-X轴日期，data-Y轴data，type-line和bar的类型，mode-主题颜色，event-事件
 * @returns echart options
 */
function getEchartOptions(
  days: string[],
  data: any[],
  type: string,
  mode: string,
  event?: any
) {
  const lineColor = mode === 'dark' ? 'rgba(244, 243, 241, 0.4)' : '#E6E6E6'
  const textColor = mode === 'dark' ? '#FFFFFF' : '#1D3353'
  days = days || [1, 2, 3, 4, 5]
  data = (
    data || [
      {
        name: 'all',
        type: 'line',
        smooth: true,
        itemStyle: { color: '#FF8E00' },
        data: [0, 0, 0, 0, 0],
      },
    ]
  )?.map((item: any) => {
    if (type === 'Graph') {
      item.type = 'line'
      if (item.stack) {
        delete item.stack
      }
    } else {
      item.type = 'bar'
      item.stack = 'bar'
    }
    return item
  })
  if (data.length && event) {
    const keys = Object.keys(event)
    if (keys.length) {
      keys.forEach((item: any) => {
        if (days.includes(item)) {
          data.push({
            name: 'event',
            type: 'line',
            itemStyle: { color: '#FF8E00' },
            data: [],
            markLine: {
              silent: false,
              symbol: 'none',
              label: {
                show: false,
                ellipsis: true,
                distance: 10,
                color: 'rgb(106, 106, 106)',
                backgroundColor: 'white',
                shadowColor: 'rgba(70, 70, 70, 0.4)',
                shadowBlur: 10,
                borderWidth: 1,
                verticalAlign: 'top',
                align:
                  item === days[0]
                    ? 'left'
                    : item === days[days.length - 1]
                    ? 'right'
                    : 'center',
                lineHeight: 16,
                formatter: '{b}',
                padding: [10, 12],
                borderRadius: 5,
                rich: {
                  b: { align: 'center', fontSize: 14, lineHeight: 16 },
                },
              },
              emphasis: {
                label: {
                  show: true,
                },
              },
              data: [
                {
                  name: `{b|Click to know more}`,
                  xAxis: item,
                },
              ],
            },
          })
        }
      })
    }
  }
  const options: any = {
    tooltip: {
      trigger: 'axis',
      order: 'valueDesc',
      confine: true,
      enterable: true, // 鼠标可以移入tooltip里
      extraCssText: `max-height:100px;overflow:auto;font-size:12px;`,
      axisPointer: { z: -1 },
      position: function (point: any) {
        return [point[0], point[1]]
      },
    },
    grid: {
      left: '12px',
      right: '36px',
      bottom: '10px',
      top: '20px',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: type !== 'Graph',
      data: days,
      axisLabel: {
        textStyle: {
          color: textColor,
        },
      },
      axisLine: {
        lineStyle: {
          color: lineColor,
        },
      },
    },
    yAxis: {
      type: 'value',
      show: true, // 显示y轴坐标,
      axisLine: {
        show: true, // 显示y轴线
        lineStyle: {
          color: lineColor,
        },
      },
      axisLabel: {
        textStyle: {
          color: textColor,
        },
      },
      splitLine: {
        lineStyle: {
          color: lineColor,
        },
      },
    },
    series: data,
  }
  if (!data.length) {
    options.yAxis.min = 0
    options.yAxis.max = 1
  }
  return options
}
/**
 * 评分对应的标题
 * params：评分
 */
function getScoringTitle(scoring: number) {
  if (!scoring && scoring !== 0) {
    return ''
  }
  const temp = Number(scoring.toFixed())
  return temp >= 85
    ? 'Excellent'
    : temp >= 70
    ? 'Good'
    : temp >= 30
    ? 'Normal'
    : temp > 0
    ? 'Poor'
    : 'Very Poor'
}
function getScoringColor(scoring: number) {
  if (!scoring && scoring !== 0) {
    return ''
  }
  const temp = Number(scoring.toFixed())
  return temp >= 85
    ? '#27AE60'
    : temp >= 70
    ? '#65cd91'
    : temp >= 30
    ? '#FAAE31'
    : '#B93D34'
}
/**
 * 格式化评分
 * params：评分, showFullScore: 是否展示为 * / 100
 */
function getScoringNum(scoring: number, showFullScore?: boolean) {
  if (!scoring && scoring !== 0) {
    return '-'
  }
  if (showFullScore) {
    return `${numFixed(scoring || 0).toFixed()} / 100`
  }
  return numFixed(scoring || 0).toFixed()
}

export {
  tabMetricFormat,
  toThousands,
  numFormatter,
  getObjectValue,
  transformUTC0ToLocalTime,
  getDay,
  getDays,
  validateDeviceId,
  getQueryParams,
  getLastTimeRange,
  downloadCsv,
  debounceFunction,
  md5Password,
  compare,
  versionSort,
  sortCompare,
  accessTimeFormatter,
  multiplication,
  toPercent,
  numFixed,
  numFixedRefillZero,
  getDayBeforeDate,
  getRandomColor,
  getDateRangeFromValue,
  rateTitleFormat,
  getUuid,
  getMaxValueFromObject,
  getDateEnglishFormat,
  detectAdBlock,
  getEchartOptions,
  getScoringTitle,
  getScoringColor,
  getScoringNum,
}
