import { useState, useMemo } from 'react'
import { Box, Popover, InputBase, useTheme, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { SVGIcon } from 'src/images'

const getUseStyles = (theme: Theme) =>
  makeStyles(() => {
    return {
      popover: {
        '& .MuiPaper-root': {
          minWidth: '200px',
          maxWidth: '500px',
          maxHeight: '70vh',
          marginTop: '14px',
          borderRadius: '6px',
          padding: '0',
          background: theme.palette.custom.dialogBg,
        },
      },
      input: {
        width: '100%',
        boxSizing: 'border-box',
        height: '32px',
      },
      option: {
        width: '100%',
        display: 'flex',
        padding: '6px 14px',
        cursor: 'pointer',
        color: theme.palette.custom.inputLabel,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
          background:
            theme.palette.mode === 'light'
              ? 'rgba(224, 229, 231, 0.5)'
              : '#555D6A',
        },
      },
      checked: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        background:
          theme.palette.mode === 'light'
            ? 'rgba(224, 229, 231, 0.5)'
            : '#555D6A',
      },
      borderSelectBox: {
        position: 'relative',
        display: 'flex',
        boxSizing: 'border-box',
        height: '40px',
        lineHeight: '24px',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '8px 16px',
        border: `1px solid ${theme.palette.custom.paperBorder}`,
        borderRadius: '6px',
        justifyContent: 'space-between',
        boxShadow:
          theme.palette.mode === 'light'
            ? '0px 0px 2px rgba(145, 158, 171, 0.14), 0px 12px 24px rgba(145, 158, 171, 0.04)'
            : 'none',
        '&:hover .clear': {
          display: 'block',
        },
      },
      label: {
        position: 'absolute',
        fontSize: '12px',
        lineHeight: '12px',
        top: '-6px',
        left: '10px',
        background: theme.palette.background.paper,
        padding: '0 6px',
        color: theme.palette.text.secondary,
      },
      clear: {
        display: 'none',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '50%',
        right: '12px',
        marginTop: '-7px',
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        cursor: 'pointer',
        textAlign: 'center',
        lineHeight: '12px',
        paddingTop: '1px',
        border: `1px solid ${theme.palette.text.secondary}`,
        background: theme.palette.background.paper,
      },
      clearIcon: {
        fontSize: '12px !important',
        color: theme.palette.text.secondary,
      },
    }
  })

interface selectProps {
  width?: string
  placeholder?: string
  labelKey?: string
  valueKey?: string
  label?: string
  showSearch?: boolean
  changeClose?: boolean
  options: any[]
  value: any
  disabled?: boolean
  clearable?: boolean
  onChange: (item: any) => void
  onTriggerFilter?: () => void
}
export default function StyleSelectOne(props: selectProps) {
  const {
    width = '150px',
    placeholder = '',
    labelKey = 'label',
    valueKey = 'value',
    label = '',
    showSearch = false,
    changeClose = false,
    options,
    value,
    disabled = false,
    clearable = false,
    onChange,
    onTriggerFilter = null,
  } = props
  const theme: Theme = useTheme()
  const useStyles = getUseStyles(theme)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [defaultValue, setDefaultValue] = useState<string>('')

  const showValue = useMemo(() => {
    let temp = value[valueKey]
    const selectTemp = options.find(
      (item: any) => item[valueKey] === value[valueKey]
    )
    if (selectTemp) {
      temp = selectTemp[labelKey]
    }
    return temp
    // eslint-disable-next-line
  }, [value])
  const showOption = useMemo(() => {
    if (searchValue) {
      return options.filter((item: any) =>
        item[labelKey].toUpperCase().includes(searchValue.toUpperCase().trim())
      )
    }
    return options
    // eslint-disable-next-line
  }, [options, searchValue])

  const handleClick = (event: any) => {
    if (disabled) {
      return
    }
    setDefaultValue(value[valueKey])
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (onTriggerFilter && defaultValue !== value[valueKey]) {
      onTriggerFilter()
    }
    setAnchorEl(null)
  }
  const searchChange = (e: any) => {
    setSearchValue(e.target.value)
  }
  const toCheck = (item: any) => {
    onChange(item)
    if (changeClose) {
      setAnchorEl(null)
    }
  }
  const clear = (event: any) => {
    event.stopPropagation()
    onChange({})
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-select-popover' : undefined
  return (
    <Box width={width}>
      <Box
        className={classes.borderSelectBox}
        aria-describedby={id}
        onClick={handleClick}
        id={'select' + label}
        sx={
          disabled
            ? {
                cursor: 'not-allowed',
                opacity: 0.4,
              }
            : {}
        }
      >
        {/* label */}
        {label && <Box className={classes.label}>{label}</Box>}
        {/* placeholder & value */}
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color:
              theme.palette.mode === 'light'
                ? '#102542'
                : 'rgba(255, 255, 255, 0.6)',
          }}
        >
          {showValue ? (
            showValue
          ) : (
            <span style={{ opacity: 0.4 }}>{placeholder}</span>
          )}
        </Box>
        {/* right icon */}
        <SVGIcon.TriangleDownSVG
          color={
            theme.palette.mode === 'dark'
              ? 'rgb(255, 255, 255)'
              : 'rgba(0, 0, 0, 0.54)'
          }
          style={{
            marginTop: '9px',
            marginLeft: '2px',
            transform: anchorEl ? 'rotate(180deg)' : '',
            minWidth: '10px',
          }}
        />
        {clearable && !disabled && (
          <Box className={clsx([classes.clear, 'clear'])} onClick={clear}>
            <CloseIcon className={classes.clearIcon} />
          </Box>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
        sx={{ zIndex: 1999 }}
      >
        <Box fontSize="14px" padding="8px 0">
          {/* search input */}
          {showSearch && (
            <Box position="relative" margin="0px 8px 2px">
              <InputBase
                className={classes.input}
                placeholder="Search"
                value={searchValue}
                onChange={searchChange}
                style={{
                  background: theme.palette.custom.dialogBg,
                  border: `1px solid ${theme.palette.custom.paperBorder}`,
                  borderRadius: '6px',
                  boxShadow: 'none',
                }}
              />
              <SVGIcon.SearchIconSVG
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '8px',
                }}
              />
            </Box>
          )}
          {/* options */}
          {showOption.length ? (
            <Box>
              {showOption.map((item: any, index: number) => (
                <Box
                  className={clsx({
                    [classes.option]: true,
                    [classes.checked]: item[valueKey] === value[valueKey],
                  })}
                  key={index}
                  onClick={() => {
                    toCheck(item)
                  }}
                >
                  <Box lineHeight="24px">{item[labelKey]}</Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box textAlign="center" padding="16px">
              No Data
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  )
}
