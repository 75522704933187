import { Box, Link, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

const NotFound = (): JSX.Element => {
  const navigate = useNavigate()

  // returns
  return (
    <Box>
      <Box mb={3}>
        <Typography color="textPrimary" variant="h4">
          This Page Not Found
        </Typography>
      </Box>
      <Link
        underline="always"
        onClick={() => {
          navigate('/')
        }}
      >
        Back home
      </Link>
    </Box>
  )
}

export default NotFound
