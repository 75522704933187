const SVG = (props: any) => {
  const width = props.width || '44'
  const height = props.height || '50'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 44 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1626 31.1661V39.2203C34.1626 39.5232 34.0423 39.8138 33.8283 40.028C33.6142 40.2422 33.3238 40.3625 33.0211 40.3625H20.0604L14.8194 36.1957L13.1917 38.2455L18.2571 42.2728V48.8094C18.257 49.0238 18.1967 49.2338 18.0829 49.4155C17.9691 49.5972 17.8065 49.7431 17.6137 49.8366C17.4209 49.9302 17.2057 49.9676 16.9927 49.9445C16.7797 49.9214 16.5775 49.8388 16.4092 49.7061V49.7192L0.41866 36.6709C0.287777 36.5631 0.182379 36.4277 0.109995 36.2744C0.0376113 36.121 2.76165e-05 35.9535 0 35.784V22.5622C0 22.2593 0.120269 21.9688 0.334337 21.7546C0.548406 21.5404 0.838736 21.4201 1.14147 21.4201H7.03893C7.07925 21.8112 7.18061 22.1944 7.34002 22.5555C7.55118 23.0339 7.85958 23.463 8.24557 23.8156L15.2187 30.1842C15.9106 30.816 16.8135 31.1662 17.7502 31.1661H34.1626ZM34.0976 28.5479H17.7502C17.466 28.5472 17.1922 28.4411 16.9816 28.2501L10.0084 21.8814C9.89052 21.7747 9.79641 21.6444 9.73216 21.4989C9.72071 21.473 9.71025 21.4467 9.70081 21.4201H26.0479C26.3324 21.4191 26.6069 21.5254 26.8165 21.7178L33.7897 28.0833C33.9072 28.1903 34.001 28.3207 34.0652 28.4661C34.0771 28.493 34.0879 28.5203 34.0976 28.5479Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.689 1.87791e-05C26.9433 6.90578e-05 27.1901 0.0866192 27.3889 0.245472L43.3794 13.2971C43.5107 13.4046 43.6166 13.5399 43.6896 13.6932C43.7625 13.8466 43.8007 14.0141 43.8013 14.1839V27.4057C43.8005 27.7089 43.6795 27.9994 43.4649 28.2135C43.2503 28.4277 42.9596 28.5479 42.6566 28.5479H17.7501C17.4659 28.5472 17.192 28.4412 16.9815 28.2501L10.0083 21.8814C9.8904 21.7747 9.79629 21.6444 9.73205 21.4989C9.6678 21.3534 9.63491 21.1961 9.63551 21.037V10.7476C9.63551 10.4447 9.75574 10.1542 9.9698 9.93997C10.1839 9.72577 10.4742 9.60542 10.777 9.60542H23.6488L30.2236 14.5395L31.7936 12.445L25.541 7.75268V1.14547C25.5405 0.994648 25.5699 0.84524 25.6275 0.705857C25.6851 0.566475 25.7697 0.439897 25.8764 0.333402C25.9832 0.226908 26.1099 0.14261 26.2494 0.0853922C26.3888 0.0281748 26.5382 -0.00084615 26.689 1.87791e-05Z"
        fill="url(#paint0_linear_2356_83)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2356_83"
          x1="11.8154"
          y1="20.2345"
          x2="45.1074"
          y2="14.2794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1731D" />
          <stop offset="0.89" stopColor="#FFA533" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SVG
