const SVG = (props: any) => {
  const width = props.width || '14'
  const height = props.height || '17'
  const color = props.color || '#1E96FC'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12.5874 6.21354C12.4947 6.28604 10.8592 7.21566 10.8592 9.28275C10.8592 11.6737 12.9404 12.5195 13.0027 12.5405C12.9931 12.592 12.672 13.6989 11.9054 14.8266C11.2218 15.8191 10.5078 16.81 9.42171 16.81C8.3356 16.81 8.05609 16.1736 6.80228 16.1736C5.58041 16.1736 5.14597 16.8309 4.15251 16.8309C3.15904 16.8309 2.46585 15.9126 1.66884 14.7848C0.745654 13.4604 -0.000244141 11.403 -0.000244141 9.4503C-0.000244141 6.31826 2.01863 4.65719 4.00556 4.65719C5.06132 4.65719 5.94138 5.35642 6.60422 5.35642C7.23512 5.35642 8.21901 4.6153 9.42011 4.6153C9.87531 4.6153 11.5109 4.65719 12.5874 6.21354ZM8.8499 3.28934C9.34664 2.69483 9.69802 1.86993 9.69802 1.04503C9.69802 0.930639 9.68844 0.814637 9.66768 0.721191C8.85949 0.751803 7.89797 1.26414 7.31818 1.94243C6.86297 2.46444 6.43811 3.28934 6.43811 4.12551C6.43811 4.25118 6.45888 4.37685 6.46846 4.41713C6.51957 4.42679 6.60263 4.43807 6.68568 4.43807C7.41082 4.43807 8.32282 3.94829 8.8499 3.28934Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
